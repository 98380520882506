import React from "react";
import AdminPodcastList from "components/adminPodcastList";

export default function AdminContentList() {

    return (
        <div>
            <AdminPodcastList />
        </div>
    )
}