import React, { useMemo, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "../../store/blogContent/actions";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../common/Breadcrumb";
import TableContainer from '../../common/TableContainer';
import AdminPageLoader from "../../common/adminPageLoader";
import CustomPagination from "common/CustomPagination";

const AdminContentList = () => {

    document.title = "Content | Allen Dreyfus";
    var [ContentList, setContentList] = useState([])
    var [title, setTitle] = useState('')
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { type } = useParams();

    const { ContentNeedApprovalList, ContentByStatus } = useSelector(state => state.BlogContentReducer)
    const { adminPageLoader } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (type === 'needApproval') {
            dispatch({
                type: BlogContentActions.GET_ADMIN_CONTENT_LIST_NEED_APPROVAL,
            });
        } else if ((type === 'Approved') || (type === 'Rejected')) {
            dispatch({
                type: BlogContentActions.GET_ADMIN_CONTENT_LIST_BY_STATUS,
                payload: type
            });
        }
    }, [type]);

    useEffect(() => {
        if ((ContentNeedApprovalList?.length !== 0) && type === 'needApproval') {
            setContentList(ContentNeedApprovalList)
        } else if (ContentByStatus?.length !== 0 && (type === 'Approved' || type === 'Rejected')) {
            setContentList(ContentByStatus)
        }
    }, [type, ContentNeedApprovalList, ContentByStatus])

    // useEffect(() => {
    //     if (ContentNeedApprovalList) {
    //         ContentNeedApprovalList?.map((single) => {
    //             if (single?.Blog_Language === 'Arabic') {
    //                 setTitle('Arabic_Content?.Title')

    //             } else if (single?.Blog_Language === 'French') {
    //                 setTitle(single?.French_Content?.Title)


    //             } else if (single?.Blog_Language === 'Portuguese') {
    //                 setTitle(single?.Portuguese_Content?.Title)


    //             } else {
    //                 setTitle(single?.English_Content?.Title)

    //             }
    //         })


    //     }
    // }, [ContentNeedApprovalList])


    const columns = useMemo(
        () => [
            // {
            //     Header: 'Id',
            //     accessor: '_id',
            //     filterable: true,
            // },

            {
                Header: 'Title',
                // accessor: `${cellProps.row.original?.Blog_Language === 'Arabic' ? 'Arabic_Content.Title' : ''}`,
                accessor: 'Title',
                filterable: true,
                Cell: title => {
                    return (
                        <div className="proName">
                            <ContentTitle {...title} />
                        </div>
                    )
                }

                //while translation below code are used

                // _________________________________________
                // Cell: title => {
                //     let data;
                //     if (title.row.original?.Blog_Language === 'Arabic') {
                //         data = title.row.original?.Arabic_Content?.Title
                //     } else if (title.row.original?.Blog_Language === 'English') {
                //         data = title.row.original?.English_Content?.Title
                //     } else if (title.row.original?.Blog_Language === 'French') {
                //         data = title.row.original?.French_Content?.Title
                //     } else if (title.row.original?.Blog_Language === 'Portuguese') {
                //         data = title.row.original?.Portuguese_Content?.Title
                //     }
                //     return data;
                // }

                //end
            },
            {
                Header: 'Status',
                accessor: 'Status',
                filterable: true,
            },
            {
                Header: 'Submitted By',
                accessor: 'Created_By',
                filterable: true,
                Cell: Created_By => {
                    return (
                        <div className="proName">
                            <ContentTitle {...Created_By} />
                        </div>
                    )
                }
            },
            {
                Header: 'Submitted On',
                accessor: 'Created_On',
                filterable: true,
                ...(type === 'Rejected' &&
                {
                    Header: 'Rejected On',
                    accessor: 'Rejected_On',
                    filterable: true,
                }),
                ...(type === 'Approved' &&
                {
                    Header: 'Article Date & time',
                    accessor: 'Approved_On',
                    filterable: true,
                })
            },
            // {
            //     Header: 'Action',
            //     Cell: (cellProps) => {
            //         return (
            //             <UncontrolledDropdown>
            //                 <DropdownToggle tag="a" className="card-drop">
            //                     <i className="mdi mdi-dots-horizontal font-size-18"></i>
            //                 </DropdownToggle>

            //                 <DropdownMenu className="dropdown-menu-end">
            //                     <DropdownItem
            //                         onClick={() => {
            //                             const Data = cellProps.row.original;
            //                             navigate('/content-details', { state: { data: Data, type: type } });
            //                             // navigate('/content-details');
            //                         }
            //                         }
            //                     >
            //                         <i className="mdi mdi-eye font-size-16 text-primary me-1" id="edittooltip"></i>
            //                         View
            //                         <UncontrolledTooltip placement="top" target="edittooltip">
            //                             View
            //                         </UncontrolledTooltip>
            //                     </DropdownItem>
            //                     {/* {(cellProps.row.original?.Registration_Status === 'Registered') &&
            //       <DropdownItem
            //         onClick={() => {
            //           const Data = cellProps.row.original;
            //           navigate('/edit-content-creator-details', { state: Data });
            //         }
            //         }
            //       >
            //         <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
            //         Edit
            //         <UncontrolledTooltip placement="top" target="edittooltip">
            //           Edit
            //         </UncontrolledTooltip>
            //       </DropdownItem>
            //     } */}
            //                 </DropdownMenu>
            //             </UncontrolledDropdown>
            //         );
            //     }
            // },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const Data = cellProps.row.original;
                                    navigate('/content-details', { state: { data: Data, type: type } });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ],
        [type]
    );

    const handleAddContentCreator = () => {
        navigate('/add-content-creator');
    };

    
    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs title="Content" breadcrumbItem={`${type === 'needApproval' ? 'need approval' : type} list`} />
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {ContentList && ContentList.length > 0 ? (
                                            <>
                                                <TableContainer
                                                    columns={columns}
                                                    data={ContentList || []}
                                                    isGlobalFilter={true}
                                                    isAddCustList={false}
                                                    isPagination={true}
                                                    // isAddContentCreator={type === 'Registered' ? true : false}
                                                    // handleAddContentCreator={handleAddContentCreator}
                                                    customPageSize={itemsPerPage}
                                                    tableClass="align-middle table-nowrap center-table"
                                                    isShowingPageLength={false}
                                                    iscustomPageSizeOptions={true}
                                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                                />                                               
                                            </>
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

const ContentTitle = (cell) => {
    return (cell?.value && cell?.value?.length > 25) ? `${cell?.value?.slice(0, 25)}...` : cell?.value
}
export default AdminContentList;
