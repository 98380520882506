export const SignUpActions = {
    

    GET_WEBUSER_SIGNUP_DETAILS: 'GET_WEBUSER_SIGNUP_DETAILS',
   
    USER_RESPONSE:'USER_RESPONSE'

  };


  




