import React from "react";
import SuperDashboardIndex from '../components/dashboard/index';
import AdminDashboard from "../components/AdminDashboard/adminDashboardIndex";
import CreatorDashboard from "components/creator-dashboard";
import { useSelector } from "react-redux";

export default function Dashboard() {
    const { superAdminTokenResponse, creatorTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer)
    return (
        <div>
            {/* <DashboardIndex /> */}
            {
                (superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin') &&
                <SuperDashboardIndex />
            }

            {
                adminTokenResponse?.result?.Type_Of_User === 'Admin' &&
                <AdminDashboard />
            }
            {
                creatorTokenResponse?.result?.Type_Of_User === 'Content Creator' &&
                <CreatorDashboard />
            }


        </div>
    )
}