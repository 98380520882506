const CryptoJS = require("crypto-js");

// Encrypt
export const encryptFunction = (data) => {
    if (data) {
        let encryptedResult = CryptoJS.AES.encrypt(JSON.stringify(data), '2023@gfx_winfomi_secret').toString();
        return encryptedResult;
    }
    return;
};

// Decrypt
export const decryptedFunction = (data) => {
    if (data) {
        let bytes = CryptoJS.AES.decrypt(data, '2023@gfx_winfomi_secret');
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    return;
};