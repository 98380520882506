import { corporateSubscriptionActions } from "./actions";

const initialState = {
    adminCorporateList: [],
    adminCorporateDetail: '',
    webCorporateResponse: []
};

export const corporateSubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case corporateSubscriptionActions.SET_ADMIN_CORPORATE_LIST:
            return {
                ...state,
                adminCorporateList: action.payload,
            }
        case corporateSubscriptionActions.SET_ADMIN_CORPORATE_DETAIL:
            return {
                ...state,
                adminCorporateDetail: action.payload,
            }
        case corporateSubscriptionActions.WEB_CORPORATE_RESPONSE:
            return {
                ...state,
                webCorporateResponse: action.payload,
            }
        default:
            return state;
    }
}
