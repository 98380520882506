import React from 'react'
import SignUp from '../../webComponents/signUp/index'
export default function WebSignUp() {

    document.title = "Sign up";
    return (
        <div>
            <SignUp />
        </div>
    )
}