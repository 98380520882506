import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import StackedColumnChart from "./StackedColumnChart";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { creatorDashboardActions } from '../../store/creatorDashboard/actions';

export default function ContentPublishedChart() {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [creatorId, setcreatorId] = useState("");
    const [periodData, setPeriodData] = useState([]);

    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { creatorContentGraphCount } = useSelector(state => state.creatorDashboardReducer);

    useEffect(() => {
        if (creatorTokenResponse?.statusCode === 200) {
            setcreatorId(creatorTokenResponse?.result?._id)
        }
    }, [creatorTokenResponse])

    useEffect(() => {
        if (creatorContentGraphCount?.statusCode === 200) {
            setPeriodData(creatorContentGraphCount?.result)
        }
    }, [creatorContentGraphCount])

    useEffect(() => {
        const filterYear = startDate?.getFullYear();
        if (creatorId) {
            dispatch({
                type: creatorDashboardActions.GET_CREATOR_CONTENT_GRAPH_COUNT, payload: { filterYear: filterYear, creatorId: creatorId }
            });
        }
    }, [startDate, creatorId])

    return (
        <Card>
            <CardBody>
                <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Content Published</h4>
                    <div className="ms-auto">
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showYearPicker
                            dateFormat="yyyy"
                        />
                    </div>
                </div>
                <StackedColumnChart
                    periodData={periodData}
                    dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' />
            </CardBody>
        </Card>
    )
}