import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Label,
  Input,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { userAuthActions } from "store/userAuth/actions"
import logosm from "../../assets/images/dashboard/new-logo22.png"
import profile from "../../assets/images/profile-img.png"
import success_img from "../../assets/images/dashboard/success1-popup-image.gif"

import { useFormik } from "formik"
import * as Yup from "yup"

const ForgotPasswordIndex = () => {
  const dispatch = useDispatch()

  const { userAuthResponse } = useSelector(state => state.userAuthReducer)

  const { buttonLoader } = useSelector(state => state.commonReducer)

  const [showSuccessPopup, setShowSuccessPopup] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: "",
    },
    validationSchema: Yup.object({
      Email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      const lowercaseEmail = values.Email.toLowerCase();
      const data = {
        Email: lowercaseEmail,
        CallingFrom: 'User'
      }
      // dispatch({ type: userAuthActions.FOTGOT_PASSWORD, payload: { Email: lowercaseEmail } })
      dispatch({ type: userAuthActions.FOTGOT_PASSWORD, payload: data })
    },
  })

  useEffect(() => {
    if (userAuthResponse && userAuthResponse?.statusCode === 200) {
      setShowSuccessPopup(true)
    } else if (userAuthResponse && userAuthResponse?.statusCode === 400) {
      validation.setErrors({ Email: "Please enter the valid Email Id" })
    }
  }, [userAuthResponse])

  const handleClose = () => {
    setShowSuccessPopup(false)
    window.location.reload()
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5 p-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-light p-4">
                        <h5 className="text-light">Welcome Back !</h5>
                        <p>Forgot your password?</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logosm}
                            alt=""
                            className="rounded-circle logo-sm-img-login"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="padding-signup-model">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="Email"
                          className="form-control formcontrol"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Email.toLowerCase() || ""}
                          invalid={
                            validation.touched.Email && validation.errors.Email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.Email && validation.errors.Email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.Email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {/* {buttonLoader ?
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              <Spinner color="light" size="sm" />
                            </button> : */}
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {buttonLoader ? (
                              <Spinner color="light" size="sm" />
                            ) : (
                              "Reset"
                            )}
                          </button>
                          {/* } */}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/web/login" className="font-weight-medium text-primary">
                    log in
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Allen Dreyfus</p>
              </div>
            </Col>
          </Row>
          <Modal isOpen={showSuccessPopup} toggle={handleClose} centered={true}>
            <ModalHeader toggle={handleClose} tag="h4">
              Alert
            </ModalHeader>
            <ModalBody>
              <Form className="padding-signup-model">
                <Row>
                  <Col md={12} className="text-center">
                    <img
                      // src={require('../../assets/images/dashboard/forgotPasswordAnimation.gif')}
                      src={success_img}
                      alt=""
                      // className="forgot-password-gif-img"
                      className="popup-success"
                    />
                    <h5>
                      *You will get new password through email associated with
                      your account within two minutes
                    </h5>
                  </Col>
                  <hr />
                  <Col md={12}>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-primary save-customer me-2"
                        onClick={handleClose}
                      >
                        Ok
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgotPasswordIndex
