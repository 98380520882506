import { webContentByCategoryActions } from "./actions";

const initialState = {
    webCategoryRecentList: [],
    webListByCategory: [],
    webPopularListByContent: [],
};

export const webContentByCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case webContentByCategoryActions.SET_WEB_RECENT_CONTENT_BY_CATEGORY:
            return {
                ...state,
                webCategoryRecentList: action.payload,
            }
        case webContentByCategoryActions.SET_WEB_LIST_BY_CATEGORY:
            return {
                ...state,
                webListByCategory: action.payload,
            }
        case webContentByCategoryActions.SET_WEB_POPULAR_LIST_BY_CATEGORY:
            return {
                ...state,
                webPopularListByContent: action.payload
            }
        default:
            return state;
    }
}