import React, { useState, useRef } from "react";
import { Container, Card, CardBody, CardTitle, Button, Input, Label, Row, Col, Table, FormFeedback, Form, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../store/admin/actions";
import Breadcrumb from "common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from 'yup';
import profileicon from '../../assets/images/dashboard/user3.png';
import uploadImage from "../../../src/assets/webImages/upload3.png";
import FileSizeValidation from '../../FileSizeValidation/index';
import { commonActions } from "../../store/common/actions";

export default function EditAdminDetailsIndex() {

    const fileInputRef = useRef(null);

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [profileImage, setProfileImage] = useState("")
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const { buttonLoader } = useSelector(state => state.commonReducer);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: state?.Name,
            Last_Name: state?.Last_Name,
            Email: state?.Email,
            Contact_Number: state?.Contact_Number,
            // Responsibilities: state?.Responsibilities
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("Name is required"),
            Email: Yup.string().required("Email is required"),
            Contact_Number: Yup.string().required("Contact Number is required"),
        }),
        onSubmit: (data) => {
            const formData = new FormData();
            formData.append("Name", data.Name);
            formData.append("Last_Name", data.Last_Name || '');
            formData.append("Email", data.Email);
            formData.append("Contact_Number", data.Contact_Number);

            if (selectedFile) {
                formData.append("Profile_Image", selectedFile)
                dispatch({
                    type: adminActions.UPDATE_ADMIN,
                    payload: { Id: state?._id, data: formData }
                });
            } else {
                dispatch({
                    type: adminActions.UPDATE_ADMIN,
                    payload: { Id: state?._id, data: formData, navigate: navigate, state: state }
                });
            }

            // for (let key in data) {
            //     (key === 'Responsibilities') ?
            //         formData.append(key, JSON.stringify(data[key])) : formData.append(key, data[key]);
            // };

            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // } 
        }
    });

    const handleSelectChange = (value, type, keyValue) => {
        let array = [...validation?.values?.Responsibilities];
        array[keyValue][type] = value;
        validation.setValues({ ...validation.values, 'Responsibilities': array });
    };

    const handleFileSelect = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            let result = FileSizeValidation(event.target.files);
            if (result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
                    result?.FinalImages[0]?.type === 'image/png') {
                    const imageUrl = URL.createObjectURL(selectedFile);
                    setSelectedImage(imageUrl);
                    setSelectedFile(selectedFile);
                } else {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { show: true, status: "failed", message: "Unsupported File Format" }
                    })
                }
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title="Admin"
                        titleLink="/admin"
                        breadcrumbItem="Edit Admin"
                    />
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div onClick={handleFileSelect}>
                                                {selectedImage ? (
                                                    <img
                                                        src={selectedImage}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                ) : (
                                                    <img
                                                        src={state?.Profile_Image ?? profileicon}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                )}

                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                    accept="image/jpg,image/jpeg,image/png"
                                                />
                                                <img className="uploadProfile" src={uploadImage} alt="upload-profile" />
                                                {/* <i className="mdi mdi-arrow-up-bold-circle" ></i> */}
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted profileAlignment">
                                                    <h5 className="proName">{name}</h5>
                                                    <p className="mb-1">{email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <small className="text-success pt-5"><i><span className="text-danger">*</span> Support's .jpeg, .jpg, .png</i></small>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Admin Information</CardTitle>
                                        <Row>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Name</Label>
                                                    <Input
                                                        name="Name"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        type="text"
                                                        value={validation?.values?.Name}
                                                        onChange={validation?.handleChange}
                                                        onBlur={validation?.handleBlur}
                                                        invalid={validation?.touched?.Name && validation?.errors?.Name ? true : false}
                                                    />
                                                    {validation?.touched?.Name && validation?.errors?.Name ?
                                                        <FormFeedback type='invalid'>{validation?.errors?.Name}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Last Name</Label>
                                                    <Input
                                                        name="Last_Name"
                                                        className="form-control"
                                                        placeholder="Enter Last Name"
                                                        type="text"
                                                        value={validation?.values?.Last_Name}
                                                        onChange={validation?.handleChange}
                                                        onBlur={validation?.handleBlur}
                                                        invalid={validation?.touched?.Last_Name && validation?.errors?.Last_Name ? true : false}
                                                    />
                                                    {validation?.touched?.Last_Name && validation?.errors?.Last_Name ?
                                                        <FormFeedback type='invalid'>{validation?.errors?.Last_Name}</FormFeedback> :
                                                        null}
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        name="Email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        type="text"
                                                        value={validation?.values?.Email}
                                                        onChange={validation?.handleChange}
                                                        onBlur={validation?.handleBlur}
                                                        invalid={validation?.touched?.Email && validation?.errors?.Email ? true : false}
                                                    />
                                                    {validation?.touched?.Email && validation?.errors?.Email ?
                                                        <FormFeedback type='invalid'>{validation?.errors?.Email}</FormFeedback> :
                                                        null}
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Contact Number</Label>
                                                    <Input
                                                        name="Contact_Number"
                                                        className="form-control"
                                                        placeholder="Enter contact Number"
                                                        type="text"
                                                        value={validation?.values?.Contact_Number}
                                                        onChange={validation?.handleChange}
                                                        onBlur={validation?.handleBlur}
                                                        invalid={validation?.touched?.Contact_Number && validation?.errors?.Contact_Number ? true : false}
                                                    />
                                                    {validation?.touched?.Contact_Number && validation?.errors?.Contact_Number ?
                                                        <FormFeedback type='invalid'>{validation?.errors?.Contact_Number}</FormFeedback> :
                                                        null}
                                                </div>
                                            </Col>
                                            <div className="mt-4">
                                                <Button
                                                    className="me-3"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    {buttonLoader ? <Spinner color="light" size='sm' /> : "Update"}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="danger"
                                                    onClick={() => navigate('/admin')}
                                                >
                                                    Close
                                                </Button>
                                            </div>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col xs="12">                                
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Responsibilities</CardTitle>
                                        <Table responsive bordered>
                                            <thead className="text-center align-middle">
                                                <tr>
                                                    <th>Role</th>
                                                    <th>View</th>
                                                    <th>Add</th>
                                                    <th>Update</th>
                                                    <th>Suspend/ Delete/ Block</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center align-middle">
                                                {validation?.values?.Responsibilities?.map((val, i) =>
                                                    <tr key={i}>
                                                        <th>{val?.TabName}</th>
                                                        <td>{val?.View !== '-' ?
                                                            <AccessSelect
                                                                value={val?.View}
                                                                keyValue={i}
                                                                type="View"
                                                                handleSelectChange={handleSelectChange}
                                                            /> :
                                                            "-"
                                                        }
                                                        </td>
                                                        <td>{val?.Add !== '-' ?
                                                            <AccessSelect
                                                                value={val?.Add}
                                                                keyValue={i}
                                                                type='Add'
                                                                handleSelectChange={handleSelectChange}
                                                            /> :
                                                            "-"
                                                        }
                                                        </td>
                                                        <td>{val?.Update !== '-' ?
                                                            <AccessSelect
                                                                value={val?.Update}
                                                                keyValue={i}
                                                                type='Update'
                                                                handleSelectChange={handleSelectChange}
                                                            /> :
                                                            '-'
                                                        }
                                                        </td>
                                                        <td>{val?.Delete !== '-' ?
                                                            <AccessSelect
                                                                value={val?.Delete}
                                                                keyValue={i}
                                                                type='Delete'
                                                                handleSelectChange={handleSelectChange}
                                                            /> :
                                                            "-"
                                                        }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>                                       
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    )
}

function AccessSelect({ value, handleSelectChange, type, keyValue }) {

    return (
        <div style={{ backgroundColor: value === 'Yes' ? '#34c38f' : '', borderRadius: value === 'Yes' ? '5px' : '' }}>
            <select
                style={{ backgroundColor: value === 'Yes' ? 'transparent' : '' }}
                className="form-control form-control-select-arrow"
                value={value}
                onChange={(e) => handleSelectChange(e.target.value, type, keyValue)}
            >
                <option disabled value="">--Select--</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
    )
}