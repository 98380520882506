import ContentDetailsIndex from "components/adminContentDetails";
import React from "react";

export default function ContentDetails() {

    return (
        <div>
            <ContentDetailsIndex />
        </div>
    )
}