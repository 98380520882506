import { stripeActions } from './actions';

const initialState = {
    paymentResponse: '',
}

export const stripeReducer = (state = initialState, action) => {
    switch (action.type) {
        case stripeActions.STRIPE_PAYMENT_RESPONSE:
            return {
                ...state,
                paymentResponse: action.payload,
            }
        default:
            return state;
    }
}