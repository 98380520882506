import React from "react"
import Header from "../../webComponents/Layout/Header"
import RelatedContent from "./relatedContent.js"
import Footer from "../../webComponents/Layout/Footer"
import ContentDetails from "./contentDetails"

const DetailIndex = () => {
  document.title = "Content Detail"

  return (
    <>
      <Header />
      <ContentDetails />
      <RelatedContent />
      <Footer />
    </>
  )
}

export default DetailIndex
