import { actions } from './actions'

const initState = {
    superAdminNotificationList: [],
    adminNotificationList: [],
    creatorNotificationList: [],
}

export const NotificationReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_SUPER_ADMIN_NOTIFICATION_LIST:
            return {
                ...state,
                superAdminNotificationList: action.payload
            }
        case actions.SET_ADMIN_NOTIFICATION_LIST:
            return {
                ...state,
                adminNotificationList: action.payload
            }
        case actions.SET_CREATOR_NOTIFICATION_LIST:
            return {
                ...state,
                creatorNotificationList: action.payload
            }
        default:
            return state;
    }
}