import React from "react"
import ProfilePageIndex from "../../webComponents/Profile/Index"
import Header from "webComponents/Layout/Header"



export default function WebProfile() {
  document.title = "Profile"
  return (
    <div>
      <Header />
      <ProfilePageIndex />
    </div>
  )
}
