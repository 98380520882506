import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Button, Container } from "react-bootstrap"
import { FaShareAlt } from "react-icons/fa"
import { MdBookmark } from "react-icons/md"

import Skyblueimg from "../../assets/webImages/right-top-latest.webp"
import Subscriptionimage from "../SubscribeBanner/index"
import { useLocation, useNavigate } from "react-router-dom"
import { WebContentDeatilActions } from "store/webContentDetail/actions"
import { useDispatch, useSelector } from "react-redux"
import { ContentCreatorActions } from "store/contentCreator/actions"
import { WebHomePageActions } from "store/webHome/actions"
import SubscriptionModal from "webComponents/modals/SubscriptionModal"
import WebPageLoader from "../../common/webPageLoader"
import NewsletterSectionCommon from "webComponents/Common/newslettercommon"
import NewsletterText from "../../assets/webImages/exclusive.webp"
import NewsletterMsgImg from "../../assets/webImages/Group 8.webp"
import Subscribenow from "../../assets/webImages/Group 12.webp"
import Subscriptiontext from "../../assets/webImages/real-time2.webp"
import { WebBookmarkActions } from "store/webBookmark/actions"
import { GoAlert } from "react-icons/go"
import NoDataInDB from "../../common/no-data-image.svg"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share"
import copyurlimg from "../../assets/webImages/copy-link-icon-.webp"
import { useParams } from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js"
import actions from "../../store/adminSubscriptionPlans/actions"
import CustomTwitterIcon from "../../assets/webImages/twitter_5969020.png"
import { STRIPE_LIVE_PUBLISHABLE_KEY, STRIPE_TEST_PUBLISHABLE_KEY } from '../../utils/constant'
const ContentDetails = () => {
  
//test publishable key
// const stripePromise = loadStripe(STRIPE_TEST_PUBLISHABLE_KEY)

// live publishable key
const stripePromise = loadStripe(STRIPE_LIVE_PUBLISHABLE_KEY)
  
  const [isOpen, setIsOpen] = useState(false)
  const [textSize, setTextSize] = useState(16)
  const [showFullBio, setShowFullBio] = useState(false)
  const [finalPrefList, setFinalPrefList] = useState([])
  const [limitedRecentListArray, setLimitedRecentListArray] = useState([])
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [showShareIcons, setShowShareIcons] = useState(false)
  const [showLinkCopied, setShowLinkCopied] = useState(false)
  const { DetailContent } = useSelector(state => state.WebContentDetailReducer)
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { contentId } = useParams()

  const currentContentId = (state?.clickedFrom === 'Bookmarks') ? state?.contentDetail?.Blog_Id : (state?.contentDetail?._id || contentId)

  const User_Id = tokenResponse?.result?._id
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)

  const [Bookmarks, setBookmarks] = useState({
    Blog_Id: "",
    Content_Creator_Id: '',
    Category: "",
    Title: "",
    Image: "",
    Blog_Created_On: "",
  })

  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"
  const stripeCutomerId = tokenResponse?.result?.Stripe_Customer_Id

  const { content_Creator_Details } = useSelector(
    state => state.ContentCreatorReducer
  )

  const { ForYouUserPrefrence } = useSelector(state => state.WebHomePageReducer)

  const isLoggedIn = !!tokenResponse?.result
  const { limitedRecentContent } = useSelector(state => state.WebHomePageReducer)

  const { webPageLoader } = useSelector(state => state.commonReducer)
  const { getSessionId } = useSelector(state => state.subscriptionPlanReducer)

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false)
  }
  // stripe checkout
  const handleStripeClick = async (tokenResponse, planId, subscriptionType) => {
    if (tokenResponse === null || tokenResponse === undefined) {
      // setIsOpen(true)
      navigate("/web/login")
    } else {
      dispatch({
        type: actions.GET_CHECKOUT_SESSION_ID,
        payload: {
          planId: planId,
          email: tokenResponse?.result?.Email,
          subscriptionType: subscriptionType,
        },
      })
    }
  }

  //stripe session
  useEffect(() => {
    const redirectCheckout = async () => {
      const stripe = await stripePromise
      if (getSessionId?.id) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: getSessionId?.id,
        })
        if (error) {
          console.error("Error:", error)
        }
      }
    }
    redirectCheckout()
  }, [getSessionId?.id])

  const hideModal = () => {
    setIsOpen(false)
    navigate("/web/login")
  }

  const hideClose = () => {
    setIsOpen(false)
  }


  useEffect(() => {
    dispatch({
      type: WebContentDeatilActions.GET_WEBCONTENT_DETAIL_NEWS,
      payload: currentContentId,
    })

    // dispatch({
    //   type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS,
    //   payload: state?.contentDetail?.Content_Creator_Id || DetailContent?.result?.Content_Creator_Id,
    // })
  }, [currentContentId])

  useEffect(() => {
    dispatch({
      type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS,
      payload: state?.contentDetail?.Content_Creator_Id || DetailContent?.result?.Content_Creator_Id,
    })
  }, [DetailContent?.result, state?.contentDetail])

  useEffect(() => {
    dispatch({ type: WebHomePageActions.GET_LIMITED_RECENT_CONTENT_LIST })
  }, [])

  useEffect(() => {
    const userId = tokenResponse?.result?._id
    const UserId = userId
    const Category = "Article"

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_FORYOU_NEWS_DETAILS,
      payload: { UserId, Category },
    })
  }, [dispatch, tokenResponse])

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, {
      state: { contentDetail: contentDetail },
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const handleVoicesChanged = () => {
      const voices = window.speechSynthesis.getVoices()
    }

    window.speechSynthesis.addEventListener("start", handleSpeechStart)
    window.speechSynthesis.addEventListener("end", handleSpeechEnd)
    window.speechSynthesis.addEventListener(
      "voiceschanged",
      handleVoicesChanged
    )

    return () => {
      window.speechSynthesis.cancel()
      window.speechSynthesis.removeEventListener("start", handleSpeechStart)
      window.speechSynthesis.removeEventListener("end", handleSpeechEnd)
      window.speechSynthesis.removeEventListener(
        "voiceschanged",
        handleVoicesChanged
      )
    }
  }, [])

  const handleSubscribeClick = () => {
    setShowSubscriptionModal(true)
  }

  const increaseTextSize = () => {
    setTextSize(prevSize => prevSize + 2)
  }

  const handleSpeechStart = () => {
    document.querySelector(".detailed-news").classList.add("highlighted")
  }

  const handleSpeechEnd = () => {
    document.querySelector(".detailed-news").classList.remove("highlighted")
  }

  const handleListenClick = () => {
    const detailedNewsElement = document.querySelector(".detailed-news")
    const detailedNewsText = detailedNewsElement.innerText

    // Split the text into sentences
    const sentences = detailedNewsText.split(/[.!?]/)

    const voices = window.speechSynthesis.getVoices()

    const englishVoice = voices.find(voice => voice.lang.startsWith("en"))

    let sentenceIndex = 0

    const utterance = new SpeechSynthesisUtterance(sentences[sentenceIndex])
    utterance.voice = englishVoice || voices[0]

    detailedNewsElement.classList.add("highlighted")

    // Event listener for the end of speech
    utterance.addEventListener("end", () => {
      // Remove the highlighting for the current sentence
      detailedNewsElement.classList.remove("highlighted")

      // Move to the next sentence and update the utterance
      sentenceIndex++
      if (sentenceIndex < sentences.length) {
        utterance.text = sentences[sentenceIndex]
        detailedNewsElement.classList.add("highlighted")
        window.speechSynthesis.speak(utterance)
      }
    })

    // Start speech synthesis
    window.speechSynthesis.speak(utterance)
  }

  const handleShare = platform => {
    const shareUrl = window.location.href
    const title = DetailContent?.result?.Title
    const imageUrl = DetailContent?.result?.Article_Images

    switch (platform) {
      case "facebook":
        return (
          <FacebookShareButton url={`${shareUrl}`} quote={title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        )
      case "linkedin":
        return (
          <LinkedinShareButton url={`${shareUrl}`} title={title}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        )
      case "twitter":
        return (
          // <TwitterShareButton url={`${shareUrl}`} title={title}>
          //   <TwitterIcon size={32} round />
          // </TwitterShareButton>

          <TwitterShareButton url={shareUrl} title={title}>
            <img
              src={CustomTwitterIcon}
              alt="Custom Twitter Icon"
              className="twitter-icon"
            />
          </TwitterShareButton>
        )
      case "whatsapp":
        return (
          <WhatsappShareButton url={`${shareUrl}`} title={title}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        )
      case "gmail":
        return (
          <EmailShareButton url={`${shareUrl}`} subject={title}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        )
      default:
        return null
    }
  }

  const handleCopyUrl = () => {
    const currentUrl = window.location.href

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setShowLinkCopied(true)
        setTimeout(() => {
          setShowLinkCopied(false)
        }, 3000)
      })
      .catch(error => { })
  }

  useEffect(() => {
    if (DetailContent && DetailContent.result) {
      const { result } = DetailContent
      const category = Array.isArray(result.Category)
        ? result.Category[0]
        : result.Category
      const image = Array.isArray(result.Article_Images)
        ? result.Article_Images[0]
        : result.Article_Images

      setBookmarks({
        Blog_Id: result._id || "",
        Content_Creator_Id: result.Content_Creator_Id || '',
        Category: category || "",
        Title: result.Title || "",
        Image: image || "",
        Blog_Created_On: result.Approved_On || "",
      })
    }
  }, [DetailContent])

  useEffect(() => {
    if (!tokenResponse?.result) {
      setShowLoginModal(saveClicked)
    }
  }, [tokenResponse, saveClicked])

  const handleLoginClickforSave = () => {
    navigate("/web/login")
    setShowLoginModal(false)
  }

  const handleCloseLoginModal = () => {
    setShowLoginModal(false)
  }

  const handleSaveClick = () => {
    setSaveClicked(true)
    if (!tokenResponse?.result) {
      setShowLoginModal(true)
    } else {
      const payload = {
        User_Id: User_Id || "",
        Bookmarks: Bookmarks,
      }
      dispatch({
        type: WebBookmarkActions.GET_WEB_ADD_TO_BOOKMARK,
        payload: payload,
      })
    }
  }

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  const handleReadMore = () => {
    setShowFullBio(true)
  }

  useEffect(() => {
    if (ForYouUserPrefrence?.result?.length > 0) {
      let FinalPrefList = ForYouUserPrefrence?.result?.filter(val => (val?._id) !== currentContentId)
      if (FinalPrefList?.length > 8) {
        setFinalPrefList(FinalPrefList?.slice(6, 8))
      } else if (FinalPrefList?.length < 8) {
        setFinalPrefList(FinalPrefList?.slice(0, 2))
      }
    }
  }, [ForYouUserPrefrence])
  
  useEffect(() => {
    if (limitedRecentContent?.result?.length !== 0) {
      let FinalRecentList = limitedRecentContent?.result?.filter(val => (val?._id) !== currentContentId)
      setLimitedRecentListArray(FinalRecentList?.slice((FinalRecentList?.length - 2), FinalRecentList?.length))
    }
  }, [limitedRecentContent])
 
  return (
    <Container>
      <>
        {webPageLoader ? (
          <WebPageLoader className="recent-loader" />
        ) : (

          <>
            {(DetailContent?.statusCode === 204) ?
              <>
                <div className="no-data-container">
                  <div className="centered-content">
                    <img
                      src={NoDataInDB}
                      alt="No Data Found"
                      className="no-data-image"
                    />
                    <h1 className="no-data-message web_fonts">
                      {" "}
                      Oops! currently this content not available!
                    </h1>
                  </div>
                </div>
              </> :
              <>
                <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
                  <div className="modal-header">
                    <h5 className="modal-title">Alert</h5>
                    <button
                      type="button"
                      className="close sub-alert"
                      onClick={handleCloseLoginModal}
                    ></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      lineHeight: "3",
                    }}
                  >
                    <GoAlert className="alert-icon1" />
                    <h5 style={{ marginTop: "10px" }}>
                      please log in to save this content
                    </h5>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="login-alert"
                      onClick={handleLoginClickforSave}
                    >
                      log in
                    </button>
                    <button
                      type="button"
                      className="close-alert"
                      onClick={handleCloseLoginModal}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
                <Row>
                  <Col xs={12} md={8} lg={8} className="mt-3">
                    {isSubscribed ? (
                      // User is subscribed  full news story
                      <div className="detailed-news-container">
                        <h3 className="web_fonts politics-title color-black">
                          {DetailContent?.result?.Title}
                        </h3>
                        <Row style={{ paddingBottom: "10px" }}>
                          <Col xs={8} sm={8}>
                            <div className="author-date">
                              <p className="web_fonts">
                                {`${formatDate(DetailContent?.result?.Approved_On)}`}&nbsp; <b> | </b>&nbsp; {` ${DetailContent?.formattedDifference} ago `}
                              </p>
                            </div>
                          </Col>

                          <Col xs={4} sm={4} className="text-right">
                            <div className="justify-content-right">
                              <Row>
                                {/* <Col xs={12} md={8}>
                              <Button
                                className="btn btn-light listen custom-nav-link"
                                onClick={handleListenClick}
                              >
                                <FaHeadphones />
                                listen
                              </Button>
                            </Col> */}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <img
                            src={DetailContent?.result?.Article_Images}
                            alt="shein"
                            className="shein-img"
                          />
                          {DetailContent?.result?.Caption && (
                            <div className="caption-detailpage">
                              <p className="shein-img-p">
                                {DetailContent?.result?.Caption}
                              </p>
                            </div>
                          )}
                        </div>
                        <Row className="creator-profile-row">
                          <Col lg={1} md={2} xs={2} sm={2}>
                            <img
                              src={content_Creator_Details?.Profile_Image}
                              alt="creatorprofile"
                              className="creator-profile"
                            />
                          </Col>

                          <Col lg={11} md={10} xs={10} sm={10}>
                            <div className="about-creator">
                              <h6 className="web_fonts">
                                <span style={{ textTransform: "none" }}>
                                  {content_Creator_Details?.Name}
                                </span>
                              </h6>
                              {showFullBio ? (
                                <p className="web_fonts color-black">
                                  {content_Creator_Details?.Brief_Bio_Description}
                                </p>
                              ) : (
                                <>
                                  {content_Creator_Details?.Brief_Bio_Description
                                    ?.length > 200 && (
                                      <>
                                        <p
                                          className="web_fonts color-black"
                                          onClick={handleReadMore}
                                        >
                                          {content_Creator_Details?.Brief_Bio_Description?.slice(
                                            0,
                                            200
                                          )}
                                          <small
                                            className="readMore"
                                            style={{ color: "blue" }}
                                          >
                                            {" "}
                                            ...read more
                                          </small>
                                        </p>
                                      </>
                                    )}

                                  {content_Creator_Details?.Brief_Bio_Description
                                    ?.length <= 200 && (
                                      <p className="web_fonts color-black">
                                        {
                                          content_Creator_Details?.Brief_Bio_Description
                                        }
                                      </p>
                                    )}
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <hr className="hr-line-recent" />
                        <Row className="icon-row">
                          <Container>
                            <div className="icons">
                              <Row>
                                <Col xs={12} md={8}>
                                  <MdBookmark
                                    className="icons-size"
                                    onClick={handleSaveClick}
                                  />
                                </Col>
                              </Row>
                              {/* <Row className="text-enlarger-buttons">
                            <Col xs={12} md={8}>
                              <IoText
                                className="icons-size"
                                // onClick={increaseTextSize}
                              />
                            </Col>
                          </Row> */}
                              <Row>
                                <Col xs={12} md={8}>
                                  <img
                                    src={copyurlimg}
                                    className="icons-size"
                                    onClick={handleCopyUrl}
                                  />
                                </Col>

                                {showLinkCopied && (
                                  <p className="link-copied">Link Copied</p>
                                )}
                              </Row>
                              <FaShareAlt
                                className="icons-size"
                                onClick={() => setShowShareIcons(!showShareIcons)}
                              />
                              {showShareIcons && (
                                <Row className="share-icons-row">
                                  <Col
                                    xs={12}
                                    md={8}
                                    className="d-flex align-items-center"
                                  >
                                    {handleShare("gmail")}
                                    {handleShare("whatsapp")}
                                    {handleShare("linkedin")}
                                    {handleShare("twitter")}
                                  </Col>
                                </Row>
                              )}{" "}
                            </div>
                          </Container>
                        </Row>
                        <Row className="detailed-news cont-det-new-font">
                          <div
                            style={{
                              textTransform: "none",
                              fontSize: `${textSize}px`,
                            }}
                            className="text-trans"
                          >
                            {/* Render the full story */}
                            <p
                              className="text-trans"
                              dangerouslySetInnerHTML={{
                                __html: DetailContent?.result?.Story,
                              }}
                            />
                          </div>
                        </Row>
                      </div>
                    ) : (
                      // User is not subscribed, show partial news story and subscription options
                      <div className="detailed-news-container">
                        <h3 className="web_fonts politics-title color-black">
                          {DetailContent?.result?.Title}
                        </h3>
                        <Row style={{ paddingBottom: "10px" }}>
                          <Col xs={8} sm={8}>
                            <div className="author-date">
                              <p className="web_fonts">
                                {/* {DetailContent?.result?.Approved_On} */}
                                {`${formatDate(DetailContent?.result?.Approved_On)}`}&nbsp; <b> | </b>&nbsp; {` ${DetailContent?.formattedDifference} ago `}
                              </p>
                            </div>
                          </Col>

                          <Col xs={4} sm={4} className="text-right">
                            <div className="justify-content-right">
                              <Row>
                                {/* <Col xs={12} md={8}>
                              <Button
                                className="btn btn-light listen custom-nav-link"
                                onClick={handleListenClick}
                              >
                                <FaHeadphones />
                                listen
                              </Button>
                            </Col> */}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <img
                            src={DetailContent?.result?.Article_Images}
                            alt="shein"
                            className="shein-img"
                          />
                          {DetailContent?.result?.Caption && (
                            <div className="caption-detailpage">
                              <p className="shein-img-p">
                                {DetailContent?.result?.Caption}
                              </p>
                            </div>
                          )}
                        </div>

                        <Row className="creator-profile-row">
                          <Col lg={1} md={2} xs={2} sm={2}>
                            <img
                              src={content_Creator_Details?.Profile_Image}
                              alt="creatorprofile"
                              className="creator-profile"
                            />
                          </Col>

                          <Col lg={11} md={10} xs={10} sm={10}>
                            <div className="about-creator">
                              <h6 className="web_fonts">
                                <span style={{ textTransform: "none" }}>
                                  {" "}
                                  {content_Creator_Details?.Name}
                                </span>
                              </h6>
                              {showFullBio ? (
                                <p className="web_fonts color-black">
                                  {content_Creator_Details?.Brief_Bio_Description}
                                </p>
                              ) : (
                                <>
                                  {content_Creator_Details?.Brief_Bio_Description
                                    ?.length > 200 && (
                                      <>
                                        <p
                                          className="web_fonts color-black"
                                          onClick={handleReadMore}
                                        >
                                          {content_Creator_Details?.Brief_Bio_Description?.slice(
                                            0,
                                            200
                                          )}
                                          <small
                                            className="readMore"
                                            style={{ color: "blue" }}
                                          >
                                            {" "}
                                            ...read more
                                          </small>
                                        </p>
                                      </>
                                    )}

                                  {content_Creator_Details?.Brief_Bio_Description
                                    ?.length <= 200 && (
                                      <p className="web_fonts color-black">
                                        {
                                          content_Creator_Details?.Brief_Bio_Description
                                        }
                                      </p>
                                    )}
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <hr className="hr-line-recent" />
                        <Row className="icon-row">
                          <Container>
                            <div className="icons">
                              <Row>
                                <Col xs={12} md={8}>
                                  <MdBookmark
                                    className="icons-size"
                                    onClick={handleSaveClick}
                                  />
                                </Col>
                              </Row>
                              {/* <Row className="text-enlarger-buttons">
                            <Col xs={12} md={8}>
                              <IoText
                                className="icons-size"
                                // onClick={increaseTextSize}
                              />
                            </Col>
                          </Row> */}
                              <Row>
                                <Col xs={12} md={8}>
                                  <img
                                    src={copyurlimg}
                                    className="icons-size"
                                    onClick={handleCopyUrl}
                                  />
                                </Col>

                                {showLinkCopied && (
                                  <p className="link-copied">Link Copied</p>
                                )}
                              </Row>
                              <FaShareAlt
                                className="icons-size"
                                onClick={() => setShowShareIcons(!showShareIcons)}
                              />
                              {showShareIcons && (
                                <Row className="share-icons-row">
                                  <Col
                                    xs={12}
                                    md={8}
                                    className="d-flex align-items-center"
                                  >
                                    {handleShare("gmail")}
                                    {handleShare("whatsapp")}
                                    {handleShare("linkedin")}
                                    {handleShare("twitter")}
                                  </Col>
                                </Row>
                              )}{" "}
                            </div>
                          </Container>
                        </Row>
                        <Row className="detailed-news cont-det-new-font">
                          <div
                            style={{
                              fontSize: `${textSize}px`,
                              textTransform: "none",
                            }}
                            className="text-trans"
                          >
                            <p
                              style={{ textTransform: "unset" }}
                              className="text-trans"
                            >
                              <span
                                className="web_fonts color-black text-trans"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (DetailContent?.result?.Story &&
                                      DetailContent.result.Story.split(" ")
                                        .slice(0, 100)
                                        .join(" ")) +
                                    (DetailContent?.result?.Story &&
                                      DetailContent.result.Story.split(" ").length >
                                      100
                                      ? "..."
                                      : ""),
                                }}
                              ></span>

                              {/* <span
                            className="detailed-news-shade1 text-trans"
                            style={{ color: "#757575" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                DetailContent?.result?.Story &&
                                DetailContent.result.Story.split(" ")
                                  .slice(51, 80)
                                  .join(" "),
                            }}
                          ></span>
                          <span
                            className="detailed-news-shade2 text-trans"
                            dangerouslySetInnerHTML={{
                              __html:
                                DetailContent?.result?.Story &&
                                DetailContent.result.Story.split(" ")
                                  .slice(81, 100)
                                  .join(" "),
                            }}
                          ></span> */}
                            </p>
                          </div>
                        </Row>
                        {/* Only show the info-to-view div for non-subscribed users */}
                        <Row>
                          <Col>
                            <div className="info-to-view">
                              <h2 className="web_fonts color-black">
                                continue reading article with a subscription
                              </h2>
                              <div>
                                <Button
                                  variant="primary"
                                  className="subscribe-button suc-sub custom-nav-link"
                                  onClick={handleSubscribeClick}
                                >
                                  <samp className="web_fonts">subscribe now</samp>
                                </Button>
                                {!stripeCutomerId && (
                                  <>
                                    <span className="text-or web_fonts">or</span>

                                    <Button
                                      className="btn btn-light button-5 sub-button  custom-nav-link "
                                      onClick={() =>
                                        handleStripeClick(
                                          tokenResponse,
                                          "price_1OuXntAXp7wlJ7cTP0dFYc7L",
                                          "freeTrial"
                                        )
                                      }
                                    >
                                      free trial
                                    </Button>
                                  </>
                                )}
                                {!isLoggedIn && (
                                  <p className="alredy-sub">
                                    already a subscriber?
                                    <a
                                      href="/web/login"
                                      className="web_fonts cont-sign"
                                    >
                                      sign in
                                    </a>
                                  </p>
                                )}
                                {/* <p className="alredy-sub ">
                        already a subscriber?
                        <a href="/web/login" className="web_fonts cont-sign">
                          signin
                        </a>
                      </p> */}
                              </div>
                            </div>
                          </Col>

                          <SubscriptionModal
                            show={showSubscriptionModal}
                            handleClose={handleCloseSubscriptionModal}
                          />
                        </Row>
                      </div>
                    )}
                  </Col>

                  <Col xs={12} md={4}>
                    <div className="skyblue-banner">
                      <img src={Skyblueimg} alt="banner" />
                    </div>
                    {isSubscribed || (isSubscribed && isTrialUsed) ? (
                      <>
                        {/* <div className="sub-now">
                  <img src={SubcribeBanner}  className="insight-driven"/>
                 </div> */}
                      </>
                    ) : (
                      <div
                        className="content-detail-sub-banner"
                        style={{
                          "--sub-banner-bg-color": "white",
                          "--sub-banner-text-color": "#fffdfd ",
                          "--sub-banner-button-bg": "#004aad",
                          "--sub-banner-border-color": "#D1D1D1",
                        }}
                      >
                        <Subscriptionimage
                          Subscribenow={Subscribenow}
                          Subscriptiontext={Subscriptiontext}
                        />
                      </div>
                    )}
                    {((!isLoggedIn && limitedRecentListArray?.length !== 0) || (isLoggedIn && finalPrefList?.length !== 0)) &&
                      <>
                        <div className="recent-heading-foru">
                          <h2 className="web_fonts ">for You</h2>
                          <hr className="hr-line" />
                        </div>
                        <h5 className="tailered web_fonts color-black">
                          tailored reading selections
                        </h5>
                      </>
                    }
                    {isLoggedIn ? (
                      // User is logged in, render dynamic content
                      // ForYouUserPrefrence?.result
                      //   ?.slice(startIndex, EndIndex)?.map((newsItem, index) => (

                      finalPrefList?.map((newsItem, index) => (
                        <Col
                          xs={12}
                          className="topic-column"
                          key={index}
                          onClick={() => handleSectionClick(newsItem)}
                        >
                          <div className="topic-column-display">
                            <div className="topic-column-dis-flex">
                              <p
                                style={{ color: "#004aad", fontWeight: "bold" }}
                                className="web_fonts p-left"
                              >
                                {" "}
                                {newsItem?.Category}
                              </p>
                              <h6 className="content-heading web_fonts">
                                {newsItem?.Title?.length > 50
                                  ? `${newsItem?.Title?.slice(0, 50)}...`
                                  : newsItem?.Title}
                              </h6>
                              <p className="disabled-text web_fonts">
                                by
                                <span
                                  className="name-of web_fonts"
                                  style={{ textTransform: "none" }}
                                >
                                  {newsItem?.Created_By}
                                </span>
                                {`${formatDate(
                                  DetailContent?.result?.Approved_On
                                )}`}
                              </p>
                            </div>
                            <div className="topic-column-dis-image ">
                              <img
                                src={newsItem?.Article_Images}
                                alt={`Image ${index + 2}`}
                                className="topic-column-dis-image-img"
                              />
                            </div>
                          </div>
                          <hr className="hr-line-recent" />
                        </Col>
                      ))
                    ) : (
                      // User is not logged in,
                      <>
                        {limitedRecentListArray &&
                          limitedRecentListArray?.map((story, index) => (
                            <Col
                              key={index}
                              xs={12}
                              className="topic-column"
                              onClick={() => handleSectionClick(story)}
                            >
                              <div className="topic-column-display">
                                <div className="topic-column-dis-flex">
                                  <h6 className="topic-column-heading web_fonts">
                                    {" "}
                                    {story?.Category}
                                  </h6>
                                  <h6 className="content-heading web_fonts">
                                    {story?.Title?.length > 70
                                      ? `${story?.Title?.slice(0, 70)}...`
                                      : story?.Title}
                                  </h6>
                                  <p className="disabled-text web_fonts">
                                    {/* by<span className="name-of web_fonts">{newsItem?.Created_By}</span> */}
                                    by
                                    <span
                                      className="name-of web_fonts"
                                      style={{ textTransform: "none" }}
                                    >
                                      {story?.Created_By}
                                    </span>
                                    {`${formatDate(
                                      DetailContent?.result?.Approved_On
                                    )}`}
                                  </p>
                                </div>
                                <div className="topic-column-dis-image ">
                                  <img
                                    src={story?.Article_Images}
                                    alt={`Image ${index + 1}`}
                                    className="topic-column-dis-image-img"
                                  />
                                </div>
                              </div>
                              <hr className="hr-line-recent" />
                            </Col>
                          ))}
                      </>
                    )}
                    <Col style={{ paddingBottom: "20px", paddingTop: "10px" }}>
                      {/* <NewsletterSection /> */}
                      <div>
                        <NewsletterSectionCommon
                          NewsletterText={NewsletterText}
                          NewsletterMsgImg={NewsletterMsgImg}
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
              </>
            }
          </>
        )}
        <Modal
          show={isOpen}
          onHide={hideModal}
          style={{
            display: isOpen ? "block" : "none",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#38b6ff85",
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Alert</h5>
              <button
                type="button"
                className="close sub-alert"
                onClick={hideClose}
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                textAlign: "center",
                fontWeight: "bold", // Make the content body bold
                lineHeight: "3", // Adjust line height
              }}
            >
              <p style={{ marginTop: "10px" }}>
                please log in before you subscribe
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="login-alert" onClick={hideModal}>
                log in
              </button>
              <button type="button" className="close-alert" onClick={hideClose}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      </>
    </Container>
  )
}

export default ContentDetails
