import React from "react";
import { Container } from "reactstrap";

export default function AbandonedCartIndex() {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h3 className="text-center mt-5">Coming Soon</h3>
                </Container>
            </div>
        </React.Fragment>
    )
};