import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from '../../common/TableContainer';
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { payoutsActions } from '../../store/payouts/actions';
import { commonActions } from "../../store/common/actions";

export default function payoutDetailsIndex() {
    document.title = "Payouts | Allen Dreyfus";

    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { adminPageLoader } = useSelector(state => state.commonReducer);
    const { adminPayoutsDetail } = useSelector(state => state.payoutsReducer);

    const Id = state?._id;

    useEffect(() => {
        dispatch({ type: payoutsActions.GET_ADMIN_PAYOUT_DETAIL, payload: Id });
    }, [Id]);

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Payouts"
                            titleLink="/payouts"
                            breadcrumbItem="details"
                        />
                        <h4 className="card-title mb-4">Payout Details</h4>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Contributor Name</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminPayoutsDetail?.Contributor_Name}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Payment Amount</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminPayoutsDetail?.Payment_Amount}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Mode Of Payment</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminPayoutsDetail?.Mode_Of_Payment}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Paid On
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminPayoutsDetail?.Paid_On}
                                            disabled
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate('/payouts')}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
};