import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, CardTitle, Table } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../store/admin/actions";
import { commonActions } from "../../store/common/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import profileicon from '../../assets/images/dashboard/user3.png';
import UpdateCreatorStatusModal from "components/commonModals/updateCreatorStatusModal";

export default function AdminDetailsIndex() {

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { adminDetails } = useSelector(state => state.adminReducer);

    const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer);

    const Id = state?._id;
    useEffect(() => {
        dispatch({ type: adminActions.GET_ADMIN_DETAILS, payload: Id });
    }, [Id]);

    const handleShowUpdateStatusPopup = (e) => {
        e.preventDefault();
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { TypeOfModal: 'BLOCKADMIN', isOpen: true, data: { modalType: adminDetails?.Status } }
        });
    };

    const handleCloseModel = () => {
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { TypeOfModal: null, isOpen: false, data: null }
        });
    };

    const handleUpdateStatus = (type) => {
        let data;
        (type === 'Approved') ? data = { Status: "Blocked" } : data = { Status: "Approved" };
        dispatch({
            type: adminActions.UPDATE_ADMIN_STATUS,
            payload: { Id: adminDetails?._id, data: data }
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Admin"
                            titleLink="/admin"
                            breadcrumbItem="details"
                        />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="ms-3">
                                                <img
                                                    src={adminDetails?.Profile_Image ?? profileicon}
                                                    alt=""
                                                    className="avatar-md rounded-circle img-thumbnail"
                                                />
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted">
                                                    <h5>{adminDetails?.Name}</h5>
                                                    <p className="mb-1">{adminDetails?.Email}</p>
                                                    {/* <p className="mb-0">Id : {adminDetails?._id}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <CardTitle className="card-title mb-4">Admin Details</CardTitle>
                                <Form className="form-horizontal">
                                    <div className="form-group mb-3">
                                        <Label className="form-label">First Name</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminDetails?.Name}
                                            disabled
                                        />
                                    </div>

                                    <div className="form-group mb-3">
                                        <Label className="form-label">Last Name</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminDetails?.Last_Name}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Email</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminDetails?.Email}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Contact Number</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminDetails?.Contact_Number}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Status</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminDetails?.Status}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Created On</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminDetails?.Created_On}
                                            disabled
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="me-3"
                                            onClick={() => navigate('/edit-admin-details', { state: adminDetails })}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            type="button"
                                            color={adminDetails?.Status === "Approved" ? "warning" : "success"}
                                            className="me-3"
                                            onClick={handleShowUpdateStatusPopup}
                                        >
                                            {adminDetails?.Status === 'Approved' ? 'Block' : 'Unblock'}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate('/admin')}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                        {/* <Card>
                            <CardBody>
                                <CardTitle className="mb-4">Responsibilities</CardTitle>
                                <Table responsive bordered>
                                    <thead className="text-center align-middle">
                                        <tr>
                                            <th>Role</th>
                                            <th>View</th>
                                            <th>Add</th>
                                            <th>Update</th>
                                            <th>Suspend/ Delete/ Block</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center align-middle">
                                        {adminDetails?.Responsibilities?.map((val, i) =>
                                            <tr key={i}>
                                                <th>{val?.TabName}</th>
                                                <td>
                                                    {val?.View !== '-' ?
                                                        <AccessSelect value={val?.View} /> : "-"
                                                    }
                                                </td>
                                                <td>
                                                    {val?.Add !== '-' ?
                                                        <AccessSelect value={val?.Add} /> : "-"
                                                    }
                                                </td>
                                                <td>
                                                    {val?.Update !== '-' ?
                                                        <AccessSelect value={val?.Update} /> : '-'
                                                    }
                                                </td>
                                                <td>
                                                    {val?.Delete !== '-' ?
                                                        <AccessSelect value={val?.Delete} /> : "-"
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card> */}
                    </Container>
                }
                <UpdateCreatorStatusModal
                    modal={showPopup?.TypeOfModal === 'BLOCKADMIN' && showPopup?.isOpen}
                    data={adminDetails}
                    toggle={handleCloseModel}
                    title={showPopup?.data?.modalType === 'Approved' ?
                        "Block Admin?" : "Unblock Admin?"}
                    message={showPopup?.data?.modalType === 'Approved' ?
                        `Are you sure you want to Block the Admin (${adminDetails?.Name})?` :
                        `Are you sure you want to Unblock the Admin (${adminDetails?.Name})?`}
                    handleSubmit={handleUpdateStatus}
                    type={showPopup?.data?.modalType}
                />
            </div>
        </React.Fragment>
    )
};


function AccessSelect({ value }) {

    return (
        <div>
            {value === 'Yes' ?
                <i className="mdi mdi-check-circle mdi-24px text-success" /> :
                <i className="mdi mdi-close-circle mdi-24px text-danger" />
            }
        </div>
    )
};