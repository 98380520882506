import { payoutsActions } from "./actions";
import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import { commonActions } from "../common/actions";
import { creatorDashboardActions } from "../creatorDashboard/actions";

export const payoutSagas = function* () {
    yield all([
        yield takeEvery(payoutsActions.GET_ADMIN_PAYOUTS_LIST, getAdminPayoutsList),
        yield takeEvery(payoutsActions.GET_ADMIN_PAYOUT_DETAIL, getAdminPayoutDetail),
        yield takeEvery(payoutsActions.ADD_ADMIN_PAYOUT, addAdminPayout),
    ])
}

// Get Payouts List
const getAdminPayoutsList = function* () {
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/payouts/payloutsList`)
        );
        yield put({
            type: payoutsActions.SET_ADMIN_PAYOUTS_LIST,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Get Payouts Detail
const getAdminPayoutDetail = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/payouts/singlePayouts/${payload}`)
        );
        yield put({
            type: payoutsActions.SET_ADMIN_PAYOUT_DETAIL,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : null
        })
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
}

//add Payout
const addAdminPayout = function* (data) {
    const { payload } = data;
    const { reset } = payload;
    const { navigate } = payload;
    const Creator_Id = payload?.data?.Contributor_Id;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/payouts/addPayouts`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: payoutsActions.PAYOUT_RESPONSE,
            payload: result?.data
        })
        if (result?.data?.statusCode === 200) {
            reset();
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "success", message: result?.data?.message }
            });
            yield put({
                type: creatorDashboardActions.GET_CREATOR_WALLET_DETAILS,
                payload: Creator_Id,
            })
            yield delay(1000);
            navigate('/payouts')
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: result?.data?.message }
            });
        };
    } catch (err) {
        console.log(err);
    }
}


