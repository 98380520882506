export const dashboardActions = {
    GET_NEED_APPROVAL_LIST: 'GET_NEED_APPROVAL_LIST',
    GET_CUSTOMERS_LIST: 'GET_CUSTOMERS_LIST',
    GET_ADMIN_LIST: 'GET_ADMIN_LIST',
    GET_CONTENT_CREATORS_LIST: 'GET_CONTENT_CREATORS_LIST',
    GET_CONTENT_LIST: 'GET_CONTENT_LIST',

    GET_ADMIN_END_USER_COUNT: 'GET_ADMIN_END_USER_COUNT',
    SET_ADMIN_END_USER_COUNT: 'SET_ADMIN_END_USER_COUNT',

    GET_ADMIN_CONTENT_CREATOR_COUNT: 'GET_ADMIN_CONTENT_CREATOR_COUNT',
    SET_ADMIN_CONTENT_CREATOR_COUNT: 'SET_ADMIN_CONTENT_CREATOR_COUNT',

    GET_ADMIN_ADMINCOUNT: 'GET_ADMIN_ADMINCOUNT',
    SET_ADMIN_ADMINCOUNT: 'SET_ADMIN_ADMINCOUNT',

    GET_ADMIN_TOTAL_SUBSCRIPTION_COUNT: 'GET_ADMIN_TOTAL_SUBSCRIPTION_COUNT',
    SET_ADMIN_TOTAL_SUBSCRIPTION_COUNT: 'SET_ADMIN_TOTAL_SUBSCRIPTION_COUNT',

    GET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT: 'GET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT',
    SET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT: 'SET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT',

    GET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT: 'GET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT',
    SET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT: 'SET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT',

    GET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT: 'GET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT',
    SET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT: 'SET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT',

    GET_ADMIN_CONTENT_GRAPH_COUNT: 'GET_ADMIN_CONTENT_GRAPH_COUNT',
    SET_ADMIN_CONTENT_GRAPH_COUNT: 'SET_ADMIN_CONTENT_GRAPH_COUNT',


};