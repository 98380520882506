import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminPageLoader from "../../common/adminPageLoader";
import { WithdrawActions } from '../../store/withdrawRequest/actions';

export default function CreatorWithdrawRequestDetailIndex() {
    document.title = "Widthdraw | Allen Dreyfus";
    const { state } = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [filterData, setFilterData] = useState('');

    const { adminPageLoader } = useSelector(state => state.commonReducer);
    const { creatorWithdrawDetail } = useSelector(state => state.withdrawReducer);

    useEffect(() => {
        if (creatorWithdrawDetail && creatorWithdrawDetail?.statusCode === 200) {
            setFilterData(creatorWithdrawDetail?.result)
        }
    }, [creatorWithdrawDetail])

    const Id = state?._id;

    useEffect(() => {
        dispatch({ type: WithdrawActions.GET_WITHDRAW_REQUEST_DETAIL, payload: Id });
    }, [Id]);

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        {/* <Breadcrumb
                            title="Withdraw-request"
                            titleLink="/withdrawal-request"
                            breadcrumbItem="details"
                        />
                        <h4 className="card-title mb-4">Details</h4> */}
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <Row>
                                        <p className='pageTitle'>WITHDRAWAL REQUEST DETAILS</p>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.Creator_Name}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.Email}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Contact number</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.ContactNumber}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Bank account number</Label>

                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.BankAccountNumber}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Bank Name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.BankName}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Branch</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.Branch}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Name as per the bank account</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.NameAsPerBankAccount}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label ibanclass">IBAN number</Label>

                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.IBAN_Number}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Total Amount</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.TotalAmount}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Received Amount</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.PaidAmount}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Pending Amount</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.PendingAmount}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Requested Amount for Withdraw</Label>

                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.WithdrawAmount}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Status</Label>

                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.Status}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Requested Date</Label>

                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={filterData?.Submitted_On}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        {filterData?.Status === 'Rejected' &&
                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Rejection Reason</Label>
                                                    <Input
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Enter User Name"
                                                        type="textarea"
                                                        rows={3}
                                                        defaultValue={filterData?.Rejection_Reason}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        }
                                        {filterData?.Status === 'Processed' &&
                                            <>
                                                <Col sm="6">
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Transaction Id</Label>

                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            defaultValue={filterData?.TransactionId}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Mode of Payment</Label>

                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            defaultValue={filterData?.ModeOfPayment}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Requested amount paid on</Label>

                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            defaultValue={filterData?.RequestedAmountPaidOn}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </>}
                                        <div className="mt-4">
                                            <Button
                                                type="button"
                                                color="danger"
                                                onClick={() => navigate('/withdrawal-request')}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
};