import React, { useMemo, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { actions } from "store/Countries/actions"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import TableContainer from "../../common/TableContainer"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { FaTrash, FaPlus } from "react-icons/fa"
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"
import CustomPagination from "common/CustomPagination";

const Countrylistindex = () => {
  document.title = "Market Focus | Allen Dreyfus"
  const dispatch = useDispatch()
  const { countryList } = useSelector(state => state.CountryReducer)
  const { buttonLoader } = useSelector(state => state.commonReducer);
  const [countryListOptions, setCountryListOptions] = useState([])
  const [newCountry, setNewCountry] = useState("")
  const [showInput, setShowInput] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [countryToDelete, setCountryToDelete] = useState(null)
  const [showErr, setShowErr] = useState('')
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch({ type: actions.GET_COUNTRY_LIST })
  }, [])

  useEffect(() => {
    if (countryList?.result?.length !== 0) {
      setCountryListOptions(countryList?.result)
    }
  }, [countryList])

  const handleAddCountry = () => {
    const lowercaseCountry = newCountry.toLowerCase();
    if (lowercaseCountry.trim() !== "") {
      dispatch({
        type: actions.GET_ADD_COUNTRY_NAMES,
        payload: { Country_Name: lowercaseCountry },
      })
      setNewCountry("")
      setShowErr("")
    } else {
      setShowErr("required")
    }
  }

  const handleDeleteCountry = cellProps => {
    const data = cellProps.row.original
    setCountryToDelete(data)
    toggleDeleteModal()
  }

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen)
  }

  const handleConfirmDelete = () => {
    if (countryToDelete) {
      dispatch({
        type: actions.GET_DELETE_COUNTRY_NAMES,
        payload: { CountryId: countryToDelete?._id },
      })

      setCountryToDelete(null)
      toggleDeleteModal()
      setShowErr('')
    }
  }

  const handleCancelDelete = () => {
    setCountryToDelete(null)
    toggleDeleteModal()
  }

  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "Country_Name",
      filterable: true,
    },
    {
      Header: "Action",
      Cell: cellProps => (
        <div>
          <FaTrash
            className="mdi fa-trash font-size-18 text-danger"
            onClick={() => handleDeleteCountry(cellProps)}
          />
        </div>
      ),
    },
  ])

  return (
    <React.Fragment>
      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the {''}
          {`"${countryToDelete?.Country_Name}"`} {''} {''}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmDelete}>
            {buttonLoader ? <Spinner color="light" size='sm' /> : 'Yes'}
          </Button>
          <Button color="secondary" onClick={handleCancelDelete}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>

              <div className="d-flex justify-content-start">
                <h4>Market Focus</h4>
              </div>
            </Col>

            <Col>
              <div className="d-flex justify-content-end mt-3">
                {showInput && (
                  <>
                    <div className="d-flex align-items-center ml-3">
                      <input
                        type="text"
                        className={(showErr && !newCountry) ? "form-control me-2 required-field" : "form-control me-2"}
                        placeholder="add market focus"
                        value={newCountry}
                        onChange={e => setNewCountry(e.target.value)}
                      />
                      <Button color="primary addcountry" onClick={handleAddCountry}>
                        {buttonLoader ? <Spinner color="light" size='sm' /> :
                          <>
                            <FaPlus className="me-1 " /> Add
                          </>
                        }
                      </Button>
                    </div>
                  </>
                )}
                {!showInput && (
                  <Button color="primary" onClick={() => setShowInput(true)}>
                    <FaPlus className="me-2" /> add Country
                  </Button>
                )}
              </div>
              {/* <p className="text-danger text-right">{showErr}</p> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="Corporate Subscription">
                <CardBody>
                  {countryListOptions && countryListOptions.length > 0 ? (
                    <React.Fragment>
                      <TableContainer
                        columns={columns}
                        data={countryListOptions || []}
                        isGlobalFilter={true}
                        isAddCustList={false}
                        isPagination={true}
                        customPageSize={itemsPerPage}
                        tableClass="align-middle table-nowrap"
                        isShowingPageLength={false}
                        iscustomPageSizeOptions={true}
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </React.Fragment>
                  ) : (
                    <div className="no-records">No records found</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Countrylistindex
