import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Logo from "../../assets/webImages/png-logo-white.webp"
import { FaSquareFacebook } from "react-icons/fa6"
import { FaLinkedin } from "react-icons/fa"
import { FaSquareXTwitter } from "react-icons/fa6"
import SubscriptionModal from "webComponents/modals/SubscriptionModal"
import ReturnToTop from "../../webComponents/Common/7604589.png"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const Footer = () => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const navigate = useNavigate()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)

  const handleSubscribeClick = () => {
    setShowSubscriptionModal(true)
  }

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false)
  }

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const handleScroll = () => {
      // Show the scroll-to-top button when scrolling down
      setShowScrollToTop(window.scrollY > 100)
    }

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll)

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handlebacktohome = () => {
    navigate("/")
  }

  return (
    <div className="footer-container">
      <footer>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <a href="/">
                <img src={Logo} alt="Your Logo" className="footer-logo" />
              </a>
            </Col>
          </Row>

          <Row className="footer-row">
            <Col xs={12} md={6} className="footer-col">
              <h5 className="footer-email web_fonts footer-address"> Allen Dreyfus Limited, <br /> 35/37 Ludgate Hill, <br /> London EC4M 7JN<br />+44 (0) 330 828 5058</h5>
            </Col>

            <Col
              xs={12}
              md={6}
              // className="d-flex flex-wrap justify-content-between mob-ft-link"
              className="d-flex flex-wrap justify-content-between mob-ft-link col-md-6 col-12"
            >
              <a href="/terms-of-use" className="custom-nav-link  footer-href">
                {/* terms of service */}
                terms of use
              </a>
              <a href="/privacy-notice" className="custom-nav-link footer-href">
                {/* privacy policy */}
                privacy notice
              </a>
              {/* <a
                // href="#"
                className="custom-nav-link footer-href"
              >
                about Us
              </a> */}
              <a
                href="/AdvertiseWithUs"
                className="custom-nav-link footer-href"
              >
                advertise with us
              </a>
              {tokenResponse?.statusCode === 200 &&
                tokenResponse?.result?.IsSubscribed === "Yes" &&
                tokenResponse?.result?.Stripe_Subscription_Id ? null : (
                <a
                  href="#"
                  className="custom-nav-link footer-href"
                  onClick={handleSubscribeClick}
                >
                  subscribe
                </a>)}
              <a href="/ContactUs" className="custom-nav-link footer-href">
                contact us
              </a>
            </Col>
          </Row>
          {/* Sticky scroll-to-top button */}
          {showScrollToTop && (
            <div className="scroll-to-top-button" onClick={handleScrollToTop}>
              <img className="scroll-image" src={ReturnToTop} />
            </div>
          )}

          <hr className="#" />

          <Row className="footer-row">
            <Col xs={12} md={6}>
              <p className="footer-copyright custom-nav-link">
                &copy; {new Date().getFullYear()} All Rights Reserved
              </p>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-end footer-social-icons"
            >
              <div>
                <FaSquareFacebook className="mr-2" onClick={handlebacktohome} />
                <FaLinkedin className="mr-2" onClick={handlebacktohome} />
                <FaSquareXTwitter className="mr-2" onClick={handlebacktohome} />
              </div>
            </Col>
            <SubscriptionModal
              show={showSubscriptionModal}
              handleClose={handleCloseSubscriptionModal}
            />
          </Row>
        </Container>
      </footer>
    </div>
  )
}

export default Footer
