// webHome/reducer.js
import { WebHomePageActions } from "./actions"

const initialState = {
  recentStories: [],
  limitedRecentContent: [],
  PoliticsCategoryNews: [],
  ForYouUserPrefrence: [],
  LimitedForUList:[],
  PopularNews: [],
  CountrylistStories: [],
  ContentByRecentTopic: [],
  RecentTopicNews: [],
  ProfileUserInfo: [],
  EditUserInformation: [],
  PodcastList: [],
  ForYouSpecificContent: [],
  PodcastListForHomePage: [],
  UserChangePassword: [],
}

export const WebHomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case WebHomePageActions.SET_WEBHOME_RECENTSTORIES_DETAILS:
      return {
        ...state,
        recentStories: action.payload,
      }
    case WebHomePageActions.SET_LIMITED_RECENT_CONTENT_LIST:
      return {
        ...state,
        limitedRecentContent: action.payload,
      }
    case WebHomePageActions.SET_WEBHOME_POLITICS_CATEGORY_DETAILS:
      return {
        ...state,
        PoliticsCategoryNews: action.payload,
      }

    case WebHomePageActions.SET_WEBHOME_FORYOU_NEWS_DETAILS:
      return {
        ...state,
        ForYouUserPrefrence: action.payload,
      }

    case WebHomePageActions.SET_WEBHOME_POPULARNEWS_DETAILS:
      return {
        ...state,
        PopularNews: action.payload,
      }

    case WebHomePageActions.SET_WEBHOME_COUNTRYLIST_DETAILS:
      return {
        ...state,
        CountrylistStories: action.payload,
      }

    case WebHomePageActions.SET_WEBHOME_CONTENTBY_RECENT_TOPIC:
      return {
        ...state,
        ContentByRecentTopic: action.payload,
      }
    case WebHomePageActions.SET_WEBHOME_RECENTTOPIC_NEWS_DETAILS:
      return {
        ...state,
        RecentTopicNews: action.payload,
      }

    case WebHomePageActions.SET_WEBHOME_PROFILE_USERINFO_DETAILS:
      return {
        ...state,
        ProfileUserInfo: action.payload,
      }

    case WebHomePageActions.SET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS:
      return {
        ...state,
        EditUserInformation: action.payload,
      }
    case WebHomePageActions.SET_WEBHOME_PODCAST:
      return {
        ...state,
        PodcastList: action.payload,
      }

    case WebHomePageActions.SET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME:
      return {
        ...state,
        ForYouSpecificContent: action.payload,
      }
      case WebHomePageActions.SET_LIMITED_FORU_LIST:
        return {
          ...state,
          LimitedForUList: action.payload, // list based on user preference
        }

    case WebHomePageActions.SET_WEBHOME_SPECIFIC_PODCAST_HOME:
      return {
        ...state,
        PodcastListForHomePage: action.payload,
      }

    case WebHomePageActions.WEB_USER_SET_CHANGE_PASSWORD:
      return {
        ...state,
        UserChangePassword: action.payload,
      }

    default:
      return state
  }
}
