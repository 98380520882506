import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PublicRoute(props) {
    // const { pathname } = useLocation()
    const { isAuthenticated, route } = useSelector(state => state.userAuthReducer)
    return (
        <>

            {
                // (isAuthenticated) ?
                (localStorage.getItem('GFX_TOKEN') || localStorage.getItem('GFX_TOKEN2') || localStorage.getItem('GFX_TOKEN3')) ? 

                    (
                        <Navigate to={route} />
                    )
                    :
                    (props.children)
            }

 {/* {
                // ((sessionStorage.getItem('GFX_TOKEN') && pathname?.slice(0, 6) === '/login')||(sessionStorage.getItem('GFX_TOKEN2') && pathname?.slice(0, 6) === '/login')) ? (
                (sessionStorage.getItem('GFX_TOKEN')) ? (
                    // <Navigate to={'/admin/dashboard'} />
                    <Navigate to={route} />
                )
                    :
                    (sessionStorage.getItem('WEB_TOKEN') && pathname?.slice(0, 6) !== '/login') ? (
                        <Navigate to={'/'} />
                    )
                        :
                        (
                            props.children
                        )
            } */}
        </>
    )
}