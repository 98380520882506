import React from 'react'
import Login from '../../webComponents/webLogin/index'

export default function WebLogin() {
    document.title = "Login";


    return (
        <div>
            <Login />
        </div>
    )
}