import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, CardTitle, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "../../store/blogContent/actions";
import { commonActions } from "../../store/common/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import profileicon from '../../assets/images/dashboard/user3.png';
import ApproveOrRejectContentModal from "components/commonModals/ApproveOrRejectContentModal";

export default function CreatorPitchIndex() {
    document.title = 'Admin-Pitch Details'
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    var [typeOfContent, setTypeOfContent] = useState('');
    var [title, setTitle] = useState('');
    var [Country, setCountry] = useState('');
    var [Category, setCategory] = useState('');
    var [SubCategory, setSubCategory] = useState('');
    var [pitchStory, setPitchStory] = useState('');
    var [Impacts, setImpacts] = useState('');
    var [RejectionReason, setRejectionReason] = useState('');
    var [rejectError, setRejectError] = useState('');
    var [PitchApproveReason, setPitchApproveReason] = useState('');
    const [approveReason, setApproveReason] = useState('');
    const [rejectReason, setRejectReason] = useState('');
    const [pitchStatus, setPitchStatus] = useState('');
    const [pitchStatusUpdatedBy, setPitchStatusUpdatedBy] = useState('');
    const [checkStatus, setCheckStatus] = useState(false)
    const [modalType, setModalType] = useState('')
    const [StatusCheckFromModal, setStatusCheckFromModal] = useState(false)

    const { creatorPitchDetail } = useSelector(state => state.BlogContentReducer);
    const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer);
    const { superAdminTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer);

    const Id = (state?.data?.NotificationType === "Pitch Submitted For Approval") ? state?.data?.ContentID : state?.data?._id;

    useEffect(() => {
        if (creatorPitchDetail) {
            setTypeOfContent(creatorPitchDetail?.result?.TypeOfContent);
            setTitle(creatorPitchDetail?.result?.Title);
            setCountry(creatorPitchDetail?.result?.Country?.map(single => single).join(', '));
            setCategory(creatorPitchDetail?.result?.Category[0]);
            setSubCategory(creatorPitchDetail?.result?.Sub_Category?.map(single => single).join(', '));
            setPitchStory(creatorPitchDetail?.result?.Pitch_story);
            setImpacts(creatorPitchDetail?.result?.Impacts)
            setRejectionReason(creatorPitchDetail?.result?.Pitch_Rejection_Reason)
            setPitchApproveReason(creatorPitchDetail?.result?.Remarks_For_Pitch)
            setPitchStatus(creatorPitchDetail?.result?.Pitch_Status)
            setPitchStatusUpdatedBy(creatorPitchDetail?.result?.Pitch_Status_Updated_By || '')
        }
    }, [creatorPitchDetail])


    useEffect(() => {
        dispatch({ type: BlogContentActions.GET_CREATOR_PITCH_DETAIL, payload: Id });
    }, [Id, checkStatus, StatusCheckFromModal]);

    const handleShowModel = (modalTypeClicked) => {
        setModalType(modalTypeClicked)
        setCheckStatus(true)
        dispatch({ type: BlogContentActions.GET_CREATOR_PITCH_DETAIL, payload: Id });
    };

    const handleCloseModel = () => {
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { TypeOfModal: null, isOpen: false, data: null }
        });
    };

    const handleInputChange = (event) => {
        setApproveReason(event.target.value);
    };

    const handleRejectChange = (event) => {
        setRejectReason(event.target.value);
    };

    const handleApprovalStatus = () => {
        setStatusCheckFromModal(true)
        setCheckStatus(false)
        setModalType('')
        dispatch({ type: BlogContentActions.GET_CREATOR_PITCH_DETAIL, payload: Id });
    };

    //To check the pitch approved status before approve
    useEffect(() => {
        if (checkStatus === true && (creatorPitchDetail?.result?.Pitch_Status === "Approved" || creatorPitchDetail?.result?.Pitch_Status === "Rejected") && (modalType === 'Approve' || modalType === 'Reject')) {//approve button
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Pitch status already updated" }
            })
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (checkStatus === true && creatorPitchDetail?.result?.Pitch_Status === "New" && (modalType === 'Approve' || modalType === 'Reject')) {
            dispatch({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: 'APPROVEORREJECTCONTENT', isOpen: true, data: { modalType: modalType } }
            });
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (StatusCheckFromModal === true && creatorPitchDetail?.result?.Pitch_Status === "New") {//model approve button
            if ((rejectReason === '' || rejectReason === undefined || rejectReason === null) && showPopup?.data?.modalType === 'Reject') {
                setRejectError('please enter the reason for rejection');
            } else {
                setRejectError('');
                let Pitch_Status_Updated_By;
                if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                    Pitch_Status_Updated_By = {
                        Name: superAdminTokenResponse?.result?.Name,
                        Email: superAdminTokenResponse?.result?.Email,
                        Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                    }
                } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                    Pitch_Status_Updated_By = {
                        Name: adminTokenResponse?.result?.Name,
                        Email: adminTokenResponse?.result?.Email,
                        Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                    }
                }
                dispatch({
                    type: BlogContentActions.ADMIN_CHANGE_PITCH_STATUS,
                    payload: {
                        ContentId: creatorPitchDetail?.result?._id,
                        data: {
                            Pitch_Status: showPopup?.data?.modalType === 'Approve' ? 'Approved' : 'Rejected',
                            Remarks_For_Pitch: approveReason,
                            Pitch_Rejection_Reason: rejectReason,
                            Pitch_Status_Updated_By: Pitch_Status_Updated_By
                        },
                        type: state?.type,
                        navigate: navigate
                    }
                });
                setStatusCheckFromModal(false)
                setCheckStatus(false)
            }
        } else if (StatusCheckFromModal === true && (creatorPitchDetail?.result?.Pitch_Status === "Approved" || creatorPitchDetail?.result?.Pitch_Status === "Rejected")) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Pitch status already updated" }
            })
            handleCloseModel()
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        }
    }, [creatorPitchDetail])

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Pitch"
                            titleLink={`/admin/pitch/${state?.type}`}
                            breadcrumbItem="details"
                        />
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Type of Content</Label>
                                            <Input
                                                name="TypeOFContent"
                                                className="form-control"
                                                placeholder="Enter Type of Content"
                                                type="text"
                                                defaultValue={typeOfContent}
                                                disabled
                                            />
                                        </div>
                                    </Col>

                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">market focus</Label>
                                            <Input
                                                name="Country"
                                                className="form-control"
                                                placeholder="Enter Type of Content"
                                                type="text"
                                                defaultValue={Country}
                                                disabled
                                            />
                                        </div>
                                    </Col>

                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Title</Label>
                                            <Input
                                                name="Title"
                                                className="form-control proName"
                                                placeholder="Enter Title"
                                                type="text"
                                                defaultValue={title}
                                                disabled
                                            />
                                        </div>
                                    </Col>

                                    <Col className="mb-4" md={creatorPitchDetail?.result?.Category[0] === "Frontier Markets" || creatorPitchDetail?.result?.Category[0] === "Recent Topic" ? "6" : "12"}>
                                        <div className="form-group">
                                            <Label className="form-label">Category</Label>
                                            <Input
                                                name="Category"
                                                className="form-control"
                                                placeholder="Enter Category"
                                                type="text"
                                                defaultValue={Category}
                                                disabled
                                            />
                                        </div>
                                    </Col>

                                    <Col md="6" className="mb-4">
                                        {
                                            creatorPitchDetail?.result?.Sub_Category?.length !== 0 &&
                                            <div className="form-group">
                                                <Label className="form-label">{creatorPitchDetail?.result?.Category[0]}</Label>
                                                <Input
                                                    name="Category"
                                                    className="form-control"
                                                    placeholder="Enter Category"
                                                    type="text"
                                                    defaultValue={SubCategory}
                                                    disabled
                                                />
                                            </div>
                                        }
                                    </Col>

                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">What is the story?</Label>
                                            <div className="form-control form-control-textarea storyfont" dangerouslySetInnerHTML={{ __html: pitchStory }} />
                                        </div>
                                    </Col>

                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Impact on Markets</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="textarea"
                                                rows={5}
                                                defaultValue={Impacts}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    {RejectionReason &&
                                        <Col md="12" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Rejection Reason</Label>
                                                <Input
                                                    name="Title"
                                                    className="form-control"
                                                    placeholder="Enter Rejection Reason"
                                                    type="textarea"
                                                    rows={3}
                                                    defaultValue={RejectionReason}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    }
                                    {PitchApproveReason &&
                                        <Col md="12" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Remarks for Pitch</Label>
                                                <Input
                                                    name="Title"
                                                    className="form-control"
                                                    placeholder="Enter Rejection Reason"
                                                    type="textarea"
                                                    rows={3}
                                                    defaultValue={PitchApproveReason}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    }
                                    {creatorPitchDetail?.result?.Pitch_Status_Updated_By &&
                                        <Col md="12" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Pitch status updated By</Label>
                                                <Input
                                                    name="Pitch Status"
                                                    className="form-control"
                                                    type="textarea"
                                                    rows={1}
                                                    defaultValue={`${creatorPitchDetail?.result?.Pitch_Status_Updated_By?.Name} - ${creatorPitchDetail?.result?.Pitch_Status_Updated_By?.Type_Of_User}`}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    }
                                    <div className="mt-5">
                                        {creatorPitchDetail?.result?.Pitch_Status === 'New' &&
                                            <>
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="me-3"
                                                    onClick={() => handleShowModel('Approve')}
                                                >
                                                    Approve
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="warning"
                                                    className="me-3"
                                                    onClick={() => handleShowModel('Reject')}
                                                >
                                                    Reject
                                                </Button>
                                            </>
                                        }

                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate(state?.data?.Pitch_Status === 'New' ? `/admin/pitch/needApproval` : state?.data?.Pitch_Status === 'Approved' ? `/admin/pitch/Approved` : `/admin/pitch/Rejected`)}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                }

                <ApproveOrRejectContentModal
                    modal={showPopup?.TypeOfModal === 'APPROVEORREJECTCONTENT' && showPopup?.isOpen}
                    data={creatorPitchDetail}
                    toggle={handleCloseModel}
                    title={showPopup?.data?.modalType === 'Approve' ?
                        "Approve" : "Reject"}
                    buttonName={showPopup?.data?.modalType === 'Approve' ?
                        "Approve" : "Reject"}
                    message={showPopup?.data?.modalType === 'Approve' ?
                        <Input
                            type="textarea"
                            placeholder="Enter your remarks here..."
                            value={approveReason}
                            onChange={handleInputChange}
                        /> :
                        <>
                            <Input
                                type="textarea"
                                placeholder="Enter your reject reason here..."
                                value={rejectReason}
                                onChange={handleRejectChange}
                            />
                            <p className="text-danger text-size pt-2">{rejectError}</p>
                        </>
                    }
                    handleSubmit={handleApprovalStatus}
                    type={showPopup?.data?.modelType}
                />
            </div>
        </React.Fragment >
    )
}