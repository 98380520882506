import EditAdminDetailsIndex from "components/editAdminDetails";
import React from "react";

export default function EditAdminDetails() {

    return (
        <div>
            <EditAdminDetailsIndex />
        </div>
    )
}