import React from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

const MonthlyEarning = () => {
    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-4">Monthly Earning</CardTitle>
                <Row>
                    <Col sm="6">
                        <p className="text-muted">This month</p>
                        <h3>$34,252</h3>
                        <br />
                    </Col>
                    <Col sm='6'>
                        <p className="text-muted">Previous month</p>
                        <h3>$43,880</h3>
                        <br />
                    </Col>
                    <Col sm='12'>
                        <p className="text-muted">
                            <span className="text-success me-2">
                                {" "}
                                12% <i className="mdi mdi-arrow-up"></i>{" "}
                            </span>{" "}
                            From previous month
                        </p>
                    </Col>
                    <Col sm='12'>
                        <div>
                            <Link
                                to="#"
                                className="btn btn-primary waves-effect waves-light btn-sm"
                            >
                                View More <i className="mdi mdi-arrow-right ms-1"></i>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <br />
                <p className="text-muted mb-0">
                    Monthly earnings from end users for subscription. 
                </p>
            </CardBody>
        </Card>
    );
};

export default MonthlyEarning;
