import { WebContentDeatilActions } from "./actions"

const initialState = {
  DetailContentPage: [],
  newsletterResponse: [],
  UnsubscribeNewsletterResponse: [],
  webRelatedContentList: []
}

export const WebContentDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case WebContentDeatilActions.SET_WEBCONTENT_DETAIL_NEWS:
      return {
        ...state,
        DetailContent: action.payload,
      }

    case WebContentDeatilActions.RESPONSE_WEB_NEWSLETTER_EMAIL:
      return {
        ...state,
        newsletterResponse: action.payload,
      };

    case WebContentDeatilActions.UNSUBSCRIBE_NEWSLETTER_RESPONSE:
      return {
        ...state,
        UnsubscribeNewsletterResponse: action.payload,
      };
    case WebContentDeatilActions.SET_WEB_DETAIL_PAGE_RELATED_LIST:
      return {
        ...state,
        webRelatedContentList: action.payload,
      }
    default:
      return state
  }

}


