import React, { useEffect } from "react";
import ForyouPageIndex from "webComponents/ForYouPage"
import Header from '../../webComponents/Layout/Header'
import Footer from '../../webComponents/Layout/Footer'
import HrBanner from "webComponents/Common/FreeTrailBannerImg";
import Banner from "../../assets/webImages/Horizontal banner-2.webp";
import { useDispatch, useSelector } from "react-redux";
import { WebHomePageActions } from "store/webHome/actions";
import SubscribedUserBanner from "../../assets/webImages/un sky,white.webp"

export default function FullForYouPage() {
  const dispatch = useDispatch();
  const { tokenResponse } = useSelector((state) => state.userAuthReducer);

  let userId = tokenResponse?.result?._id;
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes";
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes";
  const stripeCutomerId = tokenResponse?.result?.Stripe_Customer_Id;


  useEffect(() => {
    const payload = { userId };

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
      payload,
    });
  }, [dispatch, userId]);

  //meta title
  document.title = "For You";

  return (
    <div>
      <Header />
      {isSubscribed || isTrialUsed || stripeCutomerId ? (
        <HrBanner bannerImage={SubscribedUserBanner} bannerLink="/" bannerType="subscribed" />
      ) : (
        <HrBanner bannerImage={Banner} bannerLink="/" bannerType="freeTrial" />
      )}
      <ForyouPageIndex />
      <Footer />
    </div>
  )
}