import axios from 'axios'
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { actions } from './actions'
import { API_URL } from 'utils/constant'
import { commonActions } from "../common/actions";

export const notificationsSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_SUPER_ADMIN_NOTIFICATION_LIST, getSuperAdminNotificationList),
        yield takeEvery(actions.GET_ADMIN_NOTIFICATION_LIST, getAdminNotificationList),
        yield takeEvery(actions.GET_CREATOR_NOTIFICATION_LIST, getCreatorNotificationList),
        yield takeEvery(actions.SUPER_ADMIN_DELETE_NOTIFICATION_BY_ID, deleteSuperAdminNotificationById),
        yield takeEvery(actions.ADMIN_DELETE_NOTIFICATION_BY_ID, deleteAdminNotificationById),
        yield takeEvery(actions.CREATOR_DELETE_NOTIFICATION_BY_ID, deleteCreatorNotificationById),
        yield takeEvery(actions.DELETE_ALL_SUPER_ADMIN_NOTIFICATIONS, deleteAllSuperAdminNotifications),
        yield takeEvery(actions.DELETE_ALL_ADMIN_NOTIFICATIONS, deleteAllAdminNotification),
        yield takeEvery(actions.DELETE_ALL_CREATOR_NOTIFICATIONS, deleteAllCreatorNotifications)
    ])
}

const getSuperAdminNotificationList = function* () {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/superadmin/notificationList`)
        )
        yield put({
            type: actions.SET_SUPER_ADMIN_NOTIFICATION_LIST,
            payload: result.data
        })
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    } catch (err) {
        console.log(err)
    }
}


const getAdminNotificationList = function* () {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/admin/notificationList`)
        )
        yield put({
            type: actions.SET_ADMIN_NOTIFICATION_LIST,
            payload: result.data
        })
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    } catch (err) {
        console.log(err)
    }
}

const getCreatorNotificationList = function* (data) {
    const { payload } = data
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/creator/notificationList/${payload}`)
        )
        yield put({
            type: actions.SET_CREATOR_NOTIFICATION_LIST,
            payload: result.data
        })
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    } catch (err) {
        console.log(err)
    }

}

const deleteSuperAdminNotificationById = function* (data) {
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/superAdminNotification/delete/${payload}`)
        )
        yield put({
            type: actions.GET_SUPER_ADMIN_NOTIFICATION_LIST
        })
    } catch (err) {
        console.log(err)
    }
}

const deleteAdminNotificationById = function* (data) {
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/adminNotification/delete/${payload}`)
        )
        yield put({
            type: actions.GET_ADMIN_NOTIFICATION_LIST
        })
    } catch (err) {
        console.log(err)
    }
}

const deleteCreatorNotificationById = function* (data) {
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/creatorNotification/delete/${payload.NotificationId}`)
        )
        yield put({
            type: actions.GET_CREATOR_NOTIFICATION_LIST,
            payload: payload.CreatorId
        })
    } catch (err) {
        console.log(err)
    }
}

const deleteAllSuperAdminNotifications = function* () {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/superAdminNotification/deleteAll`)
        )
        yield put({
            type: actions.GET_SUPER_ADMIN_NOTIFICATION_LIST
        })

        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    } catch (err) {
        console.log(err)
    }
}

const deleteAllAdminNotification = function* () {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/adminNotification/deleteAll`))
        yield put({
            type: actions.GET_ADMIN_NOTIFICATION_LIST
        })
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    } catch (err) {
        console.log(err)
    }
}

const deleteAllCreatorNotifications = function* (data) {
    const { payload } = data
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/creatorNotification/deleteAll/${payload}`)
        )
        yield put({
            type: actions.GET_CREATOR_NOTIFICATION_LIST,
            payload: payload
        })
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    } catch (err) {
        console.log(err)
    }
}

