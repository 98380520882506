import React, { useState, useEffect, useRef } from "react";
import { Form, Card, CardBody, Col, Row, CardTitle, Container, Label, Input, FormFeedback, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import Select from 'react-select';
import { useFormik, Field } from "formik";
import DOMPurify from "dompurify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { BlogContentActions } from "store/blogContent/actions";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from "react-router-dom";
import success_img from "../../assets/images/dashboard/success1-popup-image.gif";
import failure_img from "../../assets/images/dashboard/failure-popup-image.gif";
import { commonActions } from "../../store/common/actions";
import RichTextEditor from '../../common/QuillRichTextEditor';
import { actions } from "store/Countries/actions";

export default function createPitchContent() {
    document.title = "Pitch | Allen Dreyfus";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { buttonLoader } = useSelector(state => state.commonReducer);
    const { blogContentResponse } = useSelector(state => state.BlogContentReducer);

    const { recentTopicList} = useSelector(state => state.BlogContentReducer);
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { countryList } = useSelector(state => state.CountryReducer);
    const [recentTopic, setRecentTopic] = useState([]);
    const [storyContent, setStoryContent] = useState('');

    let creatorName = creatorTokenResponse?.result?.Name;
    let creator_id = creatorTokenResponse?.result?._id;

    const [showCreationPopup, setCreationPopup] = useState({ isOpen: false, statusCode: null });

    // // Recent topic
    // let recentTopic = recentTopicList?.result?.map((val) => ({
    //     id: val?.Topic,
    //     name: val?.Topic,
    // }));

    useEffect(() => {
        if (recentTopicList && recentTopicList.result && recentTopicList.result.length > 0) {
            const firstTopic = {
                id: recentTopicList.result[0]?.Topic,
                name: recentTopicList.result[0]?.Topic,
            };
            setRecentTopic([firstTopic]);
        }
    }, [recentTopicList?.result]);

    
    // Country List
    let countryArray = countryList?.result?.map((val) => ({
        id: val?.Country_Name,
        name: val?.Country_Name,
    }));

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [marketError, setMarketError] = useState("");

    const [selectedOptions1, setSelectedOptions1] = useState([]);
    const [recentTopicError, setRecentTopicError] = useState("");

    const [countryOptions, setCountryOptions] = useState([]);
    const [countryError, setCountryError] = useState("");

    //for brief description
    const [briefEditorState, setBriefEditorState] = useState(EditorState.createEmpty());
    const [briefConvertedContent, setBriefConvertedContent] = useState(null);
    const [descError, setDescError] = useState("")


    //form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            Title: '',
            TypeOfContent: '',
            Country: [],
            Category: '',
            Sub_Category: [],
            Impacts: '',
        },
        validationSchema: Yup.object({
            Title: Yup.string().required("enter the title"),
            TypeOfContent: Yup.string().required("select the content type"),
            Category: Yup.string().required("select the category"),
            Impacts: Yup.string().required("impacts field is required"),
        }),

        onSubmit: (data) => {
            if (countryOptions?.length === 0) {
                setCountryError("select the value for market focus")
            } else if (storyContent === '') {
                setDescError("story is required")
            } else if (validation?.values?.Category === 'Frontier Markets' && selectedOptions?.length === 0) {
                setMarketError("frontier markets is required")
            } else if (validation?.values?.Category === 'Recent Topic' && selectedOptions1?.length === 0) {
                setRecentTopicError("recent topic is required")
            } else {
                setDescError('')

                if (validation?.values?.Category === 'Frontier Markets') {
                    var markets = [];
                    selectedOptions.map(val =>
                        markets = [...markets, val.name])
                    const subCategoryMarkets = JSON.stringify(markets);
                    data['Sub_Category'] = subCategoryMarkets;
                } else if (validation?.values?.Category === 'Recent Topic') {
                    var topics = [];
                    selectedOptions1.map(val =>
                        topics = [...topics, val.name])
                    const subCategoryTopic = JSON.stringify(topics)
                    data['Sub_Category'] = subCategoryTopic;
                }
                var country = [];
                countryOptions.map(val =>
                    country = [...country, val.name])
                const countryList = JSON.stringify(country)
                data['Country'] = countryList;
                // data['Pitch_story'] = briefConvertedContent;
                data['Pitch_story'] = storyContent;
                data['Created_By'] = creatorName;
                data['Content_Creator_Id'] = creator_id;
                dispatch({ type: BlogContentActions.CREATE_PITCH_CONTENT, payload: data })
            }
        }
    })

    // Country
    const onSelectCounty = (selectedCountry) => {
        setCountryOptions(selectedCountry);
        setCountryError("");
    };

    const onRemoveCountry = (selectedCountry) => {
        setCountryOptions(selectedCountry);
    };

    // frontier markets
    const onSelect = (selectedList) => {
        setSelectedOptions(selectedList);
        setMarketError("");
    };

    const onRemove = (selectedList) => {
        setSelectedOptions(selectedList);
    };

    // Recent Topics
    const onSelect1 = (selectedList1) => {
        setSelectedOptions1(selectedList1);
        setRecentTopicError("");
    };

    const onRemove1 = (selectedList1) => {
        setSelectedOptions1(selectedList1);
    };

    // modal close
    const handleClose = () => {
        setCreationPopup({ isOpen: false, statusCode: null })
        window.location.href = '/pitch-creation';
        // navigate('/pitch-creation')
    }

    useEffect(() => {
        dispatch({ type: BlogContentActions.GET_RECENT_TOPIC_LIST });
        dispatch({ type: actions.GET_COUNTRY_LIST });
    }, [])

    useEffect(() => {
        if (blogContentResponse && blogContentResponse?.statusCode === 200) {
            setCreationPopup({ isOpen: true, statusCode: 200 });
            validation.resetForm();
            setCountryOptions([])
            setBriefEditorState(EditorState.createEmpty())
            setStoryContent('')

        } else if (blogContentResponse && blogContentResponse?.statusCode === 400) {
            setCreationPopup({ isOpen: false, statusCode: 400 });
        }
    }, [blogContentResponse])

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <p className="mb-2 font-size-16 create-size">CREATE PITCH</p>

                        <Form className="form-horizontal mt-2"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-4">

                                        <Col md="6">
                                            <div className="form-group">
                                                <Label className="form-label">Type of Content</Label><span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="TypeOfContent"
                                                    id="type"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.TypeOfContent || ""}
                                                    invalid={validation.touched.TypeOfContent && validation.errors.TypeOfContent ? true : false}
                                                >
                                                    <option value="" disabled> -- Select Content Type --  </option>
                                                    <option value="Article">Article</option>
                                                    {/* <option value="Podcast">Podcast</option> */}
                                                     {/*<option value="Video">Video</option> */}
                                                </Input>

                                                {validation.touched.TypeOfContent && validation.errors.TypeOfContent ? (
                                                    <FormFeedback type="invalid">{validation.errors.TypeOfContent}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label htmlFor="Title" className="form-label">market focus</Label><span className="text-danger fw-bold">*</span>
                                                <Multiselect
                                                    options={countryArray}
                                                    displayValue="name"
                                                    onSelect={onSelectCounty}
                                                    onRemove={onRemoveCountry}
                                                    selectedValues={countryOptions}
                                                    value={validation.values.Country || []}
                                                />
                                                <div>
                                                    <div><span className="market-select">selected values:</span> {countryOptions?.map((option) => option.name).join(', ')}</div>
                                                </div>
                                                {countryError && <div style={{ color: "red" }}>{countryError}</div>}
                                            </div>
                                        </Col>

                                        <Col md="12" className="mb-4">
                                            <Label htmlFor="Title" className="form-label">Title</Label><span className="text-danger fw-bold">*</span>
                                            <Input
                                                name="Title"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Title || ""}
                                                invalid={
                                                    validation.touched.Title && validation.errors.Title ? true : false
                                                }
                                            />
                                            {validation.touched.Title && validation.errors.Title ? (
                                                <FormFeedback type="invalid">{validation.errors.Title}</FormFeedback>
                                            ) : null}
                                        </Col>

                                        <Col className="mb-4" md={validation?.values?.Category === "Frontier Markets" || validation?.values?.Category === "Recent Topic" ? "6" : "12"} >
                                            <div className="form-group">
                                                <Label className="form-label">Category</Label><span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="Category"
                                                    id="type"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.Category || ""}
                                                    invalid={validation.touched.Category && validation.errors.Category ? true : false}
                                                >
                                                    <option value="" disabled> -- Select the Category --  </option>
                                                    <option value="Business">Business</option>
                                                    <option value="Politics">Politics</option>
                                                    {/* <option value="Recent Topic">Recent Topics</option>
                                                    <option value="Green">Green</option>
                                                    <option value="In Depth">In-Depth</option>
                                                   <option  value="localknowledge">Local Knowledge</option> 
                                                     <option value="Frontier Markets">Frontier Markets</option> */}
                                                </Input>
                                                {validation.touched.Category && validation.errors.Category ? (
                                                    <FormFeedback type="invalid">{validation.errors.Category}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        {validation?.values?.Category === "Frontier Markets" ?
                                            <>
                                                <Col className="mb-4" md="6">
                                                    <div className="form-group ">
                                                        <Label className="form-label">Select the Frontier Markets</Label><span className="text-danger fw-bold">*</span>
                                                        <Multiselect
                                                            options={countryArray}
                                                            displayValue="name"
                                                            onSelect={onSelect}
                                                            onRemove={onRemove}
                                                            selectedValues={selectedOptions}
                                                            value={validation.values.Sub_Category || []}
                                                        />
                                                        <div>
                                                            <div><span className="market-select">selected values:</span> {selectedOptions.map((option) => option.name).join(', ')}</div>
                                                        </div>
                                                        {marketError && <div style={{ color: "red" }}>{marketError}</div>}
                                                    </div>
                                                </Col>
                                            </> : validation?.values?.Category === "Recent Topic" ?
                                                <>
                                                    <Col className="mb-4" md="6">
                                                        <div className="form-group">
                                                            <Label className="form-label">Select Recent Topic</Label>
                                                            <span className="text-danger fw-bold">*</span>
                                                            <Multiselect
                                                                options={recentTopic}
                                                                displayValue="name"
                                                                onSelect={onSelect1}
                                                                onRemove={onRemove1}
                                                                selectedValues={selectedOptions1}
                                                                value={validation.values.Sub_Category || []}
                                                            >
                                                                <option value="" disabled>
                                                                    -- Select the Recent Topic --
                                                                </option>
                                                                {/* Map over the array to generate options */}
                                                                {recentTopic?.map((topic) => (
                                                                    <option key={topic.value} value={topic.value}>
                                                                        {topic.name}
                                                                    </option>
                                                                ))}
                                                            </Multiselect>

                                                            <div>
                                                                <div><span className="market-select">selected values:</span> {selectedOptions1.map((option) => option.name).join(', ')}</div>
                                                            </div>
                                                            {recentTopicError && <div style={{ color: "red" }}>{recentTopicError}</div>}
                                                        </div>
                                                    </Col>
                                                </> :
                                                <></>
                                        }

                                        <Col className="mb-4" md="12">
                                            <Label htmlFor="title">What is the Story?</Label><span className="text-danger fw-bold">*</span>                                           
                                            <div className="textEditorStyle" style={{ textDecoration: 'none' }}>
                                                <RichTextEditor setStoryContent={setStoryContent} storyContent={storyContent} />
                                                {/* <div dangerouslySetInnerHTML={{ __html: storyContent }} /> */}
                                            </div>
                                            {descError && <div style={{ color: "red" }}>{descError}</div>}
                                        </Col>

                                        <Col className="mb-4" md="12">
                                            <Label htmlFor="title">Impact on Markets</Label><span className="text-danger fw-bold">*</span>

                                            <Input
                                                className="form-control"
                                                id="impacts"
                                                name="Impacts"
                                                type="textarea"
                                                rows={5}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Impacts || ""}
                                                invalid={
                                                    validation.touched.Impacts && validation.errors.Impacts ? true : false
                                                }
                                            />
                                            {validation.touched.Impacts && validation.errors.Impacts ? (
                                                <FormFeedback type="invalid">{validation.errors.Impacts}</FormFeedback>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <button
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                    >
                                        {buttonLoader ? <Spinner color="light" size="sm" /> : "Create"}
                                    </button>
                                </CardBody>
                            </Card>
                        </Form>
                    </Container>
                </div>

                <Modal isOpen={showCreationPopup?.isOpen} toggle={handleClose} centered={true}>
                    <ModalHeader centered={true} toggle={handleClose} tag="h4">
                        <h4>Alert</h4>
                    </ModalHeader>
                    <ModalBody centered={true}   >
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                // validation.handleSubmit();
                                return false;
                            }}
                        >
                            {
                                showCreationPopup?.statusCode === 200 ?
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <img src={success_img} alt="success_popup" className="popup-success" />
                                            <h5>Your Pitch is submitted for Approval.</h5>
                                        </Col>
                                        <hr />

                                        <Col md={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger save-customer me-2"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </button>

                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            }
                        </Form>
                    </ModalBody>
                </Modal>

            </React.Fragment>
        </>
    )
}