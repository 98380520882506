import React from "react";
import SubAdminProfileIndex from "components/subAdminProfile";

export default function SubAdminProfile() {
    return (
        <div>
            <SubAdminProfileIndex />
        </div>

    )
}