import { takeEvery, call, all, put, delay } from "redux-saga/effects"
import { SignUpActions } from "./actions"
import { commonActions } from "../common/actions"
import { API_URL } from "../../utils/constant"
import axios from "axios"


export const webUserSignupSagas = function* () {
  yield all([
    takeEvery(
      SignUpActions.GET_WEBUSER_SIGNUP_DETAILS,
      getWebUserSignUpDetails
    ),
  ])
}

const getWebUserSignUpDetails = function* (data) {
  const { payload } = data

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/user/create`, payload)
    )

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })

    if (result?.data?.statusCode === 200) {
      yield put({
        type: SignUpActions.USER_RESPONSE,
        payload: { status: "success", message: result?.data?.message },
      })
    } else {
      yield put({
        type: SignUpActions.USER_RESPONSE,
        payload: { status: "failed", message: result?.data?.message },
      })
    }
    // yield put({
    //   type: commonActions.SET_ALERT,
    //   payload: {
    //     show: true,
    //     status: result?.data?.statusCode === 200 ? "success" : "failed",
    //     message: result?.data?.message,
    //   },
    // })
    // yield delay(2000)
    // yield put({
    //   type: commonActions.SET_ALERT,
    //   payload: { show: false, status: null, message: null },
    // })
    // yield put({
    //   type: SignUpActions.USER_RESPONSE,
    //   payload: { status: null, message: null },
    // })
  } catch (err) {
    console.log(err)

    // yield put({
    //     type: SignUpActions.USER_RESPONSE,
    //     payload: { status: "error", message: "An error occurred during signup." }
    // });
  }
}
