export const BlogContentActions = {

    CREATE_BLOG_CONTENT: 'CREATE_BLOG_CONTENT',

    CREATE_ADMIN_PODCAST_CONTENT: 'CREATE_ADMIN_PODCAST_CONTENT',

    GETCONTENTLIST: 'GETCONTENTLIST',
    SETCONTENTLIST: 'SETCONTENTLIST',

    GETCONTENTDETAIL: 'GETCONTENTDETAIL',
    SETCONTENTDETAIL: 'SETCONTENTDETAIL',

    GET_RECENT_TOPIC_LIST: 'GET_RECENT_TOPIC_LIST',
    SET_RECENT_TOPIC_LIST: 'SET_RECENT_TOPIC_LIST',

    GET_ADMIN_CONTENT_LIST_NEED_APPROVAL: 'GET_ADMIN_CONTENT_LIST_NEED_APPROVAL',
    SET_ADMIN_CONTENT_LIST_NEED_APPROVAL: 'SET_ADMIN_CONTENT_LIST_NEED_APPROVAL',

    GET_ADMIN_CONTENT_NEED_APPROVAL_COUNT : 'GET_ADMIN_CONTENT_NEED_APPROVAL_COUNT',
    SET_ADMIN_CONTENT_NEED_APPROVAL_COUNT : 'SET_ADMIN_CONTENT_NEED_APPROVAL_COUNT',

    GET_ADMIN_CONTENT_LIST_BY_STATUS: 'GET_ADMIN_CONTENT_LIST_BY_STATUS',
    SET_ADMIN_CONTENT_LIST_BY_STATUS: 'SET_ADMIN_CONTENT_LIST_BY_STATUS',

    GET_ADMIN_CONTENT_DETAIL: 'GET_ADMIN_CONTENT_DETAIL',
    SET_ADMIN_CONTENT_DETAIL: 'SET_ADMIN_CONTENT_DETAIL',

    CHANGE_CONTENT_STATUS: 'CHANGE_CONTENT_STATUS',
    // CHANGE_CONTENT_STATUS : 'CHANGE_CONTENT_STATUS'

    GET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL: 'GET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL',
    SET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL: 'SET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL',

    GET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS: 'GET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS',
    SET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS: 'SET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS',

    CREATE_PITCH_CONTENT: 'CREATE_PITCH_CONTENT',

    GET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL: 'GET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL',
    SET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL: 'SET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL',

    GET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS: 'GET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS',
    SET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS: 'SET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS',

    GET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST: 'GET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST',
    SET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST: 'SET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST',

    GET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS: 'GET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS',
    SET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS: 'SET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS',

    GET_CREATOR_PITCH_DETAIL: 'GET_CREATOR_PITCH_DETAIL',
    SET_CREATOR_PITCH_DETAIL: 'SET_CREATOR_PITCH_DETAIL',

    ADMIN_CHANGE_PITCH_STATUS: 'ADMIN_CHANGE_PITCH_STATUS',

    GET_PITCH_TO_CONTENT_LIST: 'GET_PITCH_TO_CONTENT_LIST',
    SET_PITCH_TO_CONTENT_LIST: 'SET_PITCH_TO_CONTENT_LIST',

    BLOG_CONTENT_RESPONSE: 'BLOG_CONTENT_RESPONSE',

    
    GET_RECENT_TOPIC_ADD_TOPIC: 'GET_RECENT_TOPIC_ADD_TOPIC',
    SET_RECENT_TOPIC_ADD_TOPIC: 'SET_RECENT_TOPIC_ADD_TOPIC',

    GET_NEWSLETTER_USER_EMAIL_LIST: "GET_NEWSLETTER_USER_EMAIL_LIST",
    SET_NEWSLETTER_USER_EMAIL_LIST: "SET_NEWSLETTER_USER_EMAIL_LIST",


    GET_ADMIN_EDIT_CHANGE_CONTENT_STATUS: "GET_ADMIN_EDIT_CHANGE_CONTENT_STATUS" //only approves the content

}