import { actions } from './actions'
import axios from "axios"
import { API_URL } from "../../utils/constant"
import { takeEvery, all, put, call } from 'redux-saga/effects'
import { commonActions } from "../common/actions"

export const CountrySaga = function* () {
  yield all([
    yield takeEvery(actions.GET_COUNTRY_LIST, getCountryList),
    yield takeEvery(actions.GET_ADD_COUNTRY_NAMES, getAddCountryNames),
    yield takeEvery(actions.GET_DELETE_COUNTRY_NAMES, getDeleteCountryNames),
    yield takeEvery(actions.GET_ONLY_COUNTRY_LIST, getOnlyCountryList),
    yield takeEvery(actions.CREATE_ONLY_COUNTRIES, createOnlyCountries),
    yield takeEvery(actions.DELETE_ONLY_COUNTRIES, deleteOnlyCountries),
  ])
}
// Get Market focus List
const getCountryList = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/country/list`))
    yield put({
      type: actions.SET_COUNTRY_LIST,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
}

const getAddCountryNames = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/country/addCountry`, payload)
    )
    yield put({
      type: actions.SET_ADD_COUNTRY_NAMES,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    // if (result?.data?.statusCode === 200) {
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : 'failed',
        message: result?.data?.message,
      },
    })
    yield put({
      type: actions.GET_COUNTRY_LIST,
    })
    // }
  } catch (err) {
    console.error(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
}

const getDeleteCountryNames = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.delete(`${API_URL}/country/delete/${payload.CountryId}`)
    )
    yield put({
      type: actions.SET_DELETE_COUNTRY_NAMES,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

    // if (result?.data?.statusCode === 200) {
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    })
    yield put({
      type: actions.GET_COUNTRY_LIST,
    })
    // }
  } catch (err) {
    console.error(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
}

// Get Country List
const getOnlyCountryList = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/onlyCountry/getCountryList`))
    yield put({
      type: actions.SET_ONLY_COUNTRY_LIST,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
}

//create only countries
const createOnlyCountries = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/onlyCountry/addCountry`, payload)
    )
    yield put({
      type: actions.RESPONSE_CREATE_ONLY_COUNTRIES,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    // if (result?.data?.statusCode === 200) {
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : 'failed',
        message: result?.data?.message,
      },
    })
    yield put({
      type: actions.GET_ONLY_COUNTRY_LIST,
    })
    // }
  } catch (err) {
    console.error(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
}

//delete only countries
const deleteOnlyCountries = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.delete(`${API_URL}/onlyCountry/deleteOnlyCountry/${payload.CountryId}`)
    )
    yield put({
      type: actions.RESPONSE_DELETE_ONLY_COUNTRIES,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

    // if (result?.data?.statusCode === 200) {
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    })
    yield put({
      type: actions.GET_ONLY_COUNTRY_LIST,
    })
    // }
  } catch (err) {
    console.error(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
}