import AddAdminIndex from "components/addAdmin";
import React from "react";

export default function AddAdmin() {

    return (
        <div>
            <AddAdminIndex />
        </div>
    )
}