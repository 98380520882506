import React, { useEffect, useRef, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { WebHomePageActions } from "store/webHome/actions"
import { WebContentDeatilActions } from "store/webContentDetail/actions"
import "slick-carousel/slick/slick.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"

const RelatedContent = () => {
  const dispatch = useDispatch()
  const sliderRef = useRef(null)
  const navigate = useNavigate()

  const [filterData, setFilterData] = useState([])

  const { DetailContent } = useSelector(state => state.WebContentDetailReducer)
  const { webRelatedContentList } = useSelector(state => state.WebContentDetailReducer)

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, { state: { contentDetail: contentDetail } })
    dispatch({ type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS, payload: contentDetail._id })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const { PoliticsCategoryNews } = useSelector(
    state => state.WebHomePageReducer
  )

  useEffect(() => {
    let Category
    if (DetailContent?.result) {
      Category = DetailContent?.result?.Category[0]
    }

    dispatch({
      type: WebContentDeatilActions.GET_WEB_DETAIL_PAGE_RELATED_LIST,
      payload: DetailContent?.result?._id,
    })
  }, [DetailContent])

  useEffect(() => {
    if (webRelatedContentList?.length > 0) {
      setFilterData(webRelatedContentList)
    }
  }, [webRelatedContentList])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  return (
    <div className="politics-fullsection ">
      <Container className="politics-container">
        <Row className="politics-row">
          <div className="recent-container">
            <div className="recent-heading">
              <h2 className="web_fonts">related</h2>
              <hr className="hr-line" />
            </div>
            <h5 className="web_fonts color-black">
              explore more related coverage
            </h5>
          </div>

          {filterData &&
            filterData?.length > 4 ? (
            <div className="slider-container">
              <Slider {...settings} ref={sliderRef}>
                {filterData?.slice(0,100)?.map(news => (
                  <Col key={news._id} className="politics-column">
                    <div onClick={() => handleSectionClick(news)}>
                      <img
                        src={news.Article_Images}
                        alt={`Topic ${news._id} Image`}
                      />
                      <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                        {news?.Category[0] === "Recent Topic" ? news?.Sub_Category[0] : news?.Category[0]}
                      </p>
                      <h6 className="web_fonts color-black politics-title">
                        {news.Title.length > 50
                          ? `${news.Title.slice(0, 50)}...`
                          : news.Title}
                      </h6>
                      {/* <p className="disabled-text web_fonts">
                        by{" "}
                        <span className="name-of web_fonts">
                          {news.Created_By}
                        </span>
                        {news.Created_On}
                      </p> */}
                      <p className="disabled-text web_fonts">
                        by<span className="name-of web_fonts" style={{ textTransform: "none" }}>{news.Created_By}</span>
                        {`${formatDate(news?.Approved_On)}`}
                      </p>
                    </div>
                  </Col>
                ))}
              </Slider>
            </div>
          ) : (
            // Render news directly if there are 4 or fewer news items
            <Row>
              {PoliticsCategoryNews?.result?.map(news => (
                <Col
                  key={news._id}
                  xs={12}
                  sm={6}
                  md={3}
                  // className="politics-column"
                  className="politics-column-below4"
                >
                  <div>
                    <img
                      src={news.Article_Images}
                      alt={`Topic ${news._id} Image`}
                      onClick={() => handleSectionClick(news)}
                    />
                    <h6 className="politics-heading-text web_fonts">
                      {/* {news.Category} */}
                      {news?.Category[0] === "Recent Topic" ? news?.Sub_Category[0] : news?.Category[0]}
                    </h6>
                    <h6 className="web_fonts color-black politics-title">
                      {news.Title.length > 50
                        ? `${news.Title.slice(0, 50)}...`
                        : news.Title}
                    </h6>
                    <p className="disabled-text web_fonts">
                      by<span className="name-of web_fonts" style={{ textTransform: "none" }}>{news.Created_By}</span>
                      {`${formatDate(news?.Approved_On)}`}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default RelatedContent
