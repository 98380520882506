import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { webContentByCategoryActions } from "../../store/webContentByCategory/actions"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "../../store/webHome/actions"

const ListByCategory = ({ }) => {
  const navigate = useNavigate()
  const { category } = useParams()
  const dispatch = useDispatch()

  const { webListByCategory } = useSelector(
    state => state.webContentByCategoryReducer
  )

  const itemsPerPage = 5
  const [currentPage, setCurrentPage] = useState(1)

  // Remove the first seven items from the list
  const trimmedList = webListByCategory?.result?.slice(7) || [];
  const totalItems = trimmedList?.length;

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = trimmedList?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = newPage => {
    setCurrentPage(newPage)
  }

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  useEffect(() => {
    dispatch({
      type: webContentByCategoryActions.GET_WEB_LIST_BY_CATEGORY,
      payload: { TypeOfContent: "Article", Category: category },
    })
  }, [dispatch, category])

  const truncateContent = (content, numWords) => {
    const words = content.split(" ")
    if (words.length > numWords) {
      return `${words.slice(0, numWords).join(" ")} ...`
    } else {
      return content
    }
  }

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, { state: { contentDetail: contentDetail } })
    dispatch({ type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS, payload: contentDetail._id })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const renderPageNumbers = () => {
      const pageNumbers = []
      const maxVisiblePages = 5
      // for (let i = 1; i <= totalPages; i++) {
      //   pageNumbers.push(
      //     <button
      //       className={`page-numbs  ${currentPage === i ? "page-active" : ""}`}
      //       key={i}
      //       onClick={() => handlePageChange(i)}
      //       disabled={currentPage === i}
      //     >
      //       {i}
      //     </button>
      //   );
      // }

      for (let i = 1; i <= totalPages; i++) {
        // Show only a maximum of 5 pages, and add an ellipsis if more pages are available
        if (
          i <= maxVisiblePages ||
          i === totalPages ||
          i === currentPage ||
          i === currentPage - 1 ||
          i === currentPage + 1
        ) {
          pageNumbers.push(
            <button
              className={`page-numbs  ${currentPage === i ? "page-active" : ""
                }`}
              key={i}
              onClick={() => handlePageChange(i)}
              disabled={currentPage === i}
            >
              {i}
            </button>
          )
        } else if (
          i === maxVisiblePages + 1 &&
          totalPages > maxVisiblePages + 1
        ) {
          pageNumbers.push(
            <span key="ellipsis" className="ellipsis">
              ...
            </span>
          )
        }
      }
      return pageNumbers
    }

    return (
      <div className="pagination-container pagination-design">
        <button
          className="pagination-button previous-page"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;&lt;
        </button>
        {renderPageNumbers()}
        <button
          className="pagination-button next-page"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;&gt;
        </button>
      </div>
    )
  }
  return (
    <>
      {currentItems && currentItems.length > 0 ? (
        currentItems.map((content, index) => (
          <div key={index}>
            <Row>
              <Col
                xs={12}
                md={6}
                className="mt-3"
                key={content._id}
                onClick={() => handleSectionClick(content)}
              >
                <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                  {content?.Category[0]}
                </p>
                <h4 className="web_fonts politics-title color-black">
                  {truncateContent(content?.Title, 6)}
                </h4>
                <div className="politicsnews">
                  <p
                    className="web_fonts list-by-cat"
                    dangerouslySetInnerHTML={{
                      __html: `${content?.Story.slice(0, 170)}${content?.Story.length > 170 ? " ..." : ""
                        }`,
                    }}
                  ></p>
                </div>
                <p className="disabled-text web_fonts">
                  by<span className="name-of web_fonts" style={{ textTransform: "none" }}>{content?.Created_By}</span>
                  {`${formatDate(content.Approved_On)}`}
                </p>
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <img
                  src={content?.Article_Images[0]}
                  alt="politics"
                  className="img-fluid category-img"
                  onClick={() => handleSectionClick(content)}
                />
              </Col>
            </Row>
          </div>
        ))
      ) : (
        <> </>
      )}

      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  )
}

export default ListByCategory
