import { ContentCreatorActions } from './actions';

const initialState = {
    creator_Response: null,
    content_Creators: [],
    content_Creator_Details: null,
};

export const ContentCreatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ContentCreatorActions.CREATOR_RESPONSE:
            return {
                ...state,
                creator_Response: action.payload
            }
        case ContentCreatorActions.SET_CONTENT_CREATORS_LIST_BY_STATUS:
            return {
                ...state,
                content_Creators: action.payload
            }
        case ContentCreatorActions.SET_CONTENT_CREATOR_DETAILS:
            return {
                ...state,
                content_Creator_Details: action.payload
            }
        default:
            return state;
    }
};