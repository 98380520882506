import React, { useMemo, useEffect, useState } from "react";
import {
    Card, CardBody, Col, Container, Row, Button
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { adminActions } from "../../store/admin/actions";
import Breadcrumbs from "../../common/Breadcrumb";
import TableContainer from '../../common/TableContainer';
import AdminPageLoader from "../../common/adminPageLoader";
import CustomPagination from "../../common/CustomPagination"; 

export default function AdminIndex() {

    document.title = "Admin | Allen Dreyfus";

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Define items per page

    const { adminList } = useSelector(state => state.adminReducer);
    const { adminPageLoader } = useSelector(state => state.commonReducer);

    useEffect(() => {
        dispatch({ type: adminActions.GET_ADMIN_LIST });
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'Name',
                filterable: true,
            },
            {
                Header: 'Email',
                accessor: 'Email',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'Status',
                filterable: true,
            },
            {
                Header: 'Created On',
                accessor: 'Created_On',
                filterable: true,
            },
            {
                Header: "View Details",
                disableFilters: true,
                accessor: "view",
                Cell: (cellProps) => (
                    <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded"
                        onClick={() => handleViewDetails(cellProps)}
                    >
                        View Details
                    </Button>
                ),
            },
        ], []);

    const handleAddAdmin = () => {
        navigate('/add-admin');
    };

    const handleViewDetails = (cellProps) => {
        const adminData = cellProps.row.original;
        navigate('/admin-details', { state: adminData });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs title="Admin" breadcrumbItem="list" />
                        <Row>
                            <Col xs="12">
                                <div style={{ textAlign: 'right' }}>
                                    <button onClick={handleAddAdmin} className="btn btn-primary list-add-button">add admin</button>
                                </div>
                                <Card className="payouts">
                                    <CardBody>
                                        {adminList && adminList.length > 0 ? (
                                            <TableContainer
                                                columns={columns}
                                                data={adminList || []}
                                                isGlobalFilter={true}
                                                isAddCustList={false}
                                                isPagination={true}
                                                customPageSize={itemsPerPage}
                                                tableClass="align-middle table-nowrap center-table"
                                                isShowingPageLength={false}
                                                iscustomPageSizeOptions={true}
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
}

