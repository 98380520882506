import React, { useState, useEffect } from "react"
import { Button, Nav, Container } from "react-bootstrap"
import { FaUserCircle } from "react-icons/fa"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import { useNavigate } from "react-router-dom"
import Logoimage from "../../assets/webImages/png-logo-color.webp"
import SearchModal from "../modals/Searchmodal"
import { NavLink } from "react-router-dom"
import { CiSearch } from "react-icons/ci"
import SubscriptionModal from "webComponents/modals/SubscriptionModal"
import CorporateModal from "webComponents/modals/CorporateModal"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { userAuthActions } from "store/userAuth/actions"
import { useDispatch } from "react-redux"
import LogoutModal from "webComponents/modals/LogoutModal"
import { RiArrowDownSLine } from "react-icons/ri"
import { getColorByLetter } from "../Common/TextBgColors"
// import { BlogContentActions } from "store/blogContent/actions"
import { actions } from "store/Countries/actions"
import { WebHomePageActions } from "store/webHome/actions"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import UserChangePassword from "webComponents/modals/ChangePassword"

const Header = () => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const [countryListOptions, setCountryListOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [isScrolling, setIsScrolling] = useState(false)
  const { ProfileUserInfo } = useSelector(state => state.WebHomePageReducer)
  const [profileImage, setProfileImage] = useState("")
  const [showUserChangePassword, setshowUserChangePassword] = useState(false)

  let userId = tokenResponse?.result?._id
  useEffect(() => {
    const payload = { userId }

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
      payload,
    })
  }, [dispatch, userId])

  useEffect(() => {
    setProfileImage(ProfileUserInfo?.result?.Profile_Image || "")
  }, [ProfileUserInfo])

  const handleLoginClick = () => {
    navigate("/web/login")
  }

  const handleSearchClick = () => {
    setShowSearchModal(true)
  }

  const handleCloseSearchModal = () => {
    setShowSearchModal(false)
  }

  const handleCloseContactModal = () => {
    setShowContactModal(false)
  }

  const handleSubscribeClick = () => {
    setShowSubscriptionModal(true)
  }

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false)
  }

  const handleLogout = () => {
    setShowLogoutModal(true)
  }

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false)
  }

  const handleBookmarks = () => {
    navigate("/Save")
  }

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const handleShowChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };


  const handleLogoutConfirmed = () => {
    // Perform logout logic
    if (tokenResponse?.result?.Type_Of_User === "User") {
      localStorage.removeItem("WEB_TOKEN")
      dispatch({
        type: userAuthActions.UPDATE_AUTH_DETAILS,
        payload: { isAuthenticated: false },
      })
      toast.success("Successfully logged out!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
    localStorage.setItem("WebUserLogout", Date.now()) // For synchronized logout in all tabs
    setShowLogoutModal(false)
  }

  // const { countryList } = useSelector(state => state.BlogContentReducer)
  const { countryList } = useSelector(state => state.CountryReducer)
  const { ContentByRecentTopic } = useSelector(
    state => state.WebHomePageReducer
  )

  useEffect(() => {
    dispatch({ type: actions.GET_COUNTRY_LIST })
  }, [])

  useEffect(() => {
    dispatch({ type: WebHomePageActions.GET_WEBHOME_CONTENTBY_RECENT_TOPIC })
  }, [dispatch])

  const [firstTopic, setFirstTopic] = useState(null)
  const [hasTopics, setHasTopics] = useState(false)

  useEffect(() => {
    setFirstTopic(ContentByRecentTopic?.result?.[0]?.Topic)
  }, [ContentByRecentTopic])

  useEffect(() => {
    setHasTopics(ContentByRecentTopic?.result?.length > 0)
  }, [ContentByRecentTopic])

  useEffect(() => {
    if (countryList?.result?.length !== 0) {
      setCountryListOptions(countryList?.result)
    }
  }, [countryList])

  const handleCountryClick = country => {
    navigate(`/web/country/${country.Country_Name}`, { state: { country } })
  }

  const UserDisplay = ({ firstName }) => {
    const bgColor = getColorByLetter(firstName?.charAt(0))

    return (
      <div className="login-link">
        <NavDropdown
          title={
            <div>
              <span className="login-text color-black">{firstName}</span>
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="rounded-background-profile-img"
                  style={{ background: bgColor }}
                />
              ) : (
                <div
                  className="rounded-background"
                  style={{ background: bgColor }}
                >
                  {firstName?.charAt(0)?.toUpperCase()}
                </div>
              )}
            </div>
          }
          id="basic-nav-dropdown"
          className="profile-dropdown color-black"
        >
          <NavDropdown.Item href="/web/Profile">Profile</NavDropdown.Item>
          <NavDropdown.Item className="bookmark-nav" onClick={handleBookmarks}>
            BookMarks
          </NavDropdown.Item>
          <NavDropdown.Item onClick={handleShowChangePasswordModal}>
            Change Password
          </NavDropdown.Item>
          <li>
            <hr className="dropdown-divider" />
          </li>

          <NavDropdown.Item href="#" onClick={handleLogout}>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    )
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      const progress = (scrollY / windowHeight) * 100
      setScrollProgress(progress)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div>
      <div className="top-bar  fixed-top">
        <div className="progress-container">
          <div
            className="scroll-progress"
            style={{ width: `${scrollProgress}%` }}
          ></div>
        </div>
        <Container>
          <header className="header">
            <div>
              <a href="/">
                <img src={Logoimage} alt="Logo" className="logo-ad" />
              </a>
            </div>

            <header className="header-controls">
              {/* {tokenResponse?.statusCode === 200 &&
              tokenResponse?.result?.IsSubscribed === "Yes" &&
              tokenResponse?.result?.Stripe_Subscription_Id ? null : (
                <Button
                  variant="primary"
                  className="subscribe-button"
                  onClick={handleSubscribeClick}
                >
                  <samp>Subscribe</samp>
                </Button>
              )} */}
              <CiSearch className="search-icon " onClick={handleSearchClick} />
              <div className="vr"></div>
              {tokenResponse ? (
                // User is logged in
                <UserDisplay firstName={tokenResponse?.result?.First_Name} />
              ) : (
                // User is not logged in
                <div className="login-link" onClick={handleLoginClick}>
                  <FaUserCircle className="login-icon color-black" />
                  {/* <span className="login-text color-black d-none d-md-inline">log in</span> */}
                  <span className="login-text color-black d-none d-sm-inline">
                    log in
                  </span>
                </div>
              )}
            </header>

            <SearchModal
              show={showSearchModal}
              setShowSearchModal={setShowSearchModal}
              handleClose={handleCloseSearchModal}
            />
            <SubscriptionModal
              show={showSubscriptionModal}
              handleClose={handleCloseSubscriptionModal}
            />
            <CorporateModal
              show={showContactModal}
              handleClose={handleCloseContactModal}
            />
            <LogoutModal
              show={showLogoutModal}
              handleClose={handleCloseLogoutModal}
              handleLogoutConfirmed={handleLogoutConfirmed}
            />
             <UserChangePassword show={showChangePasswordModal} handleClose={handleCloseChangePasswordModal} />
          </header>
        </Container>
        <div className="headnavbar color-black fixed-top">
          <Navbar expand="lg" className="mb-2 color-black">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="bg-clr-for-navbar"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Container>
                <Nav className="ms-0 custom-nav color-black">
                  <Nav.Link
                    as={NavLink}
                    to="/"
                    exact
                    className="custom-nav-link ne color-black"
                    activeClassName="active-nav-link"
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/web/Business"
                    exact
                    className="custom-nav-link ne color-black"
                    activeClassName="active-nav-link"
                  >
                    business
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/web/Politics"
                    className="custom-nav-link ne color-black"
                    activeClassName="active-nav-link"
                  >
                    politics
                  </Nav.Link>
                  {/* <Nav.Link
                    as={NavLink}
                    to="/web/Green"
                    exact
                    className="custom-nav-link ne color-black"
                    activeClassName="active-nav-link"
                  >
                    green
                  </Nav.Link> */}

                  {hasTopics ? (
                    <Nav.Link
                      as={NavLink}
                      to={`/web/recentTopic/${firstTopic}`}
                      exact
                      className="custom-nav-link ne color-black firsttopic recent"
                      activeClassName="active-nav-link"
                    >
                      {firstTopic}
                    </Nav.Link>
                  ) : (
                    <></>
                  )}

                  {/* <Nav.Link
                    as={NavLink}
                    to="/web/In Depth"
                    exact
                    className="custom-nav-link ne color-black"
                    activeClassName="active-nav-link"
                  >
                    in-depth
                  </Nav.Link> */}

                  <NavDropdown
                    title={
                      <>
                        <span className="markets">markets</span>
                        <RiArrowDownSLine className="dropdown-icon" />
                      </>
                    }
                    id="basic-nav-dropdown"
                    className="custom-nav-link markets color-black dropdown-header"
                  >
                    {countryListOptions?.map((country, index) => (
                      <NavDropdown.Item
                        key={index}
                        onClick={() => handleCountryClick(country)}
                      >
                        {country?.Country_Name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>
              </Container>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {/* <Container>
          <div className="darkblue-banner">
           <img src={Hrbanner} />
          </div>
        </Container> */}
    </div>
  )
}

export default Header
