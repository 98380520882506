import { takeEvery, call, all, put, delay } from "redux-saga/effects"
import { actions } from './actions'
import axios from 'axios'
import { API_URL } from "utils/constant"
import { commonActions } from "../common/actions"

export const advertiseSagas = function* () {
    yield all([
        yield takeEvery(actions.CREATE_ADVERTISE, createAdvertise),
        yield takeEvery(actions.GET_ADVERTISE_LIST, getAdvertiseList),
        yield takeEvery(actions.GET_ADVERTISE_BY_ID, getAdvertiseById)
    ])
}

const createAdvertise = function* (data) {
    const { payload } = data
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/advertise/create`, payload)
        )
        if (result?.data?.statusCode === 200) {
            yield put({
                type: commonActions.SET_ALERT,
                payload: {
                    show: true,
                    status: "success",
                    message: result?.data?.message,
                },
            })

            yield put({
                type: actions.CREATE_ADVERTISE_RESPONSE,
                payload: result?.data
            })
            yield delay(2000)
            window.location.reload()
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: {
                    show: true,
                    status: "failed",
                    message: result?.data?.message,
                },
            })
        }
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    } catch (err) {
        console.log(err)
    }

}

const getAdvertiseList = function* () {
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/advertise/list`)
        )
        yield put({
            type: actions.SET_ADVERTISE_LIST,
            payload: result.data
        })
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
    } catch (err) {
        console.log(err)
    }
}

const getAdvertiseById = function* (data) {
    const { payload } = data
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/advertise/listById/${payload}`)
        )
        yield put({ type: actions.SET_ADVERTISE_BY_ID, payload: result?.data })
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
    } catch (err) {
        console.log(err)
    }
}
