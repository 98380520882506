import ContentCreatorsIndex from "components/contentCreators";
import React from "react";

export default function ContentCreators () {

    return(
        <div>
            <ContentCreatorsIndex />
        </div>
    )
}