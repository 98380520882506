import { takeEvery, all, call, put } from "redux-saga/effects";
import { creatorDashboardActions } from "./actions";
import { commonActions } from "../common/actions";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import { userAuthActions } from "../../store/userAuth/actions"

export const creatorDashboardSagas = function* () {
    yield all([
        yield takeEvery(creatorDashboardActions.GET_TOTAL_WALLET_COUNT, getTotalWalletCount),
        yield takeEvery(creatorDashboardActions.GET_CREATOR_CONTENT_GRAPH_COUNT, getCreatorContentGraphCount),
        yield takeEvery(creatorDashboardActions.GET_CREATOR_WALLET_DETAILS, getCreatorWalletDetails),
        yield takeEvery(creatorDashboardActions.GET_CREATOR_REVENUE_DETAILS, getCreatorRevenueDetails),
    ])
};


const getTotalWalletCount = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/contentCreator/totalCreatorWalletPrice/${payload?.Creator_Id}/${payload?.data?.Creator_Price}`)
        );
        yield put({
            type: creatorDashboardActions.SET_TOTAL_WALLET_COUNT,
            payload: result?.data,
        });
        if(result?.data.statusCode === 200){
            yield put({
                type: userAuthActions.VERIFY_TOKEN,
                payload: localStorage.getItem("GFX_TOKEN2"),
              })
        }
    } catch (err) {
        console.log(err);
    }
}

const getCreatorContentGraphCount = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/content/creator/graph/${payload.filterYear}/${payload.creatorId}`)
        );
        yield put({
            type: creatorDashboardActions.SET_CREATOR_CONTENT_GRAPH_COUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

const getCreatorWalletDetails = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/contentCreator/creatorWalletBalance/${payload}`)
        );
        yield put({
            type: creatorDashboardActions.SET_CREATOR_WALLET_DETAILS,
            payload: result?.data,
        });
    } catch (err) {
        console.log(err);
    }
}

const getCreatorRevenueDetails = function* (data) {
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/contentCreator/creatorRevenueDetailList/${payload?.Creator_Id}`)
        );
        yield put({
            type: creatorDashboardActions.SET_CREATOR_REVENUE_DETAILS,
            payload: result?.data,
        });
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}