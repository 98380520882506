import React, { useMemo, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContentCreatorActions } from "../../store/contentCreator/actions";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../common/Breadcrumb";
import TableContainer from '../../common/TableContainer';
import AdminPageLoader from "../../common/adminPageLoader";
import CustomPagination from "common/CustomPagination";

const ContentCreatorsIndex = () => {

  document.title = "Content Creators | Allen Dreyfus";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = useParams();

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const { content_Creators } = useSelector(state => state.ContentCreatorReducer);

  const { adminPageLoader } = useSelector(state => state.commonReducer);

  useEffect(() => {
    dispatch({
      type: ContentCreatorActions.GET_CONTENT_CREATORS_LIST_BY_STATUS,
      payload: type
    });
  }, [type]);

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Id',
      //   accessor: '_id',
      //   filterable: true,
      // },
      {
        Header: 'Name',
        accessor: 'Name',
        filterable: true,
      },
      {
        Header: 'Email',
        accessor: 'Email',
        filterable: true,
      },
      {
        Header: 'Contact Number',
        accessor: 'Contact_Number',
        filterable: true,
      },
      {
        Header: 'Created On',
        accessor: 'Created_On',
        filterable: true,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                  const Data = cellProps.row.original;
                  navigate('/content-creator-details', { state: { data: Data, type: cellProps?.row?.original?.Registration_Status } });
                }}
              >
                View
              </Button>
            </div>
          );
        }
      },
    ],
    []
  );

  const handleAddContentCreator = () => {
    navigate('/add-content-creator');
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {adminPageLoader ?
          <AdminPageLoader /> :
          <Container fluid>
            <Breadcrumbs title="Content Creators" breadcrumbItem={`${type} content creators`} />
            <Row>
              <Col xs="12">
                <div style={{ textAlign: 'right' }}>
                  {type === 'Registered' ? <button onClick={handleAddContentCreator} className="btn btn-primary list-add-button">add content creator</button> : <></>}
                </div>
                <Card className="payouts">
                  <CardBody>
                    {content_Creators && content_Creators?.length > 0 ? (
                      <React.Fragment>
                        <>
                          <TableContainer
                            columns={columns}
                            data={content_Creators || []}
                            isGlobalFilter={true}
                            isAddCustList={false}
                            isPagination={true} 
                            // isAddContentCreator={type === 'Registered' ? true : false}
                            // handleAddContentCreator={handleAddContentCreator}
                            customPageSize={itemsPerPage}
                            tableClass="align-middle table-nowrap"
                            isShowingPageLength={false}
                            iscustomPageSizeOptions={true}
                            pagination="pagination pagination-rounded justify-content-end mb-2"
                          />
                        </>
                      </React.Fragment>
                    ) : (
                      <div className="no-records">No records found</div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ContentCreatorsIndex;
