import EditContentCreatorDetailsIndex from "components/editContentCreatorDetails";
import React from "react";

export default function EditContentCreatorDetails() {

    return (
        <div>
            <EditContentCreatorDetailsIndex />
        </div>
    )
}