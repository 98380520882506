import React, { useEffect, useState } from "react"
import { Col, Form, Button, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { WebContentDeatilActions } from "store/webContentDetail/actions"
import { useForm } from "react-hook-form"
import NewsletterText from "../../assets/webImages/exclusive.webp"
import NewsletterMsgImg from "../../assets/webImages/Group 8.webp"
import { Spinner } from "reactstrap"
import UnsubscribeConfirmationModal from '../modals/UnsubscribeNewsletterModal'

const NewsletterSectionCommon = ({ NewsletterText, NewsletterMsgImg }) => {
  const dispatch = useDispatch()
  const { newsletterResponse, UnsubscribeNewsletterResponse } = useSelector(
    state => state.WebContentDetailReducer
  )
  const { buttonLoader } = useSelector(state => state.commonReducer);

  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const IsNewsLetterSubscribed = tokenResponse?.result?.IsNewsLetterSubscribed
  const Email = tokenResponse?.result?.Email

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm()

  const onSubmit = data => {
    const lowercaseEmail = data.Email.toLowerCase();
    const {
      Email,
      First_Name,
      Last_Name,
      receiveOffersAfricaReport,
      receiveOffersPartners,
    } = data
    if (!receiveOffersAfricaReport && !receiveOffersPartners) {
      alert("Please check both checkboxes before submitting")
      return
    }
    const payload = { First_Name, Last_Name, Email: lowercaseEmail }

    dispatch({
      type: WebContentDeatilActions.GET_WEB_NEWSLETTER_EMAIL,
      payload: payload,
    })

    // setShowModal(false)
    // reset()
  }

  const handleClose = () => {
    reset();
    setShowModal(false)
  }

  useEffect(() => {
    if (newsletterResponse?.statusCode === 200) {
      reset();
      setShowModal(false)
    }
  }, [newsletterResponse])



  const handleUnSubscribeClose = () => {
    setShowModal2(false)
  }

  const handleUnsubscribe = () => {
    dispatch({
      type: WebContentDeatilActions.UNSUBSCRIBE_NEWSLETTER,
      payload: Email,
    })
  }

  useEffect(() => {
    if (UnsubscribeNewsletterResponse?.statusCode === 200) {
      setShowModal2(false)
    }
  }, [UnsubscribeNewsletterResponse])

  return (
    <Col className="newsletter-section">
      <img src={NewsletterMsgImg} alt="newsletter" className="newsletter-img" />
      <div className="newsletter-content">
        <h4 className="web_fonts">
          <img
            src={NewsletterText}
            alt="newsletter"
            className="newsletterText"
          />
        </h4>
        <div className="newsletter-signup-btn">
          {IsNewsLetterSubscribed === 'Yes' ?
            <Button
              onClick={() => setShowModal2(true)}
              variant="primary signup-button custom-nav-link"
            >
              Unsubscribe
            </Button>
            :
            <Button
              onClick={() => setShowModal(true)}
              variant="primary signup-button custom-nav-link"
            >
              Sign up to our newsletter
            </Button>
          }
        </div>
      </div>

      <Modal
        show={showModal}
        // onHide={() => setShowModal(false)}
        onHide={() => handleClose()}
        backdrop="static"
        dialogClassName="newsletter-modal"
        className="modal-dialog-centered modal-lg newsletter-modal-alignment"
      >
        <div className="newsletter-modal-open">
          <Modal.Header closeButton className="news-modal-head">
            <div className="newsletter-header-title-modal">
              <Modal.Title>sign up to our newsletter</Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>

                <Col md={7}>
                  <Form.Group controlId="firstName">
                    <Form.Label className='web-form-label'>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter first name"
                      {...register("First_Name", {
                        required: "First name is required",
                        pattern: {
                          value: /^[A-Za-z]+$/,
                          message: "Invalid first name: alphabets only",
                        },
                      })}
                    />
                    {errors.First_Name && (
                      <span className="text-danger">
                        {errors.First_Name.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    controlId="lastName"
                    className="newsletter-modal-form-label"
                  >
                    <Form.Label className='web-form-label'>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter last name"
                      {...register("Last_Name", {
                        required: "Last name is required",
                        pattern: {
                          value: /^[A-Za-z]+$/,
                          message: "Invalid last name: alphabets only",
                        },
                      })}
                    />
                    {errors.Last_Name && (
                      <span className="text-danger">
                        {errors.Last_Name.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group
                    controlId="email"
                    className="newsletter-modal-form-label"
                  >
                    <Form.Label className='web-form-label'>Email address</Form.Label>
                    <Form.Control
                      className="formcontrol"
                      type="email"
                      placeholder="Enter email"
                      {...register("Email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.Email && (
                      <span className="text-danger">
                        {errors.Email.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <div className="newsletter-modal-rightside">
                    <p className="nl-rs-one">
                      get weekly deep dives into african market dynamics
                    </p>
                    <p className="nl-rs-one mt-md-4 mt-lg-4">
                      top stories direct from on-the-ground reporters
                    </p>
                    <p className="nl-rs-one mt-md-4 mt-lg-4">
                      anticipate major events and trends for the next week
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group
                    controlId="receiveOffers"
                    className="mb-3 receiveOffers-checkbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="i agree to receive email newsletters from allen dreyfus"
                      {...register("receiveOffersAfricaReport", {
                        required: true,
                      })}
                    />
                    <Form.Check
                      type="checkbox"
                      label={(
                        <span>
                          i have read and agree to the <a href="/terms-of-use">terms of Use</a> and  <a href="/privacy-notice">privacy notice</a>
                        </span>
                      )}
                      {...register("receiveOffersPartners", { required: true })}
                    />
                    {watch("receiveOffersAfricaReport") === false &&
                      watch("receiveOffersPartners") === false && (
                        <span className="text-danger mt-4">
                          please check both checkboxes before submitting.
                        </span>
                      )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="nl-submit-btn-container">
                <Button
                  type="submit"
                  variant="primary"
                  className="nl-ls-submit-btn"
                >
                  {buttonLoader ? <Spinner color="light" size="sm" /> : "Submit"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>

      <UnsubscribeConfirmationModal showModal2={showModal2} handleUnsubscribe={handleUnsubscribe} handleUnSubscribeClose={handleUnSubscribeClose} />
    </Col>
  )
}

export default NewsletterSectionCommon
