import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../store/Advertise/actions'
import { Spinner } from 'reactstrap'
import Logoimage from "../../assets/webImages/png-logo-color.webp"

const AdvertiseWithUsPage = () => {
  document.title = "Advertise with us"
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [message, setMessage] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { createAdvertiseResponse } = useSelector(state => state.advertiseReducer)
  const { buttonLoader } = useSelector(state => state.commonReducer);

  const handleNameChange = event => {
    setName(event.target.value)
  }

  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handleCompanyNameChange = event => {
    setCompanyName(event.target.value)
  }

  const handleMessageChange = event => {
    setMessage(event.target.value)
  }

  const isEmailValid = () =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) || !isFormSubmitted

  const checkValidations = () => {
    let letters = /^[a-zA-Z\s]*$/
    if (name.trim() === "") {
      setNameError("Please fill the name")
    } else {
      if (!letters.test(name)) {
        setNameError("Please enter only characters!")
      } else {
        setNameError("")
      }
    }
    if (email.trim() === "") {
      setEmailError("Please enter the email")
    } else {
      if (!letters.test(email)) {
        setEmailError("Please enter a valid email")
      } else {
        setEmailError("")
      }
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault()
    checkValidations()
    setIsFormSubmitted(true)
    let emailValid = isEmailValid()
    if (name, email, companyName, message, emailValid) {
      const lowercaseEmail = email.toLowerCase();
      const payload = {
        Fullname: name,
        Email: lowercaseEmail,
        CompanyName: companyName,
        Message: message
      }
      dispatch({
        type: actions.CREATE_ADVERTISE,
        payload: payload
      })
    }
  }

  const handleFormReset = () => {
    setName("")
    setEmail("")
    setCompanyName("")
    setMessage("")
    setNameError("")
    setEmailError("")
    setIsFormSubmitted(false)
  }

  useEffect(() => {
    if (createAdvertiseResponse?.statusCode === 200) {
      handleFormReset()
    }
  })

  const handleBackToHome = () => {
    navigate("/")
  }

  return (
    <>
      <Row>
        <Col md={12} className="advertise-logo">
          {/* <Button className="ad-back-to-home" onClick={handleBackToHome}>
            Back to Home
          </Button> */}
          <img src={Logoimage} alt="logo" className="logo-ad" onClick={handleBackToHome} />
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-center mt-3 ad-full-div">
          <Col md={6}>
            <div className="ad-form">
              <h4 className="text-lower adv-header">advertise with us </h4>
              <h6 className="ad-form-headline">Send us a message</h6>
              <Form id="submit-form" onSubmit={handleFormSubmit}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Your Full Name*"
                    value={name}
                    onChange={handleNameChange}
                    required
                  />
                  {
                    nameError &&
                    <Form.Text className="ad-name-error text-danger">{nameError}</Form.Text>

                  }
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    className="formcontrol "
                    type="email"
                    placeholder="Your Email*"
                    value={email}
                    onChange={handleEmailChange}
                    isInvalid={!isEmailValid() && isFormSubmitted}
                    required
                  />
                  {(!isEmailValid() && isFormSubmitted) &&
                    <Form.Text className="text-danger">{emailError}</Form.Text>
                  }
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="Company Name*"
                    value={companyName}
                    onChange={handleCompanyNameChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={7}
                    placeholder="Your Message*"
                    value={message}
                    onChange={handleMessageChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="checkbox"
                    className="ad-form-bottom text-lower advertise "
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        By clicking submit, you confirm that you have read and
                        agree to our <a href="/terms-of-use">terms of use</a>{" "}
                        and <a href="/privacy-notice">privacy notice</a>.
                      </span>
                    }
                    required
                  />
                </Form.Group>

                <Form.Group className="ad-button-group">
                  <Button
                    variant="primary"
                    type="submit"
                    className="submit-cancel "
                  >
                    {buttonLoader ? <Spinner color="light" size="sm" /> : "Submit"}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleFormReset}
                    className="submit-cancel"
                  >
                    Reset
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
          <Col md={6}>
            <div className="ad-contacts">
              <ul>
                <p className="text-lower ">
                  Allen Dreyfus is a digital platform offering real-time
                  financial news from frontier markets, customized for global
                  market professionals. Our audience includes investment banks,
                  institutional investors, fund and asset managers, brokers,
                  traders, and analysts who rely on our content to navigate
                  financial risks.
                </p>
                <p className="text-lower ">
                  At Allen Dreyfus, we offer advertisers a unique opportunity to
                  connect with our audience through our various platforms: our
                  website, mobile application, and podcast. Reach a diverse and
                  engaged audience with your message and elevate your brand's
                  presence with Allen Dreyfus.
                </p>
                <span className="ad-hightlight-contact-info">
                  <li className="ad-email-info text-lower">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    {/* Contact us at:{" "} */}
                    <a href="mailto:advertising@allendreyfus.com" className='linkColor'>
                      advertising@allendreyfus.com
                    </a>
                  </li>

                  <li className="ad-email-info text-lower">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span>
                      <a href="tel:+4403308285058" className='linkColor '>
                        +44 (0) 330 828 5058</a>
                    </span>
                  </li>
                </span>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdvertiseWithUsPage
