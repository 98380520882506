import { WebBookmarkActions } from "./actions"

const initialState = {
  AddToBookMark: [],
  ListofBookMarks: [],
  DeleteBookMark:[],
}

export const WebBookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case WebBookmarkActions.SET_WEB_ADD_TO_BOOKMARK:
      return {
        ...state,
        AddToBookMark: action.payload,
      }

    case WebBookmarkActions.SET_LIST_OF_BOOKMARKS_DATA:
      return {
        ...state,
        ListofBookMarks: action.payload,
      }

      
      case WebBookmarkActions.SET_DELETE_THE_BOOKMARK:
        return {
          ...state,
          DeleteBookMark: action.payload,
        }

    default:
      return state
  }
}
