import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerActions } from "../../store/customer/actions"
import TableContainer from "../../common/TableContainer"
import Breadcrumbs from "../../common/Breadcrumb"
import AdminPageLoader from "../../common/adminPageLoader";
import { corporateSubscriptionActions } from "../../store/corporateSubscription/actions"
import { CSVLink } from "react-csv"
import { Button } from "react-bootstrap"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from "common/CustomPagination";

const corporateSubscriptionIndex = () => {
  document.title = "Corporate | Allen Dreyfus"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [exportStatus, setExportStatus] = useState(null)

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const [corporateSubscription, setCorporateSubscription] = useState([])

  const { adminPageLoader } = useSelector(state => state.commonReducer)
  const { adminCorporateList } = useSelector(
    state => state.corporateSubscriptionReducer
  )

  useEffect(() => {
    dispatch({
      type: corporateSubscriptionActions.GET_ADMIN_CORPORATE_LIST,
    })
  }, [dispatch])

  useEffect(() => {
    if (adminCorporateList?.result?.length !== 0) {
      setCorporateSubscription(adminCorporateList?.result)
    }
  }, [adminCorporateList?.result])

  const csvHeaders = [
    { label: "First Name", key: "First_Name" },
    { label: "Last Name", key: "Last_Name" },
    { label: "Phone Number", key: "Phone_Number" },
    { label: "Email", key: "Email" },
    { label: "Company Name", key: "Company_Name" },
    { label: "Country", key: "Country" },
    { label: " How we can help you", key: "How_To_Help" },
  ]

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "First_Name",
        filterable: true,
      },
      {
        Header: "Last Name",
        accessor: "Last_Name",
        filterable: true,
      },
      {
        Header: "Phone Number",
        accessor: "Phone_Number",
        filterable: true,
      },
      {
        Header: "Email",
        accessor: "Email",
        filterable: true,
      },
      {
        Header: "Company Name",
        accessor: "Company_Name",
        filterable: true,
      },
      {
        Header: "Country",
        accessor: "Country",
        filterable: true,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                  const corporateSubscriptionData = cellProps.row.original;
                  navigate("/corporate-subscription-detail", {
                    state: corporateSubscriptionData,
                  });
                }}
              >
                View
              </Button>
            </div>
          );
        }
      },
    ],
    []
  )

  const handleSuccess = () => {
    setExportStatus("success")
    toast.success("CorporateSubsciption Export successful!", { position: toast.POSITION.TOP_CENTER })
  }

  const handleError = error => {
    setExportStatus("error")
    toast.error("Failure Export", { position: toast.POSITION.TOP_CENTER })
  }


  return (
    <React.Fragment>
      <div className="page-content">
        {adminPageLoader ? (
          <AdminPageLoader />
        ) : (
          <Container fluid>
            <Breadcrumbs title="Corporate Subscription" breadcrumbItem="List" />
            <Row>
              <Col xs="12">
                <Card className="Corporate Subscription">
                  <CardBody>
                    {corporateSubscription &&
                      corporateSubscription?.length > 0 ? (
                      <React.Fragment>
                        {/* Need approval from client */}
                        {/* <div className="d-flex justify-content-end mb-2">
                          <CSVLink
                            data={corporateSubscription}
                            headers={csvHeaders}
                            filename={"corporateSubscription_data.csv"}
                            onClick={() => handleSuccess()}
                            onError={handleError}
                          >
                            <Button color="primary">Export</Button>
                          </CSVLink>
                        </div> */}
                        <TableContainer
                          columns={columns}
                          data={corporateSubscription || []}
                          isGlobalFilter={true}
                          isAddCustList={false}
                          isPagination={true}
                          customPageSize={itemsPerPage}
                          tableClass="align-middle table-nowrap"
                          isShowingPageLength={false}
                          iscustomPageSizeOptions={true}
                          pagination="pagination pagination-rounded justify-content-end mb-2"
                        />                        
                      </React.Fragment>
                    ) : (
                      <div className="no-records">No records found</div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default corporateSubscriptionIndex
