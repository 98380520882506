import Newsletterindex from "components/NewsletterList";
import React from "react";

export default function Newsletterlist () {

    return (
        <div>
            <Newsletterindex />
        </div>
    )
}