import React, { useEffect, useMemo, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "../../common/TableContainer"
import Breadcrumbs from "../../common/Breadcrumb"
import AdminPageLoader from "../../common/adminPageLoader";
import { actions } from "../../store/Advertise/actions"
import { Button } from "react-bootstrap";
import CustomPagination from "common/CustomPagination";

const AdvertiseWithUsListIndex = () => {
    document.title = "Advertise With Us list | Allen Dreyfus"
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [listOfAdvertise, setListOfAdvertise] = useState([])

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const { adminPageLoader } = useSelector(state => state.commonReducer)
    const { advertiseList } = useSelector(
        state => state.advertiseReducer
    )

    useEffect(() => {
        dispatch({
            type: actions.GET_ADVERTISE_LIST,
        })
    }, [])

    useEffect(() => {
        if (advertiseList?.result?.length !== 0) {
            setListOfAdvertise(advertiseList?.result)
        }
    }, [advertiseList])

    const columns = useMemo(
        () => [
            {
                Header: "Full Name",
                accessor: "Fullname",
                filterable: true,
            },
            {
                Header: "Email",
                accessor: "Email",
                filterable: true,
            },
            {
                Header: "Company Name",
                accessor: "CompanyName",
                filterable: true,
            },
            {
                Header: "Submitted On",
                accessor: "Created_On",
                filterable: true,
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const AdvertiseDetails = cellProps.row.original;
                                    navigate("/advertise-details", {
                                        state: AdvertiseDetails,
                                    });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ],
        []
    )

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ? (
                    <AdminPageLoader />
                ) : (
                    <Container fluid>
                        <Breadcrumbs title="Advertise with us" breadcrumbItem="List" />
                        <Row>
                            <Col xs="12">
                                <Card className="Advertise with us">
                                    <CardBody>
                                        {listOfAdvertise &&
                                            listOfAdvertise?.length > 0 ? (
                                            <React.Fragment>
                                                <TableContainer
                                                    columns={columns}
                                                    data={listOfAdvertise || []}
                                                    isGlobalFilter={true}
                                                    isAddCustList={false}
                                                    isPagination={true}
                                                    customPageSize={itemsPerPage}
                                                    tableClass="align-middle table-nowrap"
                                                    isShowingPageLength={false}
                                                    iscustomPageSizeOptions={true}
                                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                                />                                                
                                            </React.Fragment>
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </React.Fragment>
    )
}

export default AdvertiseWithUsListIndex
