import { actions } from "react-table"
import { BlogContentActions } from "./actions"

const initialState = {
  contentList: [],
  contentDetail: null,
  recentTopicList: [],
  countryList: [],
  onlyCountryList: [],
  ContentNeedApprovalList: [],
  ContentNeedApprovalCount: null,
  ContentByStatus: [],
  adminContentDetail: null,
  contentListByCreatorIDForApproval: [],
  contentListByCreatorIDByStatus: [],
  pitchListByCreatorID: [],
  adminCreatorPitchNeedApprovalList: [],
  adminCreatorPitchListByStatus: [],
  creatorPitchListByStatus: [],
  creatorPitchDetail: [],
  blogContentResponse: null,
  pitchToContentList: [],
  NewsletterUserEmailList: [],
}

export const BlogContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case BlogContentActions.SETCONTENTLIST:
      return {
        ...state,
        contentList: action.payload,
      }
    case BlogContentActions.SETCONTENTDETAIL:
      return {
        ...state,
        contentDetail: action.payload,
      }
    case BlogContentActions.SET_RECENT_TOPIC_LIST:
      return {
        ...state,
        recentTopicList: action.payload,
      }

    case BlogContentActions.SET_ADMIN_CONTENT_LIST_NEED_APPROVAL: {
      return {
        ...state,
        ContentNeedApprovalList: action.payload,
      }
    }
    case BlogContentActions.SET_ADMIN_CONTENT_NEED_APPROVAL_COUNT: {
      return {
        ...state,
        ContentNeedApprovalCount: action.payload,
      }
    }
    case BlogContentActions.SET_ADMIN_CONTENT_LIST_BY_STATUS: {
      return {
        ...state,
        ContentByStatus: action.payload,
      }
    }
    case BlogContentActions.SET_ADMIN_CONTENT_DETAIL: {
      return {
        ...state,
        adminContentDetail: action.payload,
      }
    }
    case BlogContentActions.BLOG_CONTENT_RESPONSE:
      return {
        ...state,
        blogContentResponse: action.payload,
      }
    case BlogContentActions.SET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL:
      return {
        ...state,
        contentListByCreatorIDForApproval: action.payload,
      }
    case BlogContentActions.SET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS:
      return {
        ...state,
        contentListByCreatorIDByStatus: action.payload,
      }
    case BlogContentActions.SET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL:
      return {
        ...state,
        pitchListByCreatorIDForApproval: action.payload,
      }
    case BlogContentActions.SET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST:
      return {
        ...state,
        adminCreatorPitchNeedApprovalList: action.payload,
      }
    case BlogContentActions.SET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS:
      return {
        ...state,
        adminCreatorPitchListByStatus: action.payload,
      }
    case BlogContentActions.SET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS:
      return {
        ...state,
        creatorPitchListByStatus: action.payload,
      }
    case BlogContentActions.SET_CREATOR_PITCH_DETAIL:
      return {
        ...state,
        creatorPitchDetail: action.payload,
      }
    case BlogContentActions.SET_PITCH_TO_CONTENT_LIST:
      return {
        ...state,
        pitchToContentList: action.payload,
      }
    case BlogContentActions.SET_NEWSLETTER_USER_EMAIL_LIST:
      return {
        ...state,
        NewsletterUserEmailList: action.payload,
      }
    default:
      return state
  }
}
