import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from '../../common/TableContainer';
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { corporateSubscriptionActions } from '../../store/corporateSubscription/actions';

export default function corporateSubscriptionDetailIndex() {
    document.title = "Corporate | Allen Dreyfus";

    const { state } = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { adminPageLoader  } = useSelector(state => state.commonReducer);
    const { adminCorporateDetail } = useSelector(state => state.corporateSubscriptionReducer);

    const Id = state?._id;

    useEffect(() => {
        dispatch({ type: corporateSubscriptionActions.GET_ADMIN_CORPORATE_DETAIL, payload: Id });
    }, [Id]);


    return (
        <React.Fragment>
            <div className="page-content">
            {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Corporate Subscription"
                            titleLink="/corporate-subscription"
                            breadcrumbItem="details"
                        />
                        <h4 className="card-title mb-4">Details</h4>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <Row>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">First Name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={adminCorporateDetail?.First_Name}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Last Name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={adminCorporateDetail?.Last_Name}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Phone Number</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={adminCorporateDetail?.Phone_Number}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={adminCorporateDetail?.Email}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Company Name

                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={adminCorporateDetail?.Company_Name
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Country
                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={adminCorporateDetail?.Country}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">How we can help you

                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminCorporateDetail?.How_To_Help}
                                            disabled
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate('/corporate-subscription')}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
};