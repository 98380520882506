import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Modal,
  Container,
  Row,
  Col,
  Image,
  FormControl,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap"
import Logoimage from "../../assets/webImages/png-logo-color.webp"
import { FaUserCircle, FaTimes } from "react-icons/fa"
import { CiSearch } from "react-icons/ci"
import { IoMdClose } from "react-icons/io"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { userAuthActions } from "store/userAuth/actions"
import LogoutModal from "webComponents/modals/LogoutModal"
import NavDropdown from "react-bootstrap/NavDropdown"
import { useDispatch } from "react-redux"
import TextBgColors, { getColorByLetter } from "../Common/TextBgColors"
import { BlogContentActions } from "store/blogContent/actions"
import NoDataFound from "../Common/NoRecords"
import { WebHomePageActions } from "store/webHome/actions"
import WebPageLoader from "../../common/webPageLoader"

const SearchModal = ({ show, handleClose, setShowSearchModal }) => {
  const [searchText, setSearchText] = useState("")
  const [FinalSearchList, setFinalSearchList] = useState([])
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { ContentByStatus } = useSelector(state => state.BlogContentReducer)
  const { ProfileUserInfo } = useSelector(state => state.WebHomePageReducer)
  const [profileImage, setProfileImage] = useState("")

  const [fetchSuccess, setFetchSuccess] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setProfileImage(ProfileUserInfo?.result?.Profile_Image || "")
  }, [ProfileUserInfo])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClearSearch = () => {
    setSearchText("")
  }

  const handleLoginClick = () => {
    navigate("/web/login")
  }

  const handleLogout = () => {
    setShowLogoutModal(true)
  }

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false)
  }

  const handleLogoutConfirmed = () => {
    // Perform logout logic
    if (tokenResponse?.result?.Type_Of_User === "User") {
      localStorage.removeItem("WEB_TOKEN")
      dispatch({
        type: userAuthActions.UPDATE_AUTH_DETAILS,
        payload: { isAuthenticated: false },
      })
    }
    setShowLogoutModal(false)
  }

  // const UserDisplay = ({ firstName }) => {
  //   const bgColor = getColorByLetter(firstName?.charAt(0))
  //   return (
  //     <div className="login-link">
  //       <NavDropdown
  //         title={
  //           <div>
  //             <span className="login-text color-black">{firstName}</span>
  //             {profileImage ? (
  //               <img
  //                 src={profileImage}
  //                 alt="Profile"
  //                 className="rounded-background-profile-img"
  //                 style={{ background: bgColor }}
  //               />
  //             ) : (
  //               <div
  //                 className="rounded-background"
  //                 style={{ background: bgColor }}
  //               >
  //                 {firstName?.charAt(0)?.toUpperCase()}
  //               </div>
  //             )}
  //           </div>
  //         }
  //         id="basic-nav-dropdown"
  //         className="profile-dropdown color-black"
  //       >
  //         {/* <NavDropdown.Item href="#">Profile</NavDropdown.Item> */}
  //         <NavDropdown.Item href="#" onClick={handleLogout}>
  //           Logout
  //         </NavDropdown.Item>
  //       </NavDropdown>
  //     </div>
  //   )
  // }

  useEffect(() => {
    setLoader(true)
    dispatch({
      type: BlogContentActions.GET_ADMIN_CONTENT_LIST_BY_STATUS,
      payload: "Approved",
    })
  }, [])


  const filteredEntries = ContentByStatus?.filter(item => {
    const searchTextLower = searchText.toLowerCase();

    const titleMatches = item.Title?.toLowerCase().includes(searchTextLower);

    const categories = item?.Category || [];
    const categoryMatches = categories.some(category => category.toLowerCase().includes(searchTextLower));

    const subCategories = item?.Sub_Category || [];
    const subCategoryMatches = subCategories.some(subCategory => subCategory.toLowerCase().includes(searchTextLower));

    const countries = item?.Country || [];
    const countryMatches = countries.some(country => country.toLowerCase().includes(searchTextLower));

    const storyMatch = item.Story?.toLowerCase().includes(searchTextLower);

    return titleMatches || categoryMatches || subCategoryMatches || countryMatches || storyMatch;
  });

  useEffect(() => {
    if (filteredEntries?.result?.length === 0) {
      setLoader(false)
      setFetchSuccess(false)
    } else {
      setLoader(false)
      setFetchSuccess(true)
    }
  }, [filteredEntries])

  function handleClick(single) {
    if (single.Podcast && single.Podcast.length > 0) {
      navigate("/web/Podcast")
    } else {
      navigate(`/web/content/${single._id}`, {
        state: { contentDetail: single },
      })
      setShowSearchModal(false)
      setSearchText("")
      window.scrollTo({ top: 0, behavior: "smooth" })
      dispatch({
        type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS,
        payload: single._id,
      })
    }
  }

  useEffect(() => {
    if (searchText === "" && ContentByStatus?.length !== 0) {
      setFinalSearchList(ContentByStatus?.slice(0, 4))
    } else {
      setFinalSearchList(filteredEntries)
    }
  }, [ContentByStatus, searchText])

  const handleCloseModal = () => {
    handleClose()
    setSearchText("")
  }

  const handlePopularSearch = categoryValue => {
    navigate(`/web/${categoryValue}`)
    setSearchText("")
    setShowSearchModal(false)
  }

  const handleBookmarks = () => {
    navigate("/Save")
  }

  const UserDisplay = ({ firstName }) => {
    const bgColor = getColorByLetter(firstName?.charAt(0))

    return (
      <div className="login-link">
        <NavDropdown
          title={
            <div>
              <span className="login-text color-black">{firstName}</span>
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="rounded-background-profile-img"
                  style={{ background: bgColor }}
                />
              ) : (
                <div
                  className="rounded-background"
                  style={{ background: bgColor }}
                >
                  {firstName?.charAt(0)?.toUpperCase()}
                </div>
              )}
            </div>
          }
          id="basic-nav-dropdown"
          className="profile-dropdown color-black"
        >
          <NavDropdown.Item href="/web/Profile">Profile</NavDropdown.Item>
          <NavDropdown.Item className="bookmark-nav" onClick={handleBookmarks}>
            BookMarks
          </NavDropdown.Item>
          <li>
            <hr className="dropdown-divider" />
          </li>

          <NavDropdown.Item href="#" onClick={handleLogout}>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    )
  }

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      fullscreen
      style={{
        height: "90%",
        borderEndStartRadius: "20px",
        borderEndEndRadius: "20px",
      }}
    >
      <Modal.Header className="search-model-close" closeButton>
        {" "}
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="align-items-center">
            <Col xs="auto">
              <a href="/">
                <Image src={Logoimage} alt="Logo" className="logo-ad" />
              </a>
            </Col>
            <Col xs="auto" className="ms-auto">
              <div className="text-end">
                {tokenResponse ? (
                  // User is logged in
                  <UserDisplay firstName={tokenResponse?.result?.First_Name} />
                ) : (
                  // User is not logged in
                  <div className="login-link" onClick={handleLoginClick}>
                    <FaUserCircle className="login-icon color-black" />
                    <span className="login-text color-black d-none d-sm-inline">log in</span>
                  </div>
                )}
              </div>
            </Col>
            <LogoutModal
              show={showLogoutModal}
              handleClose={handleCloseLogoutModal}
              handleLogoutConfirmed={handleLogoutConfirmed}
            />
          </Row>
        </Container>

        <Container>
          <Col className="searchbar-cont">
            <InputGroup className="mb-3 inputgrp">
              <FormControl
                type="text"
                placeholder=""
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
              <Button
                variant="outline-white"
                onClick={handleClearSearch}
                className="search-close color-black"
              >
                <IoMdClose />
              </Button>
              <Button
                variant="outline-white"
                className="search-search color-black"
              >
                <CiSearch />
              </Button>
            </InputGroup>
          </Col>

          <Row className="justify-content-center">
            <Col>
              <a className="pop-search web_fonts">popular search : </a>

              <a
                className="pop-search-links web_fonts"
                onClick={() => handlePopularSearch("Politics")}
              >
                politics
              </a>

              <a
                className="pop-search-links web_fonts"
                onClick={() => handlePopularSearch("Business")}
              >
                business
              </a>
              {/* <a className="pop-search-links web_fonts" href="#">
                green
              </a> */}
              {/* <a className="pop-search-links web_fonts" href="#">
                peace
              </a>
              <a className="pop-search-links web_fonts" href="#">
                eco-politics
              </a> */}
            </Col>
          </Row>
          {loader ? (
            <WebPageLoader />
          ) : (
            <>
              {fetchSuccess ? (
                <>
                  <Row className="pop-search-res">
                    {filteredEntries?.length === 0 ? (
                      <NoDataFound />
                    ) : (
                      <>
                        {FinalSearchList?.map((single, Index) => (
                          <Col
                            xs={6}
                            sm={6}
                            md={3}
                            className="searchmodal"
                            key={Index}
                            onClick={() => handleClick(single)}
                          >
                            <div>
                              <img
                                src={
                                  single.TypeOfContent === "Article"
                                    ? single.Article_Images[0]
                                    : single.TypeOfContent === "Podcast"
                                      ? single.Thumbnail_Image
                                      : ""
                                }
                                alt="Topic 1 Image"
                              />
                              <h6 className="web_fonts color-black">
                                {single?.Title?.length > 60
                                  ? `${single?.Title.slice(0, 60)}...`
                                  : single?.Title}
                              </h6>
                            </div>
                          </Col>
                        ))}
                      </>
                    )}
                    {/* <Col xs={12} sm={6} md={3} className="searchmodal">
                <div>
                  <img src={Pol2} alt="Topic 1 Image" />

                  <h6 className="web_fonts color-black">
                    Nigeria: President Tinubu is kingmaker in oil-rich Rivers
                    State
                  </h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} className="searchmodal">
                <div>
                  <img src={pol3} alt="Topic 1 Image" />

                  <h6 className="web_fonts color-black">
                    Kenya: Pressure on Ruto to reprimand Transport minister for
                    saying Rwanda is an autocracy
                  </h6>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} className="searchmodal">
                <div>
                  <img src={Pol4} alt="Topic 1 Image" />

                  <h6 className="web_fonts color-black">
                    As DRC votes, what kind of economy will the victor inherit?
                  </h6>
                </div>
              </Col> */}
                  </Row>
                </>
              ) : (
                <NoDataFound />
              )}
            </>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default SearchModal
