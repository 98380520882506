import React, { useEffect, useState } from "react"
import { Modal, Button, Form } from "react-bootstrap"
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { userAuthActions } from "../../store/userAuth/actions"
import { WebHomePageActions } from "store/webHome/actions"
import { Spinner } from "reactstrap"

const UserChangePassword = ({ show, handleClose }) => {
  const dispatch = useDispatch()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { UserChangePassword } = useSelector(state => state.WebHomePageReducer)
  const { buttonLoader } = useSelector(state => state.commonReducer)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm()

  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [password, setPassword] = useState(null)
  const [oldPasswordError, setOldPasswordError] = useState("")


  const onSubmit = data => {
    setPassword(data.newPassword)
    dispatch({
      type: WebHomePageActions.WEB_USER_CHANGE_PASSWORD,
      payload: {
        Id: tokenResponse?.result?._id,
        payload: {
          Type_Of_User: "User",
          Password: data.newPassword,
        },
      },
    })
  }

  const checkOldPassword = value => {
    if (value !== tokenResponse?.result?.Password) {
      setOldPasswordError("Incorrect old password")
      return false
    } else {
      setOldPasswordError("")
      return true
    }
  }

  useEffect(() => {
    if (UserChangePassword?.statusCode === 200) {
      setValue("oldPassword", "");
      setValue("newPassword", "");
      setValue("confirmPassword", "");
      setOldPasswordError("")
      handleClose()
      setError('oldPassword', { message: "" })
      dispatch({ type: userAuthActions.VERIFY_TOKEN, payload: localStorage.getItem("WEB_TOKEN") })
    }
  }, [UserChangePassword])

  const clearError = (fieldName) => {
    if (errors[fieldName]) {
      setError(fieldName, { type: "manual", message: "" });
      setOldPasswordError("")
    }

  };

  return (
    <Modal show={show} onHide={handleClose} className="user-password-change">
      <Modal.Header closeButton>
        <Modal.Title className="cont-det-new-font color-black">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="user-form-padding">
          <Form.Group controlId="oldPassword">
            <Form.Label className="cont-det-new-font color-black ">
              Old Password{" "}
            </Form.Label>
            <div className="password-input-container">
              <Form.Control
                type={showOldPassword ? "text" : "password"}
                {...register("oldPassword", {
                  required: "Old Password is required",
                  validate: value => value === "" || checkOldPassword(value),
                })}
                onChange={() => clearError("oldPassword")}
              />
              <span
                className="password-span"
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                {showOldPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </span>
            </div>
            {errors.oldPassword && (
              <span className="text-danger">{errors.oldPassword.message}</span>
            )}
            {oldPasswordError && (
              <span className="text-danger">{oldPasswordError}</span>
            )}
          </Form.Group>

          <Form.Group controlId="newPassword">
            <Form.Label className="cont-det-new-font color-black">
              New Password{" "}
            </Form.Label>
            <div className="password-input-container">
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                {...register("newPassword", {
                  required: true,
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/,
                    message: "strong password is required",
                  },
                })}
              />
              <span
                className="password-span"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </span>
            </div>
            {errors.newPassword && (
              <span className="text-danger">{errors.newPassword.message}</span>
            )}
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Label className="cont-det-new-font color-black">
              Confirm New Password{" "}
            </Form.Label>
            <div className="password-input-container">
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                {...register("confirmPassword", {
                  required: true,
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/,
                    message: "strong password is required",
                  },
                  validate: value => value === watch("newPassword"),
                })}
              />
              <span
                className="password-span"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </span>
            </div>
            {errors.confirmPassword && (
              <span className="text-danger">
                {errors.confirmPassword.message}
              </span>
            )}
            {errors.confirmPassword?.type === "validate" && (
              <span className="text-danger">Password does not match</span>
            )}
          </Form.Group>
          <div className="change-password-btn">
            <Button
              variant="primary"
              type="submit"
              className="cont-det-new-font"
            >
              {buttonLoader ? <Spinner color="light" size="sm" /> : "Save Changes"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default UserChangePassword
