import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";

import CardUser from './card-user';
import Breadcrumbs from "../../common/Breadcrumb";
import DashboardCount from "./dashboard-count";
import MonthlyEarning from "./monthly-earnings";
import ContentPublishedChart from "./content-published-chart";
import SubscribersCount from "./subscribers-count";
import NeedApprovalList from "./need-approval-list";
import { useSelector } from "react-redux";
import AdminPageLoader from "../../common/adminPageLoader";

const DashboardIndex = props => {

  const { adminPageLoader } = useSelector(state => state.commonReducer);

  //meta title
  document.title = "Dashboard | Allen Dreyfus";
  return (
    <React.Fragment>
      <div className="page-content">
        {/* {adminPageLoader ?
          <AdminPageLoader /> : */}
          <Container fluid>
            <Row>
              {/* <Col xl={12}>
                <Breadcrumbs
                  title={props.t("Dashboards")}
                  breadcrumbItem={props.t("Dashboard")}
                />
              </Col> */}
              <Col xl={12}>
                <CardUser />
              </Col>
              <Col xl={4}>
                <SubscribersCount />
                <MonthlyEarning />
              </Col>
              <Col xl={8}>
                <DashboardCount />
                <ContentPublishedChart />
              </Col>
              <Col xl={12}>
                <NeedApprovalList />
              </Col>
            </Row>
          </Container>
        {/* } */}
      </div>
    </React.Fragment>
  );
};

DashboardIndex.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(DashboardIndex);
