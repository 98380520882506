import React, { useEffect, useState, useRef } from "react";
import { Container, Card, CardBody, CardTitle, Button, Input, Label, Row, Col, Table, FormFeedback, Form, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../store/admin/actions";
import { commonActions } from "../../store/common/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import profileicon from '../../assets/images/dashboard/user3.png';
import UpdateCreatorStatusModal from "components/commonModals/updateCreatorStatusModal";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FileSizeValidation from '../../FileSizeValidation/index'
import uploadImage from "../../../src/assets/webImages/upload3.png"

export default function SubAdminProfileIndex({ }) {
    document.title = "Profile | Allen Dreyfus";
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [profileImage, setProfileImage] = useState("")
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const { adminTokenResponse } = useSelector(state => state.userAuthReducer);

    const { buttonLoader, adminPageLoader } = useSelector(state => state.commonReducer);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: name,
            Last_Name: lastName,
            Email: email,
            Contact_Number: contactNo,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("First Name is required"),
            Email: Yup.string().required("Email is required"),
            Contact_Number: Yup.string().required('contact number is required'),
        }),
        onSubmit: (data) => {
            const formData = new FormData();
            formData.append("Name", data.Name);
            formData.append("Last_Name", data.Last_Name);
            formData.append("Email", data.Email);
            formData.append("Contact_Number", data.Contact_Number);

            if (selectedFile) {
                formData.append("Profile_Image", selectedFile)
                dispatch({
                    type: adminActions.UPDATE_ADMIN,
                    // payload: { Id: tokenResponse?.result?._id, data: formData }
                    payload: { Id: adminTokenResponse?.result?._id, data: formData }
                });
            } else {
                dispatch({
                    type: adminActions.UPDATE_ADMIN,
                    payload: { Id: adminTokenResponse?.result?._id, data: formData }
                });
            }
        }
    });

    useEffect(() => {
        if (adminTokenResponse) {
            setName(adminTokenResponse?.result?.Name);
            setLastName(adminTokenResponse?.result?.Last_Name || "");
            setEmail(adminTokenResponse?.result?.Email);
            setContactNo(adminTokenResponse?.result?.Contact_Number);
            setProfileImage(adminTokenResponse?.result?.Profile_Image);
        }
    }, [adminTokenResponse])

    useEffect(() => {
        setName(validation.values.Name);
        setEmail(validation.values.Email);
    }, [validation.values]);

    const fileInputRef = useRef(null);

    const handleFileSelect = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            let result = FileSizeValidation(event.target.files);
            if (result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
                    result?.FinalImages[0]?.type === 'image/png') {
                    const imageUrl = URL.createObjectURL(selectedFile);
                    setSelectedImage(imageUrl);
                    setSelectedFile(selectedFile);
                } else {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { show: true, status: "failed", message: "Unsupported File Format" }
                    })
                }
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                {/* {adminPageLoader ?
                    <AdminPageLoader /> : */}
                <Container fluid>
                    <Breadcrumb
                        title="Profile"
                        breadcrumbItem="details"
                    />
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div onClick={handleFileSelect}>
                                                {selectedImage ? (
                                                    <img
                                                        src={selectedImage}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                ) : (
                                                    <img
                                                        src={profileImage ?? profileicon}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                )}

                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                    accept="image/jpg,image/jpeg,image/png"
                                                />
                                                <img className="uploadProfile" src={uploadImage} alt="upload-profile" />
                                                {/* <i className="mdi mdi-arrow-up-bold-circle" ></i> */}
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted profileAlignment">
                                                    <h5 className="proName">{name}</h5>
                                                    <p className="mb-1">{email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <small className="text-success pt-5"><i><span className="text-danger">*</span> Support's .jpeg, .jpg, .png</i></small>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <CardTitle className="card-title mb-4">Admin Details</CardTitle>
                                <div className="form-group mb-3">
                                    <Label className="form-label">First Name</Label>
                                    <Input
                                        name="Name"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        type="text"
                                        value={validation?.values?.Name}
                                        onChange={validation?.handleChange}
                                        onBlur={validation?.handleBlur}
                                        invalid={validation?.touched?.Name && validation?.errors?.Name ? true : false}
                                    />
                                    {validation?.touched?.Name && validation?.errors?.Name ?
                                        <FormFeedback type='invalid'>{validation?.errors?.Name}</FormFeedback> :
                                        null
                                    }
                                </div>
                                <div className="form-group mb-3">
                                    <Label className="form-label">Last Name</Label>
                                    <Input
                                        name="Last_Name"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        type="text"
                                        value={validation?.values?.Last_Name}
                                        onChange={validation?.handleChange}
                                        onBlur={validation?.handleBlur}
                                        invalid={validation?.touched?.Last_Name && validation?.errors?.Last_Name ? true : false}
                                    />
                                    {validation?.touched?.Last_Name && validation?.errors?.Last_Name ?
                                        <FormFeedback type='invalid'>{validation?.errors?.Last_Name}</FormFeedback> :
                                        null
                                    }
                                </div>

                                <div className="form-group mb-3">
                                    <Label className="form-label">Contact Number</Label>
                                    <Input
                                        name="Contact_Number"
                                        className="form-control"
                                        placeholder="Enter Contact Number"
                                        type="text"
                                        value={validation?.values?.Contact_Number}
                                        onChange={validation?.handleChange}
                                        onBlur={validation?.handleBlur}
                                        invalid={validation?.touched?.Contact_Number && validation?.errors?.Contact_Number ? true : false}
                                    />
                                    {validation?.touched?.Contact_Number && validation?.errors?.Contact_Number ?
                                        <FormFeedback type='invalid'>{validation?.errors?.Contact_Number}</FormFeedback> :
                                        null
                                    }
                                </div>

                                <div className="form-group mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="Email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        type="text"
                                        value={validation?.values?.Email}
                                        onChange={validation?.handleChange}
                                        onBlur={validation?.handleBlur}
                                        invalid={validation?.touched?.Email && validation?.errors?.Email ? true : false}
                                        disabled
                                    />
                                    {validation?.touched?.Email && validation?.errors?.Email ?
                                        <FormFeedback type='invalid'>{validation?.errors?.Email}</FormFeedback> :
                                        null}
                                </div>
                                <Button
                                    className="me-3"
                                    type="submit"
                                    color="primary"
                                >
                                    {buttonLoader ? <Spinner color="light" size='sm' /> : "Update"}
                                </Button>

                            </CardBody>
                        </Card>
                    </Form>
                </Container>
            </div>
        </React.Fragment >

    )

}