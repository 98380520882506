import React, { useEffect, useMemo, useState } from "react";
import {
  Card, CardBody, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import TableContainer from '../../common/TableContainer';
import Breadcrumbs from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { payoutsActions } from '../../store/payouts/actions';
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from "../../common/CustomPagination";

const PayoutsIndex = () => {
  document.title = "Payouts | Allen Dreyfus";
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [payouts, setPayouts] = useState([]);
  const [exportStatus, setExportStatus] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const { adminPageLoader } = useSelector(state => state.commonReducer);
  const { adminPayoutsList } = useSelector(state => state.payoutsReducer);

  useEffect(() => {
    dispatch({
      type: payoutsActions.GET_ADMIN_PAYOUTS_LIST
    })
  }, [dispatch])

  useEffect(() => {
    if (adminPayoutsList?.result?.length !== 0) {
      setPayouts(adminPayoutsList?.result)
    }
  }, [adminPayoutsList?.result])

  const csvHeaders = [
    { label: "Contributor Name", key: "Contributor_Name" },
    { label: "Payment Amount", key: "Payment_Amount" },
    { label: "Mode Of Payment", key: "Mode_Of_Payment" },
    { label: "Paid On", key: "Paid_On" },
  ];

  const handleSuccess = () => {
    setExportStatus('success');
    toast.success(' Payouts Export successful!', { position: toast.POSITION.TOP_CENTER });
  };

  const handleError = (error) => {
    setExportStatus('error');
    toast.error('Failure Export', { position: toast.POSITION.TOP_CENTER });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Contributor Name',
        accessor: 'Contributor_Name',
        filterable: true,
      },
      {
        Header: 'Payment Amount',
        accessor: 'Payment_Amount',
        filterable: true,
      },
      {
        Header: 'payment service providery',
        accessor: 'Mode_Of_Payment',
        filterable: true,
      },
      {
        Header: 'Paid On',
        accessor: 'Paid_On',
        filterable: true,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                  const payoutData = cellProps.row.original;
                  navigate('/payout-detail', { state: payoutData });
                }}
              >
                View
              </Button>
            </div>
          );
        }
      },
    ], []
  );

  const handleAddPayouts = () => {
    navigate('/add-payouts');
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {adminPageLoader ?
          <AdminPageLoader /> :
          <Container fluid>
            <Breadcrumbs
              title="Payouts"
              breadcrumbItem="list"
            />
            <Row>
              <Col xs="12">
                <Card className="payouts">
                  <CardBody>
                    {payouts && payouts?.length > 0 ? (
                      <React.Fragment>
                        {/* Need approval from client */}
                        {/* <div className="d-flex justify-content-end mb-2">
                          <CSVLink
                            data={payouts}
                            headers={csvHeaders}
                            filename={"payouts_data.csv"}
                            onClick={() => handleSuccess()}

                          >
                            <Button color="primary">Export</Button>
                          </CSVLink>
                        </div> */}
                        <TableContainer
                          columns={columns}
                          data={payouts || []}
                          isGlobalFilter={true}
                          isAddCustList={false}
                          isPagination={true}
                          // isAddPayouts={true}
                          // handleAddPayouts={handleAddPayouts}
                          customPageSize={itemsPerPage}
                          tableClass="align-middle table-nowrap"
                          isShowingPageLength={false}
                          iscustomPageSizeOptions={true}
                          pagination="pagination pagination-rounded justify-content-end mb-2"
                        />
                      </React.Fragment>
                    ) : (
                      <div className="no-records">No records found</div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        }
      </div>
    </React.Fragment>
  );
};

export default PayoutsIndex;
