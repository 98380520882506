import React, { Fragment, useMemo } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import JobListGlobalFilter from "./GlobalSearchFilter"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col xxl={3} lg={6} md={6}>
        <input
          type="search"
          className="form-control"
          id="search-bar-0"
          value={value || ""}
          placeholder='Search here...'
          onChange={e => { setValue(e.target.value); onChange(e.target.value) }}
        />
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}
    </React.Fragment>
  )
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  globalFilter: PropTypes.any,
  setGlobalFilter: PropTypes.func,
  isJobListGlobalFilter: PropTypes.bool
}

const CustomPagination = ({
  pageOptions,
  pageIndex,
  previousPage,
  nextPage,
  gotoPage,
  canPreviousPage,
  canNextPage,
  isShowingPageLength,
  pageLength,
  dataLength,
  paginationDiv,
  pagination,
}) => {
  const renderPageNumbers = () => {
    const pageLinks = [];
    const totalPageNumbers = pageOptions.length;

    for (let i = 0; i < totalPageNumbers; i++) {
      if (i === 0 || i === totalPageNumbers - 1 || (i >= pageIndex - 1 && i <= pageIndex + 1)) {
        pageLinks.push(
          <li key={i} className={pageIndex === i ? "page-item active" : "page-item"}>
            <Link to="#" className="page-link" onClick={() => gotoPage(i)}>{i + 1}</Link>
          </li>
        );
      } else if (
        (i === 1 && pageIndex > 3) ||
        (i === totalPageNumbers - 2 && pageIndex < totalPageNumbers - 4) 
        // (i < pageIndex - 1 && pageLinks[pageLinks.length - 1] !== '...') 
        // (i > pageIndex + 1 && pageLinks[pageLinks.length - 1] !== '...')
      ) {
        pageLinks.push(<li key={i} className="page-dot">...</li>);
      }
    }
    return pageLinks;
  };

  return (
    <Row className="justify-content-between align-items-center">
      {isShowingPageLength && (
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">{pageLength}</span> of <span className="fw-semibold">{dataLength}</span> entries
          </div>
        </div>
      )}
      <div className={paginationDiv}>
        <ul className={pagination}>
          <li className={`page-item ${!canPreviousPage ? "disabled" : ''}`}>
            <Link to="#" className="page-link" onClick={previousPage}>
              <i className="mdi mdi-chevron-left"></i>
            </Link>
          </li>
          {renderPageNumbers()}
          <li className={`page-item ${!canNextPage ? "disabled" : ''}`}>
            <Link to="#" className="page-link" onClick={nextPage}>
              <i className="mdi mdi-chevron-right"></i>
            </Link>
          </li>
        </ul>
      </div>
    </Row>
  );
};

CustomPagination.propTypes = {
  pageOptions: PropTypes.array.isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  isShowingPageLength: PropTypes.bool,
  pageLength: PropTypes.number,
  dataLength: PropTypes.number,
  paginationDiv: PropTypes.string,
  pagination: PropTypes.string,
};

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  isSendRequest,
  handleSendRequest,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  isAddAdmin,
  handleAddAdmin,
  isAddContentCreator,
  handleAddContentCreator,
  isAddPayouts,
  handleAddPayouts,
  isAddPodcast,
  handleAddPodcast,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  return (
    <Fragment>
      <Row className="mb-2">
        {iscustomPageSizeOptions &&
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        }

        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="5" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
        {isAddAdmin && (
          <Col sm="5" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleAddAdmin}
              >
                <i className="mdi mdi-plus me-1" />
                Add Admin
              </Button>
            </div>
          </Col>
        )}
        {isAddContentCreator && (
          <Col sm="5" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleAddContentCreator}
              >
                <i className="mdi mdi-plus me-1" />
                Add Content Creator
              </Button>
            </div>
          </Col>
        )}
        {isAddPayouts && (
          <Col sm="5" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleAddPayouts}
              >
                <i className="mdi mdi-plus me-1" />
                Add Payouts
              </Button>
            </div>
          </Col>
        )}
        {isSendRequest && (
          <Col sm="5" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleSendRequest}
              >
                {/* <i className="mdi mdi-plus me-1" /> */}
                Send Request
              </Button>
            </div>
          </Col>
        )}
        {isAddPodcast && (
          <Col sm="5" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleAddPodcast}
              >
                <i className="mdi mdi-plus me-1" />
                Add Podcast
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive">
        <Table {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.isSort ? "sorting" : ''}>
                    <div className="m-0" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      {isPagination && (
        <CustomPagination
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          isShowingPageLength={isShowingPageLength}
          pageLength={page.length}
          dataLength={data.length}
          paginationDiv={paginationDiv}
          pagination={pagination}
        />
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isGlobalFilter: PropTypes.bool,
  isJobListGlobalFilter: PropTypes.bool,
  isAddOptions: PropTypes.bool,
  isAddUserList: PropTypes.bool,
  isSendRequest: PropTypes.bool,
  handleSendRequest: PropTypes.func,
  handleOrderClicks: PropTypes.func,
  handleUserClick: PropTypes.func,
  handleCustomerClick: PropTypes.func,
  isAddCustList: PropTypes.bool,
  isAddAdmin: PropTypes.bool,
  handleAddAdmin: PropTypes.func,
  isAddContentCreator: PropTypes.bool,
  handleAddContentCreator: PropTypes.func,
  isAddPayouts: PropTypes.bool,
  handleAddPayouts: PropTypes.func,
  isAddPodcast: PropTypes.bool,
  handleAddPodcast: PropTypes.func,
  customPageSize: PropTypes.number,
  customPageSizeOptions: PropTypes.bool,
  iscustomPageSizeOptions: PropTypes.bool,
  isPagination: PropTypes.bool,
  isShowingPageLength: PropTypes.bool,
  paginationDiv: PropTypes.string,
  pagination: PropTypes.string,
  tableClass: PropTypes.string,
  theadClass: PropTypes.string,
}

export default TableContainer
