import { corporateSubscriptionActions } from "./actions";
import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import { commonActions } from "../common/actions";

export const corporateSubscriptionSagas = function* () {
    yield all([
        yield takeEvery(corporateSubscriptionActions.GET_ADMIN_CORPORATE_LIST, getAdminCorporateList),
        yield takeEvery(corporateSubscriptionActions.GET_ADMIN_CORPORATE_DETAIL, getAdminCorporateDetail),
        yield takeEvery(corporateSubscriptionActions.GET_WEB_ADD_CORPORATE_DETAIL, getWebAddCorporateDetail),
    ])
}

// Get CorporateSubscription List
const getAdminCorporateList = function* () {
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/corporateSubscription/corporateUserList`)
        );
        yield put({
            type: corporateSubscriptionActions.SET_ADMIN_CORPORATE_LIST,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Get CorporateSubscription Detail
const getAdminCorporateDetail = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/corporateSubscription/corporationSubscriptionDetail/${payload}`)
        );
        yield put({
            type: corporateSubscriptionActions.SET_ADMIN_CORPORATE_DETAIL,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : null
        })
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
}

//add web corporate details
const getWebAddCorporateDetail = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/corporateSubscription/create`, payload)
        )
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: corporateSubscriptionActions.WEB_CORPORATE_RESPONSE,
            payload: result?.data,
        })
        if (result?.data?.statusCode === 200) {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "success", message: result?.data?.message }
            });
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: result?.data?.message }
            });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
    } catch (err) {

    }
}