import React, { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "store/webHome/actions"
import WebPageLoader from "../../common/webPageLoader";

const Recentstories = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { limitedRecentContent } = useSelector(state => state.WebHomePageReducer)
  const { webPageLoader } = useSelector(state => state.commonReducer);

  useEffect(() => {
    dispatch({ type: WebHomePageActions.GET_LIMITED_RECENT_CONTENT_LIST })
  }, [])

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, { state: { contentDetail: contentDetail } })
    document.documentElement.scrollIntoView({ behavior: "smooth" })
    dispatch({ type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS, payload: contentDetail._id })
  }

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }
  
  return (
    <Container>
      <>
        {webPageLoader ?
          <WebPageLoader className="recent-loader" /> :
          <>
            <Row>
              {limitedRecentContent?.result && (
                limitedRecentContent.result?.slice(0, 1)?.map(story => (
                  <Col xs={12} md={7} lg={8} className="mt-3" key={story._id}>
                    <div className="recent-container">
                      <div className="recent-heading">
                        <h1 className="web_fonts heading-for-website">recent </h1>
                        <hr className="hr-line" />
                      </div>
                      <h5 className="web_fonts color-black sub-heading-for">latest news insights</h5>
                      <img
                        src={story.Article_Images}
                        alt="Latest News"
                        className="latest-news-img"
                        onClick={() => handleSectionClick(story)}
                      />
                      <h5 className="politics-heading web_fonts">{story?.Category}</h5>
                      <h1
                        className="article-heading web_fonts"
                        onClick={() => handleSectionClick(story)}
                      >
                        {story?.Title?.length > 100
                          ? `${story?.Title?.slice(0, 100)}...`
                          : story?.Title}
                      </h1>
                      <p className="author-date-recent">
                        by{" "}
                        <span style={{ color: "#007bff", textTransform: "none" }}>
                          {`${story?.Created_By}`}
                        </span>
                        {` ${formatDate(story?.Approved_On)} | ${story?.TimeDifference
                          } ago`}
                      </p>

                      {/* <p className="article-content web_fonts " */}
                      <p className="article-content web_fonts"
                        onClick={() => handleSectionClick(story)}>
                        <div
                          // dangerouslySetInnerHTML={{
                          //   __html: story?.Story?.slice(0, 200),
                          // }}

                          dangerouslySetInnerHTML={{
                            __html: (story?.Story &&
                              story?.Story.split(" ")?.slice(0, 30)?.join(" ")) +
                              (story?.Story &&
                                story?.Story?.split(" ")?.length >
                                30
                                ? "..."
                                : ""),
                          }}
                        />
                      </p>
                    </div>
                  </Col>
                ))
              )
                // : (
                //   <p>No recent stories available</p>
                // )
              }

              <Col xs={12} md={5} lg={4} className="recentpage-leftside">
                <Row>
                  {limitedRecentContent?.result && (
                    limitedRecentContent?.result?.slice(1, 7)?.map((story, index) => (
                      <Col
                        xs={12}
                        className="topic-column"
                        key={story._id}
                        onClick={() => handleSectionClick(story)}
                      >
                        <div className="topic-column-display">
                          <div className="topic-column-dis-flex">
                            <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                              {story.Category}
                            </p>

                            <h6
                              className="content-heading web_fonts">
                              {story.Title?.length > 70
                                ? `${story.Title?.slice(0, 70)}...`
                                : story.Title}
                            </h6>
                            {/* <p className="disabled-text web_fonts" >
                              {`${story.Created_By}, ${formatDate(
                                story.Approved_On
                              )} | ${story.TimeDifference} ago`}
                            </p> */}
                            <p className="disabled-text web_fonts">
                              by
                              <span className="name-of web_fonts" style={{ textTransform: "none" }}>
                                {story.Created_By}
                              </span>
                              {`${formatDate(story.Approved_On)} | ${story.TimeDifference
                                } ago`}
                            </p>
                          </div>

                          <div className="topic-column-dis-image ">
                            <img
                              src={story?.Article_Images}
                              alt="Topic 1 Image"
                              className="topic-column-dis-image-img"
                            />
                          </div>
                        </div>
                        {index < 5 && <hr className="hr-line-recent" />}
                      </Col>
                    ))
                  )
                    // : (
                    //   <p>No recent stories available</p>
                    // )
                  }
                </Row>
              </Col>
            </Row>
          </>
        }
      </>
    </Container>
  )
}

export default Recentstories



