import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import profileicon from '../../assets/images/dashboard/user3.png';
import { useSelector } from "react-redux";

function CardUser() {

    const { superAdminTokenResponse } = useSelector(state => state.userAuthReducer);

    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <div className="d-flex">
                                    <div className="me-3">
                                        <img
                                            src={superAdminTokenResponse?.result?.Profile_Image ?? profileicon}
                                            alt=""
                                            className="avatar-md rounded-circle img-thumbnail"
                                        />
                                    </div>
                                    <div className="flex-grow-1 align-self-center">
                                        <div className="text-muted">
                                            <p className="mb-2">Welcome</p>
                                            <h5 className="mb-1">{superAdminTokenResponse?.result?.Name}</h5>
                                            <p className="mb-0">{superAdminTokenResponse?.result?.Type_Of_User}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg="6" className="d-none d-lg-block">
                                <div className="clearfix mt-4 mt-lg-0 float-end">
                                    <Link
                                        to="#"
                                        className="btn btn-primary waves-effect waves-light btn-md"
                                    >
                                        <i className="bx bxs-cog align-middle me-1" /> Settings
                                    </Link>
                                </div>
                            </Col> */}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default CardUser
