import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Subscriptionimage from "../SubscribeBanner/index"
import ListByCategory from "../ContentByCategory/ListByCategory"
import { useNavigate, useParams } from "react-router-dom"
import { webContentByCategoryActions } from "../../store/webContentByCategory/actions"
import { useDispatch, useSelector } from "react-redux"
import NewsletterSectionCommon from "webComponents/Common/newslettercommon"
import NewsletterText from "../../assets/webImages/exclusive.webp"
import NewsletterMsgImg from "../../assets/webImages/Group 8.webp"
import Subscriptiontext from "../../assets/webImages/real-time2.webp"
import Subscribenow1 from "../../assets/webImages/Group 12.webp"
import Subscriptiontext1 from "../../assets/webImages/real-time1.webp"
import { WebHomePageActions } from "store/webHome/actions"
import NewsletterTextImg1 from "../../assets/webImages/exclusive market (2).webp"
import NewsletterMsgImg1 from "../../assets/webImages/Group 7.webp"
import Subscribenow from "../../assets/webImages/megaphone-icon.webp"

const PopularByCategory = () => {
  const navigate = useNavigate()
  const { category } = useParams()

  const dispatch = useDispatch()

  const { webPopularListByContent } = useSelector(
    state => state.webContentByCategoryReducer)
  const { webListByCategory } = useSelector(
    state => state.webContentByCategoryReducer
  )

  const { tokenResponse } = useSelector(state => state.userAuthReducer)

  const isLoggedIn = !!tokenResponse?.result
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"

  const { ForYouSpecificContent } = useSelector(state => state.WebHomePageReducer)

  var [FinalPrefList, setFinalPrefList] = useState([])

  useEffect(() => {
    const UserId = tokenResponse?.result?._id
    const TypeOfContent = "Article"

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME,
      payload: { UserId, TypeOfContent },
    })
  }, [dispatch, tokenResponse])

  useEffect(() => {
    dispatch({
      type: webContentByCategoryActions.GET_WEB_LIST_BY_CATEGORY,
      payload: { TypeOfContent: "Article", Category: category },
    })
  }, [dispatch, category])

  useEffect(() => {
    dispatch({
      type: webContentByCategoryActions.GET_WEB_POPULAR_LIST_BY_CATEGORY,
      payload: category,
    })
  }, [dispatch, category])

  const truncateContent = (content, numWords) => {
    const words = content.split(" ")
    if (words.length > numWords) {
      return `${words.slice(0, numWords).join(" ")} ...`
    } else {
      return content
    }
  }

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, {
      state: { contentDetail: contentDetail },
    })
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS,
      payload: contentDetail._id,
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  let newsletterStyles
  let newsletterImages

  switch (category) {
    case "Business":
      newsletterStyles = {
        "--newsletter-bg-color": "#004aad",
        "--newsletter-text-color": "#000000",
        "--newsletter-signup-text": "#004aad",
      }
      newsletterImages = {
        NewsletterText: NewsletterText,
        NewsletterMsgImg: NewsletterMsgImg,
      }
      break
    case "Politics":
      newsletterStyles = {
        "--newsletter-bg-color": "#38b6ff",
        "--newsletter-text-color": "#000000",
        "--newsletter-signup-text": "#38b6ff",
      }
      newsletterImages = {
        NewsletterText: NewsletterText,
        NewsletterMsgImg: NewsletterMsgImg,
      }
      break
    case "Green":
      newsletterStyles = {
        "--newsletter-bg-color": "white",
        "--newsletter-text-color": "#000000",
        "--newsletter-signup-text": "#004aad",
        "--newsletter-border-bottom": "#004aad",
      }
      newsletterImages = {
        NewsletterText: NewsletterTextImg1,
        NewsletterMsgImg: NewsletterMsgImg1,
      }
      break
    case "In Depth":
      newsletterStyles = {
        "--newsletter-bg-color": "#004aad",
        "--newsletter-text-color": "#000000",
        "--newsletter-signup-text": "#004aad",
      }
      newsletterImages = {
        NewsletterText: NewsletterText,
        NewsletterMsgImg: NewsletterMsgImg,
      }
      break
  }

  let subscriptionStyles
  let subscriptionImages

  switch (category) {
    case "Business":
      subscriptionStyles = {
        paddingTop: "4%",
        "--sub-banner-bg-color": "#38b6ff",
        "--sub-banner-text-color": "#38b6ff",
        "--sub-banner-button-bg": "white",
        "--sub-banner-border-color": "#38b6ff",
      }
      subscriptionImages = {
        Subscribenow: Subscribenow,
        Subscriptiontext: Subscriptiontext1,
      }
      break
    case "Politics":
      subscriptionStyles = {
        paddingTop: "4%",
        "--sub-banner-bg-color": "white",
        "--sub-banner-text-color": "#fffdfd ",
        "--sub-banner-button-bg": "#004aad",
        "--sub-banner-border-color": "#D1D1D1",
      }
      subscriptionImages = {
        Subscribenow: Subscribenow1,
        Subscriptiontext: Subscriptiontext,
      }
      break
    case "Green":
      subscriptionStyles = {
        paddingTop: "4%",
        "--sub-banner-bg-color": "#38b6ff",
        "--sub-banner-text-color": "#38b6ff",
        "--sub-banner-button-bg": "white",
        "--sub-banner-border-color": "#38b6ff",
      }
      subscriptionImages = {
        Subscribenow: Subscribenow,
        Subscriptiontext: Subscriptiontext1,
      }
      break
    case "In Depth":
      subscriptionStyles = {
        paddingTop: "4%",
        "--sub-banner-bg-color": "#38b6ff",
        "--sub-banner-text-color": "#38b6ff ",
        "--sub-banner-button-bg": "white",
        "--sub-banner-border-color": "#D1D1D1",
      }
      subscriptionImages = {
        Subscribenow: Subscribenow,
        Subscriptiontext: Subscriptiontext1,
      }
      break
  }

  useEffect(() => {
    if (ForYouSpecificContent?.result?.length > 0) {
      if (ForYouSpecificContent?.result?.length > 11) {
        setFinalPrefList(ForYouSpecificContent?.result?.slice(8, 11))
      } else if (ForYouSpecificContent?.result?.length < 11) {
        setFinalPrefList(ForYouSpecificContent?.result?.slice(0, 3))
      }
    }
  }, [ForYouSpecificContent])

  return (
    <>
      {webListByCategory?.result ? (
        <>
          <Container style={{ paddingBottom: "20px" }}>
            <Row>
              <Col xs={12} md={7} lg={8}>
                <ListByCategory />
              </Col>

              <Col xs={12} md={5} lg={4}>
                <Row>
                  {isSubscribed || isTrialUsed ? (
                    <>
                      {/* <div className="sub-now">
                  <img src={SubcribeBanner}  className="insight-driven"/>
                 </div> */}
                    </>
                  ) : (
                    <div className="sub-now" style={subscriptionStyles}>
                      <Subscriptionimage
                        Subscribenow={subscriptionImages?.Subscribenow}
                        Subscriptiontext={subscriptionImages?.Subscriptiontext}
                      />
                    </div>
                  )}

                  {isLoggedIn ? (
                    <>
                      <div style={{ paddingTop: "4%" }}>
                        <div className="recent-heading">
                          <h2 className="web_fonts">For You</h2>
                          <hr className="hr-line" />
                        </div>
                        <h5 className="web_fonts color-black">
                          tailored to readers{" "}
                        </h5>
                      </div>
                      {
                      // ForYouSpecificContent?.result?.length > 0 ? (
                      //   ForYouSpecificContent?.result
                      //     .slice(0, 3)
                      FinalPrefList?.length > 0 ? (
                        FinalPrefList
                          .map((content, index) => (
                            <div key={index}>
                              <Col xs={12} className="topic-column">
                                <div className="topic-column-display">
                                  <div className="topic-column-dis-flex">
                                    <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                                      {content?.Category[0]}
                                    </p>
                                    <h6
                                      className="content-heading web_fonts politics-title"
                                      onClick={() =>
                                        handleSectionClick(content)
                                      }
                                    >
                                      {truncateContent(content?.Title, 10)}
                                    </h6>
                                  </div>

                                  <div className="topic-column-dis-image ">
                                    <img
                                      src={content?.Article_Images[0]}
                                      alt="Topic 1 Image"
                                      className="topic-column-dis-image-img"
                                      onClick={() =>
                                        handleSectionClick(content)
                                      }
                                    />
                                  </div>
                                </div>
                                <hr className="hr-line-recent" />
                              </Col>
                            </div>
                          ))
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div style={{ paddingTop: "4%" }}>
                        <div className="recent-heading">
                          <h2 className="web_fonts">Popular</h2>
                          <hr className="hr-line" />
                        </div>
                        <h5 className="web_fonts color-black">
                          reader's top picks
                        </h5>
                      </div>
                      {webPopularListByContent?.result?.length > 0 ? (
                        webPopularListByContent?.result
                          .slice(0, 3)
                          ?.map((content, index) => (
                            <div key={index}>
                              <Col xs={12} className="topic-column">
                                <div className="topic-column-display">
                                  <div className="topic-column-dis-flex">
                                    <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                                      {content?.Category[0]}
                                    </p>
                                    <h6
                                      className="content-heading web_fonts politics-title"
                                      onClick={() =>
                                        handleSectionClick(content)
                                      }
                                    >
                                      {truncateContent(content?.Title, 10)}
                                    </h6>
                                  </div>

                                  <div className="topic-column-dis-image ">
                                    <img
                                      src={content?.Article_Images[0]}
                                      alt="Topic 1 Image"
                                      className="topic-column-dis-image-img"
                                      onClick={() =>
                                        handleSectionClick(content)
                                      }
                                    />
                                  </div>
                                </div>
                                <hr className="hr-line-recent" />
                              </Col>
                            </div>
                          ))
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {/* <Col xs={12} className="topic-column">
              <div className="topic-column-display">
                <div className="topic-column-dis-flex">
                  <h6 className="topic-column-heading web_fonts">politics</h6>
                  <h6 className="content-heading web_fonts color-black politics-title">
                    Ghana's lithium deal requires scrutiny to ensure the nation
                  </h6>
                </div>

                <div className="topic-column-dis-image ">
                  <img
                    src={Image2}
                    alt="Topic 1 Image"
                    className="topic-column-dis-image-img"
                  />
                </div>
              </div>
              <hr className="hr-line-recent" />
            </Col>

            <Col
              xs={12}
              className="topic-column"
              style={{ paddingBottom: "20px" }}
            >
              <div className="topic-column-display">
                <div className="topic-column-dis-flex">
                  <h6 className="topic-column-heading web_fonts">politics</h6>
                  <h6 className="content-heading web_fonts color-black politics-title">
                    Ghana's lithium deal requires scrutiny to ensure the nation
                  </h6>
                </div>

                <div className="topic-column-dis-image ">
                  <img
                    src={Image3}
                    alt="Topic 1 Image"
                    className="topic-column-dis-image-img"
                  />
                </div>
              </div>
            </Col> */}

                  <Col xs={12}>
                    <div style={newsletterStyles}>
                      <NewsletterSectionCommon
                        NewsletterText={newsletterImages.NewsletterText}
                        NewsletterMsgImg={newsletterImages.NewsletterMsgImg}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <> </>
      )}
    </>
  )
}

export default PopularByCategory
