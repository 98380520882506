import { combineReducers } from "redux";

import Layout from "./layout/reducer";

import DashboardReducer from "./dashboard/reducer";
import { creatorDashboardReducer } from "./creatorDashboard/reducer";

import { userAuthReducer } from "./userAuth/reducer";
import { commonReducer } from "./common/reducer";
import { adminReducer } from "./admin/reducer";
import { customerReducer } from "./customer/reducer";
import { payoutsReducer } from "../store/payouts/reducer";
import { revenueReducer } from "../store/revenue/reducer";
import { corporateSubscriptionReducer } from "../store/corporateSubscription/reducer";
import { webContentByCategoryReducer } from "../store/webContentByCategory/reducer";

import { ContentCreatorReducer } from "../store/contentCreator/reducer";
import { BlogContentReducer } from "../store/blogContent/reducer";
import { subscriptionPlanReducer } from "../store/adminSubscriptionPlans/reducer";
import { SignUpReducer } from "./webAuth/reducer";
import { WebHomePageReducer } from "./webHome/reducer";
import { WebContentDetailReducer } from "./webContentDetail/reducer";
import { WebBookmarkReducer } from "./webBookmark/reducer";
import { stripeReducer } from "./stripePayment/reducer";
import { advertiseReducer } from "./Advertise/reducer";
import { NotificationReducer } from "./notification/reducer";
import { CountryReducer } from "./Countries/reducer";
import {withdrawReducer} from "./withdrawRequest/reducer";

const rootReducer = combineReducers({
  Layout,
  DashboardReducer,
  creatorDashboardReducer,
  userAuthReducer,
  commonReducer,
  ContentCreatorReducer,
  BlogContentReducer,
  adminReducer,
  customerReducer,
  subscriptionPlanReducer,
  SignUpReducer,
  payoutsReducer,
  revenueReducer,
  corporateSubscriptionReducer,
  WebHomePageReducer,
  // WebContentDetailReducer,
  webContentByCategoryReducer,
  WebContentDetailReducer,
  stripeReducer,
  WebBookmarkReducer,
  advertiseReducer,
  NotificationReducer,
  CountryReducer,
  withdrawReducer
});

export default rootReducer;
