import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import Logoimage from "../../assets/webImages/png-logo-color.webp"
import Logo from "../../assets/webImages/png-logo-white.webp"
import { FaSquareFacebook } from "react-icons/fa6"
import { FaLinkedin } from "react-icons/fa"
import { FaSquareXTwitter } from "react-icons/fa6"
import SubscriptionModal from "webComponents/modals/SubscriptionModal"
import ReturnToTop from "../../webComponents/Common/7604589.png"
import { useNavigate } from "react-router-dom"

function PrivacyPolicy() {
  document.title = "Privacy Notice"

  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const navigate = useNavigate()

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const handleScroll = () => {
      // Show the scroll-to-top button when scrolling down
      setShowScrollToTop(window.scrollY > 100)
    }

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll)

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handlebacktohome = () => {
    navigate("/")
  }

 
  return (
    <div>
      <Container>
        <div className="privacy-policy-full-content">
          <Row className="align-items-start justify-content-end">
            <Col>
              <div className="mt-3">
                <div>
                  <img src={Logoimage} alt="Logo" className="logo-ad" onClick={handlebacktohome} />
                </div>
                <div className="text-center">
                  <h1 className="pp-heading-text">Privacy Notice</h1>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="introduction">
                <h4>Introduction</h4>
              </div>
              <p className="web_fonts pp-text">
                This Privacy Notice explains how we collect and process personal
                data about you to provide the services you use; operate our
                business; meet our contractual and legal obligations; protect
                the security of our systems and our customers; or fulfil our
                other legitimate interests.
              </p>
              <p className="web_fonts pp-text">
                At Allen Dreyfus Limited (ADL) we are committed to protecting
                your privacy and the security of the data you provide to us.
              </p>
            </Col>
            <Row>
              <Col>
                <div className="introduction">
                  <h4>Identity Of Data Controller</h4>
                </div>
                <p className="web_fonts pp-text">
                  ADL is the data controller for the personal data we process
                  about you.
                </p>
                <p
                  className="web_fonts pp-text"
                  style={{
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Our contact information is:
                </p>
                <p className="web_fonts pp-text">Allen Dreyfus Limited</p>
                <p className="web_fonts pp-text">35/37 Ludgate Hill</p>
                <p className="web_fonts pp-text">London, EC4M 7JN</p>
                <p  className="web_fonts pp-text">+44 (0) 330 828 5058</p>
                <p className="web_fonts pp-text">
                  If you have any queries about this Privacy Notice, please
                  contact us at{" "}
                  <a href="mailto:dpo@allendreyfus.com" className="email-link">
                    dpo@allendreyfus.com
                  </a>
                </p>
                <p className="web_fonts pp-text">
                  Allen Dreyfus Limited is registered with the Information
                  Commissioner’s Office (the ‘ICO’) as a data controller under
                  registration reference C1446407.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="introduction">
                  <h4>Personal Data – when do we collect it?</h4>
                </div>
                <p className="web_fonts pp-text">
                  When you enter our website or sign-up to purchase an
                  information subscription from ADL we will know who you are,
                  and the activities you perform on this Website.
                </p>
                <p className="web_fonts pp-text">
                  We collect your personal data when you:
                </p>
                <div className="web_fonts pp-text">
                  <ul>
                    <li>
                      Visit or browse our website. Review our Cookie Policy
                      here.
                    </li>
                    <li>When you subscribe to our information service </li>
                    <li>
                      When you contact our Support Team via the chat function on
                      our website to using a telephone.
                    </li>
                    <li>When you email anyone in ADL</li>
                    <li>
                      When you complete a data SAR (Subject Access Request)
                    </li>
                    <li>When you withdraw consent</li>
                    <li>When you submit a complaint.</li>
                  </ul>
                </div>
                <div className="introduction">
                  <h4>Personal Data – what do we collect?</h4>
                </div>
                <p className="web_fonts pp-text">
                  ADL collect information from you to enable us to conduct our
                  business and meet the legal obligations we have as a Data
                  Controller. The information we collect about you is required
                  for the purpose of creating your subscription account or
                  identifying you as a prospect who may be interest in
                  subscribing in the future. We may collect some or all of the
                  following data depending on your customer status i.e. Prospect
                  or Subscriber.
                </p>
                <div className="web_fonts pp-text">
                  <ul>
                    <li>
                      In all cases we will collect first-name, last-name, E-mail
                      address, office and mobile phone numbers, business postal
                      address date of birth and gender.
                    </li>
                    <li>
                      When subscribing to a service we will credit and/or debit
                      card information, information about your bank account
                      number and sort code or other banking information.
                    </li>
                    <li>
                      Your usage records and duration of visits, in the form of
                      date, time, service and membership number
                    </li>
                    <li>
                      We will keep you informed of services updates and changes
                      and will record your preferences for these services and
                      your suggestions or requirements for future service
                      enhancements.
                    </li>
                    <li>
                      We will record all contacts you have with ADL regardless
                      of the method or media and this data will be recorded in
                      our CRM.
                    </li>
                    <li>
                      We will maintain a record of your membership information –
                      this will include invoicing for services, related dates
                      (payment owed and received) the services you use, and any
                      other information related to your account.
                    </li>
                    <li>
                      We will maintain a record of your marketing preferences.
                    </li>
                  </ul>
                </div>
                <div className="introduction">
                  <h4>Personal Data – How do we use it?</h4>
                </div>
                <p className="web_fonts pp-text">
                  The personal data you provide to us is used to provide you
                  with the services, products or information that you have
                  requested. It may also be used to provide you with additional
                  service information in accordance with the marketing
                  preferences you have on record with us. We may need to share
                  your information with third party service providers who work
                  with us (data processors) and we will advise you of these
                  third parties as and when appropriate to do so.
                </p>
                <p className="web_fonts pp-text">
                  Your information and personal data may be used to:
                </p>
                <div className="web_fonts pp-text">
                  <ul>
                    <li>
                      Process your subscription and set up payments with Stripe,
                      our chosen payment processor. A link to the Stripe Privacy
                      Policy is{" "}
                      <a className="email-link" href="https://stripe.com/in">
                        Privacy Policy (stripe.com)
                      </a>
                      .
                    </li>
                    <li>
                      Bill you for using our services as part of your membership
                      on the terms agreed namely month or annually.
                    </li>
                    <li>
                      Ensure you are kept informed about your subscription and
                      our services including operational matters such as system
                      downtime and product upgrades
                    </li>
                    <li>To communicate with you via email</li>
                    <li>
                      To ensure we comply with all statutory regulations
                      including but not limited to the Data Protection Act 2018
                    </li>
                    <li>
                      To respond to you should you make a subject access
                      request, a consent withdrawal, or a complaint.
                    </li>
                  </ul>
                </div>
                <div className="introduction">
                  <h4>Personal Data – Lawful Processing?</h4>
                </div>
                <p className="web_fonts pp-text">
                  ADL relies on the following lawful bases for processing,
                  storing and retaining your personal data:
                </p>
                <div className="web_fonts pp-text">
                  <ul>
                    1. In relation to your subscription to our services:
                    <ol>
                      <li>
                        You have freely given your explicit consent to use a
                        paid subscription service provided by ADL.
                      </li>
                      <li>
                        We must use your personal data and the information it
                        contains to provide the subscription services from ADL.
                      </li>
                      <li>
                        Our processing of your personal data and the information
                        it contains is absolutely necessary for us to provide
                        the subscribed services to you, the customer and the
                        legitimate interests of all parties in communications
                        with subscribers to ensure customer satisfaction.
                      </li>
                      <li>
                        We store and process the data to ensure we manage the
                        legal and financial obligations ADL is subject to.
                      </li>
                    </ol>
                  </ul>
                  <ul>
                    2. In relation to website visitors and marketing information
                    <ol>
                      <li>
                        The processing of data collected, be it in the initial
                        stages of prospective customer interest or later when
                        subscribing, is for ADL to be able to provide, optimize
                        and target the website and market the products and
                        future products we produce.
                      </li>
                      <li>
                        The processing is necessary for the legitimate purposes
                        of ADL to be able to conduct direct marketing where
                        consent has been provided.
                      </li>
                    </ol>
                  </ul>
                </div>
                <div className="introduction">
                  <h4>Personal Data - Retention</h4>
                </div>
                <p className="web_fonts pp-text">
                  We will store your personal data for as long as it is
                  necessary for the purpose of processing your subscription to
                  ADL services.
                </p>
                <p className="web_fonts pp-text">
                  If you terminate your subscription, we will keep your data if
                  we have a legitimate interest and to meet any statutory
                  requirement to which we are committed.
                </p>
                <p className="web_fonts pp-text">
                  Any data you have provided may be stored by ADL for up to
                  three years after your termination and we may use this to
                  approach you to reconsider your termination. You may request
                  the deletion of your data and if this request is received, we
                  will delete all data other than that we have a legal
                  obligation to maintain for a specific period. We can provide a
                  report on the deletion and retention of data on request.
                </p>
                <div className="introduction">
                  <h4>Personal Data – Opt Out’s and Opt In’s</h4>
                </div>
                <p className="web_fonts pp-text">
                  An automatic “Opt In” is initiated when you subscribe to an
                  ADL service. This “Opt In” will provide information about the
                  service you have subscribed to, any forthcoming
                  upgrades/enhancements, planned service maintenance periods and
                  complimentary / supporting services and subscription
                  information.
                </p>
                <p className="web_fonts pp-text">
                  We will not share your data with any third parties for
                  marketing purposes unless you consent.
                </p>
                <p className="web_fonts pp-text">
                  You may “Opt Out” of all non-subscription marketing on
                  request.
                </p>
                <p className="web_fonts pp-text">
                  If you no longer subscribe you can contact us to remove you
                  from all marketing and communications by using the
                  “Unsubscribe” function on any marketing received.
                </p>
                <div className="introduction">
                  <h4>Cookies</h4>
                </div>
                <p className="web_fonts pp-text">
                  At ADL we use cookies. Please refer to our cookie policy for
                  more information. Review our Cookie Policy here.
                </p>
                <p className="web_fonts pp-text">
                  This, in turn, helps us make our website relevant to your
                  interests and needs. We may use a persistent cookie (a cookie
                  that stays linked to your browser) to record your details so
                  we can recognise you if you visit our website again.
                </p>
                <div className="introduction">
                  <h4>Personal Data - Security</h4>
                </div>
                <p className="web_fonts pp-text">
                  Our Data Protection Officer is charged with ensuring we
                  maintain appropriate security to ensure the safety of your
                  personal data. We fully comply with the DPA 2018 act, and our
                  Privacy policy and Company Procedures are available for review
                  on request. We maintain a security activity list where we
                  check and audit our systems frequently and we also ensure that
                  any service provider we use complies with our security
                  policies
                </p>
                <div className="introduction">
                  <h4>Personal Data - Sharing</h4>
                </div>
                <p className="web_fonts pp-text">
                  ADL only shares your data with our service providers, such as
                  Stripe, to allow us to manage and service your subscription
                  and associated payments to provide you with the information we
                  are contracted to provide.
                </p>
                <p className="web_fonts pp-text">
                  We do not share your personal data with any other third
                  parties.
                </p>
                <p className="web_fonts pp-text">
                  In the event that ADL is sold as a going concern to another
                  organization and the subscription service is being maintained
                  we may transfer your personal data to that organization
                  subject to their location, their privacy policies, and their
                  compliance with the DPA 2018 act.
                </p>
                <div className="introduction">
                  <h4>Website – Do Not Track (DNT)</h4>
                </div>
                <p className="web_fonts pp-text">
                  The ADL website does not respond to DNT signals.
                </p>
                <div className="introduction">
                  <h4>Your Privacy Rights</h4>
                </div>
                <p className="web_fonts pp-text">
                  Below we have prepared an overview of your rights. Please note
                  that there may be conditions or restrictions attached to
                  exercise the rights below. It is therefore not certain that
                  you, for example, have the right to deletion in specific
                  cases. If you wish to exercise one or more of the below
                  rights, please contact us by writing an email to{" "}
                  <a className="email-link" href="mailto:dpo@allendreyfus.com">
                    {" "}
                    dpo@allendreyfus.com
                  </a>
                  with information on your name and membership number to verify
                  your identification.
                </p>
                <div className="introduction">
                  <h4>Access</h4>
                </div>
                <p className="web_fonts pp-text">
                  You have the right to ask for a copy of the data we hold about
                  you. There is not normally a fee for this service.
                </p>
                <div className="introduction">
                  <h4>Rectification</h4>
                </div>
                <p className="web_fonts pp-text">
                  If you believe we are holding inaccurate information about
                  you, or your personal details change, please update your
                  profile on the ADL website in the members’ area. Debit, Credit
                  and Bank account changes can be made in your members’ area on
                  the ADL website.
                </p>
                <div className="introduction">
                  <h4>Erasure</h4>
                </div>
                <p className="web_fonts pp-text">
                  You have the right to erasure of the data we hold about you,
                  when it is no longer needed for the purposes of your
                  Membership, or when you withdraw your consent for our
                  processing (and we have no other lawful basis to hold your
                  data).
                </p>
                <div className="introduction">
                  <h4>Restriction</h4>
                </div>
                <p className="web_fonts pp-text">
                  You have the right to ask us to place restrictions on
                  processing your data in certain circumstances.
                </p>
                <div className="introduction">
                  <h4>Notification</h4>
                </div>
                <p className="web_fonts pp-text">
                  You have the right to be notified of any rectification,
                  erasure or restrictions in relation to your personal data.
                </p>
                <div className="introduction">
                  <h4>Portability</h4>
                </div>
                <p className="web_fonts pp-text">
                  You have a right to receive the data we hold on you
                  electronically in a format that allows it to be easily
                  transferred to another data controller.
                </p>
                <div className="introduction">
                  <h4>Object</h4>
                </div>
                <p className="web_fonts pp-text">
                  You have the right to object to data processing of your
                  personal data for direct marketing or profiling purposes
                </p>
                <div className="introduction">
                  <h4>Withdraw</h4>
                </div>
                <p className="web_fonts pp-text">
                  If ADL rely on consent, you have the right to withdraw your
                  consent for the specific processing.
                </p>
                <div className="introduction">
                  <h4>Profiling</h4>
                </div>
                <p className="web_fonts pp-text">
                  You have the right not to be subject to any decision based on
                  automatic processing of your personal data.
                </p>
                <p className="web_fonts pp-text">
                  If you want to complain, alternatively, you can contact the
                  Information Commissioner’s Office at{" "}
                  <a className="email-link" href="https://ico.org.uk/concerns/">
                    {" "}
                    https://ico.org.uk/concerns/
                  </a>
                  .
                </p>
                <div className="introduction">
                  <h4>CHANGES TO THIS PRIVACY NOTICE</h4>
                </div>
                <p className="web_fonts pp-text">
                  We will update this Privacy Notice over time, especially if we
                  change our data processing activities or if new legal
                  requirements become applicable.
                </p>
              </Col>
            </Row>
          </Row>
        </div>
      </Container>
      <div className="footer-container">
        <footer>
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <a href="/">
                  <img src={Logo} alt="Your Logo" className="footer-logo" />
                </a>
              </Col>
              <Col xs={12} md={6}>
                <p className="footer-copyright custom-nav-link">
                  &copy; {new Date().getFullYear()} All Rights Reserved
                </p>
              </Col>
            </Row>

            <Row className="footer-row"></Row>
            {/* Sticky scroll-to-top button */}
            {showScrollToTop && (
              <div className="scroll-to-top-button" onClick={handleScrollToTop}>
                <img className="scroll-image" src={ReturnToTop} />
              </div>
            )}

            <Row className="footer-row"></Row>
          </Container>
        </footer>
      </div>
    </div>
  )
}

export default PrivacyPolicy



