import ForgotPasswordIndex from "components/forgotPassword";
import React from "react";

export default function ForgotPassword () {

    return(
        <div>
            <ForgotPasswordIndex />
        </div>
    )
}