import { actions } from './actions'

const initState = {
    createAdvertiseResponse: [],
    advertiseList: [],
    advertiseById:[]
}

export const advertiseReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.CREATE_ADVERTISE_RESPONSE:
            return {
                ...state,
                createAdvertiseResponse: action.payload,
            }
        case actions.SET_ADVERTISE_LIST:
            return {
                ...state,
                advertiseList: action.payload,
            }
        case actions.SET_ADVERTISE_BY_ID:
            return {
                ...state,
                advertiseById: action.payload,
            }
        default:
            return state;
    }
}
