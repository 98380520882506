import React, { useMemo, useEffect, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import TableContainer from "../../common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "store/blogContent/actions";
import { useNavigate } from "react-router-dom";

const NeedApprovalList = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { contentListByCreatorIDForApproval } = useSelector(state => state.BlogContentReducer);

    let creator_id = creatorTokenResponse?.result?._id;
    var [contentList, setContentList] = useState([])

    useEffect(() => {
        dispatch({
            type: BlogContentActions.GET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL,
            payload: creator_id,
        });
    }, [creator_id])

    useEffect(() => {
        if (contentListByCreatorIDForApproval?.result?.length !== 0) {
            setContentList(contentListByCreatorIDForApproval?.result);
        }
    }, [contentListByCreatorIDForApproval?.result])

    const columns = useMemo(
        () => [
            // {
            //     Header: "Content ID",
            //     accessor: "ContentID",
            //     filterable: false,
            //     disableFilters: true,
            // },
            {
                Header: "Title",
                accessor: "Title",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: 'Status',
                accessor: 'Status',
                filterable: true,
            },
            {
                Header: 'Submitted On',
                accessor: 'Created_On',
                filterable: true,
            },
            {
                Header: 'Submitted By',
                accessor: 'Created_By',
                filterable: true,
            },
            {
                Header: "View Details",
                disableFilters: true,
                accessor: "view",
                Cell: cellProps => {
                    return (
                        <Button
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => {
                                const Data = cellProps.row.original;
                                navigate('/creator-content-detail', { state: { data: Data, type: "needApproval" } })
                            }}
                        //   onClick={() => toggleViewModal()}
                        >
                            View Details
                        </Button>
                    );
                },
            },
        ]
    );

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="mb-4 h4 card-title">Need Approval</div>
                    {contentList && contentList.length > 0 ? (
                        <TableContainer
                            columns={columns}
                            data={contentList || []}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            isPagination={true}
                            iscustomPageSizeOptions={false}
                            customPageSize={4}
                            pagination="pagination pagination-rounded justify-content-end mb-2"
                        />
                    ) : (
                        <div className="no-records">No records found</div>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default NeedApprovalList;