import React, { useEffect } from "react"

import Layout from "./layouts/Layout/"
import NonAuthLayout from "./components/nonAuthLayouts/NonAuthLayout"
import setAuthToken from "utils/setAuthToken"

import { Routes, Route } from "react-router-dom"
import Authmiddleware from "./routes/route"
import { authProtectedRoutes, publicRoutes } from "./routes"
import { userAuthActions } from "./store/userAuth/actions"
import store from "./store/index"

import ToastMessage from "./common/toastMessage"
import FileErrorModal from './components/commonModals/FileErrorModal'
import "./assets/scss/theme.scss"
import { useDispatch } from "react-redux"

//Super admin
// if (sessionStorage.getItem('GFX_TOKEN') && window.location.pathname?.slice(0, 6) === '/login') {
if (localStorage.getItem("GFX_TOKEN")) {
  setAuthToken(localStorage.getItem('GFX_TOKEN'), 'Super_Admin');
  store.dispatch({
    type: userAuthActions.VERIFY_TOKEN,
    payload: localStorage.getItem("GFX_TOKEN"),
  })
  // store.dispatch({
  //   type: userAuthActions.UPDATE_AUTH_DETAILS,
  //   payload: { isAuthenticated: true }
  // });

  store.dispatch({
    type: userAuthActions.UPDATE_SUPER_ADMIN_AUTH_DETAILS,
    payload: { isAuthenticated: true },
  })
}
//Creator
if (localStorage.getItem("GFX_TOKEN2")) {
  setAuthToken(localStorage.getItem('GFX_TOKEN2'), 'Content_Creator');
  store.dispatch({
    type: userAuthActions.VERIFY_TOKEN,
    payload: localStorage.getItem("GFX_TOKEN2"),
  })
  // store.dispatch({
  //   type: userAuthActions.UPDATE_AUTH_DETAILS,
  //   payload: { isAuthenticated: true }
  // });

  store.dispatch({
    type: userAuthActions.UPDATE_CREATOR_AUTH_DETAILS,
    payload: { isAuthenticated: true },
  })
}
//Admin
if (localStorage.getItem("GFX_TOKEN3")) {
  setAuthToken(localStorage.getItem('GFX_TOKEN3'), 'Admin');
  store.dispatch({
    type: userAuthActions.VERIFY_TOKEN,
    payload: localStorage.getItem("GFX_TOKEN3"),
  })
  // store.dispatch({
  //   type: userAuthActions.UPDATE_AUTH_DETAILS,
  //   payload: { isAuthenticated: true }
  // });

  store.dispatch({
    type: userAuthActions.UPDATE_ADMIN_AUTH_DETAILS,
    payload: { isAuthenticated: true },
  })
}

//User
if (localStorage.getItem("WEB_TOKEN")) {
  setAuthToken(localStorage.getItem('WEB_TOKEN'), 'User');
  store.dispatch({
    type: userAuthActions.VERIFY_TOKEN,
    payload: localStorage.getItem("WEB_TOKEN"),
  })
  store.dispatch({
    type: userAuthActions.UPDATE_AUTH_DETAILS,
    payload: { isAuthenticated: true },
  })
}

const App = () => {

  const dispatch = useDispatch()

  //To achieve the synchronised login and logout in all browser tabs 
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'SuperAdminLogout') {
        localStorage.removeItem("GFX_TOKEN")
        dispatch({
          type: userAuthActions.UPDATE_SUPER_ADMIN_AUTH_DETAILS,
          payload: { isAuthenticated: false, route: "/login" },
        })
        // navigate("/login")
        window.location.reload();
      } else if (event.key === 'CreatorLogout') {
        localStorage.removeItem("GFX_TOKEN2")
        dispatch({
          type: userAuthActions.UPDATE_CREATOR_AUTH_DETAILS,
          payload: { isAuthenticated: false, route: "/login" },
        })
        // navigate("/login")
        window.location.reload();
      } else if (event.key === 'AdminLogout') {
        localStorage.removeItem("GFX_TOKEN3")
        dispatch({
          type: userAuthActions.UPDATE_ADMIN_AUTH_DETAILS,
          payload: { isAuthenticated: false, route: "/login" },
        })
        // navigate("/login")
        window.location.reload();
      }
      if (event.key === 'WebUserLogout') {
        localStorage.removeItem("WEB_TOKEN")
        dispatch({
          type: userAuthActions.UPDATE_AUTH_DETAILS,
          payload: { isAuthenticated: false },
        })
      }
      if (event.key === 'SuperAdminLogin') {
        window.location.reload();
      } else if (event.key === 'AdminLogin') {
        window.location.reload();
      } else if (event.key === 'CreatorLogin') {
        window.location.reload();
      }

      if (event.key === 'WebUserLogin') {
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <React.Fragment>
      <ToastMessage />
      <FileErrorModal />
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

export default App
