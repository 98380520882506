import { takeEvery, call, all, put, delay } from "redux-saga/effects"
import { BlogContentActions } from "./actions"
import { commonActions } from "../common/actions"
import axios from "axios"
import { API_URL } from "../../utils/constant"
import { WebHomePageActions } from "store/webHome/actions"
import { creatorDashboardActions } from "../../store/creatorDashboard/actions"

export const blogContentSagas = function* () {
  yield all([
    yield takeEvery(BlogContentActions.GETCONTENTLIST, getContentList),
    yield takeEvery(BlogContentActions.GETCONTENTDETAIL, getContentDetail),
    yield takeEvery(
      BlogContentActions.GET_RECENT_TOPIC_LIST,
      getRecentTopicList
    ),

    yield takeEvery(
      BlogContentActions.GET_ADMIN_CONTENT_LIST_NEED_APPROVAL,
      getContentNeedApproval
    ),
    yield takeEvery(
      BlogContentActions.GET_ADMIN_CONTENT_LIST_BY_STATUS,
      getContentByStatus
    ),
    yield takeEvery(
      BlogContentActions.GET_ADMIN_CONTENT_DETAIL,
      getContentDetailById
    ),
    yield takeEvery(
      BlogContentActions.CHANGE_CONTENT_STATUS,
      changeContentStatus
    ),
    yield takeEvery(BlogContentActions.CREATE_BLOG_CONTENT, createBlogContent),
    yield takeEvery(BlogContentActions.CREATE_ADMIN_PODCAST_CONTENT, createAdminPodcastContent),
    yield takeEvery(
      BlogContentActions.GET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL,
      getContentListByCreatorIDForApproval
    ),
    yield takeEvery(
      BlogContentActions.GET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS,
      getContentListByCreatorIDByStatus
    ),
    yield takeEvery(
      BlogContentActions.CREATE_PITCH_CONTENT,
      createPitchContent
    ),
    yield takeEvery(
      BlogContentActions.GET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL,
      getPitchListByCreatorIDForNeedApproval
    ),
    yield takeEvery(
      BlogContentActions.GET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS,
      getPitchListByCreatorIDByStatus
    ),
    yield takeEvery(
      BlogContentActions.GET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST,
      getAdminCreatorPitchNeedApprovalList
    ),
    yield takeEvery(
      BlogContentActions.GET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS,
      getAdminCreatorPitchListByStatus
    ),
    yield takeEvery(
      BlogContentActions.GET_CREATOR_PITCH_DETAIL,
      getCreatorPitchDetail
    ),
    yield takeEvery(
      BlogContentActions.ADMIN_CHANGE_PITCH_STATUS,
      adminChangePitch
    ),
    yield takeEvery(
      BlogContentActions.GET_PITCH_TO_CONTENT_LIST,
      pitchToContentList
    ),

    yield takeEvery(
      BlogContentActions.GET_RECENT_TOPIC_ADD_TOPIC,
      getRecentTopicAddTopic
    ),
    yield takeEvery(
      BlogContentActions.GET_NEWSLETTER_USER_EMAIL_LIST,
      getNewsletterUserEmailList
    ),
    yield takeEvery(
      BlogContentActions.GET_ADMIN_EDIT_CHANGE_CONTENT_STATUS,
      getAdminEditChangeContentStatus
    )
  ])
}

const getContentList = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/content/list`))
    yield put({
      type: BlogContentActions.SETCONTENTLIST,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
}

const getContentDetail = function* (data) {
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/detail/${payload}`)
    )
    yield put({
      type: BlogContentActions.SETCONTENTDETAIL,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
}

// Get Recent-Topic List
const getRecentTopicList = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/recentTopic/List`))
    yield put({
      type: BlogContentActions.SET_RECENT_TOPIC_LIST,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
}

const getContentNeedApproval = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/NeedApprovalList`)
    )
    if (result?.data?.result?.length !== 0) {
      yield put({
        type: BlogContentActions.SET_ADMIN_CONTENT_LIST_NEED_APPROVAL,
        payload: result?.data?.result,
      })
      yield put({
        type: BlogContentActions.SET_ADMIN_CONTENT_NEED_APPROVAL_COUNT,
        payload: result?.data?.length
      })
    } else {
      yield put({
        type: BlogContentActions.SET_ADMIN_CONTENT_LIST_NEED_APPROVAL,
        payload: [],
      })
    }
  } catch (error) {
    console.log(error)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

const getContentByStatus = function* (data) {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/listByStatus/${payload}`)
    )
    if (result?.data?.result?.length !== 0) {
      yield put({
        type: BlogContentActions?.SET_ADMIN_CONTENT_LIST_BY_STATUS,
        payload: result?.data?.result,
      })
    } else {
      yield put({
        type: BlogContentActions?.SET_ADMIN_CONTENT_LIST_BY_STATUS,
        payload: [],
      })
    }
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

const getContentDetailById = function* (data) {
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/detail/${payload}`)
    )   
    if (result?.data?.result) {
      yield put({
        type: BlogContentActions.SET_ADMIN_CONTENT_DETAIL,
        payload: result?.data?.result,
      })
    }
  } catch (err) {
    console.log(err)
  }
}

const changeContentStatus = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  const navigate = payload.navigate;
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/content/approveOrReject/${payload?.ContentId}`,
        payload?.data
      )
    )
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { TypeOfModal: null, isOpen: false, data: null },
      })
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "success",
          message: result?.data?.message,
        },
      })
      yield put({
        type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL,
        payload: payload?.ContentId,
      })
      if (payload?.previousValue === "New" && payload?.data?.Status !== "Rejected") {
        yield put({
          type: creatorDashboardActions.GET_TOTAL_WALLET_COUNT,
          payload: payload
        })
      }
      navigate("/content/needApproval")
    } else {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "failed",
          message: result?.data?.message,
        },
      })
    }
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    yield delay(2000)
    yield put({
      type: commonActions.SET_ALERT,
      payload: { show: false, status: null, message: null },
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
}


const createBlogContent = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/content/update`, payload?.data)
    )
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    yield put({
      type: BlogContentActions.BLOG_CONTENT_RESPONSE,
      payload: result?.data,
    })
    yield delay(2000)
    yield put({
      type: BlogContentActions.BLOG_CONTENT_RESPONSE,
      payload: null,
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
}

const createAdminPodcastContent = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/content/podcast/create`, payload?.data)
    )
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    yield put({
      type: BlogContentActions.BLOG_CONTENT_RESPONSE,
      payload: result?.data,
    })
    yield delay(2000)
    yield put({
      type: BlogContentActions.BLOG_CONTENT_RESPONSE,
      payload: {},
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
}

const getContentListByCreatorIDForApproval = function* (data) {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/listBasedOnCreatorForApproval/${payload}`)
    )
    yield put({
      type: BlogContentActions.SET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Get Creator list by status
const getContentListByCreatorIDByStatus = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/content/listBasedOnCreatorByStatus/${payload.Creator_Id}/${payload.Status}`
      )
    )
    yield put({
      type: BlogContentActions.SET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Create Pitch
const createPitchContent = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/pitch/create`, payload)
    )
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    yield put({
      type: BlogContentActions.BLOG_CONTENT_RESPONSE,
      payload: result?.data,
    })
    yield delay(2000)
    yield put({
      type: BlogContentActions.BLOG_CONTENT_RESPONSE,
      payload: {},
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
}

// Pitch List by creator Id(for creator dash-board)
const getPitchListByCreatorIDForNeedApproval = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/pitch/listBasedOnCreatorForApproval/${payload}`)
    )
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
    yield put({
      type: BlogContentActions.SET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Pitch List by Creator Id by Status
const getPitchListByCreatorIDByStatus = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/pitch/creatorPitchListByStatus/${payload.Creator_Id}/${payload.Pitch_Status}`
      )
    )
    yield put({
      type: BlogContentActions.SET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Creator-dashboard pitch need approval list
const getAdminCreatorPitchNeedApprovalList = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/pitch/NeedApprovalList`)
    )
    yield put({
      type: BlogContentActions?.SET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST,
      payload: result?.data,
    })
  } catch (error) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Creator-Dashboard get pitch list by status
const getAdminCreatorPitchListByStatus = function* (data) {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/pitch/listByStatus/${payload}`)
    )
    yield put({
      type: BlogContentActions?.SET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Get Creator pitch detail
const getCreatorPitchDetail = function* (data) {
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/pitch/detail/${payload}`)
    )
    yield put({
      type: BlogContentActions.SET_CREATOR_PITCH_DETAIL,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
}

const adminChangePitch = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  const navigate = payload.navigate
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/pitch/approveOrReject/${payload?.ContentId}`,
        payload?.data
      )
    )
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { TypeOfModal: null, isOpen: false, data: null },
      })
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "success",
          message: result?.data?.message,
        },
      })
      yield put({
        type: BlogContentActions.GET_CREATOR_PITCH_DETAIL,
        payload: payload?.ContentId,
      })
      // navigate('/admin/pitch/needApproval')
    } else {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "failed",
          message: result?.data?.message,
        },
      })
    }
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    yield delay(2000)
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    })
    yield delay(2000)
    yield put({
      type: commonActions.SET_ALERT,
      payload: { show: false, status: null, message: null },
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
}

const pitchToContentList = function* (data) {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/pitch/toCreateContent/${payload.Creator_Id}/${payload.Pitch_Status}`
      )
    )
    yield put({
      type: BlogContentActions.SET_PITCH_TO_CONTENT_LIST,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

const getRecentTopicAddTopic = function* (data) {
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/recentTopic/edit/${payload.Id}`, payload.payload)
    )
    yield put({
      type: BlogContentActions.SET_RECENT_TOPIC_ADD_TOPIC,
      payload: result?.data,
    })
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "success",
          message: result?.data?.message,
        },
      })

      yield put({
        type: WebHomePageActions.GET_WEBHOME_CONTENTBY_RECENT_TOPIC,
      })
    }
  } catch (err) {
    console.error(err)
  }
}


const getNewsletterUserEmailList = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() => axios.get(`${API_URL}/newsLetter/list`))
    yield put({
      type: BlogContentActions.SET_NEWSLETTER_USER_EMAIL_LIST,
      payload: result?.data,
    })
  } catch (err) {
    console.log(err)
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}


const getAdminEditChangeContentStatus = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  const navigate = payload.navigate;
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/content/adminEditApproveContent/${payload?.ContentId}`,
        payload?.data
      )
    )
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { TypeOfModal: null, isOpen: false, data: null },
      })
      if (payload?.previousValue === "New") {
        let priceValue = {
          Creator_Id: data?.payload?.Creator_Id,
          data: { Creator_Price: data?.payload?.Creator_Price }
        }
        yield put({
          type: creatorDashboardActions.GET_TOTAL_WALLET_COUNT,
          payload: priceValue
        })
      }
    }
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    navigate('/content/needApproval')
    yield delay(2000)
    yield put({
      type: commonActions.SET_ALERT,
      payload: { show: false, status: null, message: null },
    })
  } catch (err) {
    console.log(err)
  }
}


export default getAdminEditChangeContentStatus;