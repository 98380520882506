import React, { useState, useEffect } from "react";
import { Container, Card, CardBody, Form, Input, Label, Button, Row, Col, FormFeedback, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContentCreatorActions } from "../../store/contentCreator/actions";
import Breadcrumb from "../../common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from 'yup';
import UpdateCreatorStatusModal from "components/commonModals/updateCreatorStatusModal";
import { commonActions } from "../../store/common/actions";
import FileSizeValidation from '../../FileSizeValidation/index';
import Select from 'react-select';
import { actions } from "store/Countries/actions";
import Multiselect from 'multiselect-react-dropdown';

export default function EditContentCreatorDetailsIndex() {

    document.title = "Content | Allen Dreyfus";
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileInputRef = React.createRef();

    const [checkStatus, setCheckStatus] = useState(false)
    const [modalType, setModalType] = useState('')
    const [StatusCheckFromModal, setStatusCheckFromModal] = useState(false)

    const { buttonLoader } = useSelector(state => state.commonReducer);
    const { showPopup } = useSelector(state => state.commonReducer);
    const { onlyCountryList } = useSelector(state => state.CountryReducer);
    const { superAdminTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer);
    const { content_Creator_Details } = useSelector(state => state.ContentCreatorReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_ONLY_COUNTRY_LIST })
    }, [])

    useEffect(() => {
        dispatch({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: state?.Id });
    }, [state?.Id, checkStatus, StatusCheckFromModal]);

    let Category = [
        { id: 1, value: 'Business', name: 'business' },
        { id: 2, value: 'Politics', name: 'politics' },
        // { id: 3, value: 'Green', name: 'green' },
        // { id: 4, value: 'In-Depth', name: 'in-depth' },
        // { id: 5, value: 'Recent Topics', name: 'recent topics' },
        // { id: 6, name: 'Frontier Markets' },
    ]

    var [profileImage, setProfileImage] = useState('');
    const [imageFile, setImageFile] = useState("");
    const [imageError, setImageError] = useState("");
    const [countryOptions, setCountryOptions] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryError, setCategoryError] = useState("");


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: content_Creator_Details?.Name,
            Email: content_Creator_Details?.Email,
            Contact_Number: content_Creator_Details?.Contact_Number,
            Profile_Image: content_Creator_Details?.Profile_Image,
            Brief_Bio_Description: content_Creator_Details?.Brief_Bio_Description,
            Profile_Image: content_Creator_Details?.Profile_Image,
            Country: content_Creator_Details?.Country[0],
            Category: content_Creator_Details?.Category,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("name is required"),
            Email: Yup.string().required("Email is required"),
            Contact_Number: Yup.string().required("Contact number is required"),
            Brief_Bio_Description: Yup.string().min(50, "Minimum 50 characters required").required("Description is required")
        }),
        onSubmit: (data) => {
            if (categoryOptions?.length === 0) {
                setCategoryError("please select the content type");
                return;
            }
            if (!profileImage) {
                setImageError("Profile Image is required");
                return;
            }
            setImageError("");
            const formData = new FormData();

            formData.append('Id', content_Creator_Details?._id);
            formData.append('Name', data?.Name);
            formData.append('Email', data?.Email);
            formData.append('Contact_Number', data?.Contact_Number);
            formData.append('Brief_Bio_Description', data?.Brief_Bio_Description);
            formData.append("Country", JSON.stringify(countryOptions?.value));
            var category = [];
            categoryOptions.map(val =>
                category = [...category, val.value]);
            formData.append("Category", JSON.stringify(category));
            if (data?.Profile_Image) {
                formData.append('Profile_Image', data?.Profile_Image);
            } else {
                formData.append("Profile_Image", imageFile);
            }

            // for (var pair of formData.entries()) { //to console formdata
            //     console.log(pair[0] + ', ' + pair[1]);
            // }

            let Updated_By;
            if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                Updated_By = {
                    Name: superAdminTokenResponse?.result?.Name,
                    Email: superAdminTokenResponse?.result?.Email,
                    Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                }
                if (content_Creator_Details?.Registration_Status === 'Unregistered') {
                    formData.append("Registration_Status_Updated_By", JSON.stringify(Updated_By));
                } else if (content_Creator_Details?.Registration_Status === 'Registered') {
                    formData.append("Updated_By", JSON.stringify(Updated_By));

                }
            } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                Updated_By = {
                    Name: adminTokenResponse?.result?.Name,
                    Email: adminTokenResponse?.result?.Email,
                    Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                }
                if (content_Creator_Details?.Registration_Status === 'Unregistered') {
                    formData.append("Registration_Status_Updated_By", JSON.stringify(Updated_By));
                } else if (content_Creator_Details?.Registration_Status === 'Registered') {
                    formData.append("Updated_By", JSON.stringify(Updated_By));

                }
            }
            //_____________

            if (content_Creator_Details?.Registration_Status === 'Unregistered') {
                formData.append('Registration_Status', "Approved");
                handleShowModel('Approve', formData);
            } else {
                dispatch({
                    type: ContentCreatorActions.UPDATE_CONTENT_CREATOR,
                    payload: { id: content_Creator_Details?._id, data: formData, navigate: navigate, state: content_Creator_Details }
                });
            }

            //_____________
        }
    });

    // Country List
    useEffect(() => {
        if (onlyCountryList?.result) {
            const countryList = onlyCountryList.result.map(val => ({
                value: val.Country_Name,
                label: val.Country_Name,
            }));
            setCountryArray(countryList);
            if (content_Creator_Details?.Country?.[0]) {
                const defaultCountryOption = {
                    value: content_Creator_Details?.Country[0],
                    label: content_Creator_Details?.Country[0],
                };
                setCountryOptions(defaultCountryOption);
            } else if (countryList.length > 0) {
                setCountryOptions(countryList[0]);
            }
        }
    }, [onlyCountryList, content_Creator_Details]);

    const handleSelectChange = selectedOption => {
        setCountryOptions(selectedOption)
    };

    // Category
    useEffect(() => {
        if (content_Creator_Details?.Category) {
            const defaultCategoryOption = content_Creator_Details?.Category.map(cat => {
                return Category.find(c => c.value === cat);
            }).filter(Boolean);
            setCategoryOptions(defaultCategoryOption)
        }
    }, [content_Creator_Details])

    const onSelectCategory = (selectedCategory) => {
        setCategoryOptions(selectedCategory);
        setCategoryError("");
    };

    const onRemoveCategory = (selectedCategory) => {
        setCategoryOptions(selectedCategory);
    };

    const handleShowModel = (modalType, formData) => {
        formData.append('ModalType', modalType);
        // for (var pair of formData.entries()) { //to console formdata
        //     console.log(pair[0] + ', ' + pair[1]);
        // }
        let GivenFormdata = {
            modalType: modalType, formData: formData
        }
        setModalType(GivenFormdata)
        setCheckStatus(true)
        dispatch({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: Id });
    };

    const handleCloseModel = () => {
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { TypeOfModal: null, isOpen: false, data: null }
        });
    };

    const handleUpdateStatus = (formData) => {
        setStatusCheckFromModal(true)
        setCheckStatus(false)
        let GivenFormData = {
            formData: formData
        }
        setModalType(GivenFormData)
        dispatch({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: state?.Id });
    };


    // Profile Image
    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        setImageError("");
        if (imageFile) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
                fileInputRef.current.value = null
            } else {
                if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
                    result?.FinalImages[0]?.type === 'image/png') {
                    setProfileImage(URL.createObjectURL(imageFile));
                    setImageFile(imageFile);
                } else {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { show: true, status: "failed", message: "Unsupported File Format" }
                    })
                    fileInputRef.current.value = null
                }
            }
        } else {
            setImageError("Profile Image is required");
        }
    };

    const handleRemoveImage = () => {
        setProfileImage("");
        setImageFile("");
        setImageError(""); // Reset the image error
        validation.setFieldValue('Profile_Image', '');
    };

    useEffect(() => {
        setProfileImage(validation.values.Profile_Image || '');
    }, [validation.values]);

    //To check the current registration status before approve or reject
    useEffect(() => {
        if (checkStatus === true && (content_Creator_Details?.Registration_Status === 'Registered' || content_Creator_Details?.Registration_Status === 'Rejected') && (modalType?.modalType === 'Approve' || modalType?.modalType === 'Reject')) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Registration Status already updated" }
            })
            // setStatusCheckFromModal(false)
            // setCheckStatus(false)
            navigate('/content-creators/Registered')
        } else if (checkStatus === true && content_Creator_Details?.Registration_Status === 'Unregistered' && (modalType?.modalType === 'Approve' || modalType?.modalType === 'Reject')) {
         dispatch({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: 'CCAORR', isOpen: true, data: { modalType: modalType?.modalType, formData: modalType?.formData } }
            });
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (StatusCheckFromModal === true && content_Creator_Details?.Registration_Status === 'Unregistered') {
           dispatch({
                type: ContentCreatorActions.ADMIN_EDIT_APPROVE_CREATOR_REGISTRATION_STATUS,
                payload: {
                    Id: content_Creator_Details?._id,
                    data: modalType?.formData,
                    navigate: navigate
                },
            });
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (StatusCheckFromModal === true && (content_Creator_Details?.Registration_Status === 'Registered' || content_Creator_Details?.Registration_Status === 'Rejected')) {
           dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Registration Status already updated" }
            })
            handleCloseModel()
            navigate('/content-creators/Registered')
        }
    }, [content_Creator_Details])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title="Content Creators"
                        titleLink="/content-creators/Registered"
                        breadcrumbItem="edit content creator"
                    />
                    <Card>
                        <CardBody>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col sm="12">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Name</Label> <span className="text-danger fw-bold">*</span>
                                            <Input
                                                name="Name"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="text"
                                                value={validation?.values?.Name}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation.touched.Name && validation.errors.Name ? true : false}
                                            />
                                            {validation.touched.Name && validation.errors.Name ?
                                                <FormFeedback type='invalid'>{validation.errors.Name}</FormFeedback> :
                                                null
                                            }
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Email</Label> <span className="text-danger fw-bold">*</span>
                                            <Input
                                                name="Email"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="text"
                                                value={validation?.values?.Email}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation.touched.Email && validation.errors.Email ? true : false}
                                                disabled
                                            />
                                            {validation.touched.Email && validation.errors.Email ?
                                                <FormFeedback type='invalid'>{validation.errors.Email}</FormFeedback> :
                                                null
                                            }
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Contact Number</Label> <span className="text-danger fw-bold">*</span>
                                            <Input
                                                name="Contact_Number"
                                                className="form-control"
                                                placeholder="Enter contact number"
                                                type="text"
                                                value={validation?.values?.Contact_Number}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation.touched.Contact_Number && validation.errors.Contact_Number ? true : false}
                                            />
                                            {validation.touched.Contact_Number && validation.errors.Contact_Number ?
                                                <FormFeedback type="invalid">{validation.errors.Contact_Number}</FormFeedback> :
                                                null
                                            }
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Country</Label>
                                            <Select
                                                placeholder="Select a country"
                                                options={countryArray}
                                                value={countryOptions || []}
                                                onChange={handleSelectChange}
                                                onBlur={validation.handleBlur}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Type of content</Label>
                                            <Multiselect
                                                options={Category}
                                                displayValue="name"
                                                onSelect={onSelectCategory}
                                                onRemove={onRemoveCategory}
                                                selectedValues={categoryOptions}
                                                value={categoryOptions || []}
                                            />
                                            <div>
                                                <div><span className="market-select">selected values:</span> {categoryOptions?.map((option) => option.name).join(', ')}</div>
                                            </div>
                                            {categoryError && <div style={{ color: "red" }}>{categoryError}</div>}
                                        </div>
                                    </Col>

                                    <Col sm="12">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Brief Description</Label> <span className="text-danger fw-bold">*</span>
                                            <Input
                                                name="Brief_Bio_Description"
                                                placeholder="Enter Description"
                                                type="textarea"
                                                className="form-control-textarea"
                                                value={validation?.values?.Brief_Bio_Description}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation.touched.Brief_Bio_Description && validation.errors.Brief_Bio_Description ? true : false}
                                            />
                                            {validation.touched.Brief_Bio_Description && validation.errors.Brief_Bio_Description ?
                                                <FormFeedback type='invalid'>{validation.errors.Brief_Bio_Description}</FormFeedback> :
                                                null
                                            }
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Profile Image</Label> <span className="text-danger fw-bold">*</span>

                                            <span><small className="text-success"><i> (Supported Image formats : .jpeg, .jpg, .png)</i></small></span>
                                            {profileImage?.length !== 0 && (
                                                <div>
                                                    <img
                                                        className="profile-pic"
                                                        src={profileImage}
                                                        alt="Selected"
                                                        style={{
                                                            padding: "10px",
                                                            maxWidth: "50%",
                                                            maxHeight: "150px",
                                                        }}
                                                    />

                                                    <button
                                                        className="remove-button"
                                                        type="button"
                                                        onClick={handleRemoveImage}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            )}


                                            <Col className="mb-4" md="12">
                                                {!profileImage && (
                                                    <Input
                                                        type="file"
                                                        name="newImage"
                                                        innerRef={fileInputRef}
                                                        accept="image/png, image/jpeg"
                                                        onChange={handleImageChange}
                                                        multiple={false}
                                                    />
                                                )}
                                                {imageError && (
                                                    <div style={{ color: "red" }}>{imageError}</div>
                                                )}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-4">
                                    {content_Creator_Details?.Registration_Status === 'Registered' ?
                                        <Button
                                            className="me-3"
                                            type="submit"
                                            color="primary"
                                        >
                                            {buttonLoader ? <Spinner color="light" size="sm" /> : "Submit"}
                                        </Button> :
                                        <Button
                                            className="me-3"
                                            type="submit"
                                            color="primary"
                                        >
                                            {buttonLoader ? <Spinner color="light" size="sm" /> : "Approve"}
                                        </Button>
                                    }
                                    <Button
                                        type="button"
                                        color="danger"
                                        onClick={() => navigate(content_Creator_Details?.Registration_Status === 'Registered' ? '/content-creators/Registered' : '/content-creators/Unregistered')}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
                <UpdateCreatorStatusModal
                    modal={showPopup?.TypeOfModal === 'CCAORR' && showPopup?.isOpen}
                    data={content_Creator_Details}
                    toggle={handleCloseModel}
                    title={showPopup?.data?.modalType === 'Approve' ?
                        "approve content creator?" : "reject content creator?"}
                    message={showPopup?.data?.modalType === 'Approve' ?
                        `Are you sure you want to Approve the Content Creator (${content_Creator_Details?.Name})?` :
                        `Are you sure you want to Reject the Content Creator (${content_Creator_Details?.Name})?`}
                    handleSubmit={() => handleUpdateStatus(showPopup?.data?.formData)} // Pass 'value' to handleUpdateStatus
                    type={showPopup?.data?.modelType}
                    formData={showPopup?.data?.formData}
                />
            </div>
        </React.Fragment >
    )
}