import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Input, Button, FormFeedback, Form, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContentCreatorActions } from "../../store/contentCreator/actions";
import Breadcrumb from "../../common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Multiselect from 'multiselect-react-dropdown';
import { actions } from "../../store/Countries/actions"

export default function AddContentCreatorIndex() {

    document.title = 'Add Content Creator'
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let Category = [
        { id: 1, value: 'Business', name: 'business' },
        { id: 2, value: 'Politics', name: 'politics' },
        // { id: 3, value: 'Green', name: 'green' },
        // { id: 4, value: 'In-Depth', name: 'in-depth' },
        { id: 5, value: 'Recent Topics', name: 'recent topics' },
        // { id: 6, name: 'Frontier Markets' },
    ]

    const { buttonLoader } = useSelector(state => state.commonReducer);
    const { countryList } = useSelector(state => state.CountryReducer);
    const { creator_Response } = useSelector(state => state.ContentCreatorReducer);

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState("");
    const [fileError, setFileError] = useState("");

    const [countryOptions, setCountryOptions] = useState([]);
    const [countryError, setCountryError] = useState("");

    var [countryArray, setCountryArray] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryError, setCategoryError] = useState("");

    useEffect(() => {
        dispatch({ type: actions.GET_COUNTRY_LIST });
    }, [])

    // Country List
    useEffect(() => {
        let countryArray = countryList?.result?.map((val) => ({
            id: val?.Country_Name,
            name: val?.Country_Name,
        }));
        setCountryArray(countryArray)

    }, [countryList])

    const imageRef = useRef();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: '',
            Email: '',
            Contact_Number: '',
            Brief_Bio_Description: '',
            Category: [],
            Country: [],
            Type_Of_User: 'Content Creator',
            Registration_Status: 'Registered',
            Profile_Image: null
        },
        validationSchema: Yup.object({
            Name: Yup.string().required('name is required'),
            Email: Yup.string()
                .required('email is required')
                .email('invalid email format')
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'invalid email format')
                .test('no-uppercase', 'email must not contain uppercase letters', (value) => {
                    // Test function to check for uppercase letters
                    return !/[A-Z]/.test(value);
                }),
            Contact_Number: Yup.string().required('contact number is required'),
            Brief_Bio_Description: Yup.string().min(50, 'minimum 50 characters required').required('bio is required'),
        }),
        onSubmit: (data) => {
            if (!selectedImage) {
                setFileError("profile image is required");
                return;
            } else if (countryOptions?.length === 0) {
                setCountryError("please select the country");
            } else if (categoryOptions?.length === 0) {
                setCategoryError("please select the content type");
            } else {
                const formData = new FormData();
                formData.append('Name', data.Name)
                formData.append('Email', data.Email);
                formData.append('Contact_Number', data.Contact_Number);
                formData.append('Brief_Bio_Description', data.Brief_Bio_Description);
                var Country = [];
                countryOptions.map(val =>
                    Country = [...Country, val.name]);
                formData.append("Country", JSON.stringify(Country));
                var category = [];
                categoryOptions.map(val =>
                    category = [...category, val.value]);
                formData.append("Category", JSON.stringify(category));
                formData.append('Profile_Image', imageFile);
                formData.append('Type_Of_User', "Content Creator");
                formData.append('Registration_Status', "Registered");

                // for (const pair of formData.entries()) {
                //     console.log(pair[0], pair[1]);
                // }
                dispatch({
                    type: ContentCreatorActions.CREATOR_REGISTRATION,
                    payload: { data: formData, page: 'register', reset: resetFormFunction }
                });
            }
        }
    });

    useEffect(() => {
        if (creator_Response && creator_Response?.statusCode === 200) {
            // Clear the form 
            validation.resetForm();
            imageRef.current.value = null; //to clear img file   
            setSelectedImage(null);
            setFileError("");
            setCountryOptions([]);
            setCategoryOptions([]);
            setCountryArray(countryArray)
        }
    }, [creator_Response]);

    const resetFormFunction = () => {
        validation.resetForm();
        imageRef.current.value = null;
        setSelectedImage({ url: null, valid: false });
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFileError("");

        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setImageFile(file);
        } else {
            setFileError("profile image is required");
        }
    };

    // Country
    const onSelectCounty = (selectedCountry) => {
        setCountryOptions(selectedCountry);
        setCountryError("");
    };

    const onRemoveCountry = (selectedCountry) => {
        setCountryOptions(selectedCountry);
    };

    // Category
    const onSelectCategory = (selectedCategory) => {
        setCategoryOptions(selectedCategory);
        setCategoryError("");
    };

    const onRemoveCategory = (selectedCategory) => {
        setCategoryOptions(selectedCategory);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title="Content Creators"
                        titleLink="/content-creators/Registered"
                        breadcrumbItem="add content creator"
                    />
                    <Row>
                        <Col xs="12">
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Name</Label> <span className="text-danger fw-bold">*</span>
                                                    <Input
                                                        name="Name"
                                                        placeholder="enter user name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Name ?? ''}
                                                        invalid={validation.touched.Name && validation.errors.Name ? true : false}
                                                    />
                                                    {validation.touched.Name && validation.errors.Name ?
                                                        <FormFeedback type='invalid'>{validation.errors.Name}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Email</Label> <span className="text-danger fw-bold">*</span>
                                                    <Input
                                                        name="Email"
                                                        className="formcontrol"
                                                        placeholder="enter email"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Email ?? ''}
                                                        invalid={validation.touched.Email && validation.errors.Email ? true : false}
                                                    />
                                                    {validation.touched.Email && validation.errors.Email ?
                                                        <FormFeedback type='invalid'>{validation.errors.Email}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Contact Number</Label> <span className="text-danger fw-bold">*</span>
                                                    <Input
                                                        name="Contact_Number"
                                                        placeholder="enter contact number"
                                                        type="text"
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Contact_Number ?? ''}
                                                        invalid={validation.touched.Contact_Number && validation.errors.Contact_Number ? true : false}
                                                    />
                                                    {validation.touched.Contact_Number && validation.errors.Contact_Number ?
                                                        <FormFeedback type='invalid'>{validation.errors.Contact_Number}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Bio</Label><span className="text-danger fw-bold">*</span>
                                                    <Input className="form-control"
                                                        type="textarea"
                                                        name="Brief_Bio_Description"
                                                        placeholder="Enter Bio Description"
                                                        rows="4" cols="50"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Brief_Bio_Description || ""}
                                                        invalid={
                                                            validation.touched.Brief_Bio_Description && validation.errors.Brief_Bio_Description ? true : false
                                                        }
                                                    />
                                                    {validation.touched.Brief_Bio_Description && validation.errors.Brief_Bio_Description ? (
                                                        <FormFeedback type="invalid">{validation.errors.Brief_Bio_Description}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label htmlFor="Title" className="form-label">Country</Label><span className="text-danger fw-bold">*</span>
                                                    <Multiselect
                                                        options={countryArray}
                                                        displayValue="name"
                                                        onSelect={onSelectCounty}
                                                        onRemove={onRemoveCountry}
                                                        selectedValues={countryOptions}
                                                        value={validation.values.Country || []}
                                                    />
                                                    <div>
                                                        <div><span className="market-select">Selected Values:</span> {countryOptions?.map((option) => option.name).join(', ')}</div>
                                                    </div>
                                                    {countryError && <div style={{ color: "red" }}>{countryError}</div>}
                                                </div>
                                            </Col>

                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label htmlFor="Title" className="form-label">Type of Content</Label><span className="text-danger fw-bold">*</span>
                                                    <Multiselect
                                                        options={Category}
                                                        displayValue="name"
                                                        onSelect={onSelectCategory}
                                                        onRemove={onRemoveCategory}
                                                        selectedValues={categoryOptions}
                                                        value={validation.values.Category || []}
                                                    />
                                                    <div>
                                                        <div><span className="market-select">Selected Values:</span> {categoryOptions?.map((option) => option.name).join(', ')}</div>
                                                    </div>
                                                    {categoryError && <div style={{ color: "red" }}>{categoryError}</div>}
                                                </div>
                                            </Col>

                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Profile Image <span className='text-danger'>*</span></Label>

                                                    {selectedImage && (
                                                        <div >
                                                            <img src={selectedImage} alt="Selected" style={{ padding: '10px', maxWidth: '50%', maxHeight: '150px' }} />
                                                        </div>
                                                    )}

                                                    <Input
                                                        type="file"
                                                        name="image"
                                                        innerRef={imageRef}
                                                        accept="image/png, image/jpeg"
                                                        onChange={handleImageChange}
                                                        multiple={false}
                                                    />
                                                    {fileError && <small> <div style={{ color: "red" }}>{fileError}</div></small>}
                                                </div>
                                                <div>
                                                    <img
                                                        src={selectedImage?.url}
                                                        alt=""
                                                        style={{ padding: '10px', maxWidth: '50%', maxHeight: '150px' }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mt-4">
                                            <Button
                                                className="me-3"
                                                type="submit"
                                                color="primary"
                                            >
                                                {buttonLoader ? <Spinner color="light" size="sm" /> : "Add"}
                                            </Button>
                                            <Button
                                                type="button"
                                                color="danger"
                                                onClick={() => navigate('/content-creators/Registered')}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}