import React, { useMemo, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import Select from 'react-select';
import { useFormik, Field } from "formik";
import DOMPurify from "dompurify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { BlogContentActions } from "store/blogContent/actions";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from "react-router-dom";
import success_img from "../../assets/images/dashboard/success1-popup-image.gif";
import failure_img from "../../assets/images/dashboard/failure-popup-image.gif";
import { commonActions } from "../../store/common/actions";
import { ToastContainer } from "react-toastify";
import AdminPageLoader from "../../common/adminPageLoader";
import Breadcrumbs from "../../common/Breadcrumb";
import TableContainer from '../../common/TableContainer';

export default function CreateCotentIndex({ }) {
    document.title = "Content creation";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    var [pitchList, setPitchList] = useState([])
    var [creatorID, setCreatorID] = useState('')

    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    useEffect(() => {
        setCreatorID(creatorTokenResponse?.result?._id);
    }, [creatorTokenResponse])

    const { pitchToContentList } = useSelector(state => state.BlogContentReducer);
    const { adminPageLoader  } = useSelector(state => state.commonReducer);

    let type = "Approved";

    useEffect(() => {
      if(creatorID){
        dispatch({
            type: BlogContentActions.GET_PITCH_TO_CONTENT_LIST,
            payload: { Creator_Id: creatorID, Pitch_Status: "Approved" },
        })
      }
    }, [dispatch, creatorID])

    useEffect(() => {
        if (pitchToContentList?.result?.length !== 0) {
            setPitchList(pitchToContentList?.result);
        }
    }, [pitchToContentList])


    const columns = useMemo(
        () => [
            // {
            //     Header: 'Id',
            //     accessor: '_id',
            //     filterable: true,
            // },
            {
                Header: 'title',
                accessor: 'Title',
                filterable: true,
                Cell: title => {
                    return <ContentTitle {...title} />
                }
            },
            // {
            //     Header: 'Country',
            //     accessor: 'Country',
            //     filterable: true,
            // },
            {
                Header: 'pitch status',
                accessor: 'Pitch_Status',
                filterable: true,
            },
            {
                Header: 'submitted on',
                accessor: 'Pitch_Created_On',
                filterable: true,
            },
            {
                Header: 'submitted by',
                accessor: 'Created_By',
                filterable: true,
            },
            // {
            //     Header: 'action',
            //     Cell: (cellProps) => {
            //         return (
            //             <UncontrolledDropdown>
            //                 <DropdownToggle tag="a" className="card-drop">
            //                     <i className="mdi mdi-dots-horizontal font-size-18"></i>
            //                 </DropdownToggle>

            //                 <DropdownMenu className="dropdown-menu-end">
            //                     <DropdownItem
            //                         onClick={() => {
            //                             const Data = cellProps.row.original;
            //                             navigate('/pitch-detail', { state: { data: Data, type: type,clickedFrom:'ContentCreation' } })

            //                         }
            //                         }
            //                     >
            //                         <i className="mdi mdi-eye font-size-16 text-primary me-1" id="edittooltip"></i>
            //                         View
            //                         <UncontrolledTooltip placement="top" target="edittooltip">
            //                             View
            //                         </UncontrolledTooltip>
            //                     </DropdownItem>
            //                 </DropdownMenu>
            //             </UncontrolledDropdown>
            //         );
            //     }
            // },
            {
                Header: 'action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const Data = cellProps.row.original;
                                    navigate('/pitch-detail', { state: { data: Data, type: type, clickedFrom: 'ContentCreation' } });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <div className="page-content">
            {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs className="small-letter" title="pitch" breadcrumbItem={`${type === 'Approved' ? 'approved' : type} list`} />
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {pitchList && pitchList.length > 0 ? (
                                            <TableContainer
                                                columns={columns}
                                                data={pitchList || []}
                                                isGlobalFilter={true}
                                                isAddCustList={false}
                                                isPagination={true}
                                                customPageSize={10}
                                                tableClass="align-middle table-nowrap"
                                                isShowingPageLength={false}
                                                iscustomPageSizeOptions={true}
                                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                            />
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

const ContentTitle = (cell) => {
    return (cell?.value && cell?.value?.length > 25) ? `${cell?.value?.slice(0, 25)}...` : cell?.value
}