import React, { useEffect } from "react";
import {
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth
} from "../../store/actions";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import { useSelector, useDispatch } from "react-redux";

const Layout = props => {
  const dispatch = useDispatch();

  const { leftSideBarType } = useSelector((state) => state.Layout);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeSidebarTheme('colored'));
    // dispatch(changeSidebarTheme('dark'));
    dispatch(changeLayoutMode('light'));
    dispatch(changeLayoutWidth('fluid'));
    dispatch(changeSidebarType('default'));
    dispatch(changeTopbarTheme('light'));
  }, []);

  return (
    <React.Fragment>

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
};

export default Layout;
