import CustomersIndex from "components/customers";
import React from "react";

export default function Customers() {

    return (
        <div>
            <CustomersIndex />
        </div>
    )
}