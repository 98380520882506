import { commonActions } from "./actions";

const initialState = {
    alert: {
        show: false,
        status: null,
        message: null
    },
    buttonLoader: false,
    adminPageLoader: false,
    webPageLoader: false,
    showPopup: {
        TypeOfModal: null,
        isOpen: false,
        data: null
    },
    fileValidation: false,
};

export const commonReducer = (state = initialState, action) => {

    switch (action.type) {
        case commonActions.SET_ALERT:
            return {
                ...state,
                alert: action.payload
            }
        case commonActions.SET_BUTTON_LOADER:
            return {
                ...state,
                buttonLoader: action.payload
            }
        case commonActions.SET_PAGE_LOADER:
            return {
                ...state,
                adminPageLoader: action.payload
            }
        case commonActions.SET_WEB_PAGE_LOADER:
            return {
                ...state,
                webPageLoader: action.payload
            }
        case commonActions.SET_SHOW_MODAL:
            return {
                ...state,
                showPopup: action.payload
            }
        case commonActions.SET_FILE_VALIDATION:
            return {
                ...state,
                fileValidation: action.payload
            }
        default:
            return state;
    }
};