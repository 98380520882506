import { WithdrawActions } from './actions'
import axios from 'axios'
import { takeEvery, call, all, put, delay } from 'redux-saga/effects'
import { API_URL } from "../../utils/constant"
import { commonActions } from "../common/actions";
import { creatorDashboardActions } from "../creatorDashboard/actions";

export const WithdrawRequestSagas = function* () {
    yield all([
        yield takeEvery(WithdrawActions.CREATE_WITHDRAW_REQUEST, createWithdrawRequest),
        yield takeEvery(WithdrawActions.GET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID, getWithdrawRequestById),
        yield takeEvery(WithdrawActions.GET_ALL_WITHDRAW_REQUEST_LIST, getAllWithdrawRequests),
        yield takeEvery(WithdrawActions.GET_WITHDRAW_REQUEST_DETAIL, getWithdrawRequestDetail),
        yield takeEvery(WithdrawActions.UPDATE_WITHDRAW_REQUEST_STATUS, updateWithdrawRequestStatus),
    ])
}

const createWithdrawRequest = function* (data) {
    const { payload } = data;
    const { navigate } = payload;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/withdrawRequest/create`, payload?.data)
        )
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        if (result?.data?.statusCode === 200) {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "success", message: result?.data?.message }
            });
            navigate('/withdrawal-request')
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: result?.data?.message }
            });
        };

    } catch (err) {
        console.log(err)
    }
}

//Withdraw request list by creator Id

const getWithdrawRequestById = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/withdrawRequest/ListByCreatorID/${payload}`)
        );
        yield put({
            type: WithdrawActions.SET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

const getAllWithdrawRequests = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/withdrawRequest/AllList`)
        );
        yield put({
            type: WithdrawActions.SET_ALL_WITHDRAW_REQUEST_LIST,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

//Withdraw request detail by Id

const getWithdrawRequestDetail = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/withdrawRequest/detailById/${payload}`)
        );
        yield put({
            type: WithdrawActions.SET_WITHDRAW_REQUEST_DETAIL,
            payload: result?.data
        })
    } catch (err) {
        console.log(err)
    }
}

const updateWithdrawRequestStatus = function* (data) {
    const { payload } = data;
    const { navigate } = payload;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/withdrawRequest/updateStatus/${payload?.id}`, payload?.data)
        )
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        if (result?.data?.statusCode === 200) {
            if (payload?.data?.Status === 'Rejected') {
                payload.handleCloseModel(false)
            }
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "success", message: result?.data?.message }
            });
            yield delay(2000);
            navigate('/all-withdrawal-request')
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: result?.data?.message }
            });
        };

    } catch (err) {
        console.log(err)
    }
}