export const adminActions = {
    GET_ADMIN_LIST: 'GET_ADMIN_LIST',
    SET_ADMIN_LIST: 'SET_ADMIN_LIST',

    GET_ADMIN_DETAILS: 'GET_ADMIN_DETAILS',
    SET_ADMIN_DETAILS: 'SET_ADMIN_DETAILS',

    CREATE_ADMIN: 'CREATE_ADMIN',
    UPDATE_ADMIN: 'UPDATE_ADMIN',
    UPDATE_ADMIN_STATUS: 'UPDATE_ADMIN_STATUS',
}