import React, { useState, useEffect, useRef } from "react";
import { Container, Card, CardBody, CardTitle, Button, Input, Label, Row, Col, Table, FormFeedback, Form, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Breadcrumb from "../../common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { ContentCreatorActions } from "../../store/contentCreator/actions";
import FileSizeValidation from '../../FileSizeValidation/index'
import { commonActions } from "../../store/common/actions";
import uploadImage from "../../../src/assets/webImages/upload3.png";
import Select from 'react-select';
import { actions } from "store/Countries/actions";
import Multiselect from 'multiselect-react-dropdown';

const UserProfile = () => {
    document.title = "Profile | Allen Dreyfus";

    const dispatch = useDispatch();

    let Category = [
        { id: 1, value: 'Business', name: 'business' },
        { id: 2, value: 'Politics', name: 'politics' },
        // { id: 3, value: 'Green', name: 'green' },
        // { id: 4, value: 'In-Depth', name: 'in-depth' },
        // { id: 5, value: 'Recent Topics', name: 'recent topics' },
        // { id: 6, name: 'Frontier Markets' },
    ]

    const [profileImage, setProfileImage] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    const [countryError, setCountryError] = useState("");
    const [countryArray, setCountryArray] = useState([]);
    const [defaultCountry, setDefaultCountry] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryError, setCategoryError] = useState("");

    const { buttonLoader } = useSelector(state => state.commonReducer);
    const { onlyCountryList } = useSelector(state => state.CountryReducer);
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_ONLY_COUNTRY_LIST })
    }, [])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: creatorTokenResponse?.result?.Name,
            Email: creatorTokenResponse?.result?.Email,
            Brief_Bio_Description: creatorTokenResponse?.result?.Brief_Bio_Description,
            Contact_Number: creatorTokenResponse?.result?.Contact_Number || "",
            Country: creatorTokenResponse?.result?.Country[0],
            Category: creatorTokenResponse?.result?.Category,
            Bank_AC_Number: creatorTokenResponse?.result?.Bank_AC_Number || "",
            Name_As_Per_Account: creatorTokenResponse?.result?.Name_As_Per_Account || "",
            Bank_Name: creatorTokenResponse?.result?.Bank_Name || "",
            Branch: creatorTokenResponse?.result?.Branch || "",
            IBAN_Number: creatorTokenResponse?.result?.IBAN_Number || "",
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("Name is required"),
            Email: Yup.string().required("Email is required"),
            Brief_Bio_Description: Yup.string().required("Bio is required"),
            Contact_Number: Yup.string().required("Contact Number is required"),
            Bank_AC_Number: Yup.string().required("Account Number is required"),
            Name_As_Per_Account: Yup.string().required("Name is required"),
            Bank_Name: Yup.string().required("Bank Name is required"),
            Branch: Yup.string().required("Branch Name is required"),
            IBAN_Number: Yup.string().required("IBAN number is required"),
        }),
        onSubmit: (data) => {
            if (categoryOptions?.length === 0) {
                setCategoryError("please select the content type");
                return;
            }
            const formData = new FormData();
            formData.append("Name", data?.Name);
            formData.append("Email", data?.Email);
            formData.append("Brief_Bio_Description", data?.Brief_Bio_Description);
            formData.append("Contact_Number", data?.Contact_Number);
            formData.append("Country", JSON.stringify(countryOptions?.value));
            var category = [];
            categoryOptions.map(val =>
                category = [...category, val.value]);
            formData.append("Category", JSON.stringify(category));
            formData.append("Bank_AC_Number", data?.Bank_AC_Number);
            formData.append("Name_As_Per_Account", data?.Name_As_Per_Account);
            formData.append("Bank_Name", data?.Bank_Name);
            formData.append("Branch", data?.Branch);
            formData.append("IBAN_Number", data?.IBAN_Number);
            let Update_By = {
                Name: creatorTokenResponse?.result?.Name,
                Email: creatorTokenResponse?.result?.Email,
                Type_Of_User: creatorTokenResponse?.result?.Type_Of_User
            }
            formData.append("Updated_By", JSON.stringify(Update_By))
            if (selectedFile) {
                formData.append("Profile_Image", selectedFile)
                dispatch({
                    type: ContentCreatorActions.UPDATE_CONTENT_CREATOR,
                    payload: { id: creatorTokenResponse?.result?._id, data: formData }
                });
            } else {
                dispatch({
                    type: ContentCreatorActions.UPDATE_CONTENT_CREATOR,
                    payload: { id: creatorTokenResponse?.result?._id, data: formData }
                });
            }
            // for (var pair of formData.entries()) { //to console formdata
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
        }
    });

    // Category
    useEffect(() => {
        if (creatorTokenResponse?.result?.Category) {
            const defaultCategoryOption = creatorTokenResponse?.result?.Category.map(cat => {
                return Category.find(c => c.value === cat);
            }).filter(Boolean);
            setCategoryOptions(defaultCategoryOption)
        }
    }, [creatorTokenResponse?.result])

    const onSelectCategory = (selectedCategory) => {
        setCategoryOptions(selectedCategory);
        setCategoryError("");
    };

    const onRemoveCategory = (selectedCategory) => {
        setCategoryOptions(selectedCategory);
    };

    // Country List
    useEffect(() => {
        if (onlyCountryList?.result) {
            const countryList = onlyCountryList.result.map(val => ({
                value: val.Country_Name,
                label: val.Country_Name,
            }));
            setCountryArray(countryList);
            if (creatorTokenResponse?.result?.Country?.[0]) {
                const defaultCountryOption = {
                    value: creatorTokenResponse.result.Country[0],
                    label: creatorTokenResponse.result.Country[0],
                };
                setCountryOptions(defaultCountryOption);
            } else if (countryList.length > 0) {
                setCountryOptions(countryList[0]);
            }
        }
    }, [onlyCountryList, creatorTokenResponse]);

    const handleSelectChange = selectedOption => {
        setCountryOptions(selectedOption)
    };

    useEffect(() => {
        if (creatorTokenResponse) {
            setProfileImage(creatorTokenResponse?.result?.Profile_Image);
        }
    }, [creatorTokenResponse?.result])

    const fileInputRef = useRef(null);

    const handleFileSelect = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            let result = FileSizeValidation(event.target.files);
            if (result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
                fileInputRef.current.value = null
            } else {
                if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
                    result?.FinalImages[0]?.type === 'image/png') {
                    const imageUrl = URL.createObjectURL(selectedFile);
                    setSelectedImage(imageUrl);
                    setSelectedFile(selectedFile);
                } else {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { show: true, status: "failed", message: "Unsupported File Format" }
                    })
                    fileInputRef.current.value = null
                }
            }
        }

    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title="Profile"
                        // titleLink="/admin"
                        breadcrumbItem="details"
                    />
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div onClick={handleFileSelect}>
                                                {selectedImage ? (
                                                    <img
                                                        src={selectedImage}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                ) : (
                                                    <img
                                                        src={profileImage ?? profileicon}
                                                        alt=""
                                                        className="avatar-md rounded-circle img-thumbnail"
                                                    />
                                                )}

                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                    accept="image/jpg,image/jpeg,image/png"
                                                />
                                                <img className="uploadProfile" src={uploadImage} alt="upload-profile" />
                                                {/* <i className="mdi mdi-arrow-up-bold-circle" ></i> */}
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted profileAlignment">
                                                    <h5 className="proName">{validation?.values?.Name}</h5>
                                                    <p className="mb-1">{validation?.values?.Email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <span><small className="text-success"><i><span className="text-danger">*</span>Support's .jpeg, .jpg, .png</i></small></span>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <CardTitle className="card-title mb-4">Profile Details</CardTitle>
                                <Row>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Name</Label>
                                            <Input
                                                name="Name"
                                                className="form-control"
                                                placeholder="Enter Name"
                                                type="text"
                                                value={validation?.values?.Name}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Name && validation?.errors?.Name ? true : false}
                                            />
                                            {validation?.touched?.Name && validation?.errors?.Name ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Name}</FormFeedback> :
                                                null
                                            }
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Email</Label>
                                            <Input
                                                name="Email"
                                                className="form-control"
                                                placeholder="Enter Email"
                                                type="text"
                                                value={validation?.values?.Email}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Email && validation?.errors?.Email ? true : false}
                                                disabled={true}
                                            />
                                            {validation?.touched?.Email && validation?.errors?.Email ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Email}</FormFeedback> :
                                                null}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">bio description</Label>
                                            <Input
                                                name="Brief_Bio_Description"
                                                className="form-control"
                                                placeholder="Enter bio description"
                                                type="textarea"
                                                rows={5}
                                                value={validation?.values?.Brief_Bio_Description}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Brief_Bio_Description && validation?.errors?.Brief_Bio_Description ? true : false}
                                            />
                                            {validation?.touched?.Brief_Bio_Description && validation?.errors?.Brief_Bio_Description ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Brief_Bio_Description}</FormFeedback> :
                                                null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Contact Number</Label>
                                            <Input
                                                name="Contact_Number"
                                                className="form-control"
                                                placeholder="Enter Contact Number"
                                                type="text"
                                                value={validation?.values?.Contact_Number}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Contact_Number && validation?.errors?.Contact_Number ? true : false}
                                            />
                                            {validation?.touched?.Contact_Number && validation?.errors?.Contact_Number ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Contact_Number}</FormFeedback> :
                                                null}
                                        </div>
                                    </Col>

                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Country</Label>
                                            <Select
                                                placeholder="Select a country"
                                                options={countryArray}
                                                value={countryOptions || []}
                                                onChange={handleSelectChange}
                                                onBlur={validation.handleBlur}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Type of content</Label>
                                            <Multiselect
                                                options={Category}
                                                displayValue="name"
                                                onSelect={onSelectCategory}
                                                onRemove={onRemoveCategory}
                                                selectedValues={categoryOptions}
                                                value={categoryOptions || []}
                                            />
                                            <div>
                                                <div><span className="market-select">selected values:</span> {categoryOptions?.map((option) => option.name).join(', ')}</div>
                                            </div>
                                            {categoryError && <div style={{ color: "red" }}>{categoryError}</div>}
                                        </div>
                                    </Col>


                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Bank Account Number</Label>
                                            <Input
                                                name="Bank_AC_Number"
                                                className="form-control"
                                                placeholder="Enter Account Number"
                                                type="text"
                                                value={validation?.values?.Bank_AC_Number}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Bank_AC_Number && validation?.errors?.Bank_AC_Number ? true : false}
                                            />
                                            {validation?.touched?.Bank_AC_Number && validation?.errors?.Bank_AC_Number ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Bank_AC_Number}</FormFeedback> :
                                                null}
                                        </div>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Name As Per Account</Label>

                                            <Input
                                                name="Name_As_Per_Account"
                                                className="form-control"
                                                placeholder="Enter Name As Per Account"
                                                type="text"
                                                value={validation?.values?.Name_As_Per_Account}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Name_As_Per_Account && validation?.errors?.Name_As_Per_Account ? true : false}
                                            />
                                            {validation?.touched?.Name_As_Per_Account && validation?.errors?.Name_As_Per_Account ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Name_As_Per_Account}</FormFeedback> :
                                                null}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Bank Name</Label>

                                            <Input
                                                name="Bank_Name"
                                                className="form-control"
                                                placeholder="Enter Bank Name"
                                                type="text"
                                                value={validation?.values?.Bank_Name}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Bank_Name && validation?.errors?.Bank_Name ? true : false}
                                            />
                                            {validation?.touched?.Bank_Name && validation?.errors?.Bank_Name ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Bank_Name}</FormFeedback> :
                                                null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Branch</Label>

                                            <Input
                                                name="Branch"
                                                className="form-control"
                                                placeholder="Enter Branch"
                                                type="text"
                                                value={validation?.values?.Branch}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.Branch && validation?.errors?.Branch ? true : false}
                                            />
                                            {validation?.touched?.Branch && validation?.errors?.Branch ?
                                                <FormFeedback type='invalid'>{validation?.errors?.Branch}</FormFeedback> :
                                                null}
                                        </div>
                                    </Col>

                                    <Col md="6">
                                        <div className="form-group mb-3">
                                            <Label className="form-label ibanclass">IBAN number</Label>

                                            <Input
                                                name="IBAN_Number"
                                                className="form-control"
                                                placeholder="Enter IBAN number"
                                                type="text"
                                                value={validation?.values?.IBAN_Number}
                                                onChange={validation?.handleChange}
                                                onBlur={validation?.handleBlur}
                                                invalid={validation?.touched?.IBAN_Number && validation?.errors?.IBAN_Number ? true : false}
                                            />
                                            {validation?.touched?.IBAN_Number && validation?.errors?.IBAN_Number ?
                                                <FormFeedback type='invalid'>{validation?.errors?.IBAN_Number}</FormFeedback> :
                                                null}
                                        </div>


                                    </Col>

                                </Row>


                                <Button
                                    className="me-3"
                                    type="submit"
                                    color="primary"
                                >
                                    {buttonLoader ? <Spinner color="light" size='sm' /> : "Update"}
                                </Button>

                            </CardBody>
                        </Card>
                    </Form>
                </Container>
            </div>
        </React.Fragment >

    )
};

export default UserProfile;
