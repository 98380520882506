import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const ContactUs = () => {
  return (
    <>
      <Container>
        <section className="contact_us">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="contact_inner">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-left">
                        <h4 className="web_fonts contactinfo contact_field cursor">
                          contact
                        </h4>
                        <h4 className="we-want color-black">we want to hear from you.</h4>
                        <p>please choose from the following:</p>
                        <div className="left-conteactpage">
                          do you need help regarding your subscription?
                        </div>

                        <a href="mailto:queries@allendreyfus.com" className="contact-page-email-link" >
                          subscriptions@allendreyfus.com
                        </a>
                        <div className="left-conteactpage">
                          Do you need to reach a journalist or ask a question
                          about content?
                        </div>
                        <a href="mailto:queries@allendreyfus.com" className="contact-page-email-link">
                          queries@allendreyfus.com
                        </a>


                        <h4 className="web_fonts color-black allen cursor" >
                          Allendreyfus
                        </h4>
                        <h5 className="left-conteactpage">
                          {" "}
                          <i className="fas fa-map-marked-alt"></i>   35/37 ludgate
                          hill,london, ec4m 7jn
                        </h5>

                        <h5 className="left-conteactpage">
                          {" "}
                          <i className="fas fa-headset"></i> <a href="tel:+44 (0) 330 828
                          5058">+44 (0) 330 828
                          5058</a>
                        </h5>
                        <h5 className="left-conteactpage">
                          {" "}
                          <i className="fas fa-envelope-open-text"></i>{" "}
                          <a href="mailto:info@allendreyfus.com">
                            info@allendreyfus.com
                          </a>
                        </h5>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <section className="map_sec">
                        <div className="container">
                          <div className="row">

                            <div className="map_bind">
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19852.497029682976!2d-0.09937633107377686!3d51.51323450629047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ce90e55b55%3A0x88e4181e03b0249f!2sLudgate%20Hill%2C%20London%2C%20EC4M%207JN%2C%20United%20Kingdom!5e0!3m2!1sen!2sin!4v1647136753716!5m2!1sen!2sin"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                              ></iframe>
                            </div>

                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <></>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  )
}

export default ContactUs
