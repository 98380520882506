import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Container, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import Unclock from "../../assets/webImages/verified.gif";
import Unclock from "../../assets/webImages/stripe_thank_u.png";
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

const StripeSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paymentResult } = useParams();
  //const { width, height } = useWindowSize();  // Call useWindowSize at the top level
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Extract payment result from URL parameters
    const searchParams = new URLSearchParams(location.search);
    const paymentResult = searchParams.get('paymentResult');

    // Handle payment result
    if (paymentResult === 'success') {
      // Payment successful;
      // Redirect or perform any other actions
    } else {
      // Payment failed or invalid 
      // Redirect or perform any other actions
    }

    // Optionally, you can redirect the user after handling the payment result
    // navigate('/dashboard');
  }, [location, navigate]);  // Add navigate to dependency array

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="Successpage">
      <Container className="d-flex align-items-center justify-content-center">
        <Confetti
          width={width}
          height={height}
          wind={0.01}
          numberOfPieces={350}  // Adjust the number of confetti pieces
          initialVelocityY={10}  // Adjust the initial velocity in the Y direction
          recycle={false}  // Make the confetti disappear after falling
          angle={90}  // Spray upwards
          //from bottom to top
          // gravity={-0.1} // Negative gravity to make confetti rise
          // confettiSource={{ x: 0, y: height, w: width, h: 0 }} // Start from the bottom
          //pop-out effect
          // gravity={-0.3} // Negative gravity to make confetti rise
          // confettiSource={{ x: width / 2, y: height, w: 10, h: 10 }} // Pop out from the center bottom
        />
        <Row>
          <div className="center-page">
            <img
              src={Unclock}
              className="verified-sucess"
              alt="Verification Success"
              draggable="false"
            />
            <div className="thanks-div">
              <h1 className="thanks-text">you have been subscribed</h1>
            </div>

           {/*  <div className="web_fonts">
              <p className="web_fonts">
                You should receive a confirmation email soon
              </p>
            </div> */}

            <div className="text-center go-home-div">
              <Button className="web-fonts go-home" onClick={() => navigate("/")}>Back to  home</Button>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default StripeSuccess;
