import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Row,
  Col,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import profileicon from "../../assets/images/dashboard/user3.png"
import { userAuthActions } from "../../store/userAuth/actions"

const ProfileMenu = props => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { superAdminTokenResponse, creatorTokenResponse, adminTokenResponse } = useSelector(
    state => state.userAuthReducer
  )

  const [menu, setMenu] = useState(false)
  const [showLogoutPopup, setShowLogoutPopup] = useState(false)

  const handleLogoutPopup = e => {
    // e.preventDefault();
    setShowLogoutPopup(true)
  }

  const handleClose = e => {
    setShowLogoutPopup(false)
  }

  const handleLogout = e => {
    // e.preventDefault();
    if (superAdminTokenResponse?.result?.Type_Of_User === "Super Admin") {
      localStorage.removeItem("GFX_TOKEN")
      dispatch({
        type: userAuthActions.UPDATE_SUPER_ADMIN_AUTH_DETAILS,
        payload: { isAuthenticated: false, route: "/login" },
      })

      localStorage.setItem('SuperAdminLogout', Date.now()); // For synchronized logout

      setShowLogoutPopup(false)
      navigate("/login")
    } else if (
      creatorTokenResponse?.result?.Type_Of_User === "Content Creator"
    ) {
      localStorage.removeItem("GFX_TOKEN2")
      dispatch({
        type: userAuthActions.UPDATE_CREATOR_AUTH_DETAILS,
        payload: { isAuthenticated: false, route: "/login" },
      })

      localStorage.setItem('CreatorLogout', Date.now()); //For synchronized logout

      setShowLogoutPopup(false)
      navigate("/login")
    } else if (adminTokenResponse?.result?.Type_Of_User === "Admin") {
      localStorage.removeItem("GFX_TOKEN3")
      dispatch({
        type: userAuthActions.UPDATE_ADMIN_AUTH_DETAILS,
        payload: { isAuthenticated: false, route: "/login" }
      })

      localStorage.setItem('AdminLogout', Date.now());

      setShowLogoutPopup(false)
      navigate("/login")
    }
  }

  const handleLockScreen = e => {
    e.preventDefault()
    localStorage.setItem("GFX_Dashboard", "false")
    navigate("/auth-lock-screen")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item dash-board-button"
          id="page-header-user-dropdown"
          tag="button"
        >
          {superAdminTokenResponse?.result?.Type_Of_User === "Super Admin" && (
            <img
              className="rounded-circle header-profile-user"
              src={
                superAdminTokenResponse?.result?.Profile_Image ?? profileicon
              }
              alt="Header Avatar"
            />
          )}
          {adminTokenResponse?.result?.Type_Of_User === "Admin" && (
            <img className="rounded-circle header-profile-user"
              src={adminTokenResponse?.result?.Profile_Image ?? profileicon}
              alt="Header Avatar" />
          )}
          {creatorTokenResponse?.result?.Type_Of_User === "Content Creator" && (
            <img
              className="rounded-circle header-profile-user"
              src={creatorTokenResponse?.result?.Profile_Image ?? profileicon}
              alt="Header Avatar"
            />
          )}

          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{tokenResponse?.result?.Name}</span> */}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {superAdminTokenResponse?.result?.Type_Of_User === "Super Admin" && (
            <DropdownItem tag="a" href="/admin-profile">
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </DropdownItem>
          )}
          {adminTokenResponse?.result?.Type_Of_User === "Admin" && (
            <DropdownItem tag="a" href="/admin-profile-page">
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </DropdownItem>
          )}
          {creatorTokenResponse?.result?.Type_Of_User === "Content Creator" && (
            <DropdownItem tag="a" href="/creator-profile">
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </DropdownItem>
          )}
          <DropdownItem tag="a" onClick={handleLockScreen}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
          <DropdownItem tag="a" href="/ChangePassword">
            <i
              className="bx bx-shield-quarter font-size-16 align-middle me-1"
            />
            {props.t("Change Password")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="#" onClick={handleLogoutPopup} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={showLogoutPopup} toggle={handleClose}>
        <ModalHeader toggle={handleClose} tag="h4">
          Confirmation
        </ModalHeader>
        <ModalBody>
          <Form className="padding-model">
            <Row>
              <Col md={12} className="mb-4 mt-3 text-center">
                <h5>Are you sure you want to logout?</h5>
              </Col>
              <hr />
              <Col md={12}>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-primary save-customer me-2"
                    onClick={handleLogout}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger save-customer"
                    onClick={handleClose}
                  >
                    No
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProfileMenu)
