import React, { useState, useEffect } from "react"
import { Modal, Container, Row, Col, Button } from "react-bootstrap"
// import { ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { FaPoundSign, FaUserCircle } from "react-icons/fa"
import { MdAttachEmail } from "react-icons/md"
import Logoimage from "../../assets/webImages/png-logo-color.webp"
import Corpuser from "../../assets/webImages/user.svg"
import CorporateModal from "./CorporateModal"
import SearchModal from "./Searchmodal"
import { CiSearch } from "react-icons/ci"
import { BiSolidPhoneCall } from "react-icons/bi"
import { BsCheckCircle } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { userAuthActions } from "store/userAuth/actions"
import { useDispatch } from "react-redux"
import LogoutModal from "webComponents/modals/LogoutModal"
import NavDropdown from "react-bootstrap/NavDropdown"
import TextBgColors, { getColorByLetter } from "../Common/TextBgColors"
import actions from "../../store/adminSubscriptionPlans/actions"
import { loadStripe } from "@stripe/stripe-js"
import { stripeActions } from "../../store/stripePayment/actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSterlingSign } from "@fortawesome/free-solid-svg-icons"
import { IoCall } from "react-icons/io5"
import PoundSign from "../../assets/webImages/pound-mark-3-svgrepo-com.svg"
import SubLeftBanner from "../../assets/webImages/SubLeftBanner.webp"
import { MdOutlineQuestionMark } from "react-icons/md"
import { Spinner } from "reactstrap"
import { STRIPE_LIVE_PUBLISHABLE_KEY, STRIPE_TEST_PUBLISHABLE_KEY } from '../../utils/constant'

//test publishable key
// const stripePromise = loadStripe(STRIPE_TEST_PUBLISHABLE_KEY)

// live publishable key
const stripePromise = loadStripe(STRIPE_LIVE_PUBLISHABLE_KEY)

const SubscriptionModal = ({ show, handleClose }) => {
  const [showContactModal, setShowContactModal] = useState(false)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [monthlyPlan, setMonthlyPlan] = useState("")
  const [EnableMonthlyPlan, setEnableMonthlyPlan] = useState("No")
  const [annualPlan, setAnnualPlan] = useState("")
  const [monthStripeId, setMonthStripeId] = useState("")
  const [annualStripeId, setAnnualStripeId] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { buttonLoader } = useSelector(state => state.commonReducer);
  const { getSessionId } = useSelector(state => state.subscriptionPlanReducer)

  const { subscriptionPlans } = useSelector(
    state => state.subscriptionPlanReducer
  )
  const { ProfileUserInfo } = useSelector(state => state.WebHomePageReducer)
  const [profileImage, setProfileImage] = useState("")

  const handleBookmarks = () => {
    navigate("/Save")
  }
  useEffect(() => {
    dispatch({ type: actions.GET_SUBSCRIPTION_PLANS_LIST })
  }, [])

  useEffect(() => {
    setProfileImage(ProfileUserInfo?.result?.Profile_Image || "")
  }, [ProfileUserInfo])

  useEffect(() => {
    if (subscriptionPlans?.statusCode === 200 && subscriptionPlans?.result !== null) {
      setMonthlyPlan(subscriptionPlans?.result[0]?.Subscription_Amount)
      setMonthStripeId(subscriptionPlans?.result[0]?.Stripe_Plan_Id)
      setEnableMonthlyPlan(subscriptionPlans?.result[0]?.Enabled)
      setAnnualPlan(subscriptionPlans?.result[1]?.Subscription_Amount)
      setAnnualStripeId(subscriptionPlans?.result[1]?.Stripe_Plan_Id)
    }
  }, [subscriptionPlans])

  const handleContactClick = () => {
    setShowContactModal(true)
  }

  const handleSearchClick = () => {
    setShowSearchModal(true)
  }

  const handleCloseSearchModal = () => {
    setShowSearchModal(false)
  }

  const handleLoginClick = () => {
    navigate("/web/login")
  }

  const handleLogout = () => {
    setShowLogoutModal(true)
  }

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false)
  }

  const handleLogoutConfirmed = () => {
    if (tokenResponse?.result?.Type_Of_User === "User") {
      localStorage.removeItem("WEB_TOKEN")
      dispatch({
        type: userAuthActions.UPDATE_AUTH_DETAILS,
        payload: { isAuthenticated: false },
      })
    }
    setShowLogoutModal(false)
  }

  const [freePlanLoader, setFreePlanLoader] = useState(false);
  const [monthlyPlanLoader, setMonthlyPlanLoader] = useState(false);
  const [yearlyPlanLoader, setYearlyPlanLoader] = useState(false);

  // stripe checkout
  const handleStripeClick = async (tokenResponse, planId, subscriptionType, planType) => {
    if (tokenResponse === null || tokenResponse === undefined) {
      // setIsOpen(true)
      navigate("/web/login")
    } else {
      switch (planType) {
        case "free":
          setFreePlanLoader(true);
          break;
        case "monthly":
          setMonthlyPlanLoader(true);
          break;
        case "yearly":
          setYearlyPlanLoader(true);
        default:
          break;
      }
      dispatch({
        type: actions.GET_CHECKOUT_SESSION_ID,
        payload: {
          planId: planId,
          email: tokenResponse?.result?.Email,
          subscriptionType: subscriptionType,
        },
      })
    }
  }

  // stripe session
  useEffect(() => {
    const redirectCheckout = async () => {
      const stripe = await stripePromise
      if (getSessionId?.id) {
        // const { error } = await stripe.redirectToCheckout({
        //   sessionId: getSessionId?.id,
        // })
        // if (error) {
        //   console.error("Error:", error)
        // }
        setButtonLoad(false);
      }
    }
    redirectCheckout()
  }, [getSessionId?.id, stripePromise])

  const hideModal = () => {
    setIsOpen(false)
    navigate("/web/login")
  }

  const hideClose = () => {
    setIsOpen(false)
  }

  const UserDisplay = ({ firstName }) => {
    const bgColor = getColorByLetter(firstName?.charAt(0))

    return (
      <div className="login-link">
        <NavDropdown
          title={
            <div>
              <span className="login-text color-black">{firstName}</span>
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="rounded-background-profile-img"
                  style={{ background: bgColor }}
                />
              ) : (
                <div
                  className="rounded-background"
                  style={{ background: bgColor }}
                >
                  {firstName?.charAt(0)?.toUpperCase()}
                </div>
              )}
            </div>
          }
          id="basic-nav-dropdown"
          className="profile-dropdown color-black"
        >
          <NavDropdown.Item href="/web/Profile">Profile</NavDropdown.Item>
          <NavDropdown.Item className="bookmark-nav" onClick={handleBookmarks}>
            BookMarks
          </NavDropdown.Item>
          <li>
            <hr className="dropdown-divider" />
          </li>

          <NavDropdown.Item href="#" onClick={handleLogout}>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    )
  }

  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"
  const stripeCutomerId = tokenResponse?.result?.Stripe_Customer_Id

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        fullscreen
        className="sub-container"
      >
        <Modal.Header className="sub-model-head" closeButton></Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="align-items-center">
              <Col xs="auto">
                <a href="/">
                  <img src={Logoimage} alt="Logo" className="logo-ad" />
                </a>
              </Col>
              <Col xs="auto" className="ms-auto">
                <div className="d-flex align-items-center">
                  <CiSearch
                    className="search-icon"
                    onClick={handleSearchClick}
                  />
                  {tokenResponse ? (
                    // User is logged in
                    <UserDisplay
                      firstName={tokenResponse?.result?.First_Name}
                    />
                  ) : (
                    // User is not logged in
                    <div className="login-link" onClick={handleLoginClick}>
                      <FaUserCircle className="login-icon color-black" />
                      <span className="login-text color-black d-none d-sm-inline">log in</span>
                    </div>
                  )}
                </div>
                {showSearchModal && (
                  <SearchModal
                    show={showSearchModal}
                    handleClose={handleCloseSearchModal}
                  />
                )}
              </Col>
              <LogoutModal
                show={showLogoutModal}
                handleClose={handleCloseLogoutModal}
                handleLogoutConfirmed={handleLogoutConfirmed}
              />
            </Row>
          </Container>

          <div className="sub-model-body-heading">
            <h5 className="text-center web_fonts color-black">
              choose the plan that's right for you
            </h5>
          </div>
          <div className="sub-model-body-subheading">
            <h5 className="text-center sub-plan web_fonts color-black">
              select your subscription plan
            </h5>
          </div>
          <Container>
            {isSubscribed || isTrialUsed || stripeCutomerId ? (
              <Row className="justify-content-center bodyrow">
                {/* <Col xs={12} md={3} className="custom-background> */}
                <Col xs={12} md={3} className="custom-background d-flex justify-content-center align-items-center">
                  <img src={SubLeftBanner} alt="sub-left-banner" className="sub-left-banner" />

                  {/* <div className="d-flex">
                    <h4 className="web_fonts color-black">why</h4>
                    <img src={Logoimage} alt="logo" className="sub-logo" />
                  </div>

                  <div className="subscription-1">
                    <p className="sub-text-in">
                      <BsCheckCircle /> insightful perspectives, empowering
                      decisions
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> expert analysis, global perspectives
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> timely and comprehensive coverage
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> customized content and tailored
                      recommendations
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> transparency is important to us. Explore
                      our Privacy and Cookie Policies for details.
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> to learn more about our data practices,
                      please visit our Privacy and Cookie Policies.
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> learn how we treat your data by visiting
                      our Privacy and Cookie Policies.
                    </p>
                  </div>
                  <div className="subscription-1 color-black">
                    <h6 className="web_fonts">Customer service</h6>
                    <p>
                      <BiSolidPhoneCall className=" BiSolidPhoneCall" />{" "}
                      <span style={{ paddingLeft: "4px", fontSize: "12px" }}>
                        {" "}
                        <a href="tel:+4403308285058">+44 (0) 330 828 5058</a>{" "}
                      </span>
                    </p>
                    <p>
                      <MdAttachEmail className="MdAttachEmail" />
                      <span style={{ paddingLeft: "4px", fontSize: "12px" }}>
                        <a className="email-link" href="mailto:info@allendreyfus.com">
                          info@allendreyfus.com
                        </a>
                      </span>
                    </p>
                  </div> */}
                </Col>

                <Col xs={12} md={3} className="text-center sub-month">
                  <h2 className="color-black subscription-page-heading">
                    Monthly
                  </h2>
                  {/* <div className="d-flex month-FaPoundSign">
                              <h5 className="color-black align">
                                <FaPoundSign />
                              </h5>
                              <h4 className="color-black">{monthlyPlan}</h4>
                            </div> */}

                  <p>
                    {/* <FontAwesomeIcon
                                  icon={faSterlingSign}
                                  className="month-plan-dollar"
                                /> */}
                    <img src={PoundSign} className="pound-sign" />
                    <span className="month-plan-sub">{monthlyPlan} </span>
                  </p>

                  <h3 className="color-black"> for 4 weeks</h3>
                  <h4 className="color-black">monthly renewing</h4>
                  <p>renews automatically</p>
                  <div className="modal-sub-button">
                    <Button
                      className="btn btn-light button-5 sub-button  custom-nav-link"
                      onClick={() =>
                        handleStripeClick(
                          tokenResponse,
                          monthStripeId,
                          "paid",
                          "monthly"
                        )
                      }
                      disabled={monthlyPlanLoader} // Disable the button when the loader state is true
                    >
                      {monthlyPlanLoader ? <Spinner color="black" size="sm" /> : 'Select'}
                    </Button>
                  </div>
                </Col>

                {/* //need to uncomment this subscription when live . it is temporarly commented */}
                {/* <Col
                  xs={12}
                  md={3}
                  className="text-center custom-shape border-container"
                >
                  <div className="yearly">
                    <h2 className="color-black subscription-page-heading">
                      Annual
                    </h2>
                    {/* <div className="d-flex annual-FaPoundSign">
                                <h5 className="color-black align">
                                  <FaPoundSign />
                                </h5>
                                <h4 className="color-black">{annualPlan}</h4>
                              </div> */}
                {/* <p> */}
                {/* <FontAwesomeIcon
                                    icon={faSterlingSign}
                                    className="month-plan-dollar"
                                  /> */}
                {/* <img src={PoundSign} className="pound-sign" />
                      <span className="month-plan-sub">{annualPlan} </span>
                    </p>
                    <h4 className="color-black subscription-page-heading">
                      for one year
                    </h4>
                    <h5 style={{ color: "#238fce" }} className="web_fonts">
                      save 2 months
                    </h5>
                    <p className="color-black">you can cancel anytime</p>
                    <Button
                      variant="primary"
                      className="subscribe-button custom-nav-link"
                      onClick={() =>
                        handleStripeClick(
                          tokenResponse,
                          annualStripeId,
                          "paid",
                          "yearly"
                        )
                      } disabled={yearlyPlanLoader} // Disable the button when buttonLoader is true
                    >
                      {yearlyPlanLoader ? <Spinner color="black" size="sm" /> : 'Select'}
                    </Button>
                  </div>
                </Col> */}
                {/* //need to uncomment above subscription when live . it is temporarly commented */}

                <Col xs={12} md={3} className="text-center sub-month">
                  <img src={Corpuser} alt="corprate user" />
                  <h3 className="sub-month-text web_fonts  color-black subscription-page-heading">
                    corporate subscription
                  </h3>
                  <div className="modal-sub-button web_fonts">
                    <Button
                      className="btn btn-light button-5 sub-button custom-nav-link"
                      onClick={handleContactClick}
                    >
                      Contact Us
                    </Button>
                  </div>
                </Col>
                {showContactModal && (
                  <CorporateModal
                    show={showContactModal}
                    handleClose={() => setShowContactModal(false)}
                  />
                )}
              </Row>
            ) : (
              <Row className="bodyrow">
                <Col xs={12} md={4} lg={4} className="custom-background d-flex justify-content-center align-items-center">
                  <img src={SubLeftBanner} alt="sub-left-banner" className="sub-left-banner" />
                  {/* <div className="d-flex">
                    <h4 className="web_fonts color-black">why</h4>
                    <img src={Logoimage} alt="logo" className="sub-logo" />
                    <h4 className="question-mark color-black">
                      <MdOutlineQuestionMark />
                    </h4>
                  </div>

                  <div className="subscription-1">
                    <p className="sub-text-in">
                      <BsCheckCircle /> insightful perspectives, empowering
                      decisions
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> expert analysis, global perspectives
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> timely and comprehensive coverage
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> customized content and tailored
                      recommendations
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> transparency is important to us. Explore
                      our Privacy and Cookie Policies for details.
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> to learn more about our data practices,
                      please visit our Privacy and Cookie Policies.
                    </p>

                    <p className="sub-text-in">
                      <BsCheckCircle /> learn how we treat your data by visiting
                      our Privacy and Cookie Policies.
                    </p>
                  </div>
                  <div className="subscription-1 color-black">
                    <h6 className="web_fonts">Customer service</h6>
                    <p>
                      <BiSolidPhoneCall className=" BiSolidPhoneCall" />{" "}
                      <span style={{ paddingLeft: "4px", fontSize: "12px" }}>
                        {" "}
                        <a href="tel:+4403308285058">+44 (0) 330 828 5058</a>{" "}
                      </span>
                    </p>
                    <p>
                      <MdAttachEmail className="MdAttachEmail" />
                      <span style={{ paddingLeft: "4px", fontSize: "12px" }}>
                        <a className="email-link" href="mailto:info@allendreyfus.com">
                          info@allendreyfus.com
                        </a>
                      </span>
                    </p>
                  </div> */}
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={2}
                  className="text-center sub-month sub-freetrail-border"
                >
                  <h2 className="color-black subscription-page-heading">
                    Free-Trial
                  </h2>
                  <h4 className="text-lower sub-free-trial">
                    All access introductory trial
                  </h4>
                  <h3 className="color-black"> for 30 days</h3>

                  <h5 className="color-black">then £49.99 per month</h5>
                  <p className="color-black">you can cancel anytime</p>

                  <Button className="btn btn-light button-5 sub-button custom-nav-link"
                    onClick={() =>
                      handleStripeClick(
                        tokenResponse,
                        "price_1OuXntAXp7wlJ7cTP0dFYc7L",
                        "freeTrial",
                        "free"
                      )
                    }
                    disabled={freePlanLoader} // Disable the button when the loader state is true
                  >
                    {freePlanLoader ? <Spinner color="black" size="sm" /> : 'Select'}
                  </Button>
                </Col>
                {(EnableMonthlyPlan && EnableMonthlyPlan === 'Yes') &&
                  <Col
                    xs={12}
                    md={4}
                    lg={2}
                    className="text-center sub-month sub-border"
                  >
                    <h2 className="color-black subscription-page-heading">
                      Monthly
                    </h2>
                    {/* <div className="d-flex month-FaPoundSign">
                  <h5 className="color-black align">
                    <FaPoundSign />
                  </h5>
                  <h4 className="color-black">{monthlyPlan}</h4>
                </div> */}

                    <p>
                      {/* <FontAwesomeIcon
                      icon={faSterlingSign}
                      className="month-plan-dollar"
                    /> */}
                      {/* <img src={PoundSign} className="pound-sign" /> */}
                      <span className="month-plan-sub"> £{monthlyPlan} </span>
                    </p>

                    <h3 className="color-black subscription-page-heading">
                      {" "}
                      for 4 weeks
                    </h3>
                    <h4 className="color-black">monthly renewing</h4>
                    <p>renews automatically</p>
                    <div className="modal-sub-button">
                      <Button
                        className="btn btn-light button-5 sub-button  custom-nav-link"
                        onClick={() =>
                          handleStripeClick(
                            tokenResponse,
                            monthStripeId,
                            "paid",
                            "monthly"
                          )
                        }
                        disabled={monthlyPlanLoader} // Disable the button when the loader state is true
                      >
                        {monthlyPlanLoader ? <Spinner color="black" size="sm" /> : 'Select'}
                      </Button>
                    </div>
                  </Col>
                }
                {/* need to uncomment this subscription when live . it is temporarly commented */}

                {/* <Col
                  xs={12}
                  md={4}
                  lg={2}
                  className="text-center custom-shape border-container"
                >
                  <div className="yearly">
                    <h2 className="color-black subscription-page-heading">
                      Annual
                    </h2>
                    {/* <div className="d-flex annual-FaPoundSign">
                    <h5 className="color-black align">
                      <FaPoundSign />
                    </h5>
                    <h4 className="color-black">{annualPlan}</h4>
                  </div> */}
                {/* <p> */}
                {/* <FontAwesomeIcon
                        icon={faSterlingSign}
                        className="month-plan-dollar"
                      /> */}
                {/* <img src={PoundSign} className="pound-sign" /> */}
                {/* <span className="month-plan-sub"> £{annualPlan} </span>
                    </p>
                    <h4 className="color-black subscription-page-heading">
                      for one year
                    </h4>
                    <h5 style={{ color: "#238fce" }} className="web_fonts">
                      save 2 months
                    </h5>
                    <p className="color-black">you can cancel anytime</p>
                    <Button
                      variant="primary"
                      className="subscribe-button custom-nav-link"
                      onClick={() =>
                        handleStripeClick(
                          tokenResponse,
                          annualStripeId,
                          "paid",
                          "yearly"
                        )
                      } disabled={yearlyPlanLoader} // Disable the button when buttonLoader is true
                    >
                      {yearlyPlanLoader ? <Spinner color="black" size="sm" /> : 'Select'}
                    </Button>
                  </div>
                </Col> */}
                {/* //need to uncomment above subscription when live . it is temporarly commented */}

                <Col
                  xs={12}
                  md={4}
                  lg={2}
                  className="text-center sub-month subscription-page-heading"
                >
                  <img src={Corpuser} alt="corprate user" />
                  <h3 className="sub-month-text web_fonts  color-black">
                    corporate subscription
                  </h3>

                  <div className="modal-sub-button-corporate web_fonts">
                    <Button
                      className="btn btn-light button-5 sub-button custom-nav-link"
                      onClick={handleContactClick}
                    >
                      Contact Us
                    </Button>
                  </div>
                </Col>

                {showContactModal && (
                  <CorporateModal
                    show={showContactModal}
                    handleClose={() => setShowContactModal(false)}
                  />
                )}
              </Row>
            )}
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        show={isOpen}
        onHide={hideModal}
        style={{
          display: isOpen ? "block" : "none",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#38b6ff85",
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Alert</h5>
            <button
              type="button"
              className="close sub-alert"
              onClick={hideClose}
            ></button>
          </div>
          <div
            className="modal-body"
            style={{
              textAlign: "center",
              fontWeight: "bold", // Make the content body bold
              lineHeight: "3", // Adjust line height
            }}
          >
            <p style={{ marginTop: "10px" }}>
              Please log in before you subscribe
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="login-alert" onClick={hideModal}>
              log in
            </button>
            <button type="button" className="close-alert" onClick={hideClose}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SubscriptionModal
