import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AdminPageLoader from "../../common/adminPageLoader";
import TableContainer from '../../common/TableContainer';
import Breadcrumbs from "../../common/Breadcrumb";
import { WebHomePageActions } from "../../store/webHome/actions";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from "common/CustomPagination";

export default function AdminPodcastListIndex() {
    document.title = "Podcast | Allen Dreyfus";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; 

    const { adminPageLoader } = useSelector(state => state.commonReducer);
    const { PodcastList } = useSelector(state => state.WebHomePageReducer);

    const [list, setList] = useState("");

    useEffect(() => {
        if (PodcastList?.statusCode === 200 && PodcastList?.result?.length > 0) {
            setList(PodcastList?.result)
        }
    }, [PodcastList?.statusCode === 200])

    useEffect(() => {
        dispatch({ type: WebHomePageActions.GET_WEBHOME_PODCAST })
    }, [])


    const handleAddPodcast = () => {
        navigate('/admin-add-podcast')
    }

    const columns = useMemo(
        () => [
            {
                Header: 'title',
                accessor: 'Title',
                filterable: true,
                Cell: title => {
                    return <ContentTitle {...title} />
                }
            },
            {
                Header: 'category',
                accessor: 'Category',
                filterable: true,
            },
            {
                Header: 'uploaded date',
                accessor: 'Approved_On',
                filterable: true,
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const Data = cellProps.row.original;
                                    navigate('/content-details', { state: { data: Data, type: "Approved" } });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ], []
    );

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    {adminPageLoader ?
                        <AdminPageLoader /> :
                        <Container fluid>
                            <Breadcrumbs
                                title="Podcast"
                                breadcrumbItem="List"
                            />
                            <Row>
                                <Col xs="12">
                                    <div style={{ textAlign: 'right' }}>
                                        <button onClick={handleAddPodcast} className="btn btn-primary list-add-button">add podcast</button>
                                    </div>
                                    <Card className="payouts">
                                        <CardBody>
                                            {list && list?.length > 0 ? (
                                                <React.Fragment>
                                                    <>
                                                        <TableContainer
                                                            columns={columns}
                                                            data={list || []}
                                                            isGlobalFilter={true}
                                                            isAddCustList={false}
                                                            isPagination={true} 
                                                            customPageSize={itemsPerPage}
                                                            tableClass="align-middle table-nowrap center-table"
                                                            isShowingPageLength={false}
                                                            iscustomPageSizeOptions={true}
                                                            pagination="pagination pagination-rounded justify-content-end mb-2"
                                                        />                                                       
                                                    </>
                                                </React.Fragment>
                                            ) : (
                                                <div className="no-records">No records found</div>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Container>
                    }
                </div>
            </React.Fragment>
        </>
    )
}

const ContentTitle = (cell) => {
    return (cell?.value && cell?.value?.length > 25) ? `${cell?.value?.slice(0, 25)}...` : cell?.value
}