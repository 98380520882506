import AdminEditContentIndex from "components/adminEditContentDetails/index";

import React from "react";

export default function adminEditContent () {

    return(
        <div>
            <AdminEditContentIndex />
        </div>
    )
}