import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector"
import { Modal, Container } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import actions from "../../store/adminSubscriptionPlans/actions";
import WebPageLoader from "../../common/webPageLoader"
import { STRIPE_LIVE_PUBLISHABLE_KEY, STRIPE_TEST_PUBLISHABLE_KEY } from '../../utils/constant'

const HrBanner = ({ bannerImage, bannerLink, bannerType, setLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { webPageLoader } = useSelector(state => state.commonReducer);

  //test publishable key
  // const stripePromise = loadStripe(STRIPE_TEST_PUBLISHABLE_KEY)

  // live publishable key
  const stripePromise = loadStripe(STRIPE_LIVE_PUBLISHABLE_KEY)

  const [isOpen, setIsOpen] = useState(false);

  const { tokenResponse } = useSelector(state => state.userAuthReducer);

  const { getSessionId } = useSelector(state => state.subscriptionPlanReducer);

  // stripe checkout
  const handleStripeClick = async (tokenResponse, planId, subscriptionType) => {
    if (tokenResponse === null || tokenResponse === undefined) {
      navigate("/web/login")
    } else {
      setLoading(true);
      setTimeout(() => {
        dispatch({
          type: actions.GET_CHECKOUT_SESSION_ID,
          payload: {
            planId: planId,
            email: tokenResponse?.result?.Email,
            subscriptionType: subscriptionType,
          },
        })
      }, 1000);
    }
  }

  //stripe session
  useEffect(() => {
    const redirectCheckout = async () => {
      const stripe = await stripePromise;
      if (getSessionId?.id) {
        setLoading(true); // Show loader
        // const { error } = await stripe.redirectToCheckout({
        //   sessionId: getSessionId?.id,
        // });
        // if (error) {
        //   console.error("Error:", error);
        // }
      }
    };
    redirectCheckout();
  }, [getSessionId?.id, stripePromise]);


  const hideModal = () => {
    setIsOpen(false);
    navigate("/web/login")
  };

  const hideClose = () => {
    setIsOpen(false);
  }

  return (
    <>
      <Container>
        <div className="darkblue-banner">
          {bannerType === "freeTrial" ? (
            <img
              src={bannerImage}
              alt="banner-img"
              onClick={() =>
                handleStripeClick(
                  tokenResponse,
                  "price_1OuXntAXp7wlJ7cTP0dFYc7L",
                  "freeTrial"
                )
              }
            />
          ) : (
            <img src={bannerImage} alt="banner-img" />
          )}
        </div>
      </Container>

      <Modal show={isOpen} onHide={hideModal} style={{
        display: isOpen ? 'block' : 'none',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#38b6ff85',
      }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Alert</h5>
            <button
              type="button"
              className="close sub-alert"
              onClick={hideClose}
            ></button>
          </div>
          <div className="modal-body" style={{
            textAlign: 'center',
            fontWeight: 'bold', // Make the content body bold
            lineHeight: '3', // Adjust line height
          }}>
            <p style={{ marginTop: '10px' }}>please log in before you subscribe</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="login-alert" onClick={hideModal}>log in</button>
            <button type="button" className="close-alert" onClick={hideClose}>Close</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HrBanner;
