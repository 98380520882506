import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { WebHomePageActions } from "store/webHome/actions"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import NoDataFound from "webComponents/Common/NoRecords"
import WebPageLoader from "common/webPageLoader"

const ContentByRecentTopicIndex = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { Topic } = useParams()
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 8
  const [fetchSuccess, setFetchSuccess] = useState(false)
  const { RecentTopicNews } = useSelector(state => state.WebHomePageReducer)

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = RecentTopicNews?.result?.slice(indexOfFirstItem, indexOfLastItem)

  const { ContentByRecentTopic } = useSelector(state => state.WebHomePageReducer)
  const [firstTopic, setFirstTopic] = useState("")

  useEffect(() => {
    setFirstTopic(ContentByRecentTopic?.result?.[0]?.Topic)
  }, [ContentByRecentTopic])

  const totalPages = Math.ceil((RecentTopicNews?.result?.length || 0) / itemsPerPage)

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (RecentTopicNews?.result?.length === 0) {
      setLoader(false)
      setFetchSuccess(false)
    } else {
      setLoader(false)
      setFetchSuccess(true)
    }
  }, [RecentTopicNews])

  useEffect(() => {
    dispatch({ type: WebHomePageActions.GET_WEBHOME_CONTENTBY_RECENT_TOPIC })
  }, [dispatch])

  useEffect(() => {
    const Category = "Recent Topic"
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_RECENTTOPIC_NEWS_DETAILS,
      payload: { Category, Sub_Category: Topic },
    })
  }, [Topic, dispatch])

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, { state: { contentDetail: contentDetail } })
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS,
      payload: contentDetail._id,
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const renderPageNumbers = () => {
      const pageNumbers = []
      const maxVisiblePages = 5

      for (let i = 1; i <= totalPages; i++) {
        if (
          i <= maxVisiblePages ||
          i === totalPages ||
          i === currentPage ||
          i === currentPage - 1 ||
          i === currentPage + 1
        ) {
          pageNumbers.push(
            <button
              className={`page-numbs ${currentPage === i ? "page-active" : ""}`}
              key={i}
              onClick={() => handlePageChange(i)}
              disabled={currentPage === i}
            >
              {i}
            </button>
          )
        } else if (
          i === maxVisiblePages + 1 &&
          totalPages > maxVisiblePages + 1
        ) {
          pageNumbers.push(
            <span key="ellipsis" className="ellipsis">
              ...
            </span>
          )
        }
      }
      return pageNumbers
    }

    return (
      <div className="pagination-container pagination-design">
        <button
          className="pagination-button previous-page"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;&lt;
        </button>
        {renderPageNumbers()}
        <button
          className="pagination-button next-page"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;&gt;
        </button>
      </div>
    )
  }

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  const extractFirstSentence = (content, numWords = 8) => {
    // Split the content into words
    const words = content.split(' ');

    // Take the first 'numWords' words and join them back into a string
    const firstSentence = words.slice(0, numWords).join(' ');

    return words.length > numWords ? `${firstSentence} ...` : firstSentence;
  };


  return (
    <>
      {loader ? (
        <WebPageLoader />
      ) : (
        <>
          {fetchSuccess ? (
            <>
              <Container>
                <div className="recent-heading">
                  <h2 className="web_fonts content-heading country-name">
                    {firstTopic}
                  </h2>
                  <hr className="hr-line" />
                </div>

                {currentItems && currentItems?.length > 0 ? (
                  <>
                    {currentItems?.reduce((rows, item, idx) => {
                      if (idx % 2 === 0) rows.push([]);
                      rows[rows.length - 1].push(item);
                      return rows;
                    }, []).map((row, rowIndex) => (
                      <Row key={rowIndex}>
                        {row.map((recentnews, colIndex) => (
                          <Col
                            xs={12}
                            md={6}
                            className="mt-3 countrypage"
                            key={recentnews?._id}
                            onClick={() => handleSectionClick(recentnews)}
                          >
                            <Row>
                              <Col
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                className="left-side"
                              >
                                <img
                                  src={recentnews?.Article_Images[0]}
                                  alt={recentnews?.Category}
                                  className="img-fluid list-img-wrap"
                                />
                              </Col>
                              <Col
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                className="right-side"
                              >
                                <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                                  {recentnews?.Sub_Category}
                                </p>

                                <h6
                                  className="content-heading web_fonts">
                                  {recentnews?.Title?.length > 100
                                    ? `${recentnews?.Title.slice(0, 100)}...`
                                    : recentnews?.Title}
                                </h6>
                                <div className="politicsnews">
                                  <p className="web_fonts list-by-cat">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: extractFirstSentence(recentnews?.Story),
                                      }}
                                    />
                                  </p>
                                </div>
                                <p className="disabled-text web_fonts">
                                  {recentnews?.Approved_On && (
                                    <p className="disabled-text web_fonts">
                                      by<span className="name-of web_fonts" style={{ textTransform: "none" }}>{recentnews?.Created_By}</span>
                                      {`${formatDate(recentnews?.Approved_On)}`}
                                    </p>
                                  )}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    ))}
                  </>
                ) : (
                  <NoDataFound />
                )}

                <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
              </Container>
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  )
}

export default ContentByRecentTopicIndex
