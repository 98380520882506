import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContentCreatorActions } from "../../store/contentCreator/actions";
import { commonActions } from "../../store/common/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import UpdateCreatorStatusModal from "components/commonModals/updateCreatorStatusModal";

export default function ContentCreatorDetailsIndex() {

    document.title = 'Content Creator Details'

    const { state } = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [checkStatus, setCheckStatus] = useState(false)
    const [modalType, setModalType] = useState('')
    const [StatusCheckFromModal, setStatusCheckFromModal] = useState(false)
    const [StatusCheckEditApprove, setStatusCheckEditApprove] = useState(false)

    const { content_Creator_Details } = useSelector(state => state.ContentCreatorReducer);

    const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer);
    const { superAdminTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer);
    const Id = (state?.data?.NotificationType === 'Registration' ? state?.data?.CreatorID : state?.data?._id);

    useEffect(() => {
        dispatch({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: Id });
    }, [Id, checkStatus, StatusCheckFromModal]);

    const handleShowModel = (modalTypeClicked) => {
        setModalType(modalTypeClicked)
        setCheckStatus(true)
        dispatch({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: Id });
    };

    const handleCloseModel = () => {
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { TypeOfModal: null, isOpen: false, data: null }
        });
    };

    const handleUpdateStatus = () => {
        setStatusCheckFromModal(true)
        setCheckStatus(false)
        setModalType('')
        dispatch({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: Id });
    };

    const handleEditApprove = () => {
        setStatusCheckEditApprove(true)
        setStatusCheckFromModal(false)
        setCheckStatus(false)
        setModalType('')
        dispatch({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: Id });
    }

    //To check the current Registration status before approve/reject
    useEffect(() => {
        if (checkStatus === true && (content_Creator_Details?.Registration_Status === 'Registered' || content_Creator_Details?.Registration_Status === 'Rejected') && (modalType === 'Approve' || modalType === 'Reject')) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Registration Status already updated" }
            })
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            setStatusCheckEditApprove(false)
        } else if (checkStatus === true && content_Creator_Details?.Registration_Status === 'Unregistered' && (modalType === 'Approve' || modalType === 'Reject')) {
            dispatch({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: 'CCAORR', isOpen: true, data: { modalType: modalType } }
            });
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            setStatusCheckEditApprove(false)
        } else if (StatusCheckFromModal === true && content_Creator_Details?.Registration_Status === 'Unregistered') {
            let Registration_Status_Updated_By;
            if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                Registration_Status_Updated_By = {
                    Name: superAdminTokenResponse?.result?.Name,
                    Email: superAdminTokenResponse?.result?.Email,
                    Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                }
            } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                Registration_Status_Updated_By = {
                    Name: adminTokenResponse?.result?.Name,
                    Email: adminTokenResponse?.result?.Email,
                    Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                }
            }
            dispatch({
                type: ContentCreatorActions.CHANGE_CONTENT_CREATOR_REGISTRATION_STATUS,
                payload: {
                    id: content_Creator_Details?._id,
                    data: {
                        Registration_Status: showPopup?.data?.modalType === 'Approve' ? 'Approved' : 'Rejected',
                        Registration_Status_Updated_By: Registration_Status_Updated_By
                    },
                    type: state?.type,
                    navigate: navigate
                }
            });

            setStatusCheckFromModal(false)
            setCheckStatus(false)
            setStatusCheckEditApprove(false)
        } else if (StatusCheckFromModal === true && (content_Creator_Details?.Registration_Status === 'Registered' || content_Creator_Details?.Registration_Status === 'Rejected')) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Registration Status already updated" }
            })
            handleCloseModel()
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            setStatusCheckEditApprove(false)
        } else if (StatusCheckEditApprove === true && (content_Creator_Details?.Registration_Status === 'Registered' || content_Creator_Details?.Registration_Status === 'Rejected')) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Registration Status already updated" }
            })
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            setStatusCheckEditApprove(false)
        } else if (StatusCheckEditApprove === true && content_Creator_Details?.Registration_Status === 'Unregistered') {
            navigate('/edit-content-creator-details', { state: content_Creator_Details })
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            setStatusCheckEditApprove(false)
        }
    }, [content_Creator_Details])

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Content Creators"
                            titleLink={`/content-creators/${state?.type}`}
                            breadcrumbItem="details"
                        />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="ms-3">
                                                <img
                                                    src={content_Creator_Details?.Profile_Image}
                                                    alt=""
                                                    className="avatar-md rounded-circle img-thumbnail"
                                                />
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted">
                                                    <h5>{content_Creator_Details?.Name}</h5>
                                                    <p className="mb-1">{content_Creator_Details?.Email}</p>
                                                    {/* <p className="mb-0">Id: {content_Creator_Details?._id}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Name</Label>
                                        <Input
                                            name="username"
                                            className="form-control name-uncase"
                                            placeholder="Enter User Name"
                                            type="text"
                                            defaultValue={content_Creator_Details?.Name}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Email</Label>
                                        <Input
                                            name="username"
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            type="text"
                                            defaultValue={content_Creator_Details?.Email}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Contact Number</Label>
                                        <Input
                                            name="username"
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            type="text"
                                            defaultValue={content_Creator_Details?.Contact_Number}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Bio</Label>
                                        <Input
                                            name="username"
                                            className="form-control form-control-textarea"
                                            placeholder="Enter User Name"
                                            type="textarea"
                                            defaultValue={content_Creator_Details?.Brief_Bio_Description}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Type Of Content</Label>
                                        <Input
                                            name="username"
                                            className="form-control"
                                            placeholder="enter type of content"
                                            type="text"
                                            defaultValue={content_Creator_Details?.Category?.map(single => single).join(', ')}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Country</Label>
                                        <Input
                                            name="username"
                                            className="form-control"
                                            placeholder="Enter Bio"
                                            type="text"
                                            defaultValue={content_Creator_Details?.Country?.map(single => single).join(', ')}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Created On</Label>
                                        <Input
                                            name="username"
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            type="text"
                                            defaultValue={content_Creator_Details?.Created_On}
                                            disabled
                                        />
                                    </div>
                                    {content_Creator_Details?.Registration_Status === 'Registered' &&
                                        <>
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Total amount earned</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="text"
                                                    defaultValue={content_Creator_Details?.Total_Wallet_Balance}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Paid Amount</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="text"
                                                    defaultValue={content_Creator_Details?.Paid_Amount}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Pending Amount</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="text"
                                                    defaultValue={content_Creator_Details?.Remaining_Wallet_Balance}
                                                    disabled
                                                />
                                            </div>
                                        </>
                                    }
                                    {content_Creator_Details?.Registration_Status_Updated_By?.Name &&
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Registration Status Updated By</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="text"
                                                defaultValue={`${content_Creator_Details?.Registration_Status_Updated_By?.Name} - ${content_Creator_Details?.Registration_Status_Updated_By?.Type_Of_User}`}
                                                disabled
                                            />
                                        </div>
                                    }
                                    {content_Creator_Details?.Updated_By?.Name &&
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Last Updated By</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="text"
                                                defaultValue={`${content_Creator_Details?.Updated_By?.Name} - ${content_Creator_Details?.Updated_By?.Type_Of_User}`}
                                                disabled
                                            />
                                        </div>
                                    }
                                    <div className="mt-5">
                                        {content_Creator_Details?.Registration_Status === 'Unregistered' &&
                                            <>
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="me-3"
                                                    onClick={() => handleEditApprove()}
                                                >
                                                    Edit & Approve
                                                </Button>

                                                <Button
                                                    type="button"
                                                    color="success"
                                                    className="me-3"
                                                    onClick={() => handleShowModel('Approve')}
                                                >
                                                    Approve
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="warning"
                                                    className="me-3"
                                                    onClick={() => handleShowModel('Reject')}
                                                >
                                                    Reject
                                                </Button>
                                            </>
                                        }
                                        {content_Creator_Details?.Registration_Status === 'Registered' &&
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="me-3"
                                                onClick={() => navigate('/edit-content-creator-details', { state: content_Creator_Details })}
                                            >
                                                Edit
                                            </Button>
                                        }
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate(`/content-creators/${state?.type}`)}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
                <UpdateCreatorStatusModal
                    modal={showPopup?.TypeOfModal === 'CCAORR' && showPopup?.isOpen}
                    data={content_Creator_Details}
                    toggle={handleCloseModel}
                    title={showPopup?.data?.modalType === 'Approve' ?
                        "approve content creator?" : "reject content creator?"}
                    message={showPopup?.data?.modalType === 'Approve' ?
                        `Are you sure you want to Approve the Content Creator (${content_Creator_Details?.Name})?` :
                        `Are you sure you want to Reject the Content Creator (${content_Creator_Details?.Name})?`}
                    handleSubmit={handleUpdateStatus}
                    type={showPopup?.data?.modelType}
                />
            </div>
        </React.Fragment>
    )
}