import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardTitle, Container, Input, Label, Nav, NavItem, NavLink,
    Row, Col, TabContent, TabPane
} from "reactstrap";
import classnames from "classnames";
import Breadcrumb from "../../common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import actions from '../../store/adminSubscriptionPlans/actions'
import AdminPageLoader from "../../common/adminPageLoader";

export default function SubscriptionPlansIndex() {
    document.title = "Subscription | Allen Dreyfus";
    const dispatch = useDispatch();

    const { subscriptionPlans } = useSelector(state => state.subscriptionPlanReducer)
    const { adminPageLoader } = useSelector(state => state.commonReducer);

    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    let [enableUpdate, setEnableUpdate] = useState([
        { tab: 'Monthly', enable: false },
        { tab: 'Annual', enable: false }
    ]);
    var [monthlyPlanName, setMonthlyPlanName] = useState('')
    var [monthlyPlanPrice, setMonthlyPlanPrice] = useState('')
    var [monthlyPlanDuration, setMonthlyPlanDuration] = useState('')
    var [monthlyPlanId, setMonthlyPlanId] = useState('')
    var [monthlyStripeId, setMonthlyStripeId] = useState('')

    var [annualPlanName, setAnnualPlanName] = useState('')
    var [annualPlanPrice, setAnnualPlanPrice] = useState('')
    var [annualPlanDuration, setAnnualPlanDuration] = useState('')
    var [annualPlanId, setAnnualPlanId] = useState('')
    var [annualStripeId, setAnnualStripeId] = useState('')

    var [showErrMsg1, setShowErrMsg1] = useState('')
    var [showErrMsg2, setShowErrMsg2] = useState('')

    var [showStripeMonthError, setShowStripeMonthError] = useState('')
    var [showStripeAnnualError, setShowStripeAnnualError] = useState('')

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        };
    };

    useEffect(() => {
        dispatch({ type: actions.GET_SUBSCRIPTION_PLANS_LIST })
    }, [])

    useEffect(() => {
        if (subscriptionPlans?.result?.length !== 0 && subscriptionPlans?.result?.length !== undefined) {
            setMonthlyPlanId(subscriptionPlans?.result[0]?._id)
            setMonthlyPlanName(subscriptionPlans?.result[0]?.Plan_Name)
            setMonthlyPlanPrice(subscriptionPlans?.result[0]?.Subscription_Amount)
            setMonthlyPlanDuration(subscriptionPlans?.result[0]?.Plan_Duration)
            setMonthlyStripeId(subscriptionPlans?.result[0]?.Stripe_Plan_Id)

            setAnnualPlanId(subscriptionPlans?.result[1]?._id)
            setAnnualPlanName(subscriptionPlans?.result[1]?.Plan_Name)
            setAnnualPlanPrice(subscriptionPlans?.result[1]?.Subscription_Amount)
            setAnnualPlanDuration(subscriptionPlans?.result[1]?.Plan_Duration)
            setAnnualStripeId(subscriptionPlans?.result[1]?.Stripe_Plan_Id)

            let array = [...enableUpdate];
            array[0]['enable'] = subscriptionPlans?.result[0]?.Enabled === 'Yes' ? true : false;
            array[1]['enable'] = subscriptionPlans?.result[1]?.Enabled === 'Yes' ? true : false;
            setEnableUpdate(array);
            setShowErrMsg1('')
            setShowStripeMonthError('')
            setShowErrMsg2('')
            setShowStripeAnnualError('')
        } else {
            setMonthlyPlanName('')
            setMonthlyPlanPrice('')
            setMonthlyPlanDuration('')
            setMonthlyPlanId('')
            setMonthlyStripeId('')

            setAnnualPlanName('')
            setAnnualPlanPrice('')
            setAnnualPlanDuration('')
            setAnnualPlanId('')
            setShowErrMsg1('')
            setShowStripeMonthError('')
            setShowErrMsg2('')
            setShowStripeAnnualError('')

            let array = [...enableUpdate];
            array[0]['enable'] = false;
            array[1]['enable'] = false;
            setEnableUpdate(array);
        }
    }, [subscriptionPlans])

    const handleEnableUpdateMonthly = (e) => {
        let array = [...enableUpdate];
        array[0]['enable'] = e.target.checked;
        setEnableUpdate(enableUpdate = array);
        let Enabled;
        if (enableUpdate[0].enable === true) {
            Enabled = 'Yes'
            setShowErrMsg1('')
            setShowStripeMonthError('')
        } else {
            Enabled = 'No'
            setMonthlyPlanPrice(subscriptionPlans?.result[0]?.Subscription_Amount)
            setShowErrMsg1('')
            setMonthlyStripeId(subscriptionPlans?.result[0]?.Stripe_Plan_Id)
            setShowStripeMonthError('')
        }
        dispatch({
            type: actions.ENABLE_SUBSCRIPTION_PLAN,
            payload: { Id: monthlyPlanId, Enabled: Enabled }
        })
    }

    const handleEnableUpdateAnnual = (e) => {
        let array = [...enableUpdate];
        array[1]['enable'] = e.target.checked;
        setEnableUpdate(enableUpdate = array);
        let Enabled;
        if (enableUpdate[1].enable === true) {
            Enabled = 'Yes'
            setShowErrMsg2('')
        } else {
            Enabled = 'No'
            setAnnualPlanPrice(subscriptionPlans?.result[1]?.Subscription_Amount)
            setShowErrMsg2('')
            setAnnualStripeId(subscriptionPlans?.result[1]?.Stripe_Plan_Id)
            setShowStripeAnnualError('')
        }
        dispatch({
            type: actions.ENABLE_SUBSCRIPTION_PLAN,
            payload: { Id: annualPlanId, Enabled: Enabled }
        })
    }

    const handleUpdate = (PlanName) => {
        if (PlanName === 'monthly') {
            if (monthlyPlanPrice === '') {
                setShowErrMsg1('please enter plan price')
            } else if (monthlyStripeId === '') {
                setShowStripeMonthError('please enter price-id')
            } else {
                setShowErrMsg1('')
                setShowStripeMonthError('')
                dispatch({ type: actions.UPDATE_SUBSCRIPTION_PLAN, payload: { Id: monthlyPlanId, data: { Subscription_Amount: monthlyPlanPrice, Stripe_Plan_Id: monthlyStripeId } } })
            }
        } else if (PlanName === 'annual') {
            if (annualPlanPrice === '') {
                setShowErrMsg2('please enter plan price')
            } else if (annualStripeId === '') {
                setShowStripeAnnualError('please enter price-id')
            } else {
                setShowErrMsg2('')
                setShowStripeAnnualError('')
                dispatch({ type: actions.UPDATE_SUBSCRIPTION_PLAN, payload: { Id: annualPlanId, data: { Subscription_Amount: annualPlanPrice, Stripe_Plan_Id: annualStripeId } } })
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title="Subscription plans"
                        breadcrumbItem="list"
                    />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4 mb-4">PLANS</CardTitle>
                                    <Row>
                                        <Col md="3">
                                            <Nav pills className="flex-column">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            "mb-2": true,
                                                            active: verticalActiveTab === "1",
                                                        })}
                                                        onClick={() => toggleVertical("1")}
                                                    >
                                                        Monthly plan
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            "mb-2": true,
                                                            active: verticalActiveTab === "2",
                                                        })}
                                                        onClick={() => toggleVertical("2")}
                                                    >
                                                        Annual plan
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                        <Col md="9">
                                            <TabContent activeTab={verticalActiveTab} className="text-muted mt-4 mt-md-0">
                                                <TabPane tabId="1">
                                                    <div>
                                                        <Row>
                                                            <Col sm={8} className="mb-4">
                                                                <h5>Monthly Subscription plan details</h5>
                                                            </Col>
                                                            <Col sm={4} className="d-flex justify-content-end mb-4">
                                                                <div className="form-check form-switch form-switch-md">
                                                                    {/* <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizemd"
                                                                        value={enableUpdate[0].enable}
                                                                        // checked="unchecked"
                                                                        onChange={(e) => {
                                                                            let array = [...enableUpdate];
                                                                            array[0]['enable'] = e.target.checked;
                                                                            setEnableUpdate(array);
                                                                        }}
                                                                      
                                                                        // {enableUpdate[0]['enable'] === true ? checked : unchecked}
                                                                    /> */}
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizemd"
                                                                        checked={enableUpdate[0].enable} // Set the checked attribute based on the enableUpdate state
                                                                        onChange={(e) => {
                                                                            handleEnableUpdateMonthly(e)
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="customSwitchsizemd">
                                                                        Enable Update
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col sm={12} >
                                                                <Label>Price-Id from stripe <small style={{ color: "red" }}> *</small></Label>
                                                                <Input
                                                                    type="text"
                                                                    value={monthlyStripeId}
                                                                    onChange={(e) => setMonthlyStripeId(e.target.value)}
                                                                    disabled={enableUpdate[0]?.enable === false ? true : false}
                                                                // disabled={true}
                                                                />
                                                                <p className="text-danger pt-1">{showStripeMonthError}</p>
                                                            </Col>
                                                            <Col sm={12} className="mb-3">
                                                                <Label>Title</Label>
                                                                <Input
                                                                    type="text"
                                                                    // value='Digital - Monthly'
                                                                    value={monthlyPlanName}
                                                                    // disabled={!enableUpdate[0]?.enable}
                                                                    disabled={true}
                                                                />
                                                            </Col>
                                                            <Col sm={12} className="mb-3">
                                                                <Label>Plan for</Label>
                                                                <Input
                                                                    type="text"
                                                                    // value={subscriptionPlans?.result[0]?.Plan_Duration}
                                                                    value={monthlyPlanDuration}
                                                                    disabled={true}
                                                                />
                                                            </Col>
                                                            <Col sm={12} className="mb-3">
                                                                <Label>Price of the plan<small style={{ color: "red" }}> *</small></Label>
                                                                <div className="d-flex">
                                                                    <Button
                                                                        color="light"
                                                                        style={{ borderColor: '#ced4da' }}
                                                                    >
                                                                        {/* <i className="mdi mdi-currency-eur" /> */}
                                                                        <i className="mdi mdi-currency-gbp" />
                                                                    </Button>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        // value={subscriptionPlans?.result[0]?.Subscription_Amount}
                                                                        value={monthlyPlanPrice}
                                                                        onChange={(e) => setMonthlyPlanPrice(e.target.value)}
                                                                        disabled={enableUpdate[0]?.enable === false ? true : false}
                                                                    />
                                                                </div>
                                                                <p className="text-danger pt-1">{showErrMsg1}</p>
                                                            </Col>

                                                            {/* <Col sm={12} className="mb-3">
                                                                <Label>Description</Label>
                                                                <Input
                                                                    type="text"
                                                                    value='Monthly renewing'
                                                                    disabled={!enableUpdate[0]?.enable}
                                                                />
                                                            </Col> */}
                                                            {enableUpdate[0]?.enable &&
                                                                <Col sm={12} className="mb-3">
                                                                    <Button
                                                                        color="primary"
                                                                        size="md"
                                                                        // onClick={() => {
                                                                        //     let array = [...enableUpdate];
                                                                        //     array[0]['enable'] = false;
                                                                        //     setEnableUpdate(array);
                                                                        // }}
                                                                        onClick={() => handleUpdate('monthly')}

                                                                    >
                                                                        Update
                                                                    </Button>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <div>
                                                        <Row>
                                                            <Col sm={6} className="mb-3">
                                                                <h5>Annual subscription plan details</h5>
                                                            </Col>
                                                            <Col sm={6} className="d-flex justify-content-end mb-3">
                                                                <div className="form-check form-switch form-switch-md mb-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="customSwitchsizemd"
                                                                        checked={enableUpdate[1].enable}
                                                                        // onChange={(e) => {
                                                                        //     let array = [...enableUpdate];
                                                                        //     array[1]['enable'] = e.target.checked;
                                                                        //     setEnableUpdate(array);
                                                                        // }}
                                                                        onChange={(e) => handleEnableUpdateAnnual(e)}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="customSwitchsizemd">
                                                                        Enable Update
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col sm={12} >
                                                                <Label>Price-Id from stripe <small style={{ color: "red" }}> *</small></Label>
                                                                <Input
                                                                    type="text"
                                                                    value={annualStripeId}
                                                                    onChange={(e) => setAnnualStripeId(e.target.value)}
                                                                    disabled={enableUpdate[0]?.enable === false ? true : false}
                                                                // disabled={true}
                                                                />
                                                                <p className="text-danger pt-1">{showStripeAnnualError}</p>
                                                            </Col>
                                                            <Col sm={12} className="mb-3">
                                                                <Label>Title</Label>
                                                                <Input
                                                                    type="text"
                                                                    // value='Digital - Annual'
                                                                    // value={subscriptionPlans?.result[1]?.Plan_Name}
                                                                    value={annualPlanName}
                                                                    disabled={!enableUpdate[1]?.enable}
                                                                />
                                                            </Col>
                                                            <Col sm={12} className="mb-3">
                                                                <Label>Plan for</Label>
                                                                <Input
                                                                    type="text"
                                                                    // value='one year'
                                                                    // value={subscriptionPlans?.result[1]?.Plan_Duration}
                                                                    value={annualPlanDuration}
                                                                    disabled={!enableUpdate[1]?.enable}
                                                                />
                                                            </Col>
                                                            <Col sm={12} className="mb-3">
                                                                <Label>Price of the plan<small style={{ color: "red" }}> *</small></Label>
                                                                <div className="d-flex">
                                                                    <Button
                                                                        color="light"
                                                                        style={{ borderColor: '#ced4da' }}
                                                                    >
                                                                        {/* <i className="mdi mdi-currency-eur" /> */}
                                                                        {/* pound currency */}
                                                                        <i className="mdi mdi-currency-gbp" />
                                                                    </Button>
                                                                    <Input
                                                                        type="text"
                                                                        // value='499.99'
                                                                        // value={subscriptionPlans?.result[1]?.Subscription_Amount}
                                                                        value={annualPlanPrice}
                                                                        onChange={(e) => setAnnualPlanPrice(e.target.value)}
                                                                        disabled={enableUpdate[1]?.enable === false ? true : false}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <p className="text-danger pt-1">{showErrMsg2}</p>
                                                            {/* <Col sm={12} className="mb-3">
                                                                <Label>Description</Label>
                                                                <Input
                                                                    type="text"
                                                                    value='Save 2 months'
                                                                    disabled={!enableUpdate[1]?.enable}
                                                                />
                                                            </Col> */}
                                                            {enableUpdate[1]?.enable &&
                                                                <Col sm={12}>
                                                                    <Button
                                                                        color="primary"
                                                                        size="md"
                                                                        // onClick={() => {
                                                                        //     let array = [...enableUpdate];
                                                                        //     array[1]['enable'] = false;
                                                                        //     setEnableUpdate(array);
                                                                        // }}
                                                                        onClick={() => handleUpdate('annual')}
                                                                    >
                                                                        Update
                                                                    </Button>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};