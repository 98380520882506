import React, { useEffect, useMemo, useState } from "react";
import {
    Card, CardBody, Col, Container, Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import TableContainer from '../../common/TableContainer';
import Breadcrumbs from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { WithdrawActions } from '../../store/withdrawRequest/actions';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from "common/CustomPagination";

const AllWithdrawalRequestIndex = () => {
    document.title = "Withdraw | Allen Dreyfus";
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [requestList, setRequestList] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const { adminPageLoader } = useSelector(state => state.commonReducer);
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { allWithdrawRequestList } = useSelector(state => state.withdrawReducer);

    useEffect(() => {
        dispatch({
            type: WithdrawActions.GET_ALL_WITHDRAW_REQUEST_LIST
        })
    }, [])

    useEffect(() => {
        if (allWithdrawRequestList?.result?.length !== 0) {
            setRequestList(allWithdrawRequestList?.result)
        }
    }, [allWithdrawRequestList?.result])

    const columns = useMemo(
        () => [
            {
                Header: 'Creator Name',
                accessor: 'Creator_Name',
                filterable: true,
            },
            {
                Header: 'Pending Amount',
                accessor: 'PendingAmount',
                filterable: true,
            },
            {
                Header: 'Requested Amount',
                accessor: 'WithdrawAmount',
                filterable: true,
            },
            {
                Header: 'Requested On',
                accessor: 'Submitted_On',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'Status',
                filterable: true,
            },

            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const withdrawData = cellProps.row.original;
                                    navigate('/all-withdrawal-request-detail', { state: withdrawData });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ], []
    );

    const handleSendRequest = () => {
        navigate('/send-withdrawal-request')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs
                            title="Withdraw Requests"
                            breadcrumbItem="list"
                        />
                        <Row>
                            <Col xs="12">
                                <Card className="payouts">
                                    <CardBody>
                                        {requestList && requestList?.length > 0 ? (
                                            <React.Fragment>
                                                <>
                                                    <TableContainer
                                                        columns={columns}
                                                        data={requestList || []}
                                                        isGlobalFilter={true}
                                                        isAddCustList={false}
                                                        isPagination={true}
                                                        customPageSize={itemsPerPage}
                                                        tableClass="align-middle table-nowrap center-table"
                                                        isShowingPageLength={false}
                                                        iscustomPageSizeOptions={true}
                                                        pagination="pagination pagination-rounded justify-content-end mb-2"
                                                    />
                                                </>
                                            </React.Fragment>
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
};

export default AllWithdrawalRequestIndex;
