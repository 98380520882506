import React, { useEffect } from "react"
import Subscribenow from "../../assets/webImages/subscribenow.svg"
import { Button, Container, Col } from "react-bootstrap"
import SubscriptionModal from "webComponents/modals/SubscriptionModal"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Subscriptiontext from "../../assets/webImages/real-time1.webp"
import { loadStripe } from "@stripe/stripe-js"
import { useSelector } from "react-redux"
import { STRIPE_LIVE_PUBLISHABLE_KEY, STRIPE_TEST_PUBLISHABLE_KEY } from '../../utils/constant'

const SubscribeBanner = ({ Subscribenow, Subscriptiontext }) => {
  //test publishable key
  // const stripePromise = loadStripe(STRIPE_TEST_PUBLISHABLE_KEY)

  // live publishable key
  const stripePromise = loadStripe(STRIPE_LIVE_PUBLISHABLE_KEY)

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)

  const { getSessionId } = useSelector(state => state.subscriptionPlanReducer)

  //stripe session
  useEffect(() => {
    const redirectCheckout = async () => {
      const stripe = await stripePromise
      if (getSessionId?.id) {
        const { error } = await stripe.redirectToCheckout({
          sessionId: getSessionId?.id,
        })
        if (error) {
          console.error("Error:", error)
        }
      }
    }
    redirectCheckout()
  }, [getSessionId?.id])

  const handleSubscribeClick = () => {
    setShowSubscriptionModal(true)
  }

  const handleCloseSubscriptionModal = () => {
    setShowSubscriptionModal(false)
  }

  return (
    <div className="sub-img-section">
      <Container>
        <Col className="new-sub-col">
          <div className="sub-image">
            <img src={Subscribenow} alt="subscriptionbutton" />
          </div>
          <div className="sub-img-text ">
            <h5 className="web_fonts">
              {/* real-time financial news impacting markets */}
              <img src={Subscriptiontext} className="subscriptionimage-text" />
            </h5>{" "}
          </div>
          <div className="subbtn">
            <Button
              className="sub-img-button custom-nav-link"
              onClick={handleSubscribeClick}
            >
              subscribe now
            </Button>
          </div>
        </Col>
        <SubscriptionModal
          show={showSubscriptionModal}
          handleClose={handleCloseSubscriptionModal}
        />
      </Container>
    </div>
  )
}

export default SubscribeBanner
