import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AudioPlayer from "react-audio-player";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WebHomePageActions } from "store/webHome/actions";
import WebPageLoader from "common/webPageLoader";
import NoDataFound from "webComponents/Common/NoRecords";

const Podcast = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeAudioPlayer, setActiveAudioPlayer] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showFullTitles, setShowFullTitles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { PodcastList } = useSelector(state => state.WebHomePageReducer);
  const { ContentByRecentTopic } = useSelector(
    state => state.WebHomePageReducer
  );
  const [firstTopic, setFirstTopic] = useState("");

  useEffect(() => {
    setFirstTopic(ContentByRecentTopic?.result?.[0]?.Topic);
  }, [ContentByRecentTopic]);

  const { webPageLoader } = useSelector(state => state.commonReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = PodcastList?.result?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(
    (PodcastList?.result?.length || 0) / itemsPerPage
  );

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    if (!dateString) return "Invalid Date";

    const [datePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("-");

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`;

    return formattedDate;
  };

  const [showFullDescription, setShowFullDescription] = useState(
    currentItems ? currentItems.map(() => false) : []
  );

  const handleReadMore = (index) => {
    const updatedShowFullDescription = [...showFullDescription];
    updatedShowFullDescription[index] = true;
    setShowFullDescription(updatedShowFullDescription);
  };

  const handleReadLess = (index) => {
    const updatedShowFullDescription = [...showFullDescription];
    updatedShowFullDescription[index] = false;
    setShowFullDescription(updatedShowFullDescription);
  };


  useEffect(() => {
    setLoader(true);
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_PODCAST
    });
  }, [dispatch]);

  const handleListenButtonClick = audioPlayerId => {
    setActiveAudioPlayer(audioPlayerId);
  };

  useEffect(() => {
    if (PodcastList?.result?.length === 0) {
      setLoader(false);
      setFetchSuccess(false);
    } else {
      setLoader(false);
      setFetchSuccess(true);
    }
  }, [PodcastList]);

  const handlePodcastClick = () => {
    navigate("/web/Podcast");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxVisiblePages = 5;

      for (let i = 1; i <= totalPages; i++) {
        // Show only a maximum of 5 pages, and add an ellipsis if more pages are available
        if (
          i <= maxVisiblePages ||
          i === totalPages ||
          i === currentPage ||
          i === currentPage - 1 ||
          i === currentPage + 1
        ) {
          pageNumbers.push(
            <button
              className={`page-numbs  ${currentPage === i ? "page-active" : ""
                }`}
              key={i}
              onClick={() => handlePageChange(i)}
              disabled={currentPage === i}
            >
              {i}
            </button>
          );
        } else if (
          i === maxVisiblePages + 1 &&
          totalPages > maxVisiblePages + 1
        ) {
          pageNumbers.push(
            <span key="ellipsis" className="ellipsis">
              ...
            </span>
          );
        }
      }
      return pageNumbers;
    };

    return (
      <div className="pagination-container pagination-design">
        <button
          className="pagination-button previous-page"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;&lt;
        </button>
        {renderPageNumbers()}
        <button
          className="pagination-button next-page"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;&gt;
        </button>
      </div>
    );
  };

  return (
    <>
      {loader ? (
        <WebPageLoader />
      ) : (
        <>
          {fetchSuccess ? (
            <>
              <Container>
                <div className="fullrow-podcast">
                  <div className="recent-heading podcast-recent-heading">
                    <h2 className="web_fonts">Podcast </h2>
                    <hr className="hr-line" />
                  </div>
                  <Row>
                    {currentItems?.map((podcast, index) => (
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="topic-column"
                        key={index}
                      >
                        <Row>
                          <Col xs={12} md={3} lg={3}>
                            <div className="podcast-img-background">
                              <img
                                src={podcast?.Thumbnail_Image}
                                alt={`Topic ${index + 2} Image`}
                                className="pod-page"
                              />
                            </div>
                            <p className="disabled-text web_fonts" style={{ paddingLeft: '10px' }}>
                              by
                              <span className="name-of web_fonts" style={{ textTransform: "none" }}>
                                {podcast?.Created_By}
                              </span>
                              {`${formatDate(podcast?.Approved_On)}`}
                            </p>
                          </Col>
                          <Col xs={12} md={9} lg={9}>
                            <div className="pod-detail-leftside web_fonts">
                              {podcast?.Category[0] === "Recent Topic" ? (
                                <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                                  {firstTopic}
                                </p>
                              ) : (
                                <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                                  {podcast?.Category[0]}
                                </p>
                              )}
                              <h4 className="web_fonts politics-title color-black ">
                                {podcast?.Title}
                              </h4>
                              <p>
                                {(showFullDescription[index] && podcast?.Story) ? (
                                  <>
                                    <p className="web_fonts color-black">{podcast?.Story}</p>
                                    <small className="readMore"
                                     onClick={() => handleReadLess(index)} style={{ color: "blue" }}>
                                      ...show less
                                    </small>
                                  </>
                                ) : (
                                  <>
                                    {podcast?.Story?.length > 200 && (
                                      <p className="web_fonts color-black" 
                                      onClick={() => handleReadMore(index)}>
                                        {podcast?.Story?.slice(0, 200)}
                                        <small className="readMore" style={{ color: "blue" }}>
                                          ...read more
                                        </small>
                                      </p>
                                    )}
                                    {podcast?.Story?.length <= 200 && (
                                      <p className="web_fonts color-black">
                                        {podcast?.Story}
                                      </p>
                                    )}
                                  </>
                                )}
                              </p>

                              <Button
                                className="podcastpage-listen-button"
                                onClick={() =>
                                  handleListenButtonClick(index + 1)
                                }
                              >
                                Listen
                              </Button>
                            </div>
                            <div>

                            </div>
                          </Col>
                        </Row>
                        <Col>
                          <div className="podcast-audioplayer">
                            {activeAudioPlayer === index + 1 && (
                              <Row>
                                <Col xs={12}>
                                  <AudioPlayer
                                    className="reactauidoplayer"
                                    src={podcast?.Podcast[0]}
                                    autoPlay={isPlaying}
                                    controls
                                  />
                                </Col>
                              </Row>
                            )}
                          </div>
                        </Col>
                      </Col>
                    ))}
                  </Row>

                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
              </Container>
            </>
          ) : (
            <NoDataFound />
          )
          }
        </>
      )}
    </>
  );
};

export default Podcast;
