import LoginIndex from "components/login";
import React from "react";

export default function Login() {

    return (
        <div>
            <LoginIndex />
        </div>
    )
}