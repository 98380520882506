import ContentCreatorRegistrationIndex from "components/contentCreatorRegistration";
import React from "react";

export default function ContentCreatorRegistration () {

    return(
        <div>
            <ContentCreatorRegistrationIndex />
        </div>
    )
}