import React, { useEffect, useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import Bookmarkicon from "../../assets/webImages/bookmarked-icon.svg"
import { WebBookmarkActions } from "store/webBookmark/actions"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "store/webHome/actions"
import { useParams } from "react-router-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { WebContentDeatilActions } from "store/webContentDetail/actions"
import { ContentCreatorActions } from "store/contentCreator/actions"
import NoDataInDB from "../../common/no-data-image.svg"
import NoDataFound from "../Common/NoRecords"
import WebPageLoader from "../../common/webPageLoader"

const SaveContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { contentId } = useParams()
  const { state } = useLocation()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { DetailContent } = useSelector(state => state.WebContentDetailReducer)

  const User_Id = tokenResponse?.result?._id
  const { ListofBookMarks } = useSelector(state => state.WebBookmarkReducer)
  const [fetchSuccess, setFetchSuccess] = useState(false)
  const [loader, setLoader] = useState(false)

  const currentContentId = state?.contentDetail?._id || contentId
  const creatorId = DetailContent?.result?.Content_Creator_Id

  useEffect(() => {
    dispatch({
      type: WebContentDeatilActions.GET_WEBCONTENT_DETAIL_NEWS,
      payload: currentContentId,
    })

    dispatch({
      type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS,
      payload: state?.contentDetail?.Content_Creator_Id,
    })

    dispatch({
      type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS,
      payload: creatorId,
    })
  }, [state?.contentDetail?._id, contentId, dispatch, creatorId])

  useEffect(() => {
    setLoader(true)
    if (User_Id) {
      const payload = { User_Id }
      dispatch({
        type: WebBookmarkActions.GET_LIST_OF_BOOKMARKS_DATA,
        payload,
      })
    }
  }, [dispatch, User_Id])

  const [deleteToolTipVisible, setDeleteToolTipVisible] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const bookmarksPerPage = 8

  const toggleDeleteToolTip = bookmarkId => {
    setDeleteToolTipVisible(prevState => ({
      ...prevState,
      [bookmarkId]: !prevState[bookmarkId],
    }))
  }

  const handleDeleteBookmark = (User_Id, Blog_Id) => {
    dispatch({
      type: WebBookmarkActions.DELETE_THE_BOOKMARK,
      payload: { User_Id, Blog_Id },
    })
  }

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail.Blog_Id}`, {
      state: { contentDetail: contentDetail, clickedFrom: "Bookmarks" },
    })
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS,
      payload: contentDetail._id,
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  document.title = "Bookmarks"

  useEffect(() => {
    const userId = tokenResponse?.result?._id
    const UserId = userId
    const Category = "Article"

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_FORYOU_NEWS_DETAILS,
      payload: { UserId, Category },
    })
  }, [dispatch, tokenResponse])

  const indexOfLastBookmark = currentPage * bookmarksPerPage
  const indexOfFirstBookmark = indexOfLastBookmark - bookmarksPerPage
  const currentBookmarks = ListofBookMarks?.result
    ?.flatMap(item => item.Bookmarks)
    .slice(indexOfFirstBookmark, indexOfLastBookmark)

  useEffect(() => {
    if (currentBookmarks?.result?.length === 0) {
      setLoader(false)
      setFetchSuccess(false)
    } else {
      setLoader(false)
      setFetchSuccess(true)
    }
  }, [currentBookmarks])

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`
  }

  return (
    <>
      {loader ? (
        <WebPageLoader />
      ) : (
        <>
          {fetchSuccess ? (
            <div className="">
              <Container>

                <nav aria-label="breadcrumb" className="save-menu">
                  <ol className="breadcrumb save-menu-list">
                    <li className="breadcrumb-item">
                      <Link to="/" className="savepage-breadcrums">
                        Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active savepage-breadcrums your-bookmark"
                      aria-current="page"
                    >
                      Bookmarks
                    </li>
                  </ol>
                </nav>
                <hr className="hr-line save" />
                <Row>
                  {currentBookmarks?.length === 0 ? (
                    <Col>
                      <div className="no-data-container">
                        <div className="centered-content">
                          <img
                            src={NoDataInDB}
                            alt="No Data Found"
                            className="no-data-image"
                          />
                          <h1 className="no-data-message web_fonts">
                            {" "}
                            No bookmarks found. Add bookmarks to see them here.
                          </h1>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    currentBookmarks?.map(bookmark => (
                      <Col
                        key={bookmark._id}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        
                      >
                        <div className="element">
                          <div className="left-content">
                            <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                              {bookmark?.Category}</p>

                            <h4 className="web_fonts politics-title color-black"
                            onClick={() => handleSectionClick(bookmark)}
                            >
                              {bookmark?.Title?.length > 40
                                ? `${bookmark?.Title?.slice(0, 40)}...`
                                : bookmark?.Title}
                            </h4>

                            <div className="time">
                              {bookmark?.Blog_Created_On}
                            </div>
                          </div>

                          <div className="right-content" >
                            <div className="picture" onClick={() => handleSectionClick(bookmark)}>
                              <img src={bookmark.Image} alt="Bookmark"  />
                            </div>
                            <div className="bookmark-icon-div">
                              <a
                                className="bookmark"
                                onClick={() =>
                                  toggleDeleteToolTip(bookmark._id)
                                }
                              >
                                <img
                                  src={Bookmarkicon}
                                  className="bookmark-icon"
                                  alt="Bookmark Icon"
                                />
                              </a>
                              {deleteToolTipVisible[bookmark._id] && (
                                <div className="delete-tool-tip">
                                  <a
                                    className="delete"
                                    onClick={() =>
                                      handleDeleteBookmark(
                                        User_Id,
                                        bookmark.Blog_Id
                                      )
                                    }
                                  >
                                    Delete
                                  </a>

                                  <a className="cancel">Cancel</a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
                {/* Pagination */}
                <nav>
                  <ul className="pagination justify-content-center">
                    <li
                      className={`page-item ${currentPage === 1 ? "disabled" : ""
                        }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Previous"
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    {Array.from(
                      {
                        length: Math.ceil(
                          ListofBookMarks?.result?.flatMap(
                            item => item.Bookmarks
                          ).length / bookmarksPerPage
                        ),
                      },
                      (_, i) => (
                        <li
                          key={i}
                          className={`page-item ${currentPage === i + 1 ? "active" : ""
                            }`}
                        >
                          <a
                            onClick={() => paginate(i + 1)}
                            className="page-link"
                          >
                            {i + 1}
                          </a>
                        </li>
                      )
                    )}
                    <li
                      className={`page-item ${currentPage ===
                        Math.ceil(
                          ListofBookMarks?.result?.flatMap(
                            item => item.Bookmarks
                          ).length / bookmarksPerPage
                        )
                        ? "disabled"
                        : ""
                        }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Next"
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </Container>
            </div>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  )
}

export default SaveContent
