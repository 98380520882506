import ContentCreatorDetailsIndex from "components/contentCreatorDetails";
import React from "react";

export default function ContentCreatorDetails() {

    return (
        <div>
            <ContentCreatorDetailsIndex />
        </div>
    )
};