import React, { useEffect, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Slider from "react-slick"
import { WebHomePageActions } from "store/webHome/actions"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useNavigate } from "react-router-dom"
import WebPageLoader from "../../common/webPageLoader";

const Politics = () => {
  const dispatch = useDispatch()
  const sliderRef = useRef(null)
  const navigate = useNavigate()

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, { state: { contentDetail: contentDetail } })
    dispatch({ type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS, payload: contentDetail._id })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const { webPageLoader } = useSelector(state => state.commonReducer);

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  const { PoliticsCategoryNews } = useSelector(
    state => state.WebHomePageReducer
  )
  useEffect(() => {
    const TypeOfContent = "Article"
    const Category = "Politics"

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_POLITICS_CATEGORY_DETAILS,
      payload: { TypeOfContent, Category },
    })
  }, [dispatch])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <div className="politics-fullsection">
      <Container className="politics-container">
        <Row className="politics-row">
          <div className="recent-container">
            <div className="recent-heading">
              <h1 className="web_fonts heading-for-website">politics</h1>
              <hr className="hr-line" />
            </div>
            <h5 className="web_fonts color-black sub-heading-for">
              political dynamics within our markets
            </h5>
          </div>

          {webPageLoader ?
            <WebPageLoader className="recent-loader" /> :
            <>
              {PoliticsCategoryNews?.result &&
                PoliticsCategoryNews?.result?.length > 4 ? (
                <div className="slider-container">
                  <Slider {...settings} ref={sliderRef}>
                    {PoliticsCategoryNews?.result?.slice(0,50)?.map(news => (
                      <Col key={news?._id} className="politics-column">
                        <div onClick={() => handleSectionClick(news)}>
                          <img
                            src={news?.Article_Images}
                            alt={`Topic ${news?._id} Image`}
                          />
                          {/* <p className="politics-heading-text web_fonts "> */}
                          <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                            politics
                          </p>
                          <h6
                            className="web_fonts color-black politics-title"
                            onClick={() => handleSectionClick(news)}
                          >
                            {news?.Title?.length > 50
                              ? `${news?.Title?.slice(0, 50)}...`
                              : news?.Title}
                          </h6>
                          <p className="disabled-text web_fonts">
                            by
                            <span className="name-of web_fonts" style={{textTransform: "none" }}>
                              {news?.Created_By}
                            </span>
                            {`${formatDate(news?.Approved_On)} | ${news?.TimeDifference
                              } ago`}
                          </p>
                        </div>
                      </Col>
                    ))}
                  </Slider>
                </div>
              ) : (
                // Render news directly if there are 4 or fewer news items
                <Row>
                  {PoliticsCategoryNews?.result?.map(news => (
                    <Col
                      key={news._id}
                      xs={12}
                      sm={6}
                      md={3}
                      // className="politics-column"
                      className="politics-column-below4"
                      onClick={() => handleSectionClick(news)}
                    >
                      <div>
                        <img
                          src={news?.Article_Images}
                          alt={`Topic ${news?._id} Image`}
                        />
                        <h6 className="politics-heading-text web_fonts">
                          politics
                        </h6>
                        <h6 className="web_fonts color-black politics-title">
                          {news.Title.length > 50
                            ? `${news?.Title.slice(0, 50)}...`
                            : news?.Title}
                        </h6>
                        <p className="disabled-text web_fonts">
                          by{" "}
                          <span className="name-of web_fonts" style={{textTransform: "none" }}>
                            {news?.Created_By}
                          </span>
                          {` ${formatDate(news?.Approved_On)} | ${news?.TimeDifference
                            } ago`}
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </>
          }
        </Row>
      </Container>
    </div>
  )
}

export default Politics
