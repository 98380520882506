export const userAuthActions = {
    AUTH_LOGIN: 'AUTH_LOGIN',
    VERIFY_TOKEN: 'VERIFY_TOKEN',
    TOKEN_RESPONSE: 'TOKEN_RESPONSE',
    FOTGOT_PASSWORD: 'FOTGOT_PASSWORD',

    //website token response
    USER_AUTH_RESPONSE: 'USER_AUTH_RESPONSE',
    UPDATE_AUTH_DETAILS: 'UPDATE_AUTH_DETAILS',

    //super admin token response
    SUPER_ADMIN_AUTH_RESPONSE: 'SUPER_ADMIN_AUTH_RESPONSE',
    UPDATE_SUPER_ADMIN_AUTH_DETAILS: 'UPDATE_SUPER_ADMIN_AUTH_DETAILS',

    // Admin token response
    ADMIN_AUTH_RESPONSE: 'ADMIN_AUTH_RESPONSE',
    UPDATE_ADMIN_AUTH_DETAILS: 'UPDATE_ADMIN_AUTH_DETAILS',

    // creator token response
    CREATOR_AUTH_RESPONSE: 'CREATOR_AUTH_RESPONSE',
    UPDATE_CREATOR_AUTH_DETAILS: 'UPDATE_CREATOR_AUTH_DETAILS',

    //super admin, creator CHANGE PASSWORD 
    GET_RESET_YOUR_PASSWORD :"GET_RESET_YOUR_PASSWORD",
    SET_RESET_YOUR_PASSWORD :"SET_RESET_YOUR_PASSWORD",
}