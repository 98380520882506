import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Label,
    Input,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import { useNavigate } from "react-router-dom";
import { FaPoundSign } from "react-icons/fa"
import AdminPageLoader from "../../common/adminPageLoader";
import { CSVLink } from "react-csv"
import TableContainer from "../../common/TableContainer"
import Breadcrumbs from "../../common/Breadcrumb"
import { Button } from "react-bootstrap"
import { creatorDashboardActions } from "../../store/creatorDashboard/actions"
import { toast, ToastContainer } from 'react-toastify';
import { FaDollarSign } from "react-icons/fa";
import CustomPagination from "common/CustomPagination";

export default function CreatorWalletDetailIndex() {
    document.title = "Revenue List | Allen Dreyfus";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [revenueList, setRevenueList] = useState('');
    const [total, setTotal] = useState('');
    const [paid, setPaid] = useState('');
    const [remainingBalance, setRemainingBalance] = useState('');
    const [exportStatus, setExportStatus] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const { adminPageLoader } = useSelector(state => state.commonReducer);
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);

    const { creatorRevenueDetails } = useSelector(state => state.creatorDashboardReducer);

    useEffect(() => {
        if (creatorRevenueDetails && creatorRevenueDetails?.statusCode === 200) {
            setRevenueList(creatorRevenueDetails?.result)
            setTotal(creatorRevenueDetails?.Total_Amount)
            setPaid(creatorRevenueDetails?.Paid_Amount)
            setRemainingBalance(creatorRevenueDetails?.Remaining_Balance_Amount)
        }
    }, [creatorRevenueDetails])

    useEffect(() => {
        if (creatorTokenResponse) {
            dispatch({
                type: creatorDashboardActions.GET_CREATOR_REVENUE_DETAILS, payload: { Creator_Id: creatorTokenResponse?.result?._id }
            })
        }
    }, [creatorTokenResponse])

    const csvHeaders = [
        { label: "Payment Amount", key: "Payment_Amount" },
        { label: "Mode Of Payment", key: "Mode_Of_Payment" },
        { label: "Paid On", key: "Paid_On" },
        // { label: "Subscribed On", key: "Subscription_On" },
    ]

    const handleError = (error) => {
        setExportStatus('error');
        toast.error('Failure Export', { position: toast.POSITION.TOP_CENTER });
    };

    const handleSuccess = () => {
        setExportStatus('success');
        toast.success('Revenue Export successful!', { position: toast.POSITION.TOP_CENTER });
    };
    const columns = useMemo(
        () => [
            {
                Header: "Payment Amount",
                accessor: "Payment_Amount",
                filterable: true,
                Cell: ({ value }) => (
                    <>
                        <FaDollarSign /> {value}
                    </>
                ),
            },
            {
                Header: "Mode Of Payment",
                accessor: "Mode_Of_Payment",
                filterable: true,
            },
            {
                Header: "Paid On",
                accessor: "Paid_On",
                filterable: true,
            },
        ],
        []
    )

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ? (
                    <AdminPageLoader />
                ) : (
                    <Container fluid>
                        <Breadcrumbs title="revenue" breadcrumbItem="List" />
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <Label className="form-label wallet-label">Total Revenue Amount</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        value={total}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <Label className="form-label wallet-label">Received Amount</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        value={paid}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <Label className="form-label wallet-label">Pending Amount</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        value={remainingBalance}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {revenueList && revenueList?.length > 0 ? (
                                            <React.Fragment>
                                                <div className="d-flex justify-content-end mb-2">
                                                    <CSVLink
                                                        data={revenueList}
                                                        headers={csvHeaders}
                                                        filename={"revenue_data.csv"}
                                                        onClick={() => handleSuccess()}
                                                        onError={handleError}
                                                    >
                                                        {/* <Button color="primary">Export</Button> */}
                                                    </CSVLink>
                                                </div>
                                                <TableContainer
                                                    columns={columns}
                                                    data={revenueList || []}
                                                    isGlobalFilter={true}
                                                    isAddCustList={false}
                                                    isPagination={true}
                                                    customPageSize={itemsPerPage}
                                                    tableClass="align-middle table-nowrap"
                                                    isShowingPageLength={false}
                                                    iscustomPageSizeOptions={true}
                                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                                />
                                            </React.Fragment>
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </React.Fragment>
    )
}

