import RevenueDetailIndex from "components/revenueDetail";
import React from "react";

export default function RevenueDetail () {

    return (
        <div>
            <RevenueDetailIndex />
        </div>
    )
}