export const ContentCreatorActions = {
    CREATOR_REGISTRATION: 'CREATOR_REGISTRATION',

    GET_CONTENT_CREATORS_LIST_BY_STATUS: 'GET_CONTENT_CREATORS_LIST_BY_STATUS',
    SET_CONTENT_CREATORS_LIST_BY_STATUS: 'SET_CONTENT_CREATORS_LIST_BY_STATUS',

    GET_CONTENT_CREATOR_DETAILS: 'GET_CONTENT_CREATOR_DETAILS',
    SET_CONTENT_CREATOR_DETAILS: 'SET_CONTENT_CREATOR_DETAILS',

    CHANGE_CONTENT_CREATOR_REGISTRATION_STATUS: 'CHANGE_CONTENT_CREATOR_REGISTRATION_STATUS',

    ADMIN_EDIT_APPROVE_CREATOR_REGISTRATION_STATUS: 'ADMIN_EDIT_APPROVE_CREATOR_REGISTRATION_STATUS',

    UPDATE_CONTENT_CREATOR: 'UPDATE_CONTENT_CREATOR',

    CREATOR_RESPONSE: 'CREATOR_RESPONSE',


}