export const actions = {
    GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
    SET_COUNTRY_LIST: 'SET_COUNTRY_LIST',

    GET_ADD_COUNTRY_NAMES: 'GET_ADD_COUNTRY_NAMES',
    SET_ADD_COUNTRY_NAMES: 'SET_ADD_COUNTRY_NAMES',

    GET_DELETE_COUNTRY_NAMES: 'GET_DELETE_COUNTRY_NAMES',
    SET_DELETE_COUNTRY_NAMES: 'SET_DELETE_COUNTRY_NAMES',

    GET_ONLY_COUNTRY_LIST: 'GET_ONLY_COUNTRY_LIST',
    SET_ONLY_COUNTRY_LIST: 'SET_ONLY_COUNTRY_LIST',

    CREATE_ONLY_COUNTRIES :'CREATE_ONLY_COUNTRIES',
    RESPONSE_CREATE_ONLY_COUNTRIES :'RESPONSE_CREATE_ONLY_COUNTRIES',

    DELETE_ONLY_COUNTRIES :'DELETE_ONLY_COUNTRIES',
    RESPONSE_DELETE_ONLY_COUNTRIES :'RESPONSE_DELETE_ONLY_COUNTRIES'


}
