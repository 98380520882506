import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, CardTitle, Table } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "../../store/blogContent/actions";
import { commonActions } from "../../store/common/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import profileicon from '../../assets/images/dashboard/user3.png';
import { use } from "i18next";

export default function CreatorPitchIndex() {
    const { state } = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    var [typeOfContent, setTypeOfContent] = useState('');
    var [title, setTitle] = useState('');
    var [Country, setCountry] = useState('');
    var [Category, setCategory] = useState('');
    var [SubCategory, setSubCategory] = useState('');
    var [pitchStory, setPitchStory] = useState('');
    var [Impacts, setImpacts] = useState('')
    var [RejectionReason, setRejectionReason] = useState('')
    var [ApproveRemarks, setApproveRemarks] = useState('')
    var [pitchCreatedOn, setPitchCreatedOn] = useState('')

    const { creatorPitchDetail } = useSelector(state => state.BlogContentReducer);
    const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer);

    // const Id = state?.data?._id;
    const Id = state?.data?.NotificationType === "Pitch Status Updated" ? state?.data?.ContentID : state?.data?._id;

    useEffect(() => {
        dispatch({ type: BlogContentActions.GET_CREATOR_PITCH_DETAIL, payload: Id });
    }, [Id]);

    const handleNavigate = () => {
        if (state?.data?.Pitch_Status === 'New') {
            navigate(`/pitch/needApproval`)
        } else if (state?.data?.Pitch_Status === 'Approved' && state?.clickedFrom === 'ApprovedList') {
            navigate(`/pitch/Approved`)
        } else if (state?.data?.Pitch_Status === 'Approved' && state?.clickedFrom === 'ContentCreation') {
            navigate(`/content-creation`)
        } else if (state?.data?.Pitch_Status === 'Rejected') {
            navigate(`/pitch/Rejected`)
        }
    }

    useEffect(() => {
        if (creatorPitchDetail) {
            setTypeOfContent(creatorPitchDetail?.result?.TypeOfContent);
            setTitle(creatorPitchDetail?.result?.Title);
            setCountry(creatorPitchDetail?.result?.Country?.map(single => single).join(', '));
            setCategory(creatorPitchDetail?.result?.Category[0]);
            setSubCategory(creatorPitchDetail?.result?.Sub_Category?.map(single => single).join(', '));
            setPitchStory(creatorPitchDetail?.result?.Pitch_story);
            setImpacts(creatorPitchDetail?.result?.Impacts)
            setRejectionReason(creatorPitchDetail?.result?.Pitch_Rejection_Reason)
            setApproveRemarks(creatorPitchDetail?.result?.Remarks_For_Pitch)
            setPitchCreatedOn(creatorPitchDetail?.result?.Pitch_Created_On)
        }
    }, [creatorPitchDetail])

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Pitch"
                            titleLink={(state?.data?.Pitch_Status === "New") ? `/pitch/needApproval` : (state?.data?.Pitch_Status === "Approved" ? `/pitch/Approved` : `/pitch/Rejected`)}
                            breadcrumbItem="details"
                        />
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Type of Content</Label>
                                            <Input
                                                name="TypeOFContent"
                                                className="form-control"
                                                placeholder="Enter Type of Content"
                                                type="text"
                                                defaultValue={typeOfContent}
                                                disabled
                                            />
                                        </div>
                                    </Col>

                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">market focus</Label>
                                            <Input
                                                name="Country"
                                                className="form-control"
                                                placeholder="Enter market focus"
                                                type="text"
                                                defaultValue={Country}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Title</Label>
                                            <Input
                                                name="Title"
                                                className="form-control proName"
                                                placeholder="Enter Title"
                                                type="text"
                                                defaultValue={title}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Pitch Submitted On</Label>
                                            <Input
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                type="text"
                                                defaultValue={pitchCreatedOn}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-4" md={creatorPitchDetail?.result?.Category[0] === "Frontier Markets" || creatorPitchDetail?.result?.Category[0] === "Recent Topic" ? "6" : "12"}>
                                        <div className="form-group">
                                            <Label className="form-label">Category</Label>
                                            <Input
                                                name="Category"
                                                className="form-control"
                                                placeholder="Enter Category"
                                                type="text"
                                                defaultValue={Category}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    {(creatorPitchDetail?.result?.Category[0] === "Frontier Markets" || creatorPitchDetail?.result?.Category[0] === "Recent Topic") &&
                                        < Col md="6" className="mb-4">
                                            {
                                                creatorPitchDetail?.result?.Sub_Category?.length !== 0 &&
                                                <div className="form-group">
                                                    <Label className="form-label">{creatorPitchDetail?.result?.Category[0]}</Label>
                                                    <Input
                                                        name="Category"
                                                        className="form-control"
                                                        placeholder="Enter Category"
                                                        type="text"
                                                        defaultValue={SubCategory}
                                                        disabled
                                                    />
                                                </div>
                                            }
                                        </Col>
                                    }
                                </Row>
                                <Col md="12" className="mb-4">
                                    <div className="form-group">
                                        <Label className="form-label">What is the story?</Label>
                                        <div className="form-control form-control-textarea storyfont " dangerouslySetInnerHTML={{ __html: pitchStory }} />
                                    </div>
                                </Col>

                                <Col md="12" className="mb-4">
                                    <div className="form-group">
                                        <Label className="form-label">Impact on Markets</Label>
                                        <Input
                                            name="username"
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            type="textarea"
                                            rows={5}
                                            defaultValue={Impacts}
                                            disabled
                                        />
                                    </div>
                                </Col>

                                {RejectionReason &&
                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Rejection Reason</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="textarea"
                                                rows={3}
                                                defaultValue={RejectionReason}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                }

                                {ApproveRemarks &&
                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Remarks For Pitch</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="textarea"
                                                rows={3}
                                                defaultValue={ApproveRemarks}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                }

                                <div className="mt-5">
                                    {state?.data?.Pitch_Status === 'Approved' &&

                                        <>
                                            {(!creatorPitchDetail?.result?.Created_On) &&
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="me-3"
                                                    // onClick={() => handleShowModel('Edit')}
                                                    onClick={() => navigate('/edit-pitch-detail', { state: creatorPitchDetail?.result })}
                                                >
                                                    Create Content
                                                </Button>
                                            }
                                        </>
                                    }

                                    <Button
                                        type="button"
                                        color="danger"
                                        // onClick={() => navigate(state?.data?.Pitch_Status === 'New' ? `/pitch/needApproval` : state?.data?.Pitch_Status === 'Approved' ? `/content-creation` : state?.data?.Pitch_Status === 'Rejected' ? `/pitch/Rejected` : `/pitch/Approved`)}
                                        onClick={() => handleNavigate()}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment >
    )
}