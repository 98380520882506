
import React, { useState, useEffect, useRef } from 'react';
import * as Yup from "yup";
import { useFormik, Field } from "formik";
import { Form, Card, CardBody, Col, Row, CardTitle, Container, Label, Input, FormFeedback, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import Multiselect from 'multiselect-react-dropdown';
import { BlogContentActions } from 'store/blogContent/actions';
import { useDispatch, useSelector } from 'react-redux';
import RichTextEditor from '../../common/QuillRichTextEditor';
import FileSizeValidation from '../../FileSizeValidation/index';
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import { commonActions } from "../../store/common/actions";
import success_img from "../../assets/images/dashboard/success1-popup-image.gif";
import { actions } from "store/Countries/actions";
import Cropper from 'react-easy-crop';

export default function AdminAddPodcastIndex() {
    const dispatch = useDispatch();
    const audioref = useRef();
    const thumbref = useRef();
    const maxSelectableDate = new Date();

    const { buttonLoader } = useSelector(state => state.commonReducer);
    const { countryList } = useSelector(state => state.CountryReducer);
    const { recentTopicList } = useSelector(state => state.BlogContentReducer);
    const { superAdminTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer);
    const { blogContentResponse } = useSelector(state => state.BlogContentReducer);

    const [recentTopic, setRecentTopic] = useState([]);
    const [storyContent, setStoryContent] = useState('');

    const [countryOptions, setCountryOptions] = useState([]);
    const [countryError, setCountryError] = useState("");

    const [selectedOptions1, setSelectedOptions1] = useState([]);
    const [recentTopicError, setRecentTopicError] = useState("");

    const [selectedAudio, setSelectedAudio] = useState(null)
    const [audioFile, setAudioFile] = useState("")
    const [audioFileError, setAudioFileError] = useState("")

    const [descError, setDescError] = useState("")

    const [selectThumbNail, setSelectedThumbNail] = useState(null)
    const [thumbFile, setThumbFile] = useState("")
    const [thumbError, setThumbError] = useState("")

    const [duration, setDuration] = useState("")
    const [durationError, setDurationError] = useState("")

    var [articleDate, setArticleDate] = useState('')
    var [dateError, setDateError] = useState('')

    var [name, setName] = useState('')
    var [adminId, setAdminId] = useState('')

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [thumbimage, setThumbimage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [showCropper, setShowCropper] = useState(true); // State to control Cropper visibility

    const [showCreationPopup, setCreationPopup] = useState({ isOpen: false, statusCode: null });

    useEffect(() => {
        if (superAdminTokenResponse?.statusCode === 200) {
            setName(superAdminTokenResponse?.result?.Name);
            setAdminId(superAdminTokenResponse?.result?._id);
        }
    }, [superAdminTokenResponse?.statusCode])

    useEffect(() => {
        if (blogContentResponse && blogContentResponse?.statusCode === 200) {
            setCreationPopup({ isOpen: true, statusCode: 200 });
            validation.resetForm();
        } else if (blogContentResponse && blogContentResponse?.statusCode === 400) {
            setCreationPopup({ isOpen: false, statusCode: 400 });
        }
    }, [blogContentResponse])

    useEffect(() => {
        if (recentTopicList && recentTopicList.result && recentTopicList.result.length > 0) {
            const firstTopic = {
                id: recentTopicList?.result[0]?.Topic,
                name: recentTopicList?.result[0]?.Topic,
            };
            setRecentTopic([firstTopic]);
        }
    }, [recentTopicList?.result]);

    useEffect(() => {
        dispatch({ type: actions.GET_COUNTRY_LIST })
        dispatch({ type: BlogContentActions.GET_RECENT_TOPIC_LIST })
    }, [])

    const handleClose = () => {
        setCreationPopup({ isOpen: false, statusCode: null })
        window.location.href = '/admin-podcast';
        // navigate('/content/Approved')
    }

    // Country List
    let countryArray = countryList?.result?.map((val) => ({
        id: val?.Country_Name,
        name: val?.Country_Name,
    }));

    // Country
    const onSelectCounty = (selectedCountry) => {
        setCountryOptions(selectedCountry);
        setCountryError("");
    };

    const onRemoveCountry = (selectedCountry) => {
        setCountryOptions(selectedCountry);
    };

    // Recent Topics
    const onSelect1 = (selectedList1) => {
        setSelectedOptions1(selectedList1);
        setRecentTopicError("");
    };

    const onRemove1 = (selectedList1) => {
        setSelectedOptions1(selectedList1);
    };

    // Thumbnail Image
    const handleThumbNailImage = event => {
        const thumbFile = event.target.files[0]
        setThumbError("")
        if (thumbFile) {
            let result = FileSizeValidation(event.target.files);
            if (result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
                thumbref.current.value = null;
            } else {
                if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
                    result?.FinalImages[0]?.type === 'image/png') {
                    setSelectedThumbNail(URL.createObjectURL(thumbFile))
                    setThumbFile(thumbFile)
                } else {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { show: true, status: "failed", message: "Unsupported File Format" }
                    })
                    thumbref.current.value = null
                }
            }
        } else {
            setThumbError("thumbnail image is required")
        }
    }

    const handleRemoveThumbnail = () => {
        // setSelectedThumbNail("")
        setThumbFile("")
        setCroppedImage("")
        thumbref.current.value = "";

    };

    // Duration
    const handleDuration = e => {
        if (e.target.value === null) {
            setDurationError("duration for the uploaded file is required")
        } else {
            setDuration(e.target.value)
            setDurationError("")
        }
    }

    // Publish Date
    const handleChange = date => {
        // const localDateTime = moment(date, 'DD-MM-YYYY hh:mm A').local();
        // const formattedDateTime = localDateTime.format('DD-MM-YYYY hh:mm A');
        // const articleDate = moment(formattedDateTime, 'DD/MM/YYYY h:mm aa').toDate();
        setArticleDate(date);
        if (dateError) {
            setDateError('');
        }
    };

    // for validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Title: '',
            // TypeOfContent: '',
            Country: '',
            Category: '',
            Sub_Category: '',
            Story: '',
            Impacts: '',
            Duration: '',
        },
        validationSchema: Yup.object({
            Title: Yup.string().required("enter the title"),
            // TypeOfContent: Yup.string().required("select the content type"),
            Category: Yup.string().required("select the category"),
            Story: Yup.string().required("description is required"),
            Impacts: Yup.string().required("impacts field is required"),
        }),
        onSubmit: (data) => {
            if (countryOptions?.length === 0) {
                setCountryError("select the value for market focus")
            }
            //  else if (storyContent === '') {
            //     setDescError("story is required")
            // } 
            else if (validation?.values?.Category === 'Frontier Markets' && selectedOptions1?.length === 0) {
                setMarketError("frontier markets is required")
            } else if (validation?.values?.Category === 'Recent Topic' && selectedOptions1?.length === 0) {
                setRecentTopicError("recent topic is required")
            } else if ("Podcast" && !selectedAudio) {
                setAudioFileError("podcast file is required")
            } else if ("Podcast" && !croppedImage) {
                setThumbError("thumb-nail image is required")
            }
            // else if ("Podcast" && validation.values.Duration === '') {
            //     setDurationError("duration of the podcast is required")
            // } 
            else if (!articleDate) {
                setDateError("please select the date");
                return;
            } else {
                const formData = new FormData();
                let Podcast_Submitted_By = {};
                if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                    Podcast_Submitted_By = {
                        Name: superAdminTokenResponse?.result?.Name,
                        Email: superAdminTokenResponse?.result?.Email,
                        Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                    }
                } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                    Podcast_Submitted_By = {
                        Name: adminTokenResponse?.result?.Name,
                        Email: adminTokenResponse?.result?.Email,
                        Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                    }
                }
                setDescError('')
                setDurationError('')
                if (validation?.values?.Category === 'Frontier Markets') {
                    var markets = [];
                    selectedOptions1.map(val =>
                        markets = [...markets, val.name])
                    const subCategoryMarkets = JSON.stringify(markets);
                    data['Sub_Category'] = subCategoryMarkets;
                } else if (validation?.values?.Category === 'Recent Topic') {
                    var topics = [];
                    selectedOptions1.map(val =>
                        topics = [...topics, val.name])
                    const subCategoryTopic = JSON.stringify(topics)
                    data['Sub_Category'] = subCategoryTopic;
                }
                var country = [];
                countryOptions.map(val =>
                    country = [...country, val.name])
                const countryList = JSON.stringify(country)
                data['Country'] = countryList;

                formData.append("TypeOfContent", "Podcast")
                formData.append("Country", data.Country)
                formData.append("Title", data.Title)
                formData.append("Category", data.Category)
                formData.append("Sub_Category", data.Sub_Category)
                formData.append("Story", data.Story)
                formData.append("Impacts", data.Impacts)
                formData.append("Created_By", name)
                formData.append("Content_Creator_Id", adminId)
                formData.append("Status", "Approved")
                formData.append("Podcast_Submitted_By", JSON.stringify(Podcast_Submitted_By))
                formData.append("Date_Approved", articleDate)
                // formData.append("Duration", data.Duration)
                formData.append("Podcast", audioFile)
                formData.append("Thumbnail_Image", croppedImage)

                // for (var pair of formData.entries()) { //to console formdata
                //     console.log(pair[0] + ', ' + pair[1]);
                // }
                dispatch({ type: BlogContentActions.CREATE_ADMIN_PODCAST_CONTENT, payload: { data: formData } })
            }
        }
    })

    // Audio
    const handleAudioChange = event => {
        const file = event.target.files[0]
        setAudioFileError("")
        setSelectedAudio(URL.createObjectURL(file))
        setAudioFile(file)
        // if (file) {
        //     let result = FileSizeValidation(event.target.files);
        //     if (result.Validation === "false") {
        //         dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
        //         audioref.current.value = null
        //     } else {
        //         if (result?.FinalImages[0]?.type === 'audio/mpeg') {
        //             setSelectedAudio(URL.createObjectURL(file))
        //             setAudioFile(file)
        //         } else {
        //             dispatch({
        //                 type: commonActions.SET_ALERT,
        //                 payload: { show: true, status: "failed", message: "Unsupported File Format" }
        //             })
        //             audioref.current.value = null
        //         }
        //     }
        // } else {
        //     setAudioFileError("podcast file is required")
        // }
    }

    const handleClearAudio = () => {
        setSelectedAudio(null);
        if (audioref.current) {
            audioref.current.value = ''; // Reset the value of the input element if it exists
        }
    }

    const handleImageSelect = (event) => {
        if (event.target.files?.length !== 0) {
            const selectedImage = event.target.files[0];
            let result = FileSizeValidation([selectedImage]);
            if (!result || result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
                thumbref.current.value = null;
                return;
            }
            setThumbError("")
            setThumbimage(URL.createObjectURL(selectedImage)); // Set the image in state
            if (selectedImage) {
                setThumbFile(selectedImage)
            } else {
                setThumbError("thumbnail image is required")
            }
            setShowCropper(true); // Show Cropper when new image selected
        }
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    };


    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        try {
            const imgElement = new Image();
            imgElement.onload = async () => {
                const croppedImage = await getCroppedImg(imgElement, croppedAreaPixels);
                setCroppedImage(croppedImage);
            };
            imgElement.src = thumbimage;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const getCroppedImg = async (image, croppedAreaPixels) => {
        if (!(image instanceof HTMLImageElement)) {
            throw new Error('Provided image is not an HTMLImageElement');
        }

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            croppedAreaPixels.x * scaleX,
            croppedAreaPixels.y * scaleY,
            croppedAreaPixels.width * scaleX,
            croppedAreaPixels.height * scaleY,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    };

    const handleShowCroppedImage = () => {
        setShowCropper(false);
    };

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <p className="mb-2 font-size-16 create-size">Podcast</p>

                        <Form className="form-horizontal mt-2"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-4">

                                        <Col md="6">
                                            <div className="form-group">
                                                <Label className="form-label">Type of Content</Label><span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="TypeOfContent"
                                                    id="type"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={validation.touched.TypeOfContent && validation.errors.TypeOfContent ? true : false}
                                                    defaultValue="Podcast"
                                                    disabled
                                                >
                                                    <option value="" disabled> -- Select Content Type --  </option>
                                                    <option value="Podcast">Podcast</option>
                                                </Input>

                                                {validation.touched.TypeOfContent && validation.errors.TypeOfContent ? (
                                                    <FormFeedback type="invalid">{validation.errors.TypeOfContent}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label htmlFor="Title" className="form-label">market focus</Label><span className="text-danger fw-bold">*</span>
                                                <Multiselect
                                                    options={countryArray}
                                                    displayValue="name"
                                                    onSelect={onSelectCounty}
                                                    onRemove={onRemoveCountry}
                                                    selectedValues={countryOptions}
                                                    value={validation.values.Country || []}
                                                />
                                                <div>
                                                    <div><span className="market-select">selected values:</span> {countryOptions?.map((option) => option.name).join(', ')}</div>
                                                </div>
                                                {countryError && <div style={{ color: "red" }}>{countryError}</div>}
                                            </div>
                                        </Col>

                                        <Col md="12" className="mb-4">
                                            <Label htmlFor="Title" className="form-label">Title</Label><span className="text-danger fw-bold">*</span>
                                            <Input
                                                name="Title"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Title || ""}
                                                invalid={
                                                    validation.touched.Title && validation.errors.Title ? true : false
                                                }
                                            />
                                            {validation.touched.Title && validation.errors.Title ? (
                                                <FormFeedback type="invalid">{validation.errors.Title}</FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Col className="mb-4" md={validation?.values?.Category === "Frontier Markets" || validation?.values?.Category === "Recent Topic" ? "6" : "12"} >
                                            <div className="form-group">
                                                <Label className="form-label">Category</Label><span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="Category"
                                                    id="type"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.Category || ""}
                                                    invalid={validation.touched.Category && validation.errors.Category ? true : false}
                                                >
                                                    <option value="" disabled> -- Select the Category --  </option>
                                                    <option value="Business">Business</option>
                                                    <option value="Politics">Politics</option>
                                                    <option value="Recent Topic">Recent Topics</option>
                                                    {/*<option value="Green">Green</option>
                                                    <option value="In Depth">In-Depth</option>
                                                    <option value="localknowledge">Local Knowledge</option>
                                                    <option value="Frontier Markets">Frontier Markets</option> */}
                                                </Input>
                                                {validation.touched.Category && validation.errors.Category ? (
                                                    <FormFeedback type="invalid">{validation.errors.Category}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        {validation?.values?.Category === "Frontier Markets" ?
                                            <>
                                                <Col className="mb-4" md="6">
                                                    <div className="form-group ">
                                                        <Label className="form-label">Select the Frontier Markets</Label><span className="text-danger fw-bold">*</span>
                                                        <Multiselect
                                                            options={countryArray}
                                                            displayValue="name"
                                                            onSelect={onSelect1}
                                                            onRemove={onRemove1}
                                                            selectedValues={selectedOptions1}
                                                            value={validation.values.Sub_Category || []}
                                                        />
                                                        <div>
                                                            <div><span className="market-select">selected values:</span> {selectedOptions1.map((option) => option.name).join(', ')}</div>
                                                        </div>
                                                        {marketError && <div style={{ color: "red" }}>{marketError}</div>}
                                                    </div>
                                                </Col>
                                            </> : validation?.values?.Category === "Recent Topic" ?
                                                <>
                                                    <Col className="mb-4" md="6">
                                                        <div className="form-group">
                                                            <Label className="form-label">Select Recent Topic</Label>
                                                            <span className="text-danger fw-bold">*</span>
                                                            <Multiselect
                                                                options={recentTopic}
                                                                displayValue="name"
                                                                onSelect={onSelect1}
                                                                onRemove={onRemove1}
                                                                selectedValues={selectedOptions1}
                                                                value={validation.values.Sub_Category || []}
                                                            >
                                                                <option value="" disabled>
                                                                    -- Select the Recent Topic --
                                                                </option>
                                                                {/* Map over the array to generate options */}
                                                                {recentTopic?.map((topic) => (
                                                                    <option key={topic.value} value={topic.value}>
                                                                        {topic.name}
                                                                    </option>
                                                                ))}
                                                            </Multiselect>

                                                            <div>
                                                                <div><span className="market-select">selected values:</span> {selectedOptions1.map((option) => option.name).join(', ')}</div>
                                                            </div>
                                                            {recentTopicError && <div style={{ color: "red" }}>{recentTopicError}</div>}
                                                        </div>
                                                    </Col>
                                                </> :
                                                <></>
                                        }
                                        <Col className="mb-4" md="12">
                                            <Label htmlFor="title">Description</Label><span className="text-danger fw-bold">*</span>
                                            <Input
                                                className="form-control proName"
                                                id="story"
                                                name="Story"
                                                type="textarea"
                                                rows={8}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Story || ""}
                                                invalid={
                                                    validation.touched.Story && validation.errors.Story ? true : false
                                                }
                                            />
                                            {validation.touched.Story && validation.errors.Story ? (
                                                <FormFeedback type="invalid">{validation.errors.Story}</FormFeedback>
                                            ) : null}
                                        </Col>

                                        {"Podcast" ? (
                                            <>
                                                <Col md="12" >
                                                    <div className="form-group">
                                                        <Label className="form-label">Upload podcast File</Label>
                                                        <span className="text-danger fw-bold">*</span>
                                                        <span><small className="text-success"><i> (Supported Audio format: .mp3, .wav)</i></small></span>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {selectedAudio ? (
                                                                <div style={{ marginRight: '10px' }}>
                                                                    <audio controls>
                                                                        <source src={selectedAudio} type="audio/mpeg" />
                                                                    </audio>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="form-group">
                                                                        <div><Input
                                                                            type="file"
                                                                            name="podcast"
                                                                            innerRef={audioref}
                                                                            accept="audio/mpeg, audio/wav"
                                                                            onChange={handleAudioChange}
                                                                            multiple={false}
                                                                        />
                                                                        </div>
                                                                        <div style={{ color: "green" }}>upload podcast file</div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {selectedAudio && (
                                                                <button className="clear-audio" onClick={handleClearAudio}>Clear Audio File</button>
                                                            )}
                                                        </div>
                                                        {audioFileError && (
                                                            <div style={{ color: "red" }}>{audioFileError}</div>
                                                        )}

                                                    </div>
                                                </Col>
                                                <Col className="mb-4 crop-section" md="12">
                                                    <div className="form-group">
                                                        <Label className="form-label">
                                                            Upload Thumbnail Image
                                                        </Label>
                                                        <span className="text-danger fw-bold">*</span>
                                                        <span><small className="text-success"><i> (Supported Image formats : .jpeg, .jpg, .png)</i></small></span>
                                                        {/* {selectThumbNail && (
                                                            <div>
                                                                <img
                                                                    className="profile-pic"
                                                                    src={selectThumbNail}
                                                                    alt="Selected"
                                                                    style={{
                                                                        padding: "10px",
                                                                        maxWidth: "50%",
                                                                        maxHeight: "150px",
                                                                    }}
                                                                />
                                                                <button
                                                                    className="remove-button-thumb1"
                                                                    type="button"
                                                                    onClick={handleRemoveThumbnail}
                                                                >
                                                                    X
                                                                </button>
                                                            </div>
                                                        )} */}
                                                    </div>

                                                    {/* <Input
                                                        type="file"
                                                        name="newThumbnailImage"
                                                        accept="image/png, image/jpeg,image/jpg"
                                                        // innerRef={thumbref}
                                                        onChange={handleThumbNailImage} // Uncomment if needed                                                       
                                                        multiple={false}
                                                    /> */}
                                                    <div>
                                                        <Input type="file"
                                                            accept="image/png, image/jpeg,image/jpg,image/webp"
                                                            innerRef={thumbref}
                                                            onChange={handleImageSelect} />
                                                        {(thumbimage && showCropper) && (
                                                            <div>
                                                                <Cropper
                                                                    image={thumbimage}
                                                                    crop={crop}
                                                                    zoom={zoom}
                                                                    aspect={4 / 3}
                                                                    onCropChange={onCropChange}
                                                                    onZoomChange={onZoomChange}
                                                                    onCropComplete={onCropComplete}
                                                                />
                                                                <button className='finish-button' onClick={handleShowCroppedImage}>finish cropping</button>
                                                            </div>
                                                        )}
                                                        <div style={{ color: "green" }}>
                                                            upload thumbnail image
                                                        </div>
                                                        {(croppedImage && !showCropper) && (
                                                            <div className='croped-image'>
                                                                {croppedImage && (
                                                                    <div className='final-img'>
                                                                        <img
                                                                            className="thumb-img-pic"
                                                                            src={URL.createObjectURL(croppedImage)}
                                                                            alt="Selected"
                                                                            style={{
                                                                                padding: "10px",
                                                                                maxWidth: "50%",
                                                                                maxHeight: "150px",
                                                                            }}
                                                                        />
                                                                        <button
                                                                            className="remove-button-thumb1"
                                                                            type="button"
                                                                            onClick={handleRemoveThumbnail}
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}

                                                        {thumbError && (
                                                            <div style={{ color: "red" }}>{thumbError}</div>
                                                        )}
                                                    </div>
                                                </Col>

                                                {/* <Col md="12" className="mb-4">
                                                    <Label htmlFor="Title" className="form-label">Duration</Label><span className="text-danger fw-bold">*</span>
                                                    <Input
                                                        name="Duration"
                                                        type="text"
                                                        value={validation.values.Duration || ""}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.Duration && validation.errors.Duration ? true : false
                                                        }
                                                    />
                                                    {durationError && (
                                                        <div style={{ color: "red" }}>{durationError}</div>
                                                    )}
                                                </Col> */}
                                            </>
                                        ) : <> </>
                                        }
                                        <Col className="mb-4" md="12">
                                            <Label htmlFor="title">Impact on Markets</Label><span className="text-danger fw-bold">*</span>

                                            <Input
                                                className="form-control"
                                                id="impacts"
                                                name="Impacts"
                                                type="textarea"
                                                rows={5}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Impacts || ""}
                                                invalid={
                                                    validation.touched.Impacts && validation.errors.Impacts ? true : false
                                                }
                                            />
                                            {validation.touched.Impacts && validation.errors.Impacts ? (
                                                <FormFeedback type="invalid">{validation.errors.Impacts}</FormFeedback>
                                            ) : null}
                                        </Col>
                                        <Label className="form-label mt-3">Podcast publish Date</Label>
                                        <DatePicker className="articleDate"
                                            defaultValue={new Date(validation?.values?.Date_Approved)} // April 10, 2024, 9:30 AM
                                            selected={articleDate}
                                            onChange={handleChange}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            maxDate={maxSelectableDate}
                                            placeholderText="select date and time"
                                        />
                                        <p className="text-danger text-size pt-2">{dateError}</p>
                                    </Row>
                                    <button
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                    >
                                        {buttonLoader ? <Spinner color="light" size="sm" /> : "Create"}
                                    </button>
                                </CardBody>
                            </Card>

                            <Modal isOpen={showCreationPopup?.isOpen} toggle={handleClose} centered={true}>
                                <ModalHeader centered={true} toggle={handleClose} tag="h4">
                                    <h4>Alert</h4>
                                </ModalHeader>
                                <ModalBody centered={true}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            // validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {
                                            showCreationPopup?.statusCode === 200 ?
                                                <Row>
                                                    <Col md={12} className="text-center">
                                                        <img src={success_img} alt="success_popup" className="popup-success" />
                                                        <h5>Podcast is added successfully</h5>
                                                    </Col>
                                                    <hr />

                                                    <Col md={12}>
                                                        <div className="text-end">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger save-customer me-2"
                                                                onClick={handleClose}
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                :
                                                <></>
                                        }
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Form>
                    </Container>
                </div>
            </React.Fragment >

        </>
    )
}