import { revenueActions } from "./actions";
import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import { commonActions } from "../common/actions";

export const revenueSagas = function* (){
    yield all([
        yield takeEvery(revenueActions.GET_ADMIN_REVENUE_LIST, getAdminRevenueList),
        yield takeEvery(revenueActions.GET_ADMIN_REVENUE_DETAIL, getAdminRevenueDetail),
    ])
}

// Get Revenue List
const getAdminRevenueList = function* () {
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/totalSubscription`)
        );
        yield put({
            type: revenueActions.SET_ADMIN_REVENUE_LIST,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

// Get Revenue Detail
const getAdminRevenueDetail = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/detail/${payload}`)
        );
        yield put({
            type: revenueActions.SET_ADMIN_REVENUE_DETAIL,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : null
        })
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
}
