import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { actions } from '../../store/Advertise/actions';

export default function AdvertiseDetailsIndex() {
    document.title = "Advertise details | Allen Dreyfus";

    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { adminPageLoader } = useSelector(state => state.commonReducer);
    const { advertiseById } = useSelector(state => state.advertiseReducer);

    const Id = state?._id;

    useEffect(() => {
        dispatch({ type: actions.GET_ADVERTISE_BY_ID, payload: Id });
    }, [Id]);

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Advertise with us"
                            titleLink="/advertise-with-us-list"
                            breadcrumbItem="details"

                        />
                        <h4 className="card-title mb-4">Details</h4>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <Row>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Full Name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={advertiseById?.result?.Fullname}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={advertiseById?.result?.Email}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Company Name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={advertiseById?.result?.CompanyName}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Submitted On</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={advertiseById?.result?.Created_On}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Message</Label>
                                                <Input
                                                    className="form-control"
                                                    type="textarea"
                                                    rows={5}
                                                    defaultValue={advertiseById?.result?.Message}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                      
                                    </Row>
                                    <Row>
                                        
                                    </Row>
                                    <div className="mt-4">
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate('/advertise-with-us-list')}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
};