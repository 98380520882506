export const payoutsActions = {

    GET_ADMIN_PAYOUTS_LIST: 'GET_ADMIN_PAYOUTS_LIST',
    SET_ADMIN_PAYOUTS_LIST: 'SET_ADMIN_PAYOUTS_LIST',

    GET_ADMIN_PAYOUT_DETAIL: 'GET_ADMIN_PAYOUT_DETAIL',
    SET_ADMIN_PAYOUT_DETAIL: 'SET_ADMIN_PAYOUT_DETAIL',

    ADD_ADMIN_PAYOUT: 'ADD_ADMIN_PAYOUT',

    PAYOUT_RESPONSE: 'PAYOUT_RESPONSE',

}