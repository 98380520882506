import React, { useMemo, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "../../store/blogContent/actions";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../common/Breadcrumb";
import TableContainer from '../../common/TableContainer';
import AdminPageLoader from "common/adminPageLoader";
import CustomPagination from "../../common/CustomPagination";

export default function AdminPitchList() {
    document.title = "Pitch | Allen Dreyfus";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { type } = useParams();
    const [pitchList, setPitchList] = useState([]); // Ensure initial state is an empty array

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const { adminCreatorPitchNeedApprovalList, adminCreatorPitchListByStatus } = useSelector(state => state.BlogContentReducer);
    const { adminPageLoader } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (type === 'needApproval') {
            dispatch({
                type: BlogContentActions.GET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST,
            });
        } else if (type === 'Approved' || type === 'Rejected') {
            dispatch({
                type: BlogContentActions.GET_ADMIN_CREATOR_PITCH_LIST_BY_STATUS,
                payload: type
            });
        }
    }, [type, dispatch]);

    useEffect(() => {
        if (type === 'needApproval' && adminCreatorPitchNeedApprovalList?.result?.length) {
            setPitchList(adminCreatorPitchNeedApprovalList.result);
        } else if ((type === 'Approved' || type === 'Rejected') && adminCreatorPitchListByStatus?.result?.length) {
            setPitchList(adminCreatorPitchListByStatus.result);
        } else {
            setPitchList([]); // Ensure pitchList is an array
        }
    }, [type, adminCreatorPitchNeedApprovalList, adminCreatorPitchListByStatus]);

    const columns = useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'Title',
                filterable: true,
                Cell: Title => {
                    return (
                        <div className="proName">
                            <ContentTitle {...Title} />
                        </div>
                    );
                }
            },
            {
                Header: 'Pitch Status',
                accessor: 'Pitch_Status',
                filterable: true,
            },
            {
                Header: 'Submitted By',
                accessor: 'Created_By',
                filterable: true,
                Cell: Created_By => {
                    return (
                        <div className="proName">
                            <ContentTitle {...Created_By} />
                        </div>
                    );
                }
            },
            {
                Header: 'Submitted On',
                accessor: 'Pitch_Created_On',
                filterable: true,
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const Data = cellProps.row.original;
                                    navigate('/admin/pitch-detail', { state: { data: Data, type: type } });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ],
        [navigate, type]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs title="Pitch" breadcrumbItem={`${type === 'needApproval' ? 'Need Approval' : type} List`} />
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {pitchList && pitchList.length > 0 ? (
                                            <>
                                                <TableContainer
                                                    columns={columns}
                                                    data={pitchList || []}
                                                    isGlobalFilter={true}
                                                    isAddCustList={false}
                                                    isPagination={true}
                                                    customPageSize={itemsPerPage}
                                                    tableClass="align-middle table-nowrap"
                                                    isShowingPageLength={false}
                                                    iscustomPageSizeOptions={true}
                                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                                />                                               
                                            </>
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

const ContentTitle = (cell) => {
    return (cell?.value && cell?.value.length > 25) ? `${cell.value.slice(0, 25)}...` : cell.value;
}
