import { userAuthActions } from "./actions";

const initialState = {
    userAuthResponse: null,
    tokenResponse: null, //for website
    creatorTokenResponse: null, //for creator
    superAdminTokenResponse: null, //for super admin
    adminTokenResponse: null, //for admin
    isAuthenticated: false,
    route: null
};

export const userAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case userAuthActions.USER_AUTH_RESPONSE:
            return {
                ...state,
                userAuthResponse: action.payload
            }
        case userAuthActions.TOKEN_RESPONSE:
            return {
                ...state,
                tokenResponse: action.payload
            }
        //website
        case userAuthActions.UPDATE_AUTH_DETAILS:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                route: action.payload.route,
                tokenResponse: action.payload.result
            }
        //super admin 
        case userAuthActions.UPDATE_SUPER_ADMIN_AUTH_DETAILS:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                route: action.payload.route,
                superAdminTokenResponse: action.payload.result
            }

        //admin 
        case userAuthActions.UPDATE_ADMIN_AUTH_DETAILS:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                route: action.payload.route,
                adminTokenResponse: action.payload.result
            }
        //creator
        case userAuthActions.UPDATE_CREATOR_AUTH_DETAILS:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                route: action.payload.route,
                creatorTokenResponse: action.payload.result
            }
        default:
            return state;
    }
};