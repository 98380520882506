import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import { ContentCreatorActions } from "./actions";
import { commonActions } from "../common/actions";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import { creatorDashboardActions } from "../../store/creatorDashboard/actions"

export const ContentCreatorSagas = function* () {
    yield all([
        yield takeEvery(ContentCreatorActions.CREATOR_REGISTRATION, creatorRegistration),
        yield takeEvery(ContentCreatorActions.GET_CONTENT_CREATORS_LIST_BY_STATUS, getContentCreatorsListByStatus),
        yield takeEvery(ContentCreatorActions.CHANGE_CONTENT_CREATOR_REGISTRATION_STATUS, changeContentCreatorRegistrationStatus),
        yield takeEvery(ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, getContentCreatorDetails),
        yield takeEvery(ContentCreatorActions.UPDATE_CONTENT_CREATOR, updateContentCreator),
        yield takeEvery(ContentCreatorActions.ADMIN_EDIT_APPROVE_CREATOR_REGISTRATION_STATUS, adminEditApproveCreatorRegistrationStatus),
    ])
};

const creatorRegistration = function* (data) {
    const { payload } = data;
    const { reset } = payload;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/contentCreator/create`, payload?.data)
        )
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: ContentCreatorActions.CREATOR_RESPONSE,
            payload: result?.data
        });
        if (payload?.page === 'register') {
            if (result?.data?.statusCode === 200) {
                reset();
                yield put({
                    type: commonActions.SET_ALERT,
                    payload: { show: true, status: "success", message: result?.data?.message }
                });
            } else {
                yield put({
                    type: commonActions.SET_ALERT,
                    payload: { show: true, status: "failed", message: result?.data?.message }
                });
            };
        };
        yield delay(2000);
        yield put({ type: ContentCreatorActions.CREATOR_RESPONSE, payload: null });
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getContentCreatorsListByStatus = function* (data) {
    const { payload } = data;

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/contentCreator/list/${payload}`)
        );
        yield put({
            type: ContentCreatorActions.SET_CONTENT_CREATORS_LIST_BY_STATUS,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : []
        });
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });

};

const changeContentCreatorRegistrationStatus = function* (data) {

    const { payload } = data;
    const { navigate } = payload
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/contentcreator/update/registrationStatus/${payload?.id}`, payload?.data)
        );
        if (result?.data?.statusCode === 200) {
            yield put({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: null, isOpen: false, data: null }
            });
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "success", message: result?.data?.message }
            });
            if (navigate) {
                navigate('/content-creators/Registered')
            }
            // yield put({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: payload?.id });
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: result?.data?.message }
            });
        }
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

};

const getContentCreatorDetails = function* (data) {

    const { payload } = data;

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/contentCreator/detail/${payload}`)
        );
        yield put({
            type: ContentCreatorActions.SET_CONTENT_CREATOR_DETAILS,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : null
        });
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });

};

const updateContentCreator = function* (data) {

    const { payload } = data;
    const { navigate } = payload;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/contentCreator/edit/${payload?.id}`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        if (navigate) {
            // navigate('/content-creator-details', { state: { data: payload?.state, type: 'Registered' } })
            navigate('/content-creators/Registered')
        }
        if (result?.data?.statusCode === 200) {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "success", message: result?.data?.message }
            });
            yield put({ type: ContentCreatorActions.GET_CONTENT_CREATOR_DETAILS, payload: payload?.id });
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: result?.data?.message }
            });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

};

const adminEditApproveCreatorRegistrationStatus = function* (data) {

    const { payload } = data;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    const navigate = payload.navigate
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/contentCreator/adminEdit/${payload?.Id}`, payload?.data)
        );
        if (result?.data?.statusCode === 200) {
            yield put({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: null, isOpen: false, data: null }
            });
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "success", message: result?.data?.message }
            });
            navigate('/content-creators/Registered')
        } else {
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: result?.data?.message }
            });
        }
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};
