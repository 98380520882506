import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { Container } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "store/webHome/actions"
import WebPageLoader from "common/webPageLoader"
import NoDataFound from "../Common/NoRecords"

const ForyouPageIndex = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const { ForYouUserPrefrence } = useSelector(state => state.WebHomePageReducer)
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  const { DetailContent } = useSelector(state => state.WebContentDetailReducer)
  const { ForYouSpecificContent } = useSelector(
    state => state.WebHomePageReducer
  )
  const [fetchSuccess, setFetchSuccess] = useState(false)
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = ForYouSpecificContent?.result?.slice(
    indexOfFirstItem,
    indexOfLastItem
  )

  const totalPages = Math.ceil(
    (ForYouSpecificContent?.result?.length || 0) / itemsPerPage
  )

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  // useEffect(() => {

  //   const userId = tokenResponse?.result?._id
  //   const UserId = userId
  //   const Category = "Article"

  //   dispatch({
  //     type: WebHomePageActions.GET_WEBHOME_FORYOU_NEWS_DETAILS,
  //     payload: { UserId, Category },
  //   })
  // }, [dispatch, tokenResponse])

  useEffect(() => {
    setLoader(true)
    const UserId = tokenResponse?.result?._id
    const TypeOfContent = "Article"

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME,
      payload: { UserId, TypeOfContent },
    })
  }, [dispatch, tokenResponse])

  useEffect(() => {
    if (ForYouSpecificContent?.result?.length === 0) {
      setLoader(false)
      setFetchSuccess(false)
    } else {
      setLoader(false)
      setFetchSuccess(true)
    }
  }, [ForYouSpecificContent])

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, {
      state: { contentDetail: contentDetail },
    })
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS,
      payload: contentDetail._id,
    })
    document.documentElement.scrollIntoView({ behavior: "smooth" })
  }

  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const renderPageNumbers = () => {
      const pageNumbers = []
      const maxVisiblePages = 5
      // for (let i = 1; i <= totalPages; i++) {
      //   pageNumbers.push(
      //     <button
      //       className={`page-numbs  ${currentPage === i ? "page-active" : ""}`}
      //       key={i}
      //       onClick={() => handlePageChange(i)}
      //       disabled={currentPage === i}
      //     >
      //       {i}
      //     </button>
      //   );
      // }

      for (let i = 1; i <= totalPages; i++) {
        // Show only a maximum of 5 pages, and add an ellipsis if more pages are available
        if (
          i <= maxVisiblePages ||
          i === totalPages ||
          i === currentPage ||
          i === currentPage - 1 ||
          i === currentPage + 1
        ) {
          pageNumbers.push(
            <button
              className={`page-numbs  ${currentPage === i ? "page-active" : ""
                }`}
              key={i}
              onClick={() => handlePageChange(i)}
              disabled={currentPage === i}
            >
              {i}
            </button>
          )
        } else if (
          i === maxVisiblePages + 1 &&
          totalPages > maxVisiblePages + 1
        ) {
          pageNumbers.push(
            <span key="ellipsis" className="ellipsis">
              ...
            </span>
          )
        }
      }
      return pageNumbers
    }

    return (
      <div className="pagination-container pagination-design">
        <button
          className="pagination-button previous-page"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;&lt;
        </button>
        {renderPageNumbers()}
        <button
          className="pagination-button next-page"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;&gt;
        </button>
      </div>
    )
  }

  return (
    <>
      {loader ? (
        <WebPageLoader />
      ) : (
        <>
          {fetchSuccess ? (
            <>
              <Container>
                <div className="recent-heading">
                  <h2 className="web_fonts content-heading  country-name">
                    For You{" "}
                  </h2>
                  <hr className="hr-line" />
                </div>
                <Row>
                  {currentItems?.map((content, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      className="mt-3 countrypage"
                    >
                      <Row>
                        <Col
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className="left-side"
                          onClick={() => handleSectionClick(content)}
                        >
                          <img
                            className="img-fluid list-img-wrap"
                            src={content?.Article_Images[0]}
                            alt={content?.Category}
                          />
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className="right-side"
                          onClick={() => handleSectionClick(content)}
                        >
                          <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                            {content?.Category[0] === 'Recent Topic' ? content?.Sub_Category[0] : content?.Category[0]}
                          </p>
                          <h4 className="web_fonts politics-title color-black">
                            {content?.Title?.length > 40
                              ? `${content?.Title?.slice(0, 40)}...`
                              : content?.Title}
                          </h4>
                          <div className="politicsnews">
                            <p className="web_fonts list-by-cat">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: content?.Story?.slice(0, 100) + (content?.Story?.length > 100 ? "..." : ""),
                                }}
                              />
                            </p>
                          </div>
                          <p className="disabled-text web_fonts">
                            by
                            <span className="name-of web_fonts" style={{ textTransform: "none" }}>
                              {content.Created_By}
                            </span>
                            {`${formatDate(content.Approved_On)}`}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
                {totalPages > 1 && (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </Container>
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  )
}

export default ForyouPageIndex
