import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip, Container, Button } from "reactstrap";
import { ToastContainer } from "react-toastify";
import TableContainer from "common/TableContainer";
import Breadcrumbs from "../../common/Breadcrumb";
import { useDispatch } from "react-redux";
import { BlogContentActions } from "store/blogContent/actions";
import AdminPageLoader from "../../common/adminPageLoader";
import CustomPagination from "../../common/CustomPagination";

export default function CreateCotentIndex() {

    document.title = "Contents | Allen Dreyfus";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { type } = useParams();

    const [filteredData, setFilteredData] = useState([]);
    var [contentList, setContentList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const { contentListByCreatorIDForApproval, contentListByCreatorIDByStatus } = useSelector(state => state.BlogContentReducer);
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { adminPageLoader } = useSelector(state => state.commonReducer);
    let creator_id = creatorTokenResponse?.result?._id;

    useEffect(() => {
        if (type === 'needApproval') {
            dispatch({
                type: BlogContentActions.GET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL,
                payload: creator_id,
            });
        } else if ((type === 'Approved') || (type === 'Rejected')) {
            dispatch({
                type: BlogContentActions.GET_CONTENT_LIST_BY_CREATOR_ID_BY_STATUS,
                payload: { Creator_Id: creator_id, Status: type },
            });
        }
    }, [creator_id, type])

    useEffect(() => {
        if (type === 'needApproval') {
            setContentList(contentListByCreatorIDForApproval?.result);
        } else if ((type === 'Approved' || type === 'Rejected')) {
            setContentList(contentListByCreatorIDByStatus?.result);
        }
    }, [type, contentListByCreatorIDForApproval, contentListByCreatorIDByStatus]);

    // useEffect(() => {
    //     dispatch({ type: BlogContentActions.GET_CONTENT_LIST_BY_CREATOR_ID_FOR_APPROVAL, payload: creator_id });
    // }, [])

    const columns = useMemo(
        () => [
            // {
            //     Header: 'Id',
            //     accessor: '_id',
            //     filterable: true,
            // },
            {
                Header: 'title',
                accessor: 'Title',
                filterable: true,
                Cell: title => {
                    return (
                        <div className="proName">
                            <ContentTitle {...title} />
                        </div>
                    )
                }
            },
            {
                Header: 'Category',
                accessor: 'Category',
                filterable: true,
            },
            {
                Header: 'Content Type',
                accessor: 'TypeOfContent',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'Status',
                filterable: true,
            },
            {
                Header: 'Submitted On',
                accessor: 'Created_On',
                filterable: true,
            },
            // {
            //     Header: 'Submitted By',
            //     accessor: 'Created_By',
            //     filterable: true,
            // },
            // {
            //     Header: 'Action',
            //     Cell: (cellProps) => {
            //         return (
            //             <UncontrolledDropdown>
            //                 <DropdownToggle tag="a" className="card-drop">
            //                     <i className="mdi mdi-dots-horizontal font-size-18"></i>
            //                 </DropdownToggle>

            //                 <DropdownMenu className="dropdown-menu-end">
            //                     <DropdownItem
            //                         onClick={() => {
            //                             const Data = cellProps.row.original;
            //                             navigate('/creator-content-detail', { state: { data: Data, type: type } })
            //                             // handleCustomerClick(customerData);

            //                         }
            //                         }
            //                     >
            //                         <i className="mdi mdi-eye font-size-16 text-primary me-1" id="edittooltip"></i>
            //                         View
            //                         <UncontrolledTooltip placement="top" target="edittooltip">
            //                             View
            //                         </UncontrolledTooltip>
            //                     </DropdownItem>
            //                 </DropdownMenu>
            //             </UncontrolledDropdown>
            //         );
            //     }
            // },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const Data = cellProps.row.original;
                                    navigate('/creator-content-detail', { state: { data: Data, type: type } });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ],
        [type]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs title="Content" breadcrumbItem={`${type === 'needApproval' ? 'need approval' : type} list`} />
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {contentList && contentList.length > 0 ? (
                                            <>
                                                <TableContainer
                                                    columns={columns}
                                                    data={contentList || []}
                                                    isGlobalFilter={true}
                                                    isAddCustList={false}
                                                    isPagination={true}
                                                    customPageSize={itemsPerPage}
                                                    tableClass="align-middle table-nowrap"
                                                    isShowingPageLength={false}
                                                    iscustomPageSizeOptions={true}
                                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                                />                                                
                                            </>
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* } */}
                        </Row>
                    </Container>
                }
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

const ContentTitle = (cell) => {
    return (cell?.value && cell?.value?.length > 25) ? `${cell?.value?.slice(0, 25)}...` : cell?.value
}

// const ContentTitle = ({ value }) => {
//     const truncatedTitle = value && value.length > 10
//         ? `${value.slice(0, 10)}...`
//         : value;

//     return <span>{truncatedTitle}</span>;
// };