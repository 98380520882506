import React from "react"
import NoDataInDB from "../../common/no-data-image.svg"
import { HTML5_FMT } from "moment"

const NoDataFound = () => {
  return (
    <div className="no-data-container">
      <div className="centered-content">
        <img src={NoDataInDB} alt="No Data Found" className="no-data-image" />
        <h1 className="no-data-message web_fonts">NO RECORDS FOUND</h1>
        {/* <h5 className="no-data-message web_fonts">We regret to inform you that there is currently no content available for display</h5> */}
      </div>
    </div>
  )
}

export default NoDataFound
