import { WithdrawActions } from "./actions"

const initialState = {
    allWithdrawRequestList: [],
    creatorWithdrawRequestByCreatorId: [],
    creatorWithdrawDetail: null,
}

export const withdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case WithdrawActions.SET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID:
            return {
                ...state,
                creatorWithdrawRequestByCreatorId: action.payload
            }
        case WithdrawActions.SET_ALL_WITHDRAW_REQUEST_LIST:
            return {
                ...state,
                allWithdrawRequestList: action.payload
            }
        case WithdrawActions.SET_WITHDRAW_REQUEST_DETAIL:
            return {
                ...state,
                creatorWithdrawDetail: action.payload
            }
        default:
            return state;
    }
}