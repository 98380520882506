import SubscriptionPlansIndex from "components/subscriptionPlans";
import React from "react";

export default function SubscriptionPlans() {

    return (
        <div>
            <SubscriptionPlansIndex />
        </div>
    )
}