// LogoutModal.js
import React from "react"
import { Modal, Button } from "react-bootstrap"

const LogoutModal = ({ show, handleClose, handleLogoutConfirmed }) => {
  return (
    <Modal show={show} onHide={handleClose}>

              <div className="modal-header">
                <h5 className="modal-title">alert</h5>
                <button
                  type="button"
                  className="close sub-alert"
                  onClick={handleClose}
                ></button>
              </div>
              <div
                className="modal-body"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  lineHeight: "3",
                }}
              >
                <h5 style={{ marginTop: "10px" }}>
                Are you sure you want to logout?
                </h5>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="login-alert"
                  onClick={handleLogoutConfirmed}
                >
                 yes
                </button>
                <button
                  type="button"
                  className="close-alert"
                  onClick={handleClose}
                >
                  no
                </button>
              </div>
            </Modal>
    // <Modal show={show} onHide={handleClose}>
    //   <Modal.Header>
    //     <Modal.Title>Are you sure you want to logout?</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={handleClose}>
    //       No
    //     </Button>
    //     <Button variant="primary" onClick={handleLogoutConfirmed}>
    //       Yes
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  )
}

export default LogoutModal
