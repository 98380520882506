export const corporateSubscriptionActions = {

    GET_ADMIN_CORPORATE_LIST: 'GET_ADMIN_CORPORATE_LIST',
    SET_ADMIN_CORPORATE_LIST: 'SET_ADMIN_CORPORATE_LIST',

    GET_ADMIN_CORPORATE_DETAIL: 'GET_ADMIN_CORPORATE_DETAIL',
    SET_ADMIN_CORPORATE_DETAIL: 'SET_ADMIN_CORPORATE_DETAIL',

    GET_WEB_ADD_CORPORATE_DETAIL : 'GET_WEB_ADD_CORPORATE_DETAIL',

    WEB_CORPORATE_RESPONSE : 'WEB_CORPORATE_RESPONSE',
}