import CustomerDetailsIndex from "components/customerDetails";
import React from "react";

export default function CustomerDetails() {

    return (
        <div>
            <CustomerDetailsIndex />
        </div>
    )
};