import { stripeActions } from './actions';
import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constant";


import { commonActions } from "../common/actions";

export const stripeSagas = function* (){
    yield all([
        yield takeEvery(stripeActions.CREATE_STRIPE_PAYMENT,createPayment),
    ])
}

//Create payment
const createPayment = function* (data){
     const { payload } = data;
    const navigate =payload.navigate
    try{
        const result = yield call(() =>
        axios.post(`${API_URL}/createPayment`)
    );
    if(result?.data?.statusCode === 200){
        window.location.href = result?.data?.checkoutURL;
    }
    }catch(err){
        console.log(err);
    }
}