import { takeEvery, all, call, put, delay } from 'redux-saga/effects'
import actions from './actions'
import axios from 'axios'
import { API_URL } from 'utils/constant'
import { commonActions } from "../common/actions";

export const adminSubscriptionSagas = function* () {
    yield all([
        yield takeEvery(actions.GET_SUBSCRIPTION_PLANS_LIST, getSubscriptionsPlansList),
        yield takeEvery(actions.ENABLE_SUBSCRIPTION_PLAN, enableSubscriptionPlan),
        yield takeEvery(actions.UPDATE_SUBSCRIPTION_PLAN, updateSubscriptionPlan),
        yield takeEvery(actions.GET_CHECKOUT_SESSION_ID, getCheckoutSessionID),
        yield takeEvery(actions.CANCEL_SUBSCRIPTION_PLAN, cancelSubscriptionPlan),
    ])
}

const getSubscriptionsPlansList = function* () {
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/subscription/list`)
        )
        yield put({
            type: actions.SET_SUBSCRIPTION_PLANS_LIST,
            payload: result?.data
        })
    } catch (err) {
        console.log(err)
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

const enableSubscriptionPlan = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/subscription/updateEnablePlan/${payload?.Id}/${payload?.Enabled}`)
        )
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: result?.data?.statusCode === 200 ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
    } catch (err) {
        console.log(err)
    }
}

const updateSubscriptionPlan = function* (data) {
    const { payload } = data

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/subscription/editPlan/${payload?.Id}`, payload?.data)
        )
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: result?.data?.statusCode === 200 ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
        yield put({
            type: actions.GET_SUBSCRIPTION_PLANS_LIST
        })

    } catch (err) {
        console.log(err)
    }

}

const getCheckoutSessionID = function* (data) {
    // yield put({ type: commonActions.SET_PAGE_LOADER, payload: true })
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/create-checkout-session`, payload)
        )
        yield put({
            type: actions.SET_CHECKOUT_SESSION_ID,
            payload: result?.data
        })
        // yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
    } catch (err) {
        console.log(err)
    }
    // yield put({ type: commonActions.SET_PAGE_LOADER, payload: false })
}

const cancelSubscriptionPlan = function* (data) {
    const { payload } = data;
    const { navigate } = payload;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/unsubscribe`, payload)
        )
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: (result?.data?.statusCode === 200) ? "success" : "failed", message: result?.data?.message }
        });
        yield delay(2000);
        if (result?.data?.statusCode === 200) {
            window.location.href = '/'
        }
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });

    } catch (err) {
        console.log(err)
    }
}