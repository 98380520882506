import React, { useEffect } from "react"
import Header from "../../webComponents/Layout/Header"
import Footer from "../../webComponents/Layout/Footer"
import Podcast from "webComponents/Podcast"
import HrBanner from "webComponents/Common/FreeTrailBannerImg"
import Banner1 from "../../assets/webImages/Horizontal banner-1.webp"
import SubscribedUserBanner from "../../assets/webImages/in sky,black.webp"
import { useDispatch, useSelector } from "react-redux"
import { WebHomePageActions } from "store/webHome/actions"

export default function PodcastPage() {
  const dispatch = useDispatch()
  const { tokenResponse } = useSelector(state => state.userAuthReducer)
  let userId = tokenResponse?.result?._id
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"
  const stripeCutomerId = tokenResponse?.result?.Stripe_Customer_Id;

  useEffect(() => {
    const payload = { userId }

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
      payload,
    })
  }, [dispatch, userId])

  return (
    <div>
      <Header />
      {isSubscribed || isTrialUsed || stripeCutomerId ? (
        <HrBanner bannerImage={SubscribedUserBanner} bannerLink="/" bannerType="subscribed" />
      ) : (
        <HrBanner bannerImage={Banner1} bannerLink="/" bannerType="freeTrial" />
      )}
      <Podcast />
      <Footer />
    </div>
  )
}
