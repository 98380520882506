import { revenueActions } from "./actions";

const initialState = {
    adminRevenueList: [],
    adminRevenueDetail: '',
};

export const revenueReducer = (state = initialState, action) => {
    switch (action.type) {
        case revenueActions.SET_ADMIN_REVENUE_LIST:
            return {
                ...state,
                adminRevenueList: action.payload,
            }
        case revenueActions.SET_ADMIN_REVENUE_DETAIL:
            return {
                ...state,
                adminRevenueDetail: action.payload,
            }
        default:
            return state;
    }
}
