import React, { useState, useEffect } from "react"
import { Container, Row, Col, Input, Button } from "reactstrap"
import AdminPageLoader from "../../common/adminPageLoader";
import { useDispatch, useSelector } from "react-redux"
import Breadcrumb from "../../common/Breadcrumb"
import { BlogContentActions } from "store/blogContent/actions"
import { WebHomePageActions } from "store/webHome/actions"
import { useForm } from "react-hook-form"

const RecentTopicindex = () => {
  const [inputValue, setInputValue] = useState("")
  const [recentError, setRecentError] = useState("")
  const { adminPageLoader } = useSelector(state => state.commonReducer)
  const [editMode, setEditMode] = useState(false)
  const dispatch = useDispatch()
  const { ContentByRecentTopic } = useSelector(
    state => state.WebHomePageReducer
  )
  const [recentTopicId, setRecentTopicId] = useState("")

  useEffect(() => {
    const firstTopicId = ContentByRecentTopic?.result?.[0]?._id
    setRecentTopicId(firstTopicId)
  }, [ContentByRecentTopic])


  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    dispatch({ type: WebHomePageActions.GET_WEBHOME_CONTENTBY_RECENT_TOPIC })
  }, [dispatch])

  useEffect(() => {
    // setFirstTopic(ContentByRecentTopic?.result?.[0]?.Topic)
    setInputValue(ContentByRecentTopic?.result?.[0]?.Topic)
  }, [ContentByRecentTopic])

  const handleSubmit = () => { 
    if (inputValue === '') {
      setRecentError('enter the recent topic heading');
      return;
    } else {
      setRecentError('');
      // Trim the input value
      const trimmedRecentTopic = inputValue.trim();
      const trimmedValue = trimmedRecentTopic;
      
      const data = {
        Id: recentTopicId,
        payload: {
          Topic: trimmedValue
        },
      };
      dispatch({
        type: BlogContentActions.GET_RECENT_TOPIC_ADD_TOPIC,
        payload: data,
      });
    }
    // Set edit mode to false
    setEditMode(false);
  };

  const handleEditClick = () => {
    setEditMode(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {adminPageLoader ? (
          <AdminPageLoader />
        ) : (
          <Container>
            <Breadcrumb
              title="Recent Topic Heading"
              titleLink="/recent-topic"
              breadcrumbItem="Details"
            />

            <Row>
              <Col md={6}>
                <form>
                  <Input
                    type="text"
                    placeholder=""
                    {...register("Recent_Topic", {
                      required: "Set your recent topic heading",
                      validate: value => value.trim() !== '' || 'enter the recent topic heading',
                      pattern: {
                        value: /^[A-Za-z\s]+$/,
                        message: "Invalid recent topic",
                      },
                    })}
                    defaultValue={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    readOnly={!editMode}
                  />
                {errors?.Recent_Topic && <p>{errors?.Recent_Topic.message}</p>}
                {recentError && <div style={{ color: "red" }}><p>{recentError}</p></div>}
                </form>
              </Col>
              <Col md={6}>
                {editMode ? (
                  <Button color="primary" onClick={handleSubmit}>
                    Change the Heading
                  </Button>
                ) : (
                  <Button color="secondary" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default RecentTopicindex
