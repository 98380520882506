import AdminDetailsIndex from "components/adminDetails";
import React from "react";

export default function AdminDetails () {

    return(
        <div>
            <AdminDetailsIndex />
        </div>
    )
}