import { all, fork } from "redux-saga/effects";

import LayoutSaga from "./layout/saga";

import { bashboardSagas } from "./dashboard/sagas";
import { userAuthSagas } from "./userAuth/sagas";
import { ContentCreatorSagas } from './contentCreator/sagas';
import { blogContentSagas } from "./blogContent/sagas";
import { adminSagas } from "./admin/sagas";
import { customerSagas } from "./customer/sagas";
import { creatorDashboardSagas } from "../store/creatorDashboard/sagas";
import { adminSubscriptionSagas } from "../store/adminSubscriptionPlans/sagas"
import { payoutSagas } from "../store/payouts/sagas";
import { revenueSagas } from "../store/revenue/sagas";
import { corporateSubscriptionSagas } from "../store/corporateSubscription/sagas";
import { webUserSignupSagas } from "./webAuth/sagas";
import { webHomeSagas } from "./webHome/sagas";
import { WebContentDeatilSagas } from "./webContentDetail/sagas";
import { webContentByCategorySagas } from "./webContentByCategory/sagas";
import { stripeSagas } from "./stripePayment/sagas";
import { WebBookMarkSagas } from "./webBookmark/sagas";
import { advertiseSagas } from "./Advertise/sagas";
import { notificationsSaga } from "./notification/sagas";
import { CountrySaga } from "./Countries/sagas";
import { WithdrawRequestSagas } from "./withdrawRequest/sagas";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(bashboardSagas),
    fork(userAuthSagas),
    fork(ContentCreatorSagas),
    fork(blogContentSagas),
    fork(adminSagas),
    fork(customerSagas),
    fork(creatorDashboardSagas),
    fork(adminSubscriptionSagas),
    fork(webUserSignupSagas),
    fork(payoutSagas),
    fork(revenueSagas),
    fork(corporateSubscriptionSagas),
    fork(webHomeSagas),
    // fork(WebContentDeatilSagas),
    fork(webContentByCategorySagas),
    fork(WebContentDeatilSagas),
    fork(stripeSagas),
    fork(WebBookMarkSagas),
    fork(advertiseSagas),
    fork(notificationsSaga),
    fork(CountrySaga),
    fork(WithdrawRequestSagas),
  ]);
}
