import { takeEvery, call, all, put, delay } from "redux-saga/effects"
import { WebContentDeatilActions } from "./actions"
import { API_URL } from "../../utils/constant"
import axios from "axios"
import { commonActions } from "../common/actions";

export const WebContentDeatilSagas = function* () {
  yield all([
    takeEvery(
      WebContentDeatilActions.GET_WEBCONTENT_DETAIL_NEWS,
      getWebContentDetailNews
    ),
    takeEvery(
      WebContentDeatilActions.GET_WEB_NEWSLETTER_EMAIL,
      getWebNewsletterEmail
    ),
    takeEvery(
      WebContentDeatilActions.UNSUBSCRIBE_NEWSLETTER,
      unSubscribeNewsletter
    ),
    takeEvery(
      WebContentDeatilActions.GET_WEB_DETAIL_PAGE_RELATED_LIST,
      getWebDetailPageRelatedList
    )
  ])
}

const getWebContentDetailNews = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/detail/${payload}`)
    )
    yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false });
    yield put({
      type: WebContentDeatilActions.SET_WEBCONTENT_DETAIL_NEWS,
      payload: result.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false });
}

const getWebNewsletterEmail = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/newsLetter/create`, payload)
    )
    yield put({
      type: WebContentDeatilActions.RESPONSE_WEB_NEWSLETTER_EMAIL,
      payload: result.data,
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    })
    if (result?.data?.statusCode === 200) {
      yield delay(4000)
      window.location.reload()
    }
  } catch (error) {
    console.error("Error", error)
  }
}

const unSubscribeNewsletter = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.delete(`${API_URL}/newsLetter/deleteNewsLetter/${payload}`)
    )
    yield put({
      type: WebContentDeatilActions.UNSUBSCRIBE_NEWSLETTER_RESPONSE,
      payload: result?.data
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    })
    if (result?.data?.statusCode === 200) {
      yield delay(4000)
      window.location.reload()
    }

  } catch (error) {
    console.error("Error", error)
  }
}

//Detail page related content list

const getWebDetailPageRelatedList = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/relatedContentList/${payload}`))
    yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false });
    yield put({
      type: WebContentDeatilActions.SET_WEB_DETAIL_PAGE_RELATED_LIST,
      payload: result.data.result,
    })
  } catch (err) {
    console.log("Error", err)
  }
}