import actions from './actions'

const initialState = {
    subscriptionPlans: {},
    getSessionId: null,
    subscriptionResponse: null,
}

export const subscriptionPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_SUBSCRIPTION_PLANS_LIST: {
            return {
                ...state,
                subscriptionPlans: action.payload
            }
        }
        case actions.SET_CHECKOUT_SESSION_ID: {
            return {
                ...state,
                getSessionId: action.payload
            }
        }
        case actions.SUBSCRIPTION_RESPONSE: {
            return {
                ...state,
                subscriptionResponse: action.payload
            }
        }
        default:
            return state
    }
}