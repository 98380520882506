import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import { actions } from '../../store/notification/actions'

import { withTranslation } from "react-i18next";

const NotificationDropdown = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [menu, setMenu] = useState(false);

  const { superAdminTokenResponse, adminTokenResponse, creatorTokenResponse } = useSelector(state => state.userAuthReducer)
  const { superAdminNotificationList, adminNotificationList, creatorNotificationList } = useSelector(state => state.NotificationReducer)
  const { buttonLoader } = useSelector(state => state.commonReducer);

  const handleBellClick = () => {
    setMenu(!menu)
    if (superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin') {
      dispatch({
        type: actions.GET_SUPER_ADMIN_NOTIFICATION_LIST
      })
    }

    if (adminTokenResponse?.Type_Of_User === 'Admin') {
      dispatch({
        type: actions.GET_ADMIN_NOTIFICATION_LIST
      })
    }

    if (creatorTokenResponse?.result?._id) {
      dispatch({
        type: actions.GET_CREATOR_NOTIFICATION_LIST,
        payload: creatorTokenResponse?.result?._id
      })
    }
  }

  useEffect(() => {
    if (superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin') {
      dispatch({
        type: actions.GET_SUPER_ADMIN_NOTIFICATION_LIST
      })
    }
  }, [superAdminTokenResponse])

  useEffect(() => {
    if (adminTokenResponse?.Type_Of_User === "Admin") {
      dispatch({
        type: actions.GET_ADMIN_NOTIFICATION_LIST
      })
    }
  }, [adminTokenResponse])
  
  useEffect(() => {
    if (creatorTokenResponse?.result?._id) {
      dispatch({
        type: actions.GET_CREATOR_NOTIFICATION_LIST,
        payload: creatorTokenResponse?.result?._id
      })
    }
  }, [creatorTokenResponse])

  const handleNotificationClick = (val) => {
    setMenu(false)
    if (val.NotificationType === 'Registration') {
      navigate('/content-creator-details', { state: { data: val, type: 'Unregistered' } });
    } else if (val.NotificationType === 'Pitch Submitted For Approval') {
      let data = {
        ...val,
        Pitch_Status: 'New'
      }
      navigate('/admin/pitch-detail', { state: { data: data, type: 'needApproval' } })
    } else if (val.NotificationType === "Content Updated By Creator") {
      navigate('/content-details', { state: { data: val, type: 'needApproval' } })
    } else if (val.NotificationType === 'Withdraw request by Creator') {
      navigate('/all-withdrawal-request-detail', { state: { _id: val.WithdrawRequestID } })
    }
    if (superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin') {
      dispatch({
        type: actions.SUPER_ADMIN_DELETE_NOTIFICATION_BY_ID,
        payload: val?._id
      })
    }
    if (adminTokenResponse?.Type_Of_User === "Admin") {
      dispatch({
        type: actions.ADMIN_DELETE_NOTIFICATION_BY_ID,
        payload: val?._id
      })
    }
  }

  const handleCreatorNotificationClick = (val) => {
    setMenu(false)
    if (val?.NotificationType === "Pitch Status Updated") {
      navigate('/pitch-detail', { state: { data: val, type: val.Pitch_Status, clickedFrom: 'ApprovedList' } })
    } else if (val?.NotificationType === "Content Status Updated") {
      navigate('/creator-content-detail', { state: { data: val, type: val?.Status } })
    } else if (val?.NotificationType === 'Withdraw Request Status Updated') {
      navigate('/withdraw-detail', { state: { _id: val.WithdrawRequestID } });
    }
    dispatch({
      type: actions.CREATOR_DELETE_NOTIFICATION_BY_ID,
      payload: {
        NotificationId: val?._id,
        CreatorId: val?.CreatorID
      }
    })
  }

  const handleClearAll = () => {
    if (superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin') {
      dispatch({
        type: actions.DELETE_ALL_SUPER_ADMIN_NOTIFICATIONS
      })
    } else if (creatorTokenResponse?.result?.Type_Of_User === 'Content Creator') {
      dispatch({
        type: actions.DELETE_ALL_CREATOR_NOTIFICATIONS,
        payload: creatorTokenResponse?.result?._id
      })
    } else if (adminTokenResponse?.Type_Of_User === "Admin") {
      dispatch({
        type: actions.DELETE_ALL_ADMIN_NOTIFICATIONS,
      })
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => handleBellClick()}
        className="dropdown d-inline-block "
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative dash-board-button"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin' &&
            <span className="badge bg-danger rounded-pill">{superAdminNotificationList?.result?.length}</span>
          }
          {adminTokenResponse?.Type_Of_User === 'Admin' &&
            <span className="badge bg-danger rounded-pill">{adminNotificationList?.result?.length}</span>
          }
          {creatorTokenResponse?.result?.Type_Of_User === 'Content Creator' &&
            <span className="badge bg-danger rounded-pill">{creatorNotificationList?.result?.length}</span>
          }
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">

          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {((superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin' && (superAdminNotificationList?.result?.length > 0)) ||
                ((adminTokenResponse?.Type_Of_User === 'Admin' && (adminNotificationList?.result?.length > 0))) ||
                ((creatorTokenResponse?.result?.Type_Of_User === 'Content Creator') && (creatorNotificationList?.result?.length > 0))) &&
                <div className="col-auto" onClick={() => handleClearAll()}>
                  <a href="#" className="small">
                    {" "}
                    clear All
                  </a>
                </div>
              }

            </Row>
          </div>
          {/* {superAdminNotificationList?.result?.map((val,index) => 
            <p key={index}>{val.For_Display}</p>
          )} */}

          {buttonLoader ?
            <div className="text-center p-5" style={{ height: "230px" }}>
              <Spinner size="md" className="text-primary" />

            </div>
            :
            <SimpleBar style={{ height: "230px" }}>
              {superAdminTokenResponse?.result?.Type_Of_User === 'Super Admin' &&
                <>
                  {(superAdminNotificationList?.statusCode === 200 && superAdminNotificationList.result?.length > 0) ?
                    <><div className="text-reset notification-item" style={{ "cursor": "pointer" }}>
                      {/* <Link to="/content-creator-details" className="text-reset notification-item"> */}
                      {superAdminNotificationList?.result?.map((val, index) =>
                        <div key={index} onClick={() => handleNotificationClick(val)}>
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              {val?.NotificationType === 'Registration' ?
                                <span className="avatar-title bg-primary rounded-circle1 font-size-18">
                                  <i className="mdi mdi-account-reactivate" />
                                </span> :
                                <span className="avatar-title bg-success rounded-circle2 font-size-16">
                                  <i className="mdi mdi-check-bold" />
                                </span>
                              }

                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mt-0 mb-1">
                                {/* {props.t("Need Approval")} */}
                                {props.t(val.For_Display)}
                              </h6>
                              <div className="font-size-10 text-muted">
                                {/* <p className="mb-1">
                                  {props.t(
                                    "Approval needed for Content creator Registration"
                                  )}
                                </p> */}
                                {
                                  val?.TimeDifference !== "NaN minute" ? <p className="mb-0">
                                    <i className="mdi mdi-clock-outline" />{" "}
                                    {props.t(`${val.TimeDifference} ago`)}
                                  </p>
                                    :
                                    <></>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                      {/* </Link> */}
                      {/* <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="mdi mdi-account-reactivate" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Windrawal request")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Received request from Content Creator(CONCRE004)"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("1 hour ago")}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-success rounded-circle font-size-16">
                            <i className="mdi mdi-check-bold" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Need Approval")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Need approval for Article submitted by the CONCRE002"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("3 hours ago")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-success rounded-circle font-size-16">
                            <i className="mdi mdi-check-bold" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Need Approval for update")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Need Approval for update"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("1 day ago")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link> */}
                    </> : <p className="text-primary text-center m-5">-- No new notifications --</p>
                  }
                </>
              }

              {adminTokenResponse?.Type_Of_User === 'Admin' &&
                <>
                  {(adminTokenResponse?.statusCode === 200 && adminNotificationList?.result?.length > 0) ?
                    <><div className="text-reset notification-item" style={{ "cursor": "pointer" }}>
                      {/* <Link to="/content-creator-details" className="text-reset notification-item"> */}
                      {adminNotificationList?.result?.map((val, index) =>
                        <div key={index} onClick={() => handleNotificationClick(val)}>
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              {val?.NotificationType === 'Registration' ?
                                <span className="avatar-title bg-primary rounded-circle1 font-size-18">
                                  <i className="mdi mdi-account-reactivate" />
                                </span> :
                                <span className="avatar-title bg-success rounded-circle2 font-size-16">
                                  <i className="mdi mdi-check-bold" />
                                </span>
                              }

                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mt-0 mb-1">
                                {/* {props.t("Need Approval")} */}
                                {props.t(val.For_Display)}
                              </h6>
                              <div className="font-size-10 text-muted">
                                {/* <p className="mb-1">
                                  {props.t(
                                    "Approval needed for Content creator Registration"
                                  )}
                                </p> */}
                                {
                                  val?.TimeDifference !== "NaN minute" ? <p className="mb-0">
                                    <i className="mdi mdi-clock-outline" />{" "}
                                    {props.t(`${val.TimeDifference} ago`)}
                                  </p>
                                    :
                                    <></>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                      {/* </Link> */}
                      {/* <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="mdi mdi-account-reactivate" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Windrawal request")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Received request from Content Creator(CONCRE004)"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("1 hour ago")}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-success rounded-circle font-size-16">
                            <i className="mdi mdi-check-bold" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Need Approval")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Need approval for Article submitted by the CONCRE002"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("3 hours ago")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-success rounded-circle font-size-16">
                            <i className="mdi mdi-check-bold" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Need Approval for update")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Need Approval for update"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("1 day ago")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link> */}
                    </> : <p className="text-primary text-center m-5">-- No new notifications --</p>
                  }
                </>
              }

              {creatorTokenResponse?.result?.Type_Of_User === 'Content Creator' &&
                <>
                  {(creatorNotificationList?.statusCode === 200 && creatorNotificationList?.result?.length > 0) ?
                    <><div className="text-reset notification-item" style={{ "cursor": "pointer" }}>
                      {/* <Link to="/content-creator-details" className="text-reset notification-item"> */}
                      {creatorNotificationList?.result?.map((val, index) =>
                        <div key={index} onClick={() => handleCreatorNotificationClick(val)}>
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              {/* <span className="avatar-title bg-primary rounded-circle1 font-size-18">
                                <i className="mdi mdi-account-reactivate" />
                              </span> : */}
                              {val?.Status === 'Rejected' || val?.Pitch_Status === 'Rejected' ?
                                <span className="avatar-title bg-danger rounded-circle2 font-size-16">
                                  <i className="mdi mdi-close-thick" />
                                </span>
                                :
                                <span className="avatar-title bg-success rounded-circle2 font-size-16">
                                  <i className="mdi mdi-check-bold" />
                                </span>}
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mt-0 mb-1">
                                {/* {props.t("Need Approval")} */}
                                {props.t(val.For_Display)}
                              </h6>
                              <div className="font-size-10 text-muted">
                                {/* <p className="mb-1">
                                  {props.t(
                                    "Approval needed for Content creator Registration"
                                  )}
                                </p> */}
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline" />{" "}
                                  {props.t(`${val.TimeDifference} ago`)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                      {/* </Link> */}
                      {/* <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="mdi mdi-account-reactivate" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Windrawal request")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Received request from Content Creator(CONCRE004)"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("1 hour ago")}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-success rounded-circle font-size-16">
                            <i className="mdi mdi-check-bold" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Need Approval")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Need approval for Article submitted by the CONCRE002"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("3 hours ago")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="" className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-success rounded-circle font-size-16">
                            <i className="mdi mdi-check-bold" />
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">
                            {props.t("Need Approval for update")}
                          </h6>
                          <div className="font-size-10 text-muted">
                            <p className="mb-1">
                              {props.t(
                                "Need Approval for update"
                              )}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              {props.t("1 day ago")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link> */}
                    </> : <p className="text-primary text-center m-5">-- No new notifications --</p>
                  }
                </>
              }

              {/* old code backup */}
              {/* {creatorTokenResponse?.result?.Type_Of_User === 'Content Creator' &&
              <>
                <Link to="" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="mdi mdi-check-bold" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {props.t("Approved")}
                      </h6>
                      <div className="font-size-10 text-muted">
                        <p className="mb-1">
                          {props.t(
                            "Content Approved by Admin"
                          )}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {props.t("1 day ago")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="mdi mdi-check-bold" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {props.t("Approved")}
                      </h6>
                      <div className="font-size-10 text-muted">
                        <p className="mb-1">
                          {props.t(
                            "Content Approved by Admin"
                          )}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {props.t("2 days ago")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </>

            } */}
            </SimpleBar>
          }
          <div className="p-3">
            {/* <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link> */}
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};