import { SignUpActions } from "./actions";
import { RecentStoriesActions } from "./actions";

const initialState = {

  userResponse: null,
};

export const SignUpReducer = (state = initialState, action) => {

  switch (action.type) {


    case SignUpActions.USER_RESPONSE:
      return {
        ...state,
        userResponse: action.payload,
      };

    default:
      return state;
  }
};





