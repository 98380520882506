import React, { useMemo, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "../../store/blogContent/actions";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../common/Breadcrumb";
import TableContainer from '../../common/TableContainer';
import AdminPageLoader from "../../common/adminPageLoader";
import CustomPagination from "../../common/CustomPagination";

export default function creatorPitchList() {
    document.title = "Pitch List | Allen Dreyfus";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { type } = useParams();

    var [pitchList, setPitchList] = useState([])

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { pitchListByCreatorIDForApproval, creatorPitchListByStatus } = useSelector(state => state.BlogContentReducer);
    const { adminPageLoader } = useSelector(state => state.commonReducer);
    let creator_id = creatorTokenResponse?.result?._id;


    useEffect(() => {
        if (type === 'needApproval') {
            dispatch({
                type: BlogContentActions.GET_PITCH_LIST_BY_CREATOR_ID_FOR_NEED_APPROVAL,
                payload: creator_id,
            });
        } else if ((type === 'Approved')) {
            dispatch({
                type: BlogContentActions.GET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS,
                payload: { Creator_Id: creator_id, Pitch_Status: type },
            });
        } else if ((type === 'Rejected')) {
            dispatch({
                type: BlogContentActions.GET_PITCH_LIST_BY_CREATOR_ID_BY_STATUS,
                payload: { Creator_Id: creator_id, Pitch_Status: type },
            });
        } else {

        }
    }, [creator_id, type])

    useEffect(() => {
        if (type === 'needApproval' && pitchListByCreatorIDForApproval?.result?.length !== 0) {
            setPitchList(pitchListByCreatorIDForApproval?.result);
        } else if ((type === 'Approved') && creatorPitchListByStatus?.result?.length !== 0) {
            setPitchList(creatorPitchListByStatus?.result);
        } else if ((type === 'Rejected') && creatorPitchListByStatus?.result?.length !== 0) {
            setPitchList(creatorPitchListByStatus?.result);
        } else {

        }
    }, [type, pitchListByCreatorIDForApproval, creatorPitchListByStatus]);

    const columns = useMemo(
        () => [
            // {
            //     Header: 'Id',
            //     accessor: '_id',
            //     filterable: true,
            // },

            {
                Header: 'title',
                accessor: 'Title',
                filterable: true,
                Cell: title => {
                    return (
                        <div className="proName">
                            <ContentTitle {...title} />
                        </div>
                    )
                }
            },
            {
                Header: 'Category',
                accessor: 'Category',
                filterable: true,
            },
            {
                Header: 'Content Type',
                accessor: 'TypeOfContent',
                filterable: true,
            },
            {
                Header: 'pitch status',
                accessor: 'Pitch_Status',
                filterable: true,
            },
            {
                Header: 'submitted on',
                accessor: 'Pitch_Created_On',
                filterable: true,
            },
            // {
            //     Header: 'action',
            //     Cell: (cellProps) => {
            //         return (
            //             <UncontrolledDropdown>
            //                 <DropdownToggle tag="a" className="card-drop">
            //                     <i className="mdi mdi-dots-horizontal font-size-18"></i>
            //                 </DropdownToggle>

            //                 <DropdownMenu className="dropdown-menu-end">
            //                     <DropdownItem
            //                         onClick={() => {
            //                             const Data = cellProps.row.original;
            //                             navigate('/pitch-detail', { state: { data: Data, type: type, clickedFrom: 'ApprovedList' } })

            //                         }
            //                         }
            //                     >
            //                         <i className="mdi mdi-eye font-size-16 text-primary me-1" id="edittooltip"></i>
            //                         View
            //                         <UncontrolledTooltip placement="top" target="edittooltip">
            //                             View
            //                         </UncontrolledTooltip>
            //                     </DropdownItem>
            //                 </DropdownMenu>
            //             </UncontrolledDropdown>
            //         );
            //     }
            // },
            {
                Header: 'action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const Data = cellProps.row.original;
                                    navigate('/pitch-detail', { state: { data: Data, type: type, clickedFrom: 'ApprovedList' } });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs className="small-letter" title="pitch" breadcrumbItem={`${type === 'needApproval' ? 'need approval' : type} list`} />
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {pitchList && pitchList?.length > 0 ? (
                                            <>
                                                <TableContainer
                                                    columns={columns}
                                                    data={pitchList || []}
                                                    isGlobalFilter={true}
                                                    isAddCustList={false}
                                                    isPagination={true}
                                                    customPageSize={itemsPerPage}
                                                    tableClass="align-middle table-nowrap"
                                                    isShowingPageLength={false}
                                                    iscustomPageSizeOptions={true}
                                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                                />
                                            </>
                                        ) : (
                                            <div className="no-records">No records found</div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

const ContentTitle = (cell) => {
    return (cell?.value && cell?.value?.length > 25) ? `${cell?.value?.slice(0, 25)}...` : cell?.value
}

// const ContentTitle = ({ value }) => {
//     const truncatedTitle = value && value.length > 10
//         ? `${value.slice(0, 10)}...`
//         : value;

//     return <span>{truncatedTitle}</span>;
// };



