import Countrylistindex from "components/countrylist";
import React from "react";

export default function Countrylist () {

    return (
        <div>
            <Countrylistindex />
        </div>
    )
}