import CorporateSubscriptionIndex from "components/corporateSubsciption/index";
import React from "react";

export default function CorporateSubscribe () {

    return (
        <div>
            <CorporateSubscriptionIndex />
        </div>
    )
}