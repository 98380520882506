import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  Label,
  Row,
  Col,
  Table,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { BlogContentActions } from "../../store/blogContent/actions"
import { commonActions } from "../../store/common/actions"
import Breadcrumb from "common/Breadcrumb"
import { useFormik } from "formik"
import Multiselect from "multiselect-react-dropdown"
import * as Yup from "yup"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw } from "draft-js"
import { convertToHTML } from "draft-convert"
import success_img from "../../assets/images/dashboard/success1-popup-image.gif"
import failure_img from "../../assets/images/dashboard/failure-popup-image.gif"
import FileSizeValidation from '../../FileSizeValidation/index'
import RichTextEditor from '../../common/QuillRichTextEditor';
import { actions } from "../../store/Countries/actions"

export default function creatorPitchEditDetails() {
  //content creation page
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const fileInputRef = React.createRef()
  const imageRef = useRef()
  const thumbref = useRef()
  const audioref = useRef()

  var [typeOfContent, setTypeOfContent] = useState(state?.TypeOfContent)
  var [title, setTitle] = useState(state?.Title)
  var [Country, setCountry] = useState(state?.Country?.join(", "))
  var [Category, setCategory] = useState(state?.Category)
  var [SubCategory, setSubCategory] = useState(state?.Sub_Category?.join(", "))
  var [pitchStory, setPitchStory] = useState(state?.Pitch_story)
  var [Impacts, setImpacts] = useState(state?.Impacts)
  const [storyContent, setStoryContent] = useState('');

  const [countryOptions, setCountryOptions] = useState([])
  const [countryError, setCountryError] = useState("")

  const flattenedCountry = state?.Country?.flat()

  //for brief description
  const [briefEditorState, setBriefEditorState] = useState(
    EditorState.createEmpty()
  )

  const [briefConvertedContent, setBriefConvertedContent] = useState(null)
  const [descError, setDescError] = useState("")
  const [duration, setDuration] = useState("")

  var [selectedImage, setSelectedImage] = useState([])
  var [fileError, setFileError] = useState("")

  const [selectedAudio, setSelectedAudio] = useState(null)
  const [audioFile, setAudioFile] = useState("")
  const [audioFileError, setAudioFileError] = useState("")

  const [selectedVideo, setSelectedVideo] = useState(null)
  const [videoFile, setVideoFile] = useState("")
  const [videoFileError, setVideoFileError] = useState("")

  const [selectThumbNail, setSelectedThumbNail] = useState(null)
  const [thumbFile, setThumbFile] = useState("")
  const [thumbError, setThumbError] = useState("")

  const [durationError, setDurationError] = useState("")

  let email = state?.Created_By
  let creator_id = state?.Content_Creator_Id
  let blog_id = state?._id
  let pitch_status = state?.Pitch_Status

  const { buttonLoader } = useSelector(state => state.commonReducer)
  const { blogContentResponse } = useSelector(state => state.BlogContentReducer)
  const { creatorTokenResponse } = useSelector(state => state.userAuthReducer)

  const [showCreationPopup, setCreationPopup] = useState({
    isOpen: false,
    statusCode: null,
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      Title: state?.Title || "",
      TypeOfContent: state?.TypeOfContent || "",
      Country: flattenedCountry || [],
      Category: state?.Category || "",
      Sub_Category: state?.Sub_Category || [],
      Impacts: state?.Impacts || "",
    },
    validationSchema: Yup.object({
      Title: Yup.string().required("title is required"),
      TypeOfContent: Yup.string().required("type of content is required"),
    }),
    onSubmit: data => {
      // if (
      //   functionResponse === true ||
      //   (state?.TypeOfContent === "Article" && selectedImage?.length === 0) ||
      //   (state?.TypeOfContent === "Podcast" && !selectedAudio) ||
      //   (state?.TypeOfContent === "Video" && !selectedVideo) ||
      //   ((state?.TypeOfContent === "Video" ||
      //     state?.TypeOfContent === "Audio") &&
      //     !selectThumbNail)
      // ) {
      //   if (selectedImage?.length === 0) {
      //     setFileError("article image is required")
      //   }
      //   if (!selectedVideo) {
      //     setVideoFileError("video file is required")
      //   }

      if (state?.TypeOfContent === "Podcast" && !selectedAudio) {
        setAudioFileError("podcast file is required")
      } else if (state?.TypeOfContent === "Podcast" && !selectThumbNail) {
        setThumbError("thumb-nail image is required")
      } else if (state?.TypeOfContent === "Podcast" && !duration) {
        setDurationError("duration for the podcast is required")
      } else {
        if (storyContent === '') {
          setDescError("story is required")
        } else {
          // formData.append("Story", briefConvertedContent)        
          setDescError("")
          const formData = new FormData()
          // formData.append("Category", Category)
          // formData.append("Sub_Category", JSON.stringify(data.Sub_Category))        
          if (state?.TypeOfContent === "Article") {
            if (selectedImage?.length > 0) {
              for (let i = 0; i < selectedImage?.length; i++) {
                formData.append("Article_Images", selectedImage[i].file)
              }
            }
          }
          if (state?.TypeOfContent === "Podcast") {
            formData.append("Podcast", audioFile)
          }
          // if (state?.TypeOfContent === "Video") {
          //   formData.append("Video", videoFile)
          // }
          if (
            state?.TypeOfContent === "Podcast" ||
            state?.TypeOfContent === "Video"
          ) {
            formData.append("Thumbnail_Image", thumbFile)
            formData.append("Duration", duration)
          }
          let Content_Updated_By = {
            Name: creatorTokenResponse?.result?.Name,
            Email: creatorTokenResponse?.result?.Email,
            Type_Of_User: creatorTokenResponse?.result?.Type_Of_User
          }
          formData.append("Content_Updated_By", JSON.stringify(Content_Updated_By))
          formData.append("Story", storyContent)
          formData.append("Impacts", data.Impacts)
          formData.append("Created_By", email)
          formData.append("Content_Creator_Id", creator_id)
          formData.append("Pitch_Status", pitch_status)
          formData.append("Blog_Id", blog_id)
          formData.append("Status", "New")
          // formData.append("TypeOfContent", "Article")
          formData.append("TypeOfContent", state?.TypeOfContent)

          // for (var pair of formData.entries()) { //to console formdata
          //   console.log(pair[0] + ', ' + pair[1]);
          // }
          
          // dispatch
          // _____
          dispatch({
            type: BlogContentActions.CREATE_BLOG_CONTENT,
            payload: { data: formData },
          })
          // _____
        }
      }
    }
  })


  useEffect(() => {
    dispatch({ type: BlogContentActions.GET_RECENT_TOPIC_LIST })
    dispatch({ type: actions.GET_COUNTRY_LIST })
  }, []) 

  // Image
  const handleImageChange = event => {
    if (event.target.files?.length !== 0) {
      const file = event.target.files[0];
      let result = FileSizeValidation([file]);
      if (!result || result.Validation === "false") {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
        imageRef.current.value = null;
        return;
      }

      if (selectedImage.length > 0) {
        setSelectedImage([{
          file: file,
          previewUrl: URL.createObjectURL(file),
        }]);
      } else {
        setSelectedImage([{
          file: file,
          previewUrl: URL.createObjectURL(file),
        }]);
      }
    }
  };

  const handleRemoveImage = index => {
    setSelectedImage(prevImages => {
      const updatedImages = [...prevImages]
      updatedImages.splice(index, 1)
      imageRef.current.value = null;
      // if (updatedImages.length === 0) {
      //   setFileError("article image is required")
      //   setSelectedImage([])
      //   imageRef.current.value = null
      // } else {
      //   setFileError("")
      // }
      return updatedImages
    })
  }

  // Audio
  const handleAudioChange = event => {
    const file = event.target.files[0]
    setAudioFileError("")
    setSelectedAudio(URL.createObjectURL(file))
    setAudioFile(file)
    // if (file) {
    //   let result = FileSizeValidation(event.target.files);
    //   if (result.Validation === "false") {
    //     dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
    //     audioref.current.value = null

    //   } else {
    //     if (result?.FinalImages[0]?.type === 'audio/mpeg') {
    //       setSelectedAudio(URL.createObjectURL(file))
    //       setAudioFile(file)
    //     } else {
    //       dispatch({
    //         type: commonActions.SET_ALERT,
    //         payload: { show: true, status: "failed", message: "Unsupported File Format" }
    //       })
    //       audioref.current.value = null
    //     }
    //   }
    // } else {
    //   setAudioFileError("podcast file is required")
    // }
  }

  const handleClearAudio = () => {
    setSelectedAudio(null);
    if (audioref.current) {
      audioref.current.value = '';
    }
  }

  // video
  const handleVideoChange = event => {
    const file = event.target.files[0]
    setVideoFileError("")
    if (file) {
      setSelectedVideo(URL.createObjectURL(file))
      setVideoFile(file)
    } else {
      setVideoFileError("video file is required")
    }
  }

  // Thumbnail Image
  const handleThumbNailImage = event => {
    const thumbFile = event.target.files[0]
    setThumbError("")
    if (thumbFile) {
      let result = FileSizeValidation(event.target.files);
      if (result.Validation === "false") {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
        thumbref.current.value = null

      } else {
        if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
          result?.FinalImages[0]?.type === 'image/png') {
          setSelectedThumbNail(URL.createObjectURL(thumbFile))
          setThumbFile(thumbFile)
        } else {
          dispatch({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: "failed", message: "Unsupported File Format" }
          })
          thumbref.current.value = null
        }
      }
    } else {
      setThumbError("thumbnail image is required")
    }
  }

  const handleRemoveThumbnail = () => {
    setSelectedThumbNail("") // Clear the thumbnail image state
    setThumbFile("")
    if (thumbref.current) {
      thumbref.current.value = ""; // Reset the value of the input element
    }
  };

  // Duration
  const handleDuration = e => {
    if (e.target.value === null) {
      setDurationError("duration for the uploaded file is required")
    } else {
      setDuration(e.target.value)
      setDurationError("")
    }
  }

  // modal close
  const handleClose = () => {
    setCreationPopup({ isOpen: false, statusCode: null })
    navigate("/content-creation")
  }

  useEffect(() => {
    if (blogContentResponse && blogContentResponse?.statusCode === 200) {
      setCreationPopup({ isOpen: true, statusCode: 200 })
      validation.resetForm()
      setStoryContent('')
    } else if (blogContentResponse && blogContentResponse?.statusCode === 400) {
      setCreationPopup({ isOpen: false, statusCode: 400 })
    }
  }, [blogContentResponse])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Pitch"
            titleLink="/pitch"
            breadcrumbItem="Edit Pitch"
          />
          <Form
            className="form-horizontal mt-2"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Content Creation</CardTitle>
                    <Row>
                      <Col md="6" className="mb-4">
                        <div className="form-group">
                          <Label className="form-label">Type of Content</Label>
                          <Input
                            name="TypeOFContent"
                            className="form-control"
                            placeholder="Enter Type of Content"
                            type="text"
                            defaultValue={typeOfContent}
                            disabled
                          />
                        </div>
                      </Col>

                      <Col md="6" className="mb-4">
                        <div className="form-group">
                          <Label className="form-label">Market Focus</Label>
                          <Input
                            name="Country"
                            className="form-control"
                            placeholder="Enter Type of Content"
                            type="text"
                            defaultValue={Country}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>

                    <Col md="12" className="mb-4">
                      <div className="form-group">
                        <Label className="form-label">Title</Label>
                        <Input
                          name="Title"
                          className="form-control"
                          placeholder="Enter Title"
                          type="text"
                          defaultValue={title}
                          disabled
                        />
                      </div>
                    </Col>
                    <Row>
                      <Col
                        className="mb-4"
                        md={
                          state?.Category[0] === "Frontier Markets" ||
                            state?.Category[0] === "Recent Topic"
                            ? "6"
                            : "12"
                        }
                      >
                        <div className="form-group">
                          <Label className="form-label">Category</Label>
                          <Input
                            name="Category"
                            className="form-control"
                            placeholder="Enter Category"
                            type="text"
                            defaultValue={Category}
                            disabled
                          />
                        </div>
                      </Col>

                      <Col md="6" className="mb-4">
                        {state?.Sub_Category?.length !== 0 && (
                          <div className="form-group">
                            <Label className="form-label">
                              {state?.Category[0]}
                            </Label>
                            <Input
                              name="Category"
                              className="form-control"
                              placeholder="Enter Category"
                              type="text"
                              defaultValue={SubCategory}
                              disabled
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Col md="12" className="mb-4">
                      <div className="form-group">
                        <Label className="form-label">Pitch story</Label>
                        <div className="form-control form-control-textarea storyfont">
                          <div
                            className="clamp-text"
                            dangerouslySetInnerHTML={{ __html: pitchStory }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col className="mb-4" md="12">
                      <Label htmlFor="title">What is the Story?</Label>
                      <span className="text-danger fw-bold">*</span>
                      <div className="textEditorStyle" style={{ textDecoration: 'none' }}>
                        <RichTextEditor setStoryContent={setStoryContent} storyContent={storyContent} />
                        {/* <div dangerouslySetInnerHTML={{ __html: storyContent }} /> */}
                      </div>
                      {descError && (
                        <div style={{ color: "red" }}>{descError}</div>
                      )}
                    </Col>

                    <Col md="12" className="mb-4">
                      <div className="form-group">
                        <Label className="form-label">Impact on Markets</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter User Name"
                          type="textarea"
                          rows={5}
                          defaultValue={Impacts}
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md="12">
                      {state?.TypeOfContent === "Article" ? (
                        <>
                          <Label className="form-label">Upload File
                          </Label>
                          <span className="text-danger fw-bold">*</span>
                          <span><small className="text-success"><i> (Supported Image formats : .jpeg, .jpg, .png)</i></small></span>
                          {selectedImage && selectedImage.length > 0 && (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "15px",
                              }}
                            >
                              {selectedImage.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    marginBottom: "10px",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    src={image.previewUrl}
                                    alt={`image-${index}`}
                                    style={{
                                      maxWidth: "150px",
                                      maxHeight: "150px",
                                      marginBottom: "5px",
                                    }}
                                  />
                                  <button
                                    className="removeButton"
                                    type="button"
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    X
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                          <Input
                            type="file"
                            name="image"
                            innerRef={imageRef}
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={handleImageChange} // Uncomment if needed
                            multiple={false}
                          />
                          {fileError && (
                            <div style={{ color: "red" }}>{fileError}</div>
                          )}
                        </>) :
                        <></>}
                    </Col>

                    {state?.TypeOfContent === "Podcast" ? (
                      <>
                        <Col md="12" >
                          <div>
                            <Label className="form-label">Upload File</Label>
                            <span className="text-danger fw-bold">*</span>
                            <span><small className="text-success"><i> (Supported Audio format: .mp3, .wav)</i></small></span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {selectedAudio ? (
                                <div style={{ marginRight: '10px' }}>
                                  <audio controls>
                                    <source src={selectedAudio} type="audio/mpeg" />
                                  </audio>
                                </div>
                              ) : (
                                <>
                                  <div className="mb-3">
                                    <div><Input
                                      type="file"
                                      name="podcast"
                                      innerRef={audioref}
                                      accept="audio/mpeg, audio/wav"
                                      onChange={handleAudioChange}
                                      multiple={false}
                                    />
                                    </div>
                                    <div style={{ color: "green" }}>Upload Podcast File</div>
                                  </div>
                                </>
                              )}

                              {selectedAudio && (
                                <button className="clear-audio" onClick={handleClearAudio}>Clear Audio File</button>
                              )}
                            </div>
                            {audioFileError && (
                              <div className="mb-4" style={{ color: "red" }}>{audioFileError}</div>
                            )}

                          </div>
                        </Col>
                      </>
                    ) : state?.TypeOfContent === "Video" ? (
                      <>
                        <Label className="form-label">Upload File</Label>
                        <span className="text-danger fw-bold">*</span>
                        <Input
                          type="file"
                          name="video"
                          accept="video/mp4, video/mov"
                          onChange={handleVideoChange} // Uncomment if needed
                          multiple={false}
                        />
                        <div style={{ color: "green" }}>
                          Upload Video File
                        </div>
                        {videoFileError && (
                          <div style={{ color: "red" }}>{videoFileError}</div>
                        )}
                        {selectedVideo ? (
                          <video controls={true}>
                            <source src={selectedVideo} type="video/mp4" />
                          </video>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <Col className="mb-4" md="12">
                      {state?.TypeOfContent === "Podcast" ||
                        state?.TypeOfContent === "Video" ? (
                        <>
                          <Col className="mb-4" md="12">
                            <div>
                              <Label className="form-label">
                                Upload Thumbnail Image
                              </Label>
                              <span className="text-danger fw-bold">*</span>
                              <span><small className="text-success"><i> (Supported Image formats : .jpeg, .jpg, .png)</i></small></span>
                              {selectThumbNail && (
                                <div className="mb-4">
                                  <img
                                    className="profile-pic"
                                    src={selectThumbNail}
                                    alt="Selected"
                                    style={{
                                      padding: "10px",
                                      maxWidth: "50%",
                                      maxHeight: "150px",
                                    }}
                                  />
                                  <button
                                    className="remove-button-thumb-creator"
                                    type="button"
                                    onClick={handleRemoveThumbnail}
                                  >
                                    X
                                  </button>
                                </div>
                              )}
                            </div>

                            <Input
                              type="file"
                              name="newThumbnailImage"
                              accept="image/png, image/jpeg,image/jpg"
                              innerRef={thumbref}
                              onChange={handleThumbNailImage} // Uncomment if needed
                              multiple={false}
                            />
                            <div style={{ color: "green" }}>
                              Upload Thumbnail Image
                            </div>
                            {thumbError && (
                              <div style={{ color: "red" }}>{thumbError}</div>
                            )}
                          </Col>

                          <Label htmlFor="Title" className="form-label">
                            Duration
                          </Label>
                          <span className="text-danger fw-bold">*</span>
                          <Input
                            name="Duration"
                            type="text"
                            onChange={e => handleDuration(e)}
                            onBlur={validation.handleBlur}
                            // value={validation.values.Duration || ""}
                            value={duration}
                          />
                          {durationError && (
                            <div style={{ color: "red" }}>{durationError}</div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>

                    <button className="btn btn-primary btn-block" type="submit">
                      {buttonLoader ? (
                        <Spinner color="light" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>

      <Modal
        isOpen={showCreationPopup?.isOpen}
        toggle={handleClose}
        centered={true}
      >
        <ModalHeader centered={true} toggle={handleClose} tag="h4">
          <h4>Alert</h4>
        </ModalHeader>
        <ModalBody centered={true}>
          <Form
            onSubmit={e => {
              e.preventDefault()
              // validation.handleSubmit();
              return false
            }}
          >
            {showCreationPopup?.statusCode === 200 ? (
              <Row>
                <Col md={12} className="text-center">
                  <img
                    src={success_img}
                    alt="success_popup"
                    className="popup-success"
                  />
                  <h5>Your Content is submitted for Approval.</h5>
                </Col>
                <hr />

                <Col md={12}>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-danger save-customer me-2"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
