import AdminWithdrawRequestDetailIndex from "../components/adminWithdrawRequestDetail/index";
import React from "react";

export default function AdminWithdrawRequestDetailPage() {

    return (
        <div>
            <AdminWithdrawRequestDetailIndex />
        </div>
    )
}