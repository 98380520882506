import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { customerActions } from "../../store/customer/actions"
import TableContainer from "../../common/TableContainer"
import Breadcrumbs from "../../common/Breadcrumb"
import AdminPageLoader from "../../common/adminPageLoader";
import { revenueActions } from "../../store/revenue/actions"
import { FaPoundSign } from "react-icons/fa"
import { CSVLink } from "react-csv"
import { Button } from "react-bootstrap"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from "../../common/CustomPagination";

export default function RevenueIndex() {
  document.title = "Revenue | Allen Dreyfus"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [exportStatus, setExportStatus] = useState(null);
  const [revenue, setRevenue] = useState([])

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const { adminPageLoader } = useSelector(state => state.commonReducer)
  const { adminRevenueList } = useSelector(state => state.revenueReducer)

  useEffect(() => {
    dispatch({
      type: revenueActions.GET_ADMIN_REVENUE_LIST,
    })
  }, [dispatch])

  useEffect(() => {
    if (adminRevenueList?.result?.length !== 0) {
      setRevenue(adminRevenueList?.result)
    }
  }, [adminRevenueList?.result])

  const csvHeaders = [
    { label: "Customer Name", key: "Customer_Name" },
    { label: "Subscription Plan", key: "Subscription_Plan" },
    { label: "Subscription Price", key: "Subscription_Price" },
    { label: "Subscribed On", key: "Subscription_On" },
  ]

  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "Customer_Name",
        filterable: true,
      },
      {
        Header: "Subscription Plan",
        accessor: "Subscription_Plan",
        filterable: true,
      },
      {
        Header: "Subscription Price",
        accessor: "Subscription_Price",
        filterable: true,
        Cell: ({ value }) => (
          <>
            <FaPoundSign /> {value}
          </>
        ),
      },
      {
        Header: "Subscribed On",
        accessor: "Subscription_On",
        filterable: true,
      },
      //   {
      //     Header: 'Action',
      //     Cell: (cellProps) => {
      //         return (
      //             <div>
      //                 <Button
      //                     type="button"
      //                     color="primary"
      //                     className="btn-sm btn-rounded"
      //                     onClick={() => {
      //                         const revenueData = cellProps.row.original;
      //                         navigate("/revenue-detail", { state: revenueData });
      //                     }}
      //                 >
      //                     View
      //                 </Button>
      //             </div>
      //         );
      //     }
      // },
    ],
    []
  )

  const handleSuccess = () => {
    setExportStatus('success');
    toast.success('Revenue Export successful!', { position: toast.POSITION.TOP_CENTER });
  };

  const handleError = (error) => {
    setExportStatus('error');
    toast.error('Failure Export', { position: toast.POSITION.TOP_CENTER });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {adminPageLoader ? (
          <AdminPageLoader />
        ) : (
          <Container fluid>
            <Breadcrumbs title="revenue" breadcrumbItem="List" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {revenue && revenue?.length > 0 ? (
                      <React.Fragment>
                        {/* Need approval from client */}
                        {/* <div className="d-flex justify-content-end mb-2">
                          <CSVLink
                            data={revenue}
                            headers={csvHeaders}
                            filename={"revenue_data.csv"}
                            onClick={() => handleSuccess()}
                            onError={handleError}
                          >
                            <Button color="primary">Export</Button>
                          </CSVLink>
                        </div> */}
                        <TableContainer
                          columns={columns}
                          data={revenue || []}
                          isGlobalFilter={true}
                          isAddCustList={false}
                          isPagination={true}
                          customPageSize={itemsPerPage}
                          tableClass="align-middle table-nowrap"
                          isShowingPageLength={false}
                          iscustomPageSizeOptions={true}
                          pagination="pagination pagination-rounded justify-content-end mb-2"
                        />
                      </React.Fragment>
                    ) : (
                      <div className="no-records">No records found</div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}
