import React from "react"

const colorArray = [
  { letter: "a", color: "#0049b2" },
  { letter: "b", color: "#37b6ff" },
  { letter: "c", color: "#0049b2" },
  { letter: "d", color: "#37b6ff" },
  { letter: "e", color: "#0049b2" },
  { letter: "f", color: "#37b6ff" },
  { letter: "g", color: "#0049b2" },
  { letter: "h", color: "#37b6ff" },
  { letter: "i", color: "#0049b2" },
  { letter: "j", color: "#37b6ff" },
  { letter: "k", color: "#37b6ff" },
  { letter: "l", color: "#0049b2" },
  { letter: "m", color: "#37b6ff" },
  { letter: "n", color: "#0049b2" },
  { letter: "o", color: "#37b6ff" },
  { letter: "p", color: "#0049b2" },
  { letter: "q", color: "#37b6ff" },
  { letter: "r", color: "#0049b2" },
  { letter: "s", color: "#37b6ff" },
  { letter: "t", color: "#0049b2" },
  { letter: "u", color: "#0049b2" },
  { letter: "v", color: "#0049b2" },
  { letter: "w", color: "#0049b2" },
  { letter: "x", color: "#37b6ff" },
  { letter: "y", color: "#0049b2" },
  { letter: "z", color: "#37b6ff" },
]

export const getColorByLetter = letter => {
  const lowercaseLetter = letter?.toLowerCase()
  const colorObject = colorArray.find(item => item.letter === lowercaseLetter)
  return colorObject ? colorObject.color : "gray"
}
