import React, { useEffect, useRef, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { Icon } from "@iconify/react"
import PropTypes from "prop-types"

import SimpleBar from "simplebar-react"

import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"

import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import subscribe from "../../assets/images/dashboard/subscribe-icon.png"

const SidebarContent = props => {
  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const { superAdminTokenResponse, creatorTokenResponse, adminTokenResponse } = useSelector(
    state => state.userAuthReducer
  )
  
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("dashboard")}</span>
              </Link>
            </li>
            {superAdminTokenResponse?.result?.Type_Of_User ===
              "Super Admin" && (
                <>
                  <li>
                    <Link to="/admin">
                      <i className="mdi mdi-account-tie-outline"></i>
                      <span>{props.t("Admin")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin-podcast" >
                      <i className="far fa-file-audio"></i>
                      <span>{props.t("podcast")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login" className="has-arrow">
                      {/* <Icon className="pitch-list" icon="gg:list" /> */}
                      <i className="dripicons-article"></i>
                      <span>{props.t("Content list")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/content/needApproval">
                          {props.t("Need Approval")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/content/Approved">
                          {props.t("Approved List")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/content/Rejected">
                          {props.t("Rejected List")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/login" className="has-arrow">
                      <Icon className="pitch-list" icon="gg:list" />
                      <span className="pitch">{props.t("pitch list")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/admin/pitch/needApproval">
                          {props.t("Need Approval")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/pitch/Approved">
                          {props.t("Approved List")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/pitch/Rejected">
                          {props.t("Rejected List")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/subscription-plans">
                      <i className="mdi mdi-file-refresh-outline"></i>
                      <span>{props.t("Subscription plans")}</span>
                    </Link>
                  </li>
                  <li className="menu-title">{props.t("End Users")} </li>
                  <li>
                    <Link to="/customers">
                      <i className="mdi mdi-account-group-outline"></i>
                      <span>{props.t("Customers")}</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/abandoned-cart">
                    <i className="mdi mdi-cart-arrow-down"></i>
                    <span>{props.t("Abandoned Cart")}</span>
                  </Link>
                </li> */}
                  <li className="menu-title">{props.t("Content Creators")} </li>
                  <li>
                    <Link to="/login" className="has-arrow">
                      <i className="mdi mdi-account-details-outline"></i>
                      <span>{props.t("Content Creators")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/content-creators/Unregistered">
                          {props.t("Unregistered")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/content-creators/Registered">
                          {props.t("Registered")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/all-withdrawal-request">
                      {/* <Icon className="pitch-icon" icon="ri-wallet-3-fill" /> */}
                      <i className="mdi mdi-cash-lock-open">
                      </i><span>{props.t("Withdrawal requests")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/payouts">
                      <i className="mdi mdi-cash-refund"></i>
                      <span>{props.t("Payouts")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/revenue">
                      <i className="mdi mdi-cash-usd-outline"></i>
                      <span>{props.t("Revenue")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/corporate-subscription">
                      <Icon className="subscribe" icon="fluent-mdl2:subscribe" />
                      <span className="span-subscribe">
                        {props.t("Corporate Subscriptions")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/market-focus">
                      <i className="mdi mdi-book-account"></i>
                      <span className="span-subscribe">
                        {props.t("Market Focus")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/countries">
                      <i className="fas fa-synagogue"></i>
                      <span className="span-subscribe">
                        {props.t("Countries")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/recent-topic">
                      <i className="bx bx bx-heading"></i>

                      <span className="span-subscribe">
                        {props.t("Recent Topic")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Newsletterlist">
                      <i className="fas fa-newspaper"></i>
                      <span className="span-subscribe">
                        {props.t("Newsletter list")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/advertise-with-us-list">
                      <i className="fas fa-tv"></i>
                      <span className="span-subscribe">
                        {props.t("Advertise with us")}
                      </span>
                    </Link>
                  </li>
                </>
              )}

            {adminTokenResponse?.result?.Type_Of_User ===
              "Admin" && (
                <>
                  <li>
                    <Link to="/admin-podcast" >
                      <i className="far fa-file-audio"></i>
                      <span>{props.t("podcast")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login" className="has-arrow">
                      {/* <Icon className="pitch-list" icon="gg:list" /> */}
                      <i className="dripicons-article"></i>
                      <span>{props.t("Content list")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/content/needApproval">
                          {props.t("Need Approval")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/content/Approved">
                          {props.t("Approved List")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/content/Rejected">
                          {props.t("Rejected List")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/login" className="has-arrow">
                      <Icon className="pitch-list" icon="gg:list" />
                      <span className="pitch">{props.t("pitch list")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/admin/pitch/needApproval">
                          {props.t("Need Approval")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/pitch/Approved">
                          {props.t("Approved List")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/pitch/Rejected">
                          {props.t("Rejected List")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="menu-title">{props.t("Content Creators")} </li> */}
                  <li>
                    <Link to="/login" className="has-arrow">
                      <i className="mdi mdi-account-details-outline"></i>
                      <span>{props.t("Content Creators")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/content-creators/Unregistered">
                          {props.t("Unregistered")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/content-creators/Registered">
                          {props.t("Registered")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            {creatorTokenResponse?.result?.Type_Of_User ===
              "Content Creator" && (
                <>
                  <li>
                    <Link to="/pitch-creation">
                      <Icon className="pitch-icon" icon="ri:draft-line" />
                      <span className="pitch">{props.t("pitch creation")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login" className="has-arrow">
                      <Icon className="pitch-list" icon="gg:list" />
                      <span className="pitch">{props.t("pitch list")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/pitch/needApproval">
                          {props.t("need approval")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/pitch/Approved">
                          {props.t("approved list")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/pitch/Rejected">
                          {props.t("rejected list")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/content-creation">
                      <i className="mdi mdi-content-save-all-outline"></i>
                      <span>{props.t("Content Creation")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login" className="has-arrow">
                      <Icon className="pitch-list" icon="gg:list" />
                      <span className="pitch">{props.t("content list")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/creator/content-list/needApproval">
                          {props.t("need approval")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/creator/content-list/Approved">
                          {props.t("approved list")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/creator/content-list/Rejected">
                          {props.t("rejected list")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/creator-wallet-details">
                      <Icon className="pitch-icon" icon="ri-wallet-3-fill" />
                      <span className="pitch">{props.t("revenue")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdrawal-request">
                      {/* <Icon className="pitch-icon" icon="ri-wallet-3-fill" /> */}
                      <i className="mdi mdi-cash-lock-open">
                      </i><span>{props.t("Withdrawal requests")}</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/draft" >
                    <i className="mdi mdi-cash-usd-outline"></i>
                    <span>{props.t("Draft")}</span>
                  </Link>
                </li> */}
                </>
              )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(SidebarContent)
