import ForgotPasswordIndex from "webComponents/forgotPassword/index";
import React from "react";

export default function ForgotPassword () {
    document.title = "Forgot Password";
    return(
        <div>
            <ForgotPasswordIndex />
        </div>
    )
}