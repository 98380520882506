import React from "react";
import ScaleLoader from 'react-spinners/ScaleLoader';


export default function AdminPageLoader() {

    return (
        <>
            <div className="d-flex justify-content-center align-items-center page-loader-main">
                <ScaleLoader
                    color="#004bac"
                    loading={true}
                    height={40}
                    radius={4}
                    speedMultiplier={1.5}
                    width={5}
                />
            </div>           
        </>
    )
}