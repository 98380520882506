import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import { adminActions } from "./actions";
import { commonActions } from "../common/actions";
import { API_URL } from "../../utils/constant";
import axios from "axios";

export const adminSagas = function* () {

    yield all([
        yield takeEvery(adminActions.GET_ADMIN_LIST, getAdminList),
        yield takeEvery(adminActions.GET_ADMIN_DETAILS, getAdminDetails),
        yield takeEvery(adminActions.CREATE_ADMIN, createAdmin),
        yield takeEvery(adminActions.UPDATE_ADMIN, updateAdmin),
        yield takeEvery(adminActions.UPDATE_ADMIN_STATUS, updateAdminStatus)
    ])
};

const getAdminList = function* () {
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/admin/list`)
        );
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
        yield put({
            type: adminActions.SET_ADMIN_LIST,
            payload: (result?.data?.statusCode === 200) ? result?.data?.result : []
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });

};

const getAdminDetails = function* (data) {

    const { payload } = data;
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/admin/detail/${payload}`)
        );
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
        yield put({
            type: adminActions.SET_ADMIN_DETAILS,
            payload: (result?.data?.statusCode === 200) ? result?.data?.result : null
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });

};

const createAdmin = function* (data) {

    const { payload } = data;
    const { reset } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/admin/create`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        if (result?.data?.statusCode === 200) {
            reset();
        };
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: (result?.data?.statusCode === 200) ? "success" : "failed", message: result?.data?.message }
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });

    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

};

const updateAdmin = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/admin/edit/${payload?.Id}`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        if (navigate) {
            navigate('/admin-details', { state: payload?.state });
        }
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: (result?.data?.statusCode === 200) ? "success" : "failed", message: result?.data?.message }
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, status: null, message: null }
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateAdminStatus = function* (data) {

    const { payload } = data;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/admin/blockUnblock/${payload?.Id}`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({ type: adminActions.GET_ADMIN_DETAILS, payload: payload?.Id });
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: (result?.data?.statusCode === 200) ? "success" : "failed", message: result?.data?.message }
        });
        if (result?.data?.statusCode === 200) {
            yield put({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: null, isOpen: false, data: null }
            });
        };
        yield delay(2000);
        yield put({ type: commonActions.SET_ALERT, payload: { show: false, status: null, message: null } });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

};