import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Card, CardBody } from "reactstrap"
import { dashboardActions } from "../../store/dashboard/actions"
import { useNavigate } from "react-router-dom"

export default function DashboardCount() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [endUserCount, setEndUserCount] = useState("")
  const [contentCreatorCount, setContentCreatorCount] = useState("")
  const [Registration_Status] = useState("Registered")
  const [adminCount, setAdminCount] = useState("")

  const { adminEndUserCount, adminContentCreatorsCount, adminAdminCount } =
    useSelector(state => state.DashboardReducer)

  useEffect(() => {
    // const Registration_Status = "Registered"
    dispatch({
      type: dashboardActions.GET_ADMIN_END_USER_COUNT,
    })
    dispatch({
      type: dashboardActions.GET_ADMIN_CONTENT_CREATOR_COUNT, payload: Registration_Status
    })
    dispatch({
      type: dashboardActions.GET_ADMIN_ADMINCOUNT,
    })
  }, [dispatch])

  useEffect(() => {
    if (adminEndUserCount) {
      setEndUserCount(adminEndUserCount?.count)
    }
    if (adminContentCreatorsCount) {
      setContentCreatorCount(adminContentCreatorsCount?.count)
    }
    if (adminAdminCount) {
      setAdminCount(adminAdminCount?.count)
    }
  }, [adminEndUserCount, adminContentCreatorsCount, adminAdminCount])

  const redirectToPage = role => {
    switch (role) {
      case "End-User":
        navigate("/customers")
        break
      case "Content Creator":
        navigate("/content-creators/Registered")
        break
      case "Admin":
        navigate("/admin")
        break
    }
  }

  const reports = [
    {
      title: "End-Users",
      iconClass: "mdi-account-group-outline",
      description: endUserCount,
      role: "End-User",
    },
    {
      title: "Content Creators",
      iconClass: "mdi-account-multiple-check-outline",
      description: contentCreatorCount,
      role: "Content Creator",
    },
    {
      title: "Admin",
      iconClass: "mdi-account-cog-outline",
      description: adminCount,
      role: "Admin",
    },
  ];

  return (
    <Row>
      {reports.map((report, key) => (
        <Col md="4" key={"_col_" + key}>
          <Card
            className="mini-stats-wid"
            onClick={() => redirectToPage(report.role)}
          >
            <CardBody>
              <div className="d-flex" style={{ cursor: "pointer" }}>
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">{report.title}</p>
                  <h4 className="mb-0">{report.description}</h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i
                      className={
                        "mdi " + report.iconClass + " font-size-24"
                        // "bx " + report.iconClass + " font-size-24"
                      }
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}
