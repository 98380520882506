import { customerActions } from "./actions";

const initialState = {
    customerList: [],
    customerDetails: null,
};

export const customerReducer = (state = initialState, action) => {

    switch (action.type) {
        case customerActions.SET_CUSTOMER_LIST:
            return {
                ...state,
                customerList: action.payload
            }
        case customerActions.SET_CUSTOMER_DETAILS:
            return {
                ...state,
                customerDetails: action.payload
            }
        default:
            return state;
    }
};