import { creatorDashboardActions } from "./actions";

const initialState = {
    totalWallet: null,
    creatorWalletInfo: null,
    creatorContentGraphCount: null,
    creatorRevenueDetails: null,
};

export const creatorDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case creatorDashboardActions.SET_TOTAL_WALLET_COUNT:
            return {
                ...state,
                totalWallet: action.payload
            }
        case creatorDashboardActions.SET_CREATOR_WALLET_DETAILS:
            return {
                ...state,
                creatorWalletInfo: action.payload
            }
        case creatorDashboardActions.SET_CREATOR_CONTENT_GRAPH_COUNT:
            return {
                ...state,
                creatorContentGraphCount: action.payload
            }
        case creatorDashboardActions.SET_CREATOR_REVENUE_DETAILS:
            return {
                ...state,
                creatorRevenueDetails: action.payload
            }
        default:
            return state;
    }
}