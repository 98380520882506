
export default function FileSizeValidation (Images) {
    let Validation = [], FinalImages = [];
    if (Images.length > 0) {
        for (let i = 0; i < Images.length; i++) {
            if (Images[i].size > 6000000) {
                Validation.push("false");
            } else {
                Validation.push("true");
                FinalImages.push(Images[i]);
            }
        };
        if (Validation.includes("false")) {
            let Result = {
                Validation: "false",
                FinalImages: FinalImages
            };
            return Result;
        } else {
            let Result = {
                Validation: "true",
                FinalImages: FinalImages
            };
            return Result;
        }
    }
};