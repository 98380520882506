import React from "react";
import { PuffLoader } from 'react-spinners';
// import 'react-spinners/css/PuffLoader.css';

export default function WebPageLoader() {

    return (
        <>
            <div className="d-flex justify-content-center align-items-center page-loader-web">
                <PuffLoader
                    color="#004bac"
                    height={40}
                    radius={4}
                    speedMultiplier={1.5}
                    width={5}
                    loading={true}
                />
            </div>
        </>
    )
}