import SendRequestIndex from "../components/sendWithdrawRequest/index";
import React from "react";

export default function RevenueDetail () {

    return (
        <div>
            <SendRequestIndex />
        </div>
    )
}