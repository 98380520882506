import RecentTopicindex from "components/RecentTopicHeading/RecentTopic";
import React from "react";

export default function RecentTopic () {

    return (
        <div>
            <RecentTopicindex />
        </div>
    )
}