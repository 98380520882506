import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function PrivateRoute(props) {
    // const { isAuthenticated, tokenResponse } = useSelector(state => state.userAuthReducer)
    // const { pathname } = useLocation();

    return (
        <>
            {
                ((localStorage.getItem('GFX_TOKEN') || localStorage.getItem('GFX_TOKEN2') || localStorage.getItem('GFX_TOKEN3'))
                )
                    ?

                    (props.children)
                    :
                    <Navigate to={"/login"} />
            }

            {/* {
                ((pathname?.slice(0, 6) === '/login' && (sessionStorage.getItem('GFX_TOKEN') || sessionStorage.getItem('GFX_TOKEN2'))) ||
                    (pathname?.slice(0, 6) !== '/login' && localStorage.getItem('WEB_TOKEN'))) ? (
                    props.children
                )
                    :
                    (pathname?.slice(0, 6) === '/login') ?
                        <Navigate to={'/login'} />
                        :
                        <Navigate to={"/"} />
            } */}
        </>
    )
};