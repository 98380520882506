import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Col, Form, Button } from "react-bootstrap"
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa"
import Logo from "../../assets/webImages/png-logo-color.webp"
import Loginimage from "../../assets/webImages/loginpage.webp"
import { GoArrowLeft } from "react-icons/go"
import { useNavigate } from "react-router-dom"
import { userAuthActions } from "store/userAuth/actions"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Spinner } from 'reactstrap'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm()
  const [showPassword, setShowPassword] = React.useState(false)

  const { buttonLoader } = useSelector(state => state.commonReducer);

  const navigateToHome = () => {
    navigate("/")
  }

  const onSubmit = (val) => {
    const lowercaseEmail = getValues("Email").toLowerCase();
    const data = {
      Email: lowercaseEmail,
      Password: val?.Password,
      loginFrom: 'User'
    }
    dispatch({
      type: userAuthActions.AUTH_LOGIN,
      payload: { data: data },
    })
    localStorage.setItem('WebUserLogin', Date.now())
  }

  // useEffect(() => {
  //   return () => {
  //     setValue("Email", Email?.toLowerCase());
  //     setValue("Password", "")
  //   }
  // }, [setValue,Email])

  return (
    <div className="login-wrap">
      <div className="row no-space">
        <div className="col-md-7">
          <div className="login-test">
            <div className="login-body">
              <img src={Logo} alt="logo" className="logo-ad" onClick={navigateToHome} />
              {/* <p className="back-to-home web_fonts" onClick={navigateToHome}>
                <GoArrowLeft /> back to Home
              </p> */}
            </div>
            <Col className="login-container">
              <h5 className="login-header web_fonts">log in</h5>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="form-group-row pt-4">
                  <Col>
                    <Form.Group
                      controlId="formEmail"
                      className="form-group custom-nav-link"
                    >
                      <Form.Label className="form-label web-form-label custom-nav-link">
                        email ID
                      </Form.Label>
                      <Form.Control
                        className="formcontrol"
                        type="email"
                        placeholder="enter your email"
                        {...register("Email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <p className="error-message web_fonts">
                        {errors.Email?.message}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="form-group-row">
                  <Col>
                    <Form.Group
                      controlId="formPassword"
                      className="form-group custom-nav-link"
                    >
                      <Form.Label className="form-label web-form-label custom-nav-link">
                        password
                      </Form.Label>
                      <div className="password-container">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="enter your password"
                          className={
                            errors.password?.message
                              ? "required-field web_fonts  formcontrol"
                              : "web_fonts formcontrol"
                          }
                          {...register("Password", {
                            required: "Password is required",
                            // pattern: {
                            //   value: /[A-Za-z]/,
                            //   message: "Password must be at least 3 characters",
                            // },
                          })}
                        />
                        <span
                          className="password-icon custom-nav-link"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <FaRegEye className="pass-i" />
                          ) : (
                            <FaRegEyeSlash className="pass-i" />
                          )}
                        </span>
                        <p className="error-message">
                          {errors.Password?.message}
                        </p>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* <div className="checkbox-container text-right"> */}
                    <div className="text-right">
                      <Link
                        to="/web/forgotPassword"
                        className="forgot-password custom-nav-link"
                      >
                        <i className="mdi mdi-lock me-1" /> Forgot your
                        password?
                      </Link>
                      {/* <p className="forgot-password custom-nav-link">
                        forgot Password?
                      </p> */}
                      {/* <Form.Check
                        className="remember-me custom-nav-link"
                        label="remember me"
                        {...register("remember")}
                      /> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="success"
                      className="login-button custom-nav-link "
                      type="submit"
                    >
                      {buttonLoader ? <Spinner color="light" size="sm" /> : "log in"}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <p className="signup-text web_font">
                don't have an account?{" "}
                <a href="/web/signup" className="signup-link custom-nav-link">
                  sign up
                </a>
              </p>
            </Col>
          </div>
        </div>
        <div className="col-md-5">
          <div className="lgo-img login-page-padding">
            <img src={Loginimage} alt="login-image" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
