// webHome/actions.js
export const WebHomePageActions = {
  GET_WEBHOME_RECENTSTORIES_DETAILS: "GET_WEBHOME_RECENTSTORIES_DETAILS",
  SET_WEBHOME_RECENTSTORIES_DETAILS: "SET_WEBHOME_RECENTSTORIES_DETAILS",

  GET_LIMITED_RECENT_CONTENT_LIST: 'GET_LIMITED_RECENT_CONTENT_LIST',
  SET_LIMITED_RECENT_CONTENT_LIST: 'SET_LIMITED_RECENT_CONTENT_LIST',

  GET_WEBHOME_POLITICS_CATEGORY_DETAILS:
    " GET_WEBHOME_POLITICS_CATEGORY_DETAILS",
  SET_WEBHOME_POLITICS_CATEGORY_DETAILS:
    " SET_WEBHOME_POLITICS_CATEGORY_DETAILS",

  GET_WEBHOME_FORYOU_NEWS_DETAILS: "GET_WEBHOME_FORYOU_NEWS_DETAILS",
  SET_WEBHOME_FORYOU_NEWS_DETAILS: "SET_WEBHOME_FORYOU_NEWS_DETAILS",

  GET_WEBHOME_POPULARNEWS_DETAILS: "GET_WEBHOME_POPULARNEWS_DETAILS",
  SET_WEBHOME_POPULARNEWS_DETAILS: "SET_WEBHOME_POPULARNEWS_DETAILS",

  GET_WEBHOME_COUNTRYLIST_DETAILS: "GET_WEBHOME_COUNTRYLIST_DETAILS",
  SET_WEBHOME_COUNTRYLIST_DETAILS: "SET_WEBHOME_COUNTRYLIST_DETAILS",

  GET_WEBHOME_VIEWCOUNT_DETAILS: " GET_WEBHOME_VIEWCOUNT_DETAILS",
  SET_WEBHOME_VIEWCOUNT_DETAILS: "SET_WEBHOME_VIEWCOUNT_DETAILS",

  GET_WEBHOME_CONTENTBY_RECENT_TOPIC: "GET_WEBHOME_CONTENTBY_RECENT_TOPIC",
  SET_WEBHOME_CONTENTBY_RECENT_TOPIC: "SET_WEBHOME_CONTENTBY_RECENT_TOPIC",

  GET_WEBHOME_RECENTTOPIC_NEWS_DETAILS: "GET_WEBHOME_RECENTTOPIC_NEWS_DETAILS",
  SET_WEBHOME_RECENTTOPIC_NEWS_DETAILS: "SET_WEBHOME_RECENTTOPIC_NEWS_DETAILS",

  GET_WEBHOME_PROFILE_USERINFO_DETAILS: "GET_WEBHOME_PROFILE_USERINFO_DETAILS",
  SET_WEBHOME_PROFILE_USERINFO_DETAILS: "SET_WEBHOME_PROFILE_USERINFO_DETAILS",

  GET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS:
    "GET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS",
  SET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS:
    "SET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS",

  GET_WEBHOME_PODCAST: "GET_WEBHOME_PODCAST",
  SET_WEBHOME_PODCAST: "SET_WEBHOME_PODCAST",

  GET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME: "GET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME",
  SET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME: "SET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME",

  GET_LIMITED_FORU_LIST: "GET_LIMITED_FORU_LIST",
  SET_LIMITED_FORU_LIST: "SET_LIMITED_FORU_LIST",

  GET_WEBHOME_SPECIFIC_PODCAST_HOME: "GET_WEBHOME_SPECIFIC_PODCAST_HOME",
  SET_WEBHOME_SPECIFIC_PODCAST_HOME: "SET_WEBHOME_SPECIFIC_PODCAST_HOME",

  WEB_USER_CHANGE_PASSWORD: "WEB_USER_CHANGE_PASSWORD",
  WEB_USER_SET_CHANGE_PASSWORD: "WEB_USER_SET_CHANGE_PASSWORD",

}
