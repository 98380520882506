const actions = {
    GET_SUBSCRIPTION_PLANS_LIST: 'GET_SUBSCRIPTION_PLANS_LIST',
    SET_SUBSCRIPTION_PLANS_LIST: 'SET_SUBSCRIPTION_PLANS_LIST',

    ENABLE_SUBSCRIPTION_PLAN: 'ENABLE_SUBSCRIPTION_PLAN',
    UPDATE_SUBSCRIPTION_PLAN:'UPDATE_SUBSCRIPTION_PLAN',

    GET_CHECKOUT_SESSION_ID : 'GET_CHECKOUT_SESSION_ID',
    SET_CHECKOUT_SESSION_ID : 'SET_CHECKOUT_SESSION_ID',

    CANCEL_SUBSCRIPTION_PLAN : 'CANCEL_SUBSCRIPTION_PLAN',

    SUBSCRIPTION_RESPONSE : 'SUBSCRIPTION_RESPONSE',
}
export default actions;