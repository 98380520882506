import React, { useState, useEffect, useRef } from "react";
import { Row, Col, CardBody, Card, Container, Form, Label, Input, FormFeedback, textarea, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContentCreatorActions } from "store/contentCreator/actions";
import { BlogContentActions } from "store/blogContent/actions";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Field } from "formik";
import Multiselect from 'multiselect-react-dropdown';
import success_img from "../../assets/images/dashboard/success1-popup-image.gif";
import failure_img from "../../assets/images/dashboard/failure-popup-image.gif"
import { actions } from "store/Countries/actions"
import Select from 'react-select';

// import images
import registration_form_img from "../../assets/images/profile-img.png";
import logosm from "../../assets/images/dashboard/new-logo22.png";

import FileSizeValidation from '../../FileSizeValidation/index'
import { commonActions } from "../../store/common/actions";

export default function ContentCreatorRegistrationIndex() {

  const dispatch = useDispatch();
  const imageRef = useRef();
  const fileInputRef = useRef(null);

  let Category = [
    { id: 1, value: 'Business', name: 'business' },
    { id: 2, value: 'Politics', name: 'politics' },
    // { id: 3, value: 'Green', name: 'green' },
    // { id: 4, value: 'In-Depth', name: 'in-depth' },
    // { id: 5, value: 'Recent Topics', name: 'recent topics' },
    // { id: 6, name: 'Frontier Markets' },
  ]

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState("");
  const [fileError, setFileError] = useState("");

  const [countryOptions, setCountryOptions] = useState([]);
  const [countryError, setCountryError] = useState("");

  var [countryArray, setCountryArray] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryError, setCategoryError] = useState("");

  const [showRegistrationPopup, setShowRegistrationPopup] = useState({ isOpen: false, statusCode: null });

  const { buttonLoader } = useSelector(state => state.commonReducer);

  const { creator_Response } = useSelector(state => state.ContentCreatorReducer);
  //const { countryList } = useSelector(state => state.CountryReducer)
  const { onlyCountryList } = useSelector(state => state.CountryReducer);
  document.title = "Register | Allen Dreyfus";

  useEffect(() => {
    dispatch({ type: actions.GET_COUNTRY_LIST });
  }, [])

  useEffect(() => {
    dispatch({ type: actions.GET_ONLY_COUNTRY_LIST })
  }, [])

  // Country List
  useEffect(() => {
    if (onlyCountryList?.result) {
      const mappedCountries = onlyCountryList.result.map(val => ({
        value: val.Country_Name,
        label: val.Country_Name,
      }));
      setCountryArray(mappedCountries);
    }
  }, [onlyCountryList]);

  const handleSelectChange = selectedOption => {
    setCountryOptions(selectedOption)
    // validation.setFieldValue('country', selectedOption);
  };

  //form validation
  const validation = useFormik({

    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      contactnumber: '',
      description: '',
      country: [],
      category: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("please enter your name"),
      email: Yup.string()
        .required('email is required')
        .email('invalid email format')
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'invalid email format')
        .test('no-uppercase', 'email must not contain uppercase letters', (value) => {
          // Test function to check for uppercase letters
          return !/[A-Z]/.test(value);
        }),
      contactnumber: Yup.string()
        .required("please enter your contact number"),
      // .matches(/^[0-9]{10}$/, 'mobile number must be 10 digits'),
      description: Yup.string()
        .required("please enter your bio description")
        .min(50, 'minimum 50 characters required')
    }),

    onSubmit: (data) => {
      const lowercaseEmail = data.email.toLowerCase();
      if (!selectedImage) {
        setFileError("profile image is required");
        return;
      }
      // else if (countryOptions?.length === 0) {
      //   setCountryError("please select the country");
      else if (countryOptions?.length === 0) {
        setCountryError("please select the country");
      } else if (categoryOptions?.length === 0) {
        setCategoryError("please select the content type");
      } else {
        const formData = new FormData();
        formData.append('Name', data.name)
        formData.append('Email', lowercaseEmail);
        formData.append('Contact_Number', data.contactnumber);
        formData.append('Brief_Bio_Description', data.description);
        // var country = [];
        // countryOptions.map(val =>
        //   country = [...country, val.name]);
        // formData.append("Country", JSON.stringify(country));
        formData.append("Country", JSON.stringify(countryOptions.value));
        var category = [];
        // categoryOptions.map(val =>
        //   category = [...category, val.name]);
        categoryOptions.map(val =>
          category = [...category, val.value]);
        formData.append("Category", JSON.stringify(category));
        formData.append('Profile_Image', imageFile);
        formData.append('Type_Of_User', "Content Creator");
        formData.append('Registration_Status', "Unregistered");

        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
        dispatch({ type: ContentCreatorActions.CREATOR_REGISTRATION, payload: { data: formData, page: "signup" } });
      }

    }
  });

  useEffect(() => {
    if (creator_Response && creator_Response?.statusCode === 200) {
      setShowRegistrationPopup({ isOpen: true, statusCode: 200 });
      // Clear the form 
      validation.resetForm();
      imageRef.current.value = null; //to clear img file   
      setSelectedImage(null);
      setFileError("");
      setCountryOptions([]);
      setCategoryOptions([]);
      setCountryArray(countryArray)
    } else if (creator_Response && creator_Response?.statusCode === 400) {
      setShowRegistrationPopup({ isOpen: true, statusCode: 400, message: creator_Response.message });
      // Clear the form 
      // validation.resetForm();
      // imageRef.current.value = null; //to clear img file  
      // setSelectedImage(null);
      // setFileError("");
    }
  }, [creator_Response]);

  const handleClose = () => {
    setShowRegistrationPopup({ isOpen: false, statusCode: null })
  }
  const handleCloseSuccess = () => {
    setShowRegistrationPopup({ isOpen: false, statusCode: null })
    window.location.href = '/register'

  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileError("");
    if (file) {
      let result = FileSizeValidation(event.target.files);
      if (result.Validation === "false") {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
        imageRef.current.value = null
      } else {
        if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
          result?.FinalImages[0]?.type === 'image/png') {
          setSelectedImage(URL.createObjectURL(file));
          setImageFile(file)
        } else {
          dispatch({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: "failed", message: "unsupported file format" }
          })
          imageRef.current.value = null
        }
      }
      ;
    } else {
      setFileError("profile image is required");
    }
  };

  // Country
  const onSelectCounty = (selectedCountry) => {
    setCountryOptions(selectedCountry);
    setCountryError("");
  };

  const onRemoveCountry = (selectedCountry) => {
    setCountryOptions(selectedCountry);
  };

  // Category
  const onSelectCategory = (selectedCategory) => {
    setCategoryOptions(selectedCategory);
    setCategoryError("");
  };

  const onRemoveCategory = (selectedCategory) => {
    setCategoryOptions(selectedCategory);
  };


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Sign up</h5>
                        <p>Content creator Registration</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={registration_form_img} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logosm}
                              alt=""
                              className="rounded-circle logo-sm-img-login"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="p-2">
                    <Form className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Name <span className='text-danger'>*</span></Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="enter name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name ? true : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Email <span className='text-danger'>*</span></Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="enter email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Contact Number <span className='text-danger'>*</span></Label>
                        <Input
                          name="contactnumber"
                          type="text"
                          placeholder="enter contact number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactnumber || ""}
                          invalid={
                            validation.touched.contactnumber && validation.errors.contactnumber ? true : false
                          }
                        />
                        {validation.touched.contactnumber && validation.errors.contactnumber ? (
                          <FormFeedback type="invalid">{validation.errors.contactnumber}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Bio</Label><span className="text-danger fw-bold">*</span>
                        <Input
                          type="textarea"
                          name="description"
                          placeholder="Enter description"
                          rows="4" cols="50"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description && validation.errors.description ? true : false
                          }
                        />
                        {validation.touched.description && validation.errors.description ? (
                          <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="Title" className="form-label">Country</Label><span className="text-danger fw-bold">*</span>
                        <Select
                          placeholder="Select a category"
                          options={countryArray}
                          value={countryOptions || []}
                          onChange={handleSelectChange}
                          onBlur={validation.handleBlur}
                        />

                        {/* <Multiselect
                          options={countryArray}
                          displayValue="name"
                          onSelect={onSelectCounty}
                          onRemove={onRemoveCountry}
                          selectedValues={countryOptions}
                          value={validation.values.country || []}
                        /> */}
                        <div>
                          {/* <div><span className="market-select">selected values:</span> {countryOptions?.map((option) => option.name).join(', ')}</div> */}
                        </div>
                        {countryError && <div style={{ color: "red" }}>{countryError}</div>}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="Title" className="form-label">Type of Content</Label><span className="text-danger fw-bold">*</span>
                        <Multiselect
                          options={Category}
                          displayValue="name"
                          onSelect={onSelectCategory}
                          onRemove={onRemoveCategory}
                          selectedValues={categoryOptions}
                          value={validation.values.category || []}
                        />
                        <div>
                          <div><span className="market-select">selected values:</span> {categoryOptions?.map((option) => option.name).join(', ')}</div>
                        </div>
                        {categoryError && <div style={{ color: "red" }}>{categoryError}</div>}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Profile Image <span className='text-danger'>*</span></Label>

                        {selectedImage && (
                          <div >
                            <img src={selectedImage} alt="Selected" style={{ padding: '10px', maxWidth: '50%', maxHeight: '150px' }} />
                          </div>
                        )}

                        <Input
                          type="file"
                          name="image"
                          innerRef={imageRef}
                          accept="image/png, image/jpeg,image/jpg"
                          onChange={handleImageChange}
                          multiple={false}
                        />
                        {fileError && <small> <div style={{ color: "red" }}>{fileError}</div></small>}
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          {buttonLoader ? <Spinner color="light" size="sm" /> : "Register"}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Allen Dreyfus
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div >

      <Modal isOpen={showRegistrationPopup?.isOpen} toggle={showRegistrationPopup?.statusCode === 200 ? handleCloseSuccess : handleClose} centered={true}>
        <ModalHeader centered={true} toggle={showRegistrationPopup?.statusCode === 200 ? handleCloseSuccess : handleClose} tag="h4">
          <h4>Alert</h4>
        </ModalHeader>
        <ModalBody centered={true}>
          <Form
            className='padding-signup-model'
            onSubmit={(e) => {
              e.preventDefault();
              // validation.handleSubmit();
              return false;

            }}
          >
            {
              showRegistrationPopup?.statusCode === 200 ?

                <Row>
                  <Col md={12} className=" mt-3 text-center">
                    <img src={success_img} alt="success_popup" className="popup-success" />
                    <h5>Thank you for submitting the details.<br /> Admin will reach you soon !!.</h5>
                  </Col>
                  <hr />

                  <Col md={12}>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-danger save-customer me-2"
                        // onClick={(handleClose)}
                        onClick={handleCloseSuccess}
                      >
                        Close
                      </button>

                    </div>
                  </Col>
                </Row>

                :

                <Row>
                  <Col md={12} className="mb-4 mt-3 text-center">
                    <img src={failure_img} alt="failure_popup" className="popup-img" />
                    <h5>{showRegistrationPopup?.message}</h5>
                  </Col>
                  <hr />

                  <Col md={12}>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-primary save-customer me-2"
                        onClick={handleClose}
                      >
                        Ok
                      </button>
                    </div>
                  </Col>
                </Row>
            }
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
};
