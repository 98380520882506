import axios from 'axios';

const setAuthToken = (token, typeOfUser) => {

    if (token) {
      
        if (typeOfUser === "Super_Admin") {
            axios.defaults.headers.common["Authorization"] = token;
            axios.defaults.headers.common["CallingFromSuperAdmin"] = 'Super_Admin'
        }
        if (typeOfUser === "Admin") {
            axios.defaults.headers.common["Authorization"] = token;
            axios.defaults.headers.common["CallingFromAdmin"] = 'Admin'
        }
        if (typeOfUser === "Content_Creator") {
            axios.defaults.headers.common["Authorization"] = token;
            axios.defaults.headers.common["CallingFromContentCreator"] = 'Content_Creator';
        }
        if (typeOfUser === "User") {
            axios.defaults.headers.common["Authorization"] = token;
            axios.defaults.headers.common["CallingFromUser"] = 'User';
        }

        // if (CallingFromSuperAdmin === undefined && CallingFromContentCreator === undefined && CallingFromUser === undefined) {
        //     // Delete Authorization header for other cases
        //     delete axios.defaults.headers.common["Authorization"];
        //     delete axios.defaults.headers.common["typeOfUser"];
        //     delete axios.defaults.headers.common["CallingFromSuperAdmin"];
        //     delete axios.defaults.headers.common["CallingFromContentCreator"];
        //     delete axios.defaults.headers.common["CallingFromUser"];
        // }
    } else {
        // Delete Authorization header if token is not present
        delete axios.defaults.headers.common["Authorization"];
        delete axios.defaults.headers.common["typeOfUser"];
        delete axios.defaults.headers.common["CallingFromSuperAdmin"];
        delete axios.defaults.headers.common["CallingFromContentCreator"];
        delete axios.defaults.headers.common["CallingFromUser"];
        delete axios.defaults.headers.common["CallingFromAdmin"];
    }
    // if (token) {
    //     // Apply to every request
    //     axios.defaults.headers.common["Authorization"] = `${token}`;
    // } else {
    //     // Delete auth Header
    //     delete axios.defaults.headers.common["Authorization"];
    // }
    // ______
    // if (token) {
    //     let userType = ""; // Variable to store the user type

    //     if (typeOfUser === "Super_Admin" || typeOfUser === "Content_Creator" || typeOfUser === "User") {
    //         // Set user type and set Authorization header
    //         userType = typeOfUser;
    //         axios.defaults.headers.common["Authorization"] = token;
    //         axios.defaults.headers.common["typeOfUser"] = userType;
    //     } else {
    //         // Delete Authorization header for other cases
    //         delete axios.defaults.headers.common["Authorization"];
    //         delete axios.defaults.headers.common["typeOfUser"];
    //     }
    // } else {
    //     // Delete Authorization header if token is not present
    //     delete axios.defaults.headers.common["Authorization"];
    //     delete axios.defaults.headers.common["typeOfUser"];
    // }

   
};

export default setAuthToken;