import AdminIndex from "components/admin";
import React from "react";

export default function Admin() {

    return (
        <div>
            <AdminIndex />
        </div>
    )
}