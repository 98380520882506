import React, { useRef, useEffect, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import Quill 

const RichTextEditor = ({ setStoryContent, storyContent }) => {
    const quillRef = useRef(null);
    const [editorInstance, setEditorInstance] = useState(null);

    useEffect(() => {
        if (quillRef.current && !editorInstance) {
            const editor = new Quill(quillRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: true,
                },
            });
            editor.on('text-change', () => {
                const updatedContent = editor.root.innerHTML;
                setStoryContent(updatedContent);
            });
            setEditorInstance(editor);
        }
    }, [quillRef, editorInstance]);

    useEffect(() => {
        if (editorInstance && storyContent !== editorInstance.root.innerHTML) {
            editorInstance.root.innerHTML = storyContent;
        }
    }, [editorInstance, storyContent]);

    return (
        <div >
            <div id="editor" className="quill-editor" style={{ height: '400px' }} ref={quillRef} />
        </div>
    );
};

export default RichTextEditor;
