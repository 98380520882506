import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, InputGroup, InputGroupAddon } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "../../store/blogContent/actions";
import { commonActions } from "../../store/common/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import ApproveOrRejectContentModal from "components/commonModals/ApproveOrRejectContentModal";
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
const maxSelectableDate = new Date();

export default function ContentCreatorDetailsIndex() {

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    var [title, setTitle] = useState('')
    var [story, setStory] = useState('')
    var [pitchStory, setPitchStory] = useState('')
    var [pitchCreatedOn, setPitchCreatedOn] = useState('')
    var [submittedBy, setSubmittedBy] = useState('')
    var [language, setLanguage] = useState('')
    var [TypeOfContent, setTypeOfContent] = useState('')
    var [Country, setCountry] = useState('')
    var [Impacts, setImpacts] = useState('')
    var [ApprovedOn, setApprovedOn] = useState('')
    var [Caption, setCaption] = useState('')
    var [Duration, setDuration] = useState('')
    var [Image, setImage] = useState('')
    var [PodcastFile, setPodcastFile] = useState('')
    const [audioKey, setAudioKey] = useState(0);
    var [ThumnailImage, setThumnailImage] = useState('')
    var [podcastUploadedBy, setPodcastUploadedBy] = useState('')
    var [CreatedOn, setCreatedOn] = useState('')
    var [Category, setCategory] = useState('')
    var [SubCategory, setSubCategory] = useState('')
    var [price, setPrice] = useState(50);


    const [approveReason, setApproveReason] = useState('');
    const [rejectReason, setRejectReason] = useState('');
    var [rejectError, setRejectError] = useState('');
    var [RejectionReason, setRejectionReason] = useState('');
    var [RemarksForContent, setRemarksForContent] = useState('');
    var [RemarksForPitch, setRemarksForPitch] = useState('');
    var [articleDate, setArticleDate] = useState('');
    var [dateError, setDateError] = useState('');

    const [checkStatus, setCheckStatus] = useState(false);
    const [StatusCheckFromModal, setStatusCheckFromModal] = useState(false);
    const [EditApproveStatusCheck, setEditApproveStatusCheck] = useState(false);
    const [modalType, setModalType] = useState('');
    const [ContentType, setContentType] = useState('')

    const { adminContentDetail } = useSelector(state => state.BlogContentReducer);
    const { superAdminTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer);
    const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer);
    

    // const Id = state?.data?._id;
    const Id = state?.data?.NotificationType === "Content Updated By Creator" ? state?.data?.ContentID : state?.data?._id;
    // const src = PodcastFile;
    useEffect(() => {
        dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id });
    }, [Id, checkStatus, StatusCheckFromModal, EditApproveStatusCheck]);

    const handleShowModel = (modalTypeClicked) => {
        setModalType(modalTypeClicked)
        setCheckStatus(true)
        dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id });
    };


    const handleCloseModel = () => {
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { TypeOfModal: null, isOpen: false, data: null }
        });
    };


    useEffect(() => {
        if (adminContentDetail) {
            // if translation exist use below code
            // if (adminContentDetail?.Blog_Language === 'Arabic') {
            //     setTitle(adminContentDetail?.Arabic_Content?.Title)
            //     setDescription(adminContentDetail?.Arabic_Content?.Description)
            // } else if (adminContentDetail?.Blog_Language === 'French') {
            //     setTitle(adminContentDetail?.French_Content?.Title)
            //     setDescription(adminContentDetail?.French_Content?.Description)

            // } else if (adminContentDetail?.Blog_Language === 'Portuguese') {
            //     setTitle(adminContentDetail?.Portuguese_Content?.Title)
            //     setDescription(adminContentDetail?.Portuguese_Content?.Description)

            // } else {
            //     setTitle(adminContentDetail?.English_Content?.Title)
            //     setDescription(adminContentDetail?.English_Content?.Description)

            // }

            setLanguage(adminContentDetail?.Blog_Language)
            setTitle(adminContentDetail?.Title)
            setTypeOfContent(adminContentDetail?.TypeOfContent || "")
            setCountry(adminContentDetail?.Country?.map((single => single)))
            setImpacts(adminContentDetail?.Impacts)
            setCaption(adminContentDetail?.Caption)
            setApprovedOn(adminContentDetail?.Approved_On)
            setDuration(adminContentDetail?.Duration)
            if (adminContentDetail?.Article_Images?.length > 0) {
                setImage(adminContentDetail?.Article_Images[0])
            }
            // if (adminContentDetail?.Podcast && adminContentDetail?.Podcast?.length > 0) {
            //     setPodcastFile(adminContentDetail?.Podcast[0]); // Assuming Podcast[0] contains the URL or path
            // }
            setPodcastFile(adminContentDetail?.Podcast[0]);
            setThumnailImage(adminContentDetail?.Thumbnail_Image)
            setCategory(adminContentDetail?.Category[0])
            setSubCategory(adminContentDetail?.Sub_Category?.map(single => single))
            setCreatedOn(adminContentDetail?.Created_On)
            setStory(adminContentDetail?.Story)
            setPitchStory(adminContentDetail?.Pitch_story)
            setPitchCreatedOn(adminContentDetail?.Pitch_Created_On)
            setSubmittedBy(adminContentDetail?.Created_By)
            setRemarksForContent(adminContentDetail?.RemarksForContent)
            setRejectionReason(adminContentDetail?.RejectionReason)
            setRemarksForPitch(adminContentDetail?.Remarks_For_Pitch)
            if (adminContentDetail?.Status === 'Updated' || adminContentDetail?.Status === 'New') {
                setContentType('needApproval')
            } else if (adminContentDetail?.Status === 'Approved') {
                setContentType('Approved')
            } else if (adminContentDetail?.Status === 'Rejected') {
                setContentType('Rejected')
            }
            setPodcastUploadedBy(adminContentDetail?.Podcast_Submitted_By)
        }
    }, [adminContentDetail])

    useEffect(() => {
        // Update key whenever podcastFile changes to force re-render of audio element
        setAudioKey((prevKey) => prevKey + 1);
    }, [PodcastFile]);


    const handleApprovalStatus = () => {
        setStatusCheckFromModal(true)
        setCheckStatus(false)
        setModalType('')
        dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id });
    };

    const handleInputChange = (event) => {
        setApproveReason(event.target.value);
    };

    const handleRejectChange = (event) => {
        setRejectReason(event.target.value);
    };

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleChange = date => {
        setArticleDate(date);
    };

    const handleModelClose = () => {
        let path;
        if (adminContentDetail?.TypeOfContent === "Article") {
            if (adminContentDetail?.Status === 'New' || adminContentDetail?.Status === 'Updated') {
                path = '/content/needApproval';
            } else if (adminContentDetail?.Status === 'Approved') {
                path = '/content/Approved';
            } else {
                path = '/content/Rejected';
            }
        } else if (adminContentDetail?.TypeOfContent === "Podcast") {
            path = '/admin-podcast';
        } else {
            // Default path if neither "Article" nor "Podcast"
            //path = '/';
        }
        navigate(path);
    }

    const handleEditAndApproveContent = () => {
        setEditApproveStatusCheck(true)
        dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id });
    }

    useEffect(() => {
        if (checkStatus === true && (adminContentDetail?.Status === "Approved" || adminContentDetail?.Status === 'Rejected') && (modalType === 'Approve' || modalType === 'Reject')) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Content status already updated" }
            })
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (checkStatus === true && (adminContentDetail?.Status === "New" || adminContentDetail?.Status === "Updated") && (modalType === 'Approve' || modalType === 'Reject')) {
            dispatch({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: 'APPROVEORREJECTCONTENT', isOpen: true, data: { modalType: modalType } }
            });
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (StatusCheckFromModal === true && adminContentDetail?.Status === "New") {
            if ((rejectReason === '' || rejectReason === undefined || rejectReason === null) && showPopup?.data?.modalType === 'Reject') {
                setRejectError('please enter the reason for rejection');
            } else if ((articleDate === '' || articleDate === undefined || articleDate === null) && showPopup?.data?.modalType === 'Approve') {
                setDateError("please select the date")
            } else {
                setRejectError('');
                setDateError('');
                let previousValue;
                if (state?.data?.Status === "New") {
                    previousValue = "New"
                } else {
                    previousValue = "Old"
                }
                //updated only when the content is rejected
                let Content_Status_Updated_By = {};
                if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                    Content_Status_Updated_By = {
                        Name: superAdminTokenResponse?.result?.Name,
                        Email: superAdminTokenResponse?.result?.Email,
                        Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                    }
                } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                    Content_Status_Updated_By = {
                        Name: adminTokenResponse?.result?.Name,
                        Email: adminTokenResponse?.result?.Email,
                        Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                    }
                }
                dispatch({
                    type: BlogContentActions.CHANGE_CONTENT_STATUS,
                    payload: {
                        ContentId: adminContentDetail?._id,
                        Creator_Id: adminContentDetail?.Content_Creator_Id,
                        previousValue: previousValue,
                        // data: { Status: showPopup?.data?.modalType === 'Approve' ? 'Approved' : 'Rejected', RemarksForContent: approveReason, RejectionReason: rejectReason, Date_Approved: articleDate, Creator_Price: state?.data?.Status === "New" ? price : state?.data?.Creator_Price },
                        data: {
                            Status: 'Rejected',
                            RejectionReason: rejectReason,
                            Date_Approved: articleDate,
                            Creator_Price: state?.data?.Status === "New" ? " " : state?.data?.Creator_Price,
                            Content_Status_Updated_By: Content_Status_Updated_By,
                        },
                        type: state?.type,
                        navigate: navigate
                    }
                });
            }
        } else if (StatusCheckFromModal === true && (adminContentDetail?.Status === "Approved" || adminContentDetail?.Status === "Rejected")) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Content status already updated" }
            })
            handleCloseModel()
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (EditApproveStatusCheck === true && (adminContentDetail?.Status === "New" || adminContentDetail?.Status === "Updated")) {
            navigate('/edit-content-details', { state: { data: adminContentDetail, type: adminContentDetail?.Status } })
        } else if (EditApproveStatusCheck === true && adminContentDetail?.Status === "Approved") {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Content status already updated" }
            })
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            setEditApproveStatusCheck(false)
        }
    }, [adminContentDetail])

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="content"
                            titleLink={(state?.data?.TypeOfContent === "Article") ? `/content/${ContentType}` : `/admin-podcast`}
                            breadcrumbItem="details"
                        />
                        {/* <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="ms-3">
                                                <img
                                                    src={content_Creator_Details?.Profile_Image}
                                                    alt=""
                                                    className="avatar-md rounded-circle img-thumbnail"
                                                />
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted">
                                                    <h5>{content_Creator_Details?.Name}</h5>
                                                    <p className="mb-1">{content_Creator_Details?.Email}</p>
                                                    <p className="mb-0">Id: {content_Creator_Details?._id}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <Row className="mb-4">

                                        {/* <div className="form-group mb-3">
                                        <Label className="form-label">Language</Label>
                                        <Input
                                            name="Language"
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            type="text"
                                            defaultValue={language}
                                            disabled
                                        />
                                    </div> */}
                                        <Col md="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Type of Content</Label>
                                                <Input
                                                    name="TypeOFContent"
                                                    className="form-control"
                                                    placeholder="Enter Type of Content"
                                                    type="text"
                                                    defaultValue={TypeOfContent}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">market focus</Label>
                                                <Input
                                                    name="Country"
                                                    className="form-control"
                                                    placeholder="Enter Type of Content"
                                                    type="text"
                                                    defaultValue={Country}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Title</Label>
                                                <Input
                                                    name="Title"
                                                    className="form-control"
                                                    placeholder="Enter Title"
                                                    type="text"
                                                    defaultValue={title}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        {adminContentDetail?.TypeOfContent === "Article" && <>
                                            <Col md="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Content Submitted By</Label>
                                                    <Input
                                                        name="Content Submitted By"
                                                        className="form-control"
                                                        placeholder="Enter Content Submitted Byy"
                                                        type="text"
                                                        defaultValue={submittedBy}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">{adminContentDetail?.TypeOfContent === "Article" ? "Content Submitted On" : "Podcast Submitted On"} </Label>
                                                    <Input
                                                        name="Content Submitted On"
                                                        className="form-control"
                                                        placeholder="Enter Content Submitted On"
                                                        type="text"
                                                        defaultValue={CreatedOn}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Pitch Submitted On</Label>
                                                    <Input
                                                        name="Pitch Submitted On"
                                                        className="form-control"
                                                        placeholder="Enter Pitch Submitted On"
                                                        type="text"
                                                        defaultValue={pitchCreatedOn}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        </>
                                        }
                                        <Col md={adminContentDetail?.Sub_Category?.length !== 0 ? "6" : "12"}>
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Category</Label>
                                                <Input
                                                    name="Category"
                                                    className="form-control"
                                                    placeholder="Enter Category"
                                                    type="text"
                                                    defaultValue={Category}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            {adminContentDetail?.Sub_Category?.length !== 0 &&
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">{adminContentDetail?.Category[0]?.toLowerCase() === 'frontier markets' ? 'Frontier Markets' : 'Recent Topic'}</Label>
                                                    <Input
                                                        name="Category"
                                                        className="form-control"
                                                        placeholder="Enter Category"
                                                        type="text"
                                                        defaultValue={SubCategory}
                                                        disabled
                                                    />
                                                </div>
                                            }
                                        </Col>
                                        {/* <Col md={adminContentDetail?.Sub_Category?.length !== 0 ? "6" : "12"}>
                                            {adminContentDetail?.Duration &&
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Duration</Label>
                                                    <Input
                                                        name="Duration"
                                                        className="form-control"
                                                        placeholder="Enter duration"
                                                        type="text"
                                                        defaultValue={Duration}
                                                        disabled
                                                    />
                                                </div>
                                            }
                                        </Col> */}
                                        {adminContentDetail?.Remarks_For_Pitch &&
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Remarks For Pitch</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="textarea"
                                                    rows={3}
                                                    defaultValue={RemarksForPitch}
                                                    disabled
                                                />
                                            </div>
                                        }
                                        {adminContentDetail?.TypeOfContent === "Article" ? <>
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Pitch Story</Label>
                                                    <div className="form-control form-control-textarea storyfont" dangerouslySetInnerHTML={{ __html: pitchStory }} />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">What is the story?</Label>
                                                    <div className="form-control form-control-textarea OriginalStoryfont" dangerouslySetInnerHTML={{ __html: story }} />
                                                </div>
                                            </Col>
                                        </> : <></>}
                                        <div className="form-group mb-3">
                                            <Label className="form-label">Impact on Markets</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                placeholder="Enter User Name"
                                                type="textarea"
                                                rows={5}
                                                defaultValue={Impacts}
                                                disabled
                                            />
                                        </div>

                                        {adminContentDetail?.Article_Images && adminContentDetail?.Article_Images?.length !== 0 && (
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Article image</Label>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {adminContentDetail.Article_Images.map((image, index) => (
                                                        <div key={index} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                                            <img
                                                                src={image}
                                                                alt={`Image ${index + 1}`}
                                                                style={{ maxWidth: '150px', maxHeight: '150px', marginBottom: '5px' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {(adminContentDetail?.TypeOfContent === "Article" && adminContentDetail?.Caption) &&
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Caption for article image</Label>
                                                    <Input
                                                        name="Caption"
                                                        className="form-control"
                                                        placeholder="Enter the caption for article image"
                                                        type="text"
                                                        defaultValue={Caption}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        }
                                        {adminContentDetail?.Approved_On &&
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label"> {adminContentDetail?.TypeOfContent === "Article" ? "Article Publish Date" : "Podcast Publish Date"}</Label>
                                                    <Input
                                                        name="ApprovedOn"
                                                        className="form-control"
                                                        type="text"
                                                        defaultValue={ApprovedOn}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        }

                                        {adminContentDetail?.Status === "Approved" && adminContentDetail?.Content_Status_Updated_By && (
                                            <>
                                                <Col md="12">
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">
                                                            Content Approved By
                                                        </Label>
                                                        <Input
                                                            name="Content Status"
                                                            className="form-control ibanclass"
                                                            type="textarea"
                                                            rows={1}
                                                            defaultValue={`${adminContentDetail?.Content_Status_Updated_By?.Name} - ${adminContentDetail?.Content_Status_Updated_By?.Type_Of_User}`}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        )}

                                        {adminContentDetail?.Status === "Approved" && adminContentDetail?.Content_Updated_By && (
                                            <>
                                                <Col md="12">
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">
                                                            Last updated by
                                                        </Label>
                                                        <Input
                                                            name="Content Status"
                                                            className="form-control ibanclass"
                                                            type="textarea"
                                                            rows={1}
                                                            defaultValue={`${adminContentDetail?.Content_Updated_By?.Name} - ${adminContentDetail?.Content_Updated_By?.Type_Of_User}`}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        {adminContentDetail?.TypeOfContent === "Podcast" ?
                                            <>
                                                {PodcastFile && (
                                                    <div className="form-group mb-3">
                                                        <label className="form-label">Podcast File</label>
                                                        <div>
                                                            <audio key={audioKey} controls>
                                                                <source src={PodcastFile} type="audio/mpeg" />
                                                            </audio>
                                                        </div>
                                                    </div>
                                                )}
                                                {adminContentDetail?.Thumbnail_Image &&
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Thumnail Image</Label>
                                                        <div>
                                                            <img
                                                                src={ThumnailImage}
                                                                alt="Selected"
                                                                style={{ padding: '10px', maxWidth: '50%', maxHeight: '150px' }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {adminContentDetail?.Podcast_Submitted_By &&
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Podcast uploaded by</Label>
                                                        <div>
                                                            <Input
                                                                name="podcastUploadedBy"
                                                                className="form-control"
                                                                type="text"
                                                                defaultValue={`${adminContentDetail?.Podcast_Submitted_By?.Name} - ${adminContentDetail?.Podcast_Submitted_By?.Type_Of_User}`}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </> : <></>}
                                        {adminContentDetail?.RemarksForContent &&
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Remarks For Content</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="textarea"
                                                    rows={3}
                                                    defaultValue={RemarksForContent}
                                                    disabled
                                                />
                                            </div>
                                        }
                                        {adminContentDetail?.RejectionReason &&
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Rejection Reason</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="textarea"
                                                    rows={3}
                                                    defaultValue={RejectionReason}
                                                    disabled
                                                />
                                            </div>
                                        }

                                        <div className="mt-5">
                                            {(adminContentDetail?.Status === 'New' || adminContentDetail?.Status === 'Updated') &&
                                                <>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        className="me-3"
                                                        onClick={handleEditAndApproveContent}
                                                    >
                                                        Edit & Approve
                                                    </Button>
                                                    {/* <Button
                                                        type="button"
                                                        color="success"
                                                        className="me-3"
                                                        onClick={() => handleShowModel('Approve')}
                                                    >
                                                        Approve
                                                    </Button> */}
                                                    <Button
                                                        type="button"
                                                        color="warning"
                                                        className="me-3"
                                                        onClick={() => handleShowModel('Reject')}
                                                    >
                                                        Reject
                                                    </Button>
                                                </>
                                            }
                                            {state?.data?.Status === 'Approved' &&
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="me-3"
                                                    onClick={() => navigate('/edit-content-details', { state: { data: adminContentDetail, type: 'Approved' } })}
                                                >
                                                    Edit
                                                </Button>
                                            }
                                            <Button
                                                type="button"
                                                color="danger"
                                                // onClick={() => navigate(`/content/${state?.type}`)}
                                                onClick={handleModelClose}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
                <ApproveOrRejectContentModal
                    modal={showPopup?.TypeOfModal === 'APPROVEORREJECTCONTENT' && showPopup?.isOpen}
                    data={adminContentDetail}
                    toggle={handleCloseModel}
                    title={showPopup?.data?.modalType === 'Approve' ?
                        "approve" : "reject"}
                    buttonName={showPopup?.data?.modalType === 'Approve' ?
                        "approve" : "reject"}
                    message={showPopup?.data?.modalType === 'Approve' ?
                        <>
                            {state?.data?.Status === 'New' ?
                                <>
                                    <Label className="form-label">Price</Label>
                                    <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '5px' }}>

                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Input
                                            type="text"
                                            value={price}
                                            onChange={handlePriceChange}
                                            style={{ width: '30%', border: 'none', outline: 'none' }}
                                        />
                                    </div>
                                    <Label className="form-label mt-3">Remarks</Label>
                                    <Input
                                        type="textarea"
                                        placeholder="Enter your remarks here..."
                                        value={approveReason}
                                        onChange={handleInputChange}
                                    />
                                    <Label className="form-label mt-3">Article Date</Label>
                                    <DatePicker className="articleDate"
                                        selected={articleDate}
                                        onChange={handleChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        maxDate={maxSelectableDate}
                                        placeholderText="select date and time"
                                    />
                                    <p className="text-danger text-size pt-2">{dateError}</p>
                                </>
                                : <>
                                    <Label className="form-label mt-3">Remarks</Label>
                                    <Input
                                        type="textarea"
                                        placeholder="Enter your remarks here..."
                                        value={approveReason}
                                        onChange={handleInputChange}
                                    />
                                    <Label className="form-label mt-3">Article Date</Label>
                                    <DatePicker className="articleDate"
                                        selected={articleDate}
                                        onChange={handleChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        maxDate={maxSelectableDate}
                                        placeholderText="select date and time"
                                    />
                                    <p className="text-danger text-size pt-2">{dateError}</p>
                                </>
                            }
                        </> :
                        <>
                            <Input
                                type="textarea"
                                placeholder="Enter your reject reason here..."
                                value={rejectReason}
                                onChange={handleRejectChange}
                            />
                            <p className="text-danger text-size pt-2">{rejectError}</p>
                        </>
                    }
                    handleSubmit={handleApprovalStatus}
                    type={showPopup?.data?.modelType}
                />
            </div>
        </React.Fragment>
    )
}