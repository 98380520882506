export const WithdrawActions = {
    CREATE_WITHDRAW_REQUEST: "CREATE_WITHDRAW_REQUEST",

    GET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID: "GET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID",
    SET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID: "SET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID",

    GET_ALL_WITHDRAW_REQUEST_LIST:'GET_ALL_WITHDRAW_REQUEST_LIST',
    SET_ALL_WITHDRAW_REQUEST_LIST:'SET_ALL_WITHDRAW_REQUEST_LIST',

    GET_WITHDRAW_REQUEST_DETAIL: 'GET_WITHDRAW_REQUEST_DETAIL',
    SET_WITHDRAW_REQUEST_DETAIL: 'SET_WITHDRAW_REQUEST_DETAIL',

    UPDATE_WITHDRAW_REQUEST_STATUS : "UPDATE_WITHDRAW_REQUEST_STATUS"
}