import AdminPitchDetailIndex from "components/adminPitchDetail/index";
import React from "react";

export default function adminPitch () {

    return(
        <div>
            <AdminPitchDetailIndex />
        </div>
    )
}