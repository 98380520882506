import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from '../../common/TableContainer';
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { revenueActions } from '../../store/revenue/actions';
import { commonActions } from "../../store/common/actions";

export default function revenueDetailsIndex() {
    document.title = "Revenue | Allen Dreyfus";

    const { state } = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { adminPageLoader } = useSelector(state => state.commonReducer);
    const { adminRevenueDetail } = useSelector(state => state.revenueReducer);

    const Id = state?._id;

    useEffect(() => {
        dispatch({ type: revenueActions.GET_ADMIN_REVENUE_DETAIL, payload: Id });
    }, [Id]);


    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Revenue"
                            titleLink="/revenue"
                            breadcrumbItem="details"
                        />
                        <h4 className="card-title mb-4">Revenue Details</h4>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Contributor Name</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminRevenueDetail?.First_Name}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Subscription Plan</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminRevenueDetail?.Subscription_Plan}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Subscription Price</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminRevenueDetail?.Stripe_Product_Price}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Subscribed Date
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={adminRevenueDetail?.Stripe_Subscription_Start_Date}
                                            disabled
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate('/revenue')}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
};