import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Card, CardBody } from "reactstrap"
import { dashboardActions } from "../../store/dashboard/actions"
import { useNavigate } from "react-router-dom"
import AdminContentPublishedChart from './adminContentPublishedCart';
import { BlogContentActions } from "../../store/blogContent/actions";

export default function AdminDashboardCount() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [endUserCount, setEndUserCount] = useState(0)
    const [contentCreatorCount, setContentCreatorCount] = useState(0)
    const [Registration_Status] = useState("Registered")
    const [contentCount, setContentCount] = useState(0)
    const [pitchCount, setPitchCount] = useState(0)

    const { adminEndUserCount, adminContentCreatorsCount } =
        useSelector(state => state.DashboardReducer)

    const {  ContentNeedApprovalCount } = useSelector(state => state.BlogContentReducer);
    const { adminCreatorPitchNeedApprovalList } = useSelector(state => state.BlogContentReducer)

    useEffect(() => {
        dispatch({
            type: dashboardActions.GET_ADMIN_END_USER_COUNT,
        })
        dispatch({
            type: dashboardActions.GET_ADMIN_CONTENT_CREATOR_COUNT, payload: Registration_Status
        })
        dispatch({
            type: BlogContentActions.GET_ADMIN_CONTENT_LIST_NEED_APPROVAL,
        });
        dispatch({
            type: BlogContentActions.GET_ADMIN_CREATOR_PITCH_NEED_APPROVAL_LIST,
        });
    }, [dispatch])

    useEffect(() => {
        if (adminEndUserCount) {
            setEndUserCount(adminEndUserCount?.count)
        }
        if (adminContentCreatorsCount) {
            setContentCreatorCount(adminContentCreatorsCount?.count)
        }
        if (adminCreatorPitchNeedApprovalList) {
            setPitchCount(adminCreatorPitchNeedApprovalList?.length)
        }
        if (ContentNeedApprovalCount) {
            setContentCount(ContentNeedApprovalCount)
        }
    }, [adminEndUserCount, adminContentCreatorsCount, ContentNeedApprovalCount])

    const redirectToPage = role => {
        switch (role) {
            case "End-User":
                // navigate("/customers")
                break
            case "Content Creator":
                navigate("/content-creators/Registered")
                break
            case "Pitch":
                navigate("/admin/pitch/needApproval")
                break
            case "Content":
                navigate("/content/needApproval")
                break
        }
    }

    const reports = [
        {
            title: "End-Users",
            iconClass: "mdi-account-group-outline",
            description: endUserCount,
            role: "End-User",
        },
        {
            title: "Content Creators",
            iconClass: "mdi-account-multiple-check-outline",
            description: contentCreatorCount,
            role: "Content Creator",
        },
        {
            title: "Need Approval Pitch",
            iconClass: "mdi-note-text",
            description: pitchCount,
            role: "Pitch",
        },
        {
            title: "Need Approval Content",
            iconClass: "mdi-content-save-all-outline",
            description: contentCount,
            role: "Content"
        }
    ];

    return (
        <Row>
            <Col md="3" >
                {reports.map((report, key) => (
                    <>
                        <Card
                            className="mini-stats-wid"
                            onClick={() => redirectToPage(report.role)}
                        >
                            <CardBody>
                                <div className="d-flex" style={{ cursor: "pointer" }}>
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">{report?.title}</p>
                                        <h4 className="mb-0">{report?.description ? report.description : '0'}</h4>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                        <span className="avatar-title rounded-circle bg-primary">
                                            <i
                                                className={
                                                    "mdi " + report.iconClass + " font-size-24"
                                                    // "bx " + report.iconClass + " font-size-24"
                                                }
                                            ></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </>))}
            </Col>
            <Col md="9" >
                <AdminContentPublishedChart />
            </Col>
        </Row>
    )
}
