import React, { useEffect, useMemo, useState } from "react";
import {
  Card, CardBody, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerActions } from "../../store/customer/actions";
import TableContainer from '../../common/TableContainer';
import Breadcrumbs from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from "../../common/CustomPagination";

const CustomersIndex = () => {

  document.title = "Customers | Allen Dreyfus";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [exportStatus, setExportStatus] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const { adminPageLoader } = useSelector(state => state.commonReducer);

  const { customerList } = useSelector(state => state.customerReducer);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (customerList && customerList?.map(val => (val?.IsSubscribed === "Yes"))) {
      setSubscriptionStatus("Subscribed")
    } else {
      setSubscriptionStatus("-")
    }
  }, [customerList?.IsSubscribed])

  const [csvData, setCSVData] = useState([]);

  useEffect(() => {
    setCSVData(customerList);
  }, [customerList]);

  useEffect(() => {
    dispatch({ type: customerActions.GET_CUSTOMER_LIST });
  }, []);


  const csvHeaders = [
    { label: "First Name", key: "First_Name" },
    { label: "Last Name", key: "Last_Name" },
    { label: "Email", key: "Email" },
    { label: "Contact Number", key: "Contact_Number" },
    { label: "Preferences", key: "Preferences" },
    { label: "Subscription Status", key: "IsSubscribed" },
    { label: "Subscription Plan", key: "Stripe_Product_Duration" },
    { label: "Subscription Price", key: "Stripe_Product_Price" },
    { label: "Subscribed Date", key: "Stripe_Subscription_Start_Date" },
    { label: "News Letter Subsription Status", key: "IsNewsLetterSubscribed" },
    { label: "News Letter Subscribed Date", key: "IsNewsLetterSubscribedOn" },
  ];

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Id',
      //   accessor: '_id',
      //   filterable: true,
      // },
      {
        Header: 'User Name',
        accessor: 'UserName',
        filterable: true,
      },
      {
        Header: 'Email',
        accessor: 'Email',
        filterable: true,
      },
      {
        Header: 'Contact Number',
        accessor: 'Contact_Number',
        filterable: true,
      },
      {
        Header: 'Subscription Status',
        accessor: row => (row.IsSubscribed === "Yes" ? "Subscribed" : "-"),
        filterable: true,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                  const customerData = cellProps.row.original;
                  navigate('/customer-details', { state: customerData });
                }}
              >
                View
              </Button>
            </div>
          );
        }
      },
    ], []
  );

  const handleSuccess = () => {
    setExportStatus('success');
    toast.success(' Customerslist Export successful!', { position: toast.POSITION.TOP_CENTER });
  };

  const handleError = (error) => {
    setExportStatus('error');
    toast.error('Failure Export', { position: toast.POSITION.TOP_CENTER });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {adminPageLoader ?
          <AdminPageLoader /> :
          <Container fluid>
            <Breadcrumbs
              title="Customers"
              breadcrumbItem="list"
            />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {/* need approval for this export */}
                    {/* <div className="d-flex justify-content-end mb-2">
                      <CSVLink data={customerList} headers={csvHeaders} filename={"customerlist_data.csv"}
                        onClick={() => handleSuccess()}
                        onError={handleError}>
                        <Button color="primary" >Export</Button>
                      </CSVLink>
                    </div> */}
                    <>
                      <TableContainer
                        columns={columns}
                        data={customerList || []}
                        isGlobalFilter={true}
                        isAddCustList={false}
                        isPagination={true}
                        customPageSize={itemsPerPage}
                        tableClass="align-middle table-nowrap"
                        isShowingPageLength={false}
                        iscustomPageSizeOptions={true}
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  );
};

export default CustomersIndex;
