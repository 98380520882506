import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { dashboardActions } from "../../store/dashboard/actions"

export default function SubscribersCount() {
  const dispatch = useDispatch()

  const [totalCount, setTotalCount] = useState("")
  const [currentMonthCount, setCurrentMonthCount] = useState("")

  const { adminTotalSubscriptionCount, adminCurrentMonthSubscriptionCount } =
    useSelector(state => state.DashboardReducer)

  useEffect(() => {
    dispatch({
      type: dashboardActions.GET_ADMIN_TOTAL_SUBSCRIPTION_COUNT,
    })
    dispatch({
      type: dashboardActions.GET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT,
    })
  }, [dispatch])

  useEffect(() => {
    if (adminTotalSubscriptionCount) {
      setTotalCount(adminTotalSubscriptionCount?.count)
    }
    if (adminCurrentMonthSubscriptionCount) {
      setCurrentMonthCount(adminCurrentMonthSubscriptionCount?.count)
    }
  }, [adminTotalSubscriptionCount, adminCurrentMonthSubscriptionCount])

  return (
    <Card>
      <CardBody>
        <a href="/revenue">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="card-title mb-3 me-2">Subscribers</h5>
            {/* <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                tag="a"
                className="text-muted font-size-16"
                role="button"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">View</DropdownItem>
                        </DropdownMenu>
            </UncontrolledDropdown> */}
          </div>
          <div className="d-flex flex-wrap">
            <div>
              <p className="text-muted mb-1">Total Subscribe</p>
              <h4 className="mb-3">{totalCount}</h4>
              <p className="text-muted mb-1">This Month</p>
              <h4 className="mb-3">{currentMonthCount}</h4>
            </div>
            <div className="ms-auto justify-content-end align-items-center">
              <i className="bx bx-group display-4 text-light"></i>
            </div>
          </div>
        </a>
      </CardBody>
    </Card>
  )
}
