import AddPayoutsIndex from "components/adminAddPayouts";
import React from "react";

export default function AddPayouts() {

    return (
        <div>
            <AddPayoutsIndex />
        </div>
    )
}