import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import Logoimage from "../../assets/webImages/png-logo-color.webp"
import Logo from "../../assets/webImages/png-logo-white.webp"
import ReturnToTop from "../../webComponents/Common/7604589.png"
import { useNavigate } from "react-router-dom"



const TermsOfService = () => {
  document.title = "Terms of Use"
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const navigate = useNavigate()

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const handleScroll = () => {
      // Show the scroll-to-top button when scrolling down
      setShowScrollToTop(window.scrollY > 100)
    }

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll)

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handlebacktohome = () => {
    navigate("/")
  }

  return (
    <div>
      <Container>
        <div className="privacy-policy-full-content">
          <Row className="align-items-start justify-content-end">
            <Col>
              <div className="mt-3">
                <div>
                  <img src={Logoimage} alt="Logo" className="logo-ad" onClick={handlebacktohome} />
                </div>
                <div className="text-center">
                  <h1 className="pp-heading-text">terms of use</h1>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="introduction">
                <h4>
                  general conditions of use and sale of the digital editions of
                  allen dreyfus (ad) website and mobile application
                </h4>
              </div>
              <p className="web_fonts pp-text">
                these general terms and conditions of online subscription are
                offered to you by allen dreyfus in the united kingdom (uk),
                registered under number: 14879258, whose office is located at
                35/37 ludgate hill, london, united kingdom ec4m 7jn , {" "}
                <a className="email-link" href="mailto:info@allendreyfus.com">
                  {" "}
                  info@allendreyfus.com
                </a>
                {" "}{" "}, +44 (0) 330 828 5058
              </p>
              <p className="web_fonts pp-text">
                this website is hosted by amazon web service
              </p>
              <div className="introduction">
                <h4>i. definitions and scope of application</h4>
              </div>
              <p className="web_fonts pp-text">
                allen dreyfus publishes the following digital media:
              </p>
              <p className="web_fonts pp-text">
                – the website allendreyfus.com
              </p>
              <p className="web_fonts pp-text">
                – the mobile application allen dreyfus
              </p>
              <p className="web_fonts pp-text">
                – the newsletters published by allen dreyfus
              </p>
              <p className="web_fonts pp-text">
                these general conditions govern our relations with subscribers
                and/or users, subscription, access to services, use of online
                participatory information services, as well as all related
                services that we offer you via the digital media listed above
                (hereinafter referred to as “digital services”).{" "}
              </p>
              <p className="web_fonts pp-text">
                {" "}
                these conditions are supplemented by the attached document
                “terms of use for our website and application”, thus forming our
                “contractual framework”:
              </p>
              <p className="web_fonts pp-text">
                contracts are presented to each subscriber prior to the first
                subscription or access offered.
              </p>

              <p className="web_fonts pp-text">
                the aforesaid “contractual framework” is binding on all
                subscribers and users and binds them mutually to allen dreyfus,
                without conditions or reservations, thus allowing the uniform
                use of allen dreyfus’s services, regardless of the type of
                subscription chosen.
              </p>
              <p className="web_fonts pp-text">
                the possible tolerance of a contractual breach does not
                constitute a waiver by allen dreyfus to use it. in the event of
                the invalidity of one of the clauses of the contract, the other
                clauses shall remain in full force and effect.
              </p>
              <div className="introduction">
                <h4>ii. access and subscription terms and conditions</h4>
              </div>
              <p className="web_fonts pp-text">
                full access to the websites of allendreyfus.com and allen
                dreyfus mobile application with associated services implies for
                some content the subscription of a paid subscription and the
                creation of a user account, allowing the subscriber to
                authenticate himself. if you subscribe to free newsletters only,
                you might need to create a guest account.{" "}
              </p>
              <p className="web_fonts pp-text">
                the allen dreyfus account corresponds to an identifier,
                associated with a password, both of which are specific and
                strictly personal to the subscriber. they allow any subscriber
                (excluding corporate subscriptions), via a dedicated interface,
                to access the subscription, subscribe/renew/change current
                contracts and services.
              </p>
              <p className="web_fonts pp-text">
                once the account has been created, allen dreyfus will send an
                acknowledgment of receipt to the email address indicated in the
                subscription form, as confirmation of acceptance of the
                subscription request.
              </p>
              <p className="web_fonts pp-text">
                in the event of a corporate subscription, it is the main
                subscriber who chooses and proceeds with the subscription
                procedures described below.
              </p>
              <div className="introduction">
                <h4>
                  {" "}
                  our current subscription offers are available on our website
                  and fall into three categories:
                </h4>
              </div>
              <p className="web_fonts pp-text">– individual subscriptions,</p>
              <p className="web_fonts pp-text">– corporate subscriptions,</p>
              <p className="web_fonts pp-text">
                – specific and punctual promotional offers for individual
                subscriptions. the offers in force are those posted on the day
                the order is placed, including promotional offers.
              </p>
              <div className="introduction">
                <h4>individual subscription</h4>
              </div>
              <p className="web_fonts pp-text">
                individual subscription offers allow each user to have personal
                access to the service for a period of one month and one year,
                tacitly renewable unless terminated before the end of the
                current period in accordance with the terms set out in point
                viii or fixed-term subscription plans. the subscription is
                intuitu personae. the subscriber undertakes not to share his
                access codes to the service with third parties under penalty of
                immediate termination of his subscription without possible
                refund.
              </p>
              <div className="introduction">
                <h4>corporate subscriptions</h4>
              </div>
              <p className="web_fonts pp-text">
                corporate subscriptions are subscriptions reserved for
                professionals, companies, associations, administrations, local
                authorities or any other legal entity. corporate subscription
                offers allow any company, association, community or other legal
                entity, known as the “main subscriber”, to subscribe to a
                specific number of subscriptions, payable on the day of
                subscription and for a minimum period of one year, in order to
                give the selected persons, known as “beneficiary users”, their
                own right of access and use of the subscribed services.
              </p>
              <p className="web_fonts pp-text">
                in this respect, a professional subscription manager, the
                “subscription manager”, must be appointed at the time of
                subscription. the subscription manager must designate,
                individually and by name, the beneficiary users to whom it
                intends to assign said access rights and services. to this end,
                the subscription manager must provide a unique and valid
                professional nominative email address for each beneficiary user.
              </p>
              <p className="web_fonts pp-text">
                only the subscription manager of the main subscriber may, at his
                sole discretion and responsibility, grant, suspend, terminate or
                modify the access rights of each beneficiary user during the
                subscription period, for the duration he determines.
              </p>
              <p className="web_fonts pp-text">
                each beneficiary user will be directly informed by e-mail of the
                right that the subscription manager of the main subscriber
                intends to grant them. allen dreyfus cannot be held liable for
                any allocations or suspensions of access decided by the
                subscription manager alone. all information relating to these
                professional offers can be requested by e-mail at {" "}
                <a
                  className="email-link"
                  href="mailto:subscriptions@allendreyfus.com"
                >
                  subscriptions@allendreyfus.com
                </a>
              </p>
              <div className="introduction">
                <h4>promotional offers</h4>
              </div>
              <p className="web_fonts pp-text">
                allen dreyfus offers a 30-day free trial for the subscription of
                individual subscriptions, some of which are subject to the entry
                of a so-called “promotional” code. the latter must be entered by
                the user when subscribing to the offer in order to benefit from
                it
              </p>
              <p className="web_fonts pp-text">
                due to the uniqueness of the promotional offer, if allen dreyfus
                notices that a user benefits from an offer that he or she has
                already used before, the user may have his or her access
                interrupted or even deleted, without notice and without the
                benefit of any refund or compensation, or will be invited by
                allen dreyfus to subscribe to a subscription offer to which he
                or she may choose to opt or not.
              </p>
              <p className="web_fonts pp-text">
                allen dreyfus reserves the right to modify its offers at any
                time, or to propose other temporary promotional offers, under
                the conditions detailed on our website. allen dreyfus offers two
                types of subscriptions:
                <ul>
                  <li>monthly plan</li>
                  <li>annual plan</li>
                </ul>
              </p>

              <p className="web_fonts pp-text">
                the monthly subscription is for an indefinite period with a
                minimum commitment of one month. the annual subscription is for
                an indefinite period with a minimum commitment of one year.
              </p>
              <p className="web_fonts pp-text">
                at the end of the minimum commitment period, whether this is a
                month or a year, the subscription will be automatically renewed
                for the same term to which the user was originally subscribed.
                in the case of tacit renewal, the current rate in place for the
                subscription concerned will apply.
              </p>
              <p className="web_fonts pp-text">
                allen dreyfus will send an email at least one month before the
                end of the subscription, notifying subscribers of their right
                not to renew the contract. the subscription may then be
                terminated up until the date specified in the notification
                email. if the user does not cancel their subscription within the
                specified time, it will be renewed for a further period of one
                year.
              </p>
              <p className="web_fonts pp-text">
                the reference price for the subscription is £49:99 per month and
                £499.99 per year.
              </p>
              <div className="introduction">
                <h4>iii. rates and payments conditions</h4>
              </div>
              <p className="web_fonts pp-text">
                payment for the subscription is made according to the rates and
                terms in force on allen dreyfus’s digital services. the
                subscription is payable at the time of subscription or, if
                applicable, at the time of renewal
              </p>
              <p className="web_fonts pp-text">
                the subscriber is solely responsible for the payment of all sums
                due under the subscription. however, a third party may pay the
                subscription on behalf of the subscriber, without this giving
                him/her any special rights, except in the case of corporate
                subscriptions.
              </p>
              <p className="web_fonts pp-text">
                several payment methods are available from allen dreyfus:
              </p>
              <p className="web_fonts pp-text"> – credit card / paypal </p>
              <p className="web_fonts pp-text">
                {" "}
                the prices are available on allen dreyfus’s digital services,
                indicated in pounds sterling and are “all taxes included” (ttc)
                except for corporate subscriptions for which the prices are
                exclusive of tax. they do not include costs related to
                electronic equipment and communications, necessary for the
                subscription and use of the services, which remain the
                responsibility of the subscriber.
              </p>
              <p className="web_fonts pp-text">
                {" "}
                for corporate subscriptions, subscriptions attached to the main
                subscription are prepaid, due in any event, whether or not they
                are assigned to a beneficiary user and whether or not they are
                used by the beneficiary user of the main subscription, without
                the subscriber being entitled to any reimbursement.
              </p>
              <p className="web_fonts pp-text">
                the main subscriber of a corporate subscription must allocate
                the prepaid subscriptions to the beneficiary users of his choice
                at the time of subscription. subscriptions not allocated by the
                main subscriber or not used by the beneficiary users shall not
                be subject to any refund due to the non-assignment or non-use of
                the service.{" "}
              </p>

              <p className="web_fonts pp-text">
                {" "}
                in the event of unsuccessful payment by credit card or paypal,
                and except in the event of theft or opposition, our secure
                payment service provider may be required to represent the
                payment until it is regularised.
              </p>
              <div className="introduction">
                <h4>to cancel an individual subscription</h4>
              </div>
              <p className="web_fonts pp-text-termsofuse">
                to cancel an individual subscription for an unlimited period of
                time: the procedure is indicated online.
                <ul>
                  <ol>1. log in to your account.</ol>
                  <ol>2. find the "profile" section while logged in.</ol>
                  <ol>
                    3. in the "profile" area, look for the
                    option to  "unsubscribe".
                  </ol>
                  <ol>
                    4. click on the "unsubscribe" button. your subscription will end.
                  </ol>

                  <ol>
                    5. if you wish to subscribe again , you can use the same
                    log in and password.
                  </ol>
                </ul>
              </p>
              <div className="introduction">
                <h4>iv. our services</h4>
              </div>
              <p className="web_fonts pp-text">
                {" "}
                subscriptions to the various services allow you to consult all
                the articles published by the editorial team directly online on
                the various media offered, website and mobile applications.{" "}
              </p>
              <p className="web_fonts pp-text">
                allen dreyfus may offer new services, free or for a fee, the
                subscription and/or use of which may be subject to compliance
                with certain technical criteria or limitations, which will then
                be specified to the subscriber and/or user.
              </p>
              <div className="introduction">
                <h4>v. changes to the general conditions</h4>
              </div>

              <p className="web_fonts pp-text">
                allen dreyfus reserves the right to adapt or modify at any time
                these general terms and conditions of subscription and general
                terms and conditions of sale. the new general conditions will,
                if necessary, be brought to the user’s attention by online
                modification.
              </p>
              <div className="introduction">
                <h4>vi. rules of use and limitation of liability</h4>
              </div>
              <p className="web_fonts pp-text">
                creating a user account does not create rights in itself. it is
                a declarative procedure allowing you to enter your contact
                details (title, surname, first name, email, country, etc.). it
                is the responsibility of the subscriber and/or user to declare
                exact contact details and keep them up to date in the event of
                any changes.
              </p>
              <p className="web_fonts pp-text">
                on the basis of the declared information, the account is
                associated with a “username” and “password”, chosen by the
                subscriber and which are strictly personal to him. each
                subscriber and/or user acknowledges and accepts that any use
                made of allen dreyfus’s services via his account will be deemed
                to be made under his responsibility, with his agreement and is
                strictly personal. the transmission to a third party and the use
                of these access codes by a third party is strictly prohibited.
                failure to comply with this rule will result in the temporary
                suspension, after a first warning, or even the cancellation, in
                the event of a repeat subscription. for corporate subscribers,
                exceptions may be granted depending on the offers subscribed.{" "}
              </p>
              <p className="web_fonts pp-text">
                if the password is forgotten, the subscriber can request its
                recovery by using the “forgotten password?”. in the event of
                accidental disclosure or theft of the password, the subscriber
                must replace it as soon as possible, and inform allen dreyfus
                subscriber service by e-mail at{" "}
                <a
                  className="email-link"
                  href="mailto:subscriptions@allendreyfus.com"
                >
                  subscriptions@allendreyfus.com
                </a>
              </p>
              <p className="web_fonts pp-text">
                allen dreyfus’s subscriber service will, at the subscriber’s
                request only, deactivate usurped access codes. the subscriber
                will then have to choose a new password, possibly a new id.
                please note that the passwords chosen by subscribers are
                encrypted and therefore no allen dreyfus employee can access
                them. if the password is lost or forgotten, the subscriber will
                have to reset it using the “forgotten password” function.{" "}
              </p>
              <p className="web_fonts pp-text">
                {" "}
                in the absence of prompt or immediate action, the account holder
                will be held liable for any loss, theft, misappropriation, or
                unauthorized use of his access codes and their consequences.
              </p>
              <p className="web_fonts pp-text">
                unless expressly authorized in writing and in advance by allen
                dreyfus, the subscription does not allow articles to be
                distributed or reproduced for professional purposes.{" "}
              </p>
              <div className="introduction">
                <h4>
                  specifics limitations to the use of the internet network
                </h4>
              </div>

              <p className="web_fonts pp-text">
                allen dreyfus undertakes to make its best efforts to secure
                access, consultation and use of information in accordance with
                the rules of use of the internet. the sites are accessible 24
                hours a day, 7 days a week, except in the event of force majeure
                or an event beyond the control of allen dreyfus and subject to
                any breakdowns and maintenance interventions necessary for the
                proper functioning of the site. maintenance work may be carried
                out without prior notification to subscribers. the subscriber
                uses the services of allen dreyfus as they stand. the subscriber
                also declares that he/she accepts the characteristics and
                limitations of the internet, and in particular acknowledges
                that:
              </p>
              <p className="web_fonts pp-text">
                – its use of mobile sites and applications is at its own risk;{" "}
              </p>
              <p className="web_fonts pp-text">
                {" "}
                – mobile sites and applications are accessible to it “as is” and
                according to their availability;{" "}
              </p>
              <p className="web_fonts pp-text">
                – any downloading by the subscriber is done under his
                responsibility;
              </p>

              <p className="web_fonts pp-text">
                – the subscriber is solely responsible for any damage to his
                computer, telephone, tablet or any loss of data resulting from
                the downloading of this material or consulting the site or
                mobile applications;
              </p>
              <p className="web_fonts pp-text">
                – it is the subscriber’s responsibility to take all appropriate
                measures to protect his own data and/or software from
                contamination by any viruses circulating through the sites
                and/or information and content;{" "}
              </p>
              <p className="web_fonts pp-text">
                – no advice and no information, whether oral or written,
                obtained by the subscriber of allen dreyfus or during the use of
                the sites are likely to create guarantees not expressly provided
                for by these general conditions;
              </p>
              <p className="web_fonts pp-text">
                – it is solely responsible for the use made of the information
                on the sites and applications and, consequently, allen dreyfus
                cannot be held liable for any direct or indirect damage
                resulting from the use of this information;{" "}
              </p>

              <p className="web_fonts pp-text">
                – it is solely responsible for the use of the content of sites
                or applications with a hypertext link to the sites or
                applications, allen dreyfus declines any responsibility for such
                content;{" "}
              </p>

              <p className="web_fonts pp-text">
                – he is aware of the nature of the internet, in particular its
                technical performance and response times for consulting,
                querying or transferring information;
              </p>
              <p className="web_fonts pp-text">
                – the communication of its access codes or, in general, of any
                information deemed confidential is done under its own
                responsibility;
              </p>
              <p className="web_fonts pp-text">
                – it is up to him to take all necessary measures to ensure that
                the technical characteristics of his equipment allow him to
                consult the information.{" "}
              </p>
              <p className="web_fonts pp-text">
                {" "}
                the services offered by allen dreyfus comply with current
                english legislation. all information and data disseminated in
                publications published by allen dreyfus are provided for
                information purposes only. allen dreyfus cannot be held liable
                for any damage, direct or indirect, that may result from errors,
                omissions or delays in the transmission or publication of this
                information.
              </p>
              <p className="web_fonts pp-text">
                hypertext links on the sites may lead to other sites. allen
                dreyfus does not control these contents a priori and assumes no
                responsibility for the sites and contents to which these links
                refer.
              </p>
              <p className="web_fonts pp-text">
                however, allen dreyfus will remove such links or content
                published on its website after the event, as part of a
                notification of the illegal or harmful nature of such links or
                content. allen dreyfus may not, however, delete content
                published on sites other than its own.
              </p>
              <p className="web_fonts pp-text">
                each subscriber and/or user acknowledges and accepts that,
                taking into account the laws in force, allen dreyfus or its
                hosting provider may delete or make it impossible to access all
                or part of the content. allen dreyfus cannot be held liable for
                this fact.
              </p>
              <div className="introduction">
                <h4>major force</h4>
              </div>

              <p className="web_fonts pp-text">
                allen dreyfus declines all responsibility for any breach of any
                of its contractual obligations in the event of force majeure or
                fortuitous circumstances such as, but not limited to: disasters,
                fires, internal or external strikes, internal or external
                failures or breakdowns, and in general any event not allowing
                the proper transmission and/or execution of data.
              </p>
              <div className="introduction">
                <h4>vii. miscellaneous provisions</h4>
              </div>
              <p className="web_fonts pp-text">
                at the time of subscription, the person declares that he/she has
                the legal capacity and the powers or authorisations required to
                validly conclude these general terms and conditions of sale, as
                well as any other special conditions applicable to the service
                subscribed. pursuant to english laws, non-emancipated minors are
                unable to contract.{" "}
              </p>
              <div className="introduction">
                <h4>right of withdrawal</h4>
              </div>
              <p className="web_fonts pp-text">
                the subscriber, who may be qualified as a consumer within the
                meaning of the laws in force, declares that he accepts that the
                service is provided and accessible as soon as his order is
                confirmed and his first connection to the service, which makes
                his subscription run for the chosen duration. thus, the
                subscriber expressly acknowledges that the provision of the
                service begins, with his agreement, before the expiry of the
                fourteen-day period provided for in the consumer code for
                exercising the right of withdrawal, and that he cannot claim to
                benefit from it. the subscriber acknowledges that, in any event,
                these provisions do not apply to corporate subscriptions.
              </p>
              <div className="introduction">
                <h4>suspension of the subscription</h4>
              </div>
              <p className="web_fonts pp-text">
                in accordance with the participation charter (appendix 1), allen
                dreyfus reserves the right to suspend and/or terminate the
                subscription subscribed to by the subscriber, without
                compensation or right to reimbursement, in the event of the
                subscriber’s breach of these general conditions, within 15 days
                of sending a formal notice by registered letter with
                acknowledgment of receipt (in the case of a valid address
                provided by the subscriber) or by e-mail with acknowledgment of
                receipt to the e-mail address entered at the time of
                subscription, without prejudice to the right of allen dreyfus to
                claim payment of any damages in court for its entire loss.
              </p>
              <p className="web_fonts pp-text">
                the subscriber may only transfer all or part of the rights and
                obligations resulting from his subscription with the prior
                written agreement of allen dreyfus and subject to strict
                compliance with the conditions stipulated in these general
                conditions.
              </p>
              <p className="web_fonts pp-text">
                termination does not entail any penalty but will not give rise
                to a refund of the current period, monthly or annual, depending
                on the choice made at the time of subscription. however, for
                annual subscriptions, exceptionally and restrictively, the
                refund during the period will be admissible subject to a
                legitimate reason. applications will be reviewed by the
                subscription department.{" "}
              </p>
              <div className="introduction">
                <h4>specific features specific to group subscriptions</h4>
              </div>

              <p className="web_fonts pp-text">
                the main subscriber guarantees the acceptance of and compliance
                with this contractual framework for each of the beneficiary
                users, under his full and entire responsibility. the change of
                beneficiary user by the main subscriber during the subscription
                period shall not de facto extend the conditions of use assigned
                beyond the duration of the subscription period as originally
                subscribed by the main subscriber.
              </p>
              <div className="introduction">
                <h4>intellectual property</h4>
              </div>

              <p className="web_fonts pp-text">
                all the content of our websites and applications accessible
                freely or by subscription, and in particular, but not
                exclusively: the graphic charter, title, form, site map, texts,
                logos, trademarks, images and databases accessible or made
                available, both by free access and by subscription, as well as
                all the programs and/or technologies provided in connection with
                the site and, more generally, all or part of the site itself are
                protected by the provisions of the intellectual property code.
                the same applies to archives.
              </p>
              <p className="web_fonts pp-text">
                the trademarks “allen dreyfus” and “ad” as well as the domain
                name “www.allendreyfus.com” are protected names belonging to our
                company and may not be used by the subscriber and/or user under
                any circumstances without the express prior written consent of
                allen dreyfus.{" "}
              </p>

              <p className="web_fonts pp-text">
                the subscriber undertakes to use the content made available on
                the digital services only in accordance with the personal
                consultation right granted to him/her under the terms of his/her
                subscription.
              </p>
              <p className="web_fonts pp-text">
                the contractual framework of our websites and applications does
                not entail any transfer of intellectual property rights to the
                users of our websites and applications, for any reason
                whatsoever.
              </p>
              <p className="web_fonts pp-text">
                the subscriber must make lawful and reasonable use of personal
                access to the information made available to him/her as part of
                his/her subscription. any use of the content other than that
                provided for in the subscription is strictly prohibited and may
                constitute an infringement, giving rise to legal action by allen
                dreyfus for the offender(s).{" "}
              </p>
              <p className="web_fonts pp-text">
                the information and content consulted and used in the context of
                the use of allen dreyfus’s services and strictly intended for
                subscribers may under no circumstances be disclosed or used for
                any other purpose. the offender will be held liable for any
                unauthorized use of this information, which may occur on his or
                her part or from his or her account.
              </p>
              <div className="introduction">
                <h4>viii. applicable laws & jurisdictions</h4>
              </div>

              <p className="web_fonts pp-text">
                {" "}
                the parties agree that this contract is subject to english law.
                the language of this contract is the english language. any
                dispute concerning the validity, interpretation or execution of
                this contract shall, in the absence of amicable resolution, fall
                under the exclusive jurisdiction of the english courts of the
                place where allen dreyfus has its registered office.
              </p>
              <p className="web_fonts pp-text">
                {" "}
                terms of use of allen dreyfus websites and applications by
                accessing allen dreyfus websites and applications to consult or
                use our services, you acknowledge that you comply with the laws
                in force, particularly in english, and the following conditions
                of use:
              </p>
              <div className="introduction">
                <h4>article 1. intellectual property rights</h4>
              </div>
              <p className="web_fonts pp-text">
                {" "}
                all the content of our internet site and application and in
                particular, but not exclusively, the graphic charter, title,
                form, site and application map, texts, logos, trademarks, images
                and databases accessible or made available, both free of charge
                and on subscription, as well as all the programs and/or
                technologies provided in connection with the sites and
                applications and, more generally, all or part of the sites and
                applications themselves are protected by the provisions of the
                intellectual property code.
              </p>
              <p className="web_fonts pp-text">
                in particular, trademarks and domain names are protected names
                belonging to our company and may not be used by you under any
                circumstances without our express prior written consent.
              </p>

              <p className="web_fonts pp-text">
                {" "}
                these terms and conditions do not transfer any intellectual
                property rights to users of our websites or applications for any
                reason whatsoever. any user of the websites or applications,
                whether or not a subscriber, undertakes to use the content made
                available only for lawful purposes, in accordance with the
                legislation in force and in compliance with the general
                conditions and these conditions of use.
              </p>

              <p className="web_fonts pp-text">
                {" "}
                the violation of these mandatory provisions subjects the
                offender, and any person responsible, to the criminal and civil
                penalties provided for by the laws in force, particularly in
                england.
              </p>

              <div className="introduction">
                <h4>article 2. limitation of liability</h4>
              </div>
              <p className="web_fonts pp-text">
                you acknowledge and agree that, as a user, you use our websites
                and applications as they stand, at your own risk. all the
                content we publish is provided for information purposes only.
              </p>

              <p className="web_fonts pp-text">
                in any event, we cannot be held liable for any damage, direct or
                indirect, that may result from errors, omissions and/or delays
                in the transmission and publication of the information made
                available on our websites and applications, whether freely or by
                subscription.{" "}
              </p>

              <div className="introduction">
                <h4>article 3. – exercise of the right to reply</h4>
              </div>
              <p className="web_fonts pp-text">
                in accordance with the texts in force, you have a right of
                reply, which you can exercise by sending your request by
                registered letter with acknowledgment of receipt or by any other
                means guaranteeing the identity of the applicant and providing
                proof of receipt of the request.{" "}
              </p>
              <p className="web_fonts pp-text">
                these requests may only concern content published on the allen
                dreyfus websites and applications, in accordance with the texts
                in force, and must comply with the procedures provided for in
                english laws and regulations.
              </p>
              <div className="introduction">
                <h4>article 4. changes to the terms of use of the site</h4>
              </div>
              <p className="web_fonts pp-text">
                these general terms and conditions for the use of the applicable
                sites and applications are those in effect when you connect to
                and use our websites and applications, which you declare that
                you have read on our websites or applications and that you
                undertake to comply with them without restriction or
                reservation.
              </p>
              <p className="web_fonts pp-text">
                the conditions of use of the site are subject to modification or
                evolution, in particular in order to take into account any
                jurisprudential, legal or technical evolution. the changes will
                take effect on the day they are published on our websites and
                applications.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="footer-container">
        <footer>
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <a href="/">
                  <img src={Logo} alt="Your Logo" className="footer-logo" />
                </a>
              </Col>
              <Col xs={12} md={6}>
                <p className="footer-copyright custom-nav-link">
                  &copy; {new Date().getFullYear()} All Rights Reserved
                </p>
              </Col>
            </Row>

            <Row className="footer-row"></Row>
            {/* Sticky scroll-to-top button */}
            {showScrollToTop && (
              <div className="scroll-to-top-button" onClick={handleScrollToTop}>
                <img className="scroll-image" src={ReturnToTop} />
              </div>
            )}

            <Row className="footer-row"></Row>
          </Container>
        </footer>
      </div>
    </div>
  )
}

export default TermsOfService
