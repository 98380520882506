import CorporateSubscriptionDetailIndex from "components/corporateSubscriptionDetail/index";
import React from "react";

export default function CorporateSubscribe () {

    return (
        <div>
            <CorporateSubscriptionDetailIndex />
        </div>
    )
}