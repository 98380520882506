import { takeEvery, all, call, put } from "redux-saga/effects";
import { customerActions } from "./actions";
import { commonActions } from "../common/actions";
import { API_URL } from "../../utils/constant";
import axios from "axios";

export const customerSagas = function* () {

    yield all([
        yield takeEvery(customerActions.GET_CUSTOMER_LIST, getCustomerList),
        yield takeEvery(customerActions.GET_CUSTOMER_DETAILS, getCustomerDetails),
    ])
};

const getCustomerList = function* () {

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/list`)
        );
        if (result?.data?.statusCode === 200) {
            let array = [...result?.data?.result];
            let finalResult = array.map(val => {
                return { ...val, UserName: `${val?.First_Name} ${val?.Last_Name}` }
            });
            yield put({
                type: customerActions.SET_CUSTOMER_LIST,
                payload: finalResult
            });
        } else {
            yield put({ type: customerActions.SET_CUSTOMER_LIST, payload: [] });
        };
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getCustomerDetails = function* (data) {

    const { payload } = data;

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/detail/${payload}`)
        );
        yield put({
            type: customerActions.SET_CUSTOMER_DETAILS,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : null
        });
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });

};