export const actions = {
    GET_SUPER_ADMIN_NOTIFICATION_LIST: 'GET_SUPER_ADMIN_NOTIFICATION_LIST',
    SET_SUPER_ADMIN_NOTIFICATION_LIST: 'SET_SUPER_ADMIN_NOTIFICATION_LIST',

    GET_ADMIN_NOTIFICATION_LIST: 'GET_ADMIN_NOTIFICATION_LIST',
    SET_ADMIN_NOTIFICATION_LIST: 'SET_ADMIN_NOTIFICATION_LIST',

    GET_CREATOR_NOTIFICATION_LIST: 'GET_CREATOR_NOTIFICATION_LIST',
    SET_CREATOR_NOTIFICATION_LIST: 'SET_CREATOR_NOTIFICATION_LIST',

    SUPER_ADMIN_DELETE_NOTIFICATION_BY_ID: 'SUPER_ADMIN_DELETE_NOTIFICATION_BY_ID',
    ADMIN_DELETE_NOTIFICATION_BY_ID: 'ADMIN_DELETE_NOTIFICATION_BY_ID',
    CREATOR_DELETE_NOTIFICATION_BY_ID: 'CREATOR_DELETE_NOTIFICATION_BY_ID',

    DELETE_ALL_SUPER_ADMIN_NOTIFICATIONS: 'DELETE_ALL_SUPER_ADMIN_NOTIFICATIONS',
    DELETE_ALL_ADMIN_NOTIFICATIONS: 'DELETE_ALL_ADMIN_NOTIFICATIONS',
    DELETE_ALL_CREATOR_NOTIFICATIONS: 'DELETE_ALL_CREATOR_NOTIFICATIONS'

}