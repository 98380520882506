import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { dashboardActions } from '../../store/dashboard/actions';
import { FaPoundSign } from "react-icons/fa"

const MonthlyEarning = () => {
    const dispatch = useDispatch();

    const [currentMonth, setCurrentMonth] = useState(0);
    const [previousMonth, setPreviousMonth] = useState(0);
    const [difference, setDifference] = useState(0);
    const [arrow, setArrow] = useState("");

    const { adminCurrentMonthSubscriptionAmount, adminPreviousMonthSubscriptionAmount } = useSelector(state => state.DashboardReducer);

    useEffect(() => {
        dispatch({
            type: dashboardActions.GET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT,
        });
        dispatch({
            type: dashboardActions.GET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT,
        });
    }, [dispatch]);

    useEffect(() => {
        if (adminCurrentMonthSubscriptionAmount) {
            setCurrentMonth(adminCurrentMonthSubscriptionAmount?.result || 0);
        }
        if (adminPreviousMonthSubscriptionAmount) {
            setPreviousMonth(adminPreviousMonthSubscriptionAmount?.result || 0);
        }
    }, [adminCurrentMonthSubscriptionAmount, adminPreviousMonthSubscriptionAmount]);

    useEffect(() => {
        if (previousMonth !== 0) {
            let amount = ((currentMonth - previousMonth) / previousMonth) * 100;
            let absDifference = Math.abs(amount).toFixed(2);
            setDifference(absDifference);
            let arrowIcon = amount < 0 ? <i className="mdi mdi-arrow-down" /> : <i className="mdi mdi-arrow-up" />;
            setArrow(arrowIcon);
        } else {
            setDifference(currentMonth.toFixed(2));
            setArrow(<i className="mdi mdi-arrow-up" />);
        }
    }, [currentMonth, previousMonth]);

    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-4">Monthly Earning</CardTitle>
                <Row>
                    <Col sm="6">
                        <p className="text-muted">This month</p>
                        <h3><FaPoundSign className='admin-sub-price' />{currentMonth !== 0 ? Number(currentMonth).toFixed(2) : '0.00'}</h3>
                        <br />
                    </Col>
                    <Col sm='6'>
                        <p className="text-muted">Previous month</p>
                        <h3><FaPoundSign className='admin-sub-price' />{previousMonth !== 0 ? Number(previousMonth).toFixed(2) : '0.00'}</h3>
                        <br />
                    </Col>
                    <Col sm='12'>
                        <p className="text-muted">
                            <span className={`me-2 ${difference >= 0 ? "text-success" : "text-danger"}`}>
                                {isNaN(difference) ? '0.00%' : `${difference}%`} {arrow}
                            </span>
                            From previous month
                        </p>
                    </Col>
                    <Col sm='12'>
                        <div>
                            <Link
                                to="/revenue"
                                className="btn btn-primary waves-effect waves-light btn-sm"
                            >
                                View More <i className="mdi mdi-arrow-right ms-1"></i>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <br />
                <p className="text-muted mb-0">
                    Monthly earnings from end users for subscription.
                </p>
            </CardBody>
        </Card>
    );
};

export default MonthlyEarning;
