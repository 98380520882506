import { dashboardActions } from "./actions";

const initialState = {
    needApprovalList: [
        {
            ContentID: "CONTENT001",
            Title: "Money Minds",
            Country: "India",
            CreatedBy: "user 1",
            CreatedOn: "01 Nov, 2023",
        },
        {
            ContentID: "CONTENT002",
            Title: "Stocks Instance",
            Country: "Accra",
            CreatedBy: "user 2",
            CreatedOn: "01 Nov, 2023",
        },
        {
            ContentID: "CONTENT003",
            Title: "Wall Street Curiosity",
            Country: "Ghana",
            CreatedBy: "user 3",
            CreatedOn: "01 Nov, 2023",
        },
        {
            ContentID: "CONTENT004",
            Title: "Stocks Backyard",
            Country: "Burkina Faso",
            CreatedBy: "user 4",
            CreatedOn: "01 Nov, 2023",
        },
        {
            ContentID: "CONTENT005",
            Title: "Invest Arbitrator",
            Country: "Osu",
            CreatedBy: "user 5",
            CreatedOn: "01 Nov, 2023",
        },
        {
            ContentID: "CONTENT006",
            Title: "Stocks Scoreboard",
            Country: "Legon",
            CreatedBy: "user 6",
            CreatedOn: "01 Nov, 2023",
        },
    ],
    customers: [
        {
            Id: 'CUS001',
            UserName: 'Stephen Rash',
            Email: 'stephenrash@gmail.com',
            Contact_Number: '325-250-1106',
            Address: '3159 Holly Street Cleveland, GA 30528',
            CreatedOn: '01 Nov, 2023',
            Status: 'New'
        },
        {
            Id: 'CUS002',
            UserName: 'Juan Mays',
            Email: 'juanmays@gmail.com',
            Contact_Number: '443-523-4726',
            Address: '4401 Findley Avenue Minot, ND 58701',
            CreatedOn: '28 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS003',
            UserName: 'Scott Henry',
            Email: 'scotthenry@gmail.com',
            Contact_Number: '704-629-9535',
            Address: '3632 Snyder Avenue Bessemer City, NC 2801',
            CreatedOn: '24 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS004',
            UserName: 'Cody Menendez',
            Email: 'codymenendez@gmail.com',
            Contact_Number: '701-832-5838',
            Address: '3755 Harron Drive Salisbury, MD 21875',
            CreatedOn: '20 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS005',
            UserName: 'Jason Merino',
            Email: 'jasonmerino@gmail.com',
            Contact_Number: '706-219-4095',
            Address: '2470 Grove Street Bethpage, NY 11714',
            CreatedOn: '20 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS006',
            UserName: 'Kyle Aquino',
            Email: 'kyleaquino@gmail.com',
            Contact_Number: '415-232-5443',
            Address: '3309 Horizon Circle Tacoma, WA 98423',
            CreatedOn: '20 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS007',
            UserName: 'David Gaul',
            Email: 'davidgaul@gmail.com',
            Contact_Number: '314-483-4679',
            Address: '1207 Cottrill Lane Stlouis, MO 63101',
            CreatedOn: '14 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS008',
            UserName: 'John McCray',
            Email: 'johnmcCray@gmail.com',
            Contact_Number: '253-661-7551',
            Address: '4861 Delaware Avenue San Francisco, CA 94143',
            CreatedOn: '13 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS009',
            UserName: 'Stephen Rash',
            Email: 'stephenrash@gmail.com',
            Contact_Number: '325-250-1106',
            Address: '2470 Grove Street Bethpage, NY 11714',
            CreatedOn: '12 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS010',
            UserName: 'Juan Mays',
            Email: 'juanmays@gmail.com',
            Contact_Number: '443-523-4726',
            Address: '4401 Findley Avenue Minot, ND 58701',
            CreatedOn: '12 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS011',
            UserName: 'Jason Merino',
            Email: 'jasonmerino@gmail.com',
            Contact_Number: '706-219-4095',
            Address: '3632 Snyder Avenue Bessemer City, NC 2801',
            CreatedOn: '10 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CUS012',
            UserName: 'John McCray',
            Email: 'johnmccray@gmail.com',
            Contact_Number: '253-661-7551',
            Address: '3755 Harron Drive Salisbury, MD 21875',
            CreatedOn: '05 Oct, 2023',
            Status: 'Approved'
        },
    ],
    Admin: [
        {
            Id: 'ADM001',
            UserName: 'Admin 1',
            Email: 'admin001@gmail.com',
            Contact_Number: '325-250-1106',
            Address: '3159 Holly Street Cleveland, GA 30528',
            CreatedOn: '25 Nov, 2023',
            Status: 'Approved',
            UserAccess: [
                { Role: "Content Creators", View: "Yes", Add: "No", Update: "No", Delete: "Yes" },
                { Role: "Contents", View: "Yes", Add: "-", Update: "Yes", Delete: "Yes" },
                { Role: "Subscriptions", View: "Yes", Add: "No", Update: "Yes", Delete: "Yes" },
                { Role: "Customers", View: "Yes", Add: "-", Update: "-", Delete: "-" },
                { Role: "Abandoned Cart", View: "Yes", Add: "-", Update: "-", Delete: "-" },
                { Role: "Payouts", View: "Yes", Add: "-", Update: "No", Delete: "-" },
                { Role: "Revenue", View: "Yes", Add: "-", Update: "-", Delete: "-" },
            ]
        },
        {
            Id: 'ADM002',
            UserName: 'Admin 2',
            Email: 'admin002@gmail.com',
            Contact_Number: '443-523-4726',
            Address: '4401 Findley Avenue Minot, ND 58701',
            CreatedOn: '01 Oct, 2023',
            Status: 'Blocked',
            UserAccess: [
                { Role: "Content Creators", View: "No", Add: "No", Update: "No", Delete: "No" },
                { Role: "Contents", View: "Yes", Add: "-", Update: "Yes", Delete: "Yes" },
                { Role: "Subscriptions", View: "No", Add: "No", Update: "No", Delete: "No" },
                { Role: "Customers", View: "Yes", Add: "-", Update: "-", Delete: "-" },
                { Role: "Abandoned Cart", View: "Yes", Add: "-", Update: "-", Delete: "-" },
                { Role: "Payouts", View: "Yes", Add: "-", Update: "No", Delete: "-" },
                { Role: "Revenue", View: "Yes", Add: "-", Update: "-", Delete: "-" },
            ]
        },
        {
            Id: 'ADM003',
            UserName: 'Admin 3',
            Email: 'admin003@gmail.com',
            Contact_Number: '704-629-9535',
            Address: '3632 Snyder Avenue Bessemer City, NC 2801',
            CreatedOn: '01 Oct, 2023',
            Status: 'Approved',
            UserAccess: [
                { Role: "Content Creators", View: "Yes", Add: "Yes", Update: "Yes", Delete: "Yes" },
                { Role: "Contents", View: "No", Add: "-", Update: "No", Delete: "No" },
                { Role: "Subscriptions", View: "No", Add: "No", Update: "No", Delete: "No" },
                { Role: "Customers", View: "Yes", Add: "-", Update: "-", Delete: "-" },
                { Role: "Abandoned Cart", View: "No", Add: "-", Update: "-", Delete: "-" },
                { Role: "Payouts", View: "No", Add: "-", Update: "No", Delete: "-" },
                { Role: "Revenue", View: "No", Add: "-", Update: "-", Delete: "-" },
            ]
        },
    ],
    contentCreators: [
        {
            Id: 'CONCRE001',
            UserName: 'Colin Melton',
            Email: 'colin@gmail.com',
            Contact_Number: '523-052-6011',
            Address: '3159 Holly Street Cleveland, GA 30528',
            CreatedOn: '05 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CONCRE002',
            UserName: 'John Santiago',
            Email: 'john@gmail.com',
            Contact_Number: '344-325-6274',
            Address: '4401 Findley Avenue Minot, ND 58701',
            CreatedOn: '07 Oct, 2023',
            Status: 'Blocked'
        },
        {
            Id: 'CONCRE003',
            UserName: 'Shirley Smith',
            Email: 'shirley@gmail.com',
            Contact_Number: '407-926-5359',
            Address: '3632 Snyder Avenue Bessemer City, NC 2801',
            CreatedOn: '15 Oct, 2023',
            Status: 'Approved'
        },
        {
            Id: 'CONCRE004',
            UserName: 'Minnie Walter',
            Email: 'minnie@gmail.com',
            Contact_Number: '518-037-6460',
            Address: '587 Findley Avenue Minot, ND 58701',
            CreatedOn: '30 Oct, 2023',
            Status: 'New'
        },
        {
            Id: 'CONCRE005',
            UserName: 'Mark Ellison',
            Email: 'mark@gmail.com',
            Contact_Number: '916-735-0648',
            Address: '5423 Holly Street Cleveland, GA 30528',
            CreatedOn: '01 Nov, 2023',
            Status: 'New'
        },
    ],
    Contents: [
        {
            Id: "CONT001",
            Title: "Ghana Stock Exchange extends deadline for banks",
            Description: "The Ghana Stock Exchange (GSE) has approved a request by the Ghana Association of Banks (GAB) to extend, by one-month, the filing of 2022 audited financial statements",
            Image: "blog1.jpg",
            CreatedOn: "Oct 18, 2023",
            CreatedBy: "Creator Name",
            Country: "Accra",
            Category: "Category 1",
            Tags: "Tag 1, Tag 2, Tag 3, Tag 4",
            Status: "Approved"
        },
        {
            Id: "CONT002",
            Title: "Fincap Securities partners GSE to launch CediManager, an investment platform for trading government securities",
            Description: "Head of Broker Dealers of the Securities and Exchange Commission (SEC) Francis Boadu, said,The Securities and Exchange Commission reiterates the call to all stakeholders to partner the Commission in the successful implementation of the various market initiatives in the Capital Market",
            Image: "blog2.jpg",
            CreatedOn: "Oct 18, 2023",
            CreatedBy: "Creator Name",
            Country: "Accra",
            Category: "Category 2",
            Tags: "Tag 1, Tag 2, Tag 3, Tag 4",
            Status: "Approved"
        },
        {
            Id: "CONT003",
            Title: "Uncertainty to weigh on equities market in 2023 – Analysts",
            Description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam",
            Image: "blog3.jpg",
            CreatedOn: "Oct 18, 2023",
            CreatedBy: "Creator Name",
            Country: "Accra",
            Category: "Category 2",
            Tags: "Tag 1, Tag 2, Tag 3, Tag 4",
            Status: "Blocked"
        },
        {
            Id: "CONT004",
            Title: "Stock exchange grants listed banks extension for filing 2022 audited financial statements",
            Description: "The Ghana Stock Exchange has approved a request by the Ghana Association of Banks on behalf of the listed Banks",
            Image: "blog4.jpg",
            CreatedOn: "Oct 18, 2023",
            CreatedBy: "Creator Name",
            Country: "Accra",
            Category: "Category 1",
            Tags: "Tag 1, Tag 2, Tag 3, Tag 4",
            Status: "New"
        },
        {
            Id: "CONT005",
            Title: "Press spotlights bullish performance by Ghanaian bourse in 2022, others",
            Description: "The bullish performance recorded in 2022 by The Ghana Stock Exchange (GSE) in spite of the macroeconomic challenges is one of the leading stories in the Ghanaian press on Friday.",
            Image: "blog5.jpg",
            CreatedOn: "Oct 18, 2023",
            CreatedBy: "Creator Name",
            Country: "Accra",
            Category: "Category 1",
            Tags: "Tag 1, Tag 2, Tag 3, Tag 4",
            Status: "New"
        },
        {
            Id: "CONT006",
            Title: "Ghana Stock Exchange extends deadline for banks",
            Description: "The Ghana Stock Exchange (GSE) has approved a request by the Ghana Association of Banks (GAB) to extend, by one-month,.",
            Image: "blog6.jpg",
            CreatedOn: "Oct 18, 2023",
            CreatedBy: "Creator Name",
            Country: "Accra",
            Category: "Category 1",
            Tags: "Tag 1, Tag 2, Tag 3, Tag 4",
            Status: "New"
        },
    ],



    adminEndUserCount: null,
    adminContentCreatorsCount: null,
    adminAdminCount: null,
    adminTotalSubscriptionCount: null,
    adminCurrentMonthSubscriptionCount: null,
    adminCurrentMonthSubscriptionAmount: null,
    adminPreviousMonthSubscriptionAmount: null,
    adminContentGraphCount: null,
};

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case dashboardActions.GET_NEED_APPROVAL_LIST:
            return {
                ...state,
                needApprovalList: action.payload
            }
        case dashboardActions.GET_CUSTOMERS_LIST:
            return {
                ...state,
                customers: action.payload
            }
        case dashboardActions.GET_CONTENT_CREATORS_LIST:
            return {
                ...state,
                contentCreators: action.payload
            }
        case dashboardActions.GET_CONTENT_LIST:
            return {
                ...state,
                Contents: action.payload
            }
        case dashboardActions.SET_ADMIN_END_USER_COUNT:
            return {
                ...state,
                adminEndUserCount: action.payload
            }
        case dashboardActions.SET_ADMIN_CONTENT_CREATOR_COUNT:
            return {
                ...state,
                adminContentCreatorsCount: action.payload
            }
        case dashboardActions.SET_ADMIN_ADMINCOUNT:
            return {
                ...state,
                adminAdminCount: action.payload
            }
        case dashboardActions.SET_ADMIN_TOTAL_SUBSCRIPTION_COUNT:
            return {
                ...state,
                adminTotalSubscriptionCount: action.payload
            }
        case dashboardActions.SET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT:
            return {
                ...state,
                adminCurrentMonthSubscriptionCount: action.payload
            }
        case dashboardActions.SET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT:
            return {
                ...state,
                adminCurrentMonthSubscriptionAmount: action.payload
            }
        case dashboardActions.SET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT:
            return {
                ...state,
                adminPreviousMonthSubscriptionAmount: action.payload
            }
        case dashboardActions.SET_ADMIN_CONTENT_GRAPH_COUNT:
            return {
                ...state,
                adminContentGraphCount: action.payload
            }
        default:
            return state;
    }
}


export default DashboardReducer;