import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, Form, Row, Col, Spinner } from "reactstrap";

export default function UpdateCreatorStatusModal(props) {

    const { modal, toggle, title, message, handleSubmit, type,buttonName } = props;
    const { buttonLoader } = useSelector(state => state.commonReducer);

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader className="myCustomInput" toggle={toggle} tag="h4">
                {title}
            </ModalHeader>
            <ModalBody >
                <Form className="myCustomInput padding-model">
                    <Row>
                        <Col md={12} className="mb-4 mt-3">
                            <h5>{message}</h5>
                        </Col>
                        <hr />
                        <Col md={12}>
                            <div className="text-end">
                                <button
                                    type="button"
                                    className="btn btn-primary save-customer me-2 text-center"
                                    onClick={() => handleSubmit(type)}
                                >
                                    {buttonLoader ?
                                        <Spinner color="light" size="sm" /> :
                                        <>Reject</>
                                   
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger save-customer text-center"
                                    onClick={toggle}
                                    disabled={buttonLoader ? true : false}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}