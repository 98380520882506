import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { webContentByCategoryActions } from "../../store/webContentByCategory/actions"
import { useDispatch, useSelector } from "react-redux"
import NoDataFound from "webComponents/Common/NoRecords"
import WebPageLoader from "../../common/webPageLoader"
import { WebHomePageActions } from "store/webHome/actions"


const RecentContentByCategory = () => {
  const navigate = useNavigate()
  const { category } = useParams()
  const dispatch = useDispatch()

  const [recentContentList, setRecentContentList] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [loader, setLoader] = useState(true);

  const formatDate = dateString => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]

    if (!dateString) return "Invalid Date"

    const [datePart] = dateString.split(" ")
    const [day, month, year] = datePart.split("-")

    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
      day,
      10
    )}, ${year}`

    return formattedDate
  }

  const { webCategoryRecentList } = useSelector(
    state => state.webContentByCategoryReducer
  )
  const { webListByCategory } = useSelector(
    state => state.webContentByCategoryReducer
  )

  useEffect(() => {
    setLoader(true);
    dispatch({
      type: webContentByCategoryActions.GET_WEB_LIST_BY_CATEGORY,
      payload: { TypeOfContent: "Article", Category: category },
    })
  }, [dispatch, category])

  useEffect(() => {
    setLoader(true);
    if (webListByCategory?.statusCode === 204) {
      setLoader(false);
      setFetchSuccess(false);
    } else {
      setLoader(false);
      setFetchSuccess(true);
    }
  }, [webListByCategory])

  useEffect(() => {
    dispatch({
      type: webContentByCategoryActions.GET_WEB_RECENT_CONTENT_BY_CATEGORY,
      payload: { TypeOfContent: "Article", Category: category },
    })
  }, [dispatch, category])

  useEffect(() => {
    if (webCategoryRecentList?.statusCode === 200) {
      setRecentContentList(webCategoryRecentList?.result || [])
    }
  }, [webCategoryRecentList?.statusCode, webCategoryRecentList?.result])

  const handleSectionClick = contentDetail => {
    navigate(`/web/content/${contentDetail._id}`, { state: { contentDetail: contentDetail } })
    dispatch({ type: WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS, payload: contentDetail._id })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }


  return (
    <>
      {loader ? <WebPageLoader /> :
        <>
          {fetchSuccess ? (
            <>
              <Container>
                <Row>
                  {recentContentList?.length > 0 ? (
                    recentContentList.slice(0, 1).map((content) => (
                      <Col xs={12} md={7} lg={8} className="mt-3" key={content._id}>
                        <div className="recent-container">
                          <div className="recent-heading">
                            <h2 className="web_fonts">{content?.Category[0]} </h2>
                            <hr className="hr-line" />
                          </div>
                          <h5 className="web_fonts color-black">latest news insights</h5>
                          <img
                            src={content?.Article_Images[0]}
                            alt="Latest News"
                            className="latest-news-img"
                            onClick={() => handleSectionClick(content)}
                          />
                          <h5 className="politics-heading web_fonts">
                            {content?.Category[0]}
                          </h5>
                          <h1
                            className="article-heading web_fonts color-black"
                            onClick={() => handleSectionClick(content)}
                          >
                            {content?.Title}
                          </h1>
                          <p className="author-date-recent">
                            by{" "}
                            <span style={{ color: "#007bff", textTransform: "none" }}>
                              {content?.Created_By}
                            </span>{" "}
                            {`${formatDate(content?.Approved_On)} |  ${content?.TimeDifference} ago`}
                          </p>
                          <p className="article-content web_fonts">
                            <div
                              // dangerouslySetInnerHTML={{
                              //   __html: `${content?.Story.slice(0, 240)}${content?.Story.length > 240 ? " ..." : ""
                              //     }`,
                              // }}

                              dangerouslySetInnerHTML={{
                                __html: (content?.Story &&
                                  content?.Story.split(" ")
                                    .slice(0,30)
                                    .join(" ")) +
                                  (content?.Story &&
                                    content?.Story.split(" ").length >
                                    30
                                    ? "..."
                                    : ""),
                              }}
                            />
                          </p>
                        </div>
                      </Col>
                    ))
                  ) : null}

                  <Col xs={12} md={5} lg={4} className="recentpage-leftside">
                    <Row>
                      {recentContentList?.length > 0 ? (
                        recentContentList?.slice(1, 7)?.map((content, index) => (
                          <div key={index}>
                            <Col
                              xs={12}
                              className="topic-column"
                              key={content._id}
                              onClick={() => handleSectionClick(content)}
                            >
                              <div className="topic-column-display">
                                <div className="topic-column-dis-flex">
                                  <p style={{ color: "#004aad", fontWeight: "bold" }} className="web_fonts p-left">
                                    {content?.Category[0]}
                                  </p>
                                  <h6 className="content-heading web_fonts color-black">
                                    {content?.Title.slice(0, 100)}
                                    {content?.Title.length > 50 && "..."}
                                  </h6>
                                  <p className="disabled-text web_fonts">
                                    by<span className="name-of web_fonts" style={{textTransform: "none" }}>
                                      {content?.Created_By}</span>{`${formatDate(content?.Approved_On)} | ${content?.TimeDifference} ago`}
                                  </p>
                                </div>

                                <div className="topic-column-dis-image">
                                  <img
                                    src={content?.Article_Images[0]}
                                    alt="Topic 1 Image"
                                    className="topic-column-dis-image-img"
                                  />
                                </div>
                              </div>
                              <hr className="hr-line-recent" />
                            </Col>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      }
    </>
  );

}

export default RecentContentByCategory
