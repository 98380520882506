
import React from "react"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    Row,
    Col,
} from "reactstrap"
import sad_cancel from '../../../src/assets/webImages/sad_cancel.webp'

const UnsubscribeModal = ({ show, handleSubscripeClose, handleUnsubscribe }) => {

    return (
        <Modal isOpen={show} toggle={handleSubscripeClose} >
            <ModalBody className='model-free-cancel'>
                <div className='background-sad'><img className='sad-cancel' src={sad_cancel} alt="model-img" /></div>
                <Form className="padding-model">
                    <Row>
                        <Col md={12} className="text-center">
                            <div className='sad-header'>We're sad to see you go !!</div>
                            <div className="confirm-cancel">Are you sure do you want to unsubscribe?</div>
                        </Col>
                      
                        <Col md={12}>
                            <div className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-primary save-customer me-2"
                                    onClick={handleSubscripeClose}

                                >
                                    Keep me Subscribed
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary save-customer me-2 cancel"
                                    onClick={()=>handleUnsubscribe("normalCancel")}
                                >
                                    Unsubscribe
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}
export default UnsubscribeModal
