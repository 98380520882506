import React, { useMemo, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { BlogContentActions } from "store/blogContent/actions"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import TableContainer from "../../common/TableContainer"
import Breadcrumb from "../../common/Breadcrumb"
import AdminPageLoader from "../../common/adminPageLoader";
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { BlogContentReducer } from "store/blogContent/reducer"
import { FaTrash, FaPlus } from "react-icons/fa"
import CustomPagination from "../../common/CustomPagination";

const Newsletterindex = () => {
  document.title = "Newsletter list | Allen Dreyfus"
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const { adminPageLoader } = useSelector(state => state.commonReducer)
  const { NewsletterUserEmailList } = useSelector(
    state => state.BlogContentReducer
  )

  useEffect(() => {
    dispatch({
      type: BlogContentActions.GET_NEWSLETTER_USER_EMAIL_LIST,
    })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "First_Name",
        filterable: true,
      },
      {
        Header: "Last Name",
        accessor: "Last_Name",
        filterable: true,
      },


      {
        Header: "Email",
        accessor: "Email",
        filterable: true,
      },

    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        {adminPageLoader ? (
          <AdminPageLoader />
        ) : (
          <>
            <Container fluid>
              <Breadcrumb
                title="Newsletter Users Email"
                titleLink="/Newsletterlist"
                breadcrumbItem="Details"
              />
            </Container>
            <Row>
              <Col xs="12">
                <Card className="Corporate Subscription">
                  <CardBody>
                    {NewsletterUserEmailList?.result && NewsletterUserEmailList?.result.length > 0 ? (
                      <React.Fragment>
                        <TableContainer
                          columns={columns}
                          data={NewsletterUserEmailList?.result || []}
                          isGlobalFilter={true}
                          isAddCustList={false}
                          isPagination={true}
                          customPageSize={itemsPerPage}
                          tableClass="align-middle table-nowrap"
                          isShowingPageLength={false}
                          iscustomPageSizeOptions={true}
                          pagination="pagination pagination-rounded justify-content-end mb-2"
                        />
                      </React.Fragment>
                    ) : (
                      <div className="no-records">No records found</div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>)}
      </div>
    </React.Fragment>
  )
}

export default Newsletterindex
