import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Card, CardBody, Col, Row, Label, Input, Button, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, } from "reactstrap";
import { BlogContentActions } from "store/blogContent/actions";
import Breadcrumbs from "../../common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';

export default function createContentDetail() {

    document.title = "Content Details | Allen Dreyfus";

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [descError, setDescError] = useState("");
    const [typeOfContent, setTypeOfContent] = useState('');
    const [title, setTitle] = useState('');
    const [Country, setCountry] = useState('');
    const [Category, setCategory] = useState('');
    const [SubCategory, setSubCategory] = useState('');
    const [pitchStory, setPitchStory] = useState('');
    const [story, setStory] = useState('');
    const [Impacts, setImpacts] = useState('');
    const [language, setLanguage] = useState('');
    const [Duration, setDuration] = useState('');
    const [articleImage, setArticleImage] = useState([]);
    const [podcastFile, setPodcastFile] = useState([]);
    const [ThumnailImage, setThumnailImage] = useState('');
    const [CreatedOn, setCreatedOn] = useState('');
    const [pitchCreatedOn, setPitchCreatedOn] = useState('');
    const [RemarksForPitch, setRemarksForPitch] = useState('');
    const [RemarksForContent, setRemarksForContent] = useState('');
    const [RejectionReason, setRejectionReason] = useState();


    const flattenedCountry = state?.data?.Country?.flat();

    //for brief description
    const [briefEditorState, setBriefEditorState] = useState(EditorState.createEmpty());
    const [briefConvertedContent, setBriefConvertedContent] = useState(null);

    const { showPopup, adminPageLoader  } = useSelector(state => state.commonReducer);
    const { contentDetail } = useSelector(state => state.BlogContentReducer);

    // const Id = state?.data?._id;
    const Id = state?.data?.NotificationType === "Content Status Updated" ? state?.data?.ContentID : state?.data?._id;

    useEffect(() => {
        dispatch({ type: BlogContentActions.GETCONTENTDETAIL, payload: Id });
    }, [Id]);

    useEffect(() => {
        if (contentDetail) {
            setTypeOfContent(contentDetail?.result?.TypeOfContent);
            setTitle(contentDetail?.result?.Title);
            setCountry(contentDetail?.result?.Country?.map(single => single).join(', '));
            setCategory(contentDetail?.result?.Category[0]);
            setSubCategory(contentDetail?.result?.Sub_Category?.map(single => single).join(', '));
            setPitchStory(contentDetail?.result?.Pitch_story);
            setStory(contentDetail?.result?.Story);
            setImpacts(contentDetail?.result?.Impacts);
            setDuration(contentDetail?.result?.Duration);
            if (contentDetail?.result?.Article_Images?.length > 0) {
                setArticleImage(contentDetail?.result?.Article_Images);
            }
            if (contentDetail?.result?.Podcast?.length > 0) {
                setPodcastFile(contentDetail?.result?.Podcast);
            }
            setThumnailImage(contentDetail?.result?.Thumbnail_Image);
            setCreatedOn(contentDetail?.result?.Created_On);
            setRemarksForPitch(contentDetail?.result?.Remarks_For_Pitch);
            setPitchCreatedOn(contentDetail?.result?.Pitch_Created_On);
            setRemarksForContent(contentDetail?.result?.RemarksForContent);
            setRejectionReason(contentDetail?.result?.RejectionReason);
        }
    }, [contentDetail])

    // const handleBreifEditorChange = (state) => {
    //     setBriefEditorState(state);
    //     setDescError('')
    //     let html2 = convertToHTML(state.getCurrentContent());
    //     setBriefConvertedContent(html2);
    // };


    return (
        <React.Fragment>
            <div className="page-content">
            {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Content"
                            titleLink={`/creator/content-list/${state?.type}`}
                            breadcrumbItem="details"
                        />
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Type of Content</Label>
                                            <Input
                                                name="TypeOFContent"
                                                className="form-control"
                                                placeholder="Enter Type of Content"
                                                type="text"
                                                defaultValue={typeOfContent}
                                                disabled
                                            />
                                        </div>
                                    </Col>

                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">market focus</Label>
                                            <Input
                                                name="Country"
                                                className="form-control"
                                                placeholder="Enter Type of Content"
                                                type="text"
                                                defaultValue={Country}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Title</Label>
                                            <Input
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                type="text"
                                                defaultValue={title}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-4" md={Category === "Frontier Markets" || Category === "Recent Topic" ? "6" : "12"}>
                                        <div className="form-group">
                                            <Label className="form-label">Category</Label>
                                            <Input
                                                name="Category"
                                                className="form-control"
                                                placeholder="Enter Category"
                                                type="text"
                                                defaultValue={Category}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    {(Category === "Frontier Markets" || Category === "Recent Topic") &&
                                        <Col md="6" className="mb-4">
                                            {
                                                SubCategory?.length !== 0 &&
                                                <div className="form-group">
                                                    <Label className="form-label">{Category}</Label>
                                                    <Input
                                                        name="Category"
                                                        className="form-control"
                                                        placeholder="Enter Category"
                                                        type="text"
                                                        defaultValue={SubCategory}
                                                        disabled
                                                    />
                                                </div>
                                            }
                                        </Col>
                                    }
                                </Row>
                                {RemarksForPitch &&
                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Remarks For Pitch</Label>
                                            <Input
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                type="textarea"
                                                rows={3}
                                                defaultValue={RemarksForPitch}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                }
                                <Row>
                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Pitch Submitted On</Label>
                                            <Input
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                type="text"
                                                defaultValue={pitchCreatedOn}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Content Submitted On</Label>
                                            <Input
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                type="text"
                                                defaultValue={CreatedOn}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Col md="12" className="mb-4">
                                    <div className="form-group">
                                        <Label className="form-label">Pitch story</Label>
                                        <div className="form-control form-control-textarea storyfont">
                                            <div className="clamp-text" dangerouslySetInnerHTML={{ __html: pitchStory }} />
                                        </div>
                                    </div>
                                </Col>

                                <Col className="mb-4" md="12">
                                    <Label htmlFor="title">What is the Story?</Label><span className="text-danger fw-bold">*</span>
                                    <div className="form-control form-control-textarea OriginalStoryfont">
                                        <div className="clamp-text" dangerouslySetInnerHTML={{ __html: story }} />
                                    </div>
                                </Col>

                                <Col md="12" className="mb-4">
                                    <div className="form-group">
                                        <Label className="form-label">Impact on Markets</Label>
                                        <Input
                                            name="username"
                                            className="form-control"
                                            placeholder="Enter User Name"
                                            type="textarea"
                                            rows={5}
                                            defaultValue={Impacts}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                {typeOfContent === 'Article' ?
                                    <>
                                        {articleImage && articleImage.length !== 0 && (
                                            <div className="form-group mb-3">
                                                <Label className="form-label"> Article Image</Label>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {articleImage.map((image, index) => (
                                                        <div key={index} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                                            <img
                                                                src={image}
                                                                alt={`Image ${index + 1}`}
                                                                style={{ maxWidth: '150px', maxHeight: '150px', marginBottom: '5px' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}  </> : <></>}

                                {typeOfContent === 'Podcast'
                                    // || typeOfContent === 'Video'
                                    ?
                                    <>
                                        {podcastFile && podcastFile.length !== 0 && (
                                            <div className="form-group mb-3">
                                                <label className="form-label">Podcast File</label>
                                                <div>
                                                    <audio controls>
                                                        <source src={podcastFile} type="audio/mpeg" />
                                                    </audio>
                                                </div>
                                            </div>

                                        )}

                                        {ThumnailImage?.length !== 0 &&
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Thumbnail Image</Label>
                                                <div>
                                                    <img
                                                        src={ThumnailImage}
                                                        alt="Selected"
                                                        style={{ padding: '10px', maxWidth: '50%', maxHeight: '150px' }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <Col md="12" className="mb-4">
                                            <div className="form-group">
                                                <Label className="form-label">Duration</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="text"
                                                    defaultValue={Duration}
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </>
                                    :
                                    <></>
                                }

                                {RemarksForContent &&
                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Remarks For Content</Label>
                                            <Input
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                type="textarea"
                                                rows={3}
                                                defaultValue={RemarksForContent}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                }

                                {RejectionReason &&
                                    <Col md="12" className="mb-4">
                                        <div className="form-group">
                                            <Label className="form-label">Content Rejection Reason</Label>
                                            <Input
                                                name="Title"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                type="textarea"
                                                rows={3}
                                                defaultValue={RejectionReason}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                }
                                {state?.data?.Status === 'Approved' &&
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="me-3"
                                        onClick={() => navigate('/creator-edit-content', { state: state })}
                                    >
                                        Edit
                                    </Button>
                                }

                                <Button
                                    type="button"
                                    color="danger"
                                    // onClick={() => navigate(`/creator/content-list/${state?.type}`)}
                                    onClick={() => navigate(state?.data?.Status === 'New' || state?.data?.Status === 'Updated' ? `/creator/content-list/needApproval` : state?.data?.Status === 'Approved' ? `/creator/content-list/Approved` : `/creator/content-list/Rejected`)}
                                >
                                    Close
                                </Button>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
}



