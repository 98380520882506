import AddContentCreatorIndex from "components/addContentCreator";
import React from "react";

export default function AddContentCreator() {

    return (
        <div>
            <AddContentCreatorIndex />
        </div>
    )
}