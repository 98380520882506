import React from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  if (
    !localStorage.getItem("GFX_TOKEN") &&
    !localStorage.getItem("GFX_TOKEN2")&&
    !localStorage.getItem("GFX_TOKEN3")&&
    !localStorage.getItem("WEB_TOKEN")
  ) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  } else if (localStorage.getItem("GFX_Dashboard") === "false") {
    return (
      <Navigate
        to={{ pathname: "/auth-lock-screen", state: { from: props.location } }}
      />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
