import React, { useEffect, useState, useRef } from "react"
import {
  Form,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap"
import { useFormik, Field } from "formik"
import * as Yup from "yup"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { BlogContentActions } from "../../store/blogContent/actions"
import { commonActions } from "../../store/common/actions"
import Breadcrumb from "../../common/Breadcrumb"
import AdminPageLoader from "../../common/adminPageLoader";
// import profileicon from '../assets/images/dashboard/user3.png';
import ApproveOrRejectContentModal from "components/commonModals/ApproveOrRejectContentModal"
import Multiselect from "multiselect-react-dropdown"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { convertToHTML } from "draft-convert"
import success_img from "../../assets/images/dashboard/success1-popup-image.gif"
import failure_img from "../../assets/images/dashboard/failure-popup-image.gif"
import FileSizeValidation from '../../FileSizeValidation/index'
import RichTextEditor from '../../common/QuillRichTextEditor';
import { actions } from "store/Countries/actions"

export default function creatorEditContentDetail() {
  const { state } = useLocation();

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const fileInputRef = React.createRef()
  const imageRef = useRef()
  const audioref = useRef()
  const thumbref = useRef()

  var [typeOfContent, setTypeOfContent] = useState("")
  var [title, setTitle] = useState("")
  var [country, setCountry] = useState("")
  var [category, setCategory] = useState([])
  var [subCategory, setSubCategory] = useState("")
  var [pitchStory, setPitchStory] = useState("")
  var [impacts, setImpacts] = useState("")
  var [articleImage, setArticleImage] = useState([])
  var [podcast, setPodcast] = useState("")
  var [thumnailImage, setThumnailImage] = useState("")
  var [RejectionReason, setRejectionReason] = useState("")
  var [rejectError, setRejectError] = useState("")
  var [PitchApproveReason, setPitchApproveReason] = useState("")
  const [approveReason, setApproveReason] = useState("")
  const [rejectReason, setRejectReason] = useState("")
  const [editMode, setEditMode] = useState(false)

  const [countryOptions, setCountryOptions] = useState([])
  const [countryError, setCountryError] = useState("")

  const [selectedOptions, setSelectedOptions] = useState([])
  const [marketError, setMarketError] = useState("")

  const [selectedOptions1, setSelectedOptions1] = useState([])
  const [recentTopicError, setRecentTopicError] = useState("")

  const [podcastFile, setPodcastFile] = useState("")
  const [podcastError, setPodcastError] = useState("")

  const [thumbFile, setThumbFile] = useState("")
  const [thumbError, setThumbError] = useState("")

  const [duration, setDuration] = useState("")
  const [durationError, setDurationError] = useState("")

  const [creatorId, setCreatorId] = useState("")
  const [createdBy, setCreatedBy] = useState("")
  const [blogId, setBlogId] = useState("")

  const [selectedImage, setSelectedImage] = useState([])
  const [imageFile, setImageFile] = useState("")
  const [fileError, setFileError] = useState("")

  const [showCreationPopup, setCreationPopup] = useState({
    isOpen: false,
    statusCode: null,
  })

  const { creatorPitchDetail } = useSelector(state => state.BlogContentReducer)
  const { buttonLoader } = useSelector(state => state.commonReducer)
  const { blogContentResponse } = useSelector(state => state.BlogContentReducer)

  const { recentTopicList } = useSelector(
    state => state.BlogContentReducer
  );
  const { countryList } = useSelector(state => state.CountryReducer);
  const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer)

  const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
  const Id = state?.data?.ContentID ? state?.data?.ContentID : state?.data?._id

  useEffect(() => {
    dispatch({ type: BlogContentActions.GET_CREATOR_PITCH_DETAIL, payload: Id })
  }, [Id])

  useEffect(() => {
    dispatch({ type: BlogContentActions.GET_RECENT_TOPIC_LIST })
    dispatch({ type: actions.GET_COUNTRY_LIST })
  }, [])

  useEffect(() => {
    if (blogContentResponse && blogContentResponse?.statusCode === 200) {
      setCreationPopup({ isOpen: true, statusCode: 200 })
      validation.resetForm()
    } else if (blogContentResponse && blogContentResponse?.statusCode === 400) {
      setCreationPopup({ isOpen: false, statusCode: 400 })
    }
  }, [blogContentResponse])

  //for story
  function removeHtmlTags(input) {
    const regex = /<\/?[\w\s="/.':;#-\/\?]+>/gi
    return input.replace(regex, "")
  }
  const storyContent1 = creatorPitchDetail?.result?.Story || ""
  const textWithoutHtml = removeHtmlTags(storyContent1)
  const initialContentState = ContentState.createFromText(textWithoutHtml)

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialContentState || "")
  )

  const [briefConvertedContent, setBriefConvertedContent] = useState(null)
  const [descError, setDescError] = useState("")
  const [recentTopic, setRecentTopic] = useState([]);
  const [storyContent, setStoryContent] = useState('');

  //form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Title: creatorPitchDetail?.result?.Title,
      TypeOfContent: creatorPitchDetail?.result?.TypeOfContent,
      Country: creatorPitchDetail?.result?.Country || [],
      Category: creatorPitchDetail?.result?.Category[0]?.toString() || [],
      // Category: creatorPitchDetail?.result?.Category[0] || [],
      Sub_Category: creatorPitchDetail?.result?.Sub_Category || [],
      Pitch_story: creatorPitchDetail?.result?.Pitch_story || [],
      Impacts: creatorPitchDetail?.result?.Impacts,
      Article_Images: creatorPitchDetail?.result?.Article_Images || [],
      Podcast: creatorPitchDetail?.result?.Podcast[0]?.toString() || [],
      Thumbnail_Image: creatorPitchDetail?.result?.Thumbnail_Image,
      Duration: creatorPitchDetail?.result?.Duration,
      Created_By: creatorPitchDetail?.result?.Created_By,
      Blog_Id: creatorPitchDetail?.result?._id,
      Content_Creator_Id: creatorPitchDetail?.result?.Content_Creator_Id,
      Old_Article_Images: [],
    },
    validationSchema: Yup.object({
      Title: Yup.string().required("enter the title"),
      TypeOfContent: Yup.string().required("select the content type"),
      Category: Yup.string().required("select blog category"),
      Impacts: Yup.string().required("impacts field is required"),
    }),
    onSubmit: data => {
      if (countryOptions && countryOptions?.length === 0) {
        setCountryError("select the market focus")
      } else if (storyContent === '') {
        setDescError("story is required")
      } else if (
        validation?.values?.Category === "Frontier Markets" &&
        selectedOptions?.length === 0
      ) {
        setMarketError("frontier markets is required")
      } else if (
        validation?.values?.Category === "Recent Topic" &&
        selectedOptions1?.length === 0
      ) {
        setRecentTopicError("recent topic is required")
      }
      // else if ((validation?.values?.TypeOfContent === "Article") && (selectedImage?.length === 0 || !selectedImage)) {
      //   setFileError("article image is required")
      // }
      else if ((validation?.values?.TypeOfContent === "Podcast") && !podcastFile) {
        setPodcastError("podcast file is required")
      } else if (
        (validation?.values?.TypeOfContent === "Podcast"
        ) &&
        !thumnailImage
      ) {
        setThumbError("thumb-nail image is required")
      } else if (
        (validation?.values?.TypeOfContent === "Podcast"
        ) &&
        !duration
      ) {
        setDurationError("duration of the uploaded file is required")
      } else {
        setDescError("")
        const formData = new FormData()
        formData.append("TypeOfContent", data.TypeOfContent)
        formData.append("Title", data.Title)
        // formData.append("Story", briefConvertedContent)
        formData.append("Story", storyContent)
        // for country
        let countrySet = new Set()
        countryOptions.forEach(val => {
          countrySet.add(val.name)
        })
        const country_List = JSON.stringify([...countrySet])
        formData.append("Country", country_List)
        formData.append("Category", category)
        if (
          validation?.values?.Category === "Frontier Markets" ||
          validation?.values?.Category === "Recent Topic"
        ) {
          if (validation?.values?.Category === "Frontier Markets") {
            let marketSet = new Set()
            selectedOptions.forEach(val => {
              marketSet.add(val.name)
            })
            const market_List = JSON.stringify([...marketSet])
            formData.append("Sub_Category", market_List)
          } else if (validation?.values?.Category === "Recent Topic") {
            let recentSet = new Set()
            selectedOptions1.forEach(val => {
              recentSet.add(val.name)
            })
            const recent_List = JSON.stringify([...recentSet])
            formData.append("Sub_Category", recent_List)
          }
        } else {
          // For other categories, append an empty array
          formData.append("Sub_Category", JSON.stringify([]))
        }

        if (validation?.values?.TypeOfContent === "Article") {
          if (selectedImage?.length > 0) {
            for (let i = 0; i < selectedImage?.length; i++) {
              if (selectedImage[i].type === "new") {
                formData.append("Article_Images", selectedImage[i].file)
              }
            }
            //for old image
            let oldArray = []
            oldArray = selectedImage
              ?.filter(val => val?.type === "old")
              .map(val => {
                return val?.Article_Images
              })
            const oldArrayString = JSON.stringify(oldArray)
            formData.append("Old_Article_Images", oldArrayString)
          }
        }

        if (
          validation?.values?.TypeOfContent === "Podcast"
          //  || validation?.values?.TypeOfContent === "Video"
        ) {
          if (podcast) {
            formData.append("Podcast", podcast)
          } else {
            let oldPodcast = []
            oldPodcast.push(podcastFile)
            formData.append("Old_Podcast_File", JSON.stringify(oldPodcast))
          }
          if (thumbFile) {
            formData.append("Thumbnail_Image", thumbFile)
          } else {
            formData.append("Old_Thumbnail_Image", thumnailImage)
          }
          formData.append("Duration", duration)
        }

        let Content_Updated_By = {
          Name: creatorTokenResponse?.result?.Name,
          Email: creatorTokenResponse?.result?.Email,
          Type_Of_User: creatorTokenResponse?.result?.Type_Of_User
        }
        formData.append("Content_Updated_By", JSON.stringify(Content_Updated_By))
        formData.append("Impacts", data.Impacts)
        formData.append("Created_By", createdBy)
        formData.append("Content_Creator_Id", creatorId)
        formData.append("Blog_Id", blogId)
        formData.append("Status", "Updated")

        // for (var pair of formData.entries()) { //to console formdata
        //   console.log(pair[0] + ', ' + pair[1]);
        // }


        dispatch({
          type: BlogContentActions.CREATE_BLOG_CONTENT,
          payload: { data: formData },
        })
      }
    },
  })

  useEffect(() => {
    setTypeOfContent(validation.values.TypeOfContent)
    setTitle(validation.values.Title)
    setCountry(validation.values.Country)
    setCategory(validation.values.Category)
    setSubCategory(validation.values.Sub_Category)
    setPitchStory(validation.values.Pitch_story)
    setImpacts(validation.values.Impacts)
    setBriefConvertedContent(textWithoutHtml)
    setEditorState(EditorState.createWithContent(initialContentState || ""))
    setArticleImage(validation.values.Article_Images)
    setPodcastFile(validation.values.Podcast)
    setThumnailImage(validation.values.Thumbnail_Image)
    setDuration(validation.values.Duration)
    setRejectionReason(creatorPitchDetail?.result?.Pitch_Rejection_Reason)
    setApproveReason(creatorPitchDetail?.result?.RemarksForContent)
    setBlogId(validation.values.Blog_Id)
    setCreatedBy(validation.values.Created_By)
    setCreatorId(validation.values.Content_Creator_Id)
  }, [validation.values])

  useEffect(() => {
    if (creatorPitchDetail?.result?.Country) {
      setCountryOptions(
        creatorPitchDetail?.result?.Country?.map(name => ({ id: name, name }))
      )
    }
    if (creatorPitchDetail?.result?.Category[0]?.toString() === "Frontier Markets") {
      setSelectedOptions(
        creatorPitchDetail?.result?.Sub_Category.map(name => ({
          id: name,
          name,
        }))
      )
    }
    if (creatorPitchDetail?.result?.Category[0]?.toString() === "Recent Topic") {
      setSelectedOptions1(
        creatorPitchDetail?.result?.Sub_Category.map(name => ({
          id: name,
          name,
        }))
      )
    }

    if (creatorPitchDetail?.result?.Story) {
      setStoryContent(creatorPitchDetail?.result?.Story)
    }
  }, [creatorPitchDetail])

  useEffect(() => {
    if (validation.values.Category !== "Frontier Markets" ||
      validation.values.Category !== "Recent Topic") {
      setSubCategory("")
    }
  }, [validation])

  // modal close
  const handleClose = () => {
    setCreationPopup({ isOpen: false, statusCode: null })
    window.location.href = "/creator/content-list/needApproval"
  }

  // Country List
  let countryArray = countryList?.result?.map(val => ({
    id: val?.Country_Name,
    name: val?.Country_Name,
  }))

  // Recent topic
  // let recentTopic = recentTopicList?.result?.map(val => ({
  //   id: val?.Topic,
  //   name: val?.Topic,
  // }))


  useEffect(() => {
    if (recentTopicList && recentTopicList.result && recentTopicList.result.length > 0) {
      const firstTopic = {
        id: recentTopicList.result[0]?.Topic,
        name: recentTopicList.result[0]?.Topic,
      };
      setRecentTopic([firstTopic]);
    }
  }, [recentTopicList?.result]);

  // Country
  const onSelectCounty = selectedCountry => {
    setCountryOptions(selectedCountry)
    setCountryError("")
  }

  const onRemoveCountry = selectedCountry => {
    setCountryOptions(selectedCountry)
  }

  // frontier markets
  const onSelect = selectedList => {
    setSelectedOptions(selectedList)
    setMarketError("")
  }

  const onRemove = selectedList => {
    setSelectedOptions(selectedList)
  }

  // Recent Topics
  const onSelect1 = selectedList1 => {
    setSelectedOptions1(selectedList1)
    setRecentTopicError("")
  }

  const onRemove1 = selectedList1 => {
    setSelectedOptions1(selectedList1)
  }

  // Article image
  useEffect(() => {
    let imgArray = []
    creatorPitchDetail?.result?.Article_Images?.map(val =>
      imgArray.push({ Article_Images: val, type: "old" })
    )
    setSelectedImage(imgArray)
  }, [creatorPitchDetail?.result?.Article_Images])

  // const handleFileUpload = event => {
  //   const files = event.target.files
  //   if (files) {
  //     let result = FileSizeValidation(event.target.files);
  //     if (result.Validation === "false") {
  //       dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
  //       imageRef.current.value = null
  //     } else {
  //       if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
  //         result?.FinalImages[0]?.type === 'image/png') {
  //         let articleArray = []
  //         for (let i = 0; i < files.length; i++) {
  //           articleArray.push({
  //             file: files[i],
  //             previewUrl: URL.createObjectURL(files[i]),
  //             type: "new",
  //           })
  //         }
  //         setSelectedImage(prevImages => [...prevImages, ...articleArray])
  //         // setImageFile(files)
  //         if (selectedImage?.length === 0 && articleArray.length === 0) {
  //           setFileError("Article Image is required")
  //         } else {
  //           setFileError("")
  //         }

  //       } else {
  //         dispatch({
  //           type: commonActions.SET_ALERT,
  //           payload: { show: true, status: "failed", message: "Unsupported File Format" }
  //         })
  //         imageRef.current.value = null
  //       }
  //     }

  //   }

  // }

  const handleFileUpload = event => {
    const file = event.target.files[0]; // Get the first file from the input
    if (file) {
      let result = FileSizeValidation([file]);
      if (result.Validation === "false") {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
        imageRef.current.value = null;
      } else {
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          setSelectedImage([{
            file: file,
            previewUrl: URL.createObjectURL(file),
            type: "new",
          }]);
          setFileError(""); // Clear any previous file error
        } else {
          dispatch({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: "failed", message: "Unsupported File Format" }
          });
          imageRef.current.value = null;
        }
      }
    }
  };

  const handleRemoveImage = index => {
    setSelectedImage(prevImages => {
      const updatedImages = [...prevImages]
      updatedImages.splice(index, 1)
      // if (updatedImages.length === 0) {
      //   setFileError("article image is required")
      //   setSelectedImage([])
      //   imageRef.current.value = null
      // } else {
      //   setFileError("")
      // }
      return updatedImages
    })
  }

  // Thumbnail Image
  const handleThumbnailImageChange = e => {
    const thumbFile = e.target.files[0]
    setThumbError("")
    if (thumbFile) {
      let result = FileSizeValidation(e.target.files);
      if (result.Validation === "false") {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
        thumbref.current.value = null

      } else {
        if (result?.FinalImages[0]?.type === 'image/jpeg' || result?.FinalImages[0]?.type === 'image/jpg' ||
          result?.FinalImages[0]?.type === 'image/png') {
          setThumnailImage(URL.createObjectURL(thumbFile))
          setThumbFile(thumbFile)
        } else {
          dispatch({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: "failed", message: "Unsupported File Format" }
          })
          thumbref.current.value = null
        }
      }

    } else {
      setThumbError("Thumbnail Image is required")
    }
  }

  const handleRemoveThumbnail = () => {
    setThumnailImage("") // Clear the thumbnail image state
    setThumbFile("")
    if (validation?.values?.TypeOfContent === "Podcast") {
      setThumbError("thumb-nail image is required");
    } else {
      setThumbError(""); // Reset the thumbError state if not needed
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  // Duration
  const handleDuration = e => {
    if (e.target.value === null) {
      setDurationError("duration of the uploaded file is required")
    } else {
      setDuration(e.target.value)
      setDurationError("")
    }
  }

  // Audio
  const handleAudioChange = event => {
    const file = event.target.files[0]
    setPodcastError("")
    if (file) {
      let result = FileSizeValidation(event.target.files);
      if (result.Validation === "false") {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
        audioref.current.value = null
      } else {
        if (result?.FinalImages[0]?.type === 'audio/mpeg') {
          setPodcast(file)
          setPodcastFile(URL.createObjectURL(file))
        } else {
          dispatch({
            type: commonActions.SET_ALERT,
            payload: { show: true, status: "failed", message: "Unsupported File Format" }
          })
          audioref.current.value = null
        }
      }
    } else {
      setPodcastError("podcast file is required")
    }
  }

  const handleClearAudio = () => {
    setPodcastFile(null)
  }

  return (
    <React.Fragment>
      <Form
        className="form-horizontal mt-2"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="page-content">
          {adminPageLoader ? (
            <AdminPageLoader />
          ) : (
            <Container fluid>
              <Breadcrumb
                title="Content"
                titleLink={`/${state?.type}`}
                breadcrumbItem="Details"
              />
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col md="6">
                      <div className="form-group">
                        <Label className="form-label">Type of Content</Label>
                        <span className="text-danger fw-bold">*</span>
                        <Input
                          className="form-control"
                          name="TypeOfContent"
                          id="type"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            validation.values.TypeOfContent || typeOfContent
                          }
                          invalid={
                            validation.touched.TypeOfContent &&
                              validation.errors.TypeOfContent
                              ? true
                              : false
                          }
                          disabled
                        >
                          <option value="" disabled>
                            {" "}
                            -- Select Content Type --{" "}
                          </option>
                          <option value="Article">Article</option>
                          {/*  <option value="Podcast">Podcast</option>
                          <option value="Video">Video</option> */}
                        </Input>

                        {validation.touched.TypeOfContent &&
                          validation.errors.TypeOfContent ? (
                          <FormFeedback type="invalid">
                            {validation.errors.TypeOfContent}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* <Col md="6" className="mb-4">
                      <div className="form-group">
                        <Label htmlFor="Title" className="form-label">
                          Country
                        </Label>
                        <span className="text-danger fw-bold">*</span>
                        <Multiselect
                          options={countryArray}
                          displayValue="name"
                          onSelect={onSelectCounty}
                          onRemove={onRemoveCountry}
                          selectedValues={countryOptions}
                        />
                        <div>
                          <div>
                            <span className="market-select">
                              Selected Values:
                            </span>{" "}
                            {countryOptions
                              ?.map(option => option.name)
                              .join(", ")}
                          </div>
                        </div>
                        {countryError && (
                          <div style={{ color: "red" }}>{countryError}</div>
                        )}
                      </div>
                    </Col> */}

                    <Col md="6" className="mb-4">
                      <div className="form-group">
                        <Label className="form-label">market focus</Label>
                        <Input
                          name="Country"
                          className="form-control"
                          placeholder="Enter Type of Content"
                          type="text"
                          defaultValue={country}
                          disabled
                        />
                      </div>
                    </Col>

                    <Col md="12" className="mb-4">
                      <Label htmlFor="Title" className="form-label">
                        Title
                      </Label>
                      <span className="text-danger fw-bold">*</span>
                      <Input
                        name="Title"
                        type="text"
                        className="proName"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Title || title}
                        invalid={validation.touched.Title && validation.errors.Title ? true : false}
                        disabled
                      />
                      {validation.touched.Title && validation.errors.Title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Title}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    {/* <Col
                      className="mb-4" md={
                        validation?.values?.Category === "Frontier Markets" ||
                          validation?.values?.Category === "Recent Topic" ? "6" : "12"
                      }
                    >
                      <div className="form-group">
                        <Label className="form-label">Category</Label>
                        <span className="text-danger fw-bold">*</span>
                        <Input
                          className="form-control"
                          name="Category"
                          id="type"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Category || category}
                          invalid={
                            validation.touched.Category &&
                              validation.errors.Category ? true : false
                          }
                        >
                          <option value="" disabled>
                            {" "}
                            -- Select the Category --{" "}
                          </option>
                          <option value="Business">Business</option>
                          <option value="Politics">Politics</option>
                          <option value="Recent Topic">Recent Topics</option>
                           <option value="Politics">Green</option>
                          <option value="Sustainability">Sustainability</option>
                          <option value="In Depth">In-Depth</option>                          
                          <option  value="localknowledge">Local Knowledge</option>
                          <option value="Frontier Markets">Frontier Markets</option> 
                        </Input>
                        {validation.touched.Category &&
                          validation.errors.Category ? (
                          <FormFeedback type="invalid">
                            {validation.errors.Category}
                          </FormFeedback>
                        ) : null}
                      </div>                      
                    </Col> */}

                    <Col md="12" className="mb-4">
                      <div className="form-group">
                        <Label className="form-label">Category</Label>
                        <Input
                          name="Category"
                          className="form-control"
                          placeholder="Enter Category"
                          type="text"
                          defaultValue={category}
                          disabled
                        />
                      </div>
                    </Col>

                    {validation?.values?.Category === "Frontier Markets" ? (
                      <>
                        <Col className="mb-4" md="6">
                          <div className="form-group ">
                            <Label className="form-label">
                              Select the Frontier Markets
                            </Label>
                            <span className="text-danger fw-bold">*</span>
                            <Multiselect
                              options={countryArray}
                              displayValue="name"
                              onSelect={onSelect}
                              onRemove={onRemove}
                              selectedValues={selectedOptions}
                            // value={validation.values.Sub_Category || []}
                            />
                            <div>
                              <div>
                                <span className="market-select">
                                  Selected Values:
                                </span>{" "}
                                {selectedOptions
                                  .map(option => option.name)
                                  .join(", ")}
                              </div>
                            </div>
                            {marketError && (
                              <div style={{ color: "red" }}>{marketError}</div>
                            )}
                          </div>
                        </Col>
                      </>
                    ) : validation?.values?.Category === "Recent Topic" ? (
                      <>
                        <Col className="mb-4" md="6">
                          <div className="form-group">
                            <Label className="form-label">
                              Select Recent Topic
                            </Label>
                            <span className="text-danger fw-bold">*</span>
                            <Multiselect
                              options={recentTopic}
                              displayValue="name"
                              onSelect={onSelect1}
                              onRemove={onRemove1}
                              selectedValues={selectedOptions1}
                            // value={validation.values.Sub_Category || []}
                            >
                              <option value="" disabled>
                                -- Select the Recent Topic --
                              </option>
                              {/* Map over the array to generate options */}
                              {recentTopic?.map(topic => (
                                <option key={topic.value} value={topic.value}>
                                  {topic.name}
                                </option>
                              ))}
                            </Multiselect>

                            <div>
                              <div>
                                <span className="market-select">
                                  Selected Values:
                                </span>{" "}
                                {selectedOptions1
                                  .map(option => option.name)
                                  .join(", ")}
                              </div>
                            </div>
                            {recentTopicError && (
                              <div style={{ color: "red" }}>
                                {recentTopicError}
                              </div>
                            )}
                          </div>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}

                    <Col md="12" className="mb-4">
                      <div className="form-group">
                        <Label className="form-label">Pitch story</Label>
                        <div className="form-control form-control-textarea storyfont">
                          <div
                            className="clamp-text proName"
                            dangerouslySetInnerHTML={{ __html: pitchStory }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="12" className="mb-4">
                      <div className="form-group" >
                        <Col className="mb-4" md="12">
                          <Label htmlFor="title">What is the Story?</Label>
                          <span className="text-danger fw-bold">*</span>
                          <div className="textEditorStyle" style={{ textDecoration: 'none' }}>
                            <RichTextEditor setStoryContent={setStoryContent} storyContent={storyContent} />
                            {/* <div dangerouslySetInnerHTML={{ __html: storyContent }} /> */}
                          </div>
                          {descError && (
                            <div style={{ color: "red" }}>{descError}</div>
                          )}
                        </Col>
                      </div>
                    </Col>

                    <Col className="mb-4" md="12">
                      <Label htmlFor="title">Impact on Markets</Label>
                      <span className="text-danger fw-bold">*</span>

                      <Input
                        className="form-control"
                        id="impacts"
                        name="Impacts"
                        type="textarea"
                        rows={5}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Impacts || impacts}
                        invalid={
                          validation.touched.Impacts &&
                            validation.errors.Impacts
                            ? true
                            : false
                        }
                      />
                      {validation.touched.Impacts &&
                        validation.errors.Impacts ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Impacts}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    {typeOfContent === "Article" && (
                      <>
                        <Label className="form-label">Article Image</Label>
                        {selectedImage && selectedImage.length !== 0 && (
                          <Col md="12">

                            <span>
                              <small className="text-success">
                                <i>(Supported Image formats : .jpeg, .jpg, .png)</i>
                              </small>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "15px",
                              }}
                            >
                              {selectedImage.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    marginBottom: "10px",
                                    position: "relative",
                                  }}
                                >
                                  {image?.type === "old" ? (
                                    <>
                                      <img
                                        src={image?.Article_Images}
                                        alt={`Selected ${index + 1}`}
                                        style={{
                                          maxWidth: "150px",
                                          maxHeight: "150px",
                                          marginBottom: "5px",
                                        }}
                                      />
                                      <button
                                        className="removeButton"
                                        type="button"
                                        onClick={() => handleRemoveImage(index)}
                                      >
                                        X
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={image?.previewUrl}
                                        alt="Preview"
                                        style={{
                                          maxWidth: "150px",
                                          maxHeight: "150px",
                                          marginBottom: "5px",
                                        }}
                                      />
                                      <button
                                        className="removeButton"
                                        type="button"
                                        onClick={() => handleRemoveImage(index)}
                                      >
                                        X
                                      </button>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Col>
                        )}
                        <Col className="mb-4" md="12">
                          <Input
                            type="file"
                            name="image"
                            innerRef={imageRef}
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={handleFileUpload} // Uncomment if needed
                            multiple={false}
                          />
                          {fileError && <div style={{ color: "red" }}>{fileError}</div>}
                        </Col>
                      </>
                    )}


                    {typeOfContent === "Podcast" ?
                      // typeOfContent === "Video"
                      (
                        <>
                          <Col md="12" className="mb-4">
                            <div className="form-group">
                              <Label htmlFor="title">Podcast File</Label>
                              <span className="text-danger fw-bold">*</span>
                              <span><small className="text-success"><i> (Supported Image format : .mp3)</i></small></span>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {podcastFile ? (
                                  <div style={{ marginRight: '10px' }}>
                                    <audio controls>
                                      <source src={podcastFile} type="audio/mpeg" />
                                    </audio>
                                  </div>
                                ) : (
                                  <div className="form-group mb-3">
                                    <label className="form-label">Upload New Podcast File</label>
                                    <div>
                                      <Input
                                        className="form-control"
                                        type="file"
                                        accept="audio/mpeg, audio/wav"
                                        innerRef={audioref}
                                        onChange={handleAudioChange}
                                      />
                                    </div>
                                  </div>
                                )}
                                {podcastFile && (
                                  <button className="clear-audio" onClick={handleClearAudio}>Clear Audio</button>
                                )}
                              </div>

                              {podcastError && (
                                <div style={{ color: "red" }}>{podcastError}</div>
                              )}
                            </div>
                          </Col>

                          {/* Thumbnail image */}
                          <div>
                            <div className="form-group mb-3">
                              <Label className="form-label">
                                Thumbnail Image
                              </Label>
                              <span><small className="text-success"><i> (Supported Image formats : .jpeg, .jpg, .png)</i></small></span>
                              {thumnailImage?.length !== 0 && (
                                <div>
                                  <img
                                    className="profile-pic"
                                    src={thumnailImage}
                                    alt="Selected"
                                    style={{
                                      padding: "10px",
                                      maxWidth: "50%",
                                      maxHeight: "150px",
                                    }}
                                  />

                                  <button
                                    className="remove-button"
                                    type="button"
                                    onClick={handleRemoveThumbnail}
                                  >
                                    X
                                  </button>
                                </div>
                              )}
                            </div>

                            <Col className="mb-4" md="12">
                              {!thumnailImage && (
                                <Input
                                  type="file"
                                  name="newThumbnailImage"
                                  innerRef={thumbref}
                                  accept="image/png, image/jpeg, image/jpg"
                                  onChange={handleThumbnailImageChange}
                                  multiple={false}
                                />
                              )}
                              {thumbError && (
                                <div style={{ color: "red" }}>{thumbError}</div>
                              )}
                            </Col>
                          </div>

                          <Col md="12" className="mt-4 mb-4">
                            <div className="form-group">
                              <Label htmlFor="Title" className="form-label">
                                Duration
                              </Label>
                              <span className="text-danger fw-bold">*</span>
                              <Input
                                name="Duration"
                                type="text"
                                onChange={e => handleDuration(e)}
                                value={duration}
                              />
                              {durationError && (
                                <div style={{ color: "red" }}>
                                  {durationError}
                                </div>
                              )}
                            </div>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}

                    {RejectionReason && (
                      <Col md="12" className="mb-4">
                        <div>
                          <Label className="form-label">Rejection Reason</Label>
                          <Input
                            name="Title"
                            className="form-control"
                            placeholder="Enter Rejection Reason"
                            type="text"
                            defaultValue={RejectionReason}
                            disabled
                          />
                        </div>
                      </Col>
                    )}
                    {approveReason && (
                      <Col md="12" className="mb-4">
                        <div>
                          <Label className="form-label">
                            Remarks for Content
                          </Label>
                          <Input
                            name="Title"
                            className="form-control"
                            placeholder="Enter Rejection Reason"
                            type="text"
                            defaultValue={approveReason}
                            disabled
                          />
                        </div>
                      </Col>
                    )}
                    <div className="text-success" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                      <span className="text-danger fw-bold">*</span> updated content will be sent to the admin for approval
                    </div>
                    <div className="mt-2">
                      <button
                        type="submit"
                        color="primary"
                        className=" btn btn-primary btn-block me-3"
                      >
                        {buttonLoader ? (
                          <Spinner color="light" size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                      <Button
                        type="submit"
                        color="danger"
                        className="btn btn-primary btn-block"
                        onClick={() =>
                          navigate("/creator/content-list/Approved")
                        }
                      >
                        Close
                      </Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          )}
        </div>

        <Modal
          isOpen={showCreationPopup?.isOpen}
          toggle={handleClose}
          centered={true}
        >
          <ModalHeader centered={true} toggle={handleClose} tag="h4">
            <h4>Alert</h4>
          </ModalHeader>
          <ModalBody centered={true}>
            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              {showCreationPopup?.statusCode === 200 ? (
                <Row>
                  <Col md={12} className="text-center">
                    <img
                      src={success_img}
                      alt="success_popup"
                      className="popup-success"
                    />
                    <h5>Your Content is submitted for Approval.</h5>
                  </Col>
                  <hr />

                  <Col md={12}>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-danger save-customer me-2"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Form>
          </ModalBody>
        </Modal>
      </Form>
    </React.Fragment >
  )
}
