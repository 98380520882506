import React from "react"
import RecentContentByCategory from "../../webComponents/ContentByCategory/RecentContentByCategory"
import PopularByCategory from "../../webComponents/ContentByCategory/PopularByCategory"
import { useParams } from "react-router-dom"

export default function ContentByCategoryindex() {
  const { category } = useParams()
  document.title = `${category}`;
  
  return (
    <>
      <RecentContentByCategory />
      <PopularByCategory />
    </>
  )
}
