import React, { useEffect, useMemo, useState } from "react";
import {
    Card, CardBody, Col, Container, Row
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import TableContainer from '../../common/TableContainer';
import Breadcrumbs from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import { WithdrawActions } from '../../store/withdrawRequest/actions';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import { userAuthActions } from "../../store/userAuth/actions";
import ProfileUpdateAlertModal from "../../components/commonModals/ProfileUpdateAlertModal"
import WithdrawRequestDuplicateAlertModal from "../../components/commonModals/WithdrawRequestDuplicateModal"
import CustomPagination from "common/CustomPagination";

const CreatorWithdrawalRequestIndex = () => {
    document.title = "Withdraw | Allen Dreyfus";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [requestList, setRequestList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer);
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { creatorWithdrawRequestByCreatorId } = useSelector(state => state.withdrawReducer);

    useEffect(() => {
        if (creatorTokenResponse?.result) {
            dispatch({
                type: WithdrawActions.GET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID,
                payload: creatorTokenResponse?.result?._id
            })
        }
    }, [creatorTokenResponse])


    useEffect(() => {
        if (creatorWithdrawRequestByCreatorId?.result?.length !== 0) {
            setRequestList(creatorWithdrawRequestByCreatorId?.result)
        }
    }, [creatorWithdrawRequestByCreatorId?.result])


    const columns = useMemo(
        () => [
            {
                Header: 'Total Amount',
                accessor: 'TotalAmount',
                filterable: true,
            },
            {
                Header: 'Pending Amount',
                accessor: 'PendingAmount',
                filterable: true,
            },
            {
                Header: 'Requested Amount',
                accessor: 'WithdrawAmount',
                filterable: true,
            },
            {
                Header: 'Requested On',
                accessor: 'Submitted_On',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'Status',
                filterable: true,
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const withdrawData = cellProps.row.original;
                                    navigate('/withdraw-detail', { state: withdrawData });
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            },
        ], []
    );


    const handleSendRequest = () => {
        if (creatorTokenResponse && creatorTokenResponse?.result?.Bank_AC_Number && creatorTokenResponse?.result?.Bank_Name && creatorTokenResponse?.result?.Branch && creatorTokenResponse?.result?.IBAN_Number && creatorTokenResponse?.result?.Name_As_Per_Account) {
            setShowModal(false);
            dispatch({
                type: WithdrawActions.GET_WITHDRAW_REQUEST_LISTBY_CREATOR_ID,
                payload: creatorTokenResponse?.result?._id
            })
            let FindStatus = creatorWithdrawRequestByCreatorId?.result?.find((single) => single.Status === 'Pending')
            if (FindStatus) {
                setShowModal2(true)
            } else {
                navigate('/send-withdrawal-request')
            }
        } else {
            setShowModal(true);
        }
    }

    const handleSubmit = () => {
        navigate('/creator-profile')
    }

    const handleCloseModel = () => {
        setShowModal(false);
    };

    const handleCloseModel2 = () => {
        setShowModal2(false);
    };

    useEffect(() => {
        dispatch({
            type: userAuthActions.VERIFY_TOKEN,
            payload: localStorage.getItem("GFX_TOKEN2"),
        })
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumbs
                            title="Withdraw Requests"
                            breadcrumbItem="list"
                        />
                        <Row>
                            <Col xs="12">
                                {parseFloat(creatorTokenResponse?.result?.Remaining_Wallet_Balance) !== 0 &&
                                    <div style={{ textAlign: 'right' }}>
                                        <button onClick={handleSendRequest} className="btn btn-primary list-add-button">Send Request</button>
                                    </div>
                                }
                                <Card className="payouts">
                                    <CardBody>
                                        {requestList && requestList?.length > 0 ? (
                                            <React.Fragment>
                                                {/* Need approval from client */}
                                                {/* <div className="d-flex justify-content-end mb-2">
                          <CSVLink
                            data={payouts}
                            headers={csvHeaders}
                            filename={"payouts_data.csv"}
                            onClick={() => handleSuccess()}

                          >
                            <Button color="primary">Export</Button>
                          </CSVLink>
                        </div> */}
                                                <TableContainer
                                                    columns={columns}
                                                    data={requestList || []}
                                                    isGlobalFilter={true}
                                                    isAddCustList={false}
                                                    isPagination={true}
                                                    // isSendRequest={true}
                                                    // handleSendRequest={handleSendRequest}
                                                    customPageSize={itemsPerPage}
                                                    tableClass="align-middle table-nowrap"
                                                    isShowingPageLength={false}
                                                    iscustomPageSizeOptions={true}
                                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                                />
                                            </React.Fragment>
                                        ) : (
                                            <>
                                                <div className="no-records">
                                                    No records found
                                                </div>

                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                }
                {showModal && (
                    <ProfileUpdateAlertModal
                        modal={showModal}
                        data={creatorTokenResponse}
                        toggle={handleCloseModel}
                        title="alert"
                        message="kindly update your profile details before sending request"
                        handleSubmit={handleSubmit}
                        type={showPopup?.data?.modalType}
                    />
                )}
                {showModal2 && (
                    <WithdrawRequestDuplicateAlertModal
                        modal={showModal2}
                        toggle={handleCloseModel2}
                        title="alert"
                    />
                )}
            </div>

        </React.Fragment>
    );
};

export default CreatorWithdrawalRequestIndex;
