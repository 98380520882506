// webHome/sagas.js
import { takeEvery, call, all, put } from "redux-saga/effects"
import { WebHomePageActions } from "./actions"
import { API_URL } from "../../utils/constant"
import axios from "axios"
import { commonActions } from "../common/actions"

export const webHomeSagas = function* () {
  yield all([
    takeEvery(
      WebHomePageActions.GET_WEBHOME_RECENTSTORIES_DETAILS,
      getWebHomeRecentStoriesDetails
    ),
    takeEvery(
      WebHomePageActions.GET_LIMITED_RECENT_CONTENT_LIST,
      getLimitedRecentContentList
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_POLITICS_CATEGORY_DETAILS,
      getWebHomePoliticsCategoryDetails
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_FORYOU_NEWS_DETAILS,
      getWebHomeForYouNewsDetails
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_POPULARNEWS_DETAILS,
      getWebHomePopularNewsDetails
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_COUNTRYLIST_DETAILS,
      getWebHomeCountrylistDetails
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_VIEWCOUNT_DETAILS,
      getWebHomeViewCountDetails
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_CONTENTBY_RECENT_TOPIC,
      getWebHomeContentByRecentTopic
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_RECENTTOPIC_NEWS_DETAILS,
      getWebHomeRecentTopicNewsDetails
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
      getWebHomeProfileUserInfoDetails
    ),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS,
      getWebHomeUserInfoUpdatedDetails
    ),
    takeEvery(WebHomePageActions.GET_WEBHOME_PODCAST, getWebHomePodcast),
    takeEvery(
      WebHomePageActions.GET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME,
      getWebHomeForyouspecificnews
    ),
    takeEvery(
      WebHomePageActions.GET_LIMITED_FORU_LIST,
      getLimitedForYouList
    ),

    takeEvery(
      WebHomePageActions.GET_WEBHOME_SPECIFIC_PODCAST_HOME,
      getSpecificPodcastlistforHome
    ),
    takeEvery(
      WebHomePageActions.WEB_USER_CHANGE_PASSWORD,
      getWebUserChangePassword
    ),
  ])
}

const getWebHomeRecentStoriesDetails = function* () {
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/recentContentList`)
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_RECENTSTORIES_DETAILS,
      payload: result.data,
    })
    yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false })
  } catch (error) {
    console.error("Error", error)
  }
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false })
}

const getLimitedRecentContentList = function* () {
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/limited/recentContentList`)
    )
    yield put({
      type: WebHomePageActions.SET_LIMITED_RECENT_CONTENT_LIST,
      payload: result.data,
    })
    yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false })
  } catch (err) {

  }
}

const getWebHomePoliticsCategoryDetails = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/content/listBasedOnTypeOfContentAndCategory/${payload.TypeOfContent}/${payload.Category}`
      )
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_POLITICS_CATEGORY_DETAILS,
      payload: result.data,
    })
    yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false })
  } catch (error) {
    console.error("Error", error)
  }
  yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false })
}

const getWebHomeForYouNewsDetails = function* (data) {
  const { payload } = data

  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/content/listBasedOnUserPreference/${payload.Category}/${payload.UserId}`
      )
    )

    yield put({
      type: WebHomePageActions.SET_WEBHOME_FORYOU_NEWS_DETAILS,
      payload: result.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getWebHomePopularNewsDetails = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/popularContentListBasedOnViewCount`)
    )

    yield put({
      type: WebHomePageActions.SET_WEBHOME_POPULARNEWS_DETAILS,
      payload: result.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getWebHomeCountrylistDetails = function* (data) {
  const { payload } = data

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/marketsList/${payload?.Country_Value}`)
    )

    yield put({
      type: WebHomePageActions.SET_WEBHOME_COUNTRYLIST_DETAILS,
      payload: result.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getWebHomeViewCountDetails = function* (data) {
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/content/updateViewCount/${payload}`)
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_VIEWCOUNT_DETAILS,
      payload: result.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}
const getWebHomeContentByRecentTopic = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/recentTopic/list`))
    yield put({
      type: WebHomePageActions.SET_WEBHOME_CONTENTBY_RECENT_TOPIC,
      payload: result.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getWebHomeRecentTopicNewsDetails = function* (data) {
  const { payload } = data

  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/content/subCategoryList/${payload?.Category}/${payload?.Sub_Category}`
      )
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_RECENTTOPIC_NEWS_DETAILS,
      payload: result?.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getWebHomeProfileUserInfoDetails = function* (data) {
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/user/detail/${payload.userId}`)
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_PROFILE_USERINFO_DETAILS,
      payload: result?.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

function* getWebHomeUserInfoUpdatedDetails(data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/user/editDetail/${payload.userId}`,
        payload?.updatedData
      )
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "success",
          message: result?.data?.message,
        },
      })
      yield put({
        type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
        payload: { userId: payload.userId },
      })
    }
  } catch (err) {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    console.log(err)
  }
}

const getWebHomePodcast = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/content/podcastList`))
    yield put({
      type: WebHomePageActions.SET_WEBHOME_PODCAST,
      payload: result?.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getWebHomeForyouspecificnews = function* (data) {
  const { payload } = data

  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/content/list/forYouHomeContent/${payload.UserId}/${payload.TypeOfContent}`
      )
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_FORYOU_SPECIFIC_NEWS_HOME,
      payload: result?.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getLimitedForYouList = function* (data) {
  const { payload } = data

  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/limited/forYouHomeContent/${payload.UserId}/${payload.TypeOfContent}`
      )
    )
    yield put({
      type: WebHomePageActions.SET_LIMITED_FORU_LIST,
      payload: result?.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getSpecificPodcastlistforHome = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/content/podcastHomeList`)
    )
    yield put({
      type: WebHomePageActions.SET_WEBHOME_SPECIFIC_PODCAST_HOME,
      payload: result?.data,
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getWebUserChangePassword = function* (data) {
  const { payload } = data
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true })
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/resetPassword/${payload.Id}`, payload.payload)
    )

    yield put({
      type: WebHomePageActions.WEB_USER_SET_CHANGE_PASSWORD,
      payload: result?.data,
    })
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false })
    if (result.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          show: true,
          status: "success",
          message: result?.data?.message,
        },
      })
    }
  } catch (error) {
    console.error("Error", error)
  }
}
