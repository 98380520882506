import React, { useEffect, useState } from "react";
import Header from '../../webComponents/Layout/Header'
import Footer from '../../webComponents/Layout/Footer'
import ContentByRecentTopicIndex from 'webComponents/ContentByRecentTopic'
import HrBanner from 'webComponents/Common/FreeTrailBannerImg'
import Banner1 from "../../assets/webImages/HR banner-darkblue.webp";
import SubsriberBanner from "../../assets/webImages/un banner white,black.webp"
import { useDispatch, useSelector } from "react-redux";
import { WebHomePageActions } from "store/webHome/actions";
import WebPageLoader from "../../common/webPageLoader";

export default function ContentByRecentTopicPage() {
  const dispatch = useDispatch();
  const { tokenResponse } = useSelector((state) => state.userAuthReducer);

  let userId = tokenResponse?.result?._id;
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes";
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes";
  const stripeCutomerId = tokenResponse?.result?.Stripe_Customer_Id;
  const [loading, setLoading] = useState(false);

  const {getSessionId} = useSelector(state => state.subscriptionPlanReducer);
  
  useEffect(() => {
    const payload = { userId }; 
    dispatch({
      type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
      payload,
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (getSessionId) {
      setLoading(false);
    }
  }, [getSessionId])

  return (
    <>
      {loading ? <WebPageLoader /> :
        <div>
          <Header />
          {isSubscribed || isTrialUsed || stripeCutomerId ? (
            <HrBanner bannerImage={SubsriberBanner} bannerLink="/" bannerType="subscribed" setLoading={setLoading} />
          ) : (
            <HrBanner bannerImage={Banner1} bannerLink="/" bannerType="freeTrial" setLoading={setLoading} />
          )}
          <ContentByRecentTopicIndex />
          <Footer />
        </div>
      }
    </>
  )
}