import React from "react";
import AdminAddPodcastIndex from "components/adminAddPodcast";

export default function AdminAddPodcast() {
    return (
        <div>
            <AdminAddPodcastIndex />
        </div>
    )
}
