import AdminPitchListIndex from "components/adminPitchList/index";
import React from "react";

export default function adminPitch () {

    return(
        <div>
            <AdminPitchListIndex />
        </div>
    )
}