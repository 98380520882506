import React, { useEffect } from "react"
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Failed from "../../assets/webImages/transactionfailed.gif"

const StripeFailed = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { paymentResult } = useParams();

    useEffect(() => {
        // Extract payment result from URL parameters
        const searchParams = new URLSearchParams(location.search);
        const paymentResult = searchParams.get('paymentResult');
        // Handle payment result
        if (paymentResult === 'success') {
            // Payment 
            // Redirect or perform any other actions
        } else {
            // Payment failed or invalid result
            // Redirect or perform any other actions
        }

        // Optionally, you can redirect the user after handling the payment result
        // history.push('/dashboard');
    }, [location]);


    return (
        <div className="Successpage">
            <Container className="d-flex align-items-center justify-content-center">
                <Row>
                    <div className="center-page">
                        <img
                            src={Failed}
                            className="verified-sucess pb-0"
                            alt="Verification failed"
                            draggable="false"
                        />
                        <div className="trans-failed">
                            <h1>Subscription Failed</h1>
                        </div>

                        <div className="web_fonts">
                            <p className="web_fonts">
                               we are unable to process your request
                            </p>
                        </div>

                        <div className="text-center go-home-div">
                            <Button className="web-fonts go-back" onClick={() => navigate("/")}>back</Button>
                        </div>
                    </div>
                </Row>
            </Container>
        </div >
    )
}

export default StripeFailed