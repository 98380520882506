import PropTypes from "prop-types";
import React from "react";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logosm from '../../assets/images/dashboard/new-logo12.jpg';
import logofull from '../../assets/images/dashboard/new-full-log.png';

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box navbar-logo-brand-width">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logosm} alt="" className="logo-sm-img" />
            </span>
            <span className="logo-lg">
              <img src={logofull} alt="" className="logo-lg-img" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

export default Sidebar;
