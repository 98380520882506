import React from "react";
import AbandonedCartIndex from "components/abandonedCart";

export default function AbandonedCart() {

    return (
        <div>
            <AbandonedCartIndex />
        </div>
    )
}