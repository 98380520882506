import AdminProfileIndex from "components/adminProfile/index";
import React from "react";

export default function AdminProfile() {

    return (
        <div>
            <AdminProfileIndex />
        </div>
    )
}