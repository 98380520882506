import React, { useState, useEffect } from "react";
import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Container,
    Label,
    Input,
    FormFeedback
} from "reactstrap";

export default function Draft({ }) {
    const [draftContent, setDraftContent] = useState({
        Title: '',
        describtion: '',
    });

    useEffect(() => {
        saveDraft();
    }, [draftContent]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDraftContent((prevDraft) => ({ ...prevDraft, [name]: value }));
      };

      const saveDraft = async () => {
        try {
          // Send a POST request to the server to save the draft
          const response = await fetch('http://localhost:3001/api/content/draft', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(draftContent),
          });
    
          const data = await response.json();
        
        } catch (error) {
          console.error('Error saving draft:', error);
        }
      };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h1>Draft</h1>
                    <Label htmlFor="title" className="form-label">Title</Label><span className="text-danger fw-bold">*</span>
                    <Input
                        className="form-control"
                        name="Title"
                        type="text"
                        value={draftContent.Title}
                        onChange={handleInputChange}
                    ></Input>
                    <Label htmlFor="title" className="form-label">Description</Label><span className="text-danger fw-bold">*</span>
                    <Input
                        className="form-control"
                        name="describtion"
                        type="text"
                        value={draftContent.describtion}
                        onChange={handleInputChange}
                    ></Input>
                </Container>
            </div>
        </React.Fragment>
    )
}