import React from 'react'
import SaveContent from './SaveContent'
import Header from 'webComponents/Layout/Header'
import HrBanner from 'webComponents/Common/FreeTrailBannerImg'
import BannerSave from "../../assets/webImages/LK skyblue,white.webp";
import Footer from 'webComponents/Layout/Footer';





function SaveContentindex() {
  return (
    <div>
    <Header/>
    <HrBanner bannerImage={BannerSave} />
    <SaveContent/>
    <Footer/>

    </div>
  )
}

export default SaveContentindex