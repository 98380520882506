import { payoutsActions } from "./actions";

const initialState = {
    adminPayoutsList: [],
    adminPayoutsDetail: '',
    payoutResponse: '',
};

export const payoutsReducer = (state = initialState, action) => {
    switch (action.type) {
        case payoutsActions.SET_ADMIN_PAYOUTS_LIST:
            return {
                ...state,
                adminPayoutsList: action.payload,
            }
        case payoutsActions.SET_ADMIN_PAYOUT_DETAIL:
            return {
                ...state,
                adminPayoutsDetail: action.payload
            }
        case payoutsActions.PAYOUT_RESPONSE: {
            return {
                ...state,
                payoutResponse: action.payload,
            }
        }
        default:
            return state;
    }
}
