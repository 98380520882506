export const creatorDashboardActions = {
    GET_TOTAL_WALLET_COUNT: 'GET_TOTAL_WALLET_COUNT',
    SET_TOTAL_WALLET_COUNT: 'SET_TOTAL_WALLET_COUNT',

    GET_CREATOR_CONTENT_GRAPH_COUNT: 'GET_CREATOR_CONTENT_GRAPH_COUNT',
    SET_CREATOR_CONTENT_GRAPH_COUNT: 'SET_CREATOR_CONTENT_GRAPH_COUNT',

    GET_CREATOR_WALLET_DETAILS: 'GET_CREATOR_WALLET_DETAILS',
    SET_CREATOR_WALLET_DETAILS: 'SET_CREATOR_WALLET_DETAILS',

    GET_CREATOR_REVENUE_DETAILS: 'GET_CREATOR_REVENUE_DETAILS',
    SET_CREATOR_REVENUE_DETAILS: 'SET_CREATOR_REVENUE_DETAILS',

}