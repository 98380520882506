import PayoutDetailIndex from "components/payoutDetail";
import React from "react";

export default function PayoutDetail () {

    return (
        <div>
            <PayoutDetailIndex />
        </div>
    )
}