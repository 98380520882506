import AdminWithdrawRequestIndex from "../components/adminWithdrawalRequests/index";
import React from "react";

export default function AdminWithdrawRequestPage() {

    return (
        <div>
            <AdminWithdrawRequestIndex />
        </div>
    )
}