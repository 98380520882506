import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody } from "reactstrap";
import TableContainer from "../../common/TableContainer";
import { BlogContentActions } from "../../store/blogContent/actions";
import { useNavigate } from "react-router-dom";

const NeedApprovalList = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    var [ContentList, setContentList] = useState([]);

    const { ContentNeedApprovalList } = useSelector(state => state.BlogContentReducer);

    useEffect(() => {
        dispatch({
            type: BlogContentActions.GET_ADMIN_CONTENT_LIST_NEED_APPROVAL,
        });
    }, [dispatch])

    useEffect(() => {
        if (ContentNeedApprovalList?.length !== 0) {
            setContentList(ContentNeedApprovalList)
        }
    }, [ContentNeedApprovalList]);

    const columns = useMemo(
        () => [

            {
                Header: "Creator",
                accessor: "Created_By",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Title",
                accessor: "Title",
                disableFilters: true,
                filterable: false,
                Cell: title => {
                    return <ContentTitle {...title} />
                }
            },
            {
                Header: 'Status',
                accessor: 'Status',
                disableFilters: true,
                filterable: false,
            },
            {
                Header: 'Submitted On',
                accessor: 'Created_On',
                disableFilters: true,
                filterable: false,
            },
            // {
            //     Header: "Created On",
            //     accessor: "CreatedOn",
            //     disableFilters: true,
            //     filterable: false,
            // },
            {
                Header: "View Details",
                disableFilters: true,
                accessor: "view",
                Cell: cellProps => {
                    return (
                        <Button
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded"

                            onClick={() => {
                                const Data = cellProps.row.original;
                                navigate('/content-details', { state: { data: Data, type: "needApproval" } });
                            }}
                        >
                            View Details
                        </Button>
                    );
                },
            },
        ]
    );

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="mb-4 h4 card-title">Content Need Approval</div>
                    {ContentList && ContentList.length > 0 ? (
                        <TableContainer
                            columns={columns}
                            data={ContentList || []}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            isPagination={true}
                            iscustomPageSizeOptions={false}
                            customPageSize={4}
                            pagination="pagination pagination-rounded justify-content-end mb-2"
                        />) : (
                        <div className="no-records">No records found</div>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

const ContentTitle = (cell) => {
    return (cell?.value && cell?.value?.length > 25) ? `${cell?.value?.slice(0, 25)}...` : cell?.value
}
export default NeedApprovalList;