import PayoutsIndex from "components/payouts";
import React from "react";

export default function Payouts () {

    return (
        <div>
            <PayoutsIndex />
        </div>
    )
}