import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import { dashboardActions } from "./actions";
import { commonActions } from "../common/actions";
import axios from "axios";
import { API_URL } from "../../utils/constant";

export const bashboardSagas = function* () {
    yield all([
        yield takeEvery(dashboardActions.GET_ADMIN_END_USER_COUNT, getAdminEndUserCount),
        yield takeEvery(dashboardActions.GET_ADMIN_CONTENT_CREATOR_COUNT, getAdminContentCreatorCount),
        yield takeEvery(dashboardActions.GET_ADMIN_ADMINCOUNT, getAdminCount),
        yield takeEvery(dashboardActions.GET_ADMIN_TOTAL_SUBSCRIPTION_COUNT, getAdminTotalSubscriptionCount),
        yield takeEvery(dashboardActions.GET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT, getCurrentMonthSubscriptionCount),
        yield takeEvery(dashboardActions.GET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT, getAdminCurrentMonthSubscriptionAmount),
        yield takeEvery(dashboardActions.GET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT, getAdminPreviousMonthSubscriptionAmount),
        yield takeEvery(dashboardActions.GET_ADMIN_CONTENT_GRAPH_COUNT, getAdminContentGraphCount)
    ])
};

const getAdminEndUserCount = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/list`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_END_USER_COUNT,
            payload: result?.data
        })        
    } catch (err) {
        console.log(err);
    }
}

const getAdminContentCreatorCount = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/contentCreator/list/${payload}`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_CONTENT_CREATOR_COUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

const getAdminCount = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/admin/list`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_ADMINCOUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

const getCurrentMonthSubscriptionCount = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/currentMonthSubscriptionAndTotal`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_CURRENT_MONTH_SUBSCRIPTION_COUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

const getAdminTotalSubscriptionCount = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/totalSubscription`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_TOTAL_SUBSCRIPTION_COUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}


const getAdminCurrentMonthSubscriptionAmount = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/currentMonthSubscriptionAndTotal`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_CURRENT_MONTH_SUBSCRITION_AMOUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}


const getAdminPreviousMonthSubscriptionAmount = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/previousMonthSubscriptionAmount`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_PREVIOUS_MONTH_SUBSCRIPTION_AMOUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

const getAdminContentGraphCount = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/content/graph/${payload}`)
        );
        yield put({
            type: dashboardActions.SET_ADMIN_CONTENT_GRAPH_COUNT,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}