import React from "react"
import { Navigate } from "react-router-dom"

import PublicRoute from "../route/publicRoute"
import PrivateRoute from "../route/privateRoute"

import Dashboard from "../pages/dashboard"
import Customers from "../pages/customers"
import AbandonedCart from "../pages/abandonedCart"
import CustomerDetails from "../pages/customerDetails"
import Admin from "../pages/admin"
import AdminDetails from "../pages/adminDetails"
import EditAdminDetails from "pages/editAdminDetails"
import AddAdmin from "../pages/addAdmin"
import ContentCreators from "../pages/contentCreators"
import EditContentCreatorDetails from "pages/editContentCreatorDetails"
import ContentCreatorDetails from "../pages/contentCreatorDetails"
import Contents from "../pages/Admincontents"
import ContentDetails from "../pages/contentDetails"
import AddContentCreator from "../pages/addContentCreator"
import SubscriptionPlans from "pages/subscriptionPlans"
import Payouts from "../pages/payouts"
import AddPayouts from "../pages/adminAddPayouts"
import PayoutDetail from "pages/payoutDetail"
import AdminWithdrawRequest from "../pages/adminWithdrawRequestPage"
import AdminWithdrawRequestDetailPage from "../pages/adminwithdrawRequestDetail"
import Revenue from "../pages/revenue"
import CorporateSubscription from "../pages/corporateSubsciption"
import CorporateSubscriptionDetailIndex from "../pages/corporateSubscriptionDetail"
import RevenueDetail from "pages/revenueDetail"
import AdminContent from "../pages/Admincontents"
import AdminPitchList from "../pages/adminPitchList"
import AdminPitchDetail from "../pages/adminPitchDetail"
import AdminEditContentDetails from "../pages/adminEditContentDetails"
import AdminProfile from "../pages/adminProfile"
import MarketFocus from "pages/countrylistpage"
import Countrylist from "pages/countriesOnlyListPage"
import RecentTopic from "pages/RecentTopic"
import Newsletterlist from "pages/Newsletter"
import AdminContentList from "pages/adminPodcastList"
import AdminAddPodcast from "pages/adminAddPodcast"

import Login from "../pages/login"
import ContentCreatorRegistration from "../pages/contentCreatorRegistration"
import ForgotPassword from "../pages/forgotPassword"
import LockScreen from "../common/lockScreen"
import AdvertiseWithUsListPage from "../pages/AdvertiseWithUsList"
import AdvertiseDetailPage from "../pages/advertiseDetails"

//content creator dashboard
import CreateContent from "../pages/contentCreation"
import ContentList from "../pages/contentList"
import CreatorProfile from "../pages/creatorProfile"
import CreatorContentDetail from "../pages/CreatorContentDetail"
import CreatorDashboard from "components/creator-dashboard"
import CreatePitchContent from "../pages/createPitchContent"
import CreatorPitchList from "../pages/creatorPitchList"
import CreatorPitchDetail from "../pages/creatorPitchDetail"
import CreatorEditPitchDetail from "../pages/creatorEditPitch"
import EditContentDetail from "../pages/creatorEditContentDetail"
import Draft from "components/draft"
import ChangePassword from "pages/ChangePassword"
import CreatorWalletDetail from "pages/creatorWalletDetail"
import CreatorWithdrawalRequest from "../pages/creatorWithdrawalRequest"
import SendWithdrawRequest from "../pages/sendWithdrawRequest"
import CreatorWithdrawRequestDetail from '../pages/creatorWithdrawRequestDetail'

//website pages
import HomePage from "../pages/webPages/Home"
import ContentByCategory from "pages/webPages/ContentByCategoryPage"
import WebLogin from "../pages/webPages/webLogin"
import SignUp from "pages/webPages/signUp"
import WebContentDetails from "../pages/webPages/contentDetails"
import WebForgotPassword from "../pages/webPages/webForgotPassword"
import ContentByCountry from "../pages/webPages/ContentByCountry"
import FullForYouPage from "pages/webPages/FullForYouPage"
import ContentByRecentTopicPage from "pages/webPages/ContentByRecentTopicPage"
import PodcastPage from "pages/webPages/PodcastPage"
import WebProfile from "pages/webPages/Profile"
import PrivacyPolicy from "pages/webPages/PrivacyPolicy"
import SaveContentindex from "webComponents/SaveContent"
import ContactUsIndex from "pages/webPages/contactuspage"
import TermsOfService from "pages/webPages/TermsOfService"


// Stripe success & Failure page
import StripeSuccess from '../webComponents/Common/stripeSuccess'
import StripeFailure from '../webComponents/Common/stripeFailure'
import AdvertiseWithUsPage from "webComponents/Advertise/advertisewithus"

//Admin

import AdminDashboard from "../components/AdminDashboard/adminDashboardIndex"
import SubAdminProfile from "pages/subAdminProfile"

const authProtectedRoutes = [

  {
    path: "/admin-profile",
    component: (
      <PrivateRoute>
        <AdminProfile />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    component: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin-dashboard",
    component: (
      <PrivateRoute>
        <AdminDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/customers",
    component: (
      <PrivateRoute>
        <Customers />
      </PrivateRoute>
    ),
  },
  {
    path: "/abandoned-cart",
    component: (
      <PrivateRoute>
        <AbandonedCart />
      </PrivateRoute>
    ),
  },
  {
    path: "/customer-details",
    component: (
      <PrivateRoute>
        <CustomerDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin",
    component: (
      <PrivateRoute>
        <Admin />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin-details",
    component: (
      <PrivateRoute>
        <AdminDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/edit-admin-details",
    component: (
      <PrivateRoute>
        <EditAdminDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/add-admin",
    component: (
      <PrivateRoute>
        <AddAdmin />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin-podcast",
    component: (
      <PrivateRoute>
        <AdminContentList />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin-add-podcast",
    component: (
      <PrivateRoute>
        <AdminAddPodcast />
      </PrivateRoute>
    )
  },
  {
    path: "/content-creators/:type",
    component: (
      <PrivateRoute>
        <ContentCreators />
      </PrivateRoute>
    ),
  },
  {
    path: "/edit-content-creator-details",
    component: (
      <PrivateRoute>
        <EditContentCreatorDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/content-creator-details",
    component: (
      <PrivateRoute>
        <ContentCreatorDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/contents",
    component: (
      <PrivateRoute>
        <Contents />
      </PrivateRoute>
    ),
  },
  {
    path: "/content-details",
    component: (
      <PrivateRoute>
        <ContentDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/add-content-creator",
    component: (
      <PrivateRoute>
        <AddContentCreator />
      </PrivateRoute>
    ),
  },
  {
    path: "/subscription-plans",
    component: (
      <PrivateRoute>
        <SubscriptionPlans />
      </PrivateRoute>
    ),
  },
  {
    path: "/payouts",
    component: (
      <PrivateRoute>
        <Payouts />
      </PrivateRoute>
    ),
  },
  {
    path: "/add-payouts",
    component: (
      <PrivateRoute>
        <AddPayouts />
      </PrivateRoute>
    ),
  },
  {
    path: "/payout-detail",
    component: (
      <PrivateRoute>
        <PayoutDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/all-withdrawal-request",
    component: (
      <PrivateRoute>
        <AdminWithdrawRequest />
      </PrivateRoute>
    )
  },
  {
    path: "/all-withdrawal-request-detail",
    component: (
      <PrivateRoute>
        <AdminWithdrawRequestDetailPage />
      </PrivateRoute>
    )
  },
  {
    path: "/revenue",
    component: (
      <PrivateRoute>
        <Revenue />
      </PrivateRoute>
    ),
  },
  {
    path: "/revenue-detail",
    component: (
      <PrivateRoute>
        <RevenueDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/corporate-subscription",
    component: (
      <PrivateRoute>
        <CorporateSubscription />
      </PrivateRoute>
    ),
  },
  {
    path: "/corporate-subscription-detail",
    component: (
      <PrivateRoute>
        <CorporateSubscriptionDetailIndex />
      </PrivateRoute>
    ),
  },
  {
    path: "/countries",
    component: (
      <PrivateRoute>
        <Countrylist />
      </PrivateRoute>
    ),
  },
  {
    path: "/market-focus",
    component: (
      <PrivateRoute>
        <MarketFocus />
      </PrivateRoute>
    ),
  },

  {
    path: "/recent-topic",
    component: (
      <PrivateRoute>
        <RecentTopic />
      </PrivateRoute>
    ),
  },


  {
    path: "/Newsletterlist",
    component: (
      <PrivateRoute>
        <Newsletterlist />
      </PrivateRoute>
    ),
  },
  {
    path: "/advertise-with-us-list",
    component: (
      <PrivateRoute>
        <AdvertiseWithUsListPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/advertise-details",
    component: (
      <PrivateRoute>
        <AdvertiseDetailPage />
      </PrivateRoute>
    ),
  },

  {
    path: "/content/:type",
    component: (
      <PrivateRoute>
        <AdminContent />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/pitch/:type",
    component: (
      <PrivateRoute>
        <AdminPitchList />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/pitch-detail",
    component: (
      <PrivateRoute>
        <AdminPitchDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/creator-edit-content",
    component: (
      <PrivateRoute>
        <EditContentDetail />
      </PrivateRoute>
    ),
  }, //original content

  //content creator dashboard

  {
    path: "/creator-dashboard",
    component: (
      <PrivateRoute>
        <CreatorDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/content-creation",
    component: (
      <PrivateRoute>
        <CreateContent />
      </PrivateRoute>
    ),
  },
  {
    path: "/creator/content-list/:type",
    component: (
      <PrivateRoute>
        <ContentList />
      </PrivateRoute>
    ),
  },
  {
    path: "/creator-content-detail",
    component: (
      <PrivateRoute>
        <CreatorContentDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/creator-profile",
    component: (
      <PrivateRoute>
        <CreatorProfile />
      </PrivateRoute>
    ),
  },
  {
    path: "/ChangePassword",
    component: (
      <PrivateRoute>
        <ChangePassword />
      </PrivateRoute>
    ),
  },


  {
    path: "/pitch-creation",
    component: (
      <PrivateRoute>
        <CreatePitchContent />
      </PrivateRoute>
    ),
  },
  {
    path: "/pitch/:type",
    component: (
      <PrivateRoute>
        <CreatorPitchList />
      </PrivateRoute>
    ),
  },
  {
    path: "/pitch-detail",
    component: (
      <PrivateRoute>
        <CreatorPitchDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/edit-pitch-detail",
    component: (
      <PrivateRoute>
        <CreatorEditPitchDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/draft",
    component: (
      <PrivateRoute>
        <Draft />
      </PrivateRoute>
    ),
  },
  {
    path: "/edit-content-details",
    component: (
      <PrivateRoute>
        <AdminEditContentDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/creator-wallet-details",
    component: (
      <PrivateRoute>
        <CreatorWalletDetail />
      </PrivateRoute>
    )
  },
  {
    path: "/withdrawal-request",
    component: (
      <PrivateRoute>
        <CreatorWithdrawalRequest />
      </PrivateRoute>
    )
  },
  {
    path: "/send-withdrawal-request",
    component: (
      <PrivateRoute>
        <SendWithdrawRequest />
      </PrivateRoute>
    )
  },
  {
    path: "/withdraw-detail",
    component: (
      <PrivateRoute>
        <CreatorWithdrawRequestDetail />
      </PrivateRoute>
    )
  },
  {
    path: "/admin-profile-page",
    component: (
      <PrivateRoute>
        <SubAdminProfile />
      </PrivateRoute>
    )
  }
  // {
  //   path: "/dashboard",
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
]

const publicRoutes = [
  {
    path: "/login",
    component: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/forgot-password",
    component: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    ),
  },
  {
    path: "/register",
    component: (
      // <PublicRoute>
      <ContentCreatorRegistration />
      // </PublicRoute>
    ),
  },

  { path: "/auth-lock-screen", component: <LockScreen /> },

  //website pages
  // {
  //   path: "/",
  //   component: (
  //     <PublicRoute>
  //       <HomePage />
  //     </PublicRoute>
  //   ),
  // },
  // {
  //   path: "/web/:category",
  //   component: (
  //     <PublicRoute>
  //       <ContentByCategory />
  //     </PublicRoute>
  //   ),
  // },
  // {
  //   path: "/web/login",
  //   component: (
  //     <PublicRoute>
  //       <WebLogin />
  //     </PublicRoute>
  //   ),
  // },
  // {
  //   path: "/web/signUp",
  //   component: (
  //     <PublicRoute>
  //       <SignUp />
  //     </PublicRoute>
  //   ),
  // },
  // {
  //   path: "/web/content",
  //   component: (
  //     <PublicRoute>
  //       <WebContentDetails />
  //     </PublicRoute>
  //   ),
  // },

  // website pages without public
  { path: "/", component: <HomePage /> },
  { path: "/web/:category", component: <ContentByCategory /> },
  // { path: "/web/politics", component: <ContentByCategory /> },
  { path: "/web/login", component: <WebLogin /> },
  { path: "/web/signUp", component: <SignUp /> },
  { path: "/web/content/:contentId", component: <WebContentDetails /> },
  { path: "/web/forgotPassword", component: <WebForgotPassword /> },
  { path: "/web/country/:country", component: <ContentByCountry /> },
  { path: "/web/foryou", component: <FullForYouPage /> },
  { path: "/web/recentTopic/:Topic", component: <ContentByRecentTopicPage /> },
  { path: "/web/Podcast", component: <PodcastPage /> },
  { path: "/web/Profile", component: <WebProfile /> },
  { path: "/stripe/:paymentResult", component: <StripeSuccess /> },
  { path: "/stripe/failed", component: <StripeFailure /> },
  { path: "/privacy-notice", component: <PrivacyPolicy /> },
  { path: "/Save", component: <SaveContentindex /> },
  { path: "/terms-of-use", component: <TermsOfService /> },
  { path: "/ContactUs", component: <ContactUsIndex /> },
  { path: "/AdvertiseWithUs", component: <AdvertiseWithUsPage /> }
]

export { authProtectedRoutes, publicRoutes }
