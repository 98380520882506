import { adminActions } from "./actions";

const initialState = {
    adminList: [],
    adminDetails: null,
};

export const adminReducer = (state = initialState, action) => {

    switch (action.type) {

        case adminActions.SET_ADMIN_LIST:
            return {
                ...state,
                adminList: action.payload
            }
        case adminActions.SET_ADMIN_DETAILS:
            return {
                ...state,
                adminDetails: action.payload
            }
        default:
            return state;
    };
};