import { webContentByCategoryActions } from "./actions";
import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import { commonActions } from "../common/actions";

export const webContentByCategorySagas = function* () {
    yield all([
        yield takeEvery(webContentByCategoryActions.GET_WEB_RECENT_CONTENT_BY_CATEGORY, getwebRecentContentByCategory),
        yield takeEvery(webContentByCategoryActions.GET_WEB_LIST_BY_CATEGORY, getWebListByCategory),
        yield takeEvery(webContentByCategoryActions.GET_WEB_POPULAR_LIST_BY_CATEGORY, getWebPopularListByCategory),
    ])
}

// Get content by category List
const getwebRecentContentByCategory = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/content/recentContentListBasedOnCategory/${payload.TypeOfContent}/${payload.Category}`)
        );
        yield put({
            type: webContentByCategoryActions.SET_WEB_RECENT_CONTENT_BY_CATEGORY,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

// Get content by category List
const getWebListByCategory = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/content/listBasedOnTypeOfContentAndCategory/${payload.TypeOfContent}/${payload.Category}`)
        );
        yield put({
            type: webContentByCategoryActions.SET_WEB_LIST_BY_CATEGORY,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
}

// Get popular content by category for web
const getWebPopularListByCategory = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/content/popularContentListBasedOnViewCountAndCategory/${payload}`)
        );
        yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false });
        yield put({
            type: webContentByCategoryActions.SET_WEB_POPULAR_LIST_BY_CATEGORY,
            payload: result?.data
        })
    } catch (err) {
        console.log(err);
    }
    yield put({ type: commonActions.SET_WEB_PAGE_LOADER, payload: false });
}