import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Spinner,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { userAuthActions } from "store/userAuth/actions"
import { decryptedFunction } from "../../utils/cryptoFunctions"
import logosm from "../../assets/images/dashboard/new-logo22.png"
import profile from "../../assets/images/profile-img.png"
import { useFormik } from "formik"
import * as Yup from "yup"

const LoginIndex = () => {
  document.title = "Login | Allen Dreyfus"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { buttonLoader } = useSelector(state => state.commonReducer)

  const [show, setShow] = useState(false)

  let [isChecked, setIsChecked] = useState(false)

  const authUser = decryptedFunction(localStorage.getItem("Auth_User"))

  useEffect(() => {
    localStorage.getItem("Auth_User") ? setIsChecked(true) : setIsChecked(false)
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: authUser?.Email ?? "",
      Password: authUser?.Password ?? "",
    },
    validationSchema: Yup.object({
      Email: Yup.string().required("please enter your email"),
      Password: Yup.string().required("please enter your password"),
    }),
    onSubmit: values => {
      const lowercaseEmail = values.Email.toLowerCase();
      const data = {
        Email: lowercaseEmail,
        Password: values?.Password,
        loginFrom: 'AdminOrCreator'
      }
      dispatch({
        type: userAuthActions.AUTH_LOGIN,
        payload: { data: data, navigate: navigate, checked: isChecked, loginFrom: 'AdminOrCreator' },
      })
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logosm}
                            alt=""
                            className="rounded-circle logo-sm-img-login"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="Email"
                          className="formcontrol"
                          placeholder="enter email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Email || ""}
                          invalid={
                            validation.touched.Email && validation.errors.Email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.Email && validation.errors.Email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.Email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="Password"
                            value={validation.values.Password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.Password &&
                                validation.errors.Password
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.Password &&
                          validation.errors.Password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.Password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          checked={isChecked}
                          onChange={e => setIsChecked(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          {buttonLoader ? (
                            <Spinner color="light" size="sm" />
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your
                          password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Allen Dreyfus</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LoginIndex
