import React from 'react';
import { Modal, ModalHeader, ModalBody, Form, Row, Col, Spinner } from 'reactstrap';

export default function WithdrawRequestDuplicateAlertModal(props) {
    const { modal, toggle, title} = props;

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader className="myCustomInput" toggle={toggle} tag="h4">
                {title}
            </ModalHeader>
            <ModalBody>
                <Form className="myCustomInput padding-model">
                    <Row>
                        <Col md={12} className="mb-4 mt-3 text-center">
                            <h5>Your previous request's status is pending</h5>
                            <h5>Once it is processed then you can send the new request</h5>
                        </Col>
                        <hr />
                        <Col md={12}>
                            <div className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-danger save-customer text-center"
                                    onClick={toggle}
                                 
                                >
                                   close
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )

}