import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Input, Button, FormFeedback, Form, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ContentCreatorActions } from "../../store/contentCreator/actions";
import { payoutsActions } from '../../store/payouts/actions';
import { creatorDashboardActions } from '../../store/creatorDashboard/actions';
import { addDays } from 'date-fns';

export default function addPayoutsIndex() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [creator, setCreator] = useState([]);
    const maxSelectableDate = new Date();

    const { content_Creators } = useSelector(state => state.ContentCreatorReducer);
    const { payoutResponse } = useSelector(state => state.payoutsReducer);

    const { buttonLoader } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (payoutResponse && payoutResponse?.statusCode === 200) {
            validation.resetForm();
        }
    }, [payoutResponse])

    const resetFormFunction = () => {
        validation.resetForm();
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Contributor_Name: '',
            Email: '', // Correct field name
            TotalAmount: '',
            PaidAmount: '',
            Payment_Amount: '',
            Transaction_Id: '', // Change to string if alphanumeric
            Mode_Of_Payment: '',
            Paid_On: '',
        },
        validationSchema: Yup.object({
            Contributor_Name: Yup.string().required('contributor name is required'),
            Email: Yup.string().email('Invalid email format').required('email is required'), // Validation for email
            TotalAmount: Yup.number().required('Total amount is required'),
            PaidAmount: Yup.number().required('paid amount is required'),
            Payment_Amount: Yup.number().required('payment amount is required'),
            Transaction_Id: Yup.string().required('transaction id is required'), // Change to string if alphanumeric
            Mode_Of_Payment: Yup.string().required('mode of payment is required'), // Correct field name
            Paid_On: Yup.date().required('paid on is required').nullable() // Validation for date
        }),
        onSubmit: (data) => {
            const selectedCreator = creator?.find(val => val.Id === validation?.values?.Contributor_Name);
            data['Contributor_Id'] = validation?.values?.Contributor_Name;//will get id here
            data['Contributor_Name'] = selectedCreator?.Name;
            data['Email'] = validation?.values?.Email;
            data['Payment_Amount'] = validation?.values?.Payment_Amount;
            data['Transaction_Id'] = validation?.values?.Transaction_Id;
            data['Mode_Of_Payment'] = validation?.values?.Mode_Of_Payment;
            data['Paid_On'] = validation?.values?.Paid_On;
            dispatch({
                type: payoutsActions.ADD_ADMIN_PAYOUT,
                payload: { data: data, reset: resetFormFunction, navigate: navigate }
            });
        }
    });


    useEffect(() => {
        const type = "Registered"
        dispatch({
            type: ContentCreatorActions.GET_CONTENT_CREATORS_LIST_BY_STATUS,
            payload: type
        });
    }, [dispatch]);

    useEffect(() => {
        if (content_Creators) {
            const creatorsArray = content_Creators?.map(val => ({
                Id: val?._id,
                Name: val?.Name,
                Email: val?.Email,
                TotalAmount: val?.Total_Wallet_Balance,
                PaidAmount: val?.Paid_Amount,
            }));
            setCreator(creatorsArray);
        }
    }, [content_Creators]);

    const handleCreatorChange = (e) => {
        const selectedCreatorId = e.target.value;
        const selectedCreator = creator?.find(creator => creator?.Id === selectedCreatorId);
        if (selectedCreator) {
            validation.setFieldValue("Email", selectedCreator?.Email);
            validation.setFieldValue("TotalAmount", selectedCreator?.TotalAmount);
            validation.setFieldValue("PaidAmount", selectedCreator?.PaidAmount);
        } else {
            validation.setFieldValue("Email", ""); // Reset email if no creator is selected
        }
    };

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb
                            title="payouts"
                            titleLink="/payouts"
                            breadcrumbItem="new payout"
                        />

                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Select Contributor</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="Contributor_Name"
                                                    placeholder="Enter Contributor Name"
                                                    id="type"
                                                    type="select"
                                                    onChange={(e) => {
                                                        validation.handleChange(e);
                                                        handleCreatorChange(e);
                                                    }}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.Contributor_Name || ''}
                                                    invalid={validation?.touched?.Contributor_Name && validation?.errors?.Contributor_Name ? true : false}
                                                >
                                                    <option value="" disabled> -- Select Creator Name --  </option>
                                                    {creator?.map((val, index) => (
                                                        <option key={index} value={val?.Id}>{val?.Name}</option>
                                                    ))}
                                                </Input>
                                                {validation?.touched?.Contributor_Name && validation?.errors?.Contributor_Name ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Contributor_Name}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Email</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Email"
                                                    placeholder="Enter Email"
                                                    type="text"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.Email ?? ''}
                                                    invalid={validation?.touched?.Email && validation?.errors?.Email ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.Email && validation?.errors?.Email ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Email}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Creator's Total Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="TotalAmount"
                                                    placeholder="Enter Total Amount"
                                                    type="text"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.TotalAmount ?? ''}
                                                    invalid={validation?.touched?.TotalAmount && validation?.errors?.TotalAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.TotalAmount && validation?.errors?.TotalAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.TotalAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">total amount paid to creator </Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="PaidAmount"
                                                    placeholder="Enter Paid Amount"
                                                    type="text"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.PaidAmount ?? ''}
                                                    invalid={validation?.touched?.PaidAmount && validation?.errors?.PaidAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.PaidAmount && validation?.errors?.PaidAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.PaidAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Paid Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Payment_Amount"
                                                    placeholder="Enter Payment Amount"
                                                    type="number"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.Payment_Amount ?? ''}
                                                    invalid={validation?.touched?.Payment_Amount && validation?.errors?.Payment_Amount ? true : false}
                                                />
                                                {validation?.touched?.Payment_Amount && validation?.errors?.Payment_Amount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Payment_Amount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Transaction Id</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Transaction_Id"
                                                    placeholder="Enter Transaction Id"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.Transaction_Id ?? ''}
                                                    invalid={validation?.touched?.Transaction_Id && validation?.errors?.Transaction_Id ? true : false}
                                                />
                                                {validation?.touched?.Transaction_Id && validation?.errors?.Transaction_Id ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Transaction_Id}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Mode Of Payment</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Mode_Of_Payment"
                                                    placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.Mode_Of_Payment ?? ''}
                                                    invalid={validation?.touched?.Mode_Of_Payment && validation?.errors?.Mode_Of_Payment ? true : false}
                                                />
                                                {validation?.touched?.Mode_Of_Payment && validation?.errors?.Mode_Of_Payment ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Mode_Of_Payment}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Paid On</Label> <span className="text-danger fw-bold">*</span>

                                                <DatePicker
                                                    name="Paid_On"
                                                    selected={validation?.values?.Paid_On || new Date()} // Set the selected date to current date if not already set
                                                    onChange={date => validation.setFieldValue("Paid_On", date)} // Update Formik value
                                                    onBlur={validation.handleBlur}
                                                    dateFormat="dd/MM/yyyy" // Specify the date format
                                                    maxDate={maxSelectableDate} // Set the maximum selectable date
                                                    className={validation?.touched?.Paid_On && validation?.errors?.Paid_On ? "form-control is-invalid" : "form-control"}
                                                />
                                                {validation?.touched?.Paid_On && validation?.errors?.Paid_On ? (
                                                    <FormFeedback type='invalid'>{validation?.errors?.Paid_On}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="mt-4">
                                        <Button
                                            className="me-3"
                                            type="submit"
                                            color="primary"
                                        >
                                            {buttonLoader ? <Spinner color="light" size="sm" /> : "Add"}
                                        </Button>

                                    </div>
                                </CardBody>
                            </Card>
                        </Form>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

