import React from 'react';
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import AdminCard from './adminCard';
import AdminDashboardCount from './adminDashboardCount';
import AdminNeedApprovalList from "./adminContentNeedApprovalList";

const AdminDashboardIndex = props => {
    document.title = "Dashboard | Allen Dreyfus";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <AdminCard />
                            <AdminDashboardCount />
                            <AdminNeedApprovalList />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    )
}

AdminDashboardIndex.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(AdminDashboardIndex);
