import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Label, Input, Button, FormFeedback, Form, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import { WithdrawActions } from "store/withdrawRequest/actions";
import { userAuthActions } from "../../store/userAuth/actions"

export default function sendWithdrawRequest() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { buttonLoader } = useSelector(state => state.commonReducer);
    let [amountErr, setAmountErr] = useState('')

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Creator_Name: '',
            Email: '', // Correct field name
            TotalAmount: '',
            PaidAmount: '',
            PendingAmount: '',
            BankAccountNumber: '', // Change to string if alphanumeric
            BankName: '',
            Branch: '',
            NameAsPerBankAccount: '',
            WithdrawAmount: '',
            ContactNumber: '',
            IBAN_Number: ''
        },
        validationSchema: Yup.object({
            Creator_Name: Yup.string().required('creator name is required'),
            Email: Yup.string().email('Invalid email format').required('email is required'), // Validation for email
            TotalAmount: Yup.number().required('Total amount is required'),
            PaidAmount: Yup.number().required('Received amount is required'),
            PendingAmount: Yup.number().required('pending amount is required'),
            BankAccountNumber: Yup.string().required('Bank Account Number is required'), // Change to string if alphanumeric
            BankName: Yup.string().required('Bank Name is required'), // Change to string if alphanumeric
            Branch: Yup.string().required('Branch is required'), // Correct field name
            NameAsPerBankAccount: Yup.string().required('Name as per bank account is required'),
            ContactNumber: Yup.string().required('Contact number is required'),
            IBAN_Number: Yup.string().required('IBAN number is required'),
            WithdrawAmount: Yup.number().required('Withdraw amount is required')
        }),
        onSubmit: (data) => {
            if (parseFloat(validation?.values?.WithdrawAmount) > parseFloat(validation?.values?.PendingAmount)) {
                setAmountErr("Given amount should not be greater than pending amount")
            } else {
                setAmountErr('')
                data['Creator_Id'] = creatorTokenResponse?.result?._id;//will get id here
                data['Creator_Name'] = validation?.values?.Creator_Name;
                data['Email'] = validation?.values?.Email;
                data['PendingAmount'] = validation?.values?.PendingAmount;
                data['TotalAmount'] = validation?.values?.TotalAmount;
                data['PaidAmount'] = validation?.values?.PaidAmount;
                data['BankAccountNumber'] = validation?.values?.BankAccountNumber;
                data['BankName'] = validation?.values?.BankName;
                data['Branch'] = validation?.values?.Branch;
                data['NameAsPerBankAccount'] = validation?.values?.NameAsPerBankAccount;
                data['ContactNumber'] = validation?.values?.ContactNumber;
                data['IBAN_Number'] = validation?.values?.IBAN_Number;
                data['WithdrawAmount'] = validation?.values?.WithdrawAmount;

                dispatch({
                    type: WithdrawActions.CREATE_WITHDRAW_REQUEST,
                    payload: { data: data, navigate: navigate }
                });
            }

        }
    });

    useEffect(() => {
        dispatch({
            type: userAuthActions.VERIFY_TOKEN,
            payload: localStorage.getItem("GFX_TOKEN2"),
        })
    }, [])

    useEffect(() => {
        const creatorDetails = creatorTokenResponse;

        if (creatorDetails) {
            validation.setFieldValue("Creator_Name", creatorDetails?.result?.Name);
            validation.setFieldValue("Email", creatorDetails?.result?.Email);
            validation.setFieldValue("TotalAmount", creatorDetails?.result?.Total_Wallet_Balance);
            validation.setFieldValue("PaidAmount", creatorDetails?.result?.Paid_Amount);
            validation.setFieldValue("PendingAmount", creatorDetails?.result?.Remaining_Wallet_Balance);
            validation.setFieldValue("BankAccountNumber", creatorDetails?.result?.Bank_AC_Number);
            validation.setFieldValue("BankName", creatorDetails?.result?.Bank_Name);
            validation.setFieldValue("Branch", creatorDetails?.result?.Branch);
            validation.setFieldValue("NameAsPerBankAccount", creatorDetails?.result?.Name_As_Per_Account);
            validation.setFieldValue("ContactNumber", creatorDetails?.result?.Contact_Number);
            validation.setFieldValue("IBAN_Number", creatorDetails?.result?.IBAN_Number);
        }
    }, [creatorTokenResponse])

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Card>
                                <CardBody>
                                    <Row>
                                        <p className='pageTitle'>WITHDRAWAL REQUEST FORM</p>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Name</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="Creator_Name"
                                                    placeholder="Enter Name"
                                                    type="text"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.Creator_Name || ''}
                                                    invalid={validation?.touched?.Creator_Name && validation?.errors?.Creator_Name ? true : false}
                                                    disabled
                                                >
                                                </Input>
                                                {validation?.touched?.Creator_Name && validation?.errors?.Creator_Name ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Creator_Name}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Email</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Email"
                                                    placeholder="Enter Email"
                                                    type="text"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.Email ?? ''}
                                                    invalid={validation?.touched?.Email && validation?.errors?.Email ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.Email && validation?.errors?.Email ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Email}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Contact number</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="ContactNumber"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.ContactNumber ?? ''}
                                                    invalid={validation?.touched?.ContactNumber && validation?.errors?.ContactNumber ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.ContactNumber && validation?.errors?.ContactNumber ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.ContactNumber}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Bank account number</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="BankAccountNumber"
                                                    // placeholder="Enter Transaction Id"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.BankAccountNumber ?? ''}
                                                    invalid={validation?.touched?.BankAccountNumber && validation?.errors?.BankAccountNumber ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.BankAccountNumber && validation?.errors?.BankAccountNumber ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.BankAccountNumber}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Bank Name</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="BankName"
                                                    // placeholder="Enter Transaction Id"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.BankName ?? ''}
                                                    invalid={validation?.touched?.BankName && validation?.errors?.BankName ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.BankName && validation?.errors?.BankName ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.BankName}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Branch</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Branch"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.Branch ?? ''}
                                                    invalid={validation?.touched?.Branch && validation?.errors?.Branch ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.Branch && validation?.errors?.Branch ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Branch}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Name as per the bank account</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="NameAsPerBankAccount"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.NameAsPerBankAccount ?? ''}
                                                    invalid={validation?.touched?.NameAsPerBankAccount && validation?.errors?.NameAsPerBankAccount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.NameAsPerBankAccount && validation?.errors?.NameAsPerBankAccount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.NameAsPerBankAccount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label ibanclass">IBAN number</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="IBAN_Number"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.IBAN_Number ?? ''}
                                                    invalid={validation?.touched?.IBAN_Number && validation?.errors?.IBAN_Number ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.IBAN_Number && validation?.errors?.IBAN_Number ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.IBAN_Number}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Total Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="TotalAmount"
                                                    // placeholder="Enter Total Amount"
                                                    type="number"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.TotalAmount ?? ''}
                                                    invalid={validation?.touched?.TotalAmount && validation?.errors?.TotalAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.TotalAmount && validation?.errors?.TotalAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.TotalAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Received Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="PaidAmount"
                                                    placeholder="Enter Paid Amount"
                                                    type="number"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.PaidAmount ?? ''}
                                                    invalid={validation?.touched?.PaidAmount && validation?.errors?.PaidAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.PaidAmount && validation?.errors?.PaidAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.PaidAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Pending Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="PendingAmount"
                                                    placeholder="Enter Payment Amount"
                                                    type="number"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.PendingAmount ?? ''}
                                                    invalid={validation?.touched?.PendingAmount && validation?.errors?.PendingAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.PendingAmount && validation?.errors?.PendingAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.PendingAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Enter the amount you want to withdraw</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="WithdrawAmount"
                                                    placeholder="Enter the amount"
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.WithdrawAmount ?? ''}
                                                    invalid={validation?.touched?.WithdrawAmount && validation?.errors?.WithdrawAmount ? true : false}
                                                />
                                                {validation?.touched?.WithdrawAmount && validation?.errors?.WithdrawAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.WithdrawAmount}</FormFeedback> :
                                                    null
                                                }
                                                {amountErr &&
                                                    <small className="text-danger">{amountErr}</small>
                                                }
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mt-4">
                                        <Button
                                            className="me-3"
                                            type="submit"
                                            color="primary"
                                        >
                                            {buttonLoader ? <Spinner color="light" size="sm" /> : "Submit"}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate('/withdrawal-request')}
                                        >
                                            Close
                                        </Button>

                                    </div>

                                </CardBody>
                            </Card>
                        </Form>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

