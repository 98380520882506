
import React, { useEffect, useState } from 'react';
import {
    FormGroup, Label, Input, FormFeedback, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody,
    ModalHeader, Form, Row, Col
} from "reactstrap"
import sad_cancel from '../../../src/assets/webImages/sad_cancel.webp'

const UnsubscribeFreeTrialModal = ({ show, handleSubscripeClose, handleUnsubscribe, errorOtherMessage }) => {

    const [selectedOption, setSelectedOption] = useState('');
    const [otherOptionSelected, setOtherOptionSelected] = useState(false);

    const handleOptionChange = (event) => {
        const { value } = event.target;
        if (value == 'other') {
            setOtherOptionSelected(true);
            setSelectedOption('');
        } else {
            setSelectedOption(value);
            setOtherOptionSelected(false);
        }
    };

    const handleOtherInputChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <Modal isOpen={show} toggle={handleSubscripeClose} >
            <ModalBody className='model-free-cancel'>
                <div className='background-sad'><img className='sad-cancel' src={sad_cancel} alt="model-img" /></div>
                <Form className="padding-model">
                    <Row>
                        <Col md={12} className="text-center">
                            <div className='sad-header'>We're sad to see you go !!</div>
                            <div>
                                <FormGroup tag="fieldset">
                                    <FormGroup check>
                                        <div className='freetrial-reason'><b> Can you tell us why you’re leaving</b></div>
                                        <Label check className='freetrial-option'>
                                            <Input
                                                type="radio"
                                                name="radioOption"
                                                value="Cost"
                                                checked={selectedOption === 'Cost'}
                                                onChange={handleOptionChange}
                                            />
                                            Cost
                                        </Label>
                                    </FormGroup>

                                    <FormGroup check>
                                        <Label check className='freetrial-option'>
                                            <Input
                                                type="radio"
                                                name="radioOption"
                                                value="Content"
                                                checked={selectedOption === 'Content'}
                                                onChange={handleOptionChange}
                                            />
                                            Content
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check >
                                        <Label check className='freetrial-option'>
                                            <Input
                                                type="radio"
                                                name="radioOption"
                                                value='other'
                                                checked={otherOptionSelected === true}
                                                onChange={handleOptionChange}
                                            />
                                            Other Reason
                                        </Label>
                                        {otherOptionSelected && (
                                            <Input
                                                type="text"
                                                placeholder="Enter other option"
                                                value={selectedOption}
                                                onChange={handleOtherInputChange}
                                            />
                                        )}
                                    </FormGroup>
                                    {errorOtherMessage && (
                                        <small style={{ color: "red", textAlign: "left" }}>{errorOtherMessage}</small>
                                    )}
                                </FormGroup>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-primary save-customer me-2"
                                    onClick={handleSubscripeClose}

                                >
                                    Keep me Subscribed
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary save-customer me-2 cancel"
                                    onClick={() => handleUnsubscribe(selectedOption)}
                                >
                                    Unsubscribe
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}
export default UnsubscribeFreeTrialModal
