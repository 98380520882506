import React, { useState, useRef, useEffect } from "react"
import ProfileImage from "../../assets/webImages/profilepage-avatar.webp"
import { Container, Row, Col, Button, Form } from "react-bootstrap"
import Encrypted from "../../assets/webImages/encrypted.webp"
import Multiselect from "multiselect-react-dropdown"
import Editimage from "../../assets/webImages/edit2.webp"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { WebHomePageActions } from "store/webHome/actions"
import { useForm } from "react-hook-form"
import "react-toastify/dist/ReactToastify.css"
import { Label, Input, Spinner } from "reactstrap"
import adminSubActions from "../../store/adminSubscriptionPlans/actions"
import UnsubscribeModal from "webComponents/modals/unsubscribeConfrimationModal"
import UnsubscribeFreeTrialModal from "webComponents/modals/unsubscribeFreeTrialConfrimationModal"
import FileSizeValidation from "../../FileSizeValidation/index"
import { commonActions } from "../../store/common/actions"
import NoDataFound from "../Common/NoRecords"
import WebPageLoader from "../../common/webPageLoader"
import Skyblueimg from "../../assets/webImages/right-top (2) (1).webp"
import Subscribenow from "../../assets/webImages/subscribenow.svg"
import Subscriptiontext from "../../assets/webImages/real-time1.webp"
import { actions } from "../../store/Countries/actions";

import Subscriptionimage from "../ProfileSubscribeBanner/index"

const ProfilePageIndex = () => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchSuccess, setFetchSuccess] = useState(false)
  const [loader, setLoader] = useState(false)

  const [profileImage, setProfileImage] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [countryError, setCountryError] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const fileInputRef = useRef(null)
  const [subscribeID, setSubscribeID] = useState("")
  const [showUnsubscriptionPopup, setShowUnsubscriptionPopup] = useState(false)
  const [
    showFreeTrialUnsubscriptionPopup,
    setShowFreeTrialUnsubscriptionPopup,
  ] = useState(false)

  const { buttonLoader } = useSelector(state => state.commonReducer)

  const { tokenResponse } = useSelector(state => state.userAuthReducer)

  const { ProfileUserInfo } = useSelector(state => state.WebHomePageReducer)

  const { EditUserInformation } = useSelector(state => state.WebHomePageReducer)
  const { subscriptionResponse } = useSelector(
    state => state.subscriptionPlanReducer
  )

  const [country, setCountry] = useState("")
  let userId = tokenResponse?.result?._id
  const isLoggedIn = !!tokenResponse?.result
  const isSubscribed = tokenResponse?.result?.IsSubscribed === "Yes"
  const isTrialUsed = tokenResponse?.result?.Stripe_Free_Trial_Used === "Yes"
  const [selectedImage, setSelectedImage] = useState(
    ProfileUserInfo?.result?.Profile_Image || ProfileImage
  )
  const [errorMessage, setErrorMessage] = useState("")
  const [errorOtherMessage, setErrorOtherMessage] = useState("")

  useEffect(() => {
    if (ProfileUserInfo?.result?.Preferences) {
      setCountryOptions(
        ProfileUserInfo.result?.Preferences.map(name => ({ id: name, name }))
      )
    }

    setSelectedImage(ProfileUserInfo?.result?.Profile_Image || ProfileImage)
  }, [ProfileUserInfo])

  const { countryList } = useSelector(state => state.CountryReducer)

  useEffect(() => {
    dispatch({ type: actions.GET_COUNTRY_LIST });
  }, [])

  const initialCountryOptions =
    ProfileUserInfo?.result?.Preferences?.map(name => ({
      id: name,
      name,
    })) || []

  const [countryOptions, setCountryOptions] = useState([])

  // useEffect(() => {
  //   
  // })

  const handleEditClick = () => {
    setIsEditing(true)
  }

  // Country
  const onSelectCounty = selectedCountry => {
    setCountryOptions(selectedCountry)
    setCountryError("")
  }

  const onRemoveCountry = selectedCountry => {
    setCountryOptions(selectedCountry)
  }

  // Country List
  let countryArray = countryList?.result?.map(val => ({
    id: val?.Country_Name,
    name: val?.Country_Name,
  }))

  useEffect(() => {
    setLoader(true)
    const payload = { userId }

    dispatch({
      type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_DETAILS,
      payload,
    })
  }, [dispatch, userId])

  useEffect(() => {
    if (ProfileUserInfo?.result?.length === 0) {
      setLoader(false)
      setFetchSuccess(false)
    } else {
      setLoader(false)
      setFetchSuccess(true)
    }
  }, [ProfileUserInfo])

  useEffect(() => {
    setValue("First_Name", ProfileUserInfo?.result?.First_Name || "")
    setValue("Last_Name", ProfileUserInfo?.result?.Last_Name || "")
    setValue("Email", ProfileUserInfo?.result?.Email || "")
    setValue("Contact_Number", ProfileUserInfo?.result?.Contact_Number || "")
    setValue("Address", ProfileUserInfo?.result?.Address || "")
    setValue("Country", ProfileUserInfo?.result?.Preferences || "")
    setCountry(ProfileUserInfo?.result?.Preferences || "")
  }, [ProfileUserInfo])

  const handleFileSelect = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      let result = FileSizeValidation(event.target.files)
      if (result.Validation === "false") {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true })
        fileInputRef.current.value = null
      } else {
        if (
          result?.FinalImages[0]?.type === "image/jpeg" ||
          result?.FinalImages[0]?.type === "image/jpg" ||
          result?.FinalImages[0]?.type === "image/png"
        ) {
          const imageUrl = URL.createObjectURL(selectedFile)
          setSelectedImage(imageUrl)
          setSelectedFile(selectedFile)
        } else {
          dispatch({
            type: commonActions.SET_ALERT,
            payload: {
              show: true,
              status: "failed",
              message: "Unsupported File Format",
            },
          })
          fileInputRef.current.value = null
        }
      }
    }
  }

  const handleSaveChangesClick = () => {
    setLoading(true)

    // const originalUserInfo = ProfileUserInfo?.result

    if (countryOptions && countryOptions?.length === 0) {
      setCountryError("select the preferences")
    } else {
      let countrySet = new Set()
      countryOptions.forEach(val => {
        countrySet.add(val.name)
      })
      const Preferences = JSON.stringify([...countrySet])
      if (
        !getValues("First_Name") ||
        !getValues("Last_Name") ||
        !getValues("Contact_Number") ||
        // !getValues("Address") ||
        (isEditing && (!countryOptions || countryOptions.length === 0))
      ) {
        setErrorMessage("all fields are mandatory")
        return
      } else {
        setCountry(JSON.parse(Preferences))
        setErrorMessage("")
        const formData = new FormData()
        formData.append("First_Name", getValues("First_Name"))
        formData.append("Last_Name", getValues("Last_Name"))
        formData.append("Email", getValues("Email"))
        formData.append("Contact_Number", getValues("Contact_Number"))
        formData.append("Address", getValues("Address"))
        formData.append("Preferences", Preferences)

        if (selectedFile) {
          formData.append("Profile_Image", selectedFile)
        }

        // for (const pair of formData.entries()) {
        //   console.log(pair[0], pair[1])
        // }

        dispatch({
          type: WebHomePageActions.GET_WEBHOME_PROFILE_USERINFO_UPDATED_DETAILS,
          payload: {
            userId: userId,
            updatedData: formData,
          },
        })
      }
      setLoading(false)
      setIsEditing(false)
    }
  }

  const onSubmit = () => {
    handleSaveChangesClick()
  }

  useEffect(() => {
    if (tokenResponse?.statusCode === 200) {
      setSubscribeID(tokenResponse?.result?.Stripe_Subscription_Id)
    }
  }, [tokenResponse])

  const handleUnsubscribe = (cancelReason) => {
    let subscriptionId = subscribeID;
    if (cancelReason === '') {
      setErrorOtherMessage('Please Enter the reason for unsubscribe')
    } else if (cancelReason === "normalCancel") {
      dispatch({
        type: adminSubActions.CANCEL_SUBSCRIPTION_PLAN,
        payload: {
          subscriptionId: subscriptionId,
          navigate: navigate,
        },
      })
      setShowUnsubscriptionPopup(false)
    } else if (cancelReason) {
      setErrorOtherMessage("")
      dispatch({
        type: adminSubActions.CANCEL_SUBSCRIPTION_PLAN,
        payload: {
          subscriptionId: subscriptionId,
          Free_Trial_Cancellation_Reason: cancelReason,
          navigate: navigate,
        },
      })
      setShowFreeTrialUnsubscriptionPopup(false)
    }
  }

  const handleUnsubscribeClick = () => {
    if (
      ProfileUserInfo?.result?.Stripe_Free_Trial_Used === "Yes" &&
      ProfileUserInfo?.result?.Stripe_Product_Duration === "day"
    ) {
      setShowFreeTrialUnsubscriptionPopup(true)
    } else {
      setShowUnsubscriptionPopup(true)
    }
  }

  const handleSubscripeClose = () => {
    if (
      ProfileUserInfo?.result?.Stripe_Free_Trial_Used === "Yes" &&
      ProfileUserInfo?.result?.Stripe_Product_Duration === "day"
    ) {
      setShowFreeTrialUnsubscriptionPopup(false)
    } else {
      setShowUnsubscriptionPopup(false)
    }
  }
  return (
    <>
      {loader ? (
        <WebPageLoader />
      ) : (
        <>
          {fetchSuccess ? (
            <div className="profilepage-web ">
              <Container  >
                <Row className="pb-5">
                  <Col xs={12} md={6} lg={4} className="bg-clr-for-profile-left-side">
                    {/* <Row className="profile-leftside-firstrow"> */}
                    <Row >
                      {/* Profile Image section */}
                      {/* <div
                        className="profile-img-align"
                        onClick={handleFileSelect}
                      >
                        <img src={Editimage} className="editimage-profile" />
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt=""
                            className="profilepage-profile-img rounded-circle "
                          />
                        ) : (
                          <img
                            src={ProfileImage}
                            alt=""
                            className="profilepage-profile-img rounded-circle "
                          />
                        )}
                      </div>

                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/jpg,image/jpeg,image/png"
                      />
                      <h6 className="pro-user-name">
                        {ProfileUserInfo?.result?.First_Name}
                      </h6>
                      <p className="web_fonts pro-user-emailname">
                        {ProfileUserInfo?.result?.Email}
                      </p> */}
                      {/* Profile Image section */}

                      <div className="skyblue-profile-banner">
                        <img src={Skyblueimg} alt="banner" />
                      </div>
                    </Row>
                    {isSubscribed || (isSubscribed && isTrialUsed) ?
                      <Row className="profile-leftside-secondrow">
                        <div className="profile-subscription-details web_fonts">
                          <h2 className="color-black">Subscription Summary</h2>
                          <h4>
                            subscribed on <hr className="hr-line" />
                          </h4>
                          <h6 className="color-black">
                            {
                              ProfileUserInfo?.result
                                ?.Stripe_Subscription_Start_Date
                            }
                          </h6>
                          <h4>
                            subscription Plan <hr className="hr-line" />
                          </h4>
                          <h6 className="color-black">
                            {
                              (ProfileUserInfo?.result?.Stripe_Product_Duration === "day" && ProfileUserInfo?.result?.Stripe_Product_Price !== 0) ||
                                ProfileUserInfo?.result?.Stripe_Product_Duration === "month"
                                ? "monthly"
                                : ProfileUserInfo?.result?.Stripe_Product_Duration === "year"
                                  ? "yearly"
                                  : "free-trial"
                            }
                          </h6>
                          <h4>
                            validitity <hr className="hr-line" />
                          </h4>
                          <h6 className="color-black">
                            {
                              ProfileUserInfo?.result
                                ?.Stripe_Subscription_End_Date
                            }
                          </h6>
                          {tokenResponse?.statusCode === 200 &&
                            tokenResponse?.result?.IsSubscribed === "Yes" ? (
                            <Button
                              className="btn-primary unsubcribe-button web_fonts"
                              onClick={handleUnsubscribeClick}
                            >
                              Unsubscribe
                            </Button>
                          ) : null}
                        </div>
                      </Row>
                      :
                      <Row >
                        <div className="sub-now">
                          <Subscriptionimage
                            Subscribenow={Subscribenow}
                            Subscriptiontext={Subscriptiontext}
                          />
                        </div>
                      </Row>
                    }
                  </Col>
                  <Col xs={12} md={6} lg={8} className="profilepage-rightside">
                    <Row>
                      {/* <Col xs={3} sm={6} md={3} lg={2}>
                        <div className="encryptedicon-div">
                          <img
                            src={Encrypted}
                            alt="encrypted icon"
                            className="Encrypted"
                          />
                        </div>
                      </Col> */}
                      <Col xs={12} sm={6} md={12} lg={10}>
                        <h1 className="personal-info-pro">
                          Personal Information
                        </h1>
                        {/* <p
                          className="web_fonts
                 info-tex"
                        >
                          Enhance your profile with a touch of personalization!
                          Simply click the 'Edit' button to upload a profile
                          image that resonates with you. Feel free to update
                          your details anytime to keep your profile fresh and
                          unique.
                        </p> */}
                      </Col>
                    </Row>
                    <Row className="form-group-row">
                      <Col md={6}>
                        <Form.Group
                          controlId="formFirstName"
                          className="form-group"
                        >
                          <Form.Label className="form-label web_fonts">
                            first name
                          </Form.Label>
                          <span className="text-danger"> *</span>
                          <Form.Control
                            className={`profile-form-control ${errors.First_Name ? "is-invalid" : ""
                              }`}
                            type="text"
                            placeholder=""
                            {...register("First_Name", {
                              required: "First name is required",
                              pattern: {
                                value: /^[A-Za-z]+$/,
                                message: "Invalid first name",
                              },
                            })}
                            readOnly={!isEditing}
                          />
                          {errors.First_Name && (
                            <div className="invalid-feedback">
                              {errors.First_Name.message}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group
                          controlId="formLastName"
                          className="form-group"
                        >
                          <Form.Label className="form-label web_fonts">
                            last name
                          </Form.Label>
                          <span className="text-danger"> *</span>
                          <Form.Control
                            className={`profile-form-control ${errors.Last_Name ? "is-invalid" : ""
                              }`}
                            type="text"
                            placeholder=""
                            {...register("Last_Name", {
                              required: "Last name is required",
                              pattern: {
                                value: /^[A-Za-z]+$/,
                                message: "Invalid last name",
                              },
                            })}
                            readOnly={!isEditing}
                          />
                          {errors.Last_Name && (
                            <div className="invalid-feedback">
                              {errors.Last_Name.message}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="form-group-row">
                      <Col md={6}>
                        <Form.Group
                          controlId="formEmail"
                          className="form-group"
                        >
                          <Form.Label className="form-label web_fonts">
                            email address
                          </Form.Label>
                          <span className="text-danger"> *</span>
                          <Form.Control
                            className="profile-form-control"
                            type="email"
                            placeholder=""
                            {...register("Email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                            })}
                            readOnly
                          />
                          <p className="error-message web_fonts">
                            {errors.Email?.message}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group
                          controlId="formPhoneNumber"
                          className="form-group"
                        >
                          <Form.Label className="form-label web_fonts">
                            contact number
                          </Form.Label>
                          <span className="text-danger"> *</span>
                          <Form.Control
                            className={`profile-form-control ${errors.Contact_Number ? "is-invalid" : ""
                              }`}
                            type="tel"
                            placeholder=""
                            {...register("Contact_Number", {
                              required: "Contact number is required",
                              // pattern: {
                              //   value: /^\d{10}$/,
                              //   message: "Contact number must be 10 digits",
                              // },
                            })}
                            readOnly={!isEditing}
                          />
                          {errors.Contact_Number && (
                            <div className="invalid-feedback">
                              {errors.Contact_Number.message}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="form-group-row">
                      {/* <Col md={6}>
                        <Form.Group
                          controlId="formAddress"
                          className="form-group"
                        >
                          <Form.Label className="form-label web_fonts">
                            Address
                          </Form.Label>
                          <span className="text-danger"> *</span>
                          <Form.Control
                            className={`profile-form-control ${errors.Address ? "is-invalid" : ""
                              }`}
                            type="text"
                            placeholder="Enter your address"
                            {...register("Address", {
                              required: "Address is required",
                            })}
                            readOnly={!isEditing}
                          />
                          {errors.Address && (
                            <div className="invalid-feedback">
                              {errors.Address.message}
                            </div>
                          )}
                        </Form.Group>
                      </Col> */}

                      <Col md="12" className="mb-4">
                        <div className="form-group">
                          <Label htmlFor="Title" className="form-label">
                            Preferences
                          </Label>
                          <span className="text-danger fw-bold">*</span>
                          {isEditing ? (
                            <>
                              <Multiselect
                                options={countryArray}
                                style={{ dropdownContainer: { maxHeight: '10px' } }}
                                displayValue="name"
                                onSelect={onSelectCounty}
                                onRemove={onRemoveCountry}
                                selectedValues={countryOptions}
                                {...register("Country", {
                                  validate: {
                                    atLeastOnePreference: value =>
                                      (value && value.length > 0) ||
                                      "Select at least one preference",
                                  },
                                })}
                                disabled={!isEditing} // Invert the disabled state for editing mode
                              />
                              <div>
                                <div><span className="market-select">Selected Values:</span> {countryOptions?.map((option) => option.name).join(', ')}</div>
                              </div>
                              {countryError && <div style={{ color: "red" }}>{countryError}</div>}
                            </>
                          ) : (
                            <>
                              <Input
                                name="Country"
                                className="form-control"
                                placeholder="Enter Type of Content"
                                type="text"
                                defaultValue={country}
                                disabled
                              />
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <div className="savechanges-btn">
                      {loading ? (
                        <Button variant="primary" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </Button>
                      ) : isEditing ? (
                        <Button
                          className="btn-primary save-button web_fonts"
                          onClick={handleSubmit(onSubmit)}
                          disabled={Object.keys(errors).length > 0}
                        >
                          {buttonLoader ? <Spinner color="light" size="sm" /> : "Save"}
                        </Button>
                      ) : (
                        <Button
                          className="btn-primary edit-button web_fonts"
                          onClick={handleEditClick}
                        >
                          Update profile
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>

              <UnsubscribeModal
                show={showUnsubscriptionPopup}
                handleSubscripeClose={handleSubscripeClose}
                handleUnsubscribe={handleUnsubscribe}
              />

              <UnsubscribeFreeTrialModal
                show={showFreeTrialUnsubscriptionPopup}
                handleSubscripeClose={handleSubscripeClose}
                handleUnsubscribe={handleUnsubscribe}
                errorOtherMessage={errorOtherMessage}
              />
            </div >
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  )
}

export default ProfilePageIndex
