import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Card, CardBody, Col, Row, Container, Label, Input, FormFeedback, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BlogContentActions } from "../../store/blogContent/actions";
import { commonActions } from "../../store/common/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import ApproveOrRejectContentModal from "components/commonModals/ApproveOrRejectContentModal";
import Multiselect from 'multiselect-react-dropdown';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import success_img from "../../assets/images/dashboard/success1-popup-image.gif";
import FileSizeValidation from '../../FileSizeValidation/index'
import RichTextEditor from '../../common/QuillRichTextEditor';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import { actions } from "store/Countries/actions";
import Cropper from 'react-easy-crop';

export default function creatorEditContentDetail() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileInputRef = React.createRef();
    const imageRef = useRef();
    const audioref = useRef();
    const maxSelectableDate = new Date();

    const { superAdminTokenResponse, adminTokenResponse } = useSelector(state => state.userAuthReducer);
    const { adminContentDetail } = useSelector(state => state.BlogContentReducer);
    const { content_Creator_Details } = useSelector(state => state.ContentCreatorReducer);

    var [price, setPrice] = useState(50);
    const [podcastFile, setPodcastFile] = useState("")
    const [audioKey, setAudioKey] = useState(0);
    const [podcastError, setPodcastError] = useState("")
    var [podcast, setPodcast] = useState("")

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [newThumbimage, setNewThumbimage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [showCropper, setShowCropper] = useState(true); // State to control Cropper visibility

    const [showCreationPopup, setCreationPopup] = useState({ isOpen: false, statusCode: null });

    var [typeOfContent, setTypeOfContent] = useState('');
    var [title, setTitle] = useState('');
    var [caption, setCaption] = useState('');
    var [country, setCountry] = useState('');
    var [category, setCategory] = useState([]);
    var [subCategory, setSubCategory] = useState('');
    var [pitchStory, setPitchStory] = useState('');
    var [impacts, setImpacts] = useState('');
    var [articleImage, setArticleImage] = useState([]);
    var [thumnailImage, setThumnailImage] = useState('');
    var [RejectionReason, setRejectionReason] = useState('');
    var [rejectError, setRejectError] = useState('');
    const [approveReason, setApproveReason] = useState('');
    const [modelApproveReason, setModelApproveReason] = useState('');
    const [rejectReason, setRejectReason] = useState('');
    var [articleDate, setArticleDate] = useState('');
    var [dateError, setDateError] = useState('');
    //var [podcastSubmittedBy, setPodcastSubmittedBy] = useState('');

    const [checkStatus, setCheckStatus] = useState(false)
    const [modalType, setModalType] = useState('')
    const [StatusCheckFromModal, setStatusCheckFromModal] = useState(false)
    const [pageFormData, setPageFormData] = useState(null);

    const [countryOptions, setCountryOptions] = useState([]);
    const [countryError, setCountryError] = useState("");

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [marketError, setMarketError] = useState("");

    const [selectedOptions1, setSelectedOptions1] = useState([]);
    const [recentTopicError, setRecentTopicError] = useState("");

    const [thumbFile, setThumbFile] = useState("");
    const [thumbError, setThumbError] = useState("");

    const [duration, setDuration] = useState("");
    const [durationError, setDurationError] = useState("")

    const [creatorId, setCreatorId] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [blogId, setBlogId] = useState("");

    const [selectedImage, setSelectedImage] = useState([]);
    const [imageFile, setImageFile] = useState("");
    const [fileError, setFileError] = useState("");
    const [storyContent, setStoryContent] = useState('');

    const { creatorPitchDetail } = useSelector(state => state.BlogContentReducer);
    const { buttonLoader } = useSelector(state => state.commonReducer);
    const { blogContentResponse } = useSelector(state => state.BlogContentReducer);

    const { recentTopicList } = useSelector(state => state.BlogContentReducer);
    const { countryList } = useSelector(state => state.CountryReducer);
    const { showPopup, adminPageLoader } = useSelector(state => state.commonReducer);

    const Id = state?.data?._id;

    useEffect(() => {
        dispatch({ type: BlogContentActions.GET_CREATOR_PITCH_DETAIL, payload: Id });
    }, [Id]);

    useEffect(() => {
        dispatch({ type: BlogContentActions.GET_RECENT_TOPIC_LIST });
        dispatch({ type: actions.GET_COUNTRY_LIST });
    }, [])

    useEffect(() => {
        if (state?.type === "Approved") {
            setCreationPopup({ isOpen: false, statusCode: null })
        }
    }, [state?.type]);

    useEffect(() => {
        if (blogContentResponse && blogContentResponse?.statusCode === 200) {
            setModelApproveReason("");
            setCreationPopup({ isOpen: true, statusCode: 200 });
            validation.resetForm();
            dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id })
        } else if (blogContentResponse && blogContentResponse?.statusCode === 400) {
            setCreationPopup({ isOpen: false, statusCode: 400 });
        } else {
            setCreationPopup({ isOpen: false, statusCode: null });
        }
    }, [blogContentResponse])

    //for story
    function removeHtmlTags(input) {
        const regex = /<\/?[\w\s="/.':;#-\/\?]+>/gi;
        return input.replace(regex, '');
    }

    const storyContent1 = creatorPitchDetail?.result?.Story || '';
    const textWithoutHtml = removeHtmlTags(storyContent1);
    const initialContentState = ContentState.createFromText(textWithoutHtml);

    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(initialContentState || '')
    );
    const [briefConvertedContent, setBriefConvertedContent] = useState(null);
    const [descError, setDescError] = useState("");
    const [captionError, setCaptionError] = useState("");
    const [recentTopic, setRecentTopic] = useState([]);

    const initialName = creatorPitchDetail?.result?.Podcast_Submitted_By?.Name || '';
    const initialTypeOfUser = creatorPitchDetail?.result?.Podcast_Submitted_By?.Type_Of_User || '';

    const statusUpdaterName = creatorPitchDetail?.result?.Content_Status_Updated_By?.Name || '';
    const statusUpdaterTypeOfUser = creatorPitchDetail?.result?.Content_Status_Updated_By?.Type_Of_User || '';

    const contentLastUpdatedName = creatorPitchDetail?.result?.Content_Updated_By?.Name || '';
    const contentLastUpdatedTypeOfUser = creatorPitchDetail?.result?.Content_Updated_By?.Type_Of_User || '';

    //form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            Title: adminContentDetail?.Title,
            TypeOfContent: adminContentDetail?.TypeOfContent,
            Country: adminContentDetail?.Country || [],
            Category: adminContentDetail?.Category[0]?.toString() || [],
            Sub_Category: adminContentDetail?.Sub_Category || [],
            Impacts: adminContentDetail?.Impacts,
            Article_Images: adminContentDetail?.Article_Images || [],
            Caption: adminContentDetail?.Caption || '',
            Thumbnail_Image: adminContentDetail?.Thumbnail_Image,
            Duration: adminContentDetail?.Duration,
            Created_By: adminContentDetail?.Created_By,
            Blog_Id: adminContentDetail?._id,
            Content_Creator_Id: adminContentDetail?.Content_Creator_Id,
            Pitch_story: adminContentDetail?.Pitch_story || [],
            Old_Article_Images: [],
            Podcast: adminContentDetail?.Podcast[0]?.toString() || [],
            Date_Approved: adminContentDetail?.Approved_On,
            Podcast_Submitted_By: `${initialName} - ${initialTypeOfUser}`,
            Content_Status_Updated_By: `${statusUpdaterName} - ${statusUpdaterTypeOfUser}`,
            Content_Last_Updated_By: `${contentLastUpdatedName} - ${contentLastUpdatedTypeOfUser}`,
        },
        validationSchema: Yup.object({
            Title: Yup.string().required("enter the title"),
            TypeOfContent: Yup.string().required("select the content type"),
            Category: Yup.string().required("select blog category"),
            Impacts: Yup.string().required("impacts field is required"),
        }),
        onSubmit: (data) => {
            if (countryOptions && countryOptions?.length === 0) {
                setCountryError("select the market focus")
            } else if ((validation?.values?.TypeOfContent === "Article") && storyContent === '') {
                setDescError("story is required")
            } else if (validation?.values?.Category === 'Frontier Markets' && selectedOptions?.length === 0) {
                setMarketError("frontier markets is required")
            } else if (validation?.values?.Category === 'Recent Topic' && selectedOptions1?.length === 0) {
                setRecentTopicError("recent topic is required")
            } else if ((validation?.values?.TypeOfContent === "Article") && (selectedImage?.length === 0 || !selectedImage)) {
                setFileError("article image is required");
            } else if ((validation?.values?.TypeOfContent === "Podcast") && !podcastFile) {
                setPodcastError("podcast file is required")
            } else if ((validation?.values?.TypeOfContent === "Podcast" || validation?.values?.TypeOfContent === "Video") && !thumnailImage) {
                setThumbError("thumb-nail image is required");
                // } else if ((validation?.values?.TypeOfContent === "Podcast" || validation?.values?.TypeOfContent === "Video") && !duration) {
                //     setDurationError("duration of the uploaded file is required");
            } else if ((validation?.values?.TypeOfContent === "Article") && caption === '') {
                setCaptionError('caption for article image is required')
            } else {
                setDescError('')
                setCaptionError('')
                const formData = new FormData();

                if (validation?.values?.TypeOfContent === "Podcast") {
                    let Podcast_Updated_By = {};
                    if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                        Podcast_Updated_By = {
                            Name: superAdminTokenResponse?.result?.Name,
                            Email: superAdminTokenResponse?.result?.Email,
                            Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                        }
                    } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                        Podcast_Updated_By = {
                            Name: adminTokenResponse?.result?.Name,
                            Email: adminTokenResponse?.result?.Email,
                            Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                        }
                    }
                    formData.append("Podcast_Updated_By", JSON.stringify(Podcast_Updated_By))
                }
                if (validation?.values?.TypeOfContent === "Article") {
                    let Content_Updated_By = {};
                    if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                        Content_Updated_By = {
                            Name: superAdminTokenResponse?.result?.Name,
                            Email: superAdminTokenResponse?.result?.Email,
                            Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                        }
                    } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                        Content_Updated_By = {
                            Name: adminTokenResponse?.result?.Name,
                            Email: adminTokenResponse?.result?.Email,
                            Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                        }
                    }
                    formData.append("Content_Updated_By", JSON.stringify(Content_Updated_By))
                }
                formData.append('TypeOfContent', data.TypeOfContent);
                formData.append('Title', data.Title);
                // formData.append('Story', briefConvertedContent);
                formData.append('Story', storyContent);
                // for country                
                let countrySet = new Set();
                countryOptions.forEach(val => {
                    countrySet.add(val.name);
                });
                const country_List = JSON.stringify([...countrySet]);
                formData.append('Country', country_List);
                formData.append('Category', category);

                if (validation?.values?.Category === 'Frontier Markets' || validation?.values?.Category === 'Recent Topic') {
                    if (validation?.values?.Category === 'Frontier Markets') {
                        let marketSet = new Set();
                        selectedOptions.forEach(val => {
                            marketSet.add(val.name);
                        });
                        const market_List = JSON.stringify([...marketSet]);
                        formData.append('Sub_Category', market_List);
                    } else if (validation?.values?.Category === 'Recent Topic') {
                        let recentSet = new Set();
                        selectedOptions1.forEach(val => {
                            recentSet.add(val.name);
                        });
                        const recent_List = JSON.stringify([...recentSet]);
                        formData.append('Sub_Category', recent_List);
                    }
                } else {
                    // For other categories, append an empty array
                    formData.append('Sub_Category', JSON.stringify([]));
                }

                if (validation?.values?.TypeOfContent === 'Article') {
                    if (selectedImage?.length > 0) {
                        for (let i = 0; i < selectedImage?.length; i++) {
                            if (selectedImage[i].type === 'new') {
                                formData.append('Article_Images', selectedImage[i].file);
                            }
                        };
                        //for old image
                        let oldArray = [];
                        oldArray = selectedImage?.filter((val) => val?.type === 'old').map((val) => {
                            return val?.Article_Images;
                        });
                        const oldArrayString = JSON.stringify(oldArray);
                        formData.append("Old_Article_Images", oldArrayString);

                    }
                    formData.append('Caption', data.Caption);
                }

                if (validation?.values?.TypeOfContent === 'Podcast' || validation?.values?.TypeOfContent === 'Video') {
                    if (podcast) {
                        formData.append("Podcast", podcast)
                    } else {
                        let oldPodcast = []
                        oldPodcast.push(podcastFile)
                        formData.append("Old_Podcast_File", JSON.stringify(oldPodcast))
                    }
                    // if (thumbFile) {
                    //     formData.append('Thumbnail_Image', thumbFile)
                    // } else {
                    //     // let oldImage = [];
                    //     // oldImage.push(thumnailImage);
                    //     formData.append("Old_Thumbnail_Image", thumnailImage)
                    // }
                    if (croppedImage) {
                        formData.append('Thumbnail_Image', croppedImage)
                    } else {
                        // let oldImage = [];
                        // oldImage.push(thumnailImage);
                        formData.append("Old_Thumbnail_Image", thumnailImage)
                    }
                    formData.append('Duration', duration)
                }

                if (!articleDate) {
                    setDateError("please select the date");
                    return; // Prevent form submission
                }

                formData.append("Impacts", data.Impacts)
                formData.append('Created_By', createdBy)
                formData.append("Content_Creator_Id", creatorId)
                formData.append("Blog_Id", blogId)
                formData.append("Status", "Approved")
                formData.append("Date_Approved", articleDate)

                // for (var pair of formData.entries()) { //to console formdata
                //     console.log(pair[0] + ', ' + pair[1]);
                // }

                if (adminContentDetail?.Status === 'New' || adminContentDetail?.Status === 'Updated') {
                    handleShowModel('Approve', formData);
                } else {
                    // dispatch (for submit button in /edit-content-details page)
                    dispatch({ type: BlogContentActions.CREATE_BLOG_CONTENT, payload: { data: formData } })
                }
            }
        }
    })

    useEffect(() => {
        if ((creatorPitchDetail?.statusCode === 200 && creatorPitchDetail?.result?.Status === "Updated") || (creatorPitchDetail?.statusCode === 200 && creatorPitchDetail?.result?.Status === "Approved")) {
            const dateString = creatorPitchDetail?.result?.Approved_On;
            const localDateTime = moment.tz(dateString, 'DD-MM-YYYY hh:mm A', 'Africa/Accra').local();
            // Format the local date time as needed
            const formattedDateTime = localDateTime.format('DD-MM-YYYY hh:mm A');
            const articleDate = moment(formattedDateTime, 'DD/MM/YYYY h:mm aa').toDate();
            setArticleDate(articleDate);
        }
    }, [creatorPitchDetail?.statusCode === 200])

    useEffect(() => {
        setTypeOfContent(validation.values.TypeOfContent);
        setTitle(validation.values.Title);
        setCountry(validation.values.Country);
        setCategory(validation.values.Category);
        setSubCategory(validation.values.Sub_Category);
        setImpacts(validation.values.Impacts);
        setBriefConvertedContent(textWithoutHtml);
        setEditorState(EditorState.createWithContent(initialContentState || ''));
        setPodcastFile(validation.values.Podcast)
        setArticleImage(validation.values.Article_Images);
        setThumnailImage(validation.values.Thumbnail_Image);
        setDuration(validation.values.Duration);
        setRejectionReason(creatorPitchDetail?.result?.Pitch_Rejection_Reason);
        setApproveReason(creatorPitchDetail?.result?.RemarksForContent);
        setBlogId(validation.values.Blog_Id);
        setCreatedBy(validation.values.Created_By);
        setCreatorId(validation.values.Content_Creator_Id);
        setPitchStory(validation.values.Pitch_story);
    }, [validation.values])

    useEffect(() => {
        if (creatorPitchDetail?.result?.Country) {
            setCountryOptions(creatorPitchDetail?.result?.Country.map(name => ({ id: name, name })));
        }
        if (creatorPitchDetail?.result?.Category[0]?.toString() === "Frontier Markets") {
            setSelectedOptions(creatorPitchDetail?.result?.Sub_Category.map(name => ({ id: name, name })));
        }
        if (creatorPitchDetail?.result?.Category[0]?.toString() === "Recent Topic") {
            setSelectedOptions1(creatorPitchDetail?.result?.Sub_Category.map(name => ({ id: name, name })));
        }
        if (creatorPitchDetail?.result?.Story) {
            setStoryContent(creatorPitchDetail?.result?.Story)
        }
    }, [creatorPitchDetail]);

    useEffect(() => {
        // Update key whenever podcastFile changes to force re-render of audio element
        setAudioKey((prevKey) => prevKey + 1);
    }, [podcastFile]);

    useEffect(() => {
        if (validation.values.Category !== "Frontier Markets" || validation.values.Category !== "Recent Topic") {
            setSubCategory("");
        }
    }, [validation])


    // modal close
    const handleClose = () => {
        setCreationPopup({ isOpen: false, statusCode: null });
        if (state?.data?.TypeOfContent === "Article") {
            window.location.href = '/content/Approved';
        } else if (state?.data?.TypeOfContent === "Podcast") {
            window.location.href = '/admin-podcast';
        } else {
        }
    }

    // Country List
    let countryArray = countryList?.result?.map((val) => ({
        id: val?.Country_Name,
        name: val?.Country_Name,
    }));

    // Recent topic
    // let recentTopic = recentTopicList?.result?.map((val) => ({
    //     id: val?.Topic,
    //     name: val?.Topic,
    // }));

    useEffect(() => {
        if (recentTopicList && recentTopicList.result && recentTopicList?.result?.length > 0) {
            const firstTopic = {
                id: recentTopicList?.result[0]?.Topic,
                name: recentTopicList?.result[0]?.Topic,
            };
            setRecentTopic([firstTopic]);
        }
    }, [recentTopicList?.result]);

    // Country
    const onSelectCounty = (selectedCountry) => {
        setCountryOptions(selectedCountry);
        setCountryError("");
    };

    const onRemoveCountry = (selectedCountry) => {
        setCountryOptions(selectedCountry);
    };

    // frontier markets
    const onSelect = (selectedList) => {
        setSelectedOptions(selectedList);
        setMarketError("");
    };

    const onRemove = (selectedList) => {
        setSelectedOptions(selectedList);
    };

    // Recent Topics
    const onSelect1 = (selectedList1) => {
        setSelectedOptions1(selectedList1);
        setRecentTopicError("");
    };

    const onRemove1 = (selectedList1) => {
        setSelectedOptions1(selectedList1);
    };


    // Article image
    useEffect(() => {
        let imgArray = [];
        creatorPitchDetail?.result?.Article_Images?.map((val) =>
            imgArray.push({ Article_Images: val, type: "old" }));
        setSelectedImage(imgArray);
    }, [creatorPitchDetail?.result?.Article_Images])


    const handleFileUpload = event => {
        const file = event.target.files[0]; // Get the first file from the input
        if (file) {
            let result = FileSizeValidation([file]);
            if (result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
                imageRef.current.value = null;
            } else {
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    setSelectedImage([{
                        file: file,
                        previewUrl: URL.createObjectURL(file),
                        type: "new",
                    }]);
                    setFileError(""); // Clear any previous file error
                } else {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { show: true, status: "failed", message: "Unsupported File Format" }
                    });
                    imageRef.current.value = null;
                }
            }
        }
    };

    const handleRemoveImage = (index) => {
        setSelectedImage((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            if (updatedImages.length === 0) {
                // If there are no remaining images, clear the file error
                setFileError("article image is required");
            }
            return updatedImages;
        });
    };

    useEffect(() => {
        // Check if selectedImage is empty and clear the file error
        if (selectedImage.length === 0) {
            setFileError("");
        }
    }, [selectedImage]);

    // Audio
    const handleAudioChange = event => {
        const file = event.target.files[0]
        setPodcastError("")
        setPodcast(file)
        setPodcastFile(URL.createObjectURL(file))
    }

    const handleClearAudio = () => {
        setPodcastFile(null)
    }

    // Thumbnail Image
    const handleThumbnailImageChange = (e) => {
        if (event.target.files?.length !== 0) {
            const thumbFile = event.target.files[0];
            let result = FileSizeValidation([thumbFile]);
            if (!result || result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
                fileInputRef.current.value = null;
                return;
            }
            setThumbError("");
            if (thumbFile) {
                setThumnailImage(URL.createObjectURL(thumbFile));
                setNewThumbimage(URL.createObjectURL(thumbFile))
                setThumbFile(thumbFile);
            } else {
                setThumbError("Thumbnail Image is required");
            }
            setShowCropper(true);
        };
    }

    const handleRemoveThumbnail = () => {
        setThumnailImage("");
        setThumbFile("");
        setShowCropper(false);
        if (validation?.values?.TypeOfContent === "Podcast") {
            setThumbError("thumb-nail image is required");
        } else {
            setThumbError("");
        }
        fileInputRef.current.value = null;
    };

    // Duration
    const handleDuration = (e) => {
        if (e.target.value === null) {
            setDurationError("duration of the uploaded file is required");
        } else {
            setDuration(e.target.value)
            setDurationError("");
        }
    }

    useEffect(() => {
        if (adminContentDetail?.Caption) {
            setCaption(adminContentDetail.Caption);
        }
    }, [adminContentDetail]);

    useEffect(() => {
        dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id });
    }, [Id, checkStatus, StatusCheckFromModal]);

    //To check the current Content status before approve/reject
    useEffect(() => {
        if (checkStatus === true && (adminContentDetail?.Status === 'Approved') && (modalType === 'Approve')) {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Content status already updated" }
            })
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            navigate('/content-details')
        } else if (checkStatus === true && (adminContentDetail?.Status === 'New' || adminContentDetail?.Status === "Updated") && (modalType === 'Approve')) {
            dispatch({
                type: commonActions.SET_SHOW_MODAL,
                payload: { TypeOfModal: 'APPROVEORREJECTCONTENT', isOpen: true, data: { modalType: modalType } }
            });
            setStatusCheckFromModal(false)
            setCheckStatus(false)
        } else if (StatusCheckFromModal === true && (adminContentDetail?.Status === 'New' || adminContentDetail?.Status === "Updated")) {
            let formData = new FormData();
            formData = pageFormData;
            // if (formData) {
            //     for (let pair of formData.entries()) {
            //         console.log(pair[0] + ', ' + pair[1]);
            //     }
            // }
            let previousValue;
            if (adminContentDetail?.Status === 'New') {
                formData.append('Creator_Price', price);
                previousValue = "New"
            } else {
                previousValue = "Old"
            }
            let Content_Status_Updated_By = {};
            if (superAdminTokenResponse?.Type_Of_User === 'Super Admin') {
                Content_Status_Updated_By = {
                    Name: superAdminTokenResponse?.result?.Name,
                    Email: superAdminTokenResponse?.result?.Email,
                    Type_Of_User: superAdminTokenResponse?.result?.Type_Of_User
                }
            } else if (adminTokenResponse?.Type_Of_User === 'Admin') {
                Content_Status_Updated_By = {
                    Name: adminTokenResponse?.result?.Name,
                    Email: adminTokenResponse?.result?.Email,
                    Type_Of_User: adminTokenResponse?.result?.Type_Of_User
                }
            }
            formData.append("RemarksForContent", modelApproveReason || "");
            formData.append("Content_Status_Updated_By", JSON.stringify(Content_Status_Updated_By));

            // for (var pair of formData.entries()) { //to console formdata
            //     console.log(pair[0] + ', ' + pair[1]);
            // }

            dispatch({
                type: BlogContentActions.GET_ADMIN_EDIT_CHANGE_CONTENT_STATUS,
                payload: {
                    ContentId: creatorPitchDetail?.result?._id,
                    previousValue: previousValue,
                    data: formData, Creator_Id: creatorPitchDetail?.result?.Content_Creator_Id,
                    Creator_Price: price,
                    navigate: navigate
                }
            })
        } else if (StatusCheckFromModal === true && adminContentDetail?.Status === 'Approved') {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { show: true, status: "failed", message: "Content status already updated" }
            })
            handleCloseModel()
            setStatusCheckFromModal(false)
            setCheckStatus(false)
            navigate('/content-details')
        }
    }, [adminContentDetail])

    // Approve or reject model
    const handleShowModel = (modalTypeClicked, formData) => {
        setModalType(modalTypeClicked)
        setCheckStatus(true)
        setPageFormData(formData);
        dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id })
    };

    const handleCloseModel = () => {
        setModelApproveReason("");
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { TypeOfModal: null, isOpen: false, data: null }
        });
    };

    const handleApprovalStatus = () => {
        let formData = new FormData();
        formData = pageFormData;
        setStatusCheckFromModal(true)
        setCheckStatus(false)
        setModalType('')
        dispatch({ type: BlogContentActions.GET_ADMIN_CONTENT_DETAIL, payload: Id })
    };

    const handleInputChange = (event) => {
        setModelApproveReason(event.target.value);
    };

    const handleRejectChange = (event) => {
        setRejectReason(event.target.value);
    };

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleChange = date => {
        setArticleDate(date);
        if (dateError) {
            setDateError('');
        }
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    };


    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        try {
            const imgElement = new Image();
            imgElement.onload = async () => {
                const croppedImage = await getCroppedImg(imgElement, croppedAreaPixels);
                setCroppedImage(croppedImage);
            };
            imgElement.src = thumnailImage;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const getCroppedImg = async (image, croppedAreaPixels) => {
        if (!(image instanceof HTMLImageElement)) {
            throw new Error('Provided image is not an HTMLImageElement');
        }

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            croppedAreaPixels.x * scaleX,
            croppedAreaPixels.y * scaleY,
            croppedAreaPixels.width * scaleX,
            croppedAreaPixels.height * scaleY,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    };

    const handleShowCroppedImage = () => {
        setShowCropper(false);
        setThumnailImage(URL.createObjectURL(croppedImage))
    };

    return (
        <React.Fragment>
            <Form className="form-horizontal mt-2"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                <div className="page-content">
                    {adminPageLoader ?
                        <AdminPageLoader /> :
                        <Container fluid>
                            <Breadcrumb
                                title="content"
                                titleLink={`/content/${state?.type}`}
                                breadcrumbItem="details"
                            />
                            <Card>
                                <CardBody>
                                    <Row className="mb-4">

                                        <Col md="6">
                                            <div className="form-group">
                                                <Label className="form-label">Type of Content</Label><span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="TypeOfContent"
                                                    id="type"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.TypeOfContent || typeOfContent}
                                                    invalid={validation.touched.TypeOfContent && validation.errors.TypeOfContent ? true : false}
                                                    disabled
                                                >
                                                    <option value="" disabled> -- Select Content Type --  </option>
                                                    <option value="Article">Article</option>
                                                    <option value="Podcast">Podcast</option>
                                                    {/* <option value="Video">Video</option> */}
                                                </Input>

                                                {validation.touched.TypeOfContent && validation.errors.TypeOfContent ? (
                                                    <FormFeedback type="invalid">{validation.errors.TypeOfContent}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col md="6" className="mb-4">
                                            <div className="form-group">
                                                <Label htmlFor="Title" className="form-label">market focus</Label><span className="text-danger fw-bold">*</span>
                                                <Multiselect
                                                    options={countryArray}
                                                    displayValue="name"
                                                    onSelect={onSelectCounty}
                                                    onRemove={onRemoveCountry}
                                                    selectedValues={countryOptions}
                                                // value={validation.values.Country || []}
                                                />
                                                <div>
                                                    <div><span className="market-select">Selected Values:</span> {countryOptions?.map((option) => option.name).join(', ')}</div>
                                                </div>
                                                {countryError && <div style={{ color: "red" }}>{countryError}</div>}
                                            </div>
                                        </Col>

                                        <Col md="12" className="mb-4">
                                            <Label htmlFor="Title" className="form-label">Title</Label><span className="text-danger fw-bold">*</span>
                                            <Input
                                                name="Title"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Title || title}
                                                invalid={
                                                    validation.touched.Title && validation.errors.Title ? true : false
                                                }
                                            />
                                            {validation.touched.Title && validation.errors.Title ? (
                                                <FormFeedback type="invalid">{validation.errors.Title}</FormFeedback>
                                            ) : null}
                                        </Col>

                                        <Col className="mb-4" md={validation?.values?.Category === "Frontier Markets" || validation?.values?.Category === "Recent Topic" ? "6" : "12"} >
                                            <div className="form-group">
                                                <Label className="form-label">Category</Label><span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="Category"
                                                    id="type"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.Category || category}
                                                    invalid={validation.touched.Category && validation.errors.Category ? true : false}
                                                >
                                                    <option value="" disabled> -- Select the Category --  </option>
                                                    <option value="Business">Business</option>
                                                    <option value="Politics">Politics</option>
                                                    {/* <option value="Green">Green</option>
                                                    <option value="In Depth">In-Depth</option> */}
                                                    <option value="Recent Topic">Recent Topics</option>
                                                    {/* <option  value="localknowledge">Local Knowledge</option> */}
                                                    {/* <option value="Frontier Markets">Frontier Markets</option> */}
                                                </Input>
                                                {validation.touched.Category && validation.errors.Category ? (
                                                    <FormFeedback type="invalid">{validation.errors.Category}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {validation?.values?.Category === "Frontier Markets" ?
                                            <>
                                                <Col className="mb-4" md="6">
                                                    <div className="form-group ">
                                                        <Label className="form-label">Select the Frontier Markets</Label><span className="text-danger fw-bold">*</span>
                                                        <Multiselect
                                                            options={countryArray}
                                                            displayValue="name"
                                                            onSelect={onSelect}
                                                            onRemove={onRemove}
                                                            selectedValues={selectedOptions}
                                                        // value={validation.values.Sub_Category || []}
                                                        />
                                                        <div>
                                                            <div><span className="market-select">Selected Values:</span> {selectedOptions.map((option) => option.name).join(', ')}</div>
                                                        </div>
                                                        {marketError && <div style={{ color: "red" }}>{marketError}</div>}
                                                    </div>
                                                </Col>
                                            </> : validation?.values?.Category === "Recent Topic" ?
                                                <>
                                                    <Col className="mb-4" md="6">
                                                        <div className="form-group">
                                                            <Label className="form-label">Select Recent Topic</Label>
                                                            <span className="text-danger fw-bold">*</span>
                                                            <Multiselect
                                                                options={recentTopic}
                                                                displayValue="name"
                                                                onSelect={onSelect1}
                                                                onRemove={onRemove1}
                                                                selectedValues={selectedOptions1}
                                                            // value={validation.values.Sub_Category || []}
                                                            >
                                                                <option value="" disabled>
                                                                    -- Select the Recent Topic --
                                                                </option>
                                                                {/* Map over the array to generate options */}
                                                                {recentTopic?.map((topic) => (
                                                                    <option key={topic.value} value={topic.value}>
                                                                        {topic.name}
                                                                    </option>
                                                                ))}
                                                            </Multiselect>

                                                            <div>
                                                                <div><span className="market-select">Selected Values:</span> {selectedOptions1.map((option) => option.name).join(', ')}</div>
                                                            </div>
                                                            {recentTopicError && <div style={{ color: "red" }}>{recentTopicError}</div>}
                                                        </div>
                                                    </Col>
                                                </> :
                                                <></>
                                        }

                                        {creatorPitchDetail?.result?.TypeOfContent === "Article" ? <>
                                            <Col md="12" className="mb-4">
                                                <div className="form-group">
                                                    <Label className="form-label">Pitch story</Label>
                                                    <div className="form-control form-control-textarea storyfont">
                                                        <div className="clamp-text" dangerouslySetInnerHTML={{ __html: pitchStory }} />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md="12" className="mb-4">
                                                <div className="form-group">
                                                    <Col className="mb-4" md="12">
                                                        <Label htmlFor="title">What is the Story?</Label><span className="text-danger fw-bold">*</span>
                                                        <div className="textEditorStyle" style={{ textDecoration: 'none' }}>
                                                            <RichTextEditor setStoryContent={setStoryContent} storyContent={storyContent} />
                                                            {/* <div dangerouslySetInnerHTML={{ __html: storyContent }} /> */}
                                                        </div>


                                                        {descError && <div style={{ color: "red" }}>{descError}</div>}
                                                    </Col>
                                                </div>
                                            </Col>
                                        </> : <></>}
                                        <Col className="mb-4" md="12">
                                            <Label htmlFor="title">Impact on Markets</Label><span className="text-danger fw-bold">*</span>

                                            <Input
                                                className="form-control"
                                                id="impacts"
                                                name="Impacts"
                                                type="textarea"
                                                rows={5}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Impacts || impacts}
                                                invalid={
                                                    validation.touched.Impacts && validation.errors.Impacts ? true : false
                                                }
                                            />
                                            {validation.touched.Impacts && validation.errors.Impacts ? (
                                                <FormFeedback type="invalid">{validation.errors.Impacts}</FormFeedback>
                                            ) : null}
                                        </Col>

                                        {typeOfContent === 'Article' ?
                                            <>
                                                {selectedImage && selectedImage.length !== 0 && (
                                                    <Col md="12">
                                                        <div className="form-group mb-3">
                                                            <Label className="form-label">Article Image</Label>
                                                            <span><small className="text-success"><i> (Supported Image formats : .jpeg, .jpg, .png)</i></small></span>

                                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>

                                                                {selectedImage.map((image, index) => (
                                                                    <div key={index} style={{ marginTop: '10px', marginRight: '20px', marginBottom: '10px', position: 'relative' }}>
                                                                        {image?.type === 'old' ? (
                                                                            <>
                                                                                <img
                                                                                    src={image?.Article_Images}
                                                                                    alt={`Selected ${index + 1}`}
                                                                                    style={{ maxWidth: '150px', maxHeight: '150px', marginBottom: '5px' }}
                                                                                />
                                                                                <button className="removeButton"
                                                                                    type="button"
                                                                                    onClick={() => handleRemoveImage(index)}>
                                                                                    X
                                                                                </button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <img
                                                                                    src={image?.previewUrl}
                                                                                    alt="Preview"
                                                                                    style={{ maxWidth: '150px', maxHeight: '150px', marginBottom: '5px' }}
                                                                                />
                                                                                <button
                                                                                    className="removeButton"
                                                                                    type="button"
                                                                                    onClick={() => handleRemoveImage(index)}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )}
                                                {(!selectedImage || selectedImage.length === 0) && (
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Article Image <span className="text-danger fw-bold">*</span></Label>
                                                        <Col md="12">
                                                            <Input
                                                                type="file"
                                                                name="image"
                                                                innerRef={imageRef}
                                                                accept="image/png, image/jpeg,image/jpg"
                                                                onChange={handleFileUpload} // Uncomment if needed
                                                                multiple={false}
                                                            />
                                                            {fileError && <div style={{ color: "red" }}>{fileError}</div>}
                                                        </Col>
                                                    </div>
                                                )}
                                                <Col md="12" className="mb-4">
                                                    <Label htmlFor="Title" className="form-label">Caption for article image</Label><span className="text-danger fw-bold">*</span>
                                                    <Input
                                                        name="Caption"
                                                        type="text"
                                                        // onChange={validation.handleChange}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            if (inputValue?.length <= 100) {
                                                                validation.handleChange(e);
                                                                setCaption(inputValue)
                                                                setCaptionError('')
                                                            } else {
                                                                setCaptionError('Maximum 100 characters only allowed');
                                                            }
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Caption || caption}
                                                        invalid={
                                                            validation.touched.Caption && validation.errors.Caption ? true : false
                                                        }
                                                    />
                                                    {(captionError) && <div style={{ color: "red" }} className='pt-1'>{captionError}</div>}
                                                </Col>
                                            </> :
                                            <></>
                                        }


                                        {typeOfContent === "Podcast" ?
                                            // typeOfContent === "Video"
                                            (
                                                <>
                                                    <Col md="12" className="mb-4">
                                                        <div className="form-group">
                                                            <Label htmlFor="title">Podcast File</Label>
                                                            <span className="text-danger fw-bold">*</span>
                                                            <span><small className="text-success"><i> (Supported Image format : .mp3)</i></small></span>

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {podcastFile ? (
                                                                    <div style={{ marginRight: '10px' }}>
                                                                        <audio key={audioKey} controls>
                                                                            <source src={podcastFile} type="audio/mpeg" />
                                                                        </audio>
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group mb-3">
                                                                        <label className="form-label">Upload New Podcast File</label>
                                                                        <div>
                                                                            <Input
                                                                                className="form-control"
                                                                                type="file"
                                                                                accept="audio/mpeg, audio/wav"
                                                                                innerRef={audioref}
                                                                                onChange={handleAudioChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {podcastFile && (
                                                                    <button className="clear-audio" onClick={handleClearAudio}>Clear Audio</button>
                                                                )}
                                                            </div>

                                                            {podcastError && (
                                                                <div style={{ color: "red" }}>{podcastError}</div>
                                                            )}
                                                        </div>
                                                    </Col>

                                                    {/* Thumbnail image  */}
                                                    <div>
                                                        <div className="form-group mb-3">
                                                            {/* Thumbnail Image */}
                                                            <Label className="form-label">
                                                                Thumbnail Image<span className="text-danger fw-bold">*</span>
                                                            </Label>
                                                            <span><small className="text-success"><i> (Supported Image formats : .jpeg, .jpg, .png)</i></small></span>
                                                            {(thumnailImage?.length !== 0) && (
                                                                <div>
                                                                    <img
                                                                        className="profile-pic"
                                                                        src={thumnailImage}
                                                                        alt="Selected"
                                                                        style={{
                                                                            padding: "10px",
                                                                            maxWidth: "50%",
                                                                            height: "auto",
                                                                        }}
                                                                    />
                                                                    <button
                                                                        className="remove-button"
                                                                        type="button"
                                                                        onClick={handleRemoveThumbnail}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Col className="mb-4" md="12">
                                                            {/* Cropper */}
                                                            <Input
                                                                type="file"
                                                                name="newThumbnailImage"
                                                                innerRef={fileInputRef}
                                                                accept="image/png, image/jpeg,image/jpg,image/webp"
                                                                onChange={handleThumbnailImageChange}
                                                                multiple={false}
                                                            />
                                                            {(newThumbimage && showCropper) && (
                                                                <div>
                                                                    <Cropper
                                                                        image={newThumbimage}
                                                                        crop={crop}
                                                                        zoom={zoom}
                                                                        aspect={4 / 3}
                                                                        onCropChange={onCropChange}
                                                                        onZoomChange={onZoomChange}
                                                                        onCropComplete={onCropComplete}
                                                                    />
                                                                    <button className='finish-button' onClick={handleShowCroppedImage}>Finish Cropping</button>
                                                                </div>
                                                            )}
                                                            {thumbError && (
                                                                <div style={{ color: "red" }}>{thumbError}</div>
                                                            )}
                                                        </Col>
                                                    </div>

                                                    {/* <Col md="12" className="mt-4 mb-4">
                                                        <div className="form-group">
                                                            <Label htmlFor="Title" className="form-label">
                                                                Duration
                                                            </Label>
                                                            <span className="text-danger fw-bold">*</span>
                                                            <Input
                                                                name="Duration"
                                                                type="text"
                                                                onChange={e => handleDuration(e)}
                                                                value={duration}
                                                            />
                                                            {durationError && (
                                                                <div style={{ color: "red" }}>
                                                                    {durationError}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col> */}
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                        <Label className="form-label mt-3"> {creatorPitchDetail?.result?.TypeOfContent === "Article" ? "Article Publish Date" : "Podcast Publish Date"} <span className="text-danger fw-bold">*</span></Label>
                                        <DatePicker className="articleDate"
                                            defaultValue={new Date(validation?.values?.Date_Approved) || new Date()} // April 10, 2024, 9:30 AM
                                            selected={articleDate}
                                            onChange={handleChange}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            maxDate={maxSelectableDate}
                                            placeholderText="select date and time"
                                        />
                                        {(dateError) && <div style={{ color: "red" }} className='pt-1'>{dateError}</div>}
                                        {(initialName && initialTypeOfUser) && <>
                                            <Label className="form-label mt-3"> {creatorPitchDetail?.result?.TypeOfContent === "Podcast" ? "Podcast Uploaded By" : " "} <span className="text-danger fw-bold">*</span></Label>
                                            <Input
                                                className="form-control"
                                                id="podcastuploaded"
                                                name="podcastSubmittedBy"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation?.values?.Podcast_Submitted_By}
                                                invalid={
                                                    validation.touched.Podcast_Submitted_By && validation.errors.Podcast_Submitted_By ? true : false
                                                }
                                                disabled
                                            />
                                        </>}
                                        {RejectionReason &&
                                            <Col md="12" className="mb-4">
                                                <div className="form-group">
                                                    <Label className="form-label">Rejection Reason</Label>
                                                    <Input
                                                        name="Title"
                                                        className="form-control"
                                                        placeholder="Enter Rejection Reason"
                                                        type="textarea"
                                                        rows={3}
                                                        defaultValue={RejectionReason}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        }
                                        {approveReason &&
                                            <Col md="12" className="mb-4">
                                                <div className="form-group">
                                                    <Label className="form-label">Remarks for Content</Label>
                                                    <Input
                                                        name="Title"
                                                        className="form-control"
                                                        placeholder="Enter Rejection Reason"
                                                        type="textarea"
                                                        rows={3}
                                                        defaultValue={approveReason}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        }
                                        {(statusUpdaterName && statusUpdaterTypeOfUser) && <>
                                            <Label className="form-label mt-3"> {creatorPitchDetail?.result?.TypeOfContent === "Article" ? "Content Approved By" : " "} <span className="text-danger fw-bold">*</span></Label>
                                            <Input
                                                className="form-control"
                                                id="podcastuploaded"
                                                name="podcastSubmittedBy"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation?.values?.Content_Status_Updated_By}
                                                invalid={
                                                    validation.touched.Content_Status_Updated_By && validation.errors.Content_Status_Updated_By ? true : false
                                                }
                                                disabled
                                            />
                                        </>}
                                        {(contentLastUpdatedName && contentLastUpdatedTypeOfUser) && <>
                                            <Label className="form-label mt-3"> {creatorPitchDetail?.result?.TypeOfContent === "Article" ? "Last Updated By" : " "} <span className="text-danger fw-bold">*</span></Label>
                                            <Input
                                                className="form-control"
                                                id="podcastuploaded"
                                                name="podcastSubmittedBy"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation?.values?.Content_Last_Updated_By}
                                                invalid={
                                                    validation.touched.Content_Last_Updated_By && validation.errors.Content_Last_Updated_By ? true : false
                                                }
                                                disabled
                                            />
                                        </>}
                                        <div className="mt-5">
                                            {(adminContentDetail?.Status === 'New' || adminContentDetail?.Status === 'Updated') ?
                                                <>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="me-3"
                                                    >
                                                        {buttonLoader ? <Spinner color="light" size="sm" /> : "Approve"}
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        color="danger"
                                                        className="btn btn-primary btn-block"
                                                        onClick={() => navigate('/content-details')}
                                                    >
                                                        Back
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <button
                                                        type="submit"
                                                        color="primary"
                                                        className=" btn btn-primary btn-block me-3"
                                                    >
                                                        {buttonLoader ? <Spinner color="light" size="sm" /> : "Submit"}
                                                    </button>

                                                    <Button
                                                        type="submit"
                                                        color="danger"
                                                        className="btn btn-primary btn-block"
                                                        onClick={() =>
                                                            creatorPitchDetail?.result?.TypeOfContent === "Article"
                                                                ? navigate('/content/Approved')
                                                                : navigate('/admin-podcast')
                                                        }
                                                    >
                                                        Close
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>
                    }
                </div>

                <Modal isOpen={showCreationPopup?.isOpen} toggle={handleClose} centered={true}>
                    <ModalHeader centered={true} toggle={handleClose} tag="h4">
                        <h4>Alert</h4>
                    </ModalHeader>
                    <ModalBody centered={true}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                // validation.handleSubmit();
                                return false;
                            }}
                        >
                            {
                                showCreationPopup?.statusCode === 200 ?

                                    <Row>
                                        <Col md={12} className="text-center">
                                            <img src={success_img} alt="success_popup" className="popup-success" />
                                            <h5>
                                                {(state?.data.TypeOfContent === "Article")
                                                    ? "Content is Updated"
                                                    : "Podcast is Updated"}
                                            </h5>
                                        </Col>
                                        <hr />

                                        <Col md={12}>
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger save-customer me-2"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            }
                        </Form>
                    </ModalBody>
                </Modal>
                {/* APPROVE or REJECT Model */}
                <ApproveOrRejectContentModal
                    modal={showPopup?.TypeOfModal === 'APPROVEORREJECTCONTENT' && showPopup?.isOpen}
                    data={creatorPitchDetail}
                    toggle={handleCloseModel}
                    title={showPopup?.data?.modalType === 'Approve' ?
                        "approve" : "reject"}
                    buttonName={showPopup?.data?.modalType === 'Approve' ?
                        "approve" : "reject"}
                    message={showPopup?.data?.modalType === 'Approve' ?
                        <>
                            {state?.data?.Status === 'New' ?
                                <>
                                    <Label className="form-label">Price</Label>
                                    <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '5px' }}>

                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Input
                                            type="text"
                                            value={price}
                                            onChange={handlePriceChange}
                                            style={{ width: '30%', border: 'none', outline: 'none' }}
                                        />
                                    </div>
                                    <Label className="form-label mt-3">Remarks</Label>
                                    <Input
                                        type="textarea"
                                        placeholder="Enter your remarks here..."
                                        value={modelApproveReason}
                                        onChange={handleInputChange}
                                    />
                                </>
                                : <>
                                    <Label className="form-label mt-3">Remarks</Label>
                                    <Input
                                        type="textarea"
                                        placeholder="Enter your remarks here..."
                                        value={modelApproveReason}
                                        onChange={handleInputChange}
                                    />
                                </>
                            }
                        </> :
                        <>
                            <Input
                                type="textarea"
                                placeholder="Enter your reject reason here..."
                                value={rejectReason}
                                onChange={handleRejectChange}
                            />
                            <p className="text-danger text-size pt-2">{rejectError}</p>
                        </>
                    }
                    handleSubmit={() => handleApprovalStatus(showPopup?.data?.formData)}
                    type={showPopup?.data?.modelType}
                    formData={showPopup?.data?.formData}
                />
            </Form>
        </React.Fragment >
    )
}
