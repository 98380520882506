export const WebContentDeatilActions = {

    GET_WEBCONTENT_DETAIL_NEWS: "GET_WEBCONTENT_DETAIL_NEWS",
    SET_WEBCONTENT_DETAIL_NEWS: "SET_WEBCONTENT_DETAIL_NEWS",

    GET_WEB_NEWSLETTER_EMAIL: "GET_WEB_NEWSLETTER_EMAIL",
    RESPONSE_WEB_NEWSLETTER_EMAIL: "RESPONSE_WEB_NEWSLETTER_EMAIL",

    UNSUBSCRIBE_NEWSLETTER: 'UNSUBSCRIBE_NEWSLETTER',
    UNSUBSCRIBE_NEWSLETTER_RESPONSE: 'UNSUBSCRIBE_NEWSLETTER_RESPONSE',

    GET_WEB_DETAIL_PAGE_RELATED_LIST: 'GET_WEB_DETAIL_PAGE_RELATED_LIST',
    SET_WEB_DETAIL_PAGE_RELATED_LIST: 'SET_WEB_DETAIL_PAGE_RELATED_LIST'

}  