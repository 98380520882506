import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Label, Input, Button, FormFeedback, Form, Spinner } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import { WithdrawActions } from "store/withdrawRequest/actions";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime/css/react-datetime.css';
import RejectWithdrawModal from '../commonModals/RejectWithdrawRequestModal'

export default function AdminWithdrawRequestDetail() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    const { buttonLoader } = useSelector(state => state.commonReducer);
    const { creatorWithdrawDetail } = useSelector(state => state.withdrawReducer);
    const maxSelectableDate = new Date();
    let [showRejectPopup, setShowRejectPopup] = useState(false)
    const [rejectReason, setRejectReason] = useState("")
    var [rejectError, setRejectError] = useState("")
    useEffect(() => {
        if (state?._id) {
            dispatch({
                type: WithdrawActions.GET_WITHDRAW_REQUEST_DETAIL,
                payload: state?._id
            })
        }
    }, [state])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Creator_Name: '',
            Email: '', // Correct field name
            TotalAmount: '',
            PaidAmount: '',
            PendingAmount: '',
            BankAccountNumber: '', // Change to string if alphanumeric
            BankName: '',
            Branch: '',
            NameAsPerBankAccount: '',
            WithdrawAmount: '',
            ContactNumber: '',
            IBAN_Number: '',
            Status: '',
            Submitted_On: '',
            TransactionId: '',
            ModeOfPayment: '',
            RequestedAmountPaidOn: ''
        },
        validationSchema: Yup.object({
            Creator_Name: Yup.string().required('creator name is required'),
            Email: Yup.string().email('Invalid email format').required('email is required'), // Validation for email
            TotalAmount: Yup.number().required('Total amount is required'),
            PaidAmount: Yup.number().required('Received amount is required'),
            PendingAmount: Yup.number().required('pending amount is required'),
            BankAccountNumber: Yup.string().required('Bank Account Number is required'), // Change to string if alphanumeric
            BankName: Yup.string().required('Bank Name is required'), // Change to string if alphanumeric
            Branch: Yup.string().required('Branch is required'), // Correct field name
            NameAsPerBankAccount: Yup.string().required('Name as per bank account is required'),
            ContactNumber: Yup.string().required('Contact number is required'),
            IBAN_Number: Yup.string().required('IBAN number is required'),
            WithdrawAmount: Yup.number().required('Withdraw amount is required'),
            Status: Yup.string().required('Status is required'),
            Submitted_On: Yup.string().required('Submitted on is required'),
            TransactionId: Yup.string().required('Transaction id is required'),
            ModeOfPayment: Yup.string().required('Mode of payment is required'),
            RequestedAmountPaidOn: Yup.string().required('Requested amount paid on is required')
        }),
        onSubmit: (data) => {

            data['Creator_Id'] = creatorWithdrawDetail?.result?.Creator_Id;//will get id here
            data['Creator_Name'] = validation?.values?.Creator_Name;
            data['Email'] = validation?.values?.Email;
            data['PendingAmount'] = validation?.values?.PendingAmount;
            data['TotalAmount'] = validation?.values?.TotalAmount;
            data['PaidAmount'] = validation?.values?.PaidAmount;
            data['BankAccountNumber'] = validation?.values?.BankAccountNumber;
            data['BankName'] = validation?.values?.BankName;
            data['Branch'] = validation?.values?.Branch;
            data['NameAsPerBankAccount'] = validation?.values?.NameAsPerBankAccount;
            data['ContactNumber'] = validation?.values?.ContactNumber;
            data['IBAN_Number'] = validation?.values?.IBAN_Number;
            data['WithdrawAmount'] = validation?.values?.WithdrawAmount;
            data['Status'] = 'Processed';
            data['Submitted_On'] = validation?.values?.Submitted_On;
            data['TransactionId'] = validation?.values?.TransactionId;
            data['ModeOfPayment'] = validation?.values?.ModeOfPayment;
            data['RequestedAmountPaidOn'] = validation?.values?.RequestedAmountPaidOn;

            const RequestId = creatorWithdrawDetail?.result?._id
            dispatch({
                type: WithdrawActions.UPDATE_WITHDRAW_REQUEST_STATUS,
                payload: { data: data, id: RequestId, navigate: navigate }
            });



        }
    });

    useEffect(() => {
        if (creatorWithdrawDetail) {
            validation.setFieldValue("Creator_Name", creatorWithdrawDetail?.result?.Creator_Name);
            validation.setFieldValue("Email", creatorWithdrawDetail?.result?.Email);
            validation.setFieldValue("TotalAmount", creatorWithdrawDetail?.result?.TotalAmount);
            validation.setFieldValue("PaidAmount", creatorWithdrawDetail?.result?.PaidAmount);
            validation.setFieldValue("PendingAmount", creatorWithdrawDetail?.result?.PendingAmount);
            validation.setFieldValue("BankAccountNumber", creatorWithdrawDetail?.result?.BankAccountNumber);
            validation.setFieldValue("BankName", creatorWithdrawDetail?.result?.BankName);
            validation.setFieldValue("Branch", creatorWithdrawDetail?.result?.Branch);
            validation.setFieldValue("NameAsPerBankAccount", creatorWithdrawDetail?.result?.NameAsPerBankAccount);
            validation.setFieldValue("ContactNumber", creatorWithdrawDetail?.result?.ContactNumber);
            validation.setFieldValue("IBAN_Number", creatorWithdrawDetail?.result?.IBAN_Number);
            validation.setFieldValue("WithdrawAmount", creatorWithdrawDetail?.result?.WithdrawAmount);
            validation.setFieldValue("Status", creatorWithdrawDetail?.result?.Status);
            validation.setFieldValue("Submitted_On", creatorWithdrawDetail?.result?.Submitted_On);
            if (creatorWithdrawDetail?.result?.Status === 'Processed') {
                validation.setFieldValue("TransactionId", creatorWithdrawDetail?.result?.TransactionId);
                validation.setFieldValue("ModeOfPayment", creatorWithdrawDetail?.result?.ModeOfPayment);
                validation.setFieldValue("RequestedAmountPaidOn", creatorWithdrawDetail?.result?.RequestedAmountPaidOn);
            }
            if (creatorWithdrawDetail?.result?.Status === 'Rejected') {
                setRejectReason(creatorWithdrawDetail?.result?.Rejection_Reason)
            }

        }
    }, [creatorWithdrawDetail])

    const handleRejectPopup = () => {
        setShowRejectPopup(true)
    }

    const handleCloseModel = () => {
        setShowRejectPopup(false)
        setRejectError("")
    }

    const handleRejectChange = event => {
        setRejectReason(event.target.value)
    }
    const handleRejectStatus = () => {
        if (
            rejectReason === "" ||
            rejectReason === undefined ||
            rejectReason === null
        ) {
            setRejectError("Please Enter the Reason for Rejection")
        } else {
            setRejectError("")
            validation.setFieldError('TransactionId', '')
            validation.setFieldError('ModeOfPayment', '')
            validation.setFieldError('RequestedAmountPaidOn', '')
            let data = {
                Status: "Rejected",
                Rejection_Reason: rejectReason
            }
            const RequestId = creatorWithdrawDetail?.result?._id

            dispatch({
                type: WithdrawActions.UPDATE_WITHDRAW_REQUEST_STATUS,
                payload: { data: data, id: RequestId, navigate: navigate, handleCloseModel: handleCloseModel },
            })
        }
    }

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Card>
                                <CardBody>
                                    <Row>
                                        <p className='pageTitle'>WITHDRAWAL REQUEST DETAILS</p>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Creator Name</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    className="form-control"
                                                    name="Creator_Name"
                                                    placeholder="Enter Name"
                                                    type="text"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.Creator_Name || ''}
                                                    invalid={validation?.touched?.Creator_Name && validation?.errors?.Creator_Name ? true : false}
                                                    disabled
                                                >
                                                </Input>
                                                {validation?.touched?.Creator_Name && validation?.errors?.Creator_Name ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Creator_Name}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Email</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Email"
                                                    placeholder="Enter Email"
                                                    type="text"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.Email ?? ''}
                                                    invalid={validation?.touched?.Email && validation?.errors?.Email ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.Email && validation?.errors?.Email ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Email}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Contact number</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="ContactNumber"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.ContactNumber ?? ''}
                                                    invalid={validation?.touched?.ContactNumber && validation?.errors?.ContactNumber ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.ContactNumber && validation?.errors?.ContactNumber ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.ContactNumber}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Bank account number</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="BankAccountNumber"
                                                    // placeholder="Enter Transaction Id"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.BankAccountNumber ?? ''}
                                                    invalid={validation?.touched?.BankAccountNumber && validation?.errors?.BankAccountNumber ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.BankAccountNumber && validation?.errors?.BankAccountNumber ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.BankAccountNumber}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Bank Name</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="BankName"
                                                    // placeholder="Enter Transaction Id"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.BankName ?? ''}
                                                    invalid={validation?.touched?.BankName && validation?.errors?.BankName ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.BankName && validation?.errors?.BankName ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.BankName}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Branch</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Branch"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.Branch ?? ''}
                                                    invalid={validation?.touched?.Branch && validation?.errors?.Branch ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.Branch && validation?.errors?.Branch ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Branch}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Name as per the bank account</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="NameAsPerBankAccount"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.NameAsPerBankAccount ?? ''}
                                                    invalid={validation?.touched?.NameAsPerBankAccount && validation?.errors?.NameAsPerBankAccount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.NameAsPerBankAccount && validation?.errors?.NameAsPerBankAccount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.NameAsPerBankAccount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label ibanclass">IBAN number</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="IBAN_Number"
                                                    // placeholder="Enter Mode Of Payment"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.IBAN_Number ?? ''}
                                                    invalid={validation?.touched?.IBAN_Number && validation?.errors?.IBAN_Number ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.IBAN_Number && validation?.errors?.IBAN_Number ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.IBAN_Number}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Total Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="TotalAmount"
                                                    // placeholder="Enter Total Amount"
                                                    type="number"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.TotalAmount ?? ''}
                                                    invalid={validation?.touched?.TotalAmount && validation?.errors?.TotalAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.TotalAmount && validation?.errors?.TotalAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.TotalAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Paid Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="PaidAmount"
                                                    placeholder="Enter Paid Amount"
                                                    type="number"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.PaidAmount ?? ''}
                                                    invalid={validation?.touched?.PaidAmount && validation?.errors?.PaidAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.PaidAmount && validation?.errors?.PaidAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.PaidAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Pending Amount</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="PendingAmount"
                                                    placeholder="Enter Payment Amount"
                                                    type="number"
                                                    onChange={validation?.handleChange}
                                                    onBlur={validation?.handleBlur}
                                                    value={validation?.values?.PendingAmount ?? ''}
                                                    invalid={validation?.touched?.PendingAmount && validation?.errors?.PendingAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.PendingAmount && validation?.errors?.PendingAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.PendingAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Requested amount to withdraw</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="WithdrawAmount"
                                                    placeholder="Enter the amount"
                                                    type="number"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.WithdrawAmount ?? ''}
                                                    invalid={validation?.touched?.WithdrawAmount && validation?.errors?.WithdrawAmount ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.WithdrawAmount && validation?.errors?.WithdrawAmount ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.WithdrawAmount}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Status</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Status"
                                                    placeholder="status"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.Status ?? ''}
                                                    invalid={validation?.touched?.Status && validation?.errors?.Status ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.Status && validation?.errors?.Status ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Status}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="form-group mb-3">
                                                <Label className="form-label">Requested On</Label> <span className="text-danger fw-bold">*</span>
                                                <Input
                                                    name="Submitted_On"
                                                    placeholder="Submitted_On"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation?.values?.Submitted_On ?? ''}
                                                    invalid={validation?.touched?.Submitted_On && validation?.errors?.Submitted_On ? true : false}
                                                    disabled
                                                />
                                                {validation?.touched?.Submitted_On && validation?.errors?.Submitted_On ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.Submitted_On}</FormFeedback> :
                                                    null
                                                }
                                            </div>
                                        </Col>
                                        {(creatorWithdrawDetail?.result?.Status === 'Pending' || creatorWithdrawDetail?.result?.Status === 'Processed') &&
                                            <>
                                                <Col sm="6">
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Transaction Id</Label> <span className="text-danger fw-bold">*</span>
                                                        <Input
                                                            name="TransactionId"
                                                            placeholder="Enter transaction id"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation?.values?.TransactionId ?? ''}
                                                            invalid={validation?.touched?.TransactionId && validation?.errors?.TransactionId ? true : false}
                                                            disabled={creatorWithdrawDetail?.result?.Status === 'Processed' ? true : false}
                                                        />
                                                        {validation?.touched?.TransactionId && validation?.errors?.TransactionId ?
                                                            <FormFeedback type='invalid'>{validation?.errors?.TransactionId}</FormFeedback> :
                                                            null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="form-group mb-3">
                                                        <Label className="form-label">Mode of payment</Label> <span className="text-danger fw-bold">*</span>
                                                        <Input
                                                            name="ModeOfPayment"
                                                            placeholder="Enter mode of payment"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation?.values?.ModeOfPayment ?? ''}
                                                            invalid={validation?.touched?.ModeOfPayment && validation?.errors?.ModeOfPayment ? true : false}
                                                            disabled={creatorWithdrawDetail?.result?.Status === 'Processed' ? true : false}
                                                        />
                                                        {validation?.touched?.ModeOfPayment && validation?.errors?.ModeOfPayment ?
                                                            <FormFeedback type='invalid'>{validation?.errors?.ModeOfPayment}</FormFeedback> :
                                                            null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    {creatorWithdrawDetail?.result?.Status === 'Processed' ?
                                                        <div className="form-group mb-3">
                                                            <Label className="form-label">Requested amount paid on</Label> <span className="text-danger fw-bold">*</span>
                                                            <Input
                                                                name="RequestedAmountPaidOn"
                                                                placeholder="Enter requested amount paid on"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation?.values?.RequestedAmountPaidOn ?? ''}
                                                                invalid={validation?.touched?.RequestedAmountPaidOn && validation?.errors?.RequestedAmountPaidOn ? true : false}
                                                                disabled
                                                            />
                                                            {validation?.touched?.RequestedAmountPaidOn && validation?.errors?.RequestedAmountPaidOn ?
                                                                <FormFeedback type='invalid'>{validation?.errors?.RequestedAmountPaidOn}</FormFeedback> :
                                                                null
                                                            }
                                                        </div>
                                                        :
                                                        <div className="form-group mb-3">
                                                            <Label className="form-label">Requested amount paid on</Label> <span className="text-danger fw-bold">*</span>

                                                            {/* <DatePicker
                                                        name="RequestedAmountPaidOn"
                                                        selected={validation?.values?.RequestedAmountPaidOn || new Date()} // Set the selected date to current date if not already set
                                                        onChange={date => validation.setFieldValue("RequestedAmountPaidOn", date)} // Update Formik value
                                                        onBlur={validation.handleBlur}
                                                        dateFormat="dd/MM/yyyy" // Specify the date format
                                                        maxDate={maxSelectableDate} // Set the maximum selectable date
                                                        className={validation?.touched?.RequestedAmountPaidOn && validation?.errors?.RequestedAmountPaidOn ? "form-control is-invalid" : "form-control"}
                                                    />
                                                    {validation?.touched?.RequestedAmountPaidOn && validation?.errors?.RequestedAmountPaidOn ? (
                                                        <FormFeedback type='invalid'>{validation?.errors?.RequestedAmountPaidOn}</FormFeedback>
                                                    ) : null} */}


                                                            <DatePicker
                                                                //  className="articleDate"
                                                                // defaultValue={new Date(validation?.values?.RequestedAmountPaidOn)} // April 10, 2024, 9:30 AM
                                                                name="RequestedAmountPaidOn"
                                                                selected={validation?.values?.RequestedAmountPaidOn || new Date()}
                                                                onChange={date => validation.setFieldValue("RequestedAmountPaidOn", date)}
                                                                onBlur={validation.handleBlur}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                                maxDate={maxSelectableDate}
                                                                placeholderText="select date and time"
                                                                className={validation?.touched?.RequestedAmountPaidOn && validation?.errors?.RequestedAmountPaidOn ? "form-control is-invalid" : "form-control"}

                                                            />
                                                            {validation?.touched?.RequestedAmountPaidOn && validation?.errors?.RequestedAmountPaidOn ?
                                                                <small className='text-danger'>{validation?.errors?.RequestedAmountPaidOn}</small> : <></>}

                                                            {/* {validation?.touched?.RequestedAmountPaidOn && validation?.errors?.RequestedAmountPaidOn ?
                                                    <FormFeedback type='invalid'>{validation?.errors?.RequestedAmountPaidOn}</FormFeedback> :
                                                    null
                                                } */}
                                                        </div>
                                                    }

                                                </Col>
                                            </>
                                        }
                                        {creatorWithdrawDetail?.result?.Status === 'Rejected' &&
                                            <Col sm="12">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Rejection Reason</Label>
                                                    <Input
                                                        name="username"
                                                        className="form-control"
                                                        placeholder="Enter User Name"
                                                        type="textarea"
                                                        rows={3}
                                                        defaultValue={rejectReason}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        }

                                    </Row>

                                    <div className="mt-4">
                                        {creatorWithdrawDetail?.result?.Status === 'Pending' ?
                                            <>
                                                <Button
                                                    className="me-3"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    {buttonLoader ? <Spinner color="light" size="sm" /> : "Update status & Create payout"}
                                                </Button>

                                                <Button
                                                    type="button"
                                                    className="me-3"
                                                    color="danger"
                                                    onClick={() => handleRejectPopup()}
                                                >
                                                    Reject
                                                </Button></> : <></>

                                        }
                                        <Button
                                            type="button"

                                            color="warning"
                                            onClick={() => navigate('/all-withdrawal-request')}
                                        >
                                            Close
                                        </Button>

                                    </div>

                                </CardBody>
                            </Card>
                        </Form>
                    </Container>
                    <RejectWithdrawModal
                        modal={showRejectPopup}
                        toggle={handleCloseModel}
                        title="Alert"
                        message={
                            <>
                                <Input
                                    type="textarea"
                                    placeholder="Enter your reject reason here..."
                                    className="ibanclass"
                                    value={rejectReason}
                                    onChange={handleRejectChange}
                                />
                                <p className="text-danger text-size pt-2">{rejectError}</p>
                            </>
                        }
                        handleSubmit={handleRejectStatus}
                    />
                </div>
            </React.Fragment>
        </>
    )
}

