import React from "react"
import ContentListByCountry from "./ContentListByCountry"

export default function CountryListCategoryindex() {
  return (
    <div>
      <ContentListByCountry />
    </div>
  )
}
