import { takeEvery, call, all, put } from "redux-saga/effects"
import { WebBookmarkActions } from "./actions"
import { API_URL } from "../../utils/constant"
import axios from "axios"
import { commonActions } from "../common/actions"

export const WebBookMarkSagas = function* () {
  yield all([
    takeEvery(WebBookmarkActions.GET_WEB_ADD_TO_BOOKMARK, getWebAddToBookmark),
    takeEvery(
      WebBookmarkActions.GET_LIST_OF_BOOKMARKS_DATA,
      getListofBookmarksData
    ),

    takeEvery(WebBookmarkActions.DELETE_THE_BOOKMARK, DeleteTheBookMark),
  ])
}

const getWebAddToBookmark = function* (data) {
  const { payload } = data


  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/bookmark/addBookmark`, payload)
    )
    yield put({
      type: WebBookmarkActions.SET_WEB_ADD_TO_BOOKMARK,
      payload: result?.data,
    })

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    })
  } catch (error) {
    console.error("Error", error)
  }
}

const getListofBookmarksData = function* (data) {
  const { payload } = data
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/bookMark/list/${payload.User_Id}`)
    )
    yield put({
      type: WebBookmarkActions.SET_LIST_OF_BOOKMARKS_DATA,
      payload: result?.data,
    })


  } catch (error) {
    console.error("Error", error)
  }
}

const DeleteTheBookMark = function* (data) {
  const { payload } = data


  try {
    const result = yield call(() =>
      axios.delete(
        `${API_URL}/bookMark/delete/${payload.User_Id}/${payload.Blog_Id}`
      )
    )
    yield put({
      type: WebBookmarkActions.SET_DELETE_THE_BOOKMARK,
      payload: result?.data,
    })

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    })
    yield put({
      type: WebBookmarkActions.GET_LIST_OF_BOOKMARKS_DATA,
      payload,
    })


  } catch (error) {
    console.error("Error", error)
  }
}
