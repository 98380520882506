import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerActions } from "../../store/customer/actions";
import Breadcrumb from "../../common/Breadcrumb";
import AdminPageLoader from "../../common/adminPageLoader";
import profileicon from '../../assets/images/dashboard/user3.png';
import { FaPoundSign } from "react-icons/fa"

export default function CustomerDetailsIndex() {

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { customerDetails } = useSelector(state => state.customerReducer);

    const { adminPageLoader  } = useSelector(state => state.commonReducer);

    const Id = state?._id;
    useEffect(() => {
        dispatch({ type: customerActions.GET_CUSTOMER_DETAILS, payload: Id });
    }, [Id]);

    return (
        <React.Fragment>
            <div className="page-content">
            {adminPageLoader ?
                    <AdminPageLoader /> :
                    <Container fluid>
                        <Breadcrumb
                            title="Customers"
                            titleLink="/customers"
                            breadcrumbItem="details"
                        />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="ms-3">
                                                <img
                                                    src={customerDetails?.Profile_Image ?? profileicon}
                                                    alt=""
                                                    className="avatar-md rounded-circle img-thumbnail"
                                                />
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted">
                                                    <h5>{`${customerDetails?.First_Name} ${customerDetails?.Last_Name}`}</h5>
                                                    <p className="mb-1">{customerDetails?.Email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <h4 className="card-title mb-4">Customer Details</h4>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal">
                                    <div className="form-group mb-3">
                                        <Label className="form-label">First Name</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={customerDetails?.First_Name}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Last Name</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={customerDetails?.Last_Name}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Email</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={customerDetails?.Email}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Contact Number</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={customerDetails?.Contact_Number}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Preferences</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={customerDetails?.Preferences ? customerDetails?.Preferences.join(', ') : ''}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Label className="form-label">Subscription Status</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            defaultValue={customerDetails?.IsSubscribed === "Yes" ? "Subscribed" : "-"}
                                            disabled
                                        />
                                    </div>
                                    {
                                        customerDetails?.IsSubscribed === "Yes" ?
                                            <>
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Subscription Plan</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        defaultValue={
                                                            customerDetails?.Stripe_Product_Price === 0
                                                                ? "Free Trial"
                                                                : (customerDetails?.Stripe_Product_Price !== 0 && customerDetails?.Stripe_Product_Duration === "day")
                                                                    ? "Month"
                                                                    : customerDetails?.Stripe_Product_Duration
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Subscription Price</Label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FaPoundSign className="price-image" />
                                                            </span>
                                                        </div>
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            defaultValue={customerDetails?.Stripe_Product_Price}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Subscribed Date</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        defaultValue={customerDetails?.Stripe_Subscription_Start_Date}
                                                        disabled
                                                    />
                                                </div>
                                            </>
                                            : <> </>
                                    }
                                    {
                                        customerDetails?.IsNewsLetterSubscribed === "Yes" ?
                                            <>
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Newsletter Subscription</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        defaultValue={customerDetails?.IsNewsLetterSubscribed === "Yes" ? "Subscribed" : "-"}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Newsletter Subscribed Date</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        defaultValue={customerDetails?.IsNewsLetterSubscribedOn}
                                                        disabled
                                                    />
                                                </div>
                                            </> : <></>
                                    }
                                    <div className="mt-4">
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => navigate('/customers')}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
};