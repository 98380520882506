import { actions } from './actions'

const initialState = {
    countryList: [],
    onlyCountryList: [],
    AddCountryNames: [],
    AddOnlyCountryResponse: [],
    DeleteOnlyCountryResponse:[]
}

export const CountryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_COUNTRY_LIST:
            return {
                ...state,
                countryList: action.payload,
            }
        case actions.SET_ONLY_COUNTRY_LIST:
            return {
                ...state,
                onlyCountryList: action.payload,
            }
        case actions.SET_ADD_COUNTRY_NAMES:
            return {
                ...state,
                AddCountryNames: action.payload,
            }
        case actions.RESPONSE_CREATE_ONLY_COUNTRIES:
            return {
                ...state,
                AddOnlyCountryResponse: action.payload,
            }
        case actions.RESPONSE_DELETE_ONLY_COUNTRIES:
            return {
                ...state,
                DeleteOnlyCountryResponse: action.payload,
            }
        default:
            return state;
    }
}