import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Form, Row, Col, Spinner } from "reactstrap";
import { GoAlert } from 'react-icons/go';
import { commonActions } from "../../store/common/actions";


export default function FileErrorModal(props) {
    const dispatch = useDispatch()

    const { fileValidation } = useSelector((state) => state.commonReducer)

    const handleClose = () => {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: false });
    };

    return (
        <Modal isOpen={fileValidation} toggle={handleClose}>
            <ModalHeader className="myCustomInput text-danger" toggle={handleClose} tag="h4">
                Warning
            </ModalHeader>
            <ModalBody>
                <div className="mt-3 mb-3 text-center">
                    <GoAlert className="alert-icon1" />
                    <div className="mt-3 mb-3 text-center">
                        <h5>Uploaded file size is too large.</h5>
                        <h5>Maximum file size is 6 mb</h5>
                    </div>
                    <button
                        type="button"
                        className="btn btn-danger save-customer text-center"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </div>

                {/* <Form className="myCustomInput padding-model">
                    <Row>
                        <Col md={12} className="mb-4 mt-3">
                            <h5>{message}</h5>
                        </Col>
                        <hr />
                        <Col md={12}>
                            <div className="text-end">
                                <button
                                    type="button"
                                    className="btn btn-primary save-customer me-2 text-center"
                                    onClick={() => handleSubmit(type)}
                                >
                                    {buttonLoader ?
                                        <Spinner color="light" size="sm" /> :
                                        "Yes"
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger save-customer text-center"
                                    onClick={toggle}
                                    disabled={buttonLoader ? true : false}
                                >
                                    No
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form> */}
            </ModalBody>
        </Modal>
    )
}