import React, { useState } from "react";
import { Container, Card, CardBody, CardTitle, Label, Input, Button, Row, Col, Table, FormFeedback, Form, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../store/admin/actions";
import { adminResponsibilitiesArray } from "../../utils/staticArrayList";
import Breadcrumb from "../../common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Multiselect from "multiselect-react-dropdown";

export default function AddAdminIndex() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { buttonLoader } = useSelector(state => state.commonReducer);

    let [responsibilities, setResponsibilities] = useState(adminResponsibilitiesArray());

    const [selectedOptions, setSelectedOptions] = useState([]);

    const Category = [
        { name: 'Business', id: 1 },
        { name: 'Politics', id: 2 },
        { name: 'Recent Topics', id: 3 }
    ];

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: '',
            Last_Name: '',
            Email: '',
            Contact_Number: '',
            Type_Of_User: 'Admin'
        },
        validationSchema: Yup.object({
            Name: Yup.string().required('first name is required'),
            Email: Yup.string()
                .required('email is required')
                .email('invalid email format')
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'invalid email format')
                .test('no-uppercase', 'email must not contain uppercase letters', (value) => {
                    // Test function to check for uppercase letters
                    return !/[A-Z]/.test(value);
                }),
            Contact_Number: Yup.string().required('contact number is required'),
        }),
        onSubmit: (data) => {
            // console.log("data", data)
            // data['Responsibilities'] = responsibilities;
            // var category = [];
            // selectedOptions.map(val =>
            //     category = [...category, val.name]
            // )
            // const categoryList = JSON.stringify(category)
            // data['Category'] = categoryList;

            dispatch({
                type: adminActions.CREATE_ADMIN,
                payload: { data: data, reset: resetForm }
            });
        }
    });

    const resetForm = () => {
        validation.resetForm();
        setResponsibilities(adminResponsibilitiesArray());
    };

    // const handleSelectChange = (value, type, key) => {
    //     let array = [...responsibilities];
    //     array[key][type] = value;
    //     setResponsibilities(array);
    // };


    const onSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setSelectedOptions(selectedList);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title="Admin"
                        titleLink="/admin"
                        breadcrumbItem="add admin"
                    />
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Basic Information</CardTitle>
                                        <Row>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">First Name</Label>
                                                    <Input
                                                        name="Name"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter First Name"
                                                        value={validation.values.Name}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.Name && validation.errors.Name ? true : false}
                                                    />
                                                    {validation.touched.Name && validation.errors.Name ?
                                                        <FormFeedback type='invalid'>{validation.errors.Name}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Last Name</Label>
                                                    <Input
                                                        name="Last_Name"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Last Name"
                                                        value={validation.values.Last_Name}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.Last_Name && validation.errors.Last_Name ? true : false}
                                                    />
                                                    {validation.touched.Last_Name && validation.errors.Last_Name ?
                                                        <FormFeedback type='invalid'>{validation.errors.Last_Name}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        name="Email"
                                                        type="text"
                                                        className="formcontrol"
                                                        placeholder="enter email"
                                                        value={validation.values.Email}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.Email && validation.errors.Email ? true : false}
                                                    />
                                                    {validation.touched.Email && validation.errors.Email ?
                                                        <FormFeedback type='invalid'>{validation.errors.Email}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group mb-3">
                                                    <Label className="form-label">Contact Number</Label>
                                                    <Input
                                                        name="Contact_Number"
                                                        type="text"
                                                        className="formcontrol"
                                                        placeholder="enter contact number"
                                                        value={validation.values.Contact_Number}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={validation.touched.Contact_Number && validation.errors.Contact_Number ? true : false}
                                                    />
                                                    {validation.touched.Contact_Number && validation.errors.Contact_Number ?
                                                        <FormFeedback type='invalid'>{validation.errors.Contact_Number}</FormFeedback> :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                            {/* <Col md="12" className="mb-6">
                                                <div className="form-group">
                                                    <Label htmlFor="Title" className="form-label">Category</Label>
                                                    <Multiselect
                                                        options={Category}
                                                        onSelect={onSelect}
                                                        onRemove={onRemove}
                                                        displayValue="name"
                                                        selectedValues={selectedOptions}
                                                        placeholder="Select options"
                                                    />
                                                    <div>
                                                        Selected Options:
                                                        {selectedOptions.map((option) => (
                                                            <span key={option.id}> {option.name} </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Col> */}

                                            <div className="mt-4">
                                                <Button
                                                    className="me-3"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    {buttonLoader ? <Spinner color="light" size="sm" /> : "Add"}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="danger"
                                                    onClick={() => navigate('/admin')}
                                                >
                                                    Close
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>


                            {/* <CardTitle className="mb-4">Responsibilities</CardTitle>
                                        <Table responsive bordered>
                                            <thead className="text-center align-middle">
                                                <tr>
                                                    <th>Role</th>
                                                    <th>View</th>
                                                    <th>Add</th>
                                                    <th>Update</th>
                                                    <th>Suspend/ Delete/ Block</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center align-middle">
                                                {responsibilities?.map((val, i) =>
                                                    <tr key={i}>
                                                        <th>{val.TabName}</th>
                                                        <td>
                                                            {val?.View !== '-' ?
                                                                <AccessSelect
                                                                    value={val?.View}
                                                                    handleSelectChange={handleSelectChange}
                                                                    type='View'
                                                                    keyValue={i}
                                                                /> :
                                                                '-'
                                                            }
                                                        </td>
                                                        <td>{val?.Add !== '-' ?
                                                            <AccessSelect
                                                                value={val?.Add}
                                                                handleSelectChange={handleSelectChange}
                                                                type='Add'
                                                                keyValue={i}
                                                            /> :
                                                            '-'
                                                        }
                                                        </td>
                                                        <td>{val?.Update !== '-' ?
                                                            <AccessSelect
                                                                value={val?.Update}
                                                                handleSelectChange={handleSelectChange}
                                                                type='Update'
                                                                keyValue={i}
                                                            /> :
                                                            '-'
                                                        }
                                                        </td>
                                                        <td>{val?.Delete !== '-' ?
                                                            <AccessSelect
                                                                value={val?.Delete}
                                                                handleSelectChange={handleSelectChange}
                                                                type='Delete'
                                                                keyValue={i}
                                                            /> :
                                                            '-'
                                                        }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table> */}

                        </Row>
                    </Form>
                </Container >
            </div >
        </React.Fragment >
    )
}

function AccessSelect({ value, handleSelectChange, type, keyValue }) {

    return (
        <div style={{ backgroundColor: value === 'Yes' ? '#34c38f' : '', borderRadius: value === 'Yes' ? '5px' : '' }}>
            <select
                style={{ backgroundColor: value === 'Yes' ? 'transparent' : '' }}
                className="form-control form-control-select-arrow"
                value={value}
                onChange={(e) => handleSelectChange(e.target.value, type, keyValue)}
            >
                <option disabled value="">--Select--</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
    )
}