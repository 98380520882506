export const adminResponsibilitiesArray = () => {
    let array = [
        { TabName: 'Content Creator', View: 'No', Add: 'No', Update: 'No', Delete: 'No' },
        { TabName: 'Contents', View: 'No', Add: '-', Update: 'No', Delete: 'No' },
        { TabName: 'Subscriptions', View: 'No', Add: 'No', Update: 'No', Delete: 'No' },
        { TabName: 'Customers', View: 'No', Add: '-', Update: '-', Delete: '-' },
        { TabName: 'Abandoned Cart', View: 'No', Add: '-', Update: '-', Delete: '-' },
        { TabName: 'Payouts', View: 'No', Add: '-', Update: 'No', Delete: '-' },
        { TabName: 'Revenue', View: 'No', Add: '-', Update: '-', Delete: '-' }
    ];
    return array;
};