import React, { useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap";
import { Icon } from '@iconify/react';
import { Link, Navigate } from "react-router-dom";
import profileicon from '../../assets/images/dashboard/user3.png';
import { useDispatch, useSelector } from "react-redux";
import { creatorDashboardActions } from "store/creatorDashboard/actions";
import { FaPoundSign } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'
import { FaDollarSign } from "react-icons/fa";


function CardUser() {

    const dispatch = useDispatch();

    const navigate = useNavigate()

    const { creatorTokenResponse } = useSelector(state => state.userAuthReducer);
    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <Row>
                            <Col md="9" sm="6">
                                <div className="d-flex">
                                    <div className="me-3">
                                        <a href="/creator-profile">
                                            <img
                                                src={creatorTokenResponse?.result?.Profile_Image ?? profileicon}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </a>
                                    </div>
                                    <div className="flex-grow-1 align-self-center">
                                        <div className="text-muted">
                                            <p className="mb-2">Welcome</p>
                                            <h5 className="mb-1">{creatorTokenResponse?.result?.Name}</h5>
                                            {/* <p className="mb-0">{tokenResponse?.result?.Type_Of_User}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3" sm="6">
                                <div className="d-flex space ">
                                    <div className="me-3">
                                        {/* <i className="bx bx-wallet"></i> */}
                                        <div className="avatar-md rounded-circle img-thumbnail wallet">
                                            <i className="fas fa-money-check"></i>
                                        </div>
                                    </div>

                                    <div className="flex-grow-1 align-self-center" onClick={() => navigate('/creator-wallet-details')}>
                                        <div className="text-muted">
                                            <h5 className="mb-1">Wallet</h5>
                                            <p className="mb-1"> <b>
                                                <FaDollarSign  className="wallet-dashbord" />
                                            {creatorTokenResponse?.result?.Remaining_Wallet_Balance}</b></p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="float-end">
                                    <div className="dropdown show">
                                        <button type="button" aria-haspopup="true" className="btn btn-light show" aria-expanded="true">
                                            <i className="mdi mdi-wallet me-2"></i>
                                            <span className="d-none d-sm-inline-block">Wallet Balance <i className="mdi mdi-chevron-down"></i>
                                            </span>
                                        </button>
                                        <div tabIndex="-1" role="menu" aria-hidden="false" className="dropdown-menu-end dropdown-menu-md dropdown-menu show" data-popper-placement="bottom-end"
                                            style={{
                                                position: 'absolute',
                                                inset: '0px 0px auto auto',
                                                transform: 'translate3d(0px, 36.5px, 0px)',
                                            }}>
                                            <div className="dropdown-item-text"><div>
                                                <p className="text-muted mb-2">Available Balance</p>
                                                <h5 className="mb-0">$ 9148.23</h5>
                                            </div>
                                            </div>
                                            <div tabIndex="-1" className="dropdown-divider"></div>
                                            <a href="#" tabIndex="0" role="menuitem" className="dropdown-item">BTC : <span className="float-end">1.02356</span></a><a href="#" tabIndex="0" role="menuitem" className="dropdown-item">ETH : <span className="float-end">0.04121</span></a>
                                            <a href="#" tabIndex="0" role="menuitem" className="dropdown-item">LTC : <span className="float-end">0.00365</span></a>
                                            <div tabIndex="-1" className="dropdown-divider"></div>
                                            <a href="#" tabIndex="0" role="menuitem" className="text-primary text-center dropdown-item">Learn more</a>
                                        </div></div></div> */}

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col >
        </Row >
    )
}

export default CardUser
