export const WebBookmarkActions = {
    GET_WEB_ADD_TO_BOOKMARK:"GET_WEB_ADD_TO_BOOKMARK",
    SET_WEB_ADD_TO_BOOKMARK:"SET_WEB_ADD_TO_BOOKMARK",


    GET_LIST_OF_BOOKMARKS_DATA :"GET_LIST_OF_BOOKMARKS_DATA",
    SET_LIST_OF_BOOKMARKS_DATA :"SET_LIST_OF_BOOKMARKS_DATA",

    DELETE_THE_BOOKMARK :"DELETE_THE_BOOKMARK",
    SET_DELETE_THE_BOOKMARK:"SET_DELETE_THE_BOOKMARK",
    



}